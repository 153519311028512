import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Form, Card } from "react-bootstrap";
import AddNewOrderType from "./add-order-type";
import { useDispatch, useSelector } from "react-redux";
import { DeleteOrderType, LoadOrderTypeByBranchId, UpdateOrderTypeVisibility } from "../../../../application/actions/orderTypeAction";
import { getOrderTypeList, getLoading } from "../../../../application/selectors/indexSelector";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../../layouts/ShimmerTable";
import UpdateOrderType from "./update-order-type";
import { Link } from "react-router-dom";

export default function OrderTypeComponent(props) {
  const dispatch = useDispatch();
  const orderTypeList = useSelector(getOrderTypeList);
  const { loading } = useSelector(getLoading);
  useEffect(() => {
    if (props?.branch?.id) {
      dispatch(LoadOrderTypeByBranchId(props?.branch?.id));
    }
  }, [props?.branch?.id]);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const initialState = {
    status: false,
  };
  const [formData, setFormData] = useState(initialState);

  const columns = [
    {
      name: "Visibility",
      selector: (row) => (
        <div>
          {/* {row.isDefault !== true ? (
            <> */}
          <Form.Check
            type="switch"
            name={`isVisible${row.id}`}
            onChange={(e) => handleToggle(e, row.id)}
            checked={row.isVisible}
            className="me-3 fs-16 fw-semibold"
          />
        </div>
      ),
    },
    {
      name: "Order Type",
      selector: (row) => (
        <Link
          className="text-dark"
          onClick={() => handleEditOrderType(row, true)}
        >
          {row.typeName}
        </Link>
      ),
    },
    {
      name: "Created On",
      selector: (row) => (
        <div>
          <h6 className="fs-14 fw-semibold mb-1">{row.createOn}</h6>
          <h5 className="fs-12">System</h5>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          {row.type}
          <div
            className={`d-flex justify-content-end ${row.type === "Default" ? "d-none" : ""
              }`}
          >
            {row.isDefault !== true ? (
              <>
                <Button
                  variant="danger"
                  className="btn-icon  me-2"
                  onClick={() => handleDeleteOrderType(row.id)}
                >
                  <i className="ri-delete-bin-line"></i>
                </Button>
                <Button
                  variant="primary"
                  className="btn-icon"
                  onClick={() => handleEditOrderType(row, true)}
                >
                  <i className="ri-pencil-line"></i>
                </Button>
              </>

            ) : (
              <>
                <span>Default</span>
              </>
            )}
          </div>
        </div>
      ),
    },
  ];

  const [showAddOrderType, setShowAddOrderType] = useState(false);
  const handleAddOrderType = (data, state) => {
    setOrderTypeData(data);
    setShowAddOrderType(state);
  };
  const [OrderTypeData, setOrderTypeData] = useState([]);
  const handleDeleteOrderType = (id) => {
    dispatch(DeleteOrderType(id));
  };
  const [showEditOrderType, setShowEditOrderType] = useState(false);
  const handleEditOrderType = (data, state) => {
    setOrderTypeData(data);
    setShowEditOrderType(state);
  };
  const handleToggle = (e, data) => {
    e.preventDefault();
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    const orderTypeData = {
      branchId: props?.branch?.id,
      orderId: data,
      isVisible: checked,
    };
    dispatch(UpdateOrderTypeVisibility(orderTypeData));
  };
  return (
    <React.Fragment>
      <AddNewOrderType
        show={showAddOrderType}
        data={OrderTypeData}
        branch={props?.branch}
        closeFunction={handleAddOrderType}
      />
      <UpdateOrderType
        show={showEditOrderType}
        data={OrderTypeData}
        closeFunction={handleEditOrderType}
      />
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="main-title fs-16 mb-0">Order Type</h4>
        </div>
        <div
          className="d-flex gap-2 mt-3 mt-md-0"
          onClick={() => handleAddOrderType([], true)}
        >
          <Button variant="danger" className="d-flex align-items-center gap-1">
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add New Order</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Body>
          <div className="custom-common-table">
            {!loading && (
              <DataTable
                columns={columns}
                data={orderTypeList}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
          </div>
        </Card.Body>
      </Card>
      {loading && <TableShimmerLoader columnCount={4} rowCount={4} />}
    </React.Fragment>
  );
}
