import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import * as tax from "../actions/taxAction";
import {
  ADD_TAX,
  GET_TAX_LIST,
  GET_TAX_LIST_BY_TENANT,
  GetTaxListByTenantSuccess,
  GetTaxListSuccess,
  MAP_TAX_WITH_BRANCH,
  UPDATE_TAX,
} from "../actions/taxAction";
import ToastifyService from "../../View/_common/ToastifyService";

const GetTaxList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_TAX_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "taxList" }));
        const taxList = await api.taxAPI.GetTaxList();
        dispatch(GetTaxListSuccess(taxList));
        dispatch(Loading.setLoading({ loading: false, value: "taxList" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "taxList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetTaxListByTenant =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_TAX_LIST_BY_TENANT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const taxListByTenant = await api.taxAPI.GetTaxListByTenant(
          action.payload
        );
        dispatch(GetTaxListByTenantSuccess(taxListByTenant));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const getTaxDetail =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === tax.TAX_DETAILS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const TaxDetail = await api.taxAPI.getTaxDetail(action.payload);
        dispatch(tax.GetTaxDetailsSuccess(TaxDetail));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddTax =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_TAX) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const taxList = await api.taxAPI.AddTax(action.payload.data);
        dispatch(GetTaxListSuccess(taxList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Tax Added Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateTax =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_TAX) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const taxList = await api.taxAPI.UpdateTax(action.payload.data);
        dispatch(GetTaxListSuccess(taxList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (action.payload.data.isDeleted) {
          ToastifyService.success("Tax Deleted Successfully! ");
        } else {
          ToastifyService.success("Tax Updated Successfully! ");
        }
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MapTaxWithBranch =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_TAX_WITH_BRANCH) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const TaxDetails = await api.taxAPI.MapTaxWithBranch(action.payload);
        dispatch(tax.GetTaxDetailsSuccess(TaxDetails));
        dispatch(Loading.loadTax);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        console.log(error);
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UnMapTaxWithBranch =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === tax.UNMAP_TAX_WITH_BRANCH) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const TaxDetails = await api.taxAPI.UnMapTaxWithBranch(action.payload);
        dispatch(tax.GetTaxDetailsSuccess(TaxDetails));
        dispatch(Loading.loadTax);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        console.log(error);
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

export default [
  GetTaxList,
  AddTax,
  UpdateTax,
  GetTaxListByTenant,
  MapTaxWithBranch,
  UnMapTaxWithBranch,
  getTaxDetail,
];
