export const ADD_SECTION = 'ADD_SECTION';
export const GET_SECTION_SUCCESS = 'GET_SECTION_SUCCESS';
export const GET_SECTION = 'GET_SECTION';
export const ADD_TABLE = 'ADD_TABLE';
export const DELETE_TABLE = 'DELETE_TABLE';
export const DELETE_SECTION = 'DELETE_SECTION';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATE_TABLE = 'UPDATE_TABLE';
export const GET_TABLE_SUCCESS = 'GET_TABLE_SUCCESS';
export const GET_TABLE_BY_SECTION_ID = 'GET_TABLE_BY_SECTION_ID';

export const AddSection =(data)=> ({
    type: ADD_SECTION,
    payload :{data}
});
export const UpdateSection =(data)=> ({
    type: UPDATE_SECTION,
    payload :{data}
});
export const getSectionByBranchId =(data)=> ({
    type: GET_SECTION,
    payload :{data}
});
export const loadSectionSuccess =(data)=> ({
    type: GET_SECTION_SUCCESS,
    payload :{data}
});
export const AddTable =(data)=> ({
    type: ADD_TABLE,
    payload :{data}
});
export const UpdateTable =(data)=> ({
    type: UPDATE_TABLE,
    payload :{data}
});
export const DeleteTable =(data)=> ({
    type: DELETE_TABLE,
    payload :{data}
});
export const DeleteSection =(data)=> ({
    type: DELETE_SECTION,
    payload :{data}
});
export const TableSuccess =(data)=> ({
    type: GET_TABLE_SUCCESS,
    payload :data
});
export const GetTableBySectionId =(data)=> ({
    type: GET_TABLE_BY_SECTION_ID,
    payload :{data}
});