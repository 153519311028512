import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import {
  ADD_BUSINESS_LANGUAGES,
  BUSINESS_LANGUAGES,
  BusinessLanguagesSuccess,
  CHANGE_PASSWORD,
  changePasswordSuccess,
  CurrenciesSuccess,
  DELETE_BUSINESS_LANGUAGES,
  GET_ALL_COMMON_FIELDS,
  GET_ALL_CURRENCIES,
  GET_ALL_LANGUAGES,
  GET_ALL_TIMEZONES,
  IMAGE_UPLOAD,
  LanguagesSuccess,
  PROFILE_BY_TENANT,
  ProfileByTenantSuccess,
  TimeZonesSuccess,
  UPDATE_BUSINESS_PROFILE,
  UPDATE_CURRENCY,
  UPDATE_TIMEZONES,
} from "../actions/accountAction";
import ToastifyService from "../../View/_common/ToastifyService";
const ProfileByTenant =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === PROFILE_BY_TENANT) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "ProfileByTenant" })
        );
        const profileByTenant = await api.accountApi.ProfileByTenant(
          action.payload
        );
        dispatch(ProfileByTenantSuccess(profileByTenant));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const ImageUpload =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === IMAGE_UPLOAD) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        await api.accountApi.ImageUpload(action.payload);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        console.log(error);
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateBusinessProfile =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_BUSINESS_PROFILE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const profileByTenant = await api.accountApi.UpdateBusinessProfile(
          action.payload
        );
        dispatch(ProfileByTenantSuccess(profileByTenant));
        ToastifyService.success(`Business Profile Updated Successfully!`);

        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const getAllCommonFields =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_COMMON_FIELDS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CommonFields = await api.accountApi.getAllCommonFields();
        localStorage.setItem(
          "CommonFields",
          JSON.stringify(CommonFields.result)
        );
        // dispatch(ProfileByTenantSuccess(profileByTenant));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetAllCurrencies =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_CURRENCIES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const Currencies = await api.accountApi.GetAllCurrencies();
        dispatch(CurrenciesSuccess(Currencies));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetAllTimeZones =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_TIMEZONES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const TimeZones = await api.accountApi.GetAllTimeZones();
        dispatch(TimeZonesSuccess(TimeZones));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateBusinessTimeZone =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_TIMEZONES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const TimeZoneData = await api.accountApi.UpdateBusinessTimeZone(
          action.payload
        );
        dispatch(ProfileByTenantSuccess(TimeZoneData));
        ToastifyService.success(`Business TimeZone Updated Successfully!`);

        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateBusinessCurrency =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_CURRENCY) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CurrencyData = await api.accountApi.UpdateBusinessCurrency(
          action.payload
        );
        dispatch(ProfileByTenantSuccess(CurrencyData));
        ToastifyService.success(`Business Currency Updated Successfully!`);

        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetAllLanguagesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ALL_LANGUAGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const Languages = await api.accountApi.GetAllLanguagesList();
        dispatch(LanguagesSuccess(Languages));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddBusinessLanguages =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_BUSINESS_LANGUAGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const Languages = await api.accountApi.AddBusinessLanguages(
          action.payload
        );
        dispatch(BusinessLanguagesSuccess(Languages));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Bussiness Language Updated Successfully ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetBusinessLanguages =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === BUSINESS_LANGUAGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const Languages = await api.accountApi.GetBusinessLanguages(
          action.payload
        );
        dispatch(BusinessLanguagesSuccess(Languages));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteBusinessLanguages =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_BUSINESS_LANGUAGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const Languages = await api.accountApi.DeleteBusinessLanguages(
          action.payload
        );
        dispatch(BusinessLanguagesSuccess(Languages));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const changePassword =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CHANGE_PASSWORD) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        await api.accountApi.changePassword(
          action.payload
        );
        dispatch(changePasswordSuccess(true))
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  
export default [
  getAllCommonFields,
  ProfileByTenant,
  ImageUpload,
  UpdateBusinessProfile,
  GetAllCurrencies,
  GetAllLanguagesList,
  AddBusinessLanguages,
  GetBusinessLanguages,
  DeleteBusinessLanguages,
  GetAllTimeZones,
  UpdateBusinessTimeZone,
  UpdateBusinessCurrency,
  changePassword
];
