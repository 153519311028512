import React from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";

export default function AddPrinter(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add New Printer</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div>
                        <Form.Label>Printer Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Printer Name" />
                    </div>
                </Offcanvas.Body>
                <div className="offcanvas-footer bg-white">
                    <div>
                        <Button variant="primary" className="fs-14 me-3">
                            <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
                            <span className="align-middle">Add</span>
                        </Button>
                    </div>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}