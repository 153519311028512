export const GET_BRANCH = 'GET_BRANCH';
export const GET_BRANCH_SUCCESS = 'GET_BRANCH_SUCCESS';
export const UPDATE_BASIC_INFO = 'UPDATE_BASIC_INFO';
export const UPDATE_OPERATING_HOURS = 'UPDATE_OPERATING_HOURS';
export const UPDATE_BASIC_INFO_SUCCESS = 'UPDATE_BASIC_INFO_SUCCESS';
export const GET_BRANCH_OT_SESSIONS = 'GET_BRANCH_OT_SESSIONS';
export const BRANCH_OT_SESSIONS_SUCCESS = 'BRANCH_OT_SESSIONS_SUCCESS';

export const GetBranch =(data)=> ({
    type: GET_BRANCH,
    payload :{data}
});
export const GetBranchSuccess =(data)=> ({
    type: GET_BRANCH_SUCCESS,
    payload :{data}
});
export const UpdateBasicInfo =(data)=> ({
    type: UPDATE_BASIC_INFO,
    payload :{data}
});
export const UpdateBasicInfoSuccess =(data)=> ({
    type: UPDATE_BASIC_INFO_SUCCESS,
    payload :{data}
});
export const GetBranchOTSessions =(data)=> ({
    type: GET_BRANCH_OT_SESSIONS,
    payload :data
});
export const BranchOTSessionsSuccess =(data)=> ({
    type: BRANCH_OT_SESSIONS_SUCCESS,
    payload :data
});
export const UpdateOperatingHours =(data)=> ({
    type: UPDATE_OPERATING_HOURS,
    payload :data
});