import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { debounce } from "lodash";
import Environment from "../../infrastructure/core/Environment";
import { Row, Col, Card, Button } from "react-bootstrap";
import { Bar } from "react-chartjs-2";
import { Link } from "react-router-dom";

export default function FinancialDashboard() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);

    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);
    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const [branchData, setBranchData] = useState(Environment.defaultValue);
    const handleBranchIdFromHeader = debounce((data) => {
        setBranchData(data);
    }, 300);

    const production_summary_bar = {
        labels: ['Total Order Value', 'Total Insurance Balance', 'Total Open Cases', 'Total Worked Cases'],
        datasets: [{
            data: [3100, 2405, 2804, 2590],
            backgroundColor: "#506fd9", // Will be filled with gradients
            barPercentage: 0.5
        }]
    };

    const production_summary_optionBar = {
        maintainAspectRatio: false,
        responsive: true,
        plugins: {
            legend: {
                display: false
            }
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 3500,
                ticks: {
                    color: '#a1aab3',
                    font: {
                        size: 10
                    }
                }
            }
        }
    };

    return (
        <React.Fragment>
            <Header passDataToPointOfSale={handleBranchIdFromHeader} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Financial Dashboard</h4>
                    </div>

                    <div className="d-flex gap-2 mt-3 mt-md-0">
                        <Link className="d-flex align-items-center gap-1 btn btn-outline-dark">
                            <i className="ri-file-excel-2-fill fs-18 lh-1"></i>
                            <span className="d-sm-inline">Download Report</span>
                        </Link>
                    </div>
                </div>

                <Row className="g-3">
                    {/* <Col xl={5}>
                        <h3 className="fs-14 mb-3">Todays Summary</h3>
                        <Row className="g-3">
                            {[
                                {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "success",
                                        //"amount": "+28.5%"
                                    },
                                    "value": "3,643.50",
                                    "label": "Total Order Value",
                                    "des": "Total cases with Ins balance > $ 0",
                                    "last": {
                                        "color": "success",
                                        "amount": "5.8%"
                                    }
                                }, {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "danger",
                                        //"amount": "-3.8%"
                                    },
                                    "value": "0.00",
                                    "label": "Ongoing Orders",
                                    "des": "Sum of Total Insurance Balance",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.5%"
                                    }
                                }, {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "danger",
                                        //"amount": "-8.4%"
                                    },
                                    "value": "3,470.00",
                                    "label": "Total Delivered Order",
                                    "des": "Total number of cases currently open for action.",
                                    "last": {
                                        "color": "success",
                                        "amount": "4.2%"
                                    }
                                }, {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "success",
                                        //"amount": "+20.9%"
                                    },
                                    "value": "0.00",
                                    "label": "Total Cancelled Orders",
                                    "des": "Total number of worked cases in last 7 days.",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.2%"
                                    }
                                }
                            ].map((item, index) => (
                                <Col xs="6" md="3" xl="6" key={index}>
                                    <Card className="card-one card-product">
                                        <Card.Body className="p-3">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="card-icon"><i className={item.icon}></i></div>
                                                <h6 className={"fw-normal ff-numerals mb-0 text-" + item.percent.color}>{item.percent.amount}</h6>
                                            </div>
                                            <h2 className="card-value ls--1">₹{item.value}</h2>
                                            <label className="card-label fw-medium text-dark">{item.label}</label>
                                            <label className="card-label fw-medium text-dark fs-12 text-black-50">{item.des}</label>
                                            <span className="d-flex gap-1 fs-xs">
                                                <span className={"d-flex align-items-center text-" + item.last.color}>
                                                    <span className="ff-numerals">{item.last.amount}</span><i className={(item.last.color === 'success') ? "ri-arrow-up-line" : "ri-arrow-down-line"}></i>
                                                </span>
                                                <span className="text-secondary">than last week</span>
                                            </span>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col> */}

                    <Col xl={5}>
                        <Card className="card-one card-product-inventory">
                            <Card.Header>
                                <Card.Title as="h6">Production Summary (Last Week)</Card.Title>
                            </Card.Header>
                            <Card.Body className="p-3">
                                <Bar className="ht-300" data={production_summary_bar} options={production_summary_optionBar} />
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col xl={7}>
                    <h3 className="fs-14 mb-3">Sales Revenue Bifurcation</h3>
                        <Row className="g-3">
                            {[
                                {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "success",
                                        //"amount": "+28.5%"
                                    },
                                    "value": "0.00",
                                    "label": "Cash Collected",
                                    "des": "Total cases with Ins balance > $ 0",
                                    "last": {
                                        "color": "success",
                                        "amount": "5.8%"
                                    }
                                }, {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "danger",
                                        //"amount": "-3.8%"
                                    },
                                    "value": "0.00",
                                    "label": "Card Swipped",
                                    "des": "Sum of Total Insurance Balance",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.5%"
                                    }
                                }, {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "danger",
                                        //"amount": "-8.4%"
                                    },
                                    "value": "0.00",
                                    "label": "UPI Payments",
                                    "des": "Total number of cases currently open for action.",
                                    "last": {
                                        "color": "success",
                                        "amount": "4.2%"
                                    }
                                }, {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "success",
                                        //"amount": "+20.9%"
                                    },
                                    "value": "0.00",
                                    "label": "Pending Amount",
                                    "des": "Total number of worked cases in last 7 days.",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.2%"
                                    }
                                },
                                , {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "success",
                                        //"amount": "+20.9%"
                                    },
                                    "value": "0.00",
                                    "label": "Total Discount Offered",
                                    "des": "Total number of worked cases in last 7 days.",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.2%"
                                    }
                                },
                                , {
                                    "icon": "ri-task-fill",
                                    "percent": {
                                        "color": "success",
                                        //"amount": "+20.9%"
                                    },
                                    "value": "0.00",
                                    "label": "Tax Amount Collected",
                                    "des": "Total number of worked cases in last 7 days.",
                                    "last": {
                                        "color": "danger",
                                        "amount": "0.2%"
                                    }
                                }
                            ].map((item, index) => (
                                <Col xs={6} md={4} xl={4} key={index}>
                                    <Card className="card-one card-product">
                                        <Card.Body className="p-3">
                                            <div className="d-flex align-items-center justify-content-between mb-3">
                                                <div className="card-icon"><i className={item.icon}></i></div>
                                                <h6 className={"fw-normal ff-numerals mb-0 text-" + item.percent.color}>{item.percent.amount}</h6>
                                            </div>
                                            <h2 className="card-value ls--1">₹ {item.value} <sub className="text-dark fs-14">(0)</sub></h2>
                                            <label className="card-label fw-medium text-dark">{item.label}</label>
                                            <label className="card-label fw-medium text-dark fs-12 text-black-50">{item.des}</label>
                                            <span className="d-flex gap-1 fs-xs">
                                                <span className={"d-flex align-items-center text-" + item.last.color}>
                                                    <span className="ff-numerals">{item.last.amount}</span><i className={(item.last.color === 'success') ? "ri-arrow-up-line" : "ri-arrow-down-line"}></i>
                                                </span>
                                                <span className="text-secondary">than last week</span>
                                            </span>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Col>

                </Row>
            </div>
        </React.Fragment>
    )
}