import { combineReducers } from 'redux';
import sale from './sale';
import table from './table';
import item from './item'
import menu from './menu'
import addOns from './addOns'
import loader from './loader'
import kitchen from './kitchen'
import tax from './tax'
import discount from './discount'
import charges from './charges'
import branch from './branch'
import variance from './variance'
import orderType from './orderType'
import material from './material'
import receipe from './receipe'
import kotRemark from './kotRemark'
import employee from './employee'
import pos from './pos'
import customer from './customer'
import account from './account'
import user from './user'
import paymentMode from './paymentMode'
import generalSetting from './generalSetting'
import salesOrders from './salesOrders'
import printTemplate from './printTemplate'
import vendor from './vendor'
import reciept from './reciept'
import attendance from './attendance'


export default combineReducers({
    sale,
    table,
    item,
    menu,
    addOns,
    loader,
    kitchen,
    tax,
    discount,
    charges,
    branch,
    variance,
    orderType,
    material,
    receipe,
    kotRemark,
    employee,
    pos,
    customer,
    account,
    user,
    paymentMode,
    generalSetting,
    salesOrders,
    printTemplate,
    reciept,
    vendor,
    attendance
})