import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { useSelector } from "react-redux";
import { getLoading } from "../../../application/selectors/indexSelector";
import { Button, Form, Card, Table } from "react-bootstrap";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import salaryStatementlist from "../../../Json/Attendance/salary-statement.json";

export default function SalaryPayment() {
    const { selectedBranchData } = useContext(AuthContext);
    const parsedBranchData = JSON.parse(selectedBranchData);
    const { loading, value } = useSelector(getLoading);

    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = salaryStatementlist?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <div>
                    <Button variant="success" className="fs-14" type="button">Generate Statement</Button>
                </div>
                <div>
                    <Form.Select aria-label="Default select example" className="fs-13" name="branches">
                        <option value="HID04">Location: Chembur - Chedda Nagar</option>
                        <option value="HID036">Location: Nerul - Sector 27</option>
                        <option value="HID037">Location: Mumbra - Opening Soon</option>
                    </Form.Select>
                </div>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <div>
                        <h4 className="main-title fs-14 mb-0">Salary Statement</h4>
                    </div>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-250">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {filteredEmployees && filteredEmployees !== undefined && (
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Month</th>
                                    <th>Location</th>
                                    <th>Total Payable</th>
                                    <th>Total Paid</th>
                                    <th>Last Update</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {filteredEmployees?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.id}</td>
                                        <td>{item.month}</td>
                                        <td>{item.loaction}</td>
                                        <td><b>{parsedBranchData?.currencySymbol} {item.totla_payabal}</b></td>
                                        <td><b>{parsedBranchData?.currencySymbol} {item.total_paid}</b></td>
                                        <td>
                                            <h5 className="fs-14 fw-semibold mb-1">{item.last_updated}</h5>
                                            <h6 className="fs-12">11:30 PM</h6>
                                        </td>
                                        <td className="w-0">
                                            <div className="d-flex justify-content-end align-items-center">
                                                <Button variant="outline-dark" className="btn-icon me-2"><i className="ri-arrow-down-line fs-18"></i></Button>
                                                <Button variant="dark" className="text-nowrap">Payment</Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    )}
                    {!loading && filteredEmployees === undefined && (
                        <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading &&
                        value === "salaryStatement" && filteredEmployees === undefined && (
                            <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                        )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}