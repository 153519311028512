import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import { ADD_VENDOR, EDIT_VENDOR_BY_ID, VENDOR_LIST, VendorListSuccess } from "../actions/vendorAction";

const AddVendorData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_VENDOR) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VendorData = await api.vendorAPI.AddVendor(
          action.payload
        );
        console.log(VendorData);
        dispatch(VendorListSuccess(VendorData))
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const EditVendorData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === EDIT_VENDOR_BY_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VendorData = await api.vendorAPI.EditVendorDataById(
          action.payload
        );
        console.log(VendorData);
        dispatch(VendorListSuccess(VendorData))
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const getVendorList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === VENDOR_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const VendorData = await api.vendorAPI.getVendorList()
        console.log(VendorData);
        dispatch(VendorListSuccess(VendorData))
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  export default [
    AddVendorData,getVendorList,EditVendorData
  ];
  