import React, { useEffect, useState, useRef } from "react";
import Prism from "prismjs";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form } from "react-bootstrap";
import { getLoading } from "../../../application/selectors/indexSelector";
import { Imgaeupload } from "../../../application/actions/ItemAction";
import { Link } from "react-router-dom";
import PlaceholderImage from "../../../assets/img/empty-image/placeholde-image.svg";


export default function StoreBill() {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { loading } = useSelector(getLoading);

  const [imagePath, setImagePath] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePath = e.target.result;
        setImagePath(imagePath);
        const image = imagePath.split(",")[1];
        const id = 2;
        const data = {
          image: image,
          id: id,
          foldername: "Icon",
        };
        dispatch(Imgaeupload(data));
      };

      reader.readAsDataURL(file);
    }
  };


  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const [selectedPaymentOption, setSelectedPaymentOption] = useState("UPI");
  const handlePaymentOption = (e) => {
    setSelectedPaymentOption(e.target.value);
  };

  const [isChecked, setIsChecked] = useState(false);
  const handleSwitchChange = () => {
    setIsChecked(!isChecked); // Toggle the state when the switch changes
  };

  return (
    <React.Fragment>
      <div>
        <div className="d-flex mt-3 mb-4">
          <div className="me-3">
          <div className="item-manage-thumbnail">
              <div className="text-center bg-white thumbnail h-100">
                <Form.Group className="h-100" controlId="formFile">
                  <div className="custom-update-image">
                    <Link className="edit-image bg-dark text-white">
                      <i className="ri-pencil-line fs-16"></i>
                    </Link>
                    <div className={`${loading ? "placeholder" : ""}uploaded-image`}>
                      <Form.Control
                        type="file"
                        className="custom-file-input"
                        ref={fileInputRef}
                        onChange={handleFileChange}
                        accept=".jpg, .jpeg, .png"
                      />
                      <img src={PlaceholderImage}
                        onError={(e) => { e.target.src = "https://freshlo.oss-ap-south-1.aliyuncs.com/FudEasy/no-image%20(2).png" }}
                        className={`${loading ? "placeholder" : ""}img-fluid w-100 h-100`}
                        alt="thumbnail"
                        srcSet={imagePath}
                      />
                    </div>
                  </div>
                </Form.Group>
              </div>
            </div>
          </div>
          <div>
            <h3 className="fs-16 text-dark mb-1 fw-semibold">Brand Logo</h3>
            <p className="fs-14 text-dark">Set the logo image. Only <br /> *.png, *.jpg, and *.jpeg image file are accepted.</p>
          </div>
        </div>

        <div className="divider divider-start">
          <span>Header Content</span>
        </div>
        <Row className="mb-3">
          <Col md={6}>
            <Form.Check className="me-3 fs-14 text-dark" type="switch" label="Logo" name="logo" />
          </Col>
          <Col></Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Brand Name"
              name="brandName"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span><i className="ri-bold"></i></span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span><i className="ri-italic"></i></span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span><i className="ri-underline"></i></span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check className="me-3 fs-14 text-dark" type="switch" label="Legel Entity Name" name="legelEntityName" />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Mailing Address"
              name="mailingAddress"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Tax Number Label"
              name="taxNumberLebel"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Invoice Number"
              name="invoiceNumber"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Order Created Date"
              name="orderCreatedDate"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Cashier Name"
              name="cashierName"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Order Type"
              name="orderType"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Section / Area Name"
              name="sectionAreaName"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Table Name"
              name="tableName"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div>
        <div className="divider divider-start">
          <span>Sub Header Content</span>
        </div>
        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Customer Name"
              name="customerName"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Customer Address"
              name="customerAddress"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div>
        <div className="divider divider-start">
          <span>KOT Item Detail</span>
        </div>
        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Name"
              name="itemName"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Customization"
              name="itemCustomization"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Unit Price Colunm"
              name="itemUnitPrice"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Quality Colunm"
              name="itemQuality"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Tax Amount Colunm"
              name="itemTaxAmt"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Item Total Price Colunm"
              name="itemtotalPrice"
            />
          </Col>
          <Col sm={8}>
            <Row className="justify-content-end align-items-center">
              <Col sm={4} className="pr-0">
                <div className="text-right">
                  <label className="font-style-bttns">
                    <input type="checkbox" name="bold" />
                    <span>
                      <i className="ri-bold"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="italic" />
                    <span>
                      <i className="ri-italic"></i>
                    </span>
                  </label>
                  <label className="font-style-bttns">
                    <input type="checkbox" name="underline" />
                    <span>
                      <i className="ri-underline"></i>
                    </span>
                  </label>
                </div>
              </Col>
              <Col sm={4}>
                <div>
                  <Form.Select
                    className="fs-14"
                    aria-label="Default select example"
                    name="font"
                  >
                    <option value="small">Small</option>
                    <option value="Medium">Medium</option>
                    <option value="Large">Large</option>
                    <option value="Extra Small">Extra Large</option>
                  </Form.Select>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <div>
        <div className="divider divider-start">
          <span>Payment</span>
        </div>
        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="Payment Breakup Summary"
              name="paymentSummary"
              disabled
            />
          </Col>
        </Row>
        <Row className="align-items-center mb-3">
          <Col sm={4}>
            <Form.Check
              className="me-3 fs-14 text-dark"
              type="switch"
              label="QR Code Option"
              name="qrCodeOption"
              checked={isChecked}
              onChange={handleSwitchChange}
            />
            {isChecked === true && (
              <Row className="align-items-center mt-2">
                <Col>
                  <Form.Select
                    name="chooseOption"
                    value={selectedPaymentOption}
                    onChange={handlePaymentOption}
                  >
                    <option disabled>Choose Option</option>
                    <option value="UPI">UPI</option>
                    <option value="E-Bill">E-Bill</option>
                  </Form.Select>
                </Col>

                <Col>
                  {selectedPaymentOption === "UPI" && (
                    <Form.Control
                      type="text"
                      placeholder="Enter UPI ID"
                      name="upiID"
                    />
                  )}

                  {selectedPaymentOption === "E-Bill" && (
                    <Form.Control
                      type="text"
                      placeholder="Enter Number"
                      name="number"
                    />
                  )}
                </Col>
              </Row>
            )}
          </Col>
          {isChecked === true && (
            <Col sm={8}>
              <Row className="justify-content-end align-items-center">
                <Col sm={8}>
                  <div className="text-right">
                    <label className="font-style-bttns">
                      <input type="checkbox" name="bold" />
                      <span>
                        <i className="ri-align-left"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="bold" />
                      <span>
                        <i className="ri-align-center"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="bold" />
                      <span>
                        <i className="ri-align-right"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="bold" />
                      <span>
                        <i className="ri-bold"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="italic" />
                      <span>
                        <i className="ri-italic"></i>
                      </span>
                    </label>
                    <label className="font-style-bttns">
                      <input type="checkbox" name="underline" />
                      <span>
                        <i className="ri-underline"></i>
                      </span>
                    </label>
                  </div>
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
}
