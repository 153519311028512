import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Environment from "../Environment";

const AuthContext = createContext();
export const AuthContextProvider = ({ children }) => {
  const [token, setToken] = useState(() => {
    let Token = localStorage.getItem("Token");
    if (Token) {
      return Token;
    }
    return null;
  });
  const [user, setUser] = useState(() => {
    let userProfile = localStorage.getItem("userProfile");
    if (userProfile) {
      return JSON.parse(userProfile);
    }
    return null;
  });
  const [branch, setBranch] = useState(null);
  const [selectedBranchData, setSelectedBranchData] = useState(null);
  const navigate = useNavigate();
  const login = async (payload) => {
    try {
      const mydata = {
        username: payload.username,
        password: payload.password,
        domain: payload.domain,
      };
      let response = await axios.post(
        `${Environment.Account_Url}login`,
        mydata
      );
      localStorage.setItem("Token", JSON.stringify(response.data.token));
      localStorage.setItem("userProfile", JSON.stringify(response.data));
      setUser(response.data);
      setToken(response.data.token);
      if (response.data.isPasswordSetup) {
        navigate("/account/Choose-branch");
      } else {
        navigate("/account/reset-password", { state: {...response.data,password:payload.password} });
      }
    } catch (error) {
      if (error.response && error.response.status === 400) {
        throw new Error("Invalid username or password");
      } else {
        console.error("Error during login:", error);
      }
    }
  };

  const logout = async () => {
    localStorage.clear();
    setUser(null);
    navigate("/account/login");
  };
  useEffect(() => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    setUser(userProfile);
    if (storedToken) {
      setUser(userProfile);
      setToken(storedToken);
    } else {
      navigate("/account/login");
    }
  }, []);
  return (
    <>
      <AuthContext.Provider
        value={{
          user,
          token,
          branch,
          selectedBranchData,
          login,
          logout,
          setBranch,
          setSelectedBranchData,
        }}
      >
        {children}
      </AuthContext.Provider>
    </>
  );
};

export default AuthContext;
