import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import { Card, Table, Button } from "react-bootstrap";
import Environment from "../../infrastructure/core/Environment";
import { debounce } from "lodash";
import reciepts from "../../Json/Coupon/reciepts.json";
import AddInvoice from "./addInvoice";
import axios from "axios";
import LoadingSpinner from "../_common/LoadingSpinner";
import { useDispatch, useSelector } from "react-redux";
import {
  getInvoiceListByTenantId,
  getVendorListByTenantId,
} from "../../application/selectors/indexSelector";
import { InvoiceList } from "../../application/actions/recieptAction";
import { VendorList } from "../../application/actions/vendorAction";

export default function ManageInvoice() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const InvoiceListData = useSelector(getInvoiceListByTenantId);
  const dispatch = useDispatch();

  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
    dispatch(InvoiceList);
  }, [skin]);
  const [branchData, setBranchData] = useState(Environment.defaultValue);

  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);

  const [showAddCoupon, setShowAddCoupon] = useState(false);
  const handleAddCoupon = (state) => {
    setShowAddCoupon(state);
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };
  // Utility function to remove extra spaces from keys
  const cleanKeys = (obj) => {
    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const cleanKey = key.replace(/\s+/g, "");
        newObj[cleanKey] = obj[key];
      }
    }
    return newObj;
  };

  const handleSubmit = async (event) => {
    setLoading(true);

    const question = `You are a document entity extraction specialist. Given a document, your task is to extract the text value of the following entities:
    {
      "DeliveryDate": "",
      "DueDate": "",
      "FreightAmount": "",
      "InvoiceDate": "",
      "InvoiceId": "",
      "Items": [
        {
          "description": "",
          "amount": "",
          "quantity": "",
          "unit": "",
          "unit_price": ""
        }
      ],
      "NetAmount": "",
      "GrossAmount": "",
      "PaymentTerms": "",
      "PurchaseOrder": "",
      "ReceiverAddress": "",
      "ReceiverEmail": "",
      "ReceiverName": "",
      "ReceiverPhone": "",
      "ReceiverTaxId": "",
      "ReceiverWebsite": "",
      "RemitToAddress": "",
      "RemitToName": "",
      "ShipFromAddress": "",
      "ShipFromName": "",
      "ShipToAddress": "",
      "ShipToName": "",
      "SupplierAddress": "",
      "SupplierEmail": "",
      "SupplierIban": "",
      "SupplierName": "",
      "SupplierPaymentRef": "",
      "SupplierPhone": "",
      "SupplierRegistration": "",
      "SupplierTaxId": "",
      "SupplierWebsite": "",
      "TotalAmount": "",
      "TotalTaxAmount": "",
      "AmountPaidSinceLastInvoice": "",
      "BalanceAmount": "",
      "PaymentStatus": "",
      "Tax": [
        {
          "TaxType": "",
          "TaxAmount": "",
          "TaxRate": "",
          "TotalTaxableAmount": ""
        }
      ]
    }
    - The JSON schema must be followed during the extraction.
    - The values must only include text found in the document
    - Do not normalize any entity value.
    - If an entity is not found in the document, set the entity value to null.
    - normalize  Amount entity value Amount to Number/Integer format
    - remove any coumas fron the amounts
    - transliterate the extracted hindi  text into english
    - Do not change the  entity name spellings 
    - do not add any extra spaces within a entity name`;

    event.preventDefault();
    setResponse(null);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("question", question);

    try {
      const res = await axios.post(
        "https://gatewaypy.fudeasy.com/ask_Question",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      let extractedData = res.data.extracted_data;
      if (typeof extractedData === "string") {
        extractedData = extractedData.replace(/```|json/g, "").trim();

        try {
          extractedData = JSON.parse(extractedData);
          // Clean the keys of the extracted data
          const finalData = cleanKeys(extractedData);
          console.log("Received response:", finalData);
          setResponse(finalData);
        } catch (parseError) {
          console.error("Error parsing JSON:", parseError);
          setLoading(false);
          setResponse({ error: "Failed to parse JSON response" });
          return;
        }
      }

      setLoading(false);
      setShowAddCoupon(true);
      setSelectedFile("");
    } catch (err) {
      console.error(err);
      setLoading(false);
    }
  };

  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <div className="main main-app p-3 p-lg-4">
        <AddInvoice
          show={showAddCoupon}
          closeFunction={handleAddCoupon}
          data={response}
        />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0"> Manage Reciepts</h4>
          </div>

          <div className="d-flex gap-2 mt-3 mt-md-0">
            <form onSubmit={handleSubmit}>
              <div className="upload-invoice-bill d-flex align-items-center">
                <div className="invoice-bill-input me-2">
                  <input type="file" onChange={handleFileChange} />
                </div>
                {/* <Button variant="dark" type="submit" className="d-flex align-items-center gap-2">
                  <i className="ri-upload-line fs-18 lh-1"></i>
                  <span className="d-sm-inline">Upload</span>
                </Button> */}
                <Button
                  variant="dark"
                  type="submit"
                  className="d-flex align-items-center gap-2"
                  disabled={loading}
                >
                  {loading ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={20}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Upload"
                  )}
                </Button>
              </div>
            </form>
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handleAddCoupon(true)}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline"> Create New Invoice</span>
            </Button>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body>
            <Table className="mb-0" responsive>
              <thead>
                <tr>
                  <td>Invoice Id</td>
                  <td>Invoice Date</td>
                  <td>Vendor Name</td>
                  <td>Total Amount</td>
                  <td>Last Updated</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {InvoiceListData?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.invoice_id}</td>
                    <td>{item.invoice_date}</td>
                    <td>{item.supplier_name}</td>
                    <td>
                      {item.total_amount ? item.total_amount : item.net_amount}
                    </td>
                    <td>1 Jan 2024</td>
                    <td className="w-0">
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="outline-danger"
                          className="btn-icon me-2"
                        >
                          <i className="ri-delete-bin-line"></i>
                        </Button>
                        <Button variant="primary" className="btn-icon">
                          <i className="ri-pencil-line"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
