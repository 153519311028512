import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Tabs, Tab, Tooltip, OverlayTrigger } from "react-bootstrap";
import noImg from "../../../assets/img/no-image-found.png";
import { Link } from "react-router-dom";
import AddonsNewItem from "./addons-new-item";
import AddNewAddons from "./addAddons";
import {
  DeleteAddOnsItem,
  GetAddOnsItemByGroupId,
} from "../../../application/actions/addOnsAction";
import { useDispatch, useSelector } from "react-redux";
import {
  getAddOnsData,
  getAllAddOnsItem,
  getCatalogAddonsData,
  getFoodType,
  getItemCatalogData,
  getLoading,
  getQuantityType,
} from "../../../application/selectors/indexSelector";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import MappedItem from "../../_common/mapped-item";
import {
  MapAddOnItem,
  UnMapAddOnItem,
} from "../../../application/actions/ItemAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import EditAddons from "./editAddonsGroup";
import Environment from "../../../infrastructure/core/Environment";

export default function EditAddonsGroup(props) {
  const AddOnsData = useSelector(getAddOnsData);
  const { user } = useContext(AuthContext);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();
  const ItemCatalogData = useSelector(getItemCatalogData);
  useEffect(() => {
    if (AddOnsData?.id !== undefined) {
      dispatch(GetAddOnsItemByGroupId(AddOnsData?.id));
    }
  }, [AddOnsData]);
  const [activeTab, setActiveTab] = useState("itemList");

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const [showAddonsItem, setShowAddonsItem] = useState(false);
  const handleAddoneNewItem = (data, state) => {
    setItemData(data);
    setShowAddonsItem(state);
  };
  const { loading, value } = useSelector(getLoading);
  const AddOnsItemsList = useSelector(getAllAddOnsItem);
  const [itemData, setItemData] = useState({});
  const handleDelete = (id) => {
    dispatch(DeleteAddOnsItem(id));
  };
  const addIndexToData = (data) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1,
    }));
  };
  const AddOnsItemsListData = addIndexToData(AddOnsItemsList);
  const columns = [
    // {
    //   name: "Id",
    //   selector: (row) => row.index,
    // },
    {
      name: "Name",
      selector: (row) =>
        <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>{row.itemName}</Tooltip>} >
          <span>{row.itemName}    </span>
        </OverlayTrigger>

    },
    {
      name: "Price",
      selector: (row) => row.price,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleAddoneNewItem(row, true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDelete(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  const FoodType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "FOODTY"
  );
  // const QuantityType = JSON.parse(localStorage.getItem("CommonFields")).filter(
  //   (item) => item.fieldTypeCode === "ORDQTYTY"
  // );

  const CatalogAddonsData = useSelector(getCatalogAddonsData);
  const ItemOptions = ItemCatalogData?.filter((addon) =>
    !addon.isDeleted && !CatalogAddonsData?.some((mappedAddon) => mappedAddon.id === addon.id)
  ).map((item) => ({
    value: item.id,
    label: item.catalogueName,
  }));

  const AssociateItem = (data) => {
    let mappingData = [];
    for (let i = 0; i < data.length; i++) {
      const DataObject = {
        isVisible: true,
        isDeleted: false,
        createdBy: user.id,
        modifiedBy: user.id,
        catalogueId: data[i].value,
        addonGroupId: AddOnsData?.id,
        mainCategoryId: Environment.defaultValue,
        categoryId: Environment.defaultValue,
        subCategoryId: Environment.defaultValue,
      };
      mappingData.push(DataObject);
    }
    dispatch(MapAddOnItem(mappingData));
  };
  const unMapAddons = (AddOns) => {
    const Data = {
      mappingId: AddOns,
      id: AddOnsData?.id,
    };
    dispatch(UnMapAddOnItem(Data));
  };
  const  handleModalClose =()=>{
    setActiveTab('itemList')
    props.closeFunction()
  }
  return (
    <React.Fragment>
      <AddonsNewItem
        show={showAddonsItem}
        id={AddOnsData?.id}
        data={itemData}
        FoodType={FoodType}
        QuantityType={AddOnsData?.groupTypeId}
        closeFunction={handleAddoneNewItem}
      />
      <Offcanvas
        show={props.show}
        onHide={handleModalClose}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>Edit Addons Group</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl="12">
              <div className="d-flex align-items-center">
                {/* <div className="me-3">
                  <img src={noImg} width={100} />
                </div> */}
                <div>
                  <Link to="" className="fs-18 text-dark fw-semibold">
                    {AddOnsData?.groupTitle}
                  </Link>
                  <h6 className="fs-12 mb-0">
                    {AddOnsData?.groupTypeId === "d9c5a952-911e-4a0f-8935-5e1fae01201e"? "Single Selection": "Multiple Selection "}
                  </h6>
                  <div className="d-flex align-items-center mt-3">
                    <Button
                      variant="danger"
                      className="d-flex align-items-center fs-14"
                      onClick={() => handleAddoneNewItem(null, true)}
                    >
                      <i className="ri-add-box-line fs-14 me-1"></i> Add Item
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <hr />

          <Tabs
            id="custom-styled-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="sale-on-point-tabs"
          >
            <Tab
              eventKey="itemList"
              title={
                <>
                  <h6 className="fs-14 mb-0">AddOns Item List</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                {AddOnsItemsList && AddOnsItemsList !== undefined && (
                  <div className="custom-common-table">
                  <DataTable
                    columns={columns}
                    data={AddOnsItemsListData}
                    fixedHeader
                    search={true}
                    highlightOnHover
                    pagination
                  ></DataTable>
                  </div>
                )}
                {!loading && AddOnsItemsList === undefined &&  <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />}
                {loading &&
                  value === "addonsItemList" &&
                  AddOnsItemsList === undefined &&  <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />}
              </div>
            </Tab>

            {/* DETAILS TAB */}
            <Tab
              eventKey="details"
              title={
                <>
                  <h6 className="fs-14 mb-0">Edit Details</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <EditAddons data={AddOnsData} />
              </div>
            </Tab>
            <Tab
              eventKey="mappedItems"
              title={
                <>
                  <h6 className="fs-14 mb-0">Mapped Catalogue Items</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <MappedItem
                  id={AddOnsData?.id}
                  ItemCatalogData={ItemOptions}
                  data={CatalogAddonsData}
                  associateItem={AssociateItem}
                  dissociateItem={unMapAddons}
                />
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
