import { RECEIPE_LIST_BY_CATALOGUE_ID_SUCCESS } from "../actions/receipeAction";
const initialState = {
  receipeListByCatalogueId: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIPE_LIST_BY_CATALOGUE_ID_SUCCESS:
      return {
        ...state,
        receipeListByCatalogueId: action.payload.result,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
