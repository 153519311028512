import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import Environment from "../../infrastructure/core/Environment";
import {debounce} from "lodash";

export default function Employees () {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);
    const [branchData, setBranchData] = useState(Environment.defaultValue);
    const handleBranchIdFromHeader = debounce((data) => {
      setBranchData(data);
    }, 300);
    return (
      <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader}/>
             <div className="main main-app p-3 p-lg-4">
             <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item"><Link to="#">Home</Link></li>
                            <li className="breadcrumb-item active" aria-current="page">Manage</li>
                        </ol>
                        <h4 className="main-title mb-0">Employees</h4>
                    </div>
                    {/* <div className="d-flex gap-2 mt-3 mt-md-0">
                        <Button variant="primary" className="d-flex align-items-center gap-2">
                            <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline"> Create New Item</span>
                        </Button>
                    </div> */}
                </div>
             </div>
        </React.Fragment>
    )
}