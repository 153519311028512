import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Offcanvas,
  Row,
  Col,
  Form,
  Table,
  Card,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AddInvoiceData } from "../../application/actions/recieptAction";
import { getVendorListByTenantId } from "../../application/selectors/indexSelector";
import { VendorList } from "../../application/actions/vendorAction";
import Environment from "../../infrastructure/core/Environment";
import AddVendor from "../Settings/Vendor/add-vendor";
import { Link } from "react-router-dom";

export default function AddInvoice(props) {
  const dispatch = useDispatch();
  const [dataObj, setDataObj] = useState({});
  const [line_items, setLineItemsObj] = useState([]);
  const [formData, setFormData] = useState({});
  const [calculationError, setCalculationError] = useState({});
  const [selectedVendor, setSelectedVendor] = useState({});

  const verifyTotalAmount = (data, line_items) => {
    const expectedAmount = line_items?.reduce((acc, item) => {
      return acc + (parseFloat(item.amount) || 0);
    }, 0);
    const totalExpectedAmount =
      Number(expectedAmount) +
      Number(data?.BalanceAmount ? data?.BalanceAmount : 0) +
      Number(data?.TotalTaxAmount ? data?.TotalTaxAmount : 0);
    const ActualAmount = Number(
      data?.TotalAmount ? data?.TotalAmount : data?.NetAmount
    );
    const error =
      parseFloat(ActualAmount?.toFixed(0)) !==
      parseFloat(totalExpectedAmount?.toFixed(0))
        ? "Calculation error"
        : "Verified";
    setCalculationError(error);
    console.log(
      parseFloat(ActualAmount?.toFixed(0)),
      parseFloat(totalExpectedAmount?.toFixed(0))
    );
  };

  const VendorsListData = useSelector(getVendorListByTenantId);
  useEffect(() => {
    dispatch(VendorList);
    if (props?.data) {
      setDataObj(props?.data);
      const initialFormData = {};
      if (props?.data) {
        Object?.keys(props?.data)?.forEach((key) => {
          if (props?.data[key]) {
            initialFormData[key] = props?.data[key];
          }
        });
        setLineItemsObj(props.data.Items);
        verifyTotalAmount(props?.data, props.data.Items);
        setFormData(initialFormData);
      }
    }
  }, [props?.data]);
  useEffect(() => {
    if (props?.data) {
    const matchedData = VendorsListData?.find(
      (data) =>
        data.vendorName?.toLowerCase() === formData?.SupplierName?.toLowerCase()
    );
    console.log(matchedData);
    setSelectedVendor(
      matchedData?.id ? matchedData?.id : Environment.defaultValue
    );}
  }, [props?.data, VendorsListData]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedData = { ...formData, [name]: value };
    setFormData({ ...formData, [name]: value });
    verifyTotalAmount(updatedData, line_items);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formObj = {
      delivery_date: formData.DeliveryDate,
      due_date: formData.DueDate,
      freight_amount: formData.FreightAmount,
      invoice_date: formData.InvoiceDate,
      invoice_id: formData.InvoiceId,
      line_items: formData.Items,
      net_amount: formData.NetAmount,
      gross_amount: formData.GrossAmount,
      payment_terms: formData.PaymentTerms,
      purchase_order: formData.PurchaseOrder,
      receiver_address: formData.ReceiverAddress,
      receiver_email: formData.ReceiverEmail,
      receiver_name: formData.ReceiverName,
      receiver_phone: formData.ReceiverPhone,
      receiver_tax_id: formData.ReceiverTaxId,
      receiver_website: formData.ReceiverWebsite,
      remit_to_address: formData.RemitToAddress,
      remit_to_name: formData.RemitToName,
      ship_from_address: formData.ShipFromAddress,
      ship_from_name: formData.ShipFromName,
      ship_to_address: formData.ShipToAddress,
      ship_to_name: formData.ShipToName,
      supplier_address: formData.SupplierAddress,
      supplier_email: formData.SupplierEmail,
      supplier_iban: formData.SupplierIban,
      supplier_name: formData.SupplierName,
      supplier_payment_ref: formData.SupplierPaymentRef,
      supplier_phone: formData.SupplierPhone,
      supplier_registration: formData.SupplierRegistration,
      supplier_tax_id: formData.SupplierTaxId,
      supplier_website: formData.SupplierWebsite,
      total_amount: formData.TotalAmount,
      total_tax_amount: formData.TotalTaxAmount,
      amount_paid_since_last_invoice: formData.AmountPaidSinceLastInvoice,
      balance_amount: formData.BalanceAmount,
      payment_status: formData.PaymentStatus,
      tax: formData.Tax,
    };
    console.log("Form submitted with data:", formObj);
    // const updatedData = { ...formData, line_items: formData.Items };
    dispatch(AddInvoiceData(formObj));
    props?.closeFunction(false);
  };

  const [editIndex, setEditIndex] = useState(null);
  const [editItem, setEditItem] = useState(null);

  const handleEditClick = (index, item) => {
    if (editIndex === index) {
      const updatedItems = [...line_items];
      updatedItems[editIndex] = editItem;
      setLineItemsObj(updatedItems);
      verifyTotalAmount(formData, updatedItems);
      setEditIndex(null);
    } else {
      setEditIndex(index);
      setEditItem({ ...item });
    }
  };

  const handleInputChange = (e, field) => {
    setEditItem({ ...editItem, [field]: e.target.value });
  };

  const handleVendorChange = (e) => {
    setSelectedVendor(e.target.value);
  };

  const handleDeleteItem = (indexToDelete) => {
    const newLineItems = [...line_items];
    newLineItems.splice(indexToDelete, 1);
    verifyTotalAmount(formData, newLineItems);
    setLineItemsObj(newLineItems);
  };

  const handleAddItem = () => {
    const newItem = {
      description: "",
      quantity: "",
      unit: "",
      unit_price: "",
      amount: "",
    };
    setLineItemsObj((lineItems) => {
      const updatedItems = [...lineItems, newItem];
      setEditIndex(updatedItems.length - 1);
      setEditItem({ ...newItem });
      return updatedItems;
    });
  };

  const [showVendor, setShowVendor] = useState(false);
  const handleAddVendor = (state) => {
    setShowVendor(state);
  };
  console.log(dataObj);
  // if (!dataObj) return <div>Loading...</div>;

  return (
    <React.Fragment>
      <AddVendor show={showVendor} closeFunction={handleAddVendor} />
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Add New Invoice
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Invoice Date</Form.Label>
                <Form.Control
                  type="text"
                  name="InvoiceDate"
                  value={formData.invoice_date}
                  onChange={handleChange}
                />
              </div>
            </Col>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Invoice Id</Form.Label>
                <Form.Control
                  type="text"
                  name="InvoiceId"
                  value={formData.invoice_id}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Vendor Name</Form.Label>
                <Form.Select
                  className="ht-28 lh-2"
                  name="SupplierName"
                  value={selectedVendor}
                  onChange={handleVendorChange}
                >
                  <option value={Environment.defaultValue} disabled>
                    Choose Vendor
                  </option>
                  {VendorsListData?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.vendorName}
                    </option>
                  ))}
                </Form.Select>
                <Link onClick={() => handleAddVendor(true)}>
                  Add New Vendor +
                </Link>
              </div>
            </Col>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Total Amount</Form.Label>
                <Form.Control
                  type="text"
                  name="TotalAmount"
                  value={
                    formData.TotalAmount
                      ? formData.TotalAmount
                      : formData.NetAmount
                  }
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>
          <Row>
            {Object.keys(formData)
              .filter(
                (key) =>
                  !/^(Items|Tax|InvoiceId|InvoiceDate|SupplierName|TotalAmount)$/.test(
                    key
                  )
              )
              .map((key, index) => (
                <Col xl={6} key={index}>
                  <div className="mb-4">
                    <Form.Label>
                      {key.replace(/([a-z])([A-Z])/g, "$1 $2").toUpperCase()}
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name={key}
                      placeholder={`Enter ${key}`}
                      value={formData[key]}
                      onChange={handleChange}
                    />
                  </div>
                </Col>
              ))}
          </Row>

          <Card className="card-one h-auto mb-3">
            <Card.Header className="align-items-center justify-content-between">
              <h3 className="fs-14">Items</h3>
              <Button variant="primary" onClick={handleAddItem}>
                Add
              </Button>
            </Card.Header>
            <Card.Body>
              {line_items && (
                <div className="line-items">
                  <Table>
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Unit</th>
                        <th>Unit Price</th>
                        <th>Amount</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {line_items?.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {editIndex === index ? (
                              <Form.Control
                                type="text"
                                value={editItem.description}
                                onChange={(e) =>
                                  handleInputChange(e, "description")
                                }
                              />
                            ) : (
                              item.description
                            )}
                          </td>
                          <td>
                            {editIndex === index ? (
                              <Form.Control
                                type="text"
                                value={editItem.quantity}
                                onChange={(e) =>
                                  handleInputChange(e, "quantity")
                                }
                              />
                            ) : (
                              item.quantity
                            )}
                          </td>
                          <td>
                            {editIndex === index ? (
                              <Form.Control
                                type="text"
                                value={editItem.unit}
                                onChange={(e) => handleInputChange(e, "unit")}
                              />
                            ) : (
                              item.unit
                            )}
                          </td>
                          <td>
                            {editIndex === index ? (
                              <Form.Control
                                type="text"
                                value={editItem.unit_price}
                                onChange={(e) =>
                                  handleInputChange(e, "unit_price")
                                }
                              />
                            ) : (
                              item.unit_price
                            )}
                          </td>
                          <td>
                            {editIndex === index ? (
                              <Form.Control
                                type="text"
                                value={editItem.amount}
                                onChange={(e) => handleInputChange(e, "amount")}
                              />
                            ) : (
                              item.amount
                            )}
                          </td>
                          <td className="w-0">
                            <div className="d-flex justify-content-end">
                              <Button
                                variant="primary"
                                className="btn-icon me-2"
                                onClick={() => handleEditClick(index, item)}
                              >
                                <i
                                  className={
                                    editIndex === index
                                      ? "ri-add-line"
                                      : "ri-pencil-line"
                                  }
                                ></i>
                              </Button>
                              <Button
                                variant="outline-danger"
                                className="btn-icon"
                                onClick={() => handleDeleteItem(index)}
                              >
                                <i className="ri-delete-bin-line"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              )}
            </Card.Body>
          </Card>

          <Card className="card-one h-auto">
            <Card.Header className="align-items-center justify-content-between">
              <h3 className="fs-14">Tax</h3>
              <Button variant="primary" onClick={handleAddItem}>
                Add
              </Button>
            </Card.Header>
            <Card.Body>
              {dataObj?.tax && (
                <Table>
                  <thead>
                    <tr>
                      <th>Tax Type</th>
                      <th>Tax Amount</th>
                      <th>Tax Rate</th>
                      <th>Total Taxable Amount</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataObj?.tax?.map((item, index) => (
                      <tr key={index}>
                        <td>{item.taxtype}</td>
                        <td>{item.taxamount}</td>
                        <td>{item.taxrate}</td>
                        <td>{item.totaltaxableamount}</td>
                        <td className="w-0">
                          <div className="d-flex justify-content-end">
                            <Button variant="primary" className="btn-icon me-2">
                              <i className="ri-pencil-line"></i>
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className={`fs-14 me-2 d-flex align-items-center ${
              calculationError === "Calculation error" ? "disabled" : ""
            }`}
            onClick={handleSubmit}
            disabled={calculationError === "Calculation error"}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Submit</span>
          </Button>
          <Form.Text
            className={
              calculationError === "Calculation error"
                ? "text-danger"
                : "text-success"
            }
          >
            {calculationError}
          </Form.Text>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
