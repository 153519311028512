import React, { useEffect } from "react";
import Prism from "prismjs";
import { useSelector } from "react-redux";
import { getLoading } from "../../../application/selectors/indexSelector";
import { Button, Card, Table, Form } from "react-bootstrap";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import daysWiseList from "../../../Json/Attendance/days-wsie.json"

export default function DaysWiseReport() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const { loading, value } = useSelector(getLoading);

    const days = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"]

    return (
        <React.Fragment>
            <div className="mb-4">
                <Button variant="success" className="fs-14" type="button">
                    <i className="ri-file-excel-2-line align-middle fs-18 me-2"></i> <span className="align-middle">Download Report</span>
                </Button>
            </div>

            <Card className="card-one">
                <Card.Body>
                    <div className="d-md-flex align-items-center justify-content-between mb-3">
                        <div>
                            <h4 className="main-title fs-14 mb-0">Days Wise Report</h4>
                        </div>
                    </div>

                    {daysWiseList && daysWiseList !== undefined && (
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    {days?.map((list, index) => (
                                        <th key={index}>{list}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {daysWiseList?.map((item, index) => (
                                    <tr key={index}>
                                        <td><b>{item.name}</b></td>
                                        <td className={`${item.paidLeave == 'PL' ? "paid-leave-bg" : ''}`}>{item.paidLeave}</td>
                                        <td className={`${item.unpaidLeave == 'UL' ? "unpaid-leave-bg" : ''}`}>{item.unpaidLeave}</td>
                                        <td className={`${item.present == 'P' ? "present-bg" : ''}`}>{item.present}</td>
                                        <td className={`${item.absent == 'A' ? "absent-bg" : ''}`}>{item.absent}</td>
                                        <td className={`${item.halfLeave == 'HL' ? "halfDay-leave-bg" : ''}`}>{item.halfLeave}</td>
                                        <td className={`${item.workOff == 'WO' ? "work-off-bg" : ''}`}>{item.workOff}</td>
                                        <td></td>
                                        <td className={`${item.halfLeave == 'HL' ? "halfDay-leave-bg" : ''}`}>{item.halfLeave}</td>
                                        <td></td>

                                        <td></td>
                                        <td></td>
                                        <td className={`${item.present == 'P' ? "present-bg" : ''}`}>{item.present}</td>
                                        <td className={`${item.absent == 'A' ? "absent-bg" : ''}`}>{item.absent}</td>
                                        <td></td>
                                        <td className={`${item.halfLeave == 'HL' ? "halfDay-leave-bg" : ''}`}>{item.halfLeave}</td>
                                        <td></td>
                                        <td></td>
                                        <td className={`${item.workOff == 'WO' ? "work-off-bg" : ''}`}>{item.workOff}</td>
                                        <td></td>

                                        <td className={`${item.paidLeave == 'PL' ? "paid-leave-bg" : ''}`}>{item.paidLeave}</td>
                                        <td className={`${item.unpaidLeave == 'UL' ? "unpaid-leave-bg" : ''}`}>{item.unpaidLeave}</td>
                                        <td className={`${item.present == 'P' ? "present-bg" : ''}`}>{item.present}</td>
                                        <td className={`${item.absent == 'A' ? "absent-bg" : ''}`}>{item.absent}</td>
                                        <td className={`${item.halfLeave == 'HL' ? "halfDay-leave-bg" : ''}`}>{item.halfLeave}</td>
                                        <td className={`${item.workOff == 'WO' ? "work-off-bg" : ''}`}>{item.workOff}</td>
                                        <td></td>
                                        <td className={`${item.absent == 'A' ? "absent-bg" : ''}`}>{item.absent}</td>
                                        <td></td>
                                        <td className={`${item.paidLeave == 'PL' ? "paid-leave-bg" : ''}`}>{item.paidLeave}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    )}
                    {!loading && daysWiseList === undefined && (
                        <TableShimmerLoader colNames={daysWiseList?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading &&
                        value === "daysWiseList" &&
                        daysWiseList === undefined && (
                            <TableShimmerLoader colNames={daysWiseList?.map((column) => column.name)} colsCount={1} />
                        )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}