import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import verientIcon from "../../../assets/img/icons/varient-icon.svg";
import warningErr from "../../../assets/img/icons/warningErr.png";
import "./search-items.scss";
import OrderEmptyState from "../../_emptyState/order-empty";

export default function SearchItems(props) {
  const ItemCataloguelist = JSON.parse(localStorage.getItem("Catalogues")) || [];
  const [searchedItems, setSearchedItems] = useState(ItemCataloguelist);
  const todaysDay = new Date().toLocaleDateString("en-US", { weekday: "long" });
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    const searchValue = props?.searchItem?.toLowerCase();
    const matchingItems = ItemCataloguelist.filter((item) => {
      return (
        (item?.catalogueName?.toLowerCase()?.includes(searchValue) || item?.catalogueCode?.toLowerCase()?.includes(searchValue)) &&
        item?.status === "9251e44c-dda2-40d9-8d96-b392d974331b" && (item.visibility === "73649d84-d590-40b9-8971-28b830acacd5" ||
          item.visibility === "73649d84-d590-40b9-8971-28b830acacd6")
      );
    });
    setSearchedItems(matchingItems);
  }, [props?.searchItem]);
  return (
    <React.Fragment>
      <h3 className="fs-14 text-dark pt-2 pb-2 px-3">Sub Menus:</h3>
      <div className="searchable-items-view">
        <Row className="m-0">
          {searchedItems && searchedItems.length !== 0 ? (
            <>
              {searchedItems?.map((item, index) => (
                <Col sm={6} lg={3} md={4} className="mb-3" key={index}>
                  <Card
                    className={`card-one mb-3 variant-card ${
                      item?.sizeVariance?.length === 0 ||
                      (item?.isDaySpeacial &&
                        !item?.catalogueOnDays?.some(
                          (day) => day.dayName === todaysDay
                        ))
                        ? "disabled-variant"
                        : ""
                    }`}
                  >
                    <Card.Body className="p-0">
                      <Link
                        className="d-block h-100 text-dark p-2"
                        onClick={() =>
                          item?.sizeVariance?.length === 0 ||
                          (item?.isDaySpeacial &&
                            !item?.catalogueOnDays?.some(
                              (day) => day.dayName === todaysDay
                            ))
                            ? ""
                            : props.show(true, item, props?.selectedTable?.id)
                        }
                      >
                        <div className="indicator-varient">
                          <span
                            className={`indicator d-block me-2 ${
                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" : 
                              item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" : 
                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" : 
                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                            }`}
                          ></span>
                          {item?.addOnGroups?.filter(
                            (addonItems) =>
                              addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e" ).length > 0 && (
                              <img src={verientIcon} alt="varient" className="wt-18" />
                          )}
                          {item?.sizeVariance?.length === 0 && (
                            <img src={warningErr} alt="varient" className="wt-22" />
                          )}
                          {item?.isDaySpeacial &&
                            !item?.catalogueOnDays?.some(
                              (day) => day.dayName === todaysDay
                            ) && (
                              <span className="badge badge-pill bg-danger ms-2">Not available today</span>
                            )}
                        </div>

                        <h3>{item.catalogueName}</h3>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </>
          ) : (
            <>
              <OrderEmptyState />
            </>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
}
