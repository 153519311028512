import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Form } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { MaterialList } from "../../../../application/actions/materialAction";
import {
  getMaterialList,
  getVarianceList,
} from "../../../../application/selectors/indexSelector";
import { CreateReceipe } from "../../../../application/actions/receipeAction";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import { useParams } from "react-router-dom";
import environment from "../../../../infrastructure/core/Environment";

export function AddRecipeItem(props) {
  const dispatch = useDispatch();
  const materialList = useSelector(getMaterialList);
  const VarianceList = useSelector(getVarianceList);
  const globalVarince = VarianceList?.globalVariances || [];
  const MeasurementList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "MSRMNT"
      )
    : [];
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  useEffect(() => {
    dispatch(MaterialList);
  }, []);

  const initialState = {
    tenantId: "",
    branchId: "",
    isVisible: true,
    isDeleted: false,
    createdBy: "",
    rawMaterialId: "",
    catalogueId: "",
    measuredIn: `${environment.defaultValue}`,
    sizes: [],
  };
  const [formData, setFormData] = useState(initialState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleMaterialChange = (materialObject) => {
    setFormData({ ...formData, rawMaterialId: materialObject.id });
    setFormData((prevFormData) => ({
      ...prevFormData,
      measuredIn: materialObject ? materialObject.stockMeasuredIn : null,
    }));
  };

  const handleSubmit = () => {
    const sizes = globalVarince.map((list, index) => ({
      varianceId: list.id,
      values: Number(formData[`quantity-${index}`] || 0),
    }));

    const formObject = {
      tenantId: user?.tenanatId,
      branchId: user.branchId,
      isVisible: formData.isVisible,
      isDeleted: formData.isDeleted,
      createdBy: user.id,
      rawMaterialId: formData.rawMaterialId,
      catalogueId: id,
      measuredIn: formData.measuredIn,
      sizes,
    };

    dispatch(CreateReceipe(formObject));
    setFormData(initialState);
    props.closeFunction(false);
  };

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Recipe Item</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="mb-3">
            <Form.Label>
              Choose Item Name: <sup className="text-danger">*</sup>
            </Form.Label>
            <Select
              name="rawMaterialId"
              options={materialList}
              isSearchable={true}
              getOptionLabel={(option) => option.productName}
              getOptionValue={(option) => option.id}
              onChange={handleMaterialChange}
              placeholder="Choose raw material"
            />
          </div>
          <div className="mb-3">
            <Form.Label>
              Measured In: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="measuredIn"
              value={formData.measuredIn}
              onChange={handleChange}
              disabled
            >
              <option value={`${environment.defaultValue}`}>
                Choose measured in
              </option>
              {MeasurementList.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.fieldValue}
                </option>
              ))}
            </Form.Select>
          </div>

          <hr />

          <h4 className="fs-14 fw-semibold mb-3">
            Measure as per each variance:
          </h4>
          {globalVarince?.map((list, index) => (
            <div className="mb-3" key={index}>
              <Form.Label>{list.variantName}</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Qty"
                name={`quantity-${index}`} // Unique name for each input
                value={formData[`quantity-${index}`] || ""} // Ensure each input has its own state
                onChange={handleChange}
              />
            </div>
          ))}
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-16 lh-1 align-middle me-1"></i>
            <span className="align-middle">Create New Recipe</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
