import {
  CATEGORY_LIST_SUCCESS,
  CREATE_MENU_FAILURE,
  MENU_DETAILS_SUCCESS,
  MENU_LIST_SUCCESS,
} from "../actions/menuAction";

const initialState = {
  MenuList: undefined,
  MenuDetails:{},
  CategoryList:undefined,
  error: null,
  CreateMenuFail:undefined
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_LIST_SUCCESS:
      return { ...state, MenuList: action.payload.data.result, error: null };
    case CATEGORY_LIST_SUCCESS:
      return {...state, CategoryList: action.payload.data, error: null, };
      case MENU_DETAILS_SUCCESS:
      return { ...state, MenuDetails: action.payload.data, error: null };
        case CREATE_MENU_FAILURE:
      return { ...state, CreateMenuFail: action.payload, error: null };
    
    default:
      return state;
  }
};

export default reducer;
