import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Prism from "prismjs";
import { format } from "date-fns";

import Header from "../../../layouts/Header";
import { Link } from "react-router-dom";
import { Badge, Button, Card } from "react-bootstrap";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import DataTable from "react-data-table-component";

import AddNewCharges from "./add-charges";
import EditCharges from "./edit-charges";

import {
  getChargesList,
  getLoading,
} from "../../../application/selectors/indexSelector";
import { loadCharges } from "../../../application/actions/loaderAction";
import {
  DeleteCharges,
  GetChargesDetailsSuccess,
} from "../../../application/actions/chargesAction";
import { debounce } from "lodash";
import Environment from "../../../infrastructure/core/Environment";

export default function Charges() {
  const dispatch = useDispatch();

  const branch = JSON.parse(localStorage.getItem("branch"));
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";

  const [skin, setSkin] = useState(currentSkin);
  const [showAddCharges, setShowAddCharges] = useState(false);
  const [showEditCharges, setShowEditCharges] = useState(false);

  const { loading, value } = useSelector(getLoading);
  const chargeslist = useSelector(getChargesList);

  const addIndexToData = (data) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1,
    }));
  };
  const chargeListData = addIndexToData(chargeslist);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");
      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );
      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  switchSkin(skin);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(loadCharges);
  }, []);

  const handleAddCharges = (state) => {
    setShowAddCharges(state);
  };
  const handleEditCharges = (row) => {
    dispatch(GetChargesDetailsSuccess(row));
    setShowEditCharges(true);
  };
  const handleDeleteCharges = (row) => {
    if (row) {
      const data = {
        chargeId: row,
        branchId: branch?.value,
      };
      dispatch(DeleteCharges(data));
    }
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => <div>{row.index}</div>,
      width: "100px",
    },
    {
      name: "Charges Name",
      selector: (row) => <Link className="fs-14 fw-semibold" onClick={() => handleEditCharges(row)}>{row.chargeName}</Link>,
    },
    {
      name: "Type",
      selector: (row) => row.chargeTypeDesc,
    },
    {
      name: "Auto Apply",
      selector: (row) => (
        <Badge
          bg={`${row.isAutoPay ? "success" : "danger"}`}
          className="fs-14 ht-34 lh-2 wt-100"
          pill
        >
          {row.isAutoPay ? "Yes" : "No"}
        </Badge>
      ),
      width: "150px",
    },

    {
      name: "Locations",
      selector: (row) =>
        row.chargeMappings?.length > 1
          ? row.chargeMappings?.length + " Locations"
          :  "All Locations",
    },
    {
      name: "Created On",
      selector: (row) => (
        <span> {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")}</span>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          {/* <Button variant="secondary" className="btn-icon me-2">
            <i className="ri-eye-line"></i>
          </Button> */}
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleEditCharges(row)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDeleteCharges(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);
  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <AddNewCharges
        show={showAddCharges}
        closeFunction={handleAddCharges}
        detailsModal={() => setShowEditCharges(true)}
      />
      <EditCharges
        show={showEditCharges}
        // data={showdata}
        closeFunction={() => setShowEditCharges(false)}
      />
      <div className="main main-app p-3 p-lg-4">
        <div className="top-header d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Charges</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handleAddCharges(true)}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline"> Add New Charge</span>
            </Button>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body className="custom-common-table">
            {chargeslist && chargeslist !== undefined && (
              <DataTable
                columns={columns}
                data={chargeListData}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
            {!loading && chargeslist === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
            {loading &&
              value === "chargesList" &&
              chargeslist === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
