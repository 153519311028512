import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { CreateAddOnsItem } from "../../../application/actions/addOnsAction";
import environment from "../../../infrastructure/core/Environment";
import { restrictAllNumbers, restrictAllSpecialCharacters, restrictConsecutiveSpace, restrictOnlyNumber, validateRequired, validateRequiredDropdown } from "../../../infrastructure/core/validationUtils";
import Environment from "../../../infrastructure/core/Environment";

export default function AddonsNewItem(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();
  const [selectedFoodType, setSelectedFoodType] = useState(
    `${environment.defaultValue}`
  );
  const FoodType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "FOODTY"
  );
  const FoodTypeArray = FoodType?.map((type, index) => ({
    value: type.id,
    label: type.fieldValue,
  }));
  const FoodSubType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "FUDSUBTY"
  );
  const initialState = {
    itemName: "",
    itemDesc: "",
    price: 0,
    isQuantityAllowed: false,
    maxItemAllowed: '\u221E',
    foodTypeId: Environment.defaultValue,
    foodSubType:Environment.defaultValue,
    addOnGroupId: props?.id,
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (props?.id) {
      setFormData({ ...formData, addOnGroupId: props?.id });
    }
  }, [props?.id]);
  useEffect(() => {
    if (props?.data) {
      setFormData({
        ...formData,
        id: props?.data?.id,
        itemName: props?.data?.itemName,
        itemDesc: props?.data?.itemDesc,
        price: props?.data?.price,
        addOnGroupId: props?.data?.addOnGroupId,
        foodTypeId:props?.data?.foodTypeId,
        foodSubType:props?.data?.subFoodTypeId,
        isQuantityAllowed:props?.data?.isQuantityAllowed
      });
      const matchedFoodType = FoodTypeArray?.find(
        (item) => item.value === props?.data?.foodTypeId
      );
      setSelectedFoodType(matchedFoodType);
      if (props?.data?.foodTypeId === "a634316f-857d-41fd-a74c-895f61e4de65") {
        setShowSubTyppe(true);
      } else {
        setShowSubTyppe(false);
      }
    } else {
      setFormData(initialState);
    }
  }, [props?.data]);

  const handleFoodTypeChange = (selectedValue) => {
    const data = FoodType.filter((item) => item.id === selectedValue.value);
    if (data[0].fieldValue == "Non-Veg") {
      setShowSubTyppe(true);
    } else {
      setShowSubTyppe(false);
    }
    setSelectedFoodType(selectedValue);
    setFormData({
      ...formData,
      foodTypeId: selectedValue.value,
      foodSubType: `${environment.defaultValue}`,
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      foodTypeId: "",
    }));
  };
  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.itemName = validateRequired(formData.itemName)
      ? ""
      : "AddOns Item Name is required";
    // Validate mainCatId (dropdown)
    newErrors.foodTypeId = validateRequiredDropdown(formData.foodTypeId)
      ? ""
      : "Please Select Food Type";
      if (formData.foodTypeId === "a634316f-857d-41fd-a74c-895f61e4de65") {
        // Validate foodSubType (dropdown)
        newErrors.foodSubType = validateRequiredDropdown(formData.foodSubType)
          ? ""
          : "Please Select Food Sub Type";
      }
      setErrors(newErrors);
      // Check if any field has errors
      return !Object.values(newErrors).some((error) => error);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
        // Validate the form
        const isValid = validateInput();
        if (!isValid) {
          return;
        }
    dispatch(CreateAddOnsItem({...formData,maxItemAllowed:formData.maxItemAllowed==='∞'?9999:formData.maxItemAllowed}));
    setFormData(initialState);
    setSelectedFoodType(`${environment.defaultValue}`);
    setShowSubTyppe(false);
    props.closeFunction(false);
  };
  const [showSubTyppe, setShowSubTyppe] = useState(false);
  const handleMaxAllowedQtyChange = (e) => {
    let newValue = e.target.value;

    // Remove any non-numeric characters except the infinity symbol
    newValue = newValue.replace(/[^0-9]/g, '');

    // Ensure the value is not empty
    if (newValue === '') {
      newValue = '∞'; // Set back to infinity symbol if value becomes empty
    }else if (parseInt(newValue) === 0) {
      newValue = '1'; // Set to minimum allowed value if input is '0'
    }
    setFormData({
      ...formData,
      maxItemAllowed: newValue,
    });
  };
  
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-45"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>
            {props?.data?.id ? "Update Item" : "Create New Item"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Item Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Item Name"
                  name="itemName"
                  value={formData.itemName}
                  onChange={(e) => {
                    setFormData({ ...formData, itemName: e.target.value });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      itemName: "",
                    }));
                  }}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}
                />
                 <Form.Text className="text-danger">
                  {errors.itemName}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  placeholder="Enter Description"
                  name="itemDesc"
                  value={formData.itemDesc}
                  onChange={(e) => {
                    setFormData({ ...formData, itemDesc: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                  }}
                />
              </div>
            </Col>
          </Row>
          {props?.QuantityType !== "d9c5a952-911e-4a0f-8935-5e1fae01201e" && (
            <>
              <Row>
                <Col xl="12">
                  <div className="mb-4">
                    <Form.Check
                      type="switch"
                      className="me-3 fs-14 fw-semibold mt-2"
                      name="isQuantityAllowed"
                      checked={formData.isQuantityAllowed}
                      label="Allow to increase quantity"
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          isQuantityAllowed: e.target.checked,
                        });
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </>
          )}
          {formData.isQuantityAllowed && (
            <Row>
              <Col xl="6">
                <div className="mb-4">
                  <Form.Label>
                    Max Quantity Allowed: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                     type="text"
                    name="maxItemAllowed"
                    value={formData.maxItemAllowed}
                    onChange={handleMaxAllowedQtyChange}
                    onKeyDown={restrictOnlyNumber}
                  />
                </div>
                <h6 className="fs-14 mt-2 text-dark">
                    {formData.maxItemAllowed=== '∞'? 'Unlimited':''}</h6>
              </Col>
            </Row>
          )}
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Food Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <Select
                  options={FoodTypeArray}
                  isSearchable={true}
                  name="foodTypeId"
                  value={selectedFoodType}
                  onChange={handleFoodTypeChange}
                />
                 <Form.Text className="text-danger">
                  {errors.foodTypeId}
                </Form.Text>
              </div>
            </Col>
            {showSubTyppe && (
              <Col xl="6">
                <Form.Label>
                  Sub Food Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <div className="mb-4">
                  <Form.Select
                    aria-label="Default select example"
                    defaultValue={Environment.defaultValue}
                    value={formData.foodSubType}
                    name="foodSubType"
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        foodSubType: e.target.value,
                      });
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        foodSubType: "",
                      }));
                    }}
                  >
                    <option value={`${Environment.defaultValue}`} disabled>
                      Sub Food Type
                    </option>
                    {FoodSubType?.map((option, index) => (
                      <option key={index} value={option.id}>
                        {option.fieldValue}
                      </option>
                    ))}
                  </Form.Select>
                  <Form.Text className="text-danger">
                  {errors.foodSubType}
                </Form.Text>
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col xl="6">
              <div>
                <Form.Label>
                  Price: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="number" min="1"
                  name="price"
                  value={formData.price}
                  onChange={(e) => {
                    setFormData({ ...formData, price: e.target.value });
                  }}
                  onKeyDown={restrictOnlyNumber}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14"
            type="submit"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">
              {props?.data?.id ? "Update" : "Create"}
            </span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
