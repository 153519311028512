// import { format } from 'date-fns'
// import React from 'react'
// import { Badge, Button, Table } from 'react-bootstrap'

// function OutForDelivery(props) {
//   return (
//     <React.Fragment>
//             <Table className="mb-0" responsive>
//                 <thead>
//                     <tr>
//                         <th>Order ID</th>
//                         <th>Type</th>
//                         <th>Status</th>
//                         <th>Payment Mode</th>
//                         <th>Bill Amount</th>
//                         <th>Seller</th>
//                         <th>Last Updated</th>
//                         <th>Action</th>
//                     </tr>
//                 </thead>
//                 <tbody>
//                     {props?.OutForDeliveryList?.map((item, index) => (
//                         <tr key={index}>
//                 <td>     {`O-${item.orderNumber.substring(
//                           2,
//                           8
//                         )}/${item.orderNumber.substring(
//                           item.orderNumber.lastIndexOf("/") + 1
//                         )}`}</td> {/* Apply the maskOrderNumber function */}
//                             <td>{item.orderTypeDesc}</td>
//                             {/* <td><b>{item.tableName}</b></td> */}
//                             <td> <Badge className={`${item.orderStatus == "a5ca48b8-bfd1-42d6-8db1-7021c131e601" ? "bg-danger" : ""}${item.orderStatus == "A5CA48B8-BFD1-42D6-8DB1-7021C131E606" ? "bg-primary" : ""}`} pill>{item.statusDesc}</Badge></td>
//                             <td><b>{item.paymentModeDesc}</b></td>
//                             <td><b>{parsedBranchData?.currencySymbol} {item.billAmount}</b></td>
//                             <td>{item.sellerName}</td>
//                             <td>
//                                 <h6 className="mb-0 fw-semibold">{format(new Date(item.modifiedOn), "dd MMM yyyy hh:mm a")}</h6>
//                                 <span className="fs-12 text-dark">{item.modifiedByName}</span>
//                             </td>
//                             <td className="w-0">
//                                 <div className="d-flex justify-content-end">
//                                     <Button variant="secondary" className="btn-icon me-2"><i className="ri-eye-line"></i></Button>
//                                     <Button variant="outline-dark" className="btn-icon"><i className="ri-printer-line"></i></Button>
//                                 </div>
//                             </td>
//                         </tr>
//                     ))}
//                 </tbody>
//             </Table>
//         </React.Fragment>
//   )
// }

// export default OutForDelivery
import { format } from 'date-fns'
import React, { useContext } from 'react'
import { useSelector } from 'react-redux';
import { getLoading } from '../../../application/selectors/indexSelector';
import { Badge, Button, Table } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import AuthContext from '../../../infrastructure/core/helpers/AuthContext';
import TableShimmerLoader from '../../../layouts/ShimmerTable';

function OutForDelivery(props) {
  const { loading, value } = useSelector(getLoading);

  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const addIndexToData = (data) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1, // Adding 1 to start indexing from 1
    }));
  };
  const TableData = addIndexToData(props?.OutForDeliveryList);
  const columns = [
    {
      name: "Order ID",
      selector: (row) => (
        <div>{`O-${row.orderNumber.substring(2, 8)}/${row.orderNumber.substring(
          row.orderNumber.lastIndexOf("/") + 1
        )}`}</div>
      ),
    },
    {
      name: "Type",
      selector: (row) => row.orderTypeDesc,
    },
    {
      name: "Status",
      selector: (row) => (
        <Badge className={`${row.orderStatus == "a5ca48b8-bfd1-42d6-8db1-7021c131e601" ? "bg-danger" : ""}${row.orderStatus == "A5CA48B8-BFD1-42D6-8DB1-7021C131E606" ? "bg-primary" : ""}`} pill>{row.statusDesc}</Badge>
      ),
    },
    {
      name: "Bill Amount",
      selector: (row) => <b>{parsedBranchData?.currencySymbol}{row.billAmount}</b>,
    },
    {
      name: "Payment Mode",
      selector: (row) => (
        <b>{row.paymentModeDesc}</b>
      ),
    },
    {
      name: "Seller Name",
      selector: (row) => row.sellerName,
    },
    {
      name: "Last Updated",
      selector: (row) => (
        <div>
          <h6 className="mb-0 fw-semibold">
            {format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a")}
          </h6>
          <span className="fs-12 text-dark">{row.modifiedByName}</span>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button variant="secondary" className="btn-icon me-2">
            <i className="ri-eye-line"></i>
          </Button>
          <Button variant="outline-dark" className="btn-icon">
            <i className="ri-printer-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  return (
    <React.Fragment>
      {TableData && TableData !== undefined && (
        <div className="custom-common-table">
          <DataTable
            columns={columns}
            data={TableData}
            fixedHeader
            search={true}
            highlightOnHover
            pagination
          ></DataTable>
        </div>
      )}
      {!loading && TableData === undefined && (
        <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
      )}
      {loading &&
        value === "inprogresslist" &&
        TableData === undefined && (
          <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
        )}
    </React.Fragment>
  );
}

export default OutForDelivery