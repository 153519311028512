export const getLoading = (state) => state.loader;
// table management selectors
export const getSectionsList = (state) => state.table.SectionData;
export const getTablesList = (state) => state.table.TableData;

//item catalog
export const getItemCatalogData = (state) => state.item.ItemCatalogData;
export const getItemCatalogPaginationData = (state) =>
  state.item.ItemCatalogPaginationData;
export const getCatalogDataById = (state) => state.item.CatalogDataById;
export const getCatalogDataByCatId = (state) => state.item.CatalogDataByCatId;
export const getPriceListById = (state) => state.item.PriceList;
export const getCatalogueCodes = (state) => state.item.CatalogueCodes;
export const getCatalogFeature = (state) => state.item.CatalogFeature;
export const getCatalogId = (state) => state.item.CatalogId;
// size varients common fields

// export const CreateCatalogdat = (state)=>state.item.CreateCatalogData
export const AddOnsItemList = (state) => state.item.AddOnsItemList;
export const getAddOnsData = (state) => state.addOns.AddOnsData;
// export const UpdateCatalogdata = (state)=>state.menu.UpdateCatalogdata

// menu
export const getMenuList = (state) => state.menu.MenuList;
export const getCreateMenuFail = (state) => state.menu.CreateMenuFail;
export const getMenuDetails = (state) => state.menu.MenuDetails;
export const getCategoryList = (state) => state.menu.CategoryList;

//Kitchenlist
export const getKitchenList = (state) => state.kitchen.KitchenList;
export const getKitchenDetails = (state) => state.kitchen.KitchenDetails;

//tax
export const getTaxList = (state) => state.tax.taxList;
export const getTaxListByTenant = (state) => state.tax.taxListByTenant;
export const getTaxDetails = (state) => state.tax.taxDetails;

///discount
export const getDiscountList = (state) => state.discount.DiscountList;
export const getDiscountDataById = (state) => state.discount.DiscountData;

///coupon
export const getCouponList = (state) => state.discount.CouponList;
export const getCouponDataById = (state) => state.discount.CouponData;

//addons
export const getAllAddOnsList = (state) => state.addOns.AddOnsList;
export const getAllAddOnsItem = (state) => state.addOns.AddOnsItem;
export const getCatalogAddonsData = (state) => state.addOns.CatalogAddonsData;
export const getAddOnsGroupError = (state) => state.addOns.AddOnsGroupError;

// common Fields
export const getFoodType = (state) => state.addOns.FoodType;
export const getQuantityType = (state) => state.addOns.QuantityType;

//get common filed
export const gettaxcode = (state) => state.tax.taxcode;
export const gettype = (state) => state.tax.taxtype;
export const getappiedOn = (state) => state.tax.appliedOn;
export const getappiedTo = (state) => state.tax.appliedTo;

// charges module
export const getChargesList = (state) => state.charges.chargesList;
export const getChargeDetails = (state) => state.charges.chargeDetails;

// get charges mapped with item , location and section
export const MappedChargesWithItem = (state) => state.charges.mapChargedItems;
// branch
export const getBranchList = (state) => state.branch.BranchList;
export const getOperationHoursList = (state) => state.branch.OperationHoursList;
export const getBranchInfo = (state) => state.branch.BranchList;

// variance
export const getVarianceTypeList = (state) => state.variance.VariantTypeList;
export const getSizeVariantsList = (state) => state.variance.VarianceSizeList;
export const getMeasurementList = (state) =>
  state.variance.VariantMeasurementList;
export const getVarianceList = (state) => state.variance.VarianceList;
export const getCommonFieldTypeMSMT = (state) =>
  state.variance.CommonFieldTypeMSMT;

//order Type
export const getOrderTypeList = (state) =>
  state.orderType.OrderTypeListByBranchId;
export const getOrderType = (state) => state.orderType.OrderTypeList;

//material
export const getMaterialTypeList = (state) => state.material.MaterialTypeList;
export const getMaterialList = (state) => state.material.MaterialList;

//receipe
export const getReceipeListByCatalogueId = (state) =>
  state.receipe.receipeListByCatalogueId;

//kot remarks
export const getKOTRemarkList = (state) => state.kotRemark.KOTRemarkList;

//kot remarks
export const getPaymentModeList = (state) => state.paymentMode.PaymentModeList;

//employee
export const getEmployeeList = (state) => state.employee.EmployeeList;
export const getEmployeeDetail = (state) => state.employee.EmployeeDetail;
export const getRoleslistByEmpId = (state) => state.employee.RoleslistByEmpId;

//customer
export const getCustomerList = (state) => state.customer.customerList;
export const getCustomerDetail = (state) => state.customer.customerDetail;
export const getCustomerOrderList = (state) => state.customer.customerOrderList;
export const getCustomerLoyaltyPointsList = (state) =>
  state.customer.customerLoyaltyPointsList;
export const getCustomerAddressList = (state) =>
  state.customer.customerAddressList;
export const getCustomerSearchedList = (state) =>
  state.customer.searchedCustomerList;

//pos
export const getItemDetailOnPOS = (state) => state.pos.ItemDetailOnPOS;
export const getCreateOrderSuccess = (state) => state.pos.CreateOrderSuccess;
export const getWaiterListPOSSuccess = (state) =>
  state.pos.WaiterListPOSSuccess;
export const getDiscountListByBranchIdSuccess = (state) =>
  state.pos.discountListByBranchIdSuccess;
export const getChargeListByBranchIdSuccess = (state) =>
  state.pos.chargeListByBranchIdSuccess;
export const getOrderRelatedChangesDone = (state) =>
  state.pos.orderRelatedChangesDone;
export const getTransferMergeChangesDone = (state) =>
  state.pos.transferMergeChangesDone;
export const getTransferedTableOrderId = (state) =>
  state.pos.transferedTableOrderId;
export const getTableSectionByOrderType = (state) =>
  state.pos.tableSectionByOrderType;
export const getOrdersByOrderType = (state) => state.pos.ordersByOrderType;

//account
export const getProfileByTenant = (state) => state.account.ProfileByTenant;
export const getAllCurrencies = (state) => state.account.Currencies;
export const getAllTimeZones = (state) => state.account.TimeZones;
export const getAllLanguages = (state) => state.account.Languages;
export const getAllBussinessLanguages = (state) =>
  state.account.BussinessLanguages;
export const getChangePasswordResponse = (state) =>
  state.account.changePasswordResponse;

// user
export const getUserRoleList = (state) => state.user.UserRoleList;
export const getAccessList = (state) => state.user.AccessList;
export const getEmployeeListByRoleId = (state) =>
  state.user.EmployeeListByRoleId;

// setting
export const getGeneralSettingData = (state) =>
  state.generalSetting.GeneralSettingData;

// salesOrders
export const getDashBoardAnalytics = (state) =>
  state.salesOrders.DashBoardAnalytics;
export const getPendingOrders = (state) => state.salesOrders.PendingOrders;
export const getCancelledOrders = (state) => state.salesOrders.CancelledOrders;
export const getOrdersHistory = (state) => state.salesOrders.OrderHistory;
//export const getDashBoardAnalytics=(state)=>state.salesOrders.DashBoardAnalytics;
// print Template
export const getTemplateByTenantId = (state) =>
  state.printTemplate.TemplateByTenantId;

//reciepts
export const getInvoiceListByTenantId = (state) => state.reciept.InvoiceList;

//vendor
export const getVendorListByTenantId = (state) => state.vendor.VendorList;

//vendor
export const getEmpMonthlyReport = (state) => state.attendance.MonthlyReport;
export const getEmpDailyReport = (state) => state.attendance.DailyReport;
export const getEmpAttendance = (state) => state.attendance.EmpAttendance;
