import {
  COMMON_MEASUREMENT_SUCCESS,
  COMMON_SIZE_VARIANTS_SUCCESS,
} from "../actions/ItemAction";
import {
  COMMON_FIELD_TYPE_BY_GROUP_CODE_SUCCESS,
  GET_VARIANCE_SUCCESS,
  VARIANCE_LIST_ERROR,
  VARIANCE_TYPES_SUCCESS,
} from "../actions/varianceAction";

const initialState = {
  VariantTypeList: [],
  VarianceSizeList: [],
  VariantMeasurementList: [],
  VarianceList: undefined,
  VarianceType: "",
  CommonFieldTypeMSMT:[],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VARIANCE_TYPES_SUCCESS:
      return {
        ...state,
        VariantTypeList: action.payload.data.result,
        error: null,
      };
      case VARIANCE_LIST_ERROR:
      return {
        ...state,
        VarianceList: undefined,
        error: null,
      };
    case COMMON_SIZE_VARIANTS_SUCCESS:
      return {
        ...state,
        VarianceSizeList: action.payload.data.result,
        error: null,
      };
    case COMMON_MEASUREMENT_SUCCESS:
      return {
        ...state,
        VariantMeasurementList: action.payload.data.result,
        error: null,
      };
    case GET_VARIANCE_SUCCESS:
      return {
        ...state,
        VarianceList: action.payload.data.result,
        error: null,
      };
case COMMON_FIELD_TYPE_BY_GROUP_CODE_SUCCESS:
      return {
        ...state,
        CommonFieldTypeMSMT: action.payload.data.result,
        error: null,
      };

    default:
      return state;
  }
};

export default reducer;
