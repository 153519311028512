import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import "./add-tax.scss";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { UpdateTax } from "../../../application/actions/taxAction";
import {
  getBranchList,
  getOrderTypeList,
  getappiedOn,
  gettaxcode,
  gettype,
} from "../../../application/selectors/indexSelector";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { restrictAllNumbers, restrictAllSpecialCharacters, restrictConsecutiveSpace, validateRequired, validateRequiredDropdown } from "../../../infrastructure/core/validationUtils";

export default function EditTaxGroup(props) {
  const {user} = useContext(AuthContext)
  const dispatch = useDispatch();
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const appliedOnCommonList = JSON.parse(localStorage.getItem("CommonFields"))
  ? JSON.parse(localStorage.getItem("CommonFields")).filter(
      (fields) => fields.fieldTypeCode === "APLDON"
    )
  : [];
  const taxcodeCommonList = JSON.parse(localStorage.getItem("CommonFields"))
  ? JSON.parse(localStorage.getItem("CommonFields")).filter(
      (fields) => fields.fieldTypeCode === "TAXCD"
    )
  : [];
  const taxMethodCommonList = JSON.parse(localStorage.getItem("CommonFields"))
  ? JSON.parse(localStorage.getItem("CommonFields")).filter(
      (fields) => fields.fieldTypeCode === "TAXME"
    )
  : [];
  const initialState = {
    id: '',
    tenantId: "",
    branchId: '',
    locations: [],
    status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
    isVisible: true,
    isDeleted: false,
    createdBy: user.id,
    modifiedBy: user.id,
    taxGroupName: "",
    taxGroupDescription: "",
    taxTypeId:"",
    taxMethodId: "",
    taxRate: "",
    orderTypes: [],
  };
  
  const [formData, setFormData] = useState(initialState);
  
  const [errors, setErrors] = useState({});
    const validateInput = () => {
      const newErrors = {};
      // Validate Tax Name
      newErrors.taxGroupName = validateRequired(formData.taxGroupName)
        ? ""
        : "Tax Name is required";
  
      // Validate appliedOn
      newErrors.taxTypeId = validateRequired(formData.taxTypeId)
        ? ""
        : "Please Select Applied On";
      // Validate TaxType
      newErrors.taxMethodId = validateRequired(formData.taxMethodId)
        ? ""
        : "Please Select Tax Type";
      // Validate catalogueCode
      newErrors.taxRate = validateRequired(formData.taxRate)
        ? ""
        : "Tax Rate is required ";
      // Validate catalogueCode
      newErrors.appliedOn = validateRequiredDropdown(formData.appliedOn)
        ? ""
        : "Please Select  Applied On";
  
      setErrors(newErrors);
      // Check if any field has errors
      return !Object.values(newErrors).some((error) => error);
    };
  useEffect(() => {
    if (props?.data) {
      const locationData= props?.data?.locations?.map((option)=> option.locationId)
      setFormData({
        id: props?.data?.id,
        taxGroupName: props?.data?.taxGroupName,
        taxGroupDescription: props?.data?.taxGroupDescription,
        taxTypeId: props?.data?.taxTypeId,
        taxMethodId: props?.data?.taxMethodId,
        taxRate: props?.data?.taxRate,
        appliedOn: props?.data?.appliedOn,
        orderTypes: [],
        branchId: props?.data?.branchId,
        locations: locationData,
      });
    }
  }, [props?.data]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    })); setErrors({ ...errors, [name]: "" });
  };

  const OnSubmit = (e) => {
    e.preventDefault();
     // Validate the form
     const isValid = validateInput();
     if (!isValid) {
       return;
     }
    dispatch(UpdateTax(formData));
    handleClose()
  };
  const handleClose=()=>{
    props.closeFunction()
    setFormData(initialState);
    setErrors({});
  }
  return (
    <React.Fragment>
      <Offcanvas show={props?.show} onHide={handleClose} placement="end" className="w-45">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">Edit Tax Group</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Tax Group Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control type="text" placeholder="Enter Tax Group Name" name="taxGroupName" value={formData?.taxGroupName}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}                />
                 <Form.Text className="text-danger">
                  {errors.taxGroupName}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="8">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Description"
                  name="taxGroupDescription"
                  value={formData?.taxGroupDescription}
                  onChange={handleChange}
                  onKeyDown={restrictConsecutiveSpace}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Tax Code: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="taxTypeId"
                  value={formData?.taxTypeId}
                  onChange={handleChange}
                >
                  {taxcodeCommonList.map((option,index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.taxTypeId}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Taxing Method: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="taxMethodId"
                  value={formData.taxMethodId}
                  onChange={handleChange}
                >
                  {taxMethodCommonList.map((option,index) => (
                    <option key={index} value={option?.id}>
                      {option?.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.taxMethodId}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Tax Percentage: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Group
                  controlId="formFile"
                  className="position-relative overflow-hidden"
                >
                  <Form.Label className="custom-label mb-0">%</Form.Label>
                  <Form.Control
                    className="tax-percentage-input"
                    style={{ paddingLeft: "50px" }}
                    type="number" min="1"
                    placeholder="Enter Tax Percentage"
                    name="taxRate"
                    value={formData?.taxRate}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Text className="text-danger">
                  {errors.taxRate}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Applied On: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="appliedOn"
                  value={formData?.appliedOn}
                  onChange={handleChange}
                >
                  {appliedOnCommonList.map((option,index) => (
                    <option key={index} value={option?.id}>
                      {option?.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.appliedOn}
                </Form.Text>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={OnSubmit}
          >
            {" "}
            <span className="align-middle">Update Tax Group</span>
          </Button>
          <Button
            variant="secondary"
            className="fs-14 d-flex align-items-center"
          >
            <span className="align-middle">Cancel</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
