import React, { useEffect, useState } from "react";
import {  Offcanvas, Tabs, Tab } from "react-bootstrap";
import AddNewDiscount from "./add-discount";
import MappedLocation from "../../_common/mapped-location";
import {
  getBranchList,
  getDiscountDataById,
} from "../../../application/selectors/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  GetDiscountById,
  MapDiscountWithBranch,
  UnMapDiscountWithBranch,
} from "../../../application/actions/discountAction";
import UpdateDiscount from "./update-discount";

export default function EditDiscount(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (props?.data) {
      dispatch(GetDiscountById(props?.data?.id));
    }
  }, [props?.data?.id]);
  const discountDetails = useSelector(getDiscountDataById);
  const [showUpdateDiscount, setShowUpdateDiscount] = useState(false);
  const BranchList = useSelector(getBranchList);
  const BranchOptions = BranchList?.filter(
    (branch) =>
      !discountDetails?.discountBranches?.some(
        (emp) => emp?.branchId === branch?.id
      )
  ).map((item) => ({
    value: item.id,
    label: item.branchName,
  }));
  const handleUpdateDiscount = (state) => {
    setShowUpdateDiscount(state);
  };
  const [activeTab, setActiveTab] = useState("order_type_tab");
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  const AssociateBranch = (branchIds) => {
    if (branchIds) {
      let branchMappingData = [];
      for (let i = 0; i < branchIds.length; i++) {
        const DataObject = {
          discountId: discountDetails?.id,
          locationId: branchIds[i].value,
        };
        branchMappingData.push(DataObject);
      }
      dispatch(MapDiscountWithBranch(branchMappingData));
    }
  };
  const DissociateBranch = (id) => {
    dispatch(UnMapDiscountWithBranch(id));
  };
  return (
    <React.Fragment>
      <AddNewDiscount
        show={showUpdateDiscount}
        data={props.data}
        closeFunction={handleUpdateDiscount}
      />
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Discount</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-4">
            <h3 className="fs-18 fq-semibold text-dark mb-1">
              {discountDetails?.discountTitle}
            </h3>
            <h6 className="fs-12 mb-3">{discountDetails?.discountDesc}</h6>
          </div>

          <Tabs
            id="custom-styled-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="sale-on-point-tabs"
          >
            <Tab
              eventKey="order_type_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Edit Details</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <UpdateDiscount data={props.data} />
              </div>
            </Tab>

            <Tab
              eventKey="mapped_location_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Mapped Locations</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <MappedLocation
                  BranchData={BranchOptions}
                  AssociateBranch={AssociateBranch}
                  DissociateBranch={DissociateBranch}
                  Data={discountDetails?.discountMappings}
                  isGlobal={discountDetails?.isGlobal}
                />
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
