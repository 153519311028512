export const CREATE_RECEIPE = "CREATE_RECEIPE";
export const UPDATE_RECEIPE = "UPDATE_RECEIPE";
export const RECEIPE_LIST_BY_CATALOGUE_ID = "RECEIPE_LIST_BY_CATALOGUE_ID";
export const RECEIPE_LIST_BY_CATALOGUE_ID_SUCCESS = "RECEIPE_LIST_BY_CATALOGUE_ID_SUCCESS";
export const DELETE_RECEIPE = "DELETE_RECEIPE";

export const CreateReceipe = (data) => ({
  type: CREATE_RECEIPE,
  payload: data,
});
export const UpdateReceipe = (data) => ({
  type: UPDATE_RECEIPE,
  payload: data,
});
export const DeleteReceipe = (id) => ({
  type: DELETE_RECEIPE,
  payload: id,
});
export const ReceipeListByCatalogueId = (id) => ({
  type: RECEIPE_LIST_BY_CATALOGUE_ID,
  payload: id,
});
export const ReceipeListByCatalogueIdSuccess = (data) => ({
  type: RECEIPE_LIST_BY_CATALOGUE_ID_SUCCESS,
  payload: data,
});
