import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Nav, Tab, Button, Alert, Spinner } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
import Environment from "../../infrastructure/core/Environment";
import { debounce } from "lodash";
import SentimentAnalytics from "./SentimentAnalytics/sentiment-analytics";
import CustomerReviews from "./CustomerReviews/customer-reviews";
import googleBusinessIcon from "../../assets/img/google-analytics/google-business.png";
import GoogleBusinessUpdate from "./google-bussine-update";

export default function GoogleReview() {
    useEffect(() => {
        document.body.classList.add('page-app');
        return () => {
            document.body.classList.remove('page-app');
        }
    }, []);

    const [isSidebarShow, setSidebarShow] = useState(false);
    const [branchData, setBranchData] = useState(Environment.defaultValue);
    const handleBranchIdFromHeader = debounce((data) => {
        setBranchData(data);
    }, 300);

    const [showGoogleBusinessUpdate, setShowGoogleBusinessUpdate] = useState(false);
    const handleGoogleBusinessUpdate = (state) => {
        setShowGoogleBusinessUpdate(state);
    }

    return (
        <React.Fragment>
            <Header passDataToPointOfSale={handleBranchIdFromHeader} />

            {/* GOOGLE BUSINESS UPDATE MODAL */}
            <GoogleBusinessUpdate show={showGoogleBusinessUpdate} closeFunction={handleGoogleBusinessUpdate} />
                <Tab.Container id="left-tabs-example" defaultActiveKey="sentiment_analysis_tab">
                    <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                        <PerfectScrollbar className="file-sidebar page-in-tabs">
                            <div className="pt-0 pb-0 mb-3">
                                <Button variant="danger" className="w-100">Sync for new reviews</Button>
                            </div>
                            <Nav variant="pills">
                                <Nav.Item><Nav.Link eventKey="sentiment_analysis_tab"><i className="ri-add-box-line"></i> Sentiment Analytics</Nav.Link></Nav.Item>
                                <Nav.Item><Nav.Link eventKey="customer_reviews_tab"><i className="ri-add-box-line"></i> Customer Reviews</Nav.Link></Nav.Item>
                            </Nav>

                            <hr />
                            <h5 className="fs-14">Last Synced 12 hours ago.</h5>
                        </PerfectScrollbar>

                        <PerfectScrollbar className="file-content p-3 p-lg-4">
                            <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                                <i className="ri-arrow-left-line"></i>
                            </Link>
                            {/* <div className="w-100 mb-3">
                                <Alert variant="danger" className="mb-0 w-100 d-flex sm-d-block justify-content-between align-items-center">
                                    <h3 className="fs-14">
                                        <span className="align-middle me-2"><i className="ri-alert-line fs-18"></i></span>
                                        <span className="align-middle"><b>Running</b> sentiment analysis of your google business page</span>
                                    </h3>
                                    <div>
                                        <span className="align-middle me-3">15 remaning of 10500</span>
                                        <Spinner className="wt-20 ht-20 align-middle" animation="border" variant="danger" />
                                    </div>
                                </Alert>
                            </div>

                            <div className="w-100 mb-3">
                                <Alert variant="success" className="mb-0 w-100 d-flex justify-content-between align-items-center">
                                    <h3 className="fs-14">
                                        <span className="align-middle me-2"><i className="ri-check-line fs-18"></i> </span>
                                        <span className="align-middle"><b>Successfully</b> analyzed all the reviews - you can now check the report</span>
                                    </h3>
                                    <div>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                </Alert>
                            </div> */}
                            <Tab.Content>
                                {/* PERFORMANCE DASHBOARD TAB */}
                                <Tab.Pane eventKey="sentiment_analysis_tab"><SentimentAnalytics /></Tab.Pane>

                                {/* SENTIMENT ANALYSIS TAB */}
                                <Tab.Pane eventKey="customer_reviews_tab"><CustomerReviews /></Tab.Pane>
                            </Tab.Content>
                        </PerfectScrollbar>
                    </div>
                </Tab.Container>
            {/* =-=-=-=-=-=-=-=-=-=-=-=-= GOOGLE ANALYTICS EMPTY STATE =-=-=-=-=-=-=-=-=-=-=-=-= */}
            {/* <div className="sentiment-analysis-empty-state text-center">
                <div><img className="wt-80 mb-3" src={googleBusinessIcon} /></div>
                <h3 className="fs-18 mb-2">Google Business Page</h3>
                <h3 className="fs-12 mb-4">Connwct your Google My Business Profile to start analyzing the customer reviews and sentiments. </h3>
                <Button type="button" variant="primary" onClick={() => handleGoogleBusinessUpdate(true)}>Update Profile ID</Button>
            </div> */}
        </React.Fragment>
    );
}