import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import {
  ASSIGN_EMPLOYEE_BRANCH,
  ASSIGN_EMPLOYEE_ROLES,
  CREATE_EMPLOYEE,
  DELETE_EMPLOYEE,
  DELETE_MAPPED_EMP_BRANCH,
  EMPLOYEE_DETAIL,
  EmployeeDetailSuccess,
  GetRolesListByEmpIdSuccess,
  LOAD_EMPLOYEE,
  LoadEmployeeListSuccess,
  REVOKE_EMPLOYEE_ROLE,
  UPDATE_EMPLOYEE,
} from "../actions/employeeAction";
import ToastifyService from "../../View/_common/ToastifyService";

const LoadEmployee =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === LOAD_EMPLOYEE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "LoadEmployee" }));
        const EmployeeData = await api.employeeAPI.LoadEmployee(action.payload);
        dispatch(LoadEmployeeListSuccess(EmployeeData));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "LoadEmployee" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "LoadEmployee" }));
      }
    }
    next(action);
  };
const CreateEmployee =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_EMPLOYEE) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "CreateEmployee" })
        );
        const EmployeeData = await api.employeeAPI.CreateEmployee(
          action.payload
        );
        dispatch(LoadEmployeeListSuccess(EmployeeData));
        ToastifyService.success("Employee Created SuccessFully");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "CreateEmployee" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "CreateEmployee" })
        );
      }
    }
    next(action);
  };
const DeleteEmployee =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_EMPLOYEE) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "DeleteEmployee" })
        );
        const EmployeeData = await api.employeeAPI.DeleteEmployee(
          action.payload
        );
        dispatch(LoadEmployeeListSuccess(EmployeeData));
        ToastifyService.success("Employee Deleted SuccessFully");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "DeleteEmployee" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "DeleteEmployee" })
        );
      }
    }
    next(action);
  };
const UpdateEmployee =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_EMPLOYEE) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "UpdateEmployee" })
        );
        const EmployeeData = await api.employeeAPI.UpdateEmployee(
          action.payload
        );
        dispatch(LoadEmployeeListSuccess(EmployeeData));
        ToastifyService.success("Employee Updated SuccessFully");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "UpdateEmployee" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "UpdateEmployee" })
        );
      }
    }
    next(action);
  };
const EmployeeDetail =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === EMPLOYEE_DETAIL) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "EmployeeDetail" })
        );
        const EmployeeDetail = await api.employeeAPI.EmployeeDetail(
          action.payload
        );
        dispatch(EmployeeDetailSuccess(EmployeeDetail));
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "EmployeeDetail" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "EmployeeDetail" })
        );
      }
    }
    next(action);
  };
const assignEmployeeBranch =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ASSIGN_EMPLOYEE_BRANCH) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "assignEmployeeBranch" })
        );
        const EmployeeDetail = await api.employeeAPI.assignEmployeeBranch(
          action.payload
        );
        dispatch(EmployeeDetailSuccess(EmployeeDetail));
        ToastifyService.success("Branch Assigned To Employee SuccessFully");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "assignEmployeeBranch" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "assignEmployeeBranch" })
        );
      }
    }
    next(action);
  };
const GetRolesListByEmployeeId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ASSIGN_EMPLOYEE_ROLES) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "GetRolesListByEmployeeId",
          })
        );
        const RolesList = await api.employeeAPI.assignEmployeeBranch(
          action.payload
        );
        dispatch(GetRolesListByEmpIdSuccess(RolesList));
      } catch (error) {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "GetRolesListByEmployeeId",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "GetRolesListByEmployeeId",
          })
        );
      }
    }
    next(action);
  };
const deleteMappedEmpBranch =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_MAPPED_EMP_BRANCH) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "deleteMappedEmpBranch" })
        );
        const EmployeeDetail = await api.employeeAPI.deleteMappedEmpBranch(
          action.payload
        );
        dispatch(EmployeeDetailSuccess(EmployeeDetail));
        ToastifyService.success("Branched Unmapped SuccessFully");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "deleteMappedEmpBranch" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "deleteMappedEmpBranch" })
        );
      }
    }
    next(action);
  };
const revokeEmployeeRole =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === REVOKE_EMPLOYEE_ROLE) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "revokeEmployeeRole" })
        );
        const EmployeeDetail = await api.employeeAPI.revokeEmployeeRole(
          action.payload
        );
        dispatch(EmployeeDetailSuccess(EmployeeDetail));
        ToastifyService.success("Employee Role Revoked SuccessFully");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "revokeEmployeeRole" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "revokeEmployeeRole" })
        );
      }
    }
    next(action);
  };
export default [
  CreateEmployee,
  LoadEmployee,
  DeleteEmployee,
  UpdateEmployee,
  EmployeeDetail,
  assignEmployeeBranch,
  deleteMappedEmpBranch,
  GetRolesListByEmployeeId,
  revokeEmployeeRole,
];
