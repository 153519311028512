import React, { useEffect, useState } from "react";
import { Offcanvas, Button, Spinner } from "react-bootstrap";
import "./change-address.scss";
import Environment from "../../../../infrastructure/core/Environment";
import AddNewAddress from "./add-address";
import { useDispatch, useSelector } from "react-redux";
import { updateCustomerDetailsInOrder } from "../../../../application/actions/customerAction";
import { getCreateOrderSuccess, getLoading } from "../../../../application/selectors/indexSelector";
import LoadingSpinner from "../../../_common/LoadingSpinner";

export default function Changeaddress(props) {
  const dispatch = useDispatch();
  const [addressList, setAddressList] = useState(
    props?.getCustomerDetails?.userAddresses
  );
  const [showAddNewAddree, setShowAddNewAddree] = useState(false);
  const [addressId, setAddressId] = useState(Environment.defaultValue);
  const [operationValue, setOperationValue] = useState("AddNewAddress");
  const addressType = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "ADDTY"
      )
    : [];
  const [selectedAddressType, setSelectedAddressType] = useState(
    addressType[0]?.id
  );
  const [selectedAddress, setSelectedAddress] = useState(
    Environment.defaultValue
  );
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  useEffect(() => {
    if (props?.show) {
      setSelectedAddressType(addressType[0]?.id);
      setAddressList(
        props?.getCustomerDetails?.userAddresses?.filter(
          (address) =>
            address?.addressType === addressType[0]?.id &&
            address?.isDeleted === false
        )
      );
    }
  }, [props?.show, props?.getCustomerDetails]);
  const handleAddNewAddress = (state, addressId, data) => {
    setShowAddNewAddree(state);
    setAddressId(addressId);
    setOperationValue(data);
  };
  const handleSetAddressDefault = () => {
    props.onCustomerAddressSelect(selectedAddress);
    if (createOrderSuccess) {
      const formObject = {
        orderId: createOrderSuccess?.id,
        customerId: props?.getCustomerDetails?.id,
        addressId: selectedAddress,
      };
      const formObjectData = {
        formObject: formObject,
        orderTypeId: createOrderSuccess?.orderTypeId,
      };
      dispatch(updateCustomerDetailsInOrder(formObjectData));
    }
    props?.closeFunction();
  };
  return (
    <React.Fragment>
      <AddNewAddress
        show={showAddNewAddree}
        closeFunction={handleAddNewAddress}
        getCustomerDetails={props?.getCustomerDetails}
        operationValue={operationValue}
        addressId={addressId}
      />
      {loading && value === "updateCustomerAddress" && (
        <div className="card-loader">
          <Spinner/>
        </div>
      )}
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-35"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Changes Address
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="address-custom-radio-bttm mb-3">
            {addressType?.map((type, index) => (
              <div className="form-row flex-center me-2" key={index}>
                <input
                  type="radio"
                  name="addressType"
                  id={type?.fieldValue}
                  className="form-input"
                  checked={type?.id === selectedAddressType}
                  onChange={() => {
                    setSelectedAddressType(type?.id);
                    setAddressList(
                      props?.getCustomerDetails?.userAddresses?.filter(
                        (address) => address?.addressType === type?.id
                      )
                    );
                  }}
                />
                <label for={type?.fieldValue} className="form-label">
                  {type?.fieldValue}
                </label>
              </div>
            ))}
          </div>
          <div className="existing-address">
            <div className="existing-address">
              {addressList
                ?.filter((address) => address.isDeleted === false)
                ?.map((address, index) => (
                  <div
                    key={index}
                    className="selected-address"
                    onClick={() => setSelectedAddress(address?.id)}
                  >
                    <input
                      type="radio"
                      id={`address${index}`}
                      name="existingAddress"
                    />
                    <label htmlFor={`address${index}`}>
                      {`${address.buildingName}, ${address.roomNo}, ${address.landmark}, ${address.city}, ${address.state}, ${address.zipCode}.`}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer d-block h-auto">
          <Button
            variant="outline-primary"
            className="fs-14 me-2 d-flex align-items-center justify-content-center w-100"
            onClick={() =>
              handleAddNewAddress(
                true,
                Environment.defaultValue,
                "AddNewAddress"
              )
            }
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Add New Address</span>
          </Button>
          <hr />
          <Button
            variant="danger"
            className="fs-14 me-2 d-flex align-items-center justify-content-center w-100"
            onClick={() => handleSetAddressDefault()}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Select and Proceed</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
