import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Table } from "react-bootstrap";
import Languageslist from "../../../Json/setting/languages.json";
import AddLanguage from "./add-language";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteBusinessLanguages,
  GetAllLanguagesList,
  GetBusinessLanguages,
} from "../../../application/actions/accountAction";
import {
  getAllBussinessLanguages,
  getAllLanguages,
} from "../../../application/selectors/indexSelector";
import { format } from "date-fns";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

export default function Languages() {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(GetBusinessLanguages(user?.tenanatId));
  }, []);
  const [showAddLanguage, setShowAddLanguage] = useState(false);
  const handleAddLanguage = (state) => {
    setShowAddLanguage(state);
  };
  const AllBussinessLanguages = useSelector(getAllBussinessLanguages);
  const handleDeleteLng = (item) => {
    SweetAlertService.showAlert(
      "Delete Language",
      "Are you sure you want to delete this Language?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(DeleteBusinessLanguages(item.id));
          SweetAlertService.showSuccess("Success", "Language!");
          ToastifyService.success(`Deleted Language Successfully`);
        } else {
          console.log("Delete operation cancelled!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`something went wrong`);
      });
  };
  return (
    <React.Fragment>
      <AddLanguage show={showAddLanguage} closeFunction={handleAddLanguage} />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-14 main-title mb-1">Support Languages</h4>
          <h5 className="fs-12 text-secondary">
            Define list languages supported
          </h5>
        </div>

        <div>
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            type="button"
            onClick={() => handleAddLanguage(true)}
          >
            <i className="ri-add-line me-1"></i>{" "}
            <span className="align-middle">Add Language</span>
          </Button>
        </div>
      </div>

      <Table className="mb-0" responsive>
        <thead>
          <tr>
            <th>Language</th>
            <th>Code</th>
            <th>Mapped On</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {AllBussinessLanguages?.map((item, index) => (
            <tr key={index}>
              <td>{item.languageName}</td>
              <td>{item.code}</td>
              <td>
                <h5 className="fs-14 fw-semibold">
                  {" "}
                  {format(new Date(item.createdOn), "dd MMM yyyy hh:mm a")}
                </h5>
                <h6 className="fs-11 mb-1">{item.createdByName}</h6>
              </td>
              <td>
                {item.isDefault ? (
                  <h4 className={`text-uppercase text-dark fs-12`}>Default</h4>
                ) : (
                  <Button
                    variant="danger"
                    className="btn-icon"
                    onClick={() => handleDeleteLng(item)}
                  >
                    <i className="ri-delete-bin-line"></i>
                  </Button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
}
