import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Row, Col, Form, Offcanvas } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAllLanguages } from "../../../application/selectors/indexSelector";
import AuthContext from "./../../../infrastructure/core/helpers/AuthContext";
import {
  AddBusinessLanguages,
  GetAllLanguagesList,
} from "../../../application/actions/accountAction";

export default function AddLanguage(props) {
  // Accessing user context
  const { user } = useContext(AuthContext);

  // Dispatch hook for Redux actions
  const dispatch = useDispatch();

  // Effect hook to highlight code on component mount and fetch all languages
  useEffect(() => {
    Prism.highlightAll();
    dispatch(GetAllLanguagesList);
  }, []);

  // Selector hook to get all languages from Redux store
  const AllLanguages = useSelector(getAllLanguages);

  // Mapping languages to options for react-select
  const AllLanguagesOptions = AllLanguages?.map((lang) => ({
    value: lang.id,
    label: lang.name,
  }));

  // State hook to manage selected language
  const [languageId, setLanguageId] = useState({});

  // Function to handle form submission
  const handleSubmit = () => {
    // Constructing data object to be dispatched
    const dataObject = {
      tenantId: user?.tenanatId,
      branchId: user.branchId,
      languageId: languageId.value,
    };
    // Dispatching action to add business languages
    dispatch(AddBusinessLanguages(dataObject));
    // Closing the offcanvas
    props.closeFunction();
  };

  return (
    <React.Fragment>
      {/* Offcanvas component for adding new language */}
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Include New Language Support
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={8} sm={12}>
              <div className="mb-4">
                <Form.Label>Choose Language</Form.Label>
                {/* Select component for choosing language */}
                <Select
                  options={AllLanguagesOptions}
                  isSearchable={true}
                  value={languageId}
                  onChange={(e) => setLanguageId(e)}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        {/* Offcanvas footer with include button */}
        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            <i className="ri-add-line me-1"></i>{" "}
            <span className="align-middle">Include</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
