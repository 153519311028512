import { Navigate } from "react-router-dom";
import {
  CREATE_KITCHEN,
  DELETE_KITCHEN,
  GET_KITCHEN_BY_ID,
  GET_KITCHEN_LIST,
  GET_KITCHEN_LIST_BY_TENANT,
  GetKitchenByIdSuccess,
  GetKitchenListByTenantSuccess,
  GetKitchenListSuccess,
  KITCHEN_MENU_MAPPING,
  UNMAP_KITCHEN_MENU,
  UPDATE_KITCHEN,
} from "../actions/kitchenAction";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";

const GetKitchenList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_KITCHEN_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "kitchenList" }));
        const KitchenList = await api.kitchenAPI.GetKitchenList(
          action.payload.data
        );
        dispatch(GetKitchenListSuccess(KitchenList));
        dispatch(Loading.setLoading({ loading: false, value: "kitchenList" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "kitchenList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetKitchenListByTenant =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_KITCHEN_LIST_BY_TENANT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "kitchenList" }));
        const KitchenList = await api.kitchenAPI.GetKitchenListByTenant(
          action.payload.data
        );
        dispatch(GetKitchenListByTenantSuccess(KitchenList));
        dispatch(Loading.setLoading({ loading: false, value: "kitchenList" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "kitchenList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

const CreateKitchen =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_KITCHEN) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const KitchenList = await api.kitchenAPI.CreateKitchen(
          action.payload.data
        );
        dispatch(GetKitchenListSuccess(KitchenList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Kitchen Added Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateKitchen =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_KITCHEN) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const KitchenList = await api.kitchenAPI.UpdateKitchen(
          action.payload.data
        );
        dispatch(GetKitchenListSuccess(KitchenList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Kitchen Details Updated Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteKitchen =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_KITCHEN) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const KitchenList = await api.kitchenAPI.DeleteKitchen(
          action.payload.data
        );
        dispatch(GetKitchenListSuccess(KitchenList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MapKitchenMenu =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === KITCHEN_MENU_MAPPING) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "kitchenMenuList" })
        );
        const KitchenDetails = await api.kitchenAPI.MapKitchenMenu(
          action.payload
        );
        dispatch(GetKitchenByIdSuccess(KitchenDetails));
        dispatch(
          Loading.setLoading({ loading: false, value: "kitchenMenuList" })
        );
        ToastifyService.success("Menu Associated Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "kitchenMenuList" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UnMapKitchenMenu =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UNMAP_KITCHEN_MENU) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "kitchenMenuList" })
        );
        const KitchenDetails = await api.kitchenAPI.UnMapKitchenMenu(
          action.payload
        );
        dispatch(GetKitchenByIdSuccess(KitchenDetails));
        dispatch(
          Loading.setLoading({ loading: false, value: "kitchenMenuList" })
        );
        ToastifyService.success("Menu Dissociated Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "kitchenMenuList" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const KitchenDetailsById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_KITCHEN_BY_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const KitchenDetails = await api.kitchenAPI.KitchenDetailsById(
          action.payload
        );
        dispatch(GetKitchenByIdSuccess(KitchenDetails));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  GetKitchenList,
  GetKitchenListByTenant,
  CreateKitchen,
  UpdateKitchen,
  DeleteKitchen,
  MapKitchenMenu,
  KitchenDetailsById,
  UnMapKitchenMenu,
];
