import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import { CreateKOTRemark } from "../../../../application/actions/KOTAction";

export function CreateKOTRemarks(props) {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const initialState = {
    remark: "",
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = () => {
    const formObject = {
      createdBy: String(user.id),
      createdOn: new Date(),
      remark: formData.remark,
    };
    dispatch(CreateKOTRemark(formObject));
    setFormData(initialState);
    props.closeFunction(false);
  };
  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end">
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>Add KOT Remark</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="mb-3">
            <Form.Label>
              KOT Remark: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter remark"
              name="remark"
              value={formData.remark}
              onChange={handleChange}
            />
          </div>
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-16 lh-1 align-middle me-1"></i>
            <span className="align-middle">Create KOT Remark</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
