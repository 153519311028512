import { Navigate } from "react-router";
import * as Loading from "../actions/loaderAction";
import {
  ACCESS_LIST_BY_USERROLEID,
  ASSIGN_ACCESS_BY_ROLEID,
  ASSIGN_ROLE_TO_EMPLOYEE,
  AccessByUserRoleIdSuccess,
  CREATE_USERROLE,
  DELETE_USERROLE,
  EMPLOYEE_LIST_BY_ROLEID,
  EmployeeListByRoleIdSuccess,
  REMOVE_ACCESS_BY_ROLEID,
  UPDATE_USERROLE,
  USERROLE_DETAIL,
  USERROLE_LIST,
  UserRoleDetailSuccess,
  UserRoleListSuccess,
} from "../actions/userAction";
import ToastifyService from "../../View/_common/ToastifyService";

const UserRoleList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === USERROLE_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const UserRoleList = await api.userAPI.UserRoleList();
        dispatch(UserRoleListSuccess(UserRoleList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const CreateUserRole =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_USERROLE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const UserRoleList = await api.userAPI.CreateUserRole(action.payload);
        dispatch(UserRoleListSuccess(UserRoleList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("User Role Created Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UserRoleDetail =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === USERROLE_DETAIL) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const UserRoleData = await api.userAPI.UserRoleDetail(action.payload);
        dispatch(UserRoleDetailSuccess(UserRoleData));
        dispatch(EmployeeListByRoleIdSuccess(UserRoleData.result.mappedUsers));
       // dispatch(AccessByUserRoleIdSuccess(UserRoleData.result.rolePermissions));

        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateUserRole =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_USERROLE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const UserRoleList = await api.userAPI.UpdateUserRole(action.payload);
        dispatch(UserRoleListSuccess(UserRoleList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("User Role Details Updated Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteUserRole =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_USERROLE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const UserRoleList = await api.userAPI.DeleteUserRole(action.payload);
        dispatch(UserRoleListSuccess(UserRoleList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AccessByUserRoleId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ACCESS_LIST_BY_USERROLEID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const AccessList = await api.userAPI.AccessByUserRoleId(action.payload);
        dispatch(AccessByUserRoleIdSuccess(AccessList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AssignAccessRoleId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ASSIGN_ACCESS_BY_ROLEID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const AccessList = await api.userAPI.AssignAccessRoleId(action.payload);
        dispatch(AccessByUserRoleIdSuccess(AccessList));
        ToastifyService.success("Access Updated ! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const RemoveAccessRoleId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === REMOVE_ACCESS_BY_ROLEID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        
        const AccessList = await api.userAPI.RemoveAccessRoleId(action.payload);
        dispatch(AccessByUserRoleIdSuccess(AccessList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Access Removed Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AssignRoletoEmployees =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ASSIGN_ROLE_TO_EMPLOYEE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const UserRoleDetail = await api.userAPI.AssignRoletoEmployees(
          action.payload
        );
        dispatch(UserRoleDetailSuccess(UserRoleDetail));
        dispatch(EmployeeListByRoleIdSuccess(UserRoleDetail.result.mappedUsers));
        //dispatch(AccessByUserRoleIdSuccess(UserRoleDetail.result.rolePermissions));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("User Assigned To This Role Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const EmployeeListByRoleId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === EMPLOYEE_LIST_BY_ROLEID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const EmployeeList = await api.userAPI.EmployeeListByRoleId(
          action.payload
        );
        dispatch(EmployeeListByRoleIdSuccess(EmployeeList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  EmployeeListByRoleId,
  AssignRoletoEmployees,
  UserRoleList,
  CreateUserRole,
  UserRoleDetail,
  UpdateUserRole,
  DeleteUserRole,
  AccessByUserRoleId,
  AssignAccessRoleId,
  RemoveAccessRoleId,
];
