import {
  GET_TEMPLATE_BY_TENANTID,
  GetTemplateByTenantIdSuccess,
} from "../actions/printTemplate";
import * as Loading from "../actions/loaderAction";
import { Navigate } from "react-router-dom";
import ToastifyService from "../../View/_common/ToastifyService";

const GetTemplateByTenantId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_TEMPLATE_BY_TENANTID) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "templateBytenant" })
        );
        const TemplateList = await api.printTemplateAPI.GetTemplateByTenantId(
          action.payload
        );
        dispatch(GetTemplateByTenantIdSuccess(TemplateList));
        dispatch(
          Loading.setLoading({ loading: false, value: "templateBytenant" })
        );
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "templateBytenant" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [GetTemplateByTenantId];
