import React, { useEffect, useState, useCallback, useMemo } from "react";
import Prism from "prismjs";
import { Offcanvas, Form, Button } from "react-bootstrap";
import SettlePaymentSingle from "./payment-single";
import SettlePaymentMultiple from "./payment-multiple";
import { useDispatch, useSelector } from "react-redux";
import {
  getCreateOrderSuccess,
  getLoading,
  getOrderRelatedChangesDone,
  getPaymentModeList,
} from "../../../application/selectors/indexSelector";
import { orderSettlePayment } from "../../../application/actions/posAction";
import LoadingSpinner from "../../_common/LoadingSpinner";

const SettlePayment = ({ show, closeFunction }) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const dispatch = useDispatch();
  const createOrderSuccess = useSelector(getCreateOrderSuccess);

  const orderRelatedChangesDone = useSelector(getOrderRelatedChangesDone);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [resetChild, setResetChild] = useState(false);
  const paymentModes = useSelector(getPaymentModeList);

  const [state, setState] = useState({
    isChecked: false,
    selectedPaymentDetails: [],
    cashBalance: 0,
    tips: 0,
    transactionIds: {},
  });

  const handleSplitBill = useCallback((event) => {
    setState((prev) => ({ ...prev, isChecked: event.target.checked }));
  }, []);

  const handlePaymentChange = useCallback((data, cashBalanceAmount, tips) => {
    setState((prev) => ({
      ...prev,
      selectedPaymentDetails: data,
      cashBalance: cashBalanceAmount,
      tips: tips,
    }));
  }, []);

  useEffect(() => {
    if (
      show &&
      (orderRelatedChangesDone ||
        (loading && value === "orderSettlePayment") ||
        isDataLoaded)
    ) {
      setIsDataLoaded(false);
      closeFunction();
    }
  }, [
    closeFunction,
    isDataLoaded,
    loading,
    orderRelatedChangesDone,
    value,
    show,
  ]);

  const handleSettle = useCallback(() => {
    setIsDataLoaded(true);
    const { isChecked, selectedPaymentDetails, cashBalance, tips } = state;

    if (selectedPaymentDetails.length > 0 && cashBalance >= 0) {
      let runningBalance = createOrderSuccess?.grandTotalPrice || 0;
      const updatedPaymentDetails = selectedPaymentDetails.map(
        (paymentMode) => {
          const paidAmount = parseFloat(paymentMode.paidAmount) || 0;
          const balanceAmount = (runningBalance - paidAmount).toFixed(2);
          runningBalance = balanceAmount;
          return {
            ...paymentMode,
            balanceAmount: Number((-balanceAmount).toFixed(2)),
          };
        }
      );
      const totalPaidAmount = selectedPaymentDetails.reduce(
        (total, paymentMode) => total + parseFloat(paymentMode.paidAmount) || 0,
        0
      );
      const formObject = {
        orderId: createOrderSuccess?.id,
        payementStatus: "a5ca48b8-bfd1-42d6-8db1-7021c131e701",
        isSplit: isChecked,
        tips: Number(tips.toFixed(2)),
        totalPaidAmount: Number(totalPaidAmount.toFixed(2)),
        balanceAmount: Number(cashBalance.toFixed(2)),
        payModes: updatedPaymentDetails,
      };
      const formObjectData = {
        formObject: formObject,
        orderTypeId: createOrderSuccess?.orderTypeId,
      };
      dispatch(orderSettlePayment(formObjectData));
    } else {
      console.error(
        "Invalid selectedPaymentDetails structure or cash balance is negative"
      );
    }
  }, [state, createOrderSuccess?.id, dispatch, closeFunction]);
  const handleReset = useCallback(() => {
    // Reset the state to initial values
    setState({
      selectedPaymentDetails: [],
      cashBalance: 0,
      tips: 0,
      transactionIds: {},
    });
    // Trigger the useEffect in the child component by changing the resetChild state
    setResetChild(true);
  }, []);
  const settlePaymentComponent = useMemo(() => {
    return state.isChecked ? (
      <SettlePaymentMultiple
        onPaymentChange={handlePaymentChange}
        resetChild={resetChild}
        setResetChild={setResetChild}
      />
    ) : (
      <SettlePaymentSingle
        onPaymentChange={handlePaymentChange}
        resetChild={resetChild}
        setResetChild={setResetChild}
      />
    );
  }, [state.isChecked, handlePaymentChange, resetChild]);

  return (
    <React.Fragment>
      {(orderRelatedChangesDone ||
        (loading && value === "orderSettlePayment") ||
        isDataLoaded) && (
        <div className="card-loader">
          <LoadingSpinner color="#dc3545" size="100" type={"ThreeDots"} />
        </div>
      )}
      <Offcanvas
        show={show}
        onHide={closeFunction}
        placement="end"
        className="w-35"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Settle Payment
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h3 className="fw-bold fs-14">Choose Payment Mode</h3>
            <Form.Check
              type="switch"
              label="Split Bill"
              checked={state.isChecked}
              onChange={handleSplitBill}
            />
          </div>

          <div className="settle-payment-view">{settlePaymentComponent}</div>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className={`me-2 ps-5 pe-5`}
            onClick={handleSettle}
            disabled={
              state.selectedPaymentDetails.length === 0 ||
              (Number(state.cashBalance) < 0 &&
                !state.selectedPaymentDetails.some(
                  (paymentMode) =>
                    paymentMode.paymentMode ===
                      "a1ca48b8-bfd1-42d6-8db1-7021c131e002" ||
                    paymentMode.paymentMode ===
                      "a1ca48b8-bfd1-42d6-8db1-7021c131e003"
                )) ||
              state.selectedPaymentDetails.some((paymentMode) => {
                const mode = paymentModes.find(
                  (mode) => mode.id === paymentMode.paymentMode
                );
                return (
                  mode &&
                  mode.isTransactionIdMandatory &&
                  paymentMode.transactionId === ""
                );
              })
            }
          >
            Settle
          </Button>

          <Button
            variant="secondary"
            className="ps-5 pe-5"
            onClick={handleReset}
          >
            Discard
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
};

export default SettlePayment;
