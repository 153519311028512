import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";
import { ADD_INVOICE, INVOICE_LIST, INVOICE_LIST_SUCCESS, InvoiceListSuccess } from "../actions/recieptAction";

const AddInvoiceData =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_INVOICE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const InvoiceData = await api.recieptAPI.AddReciept(
          action.payload
        );
        console.log(InvoiceData);
        dispatch(InvoiceListSuccess(InvoiceData))
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const getInvoiceList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === INVOICE_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const InvoiceData = await api.recieptAPI.getInvoiceList()
        console.log(InvoiceData);
        dispatch(InvoiceListSuccess(InvoiceData))
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  export default [
    AddInvoiceData,getInvoiceList
  ];
  