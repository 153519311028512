export const GET_EMP_MONTHLY_REPORT = 'GET_EMP_MONTHLY_REPORT';
export const MARK_EMP_ATTEN = 'MARK_EMP_ATTEN';
export const GET_EMP_DAILY_ATT = 'GET_EMP_DAILY_ATT';
export const GET_EMP_DAILY_REPORT = 'GET_EMP_DAILY_REPORT';
export const MARK_EMP_ATTEN_SUCCESS = 'MARK_EMP_ATTEN_SUCCESS';
export const GET_EMP_DAILY_REPORT_SUCCESS = 'GET_EMP_DAILY_REPORT_SUCCESS';
export const GET_EMP_MONTHLY_REPORT_SUCCESS = 'GET_EMP_MONTHLY_REPORT_SUCCESS';

export const GetEmployeeMonthlyReport =(data)=> ({
    type: GET_EMP_MONTHLY_REPORT,
    payload :data
});
export const GetEmployeeMonthlyReportSuccess =(data)=> ({
    type: GET_EMP_MONTHLY_REPORT_SUCCESS,
    payload :data
});
export const EmployeeDaywiseAttendace =(data)=> ({
    type: GET_EMP_DAILY_ATT,
    payload :data
});
export const GetEmployeeDailyReport =(data)=> ({
    type: GET_EMP_DAILY_REPORT,
    payload :data
});
export const GetEmployeeDailyReportSuccess =(data)=> ({
    type: GET_EMP_DAILY_REPORT_SUCCESS,
    payload :data
});
export const MarkEmpAttendance =(data)=> ({
    type: MARK_EMP_ATTEN,
    payload :data
});
export const MarkEmpAttendanceSuccess =(data)=> ({
    type: MARK_EMP_ATTEN_SUCCESS,
    payload :data
});