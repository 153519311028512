import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { useDispatch, useSelector } from "react-redux";

import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import Select from "react-select";

import { AddDiscount } from "../../../application/actions/discountAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { getOrderType } from "../../../application/selectors/indexSelector";
import { OrderType } from "../../../application/actions/orderTypeAction";
import environment from "../../../infrastructure/core/Environment";
import { restrictAllNumbers, restrictAllSpecialCharacters, restrictConsecutiveSpace, restrictOnlyNumber, validateRequired } from "../../../infrastructure/core/validationUtils";

export default function AddNewDiscount(props) {
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [discountTypeChange, setDiscountTypeChange] = useState(null);
  const [orderValueRestrictChange, setOrderValueRestrictChange] =useState(false);
  useEffect(() => {
    dispatch(OrderType);
  }, [user.branchId]);
  const orderTypeCommonList = useSelector(getOrderType);
  const discountTypeCommonList = JSON.parse(
    localStorage.getItem("CommonFields")
  )
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "DISTYP"
      )
    : [];
  useEffect(() => {
    if (props?.data !== undefined) {
      const splitApplicableTo = props?.data?.orderTypeIds?.split(",");
      const newArray = splitApplicableTo?.map((id) => {
        const matchedItem = orderTypeCommonList?.find((item) => item.id === id);
        return { id, label: matchedItem ? matchedItem.typeName : "Unknown" };
      });
      setSelectedOptions(newArray);
    }
    if (props?.data?.id) {
      setFormData({
        ...formData,
        id: props?.data?.id,
        discountTitle: props?.data?.discountTitle,
        discountDesc: props?.data?.discountDesc,
        applicableTo: "",
        orderTypeIds: selectedOptions,
        coupenCode: props?.data?.coupenCode,
        discountTypeId: props?.data?.discountTypeId,
        discountRate: props?.data?.discountRate,
        isOrderValue: props?.data?.isOrderValue,
        minOrderValue: props?.data?.minOrderValue,
        maxDiscountValue: props?.data?.maxDiscountValue,
        isDeleted: false,
        createdBy: user?.id,
        modifiedBy: user?.id,
      });
      setOrderValueRestrictChange(props?.data?.isOrderValue);
      setDiscountTypeChange(props?.data?.discountTypeDesc === "Fixed Discount");
    }
  }, [props?.data]);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const initialState = {
    discountTitle: "",
    discountDesc: "",
    applicableTo: "",
    coupenCode: "",
    discountTypeId: "",
    discountRate: 0,
    isOrderValue: false,
    minOrderValue: 0,
    maxDiscountValue: 0,
    isDeleted: false,
    createdBy: user?.id,
    modifiedBy: user?.id,
  };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate discountTitle
    newErrors.discountTitle = validateRequired(formData.discountTitle)
      ? ""
      : "Discount Title is required";

    // Validate coupenCode
    newErrors.coupenCode = validateRequired(formData.coupenCode)
      ? ""
      : "Coupen Code is required";
    // Validate discountTypeId
    newErrors.discountTypeId = validateRequired(formData.discountTypeId)
      ? ""
      : "Please Select discount Type";
    
    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const handleClose = () => {
    setFormData(initialState);
    setErrors({});
    props.closeFunction();
    setOrderValueRestrictChange(false)
    setDiscountTypeChange(null)
  };
  const handleSubmit = (e) => {
    e.preventDefault();
      // Validate the form
      const isValid = validateInput();
      if (!isValid) {
        return;
      }
    dispatch(AddDiscount(formData));
    setFormData(initialState);
    props.closeFunction(false);
    props?.handleDetail();
  };
  const appliedOnCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "APLDON"
      )
    : [];
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-45"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {props?.data?.id ? "Update Discount" : "Add New Discount"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="8">
              <div className="mb-4">
                <Form.Label>
                  Discount Title: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="discountTitle"
                  value={formData.discountTitle}
                  onChange={(e) => {
                    setFormData({ ...formData, discountTitle: e.target.value });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      discountTitle: "",
                    }));
                  }}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}                  placeholder="Enter Discount Title"
                />
                 <Form.Text className="text-danger">
                  {errors.discountTitle}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="discountDesc"
                  value={formData.discountDesc}
                  onChange={(e) => {
                    setFormData({ ...formData, discountDesc: e.target.value });
                  }}
                  onKeyDown={restrictConsecutiveSpace}
                  placeholder="Enter Description"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>Coupon Code</Form.Label>
                <Form.Control
                  type="text"
                  name="coupenCode"
                  placeholder="Enter Coupon Code"
                  value={formData.coupenCode}
                  onChange={(e) => {
                    setFormData({ ...formData, coupenCode: e.target.value });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      coupenCode: "",
                    }));
                  }}
                  onKeyDown={restrictConsecutiveSpace}
                />
                <Form.Text className="text-danger">
                  {errors.coupenCode}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Discount Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  defaultValue={`${environment.defaultValue}`}
                  name="discountTypeId"
                  value={formData.discountTypeId}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      discountTypeId: e.target.value,
                    });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      discountTypeId: "",
                    }));
                    e.target.selectedOptions[0].innerText === "Fixed Discount"
                      ? setDiscountTypeChange(true)
                      : setDiscountTypeChange(false);
                  }}
                >
                  <option value={`${environment.defaultValue}`}>
                    Choose Discount Type
                  </option>
                  {discountTypeCommonList.map((data) => (
                    <option value={data.id}>{data.fieldValue}</option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.discountTypeId}
                </Form.Text>
              </div>
            </Col>
            {discountTypeChange !== null ? (
              <>
                <Col xl="6">
                  <div className="mb-4">
                    <Form.Label>
                      {discountTypeChange
                        ? "Enter Fixed Discount"
                        : "Enter Float Discount"}{" "}
                      : <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Group
                      controlId="formFile"
                      className="position-relative overflow-hidden"
                    >
                      <Form.Label className="custom-label mb-0">{discountTypeChange
                        ? '$'
                        :' %'}</Form.Label>
                      <Form.Control
                        className="tax-percentage-input"
                        style={{ paddingLeft: "50px" }}
                        type="number" min="1"
                        placeholder="Enter Tax Percentage"
                        name="discountRate"
                        value={formData.discountRate}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            discountRate: e.target.value,
                          });
                        }}
                        onKeyDown={restrictConsecutiveSpace}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-2">
                <Form.Check
                  type="switch"
                  name="isOrderValue"
                  label="Order Value Restriction"
                  className="me-3 fs-14 fw-semibold"
                  checked={formData.isOrderValue}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      isOrderValue: e.target.checked,
                    });
                    e.target.checked
                      ? setOrderValueRestrictChange(true)
                      : setOrderValueRestrictChange(false);
                  }}
                />
              </div>
            </Col>
          </Row>
          {orderValueRestrictChange ? (
            <>
              <Row>
                <Col xl="6">
                  <div className="mb-4">
                    <Form.Label>
                      Minimum Order Value: <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Group
                      controlId="formFile"
                      className="position-relative overflow-hidden"
                    >
                      <Form.Label className="custom-label mb-0">$</Form.Label>
                      <Form.Control
                        className="tax-percentage-input"
                        style={{ paddingLeft: "50px" }}
                        type="number" min="1"
                        placeholder="Enter Tax Percentage"
                        name="minOrderValue"
                        value={formData.minOrderValue}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            minOrderValue: e.target.value,
                          });
                        }}
                        onKeyDown={(e)=>{restrictConsecutiveSpace(e)
                        restrictOnlyNumber(e)}}  
                        />
                    </Form.Group>
                  </div>
                </Col>
                <Col xl="6">
                  <div className="mb-4">
                    <Form.Label>
                      Maximum Discount Allowed:{" "}
                      <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Group
                      controlId="formFile"
                      className="position-relative overflow-hidden"
                      >
                      <Form.Label className="custom-label mb-0">$</Form.Label>
                      <Form.Control
                        className="tax-percentage-input"
                        style={{ paddingLeft: "50px" }}
                        type="number" min="1"
                        placeholder="Enter Tax Percentage"
                        name="maxDiscountValue"
                        value={formData.maxDiscountValue}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            maxDiscountValue: e.target.value,
                          });
                        }}
                        onKeyDown={(e)=>{restrictConsecutiveSpace(e)
                       restrictOnlyNumber(e)}}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            {props?.data ? (
              <></>
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
              </>
            )}
            <span className="align-middle">
              {props?.data?.id ? "Update Discount" : "Create New Discount"}
            </span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
