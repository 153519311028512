import React, { useEffect, useState, useRef, useContext } from "react";
import Prism from "prismjs";
import "./menu.scss";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import {
  CreateMain,
  CreateMainFailure,
} from "../../../application/actions/menuAction";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import ManageMainMenu from "./manage-menu";
import environment from "../../../infrastructure/core/Environment";
import {
  getCreateMenuFail,
  getMenuList,
} from "../../../application/selectors/indexSelector";
import {
  restrictAllNumbers,
  restrictAllSpecialCharacters,
  restrictConsecutiveSpace,
  restrictOnlyNumber,
  validateRequired,
  validateRequiredDropdown,
} from "../../../infrastructure/core/validationUtils";
import Environment from "../../../infrastructure/core/Environment";

export default function AddNewMenu(props) {
  const { user } = useContext(AuthContext);
  const menuList = useSelector(getMenuList);
  useEffect(() => {
    Prism.highlightAll();
    if (menuList) {
      setFormData({ ...formData, sequence: menuList?.length + 1 });
    }
  }, [menuList]);
  const dispatch = useDispatch();
  const initialState = {
    mainCatName: "",
    mainCatDesc: "",
    isVisible: true,
    sequence: "",
    createdBy: user.id,
    modifiedBy: user.id,
    isDeleted: false,
    status: Environment.defaultValue,
  };
  const [formData, setFormData] = useState(initialState);

  const Status = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "STS"
  );
  const StatusData=[
    {id:'a5ca48b8-bfd1-42d6-8db1-7021c131e400', fieldValue:'Yes'},
    {id:'a5ca48b8-bfd1-42d6-8db1-7021c131e401', fieldValue:'No'}
  ]
  const CreateMenuFail = useSelector(getCreateMenuFail);
  const [errors, setErrors] = useState({});
  const validateMainCatName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.mainCatName = validateRequired(formData.mainCatName)
      ? ""
      : "Main Menu is required";
    // Validate mainCatId (dropdown)
    newErrors.status = validateRequiredDropdown(formData.status)
      ? ""
      : "Please Select Visibility";

      setErrors(newErrors);
      // Check if any field has errors
      return !Object.values(newErrors).some((error) => error);
  };

  const handleMainCatNameChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      mainCatName: value,
    }));
   setErrors((prevErrors) => ({
      ...prevErrors,
      mainCatName:  "" ,
    }));
  };

  const OnSubmit = (e) => {
    e.preventDefault();

    // Validate mainCatName before submitting
    const isValid = validateMainCatName();
    if (!isValid) {
      return;
    }
    // Proceed with form submission
    const formObject = {
      mainCatName: formData.mainCatName,
      mainCatDesc: formData.mainCatDesc,
      status: formData.status,
      isVisible: true,
      sequence: formData.sequence,
      createdBy: user.id,
      modifiedBy: user.id,
      isDeleted: false,
    };

    dispatch(CreateMain(formObject));
    setFormData(initialState);
  };
  useEffect(() => {
    if (CreateMenuFail !== undefined && !CreateMenuFail) {
      props.closeFunction();
      props.handleManage();
      dispatch(CreateMainFailure(true));
    } else {
      props.closeFunction();
    }
  }, [CreateMenuFail]);
  const handleClose = () => {
    props?.closeFunction();
    setFormData(initialState);
    setErrors({
      mainCatName: "",
    });
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Main Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Main Menu Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Item Name"
                  value={formData.mainCatName}
                  onChange={handleMainCatNameChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}                />
                <Form.Text className="text-danger">
                  {errors.mainCatName}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Short Description"
                  value={formData?.mainCatDesc}
                  onChange={(e) => {
                    setFormData({ ...formData, mainCatDesc: e.target.value });
                  }}
                  onKeyDown={restrictConsecutiveSpace}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Visible For Online Orders:{" "}
                  <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  defaultValue={Environment.defaultValue}
                  onChange={(e) => {
                    setFormData({ ...formData, status: e.target.value });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      status:  "" ,
                    }));
                  }}
                >
                  <option value={`${environment.defaultValue}`} disabled>
                    Is It Visible For Online Orders?
                  </option>
                  {StatusData?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.status}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <div className="mb-4">
                <Form.Label>Menu Sequence</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Sequence"
                  value={formData?.sequence}
                  onChange={(e) => {
                    setFormData({ ...formData, sequence: e.target.value });
                  }}
                  onKeyDown={restrictOnlyNumber}
                />
              </div>
            </Col>
          </Row>
          {/* <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Menu Icon</Form.Label>
                  <div className="custom-update-image">
                    <Form.Control
                      type="file"
                      className="custom-file-input"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileInput"
                    >
                      <div>
                        <i className="ri-upload-line"></i>{" "}
                        <span className="d-block">Browse</span>
                      </div>
                    </label>
                  
                  </div>
                </Form.Group>
              </div>
            </Col>
          </Row> */}
        </Offcanvas.Body>
        <div className="offcanvas-footer" onClick={OnSubmit}>
          <Button variant="primary" className="fs-14">
            {" "}
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create Menu</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
