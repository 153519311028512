import { Navigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../infrastructure/core/helpers/AuthContext";
 
const ProtectedRoute = ({ element, accessBy }) => {
  const { user } = useContext(AuthContext);
  
  if (accessBy === "non-authenticated") {
    if (!user) {
      return <Navigate to="/account/login"></Navigate>;
      // return children;
    }
  } else if (accessBy === "authenticated") {
    if (user) {
      return element;
    }
  }
  return 
};
export default ProtectedRoute;