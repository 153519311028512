import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Offcanvas, Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  MapBranchVariance,
  ResetAllByBranchId,
} from "../../../../application/actions/varianceAction";
import { getVarianceList } from "../../../../application/selectors/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";

export default function MappedLocation(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();
  const VarianceList = useSelector(getVarianceList);
  const handleMappedLocation = (data, checked) => {
    if (!checked) {
      const Obj = VarianceList.globalVariances?.map((item) => ({
        catalogueId: item.catalogueId,
        varianceId: item.id,
        sellingPrice: item.sellingPrice,
        markupPrice: item.markupPrice,
        preparationPrice: item.preparationPrice,
        branchId: data.branchId,
      }));
      dispatch(MapBranchVariance(Obj));
    } else {
      resetByBranch(data);
    }
  };
  const resetByBranch = (data) => {
    const Data = {
      branchId: data.branchId,
      catalogueId: data.variances[0].catalogueId,
    };
    dispatch(ResetAllByBranchId(Data));
  };
  const handleClose = () => {
    const hasVisibleBranch = VarianceList?.locations?.some(
      (branch) => branch.isVisible
    );
    hasVisibleBranch ? props.setGlobal(false) : props.setGlobal(true);
    props.closeFunction();
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-45"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Mapped Location</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table>
            <thead>
              <tr>
                <th>Location</th>
                <th>Added On</th>
              </tr>
            </thead>
            <tbody>
              {VarianceList?.locations?.map((item, index) => (
                <tr key={index}>
                  <td>
                    <label className="cursor-pointer">
                      <input
                        type="checkbox"
                        className="wt-18 ht-18 align-middle"
                        onChange={() =>
                          handleMappedLocation(
                            item,
                            item.variances.length !== 0
                          )
                        }
                        checked={item.variances.length !== 0}
                      />
                      <span className="ms-2 align-middle">
                        {item.branchName}
                      </span>
                    </label>
                  </td>
                  <td>
                    <h5 className="text-dark fs-14">{item.modifiedOn}</h5>
                    <h6 className="text-dark fs-12">{item.modifiedByName}</h6>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Offcanvas.Body>

        {/* <div className="offcanvas-footer bg-white">
                    <Button variant="primary" className="d-flex align-items-center gap-1 me-2" onClick={()=> handleCopyBranch(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i>
                        <span className="d-sm-inline">Include</span>
                    </Button>
                </div> */}
      </Offcanvas>
    </React.Fragment>
  );
}
