export const GENERAL_SETTING="GENERAL_SETTING";
export const GENERAL_SETTING_SUCCESS="GENERAL_SETTING_SUCCESS";
export const GET_POS_GEN_SETTING="GET_POS_GEN_SETTING";


export const GeneralSetting=(data)=>({
    type:GENERAL_SETTING,
    payload:data
})
export const GetPOSGenSetting=(tenantId)=>({
    type:GET_POS_GEN_SETTING,
    payload:tenantId
})
export const GeneralSettingSuccess=(id)=>({
    type:GENERAL_SETTING_SUCCESS,
    payload:id
})