import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const GetBranchList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.SetUp_Url}branch-by-tenant/${data}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const GetBranchOTSessions = async (branchId) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Setting_Url}branch-OT-Sessions/${branchId}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const UpdateBasicInfo = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.SetUp_Url}update-branch`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const UpdateOperatingHors = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post( `${Environment.Setting_Url}branch-operation-time`,data, {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
export default { UpdateOperatingHors,GetBranchOTSessions,GetBranchList, UpdateBasicInfo };
