import React, { useEffect, useState, useContext } from "react";
import Header from "../../layouts/Header";
import Environment from "../../infrastructure/core/Environment";
import { debounce } from "lodash";
import { Row, Col, Card, Button, Nav, ProgressBar, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
import prod1 from "../../assets/img/prod1.jpg";
import prod2 from "../../assets/img/prod2.jpg";
import prod3 from "../../assets/img/prod3.jpg";
import prod4 from "../../assets/img/prod4.jpg";

export default function Dashboard() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }
    switchSkin(skin);
    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const [branchData, setBranchData] = useState(Environment.defaultValue);
    const handleBranchIdFromHeader = debounce((data) => {
        setBranchData(data);
    }, 300);

    const { selectedBranchData } = useContext(AuthContext);
    const parsedBranchData = JSON.parse(selectedBranchData);
    const d1 = [[0, 38], [1, 32], [2, 31], [3, 33], [4, 34], [5, 35], [6, 38], [7, 37], [8, 39], [9, 34], [10, 33], [11, 32], [12, 34], [13, 38], [14, 42], [15, 43], [16, 45], [17, 43], [18, 45], [19, 48], [20, 45], [21, 46], [22, 44], [23, 42], [24, 46], [25, 48], [26, 55], [27, 54], [28, 58], [29, 69]];
    const d2 = [[0, 12], [1, 11], [2, 9], [3, 10], [4, 15], [5, 9], [6, 11], [7, 12], [8, 7], [9, 16], [10, 18], [11, 24], [12, 16], [13, 10], [14, 9], [15, 16], [16, 15], [17, 28], [18, 20], [19, 15], [20, 20], [21, 12], [22, 15], [23, 12], [24, 10], [25, 12], [26, 14], [27, 18], [28, 20], [29, 21],];

    // Tickets Earnings
    const seriesOne = [{
        name: 'series1',
        data: d1
    }, {
        name: 'series2',
        data: d2
    }];

    const optionOne = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false },
            stacked: true,
            sparkline: { enabled: true }
        },
        colors: ['#85b6fe', '#506fd9'],
        stroke: {
            curve: 'straight',
            width: 2
        },
        yaxis: {
            min: 0,
            max: 150,
            show: false
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.5,
                opacityTo: 0,
            }
        },
        tooltip: { enabled: false }
    };

    // Tickets Available
    const seriesTwo = [{
        data: [[0, 1], [1, 2], [2, 5], [3, 6], [4, 8], [5, 10], [6, 15], [7, 18], [8, 13], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5], [15, 8], [16, 11], [17, 7], [18, 5], [19, 5], [20, 6], [21, 6], [22, 5], [23, 5], [24, 6], [25, 2], [26, 1]]
    }, {
        data: [[0, 2], [1, 3], [2, 4], [3, 8], [4, 4], [5, 12], [6, 4], [7, 6], [8, 5], [9, 10], [10, 4], [11, 5], [12, 10], [13, 2], [14, 6], [15, 16], [16, 5], [17, 17], [18, 14], [19, 6], [20, 5], [21, 2], [22, 12], [23, 4], [24, 7], [25, 1], [26, 2], [27, 1], [28, 2], [29, 2], [30, 1], [31, .2], [32, .2], [33, .2], [34, .2], [35, .2], [36, .2], [37, .2], [38, .2], [39, .2], [40, .2], [41, .2], [42, .2], [43, .2], [44, .2], [45, .2], [46, .2], [47, .2], [48, .2], [49, .2]]
    }];

    const optionTwo = {
        chart: {
            stacked: true,
            sparkline: { enabled: true }
        },
        states: {
            hover: {
                filter: { type: 'none' }
            },
            active: {
                filter: { type: 'none' }
            }
        },
        colors: ['#506fd9', '#e5e9f2'],
        grid: {
            padding: {
                bottom: 10,
                left: 0,
                right: 0
            }
        },
        plotOptions: {
            bar: {
                columnWidth: '40%',
                endingShape: 'rounded'
            },
        },
        stroke: {
            curve: 'straight',
            lineCap: 'square'
        },
        yaxis: {
            min: 0,
            max: 30
        },
        fill: { opacity: 1 },
        tooltip: { enabled: false }
    };

    // Tickets Sold
    const seriesThree = [{
        name: 'series1',
        data: d1
    }]

    const optionThree = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false },
            sparkline: { enabled: true }
        },
        colors: ['#506fd9'],
        stroke: {
            curve: 'straight',
            width: 2
        },
        xaxis: { max: 20 },
        yaxis: {
            min: 0,
            max: 160,
            show: false
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.5,
                opacityTo: 0,
            }
        },
        tooltip: { enabled: false }
    };

    // Tickets Unsold
    const optionFour = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false },
            sparkline: { enabled: true }
        },
        colors: ['#85b6ff'],
        stroke: {
            curve: 'straight',
            width: 2
        },
        xaxis: {
            min: 3,
            max: 25
        },
        yaxis: {
            min: 0,
            max: 160,
            show: false
        },
        fill: {
            type: 'gradient',
            gradient: {
                opacityFrom: 0.5,
                opacityTo: 0,
            }
        },
        tooltip: { enabled: false }
    };

    // Time to Resolved Complain
    const optionDonut1 = {
        chart: { parentHeightOffset: 0 },
        colors: ['#506fd9', '#e5e9f2'],
        dataLabels: { enabled: false },
        legend: { show: false }
    };

    const optionDonut2 = {
        chart: { parentHeightOffset: 0 },
        colors: ['#4c5366', '#e5e9f2'],
        dataLabels: { enabled: false },
        legend: { show: false }
    }

    return (
        <React.Fragment>
            <Header passDataToPointOfSale={handleBranchIdFromHeader} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Welcome to Dashboard</h4>
                    </div>
                </div>

                <Row className="g-3 mb-4">
                    <Col xl="6">
                        <Card className="card-one card-ticket-earnings">
                            <Card.Body className="d-flex flex-column p-4">
                                <h1 className="card-value mb-3 fs-40 ls--2"><span><i className="ri-equalizer-line text-dark"></i></span>05</h1>
                                <label className="card-label fw-semibold text-dark mb-1">Total Barnches</label>
                                <p className="w-75 fs-sm text-secondary mb-4">Etiam ultricies nisi vel augue. Curabitur ullamcorper ultricies nisi. Nam eget dui. Etiam rhoncus.</p>
                                <p className="mb-5">
                                    <Button as="a" variant="primary" className="fs-sm">View Statements</Button>
                                </p>

                                <div className="d-flex gap-4 fs-sm mt-auto text-primary-dark lh-1 opacity-75">
                                    <span><strong className="fw-semibold ff-numerals">11,716</strong> Tickets Sold</span>
                                    <span><strong className="fw-semibold ff-numerals">12,864</strong> Tickets Unsold</span>
                                </div>
                            </Card.Body>
                            <ReactApexChart series={seriesOne} options={optionOne} type="area" height={390} className="apex-chart-two d-flex align-items-end" />
                        </Card>
                    </Col>

                    <Col xl="6">
                        <Row className="g-3">
                            <Col xs="12">
                                <Card className="card-one card-ticket-available">
                                    <Card.Body className="p-4">
                                        <div className="d-flex justify-content-end">
                                            <div className="w-45">
                                                <h1 className="card-value fs-32 mb-2 ls--1">24,580</h1>
                                                <label className="card-label fw-semibold text-dark mb-1">Tickets Available</label>
                                                <p className="text-secondary fs-sm mb-0">Cura bitur ullam corper ultri cies nisi nam eget dui etiam rhoncus.</p>
                                            </div>
                                        </div>
                                    </Card.Body>
                                    <ReactApexChart series={seriesTwo} options={optionTwo} type="bar" height={170} className="apex-chart" />
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className="card-one card-ticket-sold">
                                    <Card.Body className="p-4">
                                        <div className="d-flex flex-column align-items-center">
                                            <h1 className="card-value fs-32 mb-2 ls--1">11,716</h1>
                                            <label className="card-label fw-semibold text-dark mb-1">Tickets Sold</label>
                                            <p className="text-secondary text-center fs-sm mb-0">Ullam corper ultricies cura bitur nisi nam eget dui etia.</p>
                                        </div>
                                    </Card.Body>
                                    <ReactApexChart series={seriesThree} options={optionThree} type="area" height={210} className="apex-chart" />
                                </Card>
                            </Col>
                            <Col sm="6">
                                <Card className="card-one card-ticket-sold">
                                    <Card.Body className="p-4">
                                        <div className="d-flex flex-column align-items-center">
                                            <h1 className="card-value fs-32 mb-2 ls--1">12,864</h1>
                                            <label className="card-label fw-semibold text-dark mb-1">Tickets Unsold</label>
                                            <p className="text-secondary text-center fs-sm mb-0">Nam libero tempore, cum soluta nobis est eligendi.</p>
                                        </div>
                                    </Card.Body>
                                    <ReactApexChart series={seriesThree} options={optionFour} type="area" height={210} className="apex-chart" />
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className="mb-4">
                    <Col md="6">
                        <Card className="card-one">
                            <Card.Body className="p-3">
                                <Row className="g-3 row-cols-auto align-items-center">
                                    <Col>
                                        <div className="apex-donut-one">
                                            <ReactApexChart series={[86, 14]} options={optionDonut1} width={160} height={160} type="donut" />
                                            <div>
                                                <h4 className="ff-numerals text-dark mb-0">86%</h4>
                                                <span className="fs-xs text-secondary">Reached</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="6" xl="7">
                                        <h2 className="card-value mb-3 fs-18 fw-semibold">Todays Summary</h2>
                                        <h6 className="card-label text-dark fw-semibold mb-1">Todays Summary</h6>
                                        <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md="6">
                        <Card className="card-one">
                            <Card.Body className="p-3">
                                <Row className="g-3 row-cols-auto align-items-center">
                                    <Col>
                                        <div className="apex-donut-one">
                                            <ReactApexChart series={[68, 32]} options={optionDonut2} width={160} height={160} type="donut" />
                                            <div>
                                                <h4 className="ff-numerals text-dark mb-0">68%</h4>
                                                <span className="fs-xs text-secondary">Reached</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs="6" xl="7">
                                        <h2 className="card-value mb-3 fs-18 fw-semibold">Sales Revenue Bifurcation</h2>
                                        <h6 className="card-label text-dark fw-semibold mb-1">Sales Revenue Bifurcation</h6>
                                        <p className="fs-sm text-secondary mb-0">The average time taken to resolve complaints.</p>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Card className="card-one h-auto mb-4">
                    <Card.Body className="p-3 p-xl-4">
                        <Row className="justify-content-center g-3 mb-2 mb-xl-4">
                            <Col sm="6" xl="3">
                                <div className="finance-item">
                                    <div className="finance-item-circle">
                                        <h1>4.7B</h1>
                                        <label>Total Income</label>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" xl="3">
                                <div className="finance-item">
                                    <div className="finance-item-circle">
                                        <h1>60M</h1>
                                        <label>Total Expenses</label>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" xl="3">
                                <div className="finance-item">
                                    <div className="finance-item-circle bg-gray-400">
                                        <h1>2.1B</h1>
                                        <label>Net Profit</label>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" xl="3">
                                <div className="finance-item">
                                    <div className="finance-item-circle">
                                        <h1>18.2%</h1>
                                        <label>Quick Ratio</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row className="g-4 g-lg-5 pt-3">
                            <Col sm="6" xl="3">
                                <div className="d-flex">
                                    <i className="ri-wallet-2-line fs-32 lh-1 me-3"></i>
                                    <div>
                                        <h6 className="fw-semibold text-dark mb-2">Accounts Receivable</h6>
                                        <p className="fs-sm text-secondary mb-0">The proceeds or payment which the company will receive from its customers.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" xl="3">
                                <div className="d-flex">
                                    <i className="ri-refund-2-line fs-32 lh-1 me-3"></i>
                                    <div>
                                        <h6 className="fw-semibold text-dark mb-2">Accounts Payable</h6>
                                        <p className="fs-sm text-secondary mb-0">Money owed by a business to its suppliers shown as a liability.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" xl="3">
                                <div className="d-flex">
                                    <i className="ri-exchange-line fs-32 lh-1 me-3"></i>
                                    <div>
                                        <h6 className="fw-semibold text-dark mb-2">Quick Ratio</h6>
                                        <p className="fs-sm text-secondary mb-0">Measures the ability of a company to use its near cash or quick assets.</p>
                                    </div>
                                </div>
                            </Col>
                            <Col sm="6" xl="3">
                                <div className="d-flex">
                                    <i className="ri-exchange-dollar-line fs-32 lh-1 me-3"></i>
                                    <div>
                                        <h6 className="fw-semibold text-dark mb-2">Current Ratio</h6>
                                        <p className="fs-sm text-secondary mb-0">Measures whether a firm has enough resources to meet its short-term obligations.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

                <Row className="g-4 g-lg-5 pt-3 mb-4">
                    <Col xl="12">
                        <Card className="card-one h-auto">
                            <Card.Header className="border-0 pb-2">
                                <Card.Title as="h6">Profit Margin (%)</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="pt-0">
                                <p className="fs-sm text-secondary mb-4">Profit margin is a measure of profitability. It is calculated by finding the profit as a percentage of the revenue.</p>

                                <ProgressBar className="progress-finance mb-4">
                                    <ProgressBar now={29.7} label="29.7%" />
                                    <ProgressBar now={52.8} label="52.8%" />
                                    <ProgressBar now={18.3} label="18.3%" />
                                </ProgressBar>

                                <Row className="g-3">
                                    <Col>
                                        <label className="card-label fs-sm fw-medium mb-1">Gross Profit</label>
                                        <h2 className="card-value mb-0">29.7%</h2>
                                    </Col>
                                    <Col xs="5" sm>
                                        <label className="card-label fs-sm fw-medium mb-1">Operating Profit</label>
                                        <h2 className="card-value mb-0">52.8%</h2>
                                    </Col>
                                    <Col>
                                        <label className="card-label fs-sm fw-medium mb-1">Net Profit</label>
                                        <h2 className="card-value mb-0">18.3%</h2>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Row className="g-4">
                    <Col xl="12">
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Popular Products</Card.Title>
                                <Nav className="nav-icon nav-icon-sm ms-auto">
                                    <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                                    <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                                </Nav>
                            </Card.Header>
                            <Card.Body className="p-3">
                                {[
                                    {
                                        "image": prod1,
                                        "name": "iPhone 14 Pro Max Leather Case",
                                        "text": "Leather Case with MagSafe",
                                        "price": `${parsedBranchData?.currencySymbol} 35.50`,
                                        "available": "560",
                                        "sold": "40",
                                        "sales": `${parsedBranchData?.currencySymbol} 1,420.00`
                                    }, {
                                        "image": prod2,
                                        "name": "Apple Watch Series 7 GPS",
                                        "text": "Midnight Aluminum Sport Band",
                                        "price": `${parsedBranchData?.currencySymbol} 395.99`,
                                        "available": "350",
                                        "sold": "150",
                                        "sales": `${parsedBranchData?.currencySymbol} 59,398.50`
                                    }, {
                                        "image": prod3,
                                        "name": "Apple Wireless Black Airpods",
                                        "text": "(3rd Generation) Wireless Earbuds",
                                        "price": `${parsedBranchData?.currencySymbol} 210.95`,
                                        "available": "250",
                                        "sold": "100",
                                        "sales": `${parsedBranchData?.currencySymbol} 21,095.00`
                                    }, {
                                        "image": prod4,
                                        "name": "Macbook Air M2 Chip",
                                        "text": "16GB Ram 512GB SSD Storage",
                                        "price": `${parsedBranchData?.currencySymbol} 1,910.00`,
                                        "available": "100",
                                        "sold": "50",
                                        "sales": `${parsedBranchData?.currencySymbol} 95,500.00`
                                    }
                                ].map((item, index) => (
                                    <div className="product-wrapper" key={index}>
                                        <Table>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <div className="product-thumb">
                                                            <img src={item.image} alt="" />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <h6 className="mb-1">
                                                            <Link to="">{item.name}</Link>
                                                        </h6>
                                                        <span className="fs-sm text-secondary">{item.text}</span>
                                                    </td>
                                                    <td>
                                                        <label className="d-block text-secondary fs-sm mb-1">Price</label>
                                                        <span className="d-block fw-medium text-dark ff-numerals">{item.price}</span>
                                                    </td>
                                                    <td>
                                                        <label className="d-block text-secondary fs-sm mb-1">Available</label>
                                                        <span className="d-block fw-medium text-dark ff-numerals">{item.available}</span>
                                                    </td>
                                                    <td>
                                                        <label className="d-block text-secondary fs-sm mb-1">Sold</label>
                                                        <span className="d-block fw-medium text-dark ff-numerals">{item.sold}</span>
                                                    </td>
                                                    <td>
                                                        <label className="d-block text-secondary fs-sm mb-1">Sales</label>
                                                        <span className="d-block fw-medium text-dark ff-numerals">{item.sales}</span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}