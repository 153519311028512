import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const AddOrderType  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.post(`${Environment.Setting_Url}add-order-type`,data,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  }
  const OrderType  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.get(`${Environment.Setting_Url}order-types`,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  } 
   const LoadOrderType  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.get(`${Environment.Setting_Url}branch-order-types/${data}`,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  }
  const DeleteOrderType  = async (id)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.post(`${Environment.Setting_Url}delete-order-type?id=${id}`,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  } 
  const UpdateOrderTypeStatus  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.post(`${Environment.Setting_Url}update-order-type`,data,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  }  
  const UpdateOrderTypeVisibility  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.post(`${Environment.Setting_Url}update-order-type-visibility/${data.branchId}/${data.orderId}/${data.isVisible}`,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  }
  export default {AddOrderType,LoadOrderType,DeleteOrderType,UpdateOrderTypeStatus,OrderType,UpdateOrderTypeVisibility};