import { Navigate } from "react-router-dom";
import {CREATE_ORDER_TYPE,LOAD_ORDER_TYPE,DELETE_ORDER_TYPE,UPDATE_ORDER_TYPE_STATUS,LoadOrderTypeListSuccess, COMMON_FIELD, GET_ORDERTYPE_DETAIL, ORDER_TYPE, OrderTypeSuccess, ORDER_TYPE_VISIBILITY} from "../actions/orderTypeAction";
import * as Loading from "../actions/loaderAction";
import * as table from "../actions/tableAction";
import ToastifyService from "../../View/_common/ToastifyService";

const LoadOrderTypeByBranchId = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    if (action.type === LOAD_ORDER_TYPE) {
      try {
      dispatch(Loading.setLoading({loading:true, value:'loadOrderType'}))
      const OrderTypeData = await api.orderTypeAPI.LoadOrderType(action.payload);
      dispatch(LoadOrderTypeListSuccess(OrderTypeData));
      dispatch(Loading.setLoading({loading:false, value:'loadOrderType'}))
      } catch (error) {
        dispatch(Loading.setLoading({loading:false, value:'loadOrderType'}))
        if(error.response.data === "Invalid access token or refresh token"){
          localStorage.removeItem("Token");
          <Navigate to="/account/login"/>;
        }
      }
    }
    next(action);
  };
const OrderType = ({ api }) => ({ dispatch }) => (next) => async (action) => {
    if (action.type === ORDER_TYPE) {
      try {
      dispatch(Loading.setLoading({loading:true, value:''}))
      const OrderTypeData = await api.orderTypeAPI.OrderType();
      dispatch(OrderTypeSuccess(OrderTypeData));
      dispatch(Loading.setLoading({loading:false, value:''}))
      } catch (error) {
        if(error.response.data === "Invalid access token or refresh token"){
          localStorage.removeItem("Token");
          <Navigate to="/account/login"/>;
        }
      }
    }
    next(action);
  };

const CreateOrderType = ({ api }) => ({ dispatch }) => (next) => async (action) => {
  if (action.type === CREATE_ORDER_TYPE) {
    try {
      dispatch(Loading.setLoading({loading:true, value:''}));
      const OrderTypeData = await api.orderTypeAPI.AddOrderType(action.payload.data);
      dispatch(LoadOrderTypeListSuccess(OrderTypeData));
      dispatch(Loading.setLoading({loading:false, value:''}));
      ToastifyService.success("Order Type Created Successfully! ");
    } catch (error) {
      ToastifyService.error("Something Went wrong! ");
      dispatch(Loading.setLoading({loading:false, value:''}));
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
        <Navigate to="/account/login" />;
      }
    }
  }
  next(action);
};
const DeleteOrderType = ({ api }) => ({ dispatch }) => (next) => async (action) => {
  if (action.type === DELETE_ORDER_TYPE) {
    try {
      dispatch(Loading.setLoading({loading:true, value:''}));
      const OrderTypeData = await api.orderTypeAPI.DeleteOrderType(action.payload.id);
      dispatch(LoadOrderTypeListSuccess(OrderTypeData));
      dispatch(Loading.setLoading({loading:false, value:''}));
      ToastifyService.success("Order Type deleted Successfully! ");
    } catch (error) {
      ToastifyService.error("Something Went wrong! ");
      dispatch(Loading.setLoading({loading:false, value:''}));
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
        <Navigate to="/account/login" />;
      }
      //    dispatch(loadFailure(error));
    }
  }
  next(action);
};

const UpdateOrderTypeStatus = ({ api }) => ({ dispatch }) => (next) => async (action) => {
  if (action.type === UPDATE_ORDER_TYPE_STATUS) {
    try {
      dispatch(Loading.setLoading({loading:true, value:''}));
      const OrderTypeData = await api.orderTypeAPI.UpdateOrderTypeStatus(action.payload.data);
      dispatch(LoadOrderTypeListSuccess(OrderTypeData));
      dispatch(Loading.setLoading({loading:false, value:''}));
      ToastifyService.success("Order type Details Updated Successfully! ");
    } catch (error) {
      ToastifyService.error("Something Went wrong! ");
      dispatch(Loading.setLoading({loading:false, value:''}));
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
        <Navigate to="/account/login" />;
      }
      //    dispatch(loadFailure(error));
    }
  }
  next(action);
};
const UpdateOrderTypeVisibility = ({ api }) => ({ dispatch }) => (next) => async (action) => {
  if (action.type === ORDER_TYPE_VISIBILITY) {
    try {
      dispatch(Loading.setLoading({loading:true, value:''}));
      const OrderTypeData = await api.orderTypeAPI.UpdateOrderTypeVisibility(action.payload);
      dispatch(LoadOrderTypeListSuccess(OrderTypeData));
      dispatch(Loading.setLoading({loading:false, value:''}));
      ToastifyService.success("Order Type Visibility Status Updated Successfully! ");
    } catch (error) {
      ToastifyService.error("Something Went wrong! ");
      dispatch(Loading.setLoading({loading:false, value:''}));
      if (error.response.data === "Invalid access token or refresh token") {
        localStorage.removeItem("Token");
        <Navigate to="/account/login" />;
      }
      //    dispatch(loadFailure(error));
    }
  }
  next(action);
};
export default [CreateOrderType,LoadOrderTypeByBranchId,DeleteOrderType,UpdateOrderTypeStatus, OrderType,UpdateOrderTypeVisibility];
