import React, { useContext, useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Tab, Nav } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import MarkAttendace from "./MarkAttendance/mark-attendance";
import MonthlyReport from "./MonthlyReport/monthly-report";
import DaysWiseReport from "./DaysWiseReport/days-wise-report";
import { Link } from "react-router-dom";
import Environment from "../../infrastructure/core/Environment";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { GetEmployeeMonthlyReport } from "../../application/actions/attendanceAction";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
import { LoadEmployee } from "../../application/actions/employeeAction";
import { getEmployeeList } from "../../application/selectors/indexSelector";
import { GetBranch } from "../../application/actions/branchAction";

export default function Attendance() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const { user } = useContext(AuthContext);
  const AttendanceStatus = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "AttSTS"
      )
    : [];

  const dispatch = useDispatch();
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  dispatch(GetBranch(user.tenanatId))
    dispatch(
      GetEmployeeMonthlyReport({
        id: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        status: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        tenantId: user?.tenanatId,
        isVisible: true,
        isDeleted: true,
        createdOn: "2024-07-31T09:30:15.736Z",
        createdBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        createdByName: "string",
        modifiedOn: "2024-07-31T09:30:15.736Z",
        modifiedBy: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        modifiedByName: "string",
        month: 1,
        year: 2024,
        branchId: user?.branchId,
      })
    );
  }, [skin]);

  useEffect(() => {
    document.body.classList.add("page-app");
    return () => {
      document.body.classList.remove("page-app");
    };
  }, []);

  // toggle sidebar in mobile
  // toggle sidebar in mobile
  const [isSidebarShow, setSidebarShow] = useState(false);

  return (
    <React.Fragment>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="mark_attendance_tab"
      >
        <div
          className={"main main-file-manager" + (isSidebarShow ? " show" : "")}
        >
          <PerfectScrollbar className="file-sidebar page-in-tabs">
            <Nav variant="pills">
              <Nav variant="pills">
                <Nav.Item>
                  <Nav.Link
                    className="position-relative"
                    eventKey="mark_attendance_tab"
                  >
                    <i className="ri-user-follow-line align-middle"></i> Mark
                    Attendance
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="position-relative"
                    eventKey="monthly_report_tab"
                  >
                    <i className="ri-calendar-line align-middle"></i> Monthly
                    Report
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    className="position-relative"
                    eventKey="daywise_report_tab"
                  >
                    <i className="ri-calendar-line align-middle"></i> Day Wise
                    Report
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Nav>
          </PerfectScrollbar>

          <PerfectScrollbar className="file-content p-3 p-lg-4">
            <Link
              href=""
              className="menu-file-manager"
              onClick={() => setSidebarShow(!isSidebarShow)}
            >
              <i className="ri-arrow-left-line"></i>
            </Link>
            <Tab.Content>
              <Tab.Pane eventKey="mark_attendance_tab">
                <MarkAttendace AttendanceStatus={AttendanceStatus} />
              </Tab.Pane>
              <Tab.Pane eventKey="monthly_report_tab">
                <MonthlyReport />
              </Tab.Pane>
              <Tab.Pane eventKey="daywise_report_tab">
                <DaysWiseReport />
              </Tab.Pane>
            </Tab.Content>
          </PerfectScrollbar>
        </div>
      </Tab.Container>
    </React.Fragment>
  );
}
