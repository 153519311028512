import React, { useState } from "react";
import { Button, Row, Col, Form, Offcanvas, Card } from "react-bootstrap";
import qzTrayIcon from "../../assets/img/icons/qz-tray.png";
import AddPrinter from "./add-printer";

export default function PrintConfiguration(props) {
    const [showAddPrinter, setShowAddPrinter] = useState(false);
    const handleAddPrinter = (state) => {
        setShowAddPrinter(state)
    };

    return (
        <React.Fragment>
            <AddPrinter show={showAddPrinter} closeFunction={handleAddPrinter} />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-35">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Printer Configuration</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>

                    <div>
                        <h3 className="fs-16 text-dark mb-3"><img src={qzTrayIcon} alt="trayicon" /> Download QZ Tray</h3>
                        <Row>
                            <Col xl={6}>
                                <Button variant="outline-primary" type="button"
                                    className="d-flex align-items-center gap-2 justify-content-center w-100">
                                    <i className="ri-windows-fill fs-18"></i>Download for Windows
                                </Button>
                            </Col>
                            <Col xl={6}>
                                <Button variant="outline-primary" type="button"
                                    className="d-flex align-items-center gap-2 justify-content-center w-100">
                                    <i className="ri-apple-fill fs-18"></i>Download for Mac OS
                                </Button>
                            </Col>
                        </Row>
                    </div>

                    <hr />

                    <div>
                        <div className="d-flex align-items-center justify-content-between mb-4">
                            <div>
                                <h3 className="fs-16 text-dark">Print Management</h3>
                            </div>
                        </div>

                        <div className="d-flex align-items-center justify-content-between mb-3 mt-4 pt-3">
                            <div>
                                <h3 className="fs-16 text-dark">List of Kitchen Group</h3>
                            </div>
                        </div>
                        <Row className="mb-3">
                            <Col>
                                <Card className="card-one">
                                    <Card.Body>
                                        <div>
                                            <h3 className="mb-0 fs-14 mb-3 fw-semibold ">Juice Counter</h3>
                                        </div>
                                        <div>
                                            <Form.Select>
                                                <option>Choose Printer</option>
                                                <option value={1}>POS-80C</option>
                                                <option value={2}>Epsont L1210</option>
                                                <option value={3}>Canol Thurmal 80C</option>
                                            </Form.Select>
                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                <h6 className="fs-10 text-uppercase text-danger fw-semibold">
                                                    <i className="ri-close-line fs-12 align-middle"></i> Not Connected
                                                </h6>

                                                <Button variant="outline-primary" className="p-1 pb-0 pt-0 fs-12" type="button">
                                                    <i className="ri-restart-line align-middle"></i> <span className="align-middle">Test</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="card-one">
                                    <Card.Body>
                                        <div>
                                            <h3 className="mb-0 fs-14 mb-3 fw-semibold">Pizza Counter</h3>
                                        </div>
                                        <div>
                                            <Form.Select>
                                                <option>Choose Printer</option>
                                                <option value={1}>POS-80C</option>
                                                <option value={2}>Epsont L1210</option>
                                                <option value={3}>Canol Thurmal 80C</option>
                                            </Form.Select>
                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                <h6 className="fs-10 text-uppercase text-danger fw-semibold">
                                                    <i className="ri-close-line fs-16 align-middle"></i> Not Connected
                                                </h6>

                                                <Button variant="outline-primary" className="p-1 pb-0 pt-0 fs-12" type="button">
                                                    <i className="ri-restart-line align-middle"></i> <span className="align-middle">Test</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>

                        <div className="d-flex align-items-center justify-content-between mb-3 mt-3 pt-3 border-top">
                            <div>
                                <h3 className="fs-16 text-dark">Bill Print</h3>
                            </div>
                        </div>

                        <Row>
                            <Col>
                                <Card className="card-one mb-3">
                                    <Card.Body>
                                        <div className="mb-3">
                                            <h3 className="mb-0 fs-14 fw-semibold mb-2">Bill Printer</h3>
                                            <h4 className="mb-0 fs-12">Template Name: <b>Template 2548</b></h4>
                                        </div>
                                        <div>
                                            <Form.Select>
                                                <option>Choose Printer</option>
                                                <option value={1}>POS-80C</option>
                                                <option value={2}>Epsont L1210</option>
                                                <option value={3}>Canol Thurmal 80C</option>
                                            </Form.Select>
                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                <h6 className="fs-10 text-uppercase text-success fw-semibold">
                                                    <i className="ri-check-line fs-16 align-middle"></i> Connected
                                                </h6>

                                                <Button variant="outline-primary" className="p-1 pb-0 pt-0 fs-12" type="button">
                                                    <i className="ri-restart-line align-middle"></i> <span className="align-middle">Test</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col>
                                <Card className="card-one">
                                    <Card.Body>
                                        <div className="mb-3">
                                            <h3 className="mb-0 fs-14 fw-semibold mb-2">Online Invoice</h3>
                                            <h4 className="mb-0 fs-12">Template Name: <b>Template 2548</b></h4>
                                        </div>
                                        <div>
                                            <Form.Select>
                                                <option>Choose Printer</option>
                                                <option value={1}>POS-80C</option>
                                                <option value={2}>Epsont L1210</option>
                                                <option value={3}>Canol Thurmal 80C</option>
                                            </Form.Select>
                                            <div className="d-flex align-items-center justify-content-between mt-1">
                                                <h6 className="fs-10 text-uppercase text-success fw-semibold">
                                                    <i className="ri-check-line fs-16 align-middle"></i> Connected
                                                </h6>

                                                <Button variant="outline-primary" className="p-1 pb-0 pt-0 fs-12" type="button">
                                                    <i className="ri-restart-line align-middle"></i> <span className="align-middle">Test</span>
                                                </Button>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}