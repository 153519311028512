import React, { useCallback, useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  getCreateOrderSuccess,
  getPaymentModeList,
} from "../../../application/selectors/indexSelector";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

const SettlePaymentMultiple = ({
  onPaymentChange,
  resetChild,
  setResetChild,
}) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const paymentModes = useSelector(getPaymentModeList);
  const reorderedPaymentModes = paymentModes.slice().sort((a, b) => {
    if (a.title === "Cash") return -1;
    if (b.title === "Cash") return 1;
    return 0;
  });
  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  const [state, setState] = useState({
    selectedPayments: [],
    amounts: {},
    transactionIds: {},
    tips: "",
  });
  useEffect(() => {
    if (resetChild) {
      // Reset the state to initial values when resetChild changes
      setState({
        selectedPayments: [],
        amounts: {},
        transactionIds: {},
        tips: "",
      });
      setResetChild(false);
    }
  }, [resetChild]);
  const calculateCashBalance = () => {
    const totalPaidAmount = state.selectedPayments.reduce(
      (total, paymentId) => {
        const amount = parseFloat(state.amounts[paymentId]) || 0;
        return total + amount;
      },
      0
    );
    const tips = parseFloat(state.tips) || 0;
    const billAmount = createOrderSuccess?.grandTotalPrice || 0;
    return totalPaidAmount - (billAmount + tips);
  };

  const memoizedOnPaymentChange = useCallback(
    (updatedPaymentList, cashBalance, tips) => {
      onPaymentChange(updatedPaymentList, cashBalance, tips);
    },
    [onPaymentChange]
  );

  useEffect(() => {
    const tips = parseFloat(state.tips) || 0;
    const updatedPaymentList = state.selectedPayments.map((paymentId) => {
      const paidAmount = parseFloat(state.amounts[paymentId]) || 0;

      return {
        paymentMode: paymentId,
        transactionId: state.transactionIds[paymentId] || "",
        paymentResponse: "-",
        paidAmount: Number(paidAmount.toFixed(2)),
      };
    });

    memoizedOnPaymentChange(updatedPaymentList, calculateCashBalance(), tips);
  }, [
    state.selectedPayments,
    state.amounts,
    state.transactionIds,
    state.tips,
    memoizedOnPaymentChange,
    calculateCashBalance,
  ]);

  const handlePaymentChange = (mode) => {
    const selectedPayments = [...state.selectedPayments];
    const index = selectedPayments.indexOf(mode.id);

    if (index === -1) {
      selectedPayments.push(mode.id);
    } else {
      selectedPayments.splice(index, 1);
    }

    setState((prev) => ({
      ...prev,
      selectedPayments,
    }));
  };

  const handleAmountChange = (paymentId, amount, transactionId) => {
    setState((prev) => ({
      ...prev,
      amounts: {
        ...prev.amounts,
        [paymentId]: amount,
      },
      transactionIds: {
        ...prev.transactionIds,
        [paymentId]: transactionId,
      },
    }));
  };
  const handleTipsChange = (tips) => {
    setState((prev) => ({
      ...prev,
      tips: parseFloat(tips) || 0,
    }));
  };

  const cashBalance = calculateCashBalance();

  return (
    <React.Fragment>
      {reorderedPaymentModes
        .filter(
          (mode) =>
            mode.id !== "a1ca48b8-bfd1-42d6-8db1-7021c131e002" &&
            mode.id !== "a1ca48b8-bfd1-42d6-8db1-7021c131e003"
        )
        .map((mode) => (
          <Row key={mode.id} className="mb-4 align-items-center">
            <Col xl={3}>
              <label className="cursor-pointer">
                <input
                  type="checkbox"
                  name="payment"
                  className="align-middle"
                  onChange={() => handlePaymentChange(mode)}
                  checked={state.selectedPayments.includes(mode.id)}
                />
                <span className="ms-2 fw-bold align-middle text-dark fw-bold">
                  {mode.title}
                </span>
              </label>
            </Col>

            <Col xl={9}>
              <Row>
                <Col xl={5}>
                  <Form.Control
                    type="number"
                    placeholder="Amount"
                    min={0}
                    disabled={!state.selectedPayments.includes(mode.id)}
                    onChange={(e) =>
                      handleAmountChange(
                        mode.id,
                        e.target.value,
                        state.transactionIds[mode.id]
                      )
                    }
                  />
                </Col>

                {mode.isTransactionIdRequired && (
                  <Col xl={5}>
                    <Form.Control
                      type="text"
                      placeholder="Transaction ID"
                      disabled={!state?.selectedPayments?.includes(mode.id)}
                      onChange={(e) =>
                        handleAmountChange(
                          mode.id,
                          state.amounts[mode.id],
                          e.target.value
                        )
                      }
                    />
                  </Col>
                )}
                {mode.isTransactionIdMandatory &&
                  state?.selectedPayments?.includes(mode.id) && (
                    <Col xl={1}>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-top`}>
                            This field is required
                          </Tooltip>
                        }
                      >
                        <span>
                          <i className="fs-20 ri-error-warning-line text-danger"></i>
                        </span>
                      </OverlayTrigger>
                    </Col>
                  )}
              </Row>
            </Col>
          </Row>
        ))}

      <Row className="mb-4 align-items-center">
        <Col xl={8}>
          <h5 className="fs-14 text-dark text-right">Bill Amount</h5>
        </Col>
        <Col xl={4} className="text-right">
          <h5 className="fs-14 text-dark fw-semibold">
            {parsedBranchData?.currencySymbol}{" "}
            {createOrderSuccess?.grandTotalPrice}
          </h5>
        </Col>
      </Row>

      <Row className="mb-3 align-items-center">
        <Col xl={8}>
          <h5 className="fs-14 text-dark text-right">Tips</h5>
        </Col>
        <Col xl={4} className="text-right">
          <Form.Control
            type="text"
            className="wt-120 d-inline-block"
            value={state.tips}
            onChange={(e) => handleTipsChange(e.target.value)}
          />
        </Col>
      </Row>
      <Row className="align-items-center">
        <Col xl={8}>
          <h5 className="fs-14 text-dark text-right">Cash Balance</h5>
        </Col>
        <Col xl={4} className="text-right">
          <h5
            className={`fs-14 ${
              cashBalance >= 0 ? "text-success" : "text-danger"
            }`}
          >
            {parsedBranchData?.currencySymbol}{" "}
            {Math.abs(cashBalance).toFixed(2)}
          </h5>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SettlePaymentMultiple;
