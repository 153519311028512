import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const getDashBoardAnalytics = async (id) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.get(
      `${Environment.DashBoard_Url}order-dashboard/${id}`,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };
  const getPendingOrders = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.get(
      `${Environment.DashBoard_Url}pending-orders/${data}`,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };  const getOrderHistory = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.post(
      `${Environment.DashBoard_Url}orders-history`,
      data,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };  
  const getCancelledOrders = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.post(
      `${Environment.DashBoard_Url}cancelled-orders`,
      data,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };
  export default {getDashBoardAnalytics,getPendingOrders,getCancelledOrders,getOrderHistory}