import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Button } from "react-bootstrap";

export default function PasswordPolicy() {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const [formData, setFormData] = useState({
    minPassword: '',
    restrictPassword: '',
    sessionIdle: '',
    paswordExpire: '',
    rememberPassword: false
  });

  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const onSubmit = () => {
    console.log({
      ...formData
    });
  };

  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="main-title fs-16 mb-0">Password Policy</h4>
          <h5 className="fs-14 text-secondary">Send a digital copy of bill to your walk-in customers.</h5>
        </div>
      </div>

      <Row>
        <Col xl="4">
          <div className="mb-4">
            <Form.Label>
              Minimum Password Length: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="minPassword"
              placeholder="Enter Minimum Password Length"
              onChange={handleChange}
              value={formData.minPassword}
            />
            <h6 className="mt-1 fs-12 text-dark">
              (Cannot be less than 8 characters)
            </h6>
          </div>
        </Col>
        <Col xl="4">
          <div className="mb-4">
            <Form.Label>
              Restrict Same Password: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="restrictPassword"
              placeholder="Enter Restrict Same Password"
              onChange={handleChange}
              value={formData.restrictPassword}
            />
            <h6 className="mt-1 fs-12 text-dark">(Maximum number is 12)</h6>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="4">
          <div className="mb-4">
            <Form.Label>
              Session Idle Time: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="sessionIdle"
              placeholder="Enter Session Idle Time"
              onChange={handleChange}
              value={formData.sessionIdle}
            />
            <h6 className="mt-1 fs-12 text-dark">(Maximum 1440 minutes)</h6>
          </div>
        </Col>

        <Col xl="4">
          <div className="mb-4">
            <Form.Label>
              Password expires every: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Select name="paswordExpire" onChange={handleChange} value={formData.paswordExpire}>
              <option value="">Choose Domain Type</option>
              <option value="7">7 days</option>
              <option value="30">30 days</option>
              <option value="60">60 days</option>
              <option value="90">90 days</option>
              <option value="180">180 days</option>
              <option value="365">365 days</option>
            </Form.Select>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="4">
          <div className="mb-4">
            <Form.Label className="mb-1">Remember password:</Form.Label>
            <Form.Check
              type="switch"
              name="rememberPassword"
              className="me-3 fs-16 fw-semibold"
              onChange={handleChange}
              checked={formData.rememberPassword}
            />
          </div>
        </Col>
      </Row>

      <div>
        <Button variant="primary" onClick={onSubmit}>Submit</Button>
      </div>
    </React.Fragment>
  );
}
