// react-packages
import React, { useContext, useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Prism from "prismjs";
//context
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
//actions,selectors
import { createCustomer } from "../../application/actions/customerAction";
import Environment from "../../infrastructure/core/Environment";
import { getCustomerList, getEmployeeList } from "../../application/selectors/indexSelector";
import {
  restrictAllNumbers,
  restrictAllSpecialCharacters,
  restrictConsecutiveSpace,
  restrictEmailCharacters,
  restrictOnlyNumber,
  restrictSpace,
  validateEmail,
  validateRequired,
  validateRequiredDropdown,
} from "../../infrastructure/core/validationUtils";

export default function AddNewCustomer(props) {
  //accessing hook from react-redux package
  const dispatch = useDispatch();
  //accessing user data from local storage using context
  const { user } = useContext(AuthContext);
  const customerList = useSelector(getCustomerList);
  const employeeList = useSelector(getEmployeeList);
  const initialState = {
    tenantId: user?.tenanatId,
    branchId: user?.branchId,
    isVisible: true,
    isDeleted: false,
    createdBy: user?.id,
    fullName: "",
    countryCode: "+91",
    contactNumber: "",
    email: "",
    username: "",
    defaultPassword: "Pass@123",
    genderId: Environment.defaultValue,
  };
  //making useState for customer formData
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  //manipulating the common fields list as needed in select package of react
  const genderCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter((fields) => fields.fieldTypeCode === "GEN"): [];
  
  const checkDuplicateEmail = (name, value) => {
    const email = value.toLowerCase(); // Convert email to lowercase
    const isDuplicateEmail = customerList.some((user) => user?.username.toLowerCase() === email)
      ? customerList?.some((user) => user?.username.toLowerCase() === email)
      : employeeList?.some((user) => user?.email.toLowerCase() === email);
    if (isDuplicateEmail) {
      setErrors({...errors,[name]: "Email already exists"});
    }
    return isDuplicateEmail;
  };
  const validateInput = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.fullName = validateRequired(formData.fullName) ? "" : "Full Name is required";

    // Validate catalogueCode
    newErrors.countryCode = validateRequiredDropdown(formData.countryCode) ? "" : "Country Code is required";

    // Validate mainCatId (dropdown)
    newErrors.contactNumber = validateRequired(formData.contactNumber) ? "" : "Contact Number is required";

    // Validate catId (dropdown)
    newErrors.email = !validateRequired(formData.email) ? "Email is required": 
    !validateEmail(formData.email) ? "Invalid email format": 
    checkDuplicateEmail("email", formData.email) ? "Email already exists" : "";
    // Validate foodtype (dropdown)
    newErrors.genderId = validateRequiredDropdown(formData.genderId)
      ? ""
      : "Please select gender";
    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const resetForm = () => {
    setFormData(initialState);
    setErrors({});
  };
  useEffect(() => {
    if (props?.show) {
      Prism.highlightAll();
      resetForm();
    }
  }, [props?.show]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (value?.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
      // Email format validation
      if (name === "email") {
        const isValidEmail = validateEmail(value);
        if (!isValidEmail) {
          setErrors({
            ...errors,
            [name]: "Invalid email format",
          });
          return;
        } else {
          checkDuplicateEmail(name, value);
        }
      }
    } else {
      setErrors({
        ...errors,
        [name]: `${
          name === "fullName"
            ? "Full Name"
            : name === "countryCode"
            ? "Country code"
            : name === "contactNumber"
            ? "Contact Number"
            : name === "email"
            ? "Email"
            : name === "genderId"
            ? "Gender"
            : "This Field"
        } is required`,
      });
    }
  };
  //create customer api integration
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    const formObject = {
      tenantId: user?.tenanatId,
      branchId: user?.branchId,
      isVisible: true,
      isDeleted: false,
      createdBy: user?.id,
      fullName: formData.fullName,
      countryCode: "+91",
      contactNumber: formData.contactNumber,
      email: formData.email,
      username: formData.email,
      defaultPassword: "Pass@123",
      genderId: formData.genderId,
    };
    dispatch(createCustomer(formObject));
    props.closeFunction(false);
  };
  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">Add New Customer</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
          <Col xl={8}>
              <div className="mb-4">
                <Form.Label>
                  Phone Number: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Group
                  controlId="formFile"
                  className="position-relative overflow-hidden"
                >
                  <Form.Label className="custom-label mb-0">+91</Form.Label>
                  <Form.Control
                    className="tax-percentage-input"
                    style={{ paddingLeft: "50px" }}
                    type="number"
                    min="1"
                    placeholder="Enter Phone Number"
                    name="contactNumber"
                    onChange={(e) => handleChange(e)}
                    onKeyDown={(e) => restrictOnlyNumber(e)}
                  />
                </Form.Group>
                <Form.Text className="text-danger">
                  {errors.contactNumber}
                </Form.Text>
              </div>
            </Col>
            <Col xl={8}>
              <div className="mb-4">
                <Form.Label>
                  Customer Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Customer Name"
                  name="fullName"
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.fullName}</Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <div className="mb-4">
                <Form.Label>Email Address</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Email Address"
                  name="email"
                  onChange={(e) => handleChange(e)}
                  onKeyDown={(e) => {
                    restrictSpace(e);
                    restrictEmailCharacters(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.email}</Form.Text>
              </div>
            </Col>

            <Col xl={8}>
              <div>
                <Form.Label>Gender</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="genderId"
                  onChange={handleChange}
                >
                  <option value={Environment.defaultValue}>
                    Choose Gender
                  </option>
                  {genderCommonList?.map((data) => (
                    <option value={data.id} key={data.id}>
                      {data.fieldValue}
                    </option>
                  ))}
                </Form.Select>

                <Form.Text className="text-danger">{errors.genderId}</Form.Text>
              </div>
            </Col>
          </Row>
          
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center" onClick={handleSubmit}>
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create New Customer</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
