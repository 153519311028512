import React, { useEffect, useState, useRef } from "react";
import Prism from "prismjs";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  Tab,
  Tabs,
  Row,
  Col,
  Card,
  Table,
} from "react-bootstrap";
import imageFound from "../../assets/img/no-image-found.png";
import "./manage.scss";
import Environment from "../../infrastructure/core/Environment";
import {debounce} from "lodash";

export default function ManageItem() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    // Trigger click on the hidden file input
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
  };

  const [activeTab, setActiveTab] = useState("priceVariants");
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);
  return (
    <React.Fragment>
    <Header passDataToPointOfSale={handleBranchIdFromHeader}/>
      <div className="main main-app manage-item-page p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Manage Item</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="primary" className="d-flex align-items-center">
              <i className="ri-add-line fs-18 me-2"></i>
              <span className="d-sm-inline"> Save Changes</span>
            </Button>
          </div>
        </div>

        <Row>
          <Col xl="3">
            <Card className="card-one mb-2 ht-auto">
              <Card.Body>
                <h3 className="fs-14 mb-0 text-dark mb-4">Thumbnail</h3>
                <div className="item-manage-thumbnail">
                  <div className="text-center bg-white mb-3 thumbnail">
                    <Link className="edit-thumbnail text-dark bg-white">
                      <i className="ri-pencil-line fs-16"></i>
                    </Link>
                    <img
                      src={imageFound}
                      className="img-fluid"
                      alt="thumbnail"
                    />
                  </div>
                  <p className="fs-sm text-secondary text-center">
                    Set the item thumbnail image, only *.png *.jpg *.jpeg image
                    file are accepted
                  </p>
                </div>
              </Card.Body>
            </Card>
            <Card className="card-one mb-2 ht-auto">
              <Card.Body>
                <div className="mb-3">
                  <Form.Label className="position-relative w-100">
                    Status: <sup className="text-danger">*</sup>{" "}
                    <span className="order-indicator"></span>
                  </Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option value="1">Published</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
                <div className="mb-3">
                  <Form.Label>
                    Visibility: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option value="1">All Mediums</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>

                <div className="mb-3">
                  <Form.Label>
                    Main Menu: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option value="1">Shorba & Salad</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>

                <div className="mb-3">
                  <Form.Label>
                    Sub Menu: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Select aria-label="Default select example">
                    <option value="1">Arabain Soup</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                  </Form.Select>
                </div>
              </Card.Body>
            </Card>

            <Card className="card-one ht-auto">
              <Card.Body>
                <h3 className="fs-14 mb-0 text-dark mb-4">Image Gallery</h3>

                <Row>
                  <Col xl="3">
                    <Form.Group controlId="formFile">
                      <div className="custom-update-image">
                        <Form.Control
                          type="file"
                          className="custom-file-input"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                        />
                        <label
                          className="custom-file-label"
                          htmlFor="customFileInput"
                        >
                          <i className="ri-add-fill"></i>
                        </label>
                        {/* <Button variant="primary" onClick={handleButtonClick}></Button> */}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col xl="3">
                    <div className="upload-image">
                      <img
                        src={imageFound}
                        className="img-fluid"
                        alt="gallery"
                      />
                      <Link className="remove-image text-white">
                        <i className="ri-delete-bin-line"></i>
                      </Link>
                    </div>
                  </Col>
                  <Col xl="3">
                    <div className="upload-image">
                      <img
                        src={imageFound}
                        className="img-fluid"
                        alt="gallery"
                      />
                      <Link className="remove-image text-white">
                        <i className="ri-delete-bin-line"></i>
                      </Link>
                    </div>
                  </Col>
                  <Col xl="3">
                    <div className="upload-image">
                      <img
                        src={imageFound}
                        className="img-fluid"
                        alt="gallery"
                      />
                      <Link className="remove-image text-white">
                        <i className="ri-delete-bin-line"></i>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          <Col xl="9">
            <Card className="card-one ht-auto mb-2">
              <Card.Body>
                <div>
                  <h2 className="fs-16 text-dark d-flex align-items-center">
                    <span className="indicator non-veg me-2"></span> Arabain
                    Dastar Sp. Non-Veg Soup
                  </h2>
                  <h5 className="fs-11 mt-1">
                    This is a sample description of the item
                  </h5>
                  <h3 className="fs-14 mt-1 text-dark d-flex align-items-center">
                    Shorba & Salad{" "}
                    <i className="ri-arrow-right-s-line fs-16 align-middle"></i>{" "}
                    Arabian Soup
                  </h3>
                  <Row className="mt-2">
                    <Col xl="3" className="position-relative">
                      <Button
                        variant="primary"
                        className="fs-14 d-flex align-items-center"
                      >
                        <i className="ri-pencil-line fs-18 lh-1 me-1"></i>
                        <span className="align-middle">Create Menu</span>
                      </Button>
                      <div className="border-divider"></div>
                    </Col>
                    <Col xl="2">
                      <h3 className="fs-14 text-dark fw-bold">ASCO1</h3>
                      <h5 className="fs-14 text-dark">Short Code</h5>
                    </Col>
                    <Col xl="2">
                      <h3 className="fs-14 text-dark fw-bold">Vegetarian</h3>
                      <h5 className="fs-14 text-dark">Food Type</h5>
                    </Col>
                    <Col xl="2">
                      <h3 className="fs-14 text-dark fw-bold">Yes</h3>
                      <h5 className="fs-14 text-dark">KOT Print</h5>
                    </Col>
                    <Col xl="2">
                      <h3 className="fs-14 text-dark fw-bold">Yes</h3>
                      <h5 className="fs-14 text-dark">Discount Allowed</h5>
                    </Col>
                  </Row>
                </div>
              </Card.Body>
            </Card>
            <Card className="card-one ht-auto">
              <Card.Body>
                <Tabs
                  id="custom-styled-tabs"
                  activeKey={activeTab}
                  onSelect={handleTabSelect}
                  className="sale-on-point-tabs"
                >
                  <Tab
                    eventKey="priceVariants"
                    title={
                      <>
                        <h6 className="fs-12 mb-0">Price Variants</h6>
                      </>
                    }
                    tabClassName="custom-tab-header"
                  >
                    <div className="custom-tab-body">
                      <div className="price-variant-bg">
                        <Row>
                          <Col xl="6">
                            <Row>
                              <Col xl="12">
                                <Form.Label>
                                  Price Variant:{" "}
                                  <sup className="text-danger">*</sup>
                                </Form.Label>
                              </Col>
                              <Col xl="4">
                                <Form.Select aria-label="Default select example">
                                  <option value="1">Multiple Variant</option>
                                  <option value="2">Single Variant</option>
                                </Form.Select>
                              </Col>
                              <Col xl="4">
                                <Form.Select aria-label="Default select example">
                                  <option value="1">Multiple Variant</option>
                                  <option value="2">Single Variant</option>
                                </Form.Select>
                              </Col>

                              <Col xl="4">
                                <Button variant="danger">Reset</Button>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Tab>

                  <Tab
                    eventKey="addons"
                    title={
                      <>
                        <h6 className="fs-12 mb-0">Addons</h6>
                      </>
                    }
                    tabClassName="custom-tab-header"
                  >
                    <div className="custom-tab-body">Addons</div>
                  </Tab>

                  <Tab
                    eventKey="itemDetails"
                    title={
                      <>
                        <h6 className="fs-12 mb-0">Item Details</h6>
                      </>
                    }
                    tabClassName="custom-tab-header"
                  >
                    <div className="custom-tab-body">Item Details</div>
                  </Tab>

                  <Tab
                    eventKey="tax"
                    title={
                      <>
                        <h6 className="fs-12 mb-0">Tax</h6>
                      </>
                    }
                    tabClassName="custom-tab-header"
                  >
                    <div className="custom-tab-body">Tax</div>
                  </Tab>

                  <Tab
                    eventKey="Receipe"
                    title={
                      <>
                        <h6 className="fs-12 mb-0">Receipe</h6>
                      </>
                    }
                    tabClassName="custom-tab-header"
                  >
                    <div className="custom-tab-body">Receipe</div>
                  </Tab>

                  <Tab
                    eventKey="translation"
                    title={
                      <>
                        <h6 className="fs-12 mb-0">Translation</h6>
                      </>
                    }
                    tabClassName="custom-tab-header"
                  >
                    <div className="custom-tab-body">Translation</div>
                  </Tab>
                </Tabs>

                <div className="mt-3">
                  <Button
                    variant="primary"
                    className="d-flex align-items-center mb-3"
                  >
                    <i className="ri-add-line fs-18 lh-1"></i>
                    <span className="d-sm-inline"> Add New Variant</span>
                  </Button>

                  <Card className="card-one ht-auto border mb-3">
                    <Card.Body>
                      <div>
                        {" "}
                        <Form.Check
                          type="switch"
                          className="s-16 fw-semibold"
                          checked
                          label="Nerul Sec 27 Branch"
                          onChange={() => {}}
                        />
                      </div>
                      <Table className="table-agent mb-0" responsive>
                        <thead>
                          <tr>
                            <th>Size</th>
                            <th>Selling Price</th>
                            <th>Preperation Cost</th>
                            <th>Markup Price</th>
                            <th>Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>Full</td>
                            <td>
                              <b>350</b>
                            </td>
                            <td>
                              <b>200</b>
                            </td>
                            <td>
                              <b>400</b>
                            </td>
                            <td className="w-0">
                              <div className="d-flex justify-content-end">
                                <Button
                                  variant="primary"
                                  className="btn-icon me-2"
                                >
                                  <i className="ri-pencil-line"></i>
                                </Button>
                                <Button variant="danger" className="btn-icon">
                                  <i className="ri-delete-bin-line"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td>Full</td>
                            <td>
                              <b>350</b>
                            </td>
                            <td>
                              <b>200</b>
                            </td>
                            <td>
                              <b>400</b>
                            </td>
                            <td className="w-0">
                              <div className="d-flex justify-content-end">
                                <Button
                                  variant="primary"
                                  className="btn-icon me-2"
                                >
                                  <i className="ri-pencil-line"></i>
                                </Button>
                                <Button variant="danger" className="btn-icon">
                                  <i className="ri-delete-bin-line"></i>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>

                      <div className="d-flex">
                        <Form.Check
                          type="switch"
                          className="me-3 fs-16 fw-semibold"
                          checked
                          label="Bestseller"
                          onChange={() => {}}
                        />
                        <Form.Check
                          type="switch"
                          className="me-3 fs-16 fw-semibold"
                          checked
                          label="Featured"
                          onChange={() => {}}
                        />
                        <Form.Check
                          type="switch"
                          className="fs-16 fw-semibold"
                          checked
                          label="Discount Allowed"
                          onChange={() => {}}
                        />
                      </div>
                    </Card.Body>
                  </Card>

                  <Card className="card-one ht-auto border">
                    <Card.Body className="d-flex align-items-center justify-content-between">
                      <Form.Check
                        type="switch"
                        className="me-3 fs-16 fw-semibold"
                        label="Mumbra - Y Junction"
                      />
                      <h5 className="text-danger fs-14 fw-bold">Excluded</h5>
                    </Card.Body>
                  </Card>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
