import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AddRecipeItem } from "./add-recipe";
import { AddNewMaterial } from "../Translation/add-material";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  DeleteReceipe,
  ReceipeListByCatalogueId,
  UpdateReceipe,
} from "../../../../application/actions/receipeAction";
import {
  getLoading,
  getReceipeListByCatalogueId,
} from "../../../../application/selectors/indexSelector";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../../layouts/ShimmerTable";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";

export default function RecipieComponent() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { loading } = useSelector(getLoading);
  const receipeList = useSelector(getReceipeListByCatalogueId);
  const [tempValues, setTempValues] = useState({});
  const [showAddRecipe, setShowAddRecipe] = useState(false);
  const [showAddMateril, setShowAddMateril] = useState(false);

  const processedData = receipeList.reduce((acc, item) => {
    const existingItem = acc.find((i) => i.rawMaterialId === item.rawMaterialId);
    if (existingItem) {
      existingItem[item.sizeName] = (existingItem[item.sizeName] || 0) + item.values;
    } else {
      const newItem = {
        id: item.id,
        tenantId: item.tenantId,
        branchId: item.branchId,
        status: item.status,
        isVisible: item.isVisible,
        isDeleted: item.isDeleted,
        modifiedBy: user.id,
        catalougueId: id,
        measuredIn: item.measuredIn,
        rawMaterialId: item.rawMaterialId,
        rawMaterialName: item.rawMaterialName,
        measuredInDesc: item.measuredInDesc,
        sizeName: item.sizeName,
        [item.sizeName]: item.values,
        varianceId: item.varianceId
      };
      acc.push(newItem);
    }
    return acc;
  }, []);
  useEffect(() => {
    const initialTempValues = {};
    receipeList.forEach((row,index) => {
      initialTempValues[`${row.sizeName}_${row.rawMaterialId}`] = row.values.toString();
    });
    setTempValues(initialTempValues);
  }, [receipeList]);
  useEffect(() => {
    dispatch(ReceipeListByCatalogueId(id));
  }, [id]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setTempValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };
  const handleBlur = (row) => {
    const formObject = {
      id: row.id,
      tenantId: row.tenantId,
      branchId: row.branchId,
      status: row.status,
      isVisible: row.isVisible,
      isDeleted: row.isDeleted,
      modifiedBy: user.id,
      rawMaterialId: row.rawMaterialId,
      catalougueId: id,
      measuredIn: row.measuredIn,
      varianceId:row.varianceId,
      values: parseFloat(
        tempValues[`${row.sizeName}_${row.rawMaterialId}`] || 0
      ),
    };
    dispatch(UpdateReceipe(formObject));
  };
  const handleAddRecipeItem = (state) => {
    setShowAddRecipe(state);
  };
  const handleAddNewMaterial = (state) => {
    setShowAddMateril(state);
  };
  const deleteReceipe = (id) => {
    const dataId=receipeList.find((receipe)=>(
      `${receipe.sizeName}_${receipe.rawMaterialId}`===id
    )).id;
    dispatch(DeleteReceipe(dataId));
  };
  const columns = [
    {
      name: "Raw Material Name",
      selector: (row) => row.rawMaterialName,
    },
    ...Object.keys(processedData[0] || {})
      .filter(
        (key) =>
          key !== "rawMaterialId" &&
          key !== "rawMaterialName" &&
          key !== "measuredInDesc" &&
          key !== "id" &&
          key !== "tenantId" &&
          key !== "branchId" &&
          key !== "status" &&
          key !== "isVisible" &&
          key !== "isDeleted" &&
          key !== "modifiedBy" &&
          key !== "catalougueId" &&
          key !== "sizeName" &&
          key !== "varianceId" &&
          key !== "measuredIn"
      )
      .map((sizeName) => ({
        name: sizeName,
        selector: (row) => (
          <Form.Control
            className="w-80"
            type="text"
            placeholder="Enter Qty"
            value={tempValues[`${sizeName}_${row.rawMaterialId}`] || ""}
            name={`${sizeName}_${row.rawMaterialId}`}
            onChange={handleChange}
            onBlur={() => handleBlur(row)}
          />
        ),
      })),
    {
      name: "Measured In",
      selector: (row) => row.measuredInDesc,
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => deleteReceipe(`${row.sizeName}_${row.rawMaterialId}`)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  
  return (
    <React.Fragment>
      <AddRecipeItem show={showAddRecipe} closeFunction={handleAddRecipeItem} />
      <AddNewMaterial
        show={showAddMateril}
        closeFunction={handleAddNewMaterial}
      />
      <div className="custom-tab-body">
        <div className="mb-3 text-end">
          <Button
            variant="outline-dark me-2 fs-12"
            onClick={() => handleAddRecipeItem(true)}
          >
            Add Recipe Item
          </Button>
          <Button
            variant="outline-danger fs-12"
            onClick={() => handleAddNewMaterial(true)}
          >
            Create New Material
          </Button>
        </div>
        <div className="custom-common-table">
          {!loading ? (
            <DataTable
              columns={columns}
              data={processedData}
              fixedHeader
              search={true}
              highlightOnHover
              pagination
            />
          ) : (
            <TableShimmerLoader columnCount={4} rowCount={4} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
