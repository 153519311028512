import Environment from "../../../core/Environment";
import jwtInterceptor from "./../../../core/helpers/jwtInterceptor";

const GetKitchenList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Setting_Url}kitchen-by-branch/${data}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const GetKitchenListByTenant = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Setting_Url}kitchen-by-tanant/${data}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};

const CreateKitchen = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const Kitchendata = {
    branchId: data.branchId,
    isVisible: true,
    kitchenName: data.kitchenName,
    kitchenDescription: data.kitchenDescription,
  };
  const response = await jwtInterceptor.post(
    `${Environment.Setting_Url}add-kitchen`,
    Kitchendata,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const UpdateKitchen = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));

  const Kitchendata = {
    id: data.id,
    branchId: data.branchId,
    isVisible: data.isVisible ,
    isDeleted: false,
    kitchenName: data.kitchenName,
    kitchenDescription: data.kitchenDescription,
  };

  const response = await jwtInterceptor.post(
    `${Environment.Setting_Url}update-kitchen`,
    Kitchendata,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const DeleteKitchen = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));

  const Kitchendata = {
    id: data.id,
    isDeleted: true,
    kitchenName: data.kitchenName,
    kitchenDescription: data.kitchenDescription,
  };

  const response = await jwtInterceptor.post(
    `${Environment.Setting_Url}update-kitchen`,
    Kitchendata,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const MapKitchenMenu = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Setting_Url}add-kitchen-menu`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const UnMapKitchenMenu = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Setting_Url}remove-kitchen-mapping/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};
const KitchenDetailsById = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Setting_Url}kitchen/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );

  return response.data;
};

export default {
  GetKitchenList,
  GetKitchenListByTenant,
  CreateKitchen,
  UpdateKitchen,
  DeleteKitchen,
  MapKitchenMenu,
  KitchenDetailsById,
  UnMapKitchenMenu
};
