// react packages
import React, { useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { useSelector } from "react-redux";
import Prism from "prismjs";
// actoins
import { getCustomerAddressList } from "../../application/selectors/indexSelector";

export default function CustomerAddress() {
  //fetching and storing customer address list
  const customerAddressList = useSelector(getCustomerAddressList);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  return (
    <React.Fragment>
      <ListGroup>
        {customerAddressList && customerAddressList.length !== 0 ? (
          <>
            {customerAddressList
              ?.filter((address) => address.isDeleted === false)
              ?.map((data, index) => (
                <ListGroup.Item
                  className="d-flex justify-content-between mb-2 border"
                  key={index}
                >
                  <span className="">
                    <b>
                      {data.addressType ===
                      "a5ca48b8-bfd1-42d6-8db1-7021c131e001"
                        ? "Office"
                        : data.addressType ===
                          "a5ca48b8-bfd1-42d6-8db1-7021c131e002"
                        ? "Home"
                        : "Unknown"}
                      <span className="mx-1">
                        {data.isDefault === true ? "(Default)" : ""}
                      </span>
                    </b>
                    :- {data.roomNo}, {data.buildingName}, {data.streetName},{" "}
                    {data.landmark}, {data.location}, {data.city}, {data.state},{" "}
                    {data.country}, {data.zipCode}.
                  </span>{" "}
                </ListGroup.Item>
              ))}
          </>
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center shadow-sm p-5">
              <span>There are no records to display</span>
            </div>
          </>
        )}
      </ListGroup>
    </React.Fragment>
  );
}
