import { ACCESS_LIST_BY_USERROLEID_SUCCESS, EMPLOYEE_LIST_BY_ROLEID_SUCCESS, USERROLE_DETAIL_SUCCESS, USERROLE_LIST_SUCCESS,  } from "../actions/userAction";

const initialState = {
    UserRoleList:[],
    UserRoleDetail:{},
    AccessList:[],
    EmployeeListByRoleId:[],
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case USERROLE_LIST_SUCCESS:
            return { ...state ,UserRoleList: action.payload.result, error: null}
        case USERROLE_DETAIL_SUCCESS:
            return { ...state ,UserRoleDetail: action.payload.result, error: null}
       case ACCESS_LIST_BY_USERROLEID_SUCCESS:
            return { ...state ,AccessList: action.payload.result, error: null}
        case EMPLOYEE_LIST_BY_ROLEID_SUCCESS:
            return { ...state ,EmployeeListByRoleId: action.payload, error: null}
       default:            
            return state;
    }
}

export default reducer;