import { MATERIAL_LIST_SUCCESS, MATERIAL_TYPES_SUCCESS } from "../actions/materialAction";

const initialState = {
    MaterialTypeList:[],
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case MATERIAL_TYPES_SUCCESS:
            return { ...state ,MaterialTypeList: action.payload.data.result, error: null };    
        case MATERIAL_LIST_SUCCESS:
            return { ...state ,MaterialList: action.payload.result, error: null };     
        default:            
            return state;
    }
}

export default reducer;