import { Navigate } from "react-router-dom";
import {
  GENERAL_SETTING,
  GET_POS_GEN_SETTING,
  GeneralSettingSuccess,
} from "../actions/generalSettingAction";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";

const GeneralSetting =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GENERAL_SETTING) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GenSettingData" }));
        const SettingData = await api.generalSettingAPI.GeneralSetting(action.payload);
        dispatch(GeneralSettingSuccess(SettingData));
        ToastifyService.success(`General Setting Updated Successfully!`);
        dispatch(Loading.setLoading({ loading: false, value: "GenSettingData" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "GenSettingData" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetPOSGenSetting =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_POS_GEN_SETTING) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "GenSettingData" }));
        const SettingData = await api.generalSettingAPI.GetPOSGenSetting(action.payload);
        dispatch(GeneralSettingSuccess(SettingData));
        dispatch(Loading.setLoading({ loading: false, value: "GenSettingData" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "GenSettingData" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [GeneralSetting, GetPOSGenSetting];
