import React from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";

export default function ChangeCustomer(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-35">
                <Offcanvas.Header closeButton style={{ borderBottom: '1px solid #ccc' }}>
                    <Offcanvas.Title>Change Customer</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="mb-4">
                        <Form.Label>Table Code</Form.Label>
                        <Form.Select>
                            <option value="0" selected="selected">Choose the Table</option>
                            <option value="T1">T1</option>
                            <option value="T2">T2</option>
                            <option value="T3">T3</option>
                            <option value="T4">T4</option>
                        </Form.Select>
                    </div>
                    <div>
                        <Form.Label>Customer Name</Form.Label>
                        <Form.Control type="text" placeholder="Enter Customer Name / Number" name="customerName" />
                    </div>
                </Offcanvas.Body>

                <div className="offcanvas-footer">
                    <Button variant="primary" className="fs-14">
                        <span className="align-middle">Change</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}