import React from "react";
import { Row, Col, Card } from "react-bootstrap";


export default function OrderDetails() {
    return (
        <React.Fragment>
            <div className="mb-3">
                <h3 className="fs-16 fw-semibold">Order Details</h3>
                <p className="fs-12 fw-semibold">Online Orders</p>
            </div>

            <Card className="card-one mb-3">
                <Card.Body>
                    <Row>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Type</label>
                            <h5 className="fs-14">Dine-In</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Id</label>
                            <h5 className="fs-14">T1-8791</h5>
                        </Col>
                        <Col xl={3}>
                            <label className="fs-12 mb-1">Item Name</label>
                            <h5 className="fs-14">Choco Bomb - 1 scoop</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Total Quantity</label>
                            <h5 className="fs-14">2</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Price</label>
                            <h5 className="fs-14 fw-bold">₹800</h5>
                        </Col>
                        <Col xl={1}>
                            <label className="fs-12 mb-1">Bill Status</label>
                            <h5 className="fs-14 text-danger fw-bold">(Unpaid)</h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="card-one mb-3">
                <Card.Body>
                    <Row>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Type</label>
                            <h5 className="fs-14">Takeaway</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Id</label>
                            <h5 className="fs-14">A2-2548</h5>
                        </Col>
                        <Col xl={3}>
                            <label className="fs-12 mb-1">Item Name</label>
                            <h5 className="fs-14">Sandwitch Dhokala Tiranga - Full</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Total Quantity</label>
                            <h5 className="fs-14">4</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Price</label>
                            <h5 className="fs-14 fw-bold">₹180</h5>
                        </Col>
                        <Col xl={1}>
                            <label className="fs-12 mb-1">Bill Status</label>
                            <h5 className="fs-14 text-success fw-bold">(Paid)</h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="card-one mb-3">
                <Card.Body>
                    <Row>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Type</label>
                            <h5 className="fs-14">Dine-In</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Id</label>
                            <h5 className="fs-14">AD-2154</h5>
                        </Col>
                        <Col xl={3}>
                            <label className="fs-12 mb-1">Item Name</label>
                            <h5 className="fs-14">Choco Bomb - 1 scoop</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Total Quantity</label>
                            <h5 className="fs-14">2</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Price</label>
                            <h5 className="fs-14 fw-bold">₹800</h5>
                        </Col>
                        <Col xl={1}>
                            <label className="fs-12 mb-1">Bill Status</label>
                            <h5 className="fs-14 text-danger fw-bold">(Unpaid)</h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="card-one mb-3">
                <Card.Body>
                    <Row>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Type</label>
                            <h5 className="fs-14">Takeaway</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Id</label>
                            <h5 className="fs-14">AD-4879</h5>
                        </Col>
                        <Col xl={3}>
                            <label className="fs-12 mb-1">Item Name</label>
                            <h5 className="fs-14">Sandwitch Dhokala Tiranga - Full</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Total Quantity</label>
                            <h5 className="fs-14">4</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Price</label>
                            <h5 className="fs-14 fw-bold">₹180</h5>
                        </Col>
                        <Col xl={1}>
                            <label className="fs-12 mb-1">Bill Status</label>
                            <h5 className="fs-14 text-success fw-bold">(Paid)</h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="card-one mb-3">
                <Card.Body>
                    <Row>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Type</label>
                            <h5 className="fs-14">Self Order</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Id</label>
                            <h5 className="fs-14">D1-1582</h5>
                        </Col>
                        <Col xl={3}>
                            <label className="fs-12 mb-1">Item Name</label>
                            <h5 className="fs-14">Choco Bomb - 1 scoop</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Total Quantity</label>
                            <h5 className="fs-14">2</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Price</label>
                            <h5 className="fs-14 fw-bold">₹800</h5>
                        </Col>
                        <Col xl={1}>
                            <label className="fs-12 mb-1">Bill Status</label>
                            <h5 className="fs-14 text-danger fw-bold">(Unpaid)</h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>

            <Card className="card-one mb-3">
                <Card.Body>
                    <Row>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Type</label>
                            <h5 className="fs-14">Pickup</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Order Id</label>
                            <h5 className="fs-14">T2-2548</h5>
                        </Col>
                        <Col xl={3}>
                            <label className="fs-12 mb-1">Item Name</label>
                            <h5 className="fs-14">Sandwitch Dhokala Tiranga - Full</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Total Quantity</label>
                            <h5 className="fs-14">4</h5>
                        </Col>
                        <Col xl={2}>
                            <label className="fs-12 mb-1">Price</label>
                            <h5 className="fs-14 fw-bold">₹180</h5>
                        </Col>
                        <Col xl={1}>
                            <label className="fs-12 mb-1">Bill Status</label>
                            <h5 className="fs-14 text-success fw-bold">(Paid)</h5>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}