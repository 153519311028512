import React, { useContext, useEffect, useMemo, useState } from "react";
import Prism from "prismjs";
import { Link } from "react-router-dom";
import "./add-item.scss";
import "./my-card.scss";
import { Offcanvas, Row, Col, Tab, Tabs, Nav, Button, Form } from "react-bootstrap";
import BrowseMenu from "./menu/browse-menu";
import MyCardOrder from "./MyCard/order";
import MyCardTransfer from "./MyCard/transfer";
import MyCardCustomer from "./MyCard/customer/customer";
import MyCardKOT from "./MyCard/kot";
import { useCart } from "./MyCard/cartProvider";
import { useDispatch, useSelector } from "react-redux";
import { getCreateOrderSuccess } from "../../application/selectors/indexSelector";
import { customerListByTenant, resetCustomerDetails } from "../../application/actions/customerAction";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
import lockIcon from "../../assets/img/icons/lock.png";
import unlockIcon from "../../assets/img/icons/unlock.png";
import ToastifyService from "../_common/ToastifyService";

export default function AddNewItem(props) {
  const dispatch = useDispatch();
  const { user, selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const storedMenuList = JSON.parse(localStorage.getItem("MenuList")) || [];
  const MenuList = [{ id: "favourites", mainCatName: "Favourites" }, ...storedMenuList];
  const Categorylist = JSON.parse(localStorage.getItem("SubMenuList")) || [];
  const ItemCataloguelist = JSON.parse(localStorage.getItem("Catalogues")) || [];
  const [catagoryListByMenuId, setCatagoryListByMenuId] = useState(
    Categorylist.filter((subMenu) => subMenu.mainCategoryId === MenuList[0].id)
  );
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [myOrderLog, setMyOrderLog] = useState([]);
  const [orderLocked, setOrderLocked] = useState(false);
  const [selectedCustomerAddress, setSelectedCustomerAddress] = useState(null);
  const [activeTab, setActiveTab] = useState("Order_tab");
  const [mainCategory, setmainCategory] = useState("tab-0");
  const [modifyItemId, setModifyItemId] = useState(null);
  const [searchItem, setSearchItem] = useState("");
  const [unlockShow, setUnlockShow] = useState(false);
  const [isFavourites, setIsFavourites] = useState(false);
  const [error, setError] = useState("");
  const [passkey, setPasskey] = useState("");
  const { localStorageCartData, setCartData, setDeletedItems } = useCart();
  const createOrderSuccess = useSelector(getCreateOrderSuccess);

  useEffect(() => {
    Prism.highlightAll();
    setmainCategory("tab-0");
    setCatagoryListByMenuId(Categorylist.filter((subMenu) => subMenu.mainCategoryId === MenuList[0].id));
    setSearchItem("");
    setIsFavourites(true);
    dispatch(customerListByTenant(user?.tenanatId));
  }, [props.show]);

  useEffect(() => {
    if (createOrderSuccess?.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e701") {
      setOrderLocked(true);
    }
  }, [createOrderSuccess]);

  const handleSearchInputChange = (e) => {
    setSearchItem(e.target.value);
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const handleMainCategoryTab = (tab) => {
    setmainCategory(tab);
  };

  const [selectedSubCategory, setSelectedSubCategory] = useState(
    catagoryListByMenuId[0]?.id
  );

  const mainCategoryClick = (item) => {
    if (item.id === "favourites") {
      setCatagoryListByMenuId([]); // Assuming you handle favourites separately
      setSelectedSubCategory(null);
      setIsFavourites(true);
    } else {
      setCatagoryListByMenuId(Categorylist.filter((subMenu) => subMenu.mainCategoryId === item.id));
      const selectedSubCategory = Categorylist.find((subMenu) => subMenu.mainCategoryId === item.id)?.id || null;
      setSelectedSubCategory(selectedSubCategory);
      setIsFavourites(false);
    }
  };

  const handleOpenAddVariant = (itemData) => {
    setModifyItemId(itemData);
  };

  const handleunlock = (state) => {
    setUnlockShow(state);
    setError("");
    setPasskey("");
  };

  const handlePasskeyChange = (event) => {
    setPasskey(event.target.value);
    setError("");
  };

  const handleSubmit = (state) => {
    const storedPasskey = parsedBranchData?.passKey;
    if (passkey === storedPasskey) {
      setUnlockShow(state);
      setOrderLocked(state);
      ToastifyService.success("Order Unlocked!");
    } else {
      setError("Invalid Passkey");
    }
  };

  const browseMenuComponent = useMemo(
    () => (
      <BrowseMenu
        isFavourites={isFavourites}
        setModifyItemId={setModifyItemId}
        modifyItemId={modifyItemId}
        searchItem={searchItem}
        Categorylist={catagoryListByMenuId}
        ItemCataloguelist={ItemCataloguelist}
        selectedTable={props?.selectedTab.tableData}
        selectedSubCategory={selectedSubCategory}
      />
    ),
    [searchItem, catagoryListByMenuId, ItemCataloguelist, props.selectedTab?.tableData, selectedSubCategory]
  );

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-100 table-state-my-card">


        <Row className="m-0 add-item-modal-col">
          <Col xl={8} md={8} sm={12} className="pr-0 sm-pl-0 pl-0 position-relative">
            {createOrderSuccess &&
              createOrderSuccess.length !== 0 &&
              localStorageCartData.length === 0 &&
              createOrderSuccess?.paymentStatus ===
              "a5ca48b8-bfd1-42d6-8db1-7021c131e701" &&
              orderLocked && (
                <div className={`block-pages`}>
                  {unlockShow ? (
                    <div className={`text-center`}>
                      <div className="locks-icon">
                        <img src={unlockIcon} alt="lock" />
                      </div>

                      <div className="mt-3">
                        <Form.Label className="fw-semibold text-dark mb-2">Supervisor Passkey</Form.Label>
                        <Form.Control
                          autoComplete="off"
                          type="text"
                          name="Passkey"
                          placeholder="Enter Supervisor Passkey"
                          className={`wt-250 ${error ? "border border-danger" : ""
                            }`}
                          value={passkey}
                          onChange={handlePasskeyChange}
                        />
                        {error && <p className="text-danger">{error}</p>}
                      </div>
                      <Button variant="danger" className="text-uppercase btn btn-danger ps-4 pe-4 mt-3" onClick={() => handleSubmit(false)}>
                        Submit
                      </Button>
                    </div>
                  ) : (
                    <div className={`text-center`}>
                      <div className="locks-icon"><img src={lockIcon} alt="lock" /></div>
                      <Button variant="danger" className="text-uppercase btn btn-danger ps-4 pe-4 mt-3" onClick={() => handleunlock(true)}>Unlock</Button>
                    </div>
                  )}
                </div>
              )}
            <Offcanvas.Header className="d-block">
              <Row className="align-items-center">
                <Col xs={6} md={5}>
                  <Offcanvas.Title
                    onClick={() => {
                      localStorage.removeItem("TableCartData");
                      localStorage.removeItem("DeletedItems");
                      setCartData([]);
                      setDeletedItems([]);
                      dispatch(resetCustomerDetails);
                      setSelectedCustomer(null);
                      setSelectedCustomerAddress(null);
                    }}
                  >
                    <h3 className="text-capitalize">
                      <Link onClick={props.closeFunction} className="align-middle text-dark me-2 position-relative" style={{ zIndex: 11 }}>
                        <i className="ri-arrow-left-s-line fs-28"></i>
                      </Link>{" "}
                      {props?.selectedTab?.tableData?.tableName}
                    </h3>
                  </Offcanvas.Title>
                </Col>
                <Col xs={6} md={7}>
                  <Row className="align-items-center">
                    <Col xs={12} md={12}>
                      <div className="form-search border">
                        <i className="ri-search-line"></i>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          name="searchItem"
                          value={searchItem} // Bind the input value to the state
                          onChange={handleSearchInputChange} // Add an onChange handler to update the state
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Offcanvas.Header>

            <Offcanvas.Body className="pt-0 pl-0 pr-0 position-relative">
              {/* MAIN CATEGORY BEGIN */}
              <div className="main-category-wrapper">
                {MenuList?.map((item, index) => (
                  <Nav
                    variant="pills"
                    className="sale-on-point-tabs main-category-tabs"
                    key={index}
                    activeKey={mainCategory}
                    onClick={(e) => mainCategoryClick(item)}
                    eventKey={`tab-${index}`}
                    onSelect={handleMainCategoryTab}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={`tab-${index}`} title={item.mainCatName}>
                        {item.id === "favourites" && (<span className="fav-icon ri-star-fill me-1"></span>)}
                        {item.mainCatName}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                ))}
              </div>

              <Tab.Content>{browseMenuComponent}</Tab.Content>
              {/* MAIN CATEGORY ENDED */}
            </Offcanvas.Body>
          </Col>

          {/* MY CARD */}
          <Col xl={4} md={4} sm={12} className="pl-0 pe-0">
            <div className="my-card">
              <div className="my-card-body">
                <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="card-tabs">
                  {/* CARD TAB */}
                  <Tab
                    eventKey="Order_tab"
                    title={<><i className="ri-shopping-cart-2-line"></i>{" "}<h6 className="fs-12 mb-0">Order</h6></>}
                    className="custom-tab-header"
                  >
                    {/* Content for the Home tab */}
                    <div className="custom-tab-body">
                      <div
                        className={`${createOrderSuccess && createOrderSuccess.length !== 0 &&
                          localStorageCartData.length === 0 && createOrderSuccess?.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e701" &&
                          orderLocked ? "freeze-tab" : ""
                          }`}
                      ></div>
                      <MyCardOrder
                        onOpenAddVariant={handleOpenAddVariant}
                        selectedTab={props.selectedTab?.tableData}
                        orderType={props.orderType}
                        closeFunction={props.closeFunction}
                        onCustomerSelect={(customer) =>
                          setSelectedCustomer(customer)
                        }
                        onCustomerAddressSelect={(address) =>
                          setSelectedCustomerAddress(address)
                        }
                        selectedCustomer={selectedCustomer}
                        selectedCustomerAddress={selectedCustomerAddress}
                        orderLocked={orderLocked}
                        selectedTabSection={props?.selectedTabSection}
                        orderTypeList={props?.orderTypeList}
                        setOrderLocked={setOrderLocked}
                        customerFormData={props?.customerFormObject}
                        setMyOrderLog={setMyOrderLog}
                      />
                    </div>
                  </Tab>

                  {/* CUSTOMER TAB */}
                  <Tab eventKey="Customer_tab"
                    title={<>{" "}<i className="ri-user-2-line"></i>{" "}<h6 className="fs-12 mb-0">Customer</h6></>}
                    className="custom-tab-header"
                  >
                    {/* Content for the Customer tab */}
                    <div className="custom-tab-body">
                      <MyCardCustomer
                        selectedCustomer={selectedCustomer}
                        selectedCustomerAddress={selectedCustomerAddress}
                        onCustomerSelect={(customer) =>
                          setSelectedCustomer(customer)
                        }
                        onCustomerAddressSelect={(address) =>
                          setSelectedCustomerAddress(address)
                        }
                        customerFormData={props?.customerFormObject}
                      />
                    </div>
                  </Tab>

                  {/* KOT TAB */}
                  <Tab eventKey="KOT_tab"
                    title={<><i className="ri-file-line"></i>{" "}<h6 className="fs-12 mb-0">Logs</h6></>}
                    className="custom-tab-header"
                  >
                    {/* Content for the Logs tab */}
                    <div className="custom-tab-body">
                      <MyCardKOT myOrderLog={myOrderLog} />
                    </div>
                  </Tab>

                  {/* TRANSFER TAB */}
                  <Tab eventKey="Transfer_tab"
                    title={<><i className="ri-file-transfer-line"></i>{" "}<h6 className="fs-12 mb-0">Transfer</h6></>}
                    className="custom-tab-header"
                    disabled={createOrderSuccess && createOrderSuccess.length !== 0 &&
                      localStorageCartData.length === 0 && createOrderSuccess?.paymentStatus === "a5ca48b8-bfd1-42d6-8db1-7021c131e701" && orderLocked
                    }
                  >
                    {/* Content for the Logs tab */}
                    <div className="custom-tab-body">
                      <MyCardTransfer orderTypeList={props?.orderTypeList} closeFunction={props.closeFunction} show={props?.show} />
                    </div>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
        </Row>
      </Offcanvas>
    </React.Fragment>
  );
}