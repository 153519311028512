import React, { useContext, useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import EditDetails from "./edit-details";
import ProfileSetting from "./profile-setting";
import Languages from "./languages";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { ProfileByTenant } from "../../../application/actions/accountAction";
import { getProfileByTenant } from "../../../application/selectors/indexSelector";

export default function BusinessProfile() {
  // Accessing user context
  const { user } = useContext(AuthContext);

  // Redux dispatch hook
  const dispatch = useDispatch();

  // State hooks for managing active tab and skin mode
  const [activeTab, setActiveTab] = useState("edit_details_tab");
  const [skin, setSkin] = useState(localStorage.getItem("skin-mode") ? "dark" : "");

  // Effect hook to fetch profile data on component mount
  useEffect(() => {
    dispatch(ProfileByTenant(user?.tenanatId));
  }, [dispatch, user]);

  // Effect hook to switch skin mode
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  // Function to handle tab selection
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  // Function to switch skin mode
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName("btn-outline-primary");

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  // Initial skin mode switch
  switchSkin(skin);

  return (
    <React.Fragment>
      {/* Header */}
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">Business Profile</h4>
          <h5 className="fs-14 text-secondary">
            Configure basic settings of your organization.
          </h5>
        </div>
      </div>

      {/* Tabs for different sections */}
      <div className="common-tabs">
        <Tabs
          id="custom-styled-tabs"
          activeKey={activeTab}
          onSelect={handleTabSelect}
          className="sale-on-point-tabs bg-transparent"
        >
          {/* Tab for editing details */}
          <Tab
            eventKey="edit_details_tab"
            title="Edit Details"
            tabClassName="custom-tab-header fw-semibold"
          >
            <div className="custom-tab-body pt-3">
              <EditDetails />
            </div>
          </Tab>
          {/* Tab for profile settings */}
          <Tab
            eventKey="profile_setting_tab"
            title="Profile Setting"
            tabClassName="custom-tab-header fw-semibold"
          >
            <div className="custom-tab-body pt-3">
              <ProfileSetting />
            </div>
          </Tab>
          {/* Tab for managing languages */}
          <Tab
            eventKey="language_tab"
            title="Languages"
            tabClassName="custom-tab-header fw-semibold"
          >
            <div className="custom-tab-body pt-3">
              <Languages />
            </div>
          </Tab>
        </Tabs>
      </div>
    </React.Fragment>
  );
}
