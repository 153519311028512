import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Form } from "react-bootstrap";
import AddNewUserRole from "./add-role-user";
import UpdateUserRole from "./update-role";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeList, getUserRoleList, getLoading } from "../../../application/selectors/indexSelector";
import { DeleteUserRole, UserRoleList } from "../../../application/actions/userAction";
import DataTable from "react-data-table-component";
import { LoadEmployee } from "../../../application/actions/employeeAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import TableShimmerLoader from "../../../layouts/ShimmerTable";

export default function UserRoles() {
  const { user } = useContext(AuthContext);
  const { loading, value } = useSelector(getLoading);

  useEffect(() => {
    Prism.highlightAll();
    dispatch(UserRoleList);
    dispatch(LoadEmployee(user?.tenanatId));
  }, []);

  const dispatch = useDispatch();
  const [showUserRole, setShowUserRole] = useState(false);
  const handleUserRole = (state) => {
    setShowUserRole(state);
  };

  const [showUpdateUserRole, setShowUpdateUserRole] = useState(false);
  const handleUpdateUserRole = (data, state) => {
    setUserRole(data);
    setShowUpdateUserRole(state);
  };
  
  const UserRolesList = useSelector(getUserRoleList);
  const [userRole, setUserRole] = useState({});

  const handleDeleteUserRole = (id) => {
    SweetAlertService.showAlert(
      'Delete User Role',
      'Are you sure you want to delete this User Role?',
      'warning'
    ).then((result) => {
      if (result.isConfirmed) {
        SweetAlertService.showSuccess('Success', 'User Role!');
        dispatch(DeleteUserRole(id))
        ToastifyService.success(`Deleted User Role Successfully`);
      } else {
        console.log('Delete operation cancelled!');
      }
    }).catch((error) => {
      console.error('Error:', error);
      ToastifyService.error(`something went wrong`);
    });

  }

  const columns = [
    {
      name: "Role Name",
      selector: (row) => (
        <div>
          <h6
            className="fs-14 fw-semibold mb-1"
          // onClick={() => handleEditAddons(row, true)}
          >
            {row.roleName}
          </h6>
        </div>
      ),
    },
    {
      name: "Mapped Users",
      selector: (row) => '-',
    },
    {
      name: "CreatedOn",
      selector: (row) => row.createdOn,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleUpdateUserRole(row, true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon me-2"
            onClick={() => handleDeleteUserRole(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const employeeList = useSelector(getEmployeeList);

  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = UserRolesList?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <React.Fragment>
      <AddNewUserRole show={showUserRole} closeFunction={handleUserRole} employeeList={employeeList} />
      <UpdateUserRole show={showUpdateUserRole} closeFunction={handleUpdateUserRole} data={userRole} employeeList={employeeList} />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">User Roles</h4>
          <h5 className="fs-14 text-secondary">Configure basic settings of your organisation.</h5>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="primary" className="d-flex align-items-center gap-1" onClick={() => handleUserRole(true)}>
            <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline">Add Role</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
         <Card.Header className="align-items-center justify-content-between">
         <div>
            <h4 className="main-title fs-14 mb-0">List Of Users</h4>
          </div>
          <div className="custom-dropdown-wrapper position-relative">
            <div className="custom-drop-down z-index-2 wt-300">
              <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
              <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="custom-common-table">
            {filteredEmployees && filteredEmployees !== undefined && (
              <DataTable
                columns={columns}
                data={filteredEmployees}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
          </div>
          {loading && filteredEmployees === undefined && (
            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
          )}
          {loading && value === "LoadUserRole" && filteredEmployees === undefined && (
            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
          )}
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}