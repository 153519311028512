import { Navigate } from "react-router-dom";
import {} from "../actions/discountAction";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";
import { GET_EMP_DAILY_ATT, GET_EMP_DAILY_REPORT, GET_EMP_MONTHLY_REPORT, GetEmployeeDailyReportSuccess, GetEmployeeMonthlyReportSuccess, MARK_EMP_ATTEN, MarkEmpAttendanceSuccess } from "../actions/attendanceAction";

const getMonthlyReport =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_EMP_MONTHLY_REPORT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "branchList" }));
        const MonthlyReport = await api.attendanceAPI.getEmpMonthlyReport(
          action.payload
        );
        dispatch(GetEmployeeMonthlyReportSuccess(MonthlyReport));
        dispatch(Loading.setLoading({ loading: false, value: "branchList" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const MarkEmpAttendance =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MARK_EMP_ATTEN) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "branchList" }));
        const DailyAttendance = await api.attendanceAPI.MarkEmpAttendance(
          action.payload
        );
        dispatch(MarkEmpAttendanceSuccess(DailyAttendance));
        dispatch(Loading.setLoading({ loading: false, value: "branchList" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  }; 
  const EmployeeDaywiseAttendace =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_EMP_DAILY_ATT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "branchList" }));
        const DailyAttendance = await api.attendanceAPI.EmployeeDaywiseAttendace(
          action.payload
        );
        dispatch(MarkEmpAttendanceSuccess(DailyAttendance));
        dispatch(Loading.setLoading({ loading: false, value: "branchList" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };  
  const GetEmployeeDailyReport =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_EMP_DAILY_REPORT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "branchList" }));
        const DailyAttendanceReport = await api.attendanceAPI.GetEmployeeDailyReport(
          action.payload
        );
        dispatch(GetEmployeeDailyReportSuccess(DailyAttendanceReport));
        dispatch(Loading.setLoading({ loading: false, value: "branchList" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

export default [getMonthlyReport,MarkEmpAttendance,EmployeeDaywiseAttendace,GetEmployeeDailyReport];

