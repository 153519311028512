import {
  BRANCH_OT_SESSIONS_SUCCESS,
  GET_BRANCH_SUCCESS,
  UPDATE_BASIC_INFO_SUCCESS,
} from "../actions/branchAction";

const initialState = {
  BranchList: undefined,
  BranchInfo: undefined,
  OperationHoursList:undefined,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BRANCH_SUCCESS:
      return { ...state, BranchList: action.payload.data.result, error: null };
     case BRANCH_OT_SESSIONS_SUCCESS:
      return { ...state, OperationHoursList: action.payload.result, error: null };
    case UPDATE_BASIC_INFO_SUCCESS:
      return { ...state, BranchInfo: action.payload.data.result, error: null };
    default:
      return state;
  }
};

export default reducer;
