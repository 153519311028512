import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Card, Button, Form } from "react-bootstrap";
import SelectPrintTemlate from "./PrinterTemplate/select-printer";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getTemplateByTenantId } from "../../../application/selectors/indexSelector";
import DataTable from "react-data-table-component";
import ConfirmToaster from "../../_common/ConfirmToaster";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import { GetTemplateByTenantId } from "../../../application/actions/printTemplate";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

export default function PrintTemplates() {
  const dispatch = useDispatch()
  const { user } = useContext(AuthContext)
  useEffect(() => {
    Prism.highlightAll();
    dispatch(GetTemplateByTenantId(user?.tenanatId))
  }, []);
  const PrintTemplateByTenant = useSelector(getTemplateByTenantId);
  const { loading, value } = useSelector(getLoading);
  const [showSelectPrintTemlate, setShowSelectPrintTemlate] = useState(false);
  const handleSelectPrintTemlate = (state) => {
    setShowSelectPrintTemlate(state);
  };

  const columns = [
    {
      name: "Template Name",
      selector: (row) => (
        <div>
          <h6
            className="fs-14 fw-semibold mb-1"
            onClick={() => handleSelectPrintTemlate(row, true)}
          >
            {row.groupTitle}
          </h6>
          <h5 className="fs-11">
            {row.groupDesc && row.groupDesc.length > 50 ? (
              <span className="fs-xs text-secondary">
                {row.groupDesc.substring(0, 50)}...
              </span>
            ) : (
              <span className="fs-xs text-secondary">{row.groupDesc}</span>
            )}
          </h5>
        </div>
      ),
    },
    {
      name: "Template Type",
      selector: (row) => row.addOnItemCount,
    },
    {
      name: "Mapped Locations",
      selector: (row) =>
        row.groupTypeId === "d9c5a952-911e-4a0f-8935-5e1fae01201e"
          ? "Single Selection"
          : "Multiple Selection ",
    },
    {
      name: "Created On",
      selector: (row) => row.mappedWithCount,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleSelectPrintTemlate(true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <ConfirmToaster
            message={"Are You Sure ?"}
            position={"top-right"}
            theme={"snow"}
            handleFunction={handleDelete}
            params={row.id}
            btnVariant={"danger"}
            btnclassName={"btn-icon"}
            buttonIcon={"ri-delete-bin-line"}
          />
        </div>
      ),
    },
  ];
  const handleDelete = (id) => {
    // dispatch(DeleteAddOnsGroup(id));
  };

  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = PrintTemplateByTenant?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <React.Fragment>
      <SelectPrintTemlate
        show={showSelectPrintTemlate}
        closeFunction={handleSelectPrintTemlate}
      />
      <div className="d-md-flex align-items-center justify-content-between mb-3">
        <div>
          <h4 className="main-title fs-16 mb-0">Print Templates</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-2"
            onClick={() => handleSelectPrintTemlate(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add New Template</span>
          </Button>
        </div>
      </div>
      <Card className="card-one">
        <Card.Header className="align-items-center justify-content-between sm-d-block">
          <div>
            <h4 className="main-title fs-14 mb-0">List of Templates</h4>
          </div>
          <div className="custom-dropdown-wrapper">
            <div className="custom-drop-down z-index-2 wt-300">
              <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
              <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
        </Card.Header>
        <Card.Body className="p-3 p-xl-4">
          <div className="custom-common-table">
            {filteredEmployees && filteredEmployees !== undefined && (
              <DataTable
                columns={columns}
                data={filteredEmployees}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
          </div>
          {!loading && filteredEmployees === undefined && <TableShimmerLoader columnCount={4} rowCount={4} />}
          {loading && value === "templateBytenant" && filteredEmployees === undefined && <TableShimmerLoader columnCount={4} rowCount={4} />}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
