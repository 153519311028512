import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Link } from "react-router-dom";
import { Card, Table, Button } from "react-bootstrap";
import Environment from "../../infrastructure/core/Environment";
import { debounce } from "lodash";
import couponlist from "../../Json/Coupon/couponlist.json";
import AddNewCoupon from "./add-coupon";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteCoupon,
  GetCoupon,
} from "../../application/actions/discountAction";
import { getCouponList } from "../../application/selectors/indexSelector";
import { format } from "date-fns";

export default function CouponManagement() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const CouponList = useSelector(getCouponList);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  const dispatch = useDispatch();
  switchSkin(skin);

  useEffect(() => {
    dispatch(GetCoupon);
    switchSkin(skin);
  }, [skin]);
  const [branchData, setBranchData] = useState(Environment.defaultValue);

  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);

  const [showAddCoupon, setShowAddCoupon] = useState(false);
  const handleAddCoupon = (state) => {
    setShowAddCoupon(state);
  };
  const handleDelete = (id) => {
    dispatch(DeleteCoupon(id));
  };
  console.log(CouponList);
  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <div className="main main-app p-3 p-lg-4">
        <AddNewCoupon show={showAddCoupon} closeFunction={handleAddCoupon} />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Coupon Management</h4>
          </div>

          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handleAddCoupon(true)}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline"> Create New Coupon</span>
            </Button>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body>
            <Table className="mb-0" responsive>
              <thead>
                <tr>
                  <td>Title</td>
                  <td>Coupon Code</td>
                  <td>Minimum Order Value</td>
                  <td>Max Discount</td>
                  <td>Discount %</td>
                  <td>Usage Allow Per User</td>
                  <td>Status</td>
                  <td>Modified On</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {CouponList?.map((item, index) => (
                  <tr key={index}>
                    {/* <td>{item.id}</td> */}
                    <td>{item.title}</td>
                    <td>{item.coupenCode}</td>
                    <td>{item.minOrderValue}</td>
                    <td>{item.maxDiscount}</td>
                    <td>{item.discountRate}</td>
                    <td>{item.usageAllowPerUser}</td>
                    <td
                      className={`${
                        item.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e400"
                          ? "text-success"
                          : ""
                      }${
                        item.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e401"
                          ? "text-danger"
                          : ""
                      }`}
                    >
                      {item.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e400"
                        ? "Active"
                        : "In Active"}
                    </td>
                    <td>
                      {format(new Date(item.modifiedOn), "dd MMM yyyy hh:mm a")}
                      <p>{item.modifiedByName}</p>
                    </td>

                    <td className="w-0">
                      <div className="d-flex justify-content-end">
                        <Button
                          variant="outline-danger"
                          className="btn-icon"
                          onClick={() => handleDelete(item.id)}
                        >
                          <i className="ri-delete-bin-line"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
