import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "../system-setting.scss";
import GeneralComponent from "./General";
//import PaymentModeComponent from "./PaymentMode";
import ReMarkComponent from "./KOTRemarks/KOT-remark";
//import OrderTypeComponent from "./OrderType/order-type";

export default function SettingPointOfSale() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const [activeTab, setActiveTab] = useState('general_tab');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };


    return (
        <React.Fragment>
            <div className="common-tabs">
                <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="sale-on-point-tabs bg-transparent">
                    <Tab eventKey="general_tab" title="General" tabClassName="custom-tab-header fw-semibold">
                        <div className="custom-tab-body pt-3">
                            <GeneralComponent />
                        </div>
                    </Tab>
                    {/* <Tab eventKey="payment_mode_tab" title="Payment Mode" tabClassName="custom-tab-header fw-semibold">
                        <div className="custom-tab-body pt-3">
                            <PaymentModeComponent />
                        </div>
                    </Tab> */}
                    {/* <Tab eventKey="order_type_tab" title="Order Type" tabClassName="custom-tab-header fw-semibold">
                        <div className="custom-tab-body pt-3">
                            <OrderTypeComponent />
                        </div>
                    </Tab> */}
                    <Tab eventKey="KOT_remark_tab" title="KOT Remark" tabClassName="custom-tab-header fw-semibold">
                        <div className="custom-tab-body pt-3">
                            <ReMarkComponent />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </React.Fragment>
    )
}