import React, {  useEffect } from "react";
import Prism from "prismjs";
import { Offcanvas } from "react-bootstrap";
import {Form, Row, Col, Button} from "react-bootstrap";
import Select from "react-select";

export default function AddNewPaymentGateway(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const selectPaymentGatewau   = [
    { value: 'chocolate', label: 'API Key or Merchant ID' },
    { value: 'strawberry', label: 'API Secret or Authentication Token' },
    { value: 'vanilla', label: 'Supported Payment Methods' }
  ];

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-50">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add Payment Gateway</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col xl={8} md={12} sm={12}>
                                <div>
                                <Form.Label>Search Payment Gatewau</Form.Label>
                                <Select options={selectPaymentGatewau} isSearchable={true} />
                                </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
                <div className="offcanvas-footer justify-content-start">
                <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Submit</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}