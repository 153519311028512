import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import "./operations-timing.scss"
//import { Row, Col, Form, Card, Button } from "react-bootstrap";
import WeeklySchedule from "../weekly-schedule";
//import { getOrderTypeList } from "../../../../../application/selectors/indexSelector";
//import { useSelector } from 'react-redux';
//import Environment from "../../../../../infrastructure/core/Environment";

export default function OperationsTimingView(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  // const orderTypeList = useSelector(getOrderTypeList);
  // const [formData, setFormData] = useState({
  //   orderTypes: Environment.defaultValue
  // })
  return (
    <React.Fragment>
      {/* < Form.Label>Order Type</> */}
      {/* <Row className="mb-3 align-items-center">
        <Col xl={4}>
          <Form.Select value={formData.orderTypes} name="orderTypes">
            <option value={Environment.defaultValue} disabled>Select Order Type</option>
            {orderTypeList?.map((data) => (
              <option value={data.id} key={data.id}>{data.typeName}</option>
            ))}
          </Form.Select>
        </Col>
        <Col xl={4}>
          <Button variant="dark" className="gap-1 d-flex align-items-center">
            <i className="ri-add-line fs-16"></i>
            <span className="d-sm-inline fs-14 text-uppercase">Add</span>
          </Button>
        </Col>
      </Row> */}

      <WeeklySchedule branch={props?.branch}/>
    
    </React.Fragment>
  )
}