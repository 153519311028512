export const COMMON_FIELD = "COMMON_FIELD";
export const CREATE_MATERIAL = "CREATE_MATERIAL";
export const MATERIAL_LIST = "MATERIAL_LIST";
export const MATERIAL_TYPES_SUCCESS = "MATERIAL_TYPES_SUCCESS";
export const MATERIAL_LIST_SUCCESS = "MATERIAL_LIST_SUCCESS";

export const MaterialList ={
  type: MATERIAL_LIST
};
export const CreateMaterial  = (data)=>({
  type: CREATE_MATERIAL,   
  payload:{data}
});
export const MaterialListSuccess =(data) =>({
  type: MATERIAL_LIST_SUCCESS,
  payload:data
});