import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
//import { toast } from "react-toastify";
import { useContext } from "react";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import {
  AddPaymentModes,
  UpdatePaymentMode,
} from "../../../../application/actions/paymentModeAction";
//import { getPaymentModeList } from "../../../../application/selectors/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";

export default function AddPaymentMode(props) {
  const dispatch = useDispatch();
  const { user,selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const initialState = {
    id: Environment.defaultValue,
    isTransactionIdRequired: false,
    isTransactionIdMandatory: false,
    title: "",
    tenantId: user?.tenanatId,
    branchId: parsedBranchData?.id,
    createdBy: user.id,
  };
  useEffect(() => {
    if (props?.selectedData) {
      setFormData({
        ...formData,
        id: props.selectedData.id,
        title: props.selectedData.title,
        isTransactionIdMandatory: props.selectedData.isTransactionIdMandatory,
        isTransactionIdRequired: props.selectedData.isTransactionIdRequired,
        branchId: props.selectedData.branchId,
      });
    }
  }, [props?.selectedData]);
  const [formData, setFormData] = useState(initialState);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (props?.selectedData?.id) {
      dispatch(UpdatePaymentMode(formData));
    } else {
      dispatch(AddPaymentModes(formData));
    }
    setFormData(initialState);
    props.closeFunction();
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Add Payment Mode
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={12}>
              <div className="mb-4">
                <Form.Label>
                  Payment Mode Title: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Payment Mode Title"
                  name="title"
                  value={formData.title}
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div className="mb-4">
                <Form.Label>
                  Required Transaction ID: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="isTransactionIdRequired"
                  value={formData.isTransactionIdRequired.toString()}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      isTransactionIdRequired: e.target.value === "true",
                    });
                  }}
                >
                  <option value="true">YES</option>
                  <option value="false">NO</option>
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <div>
                <Form.Label>
                  is Transaction ID mandatory:{" "}
                  <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="isTransactionIdMandatory"
                  value={formData.isTransactionIdMandatory.toString()}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      isTransactionIdMandatory: e.target.value === "true",
                    });
                  }}
                >
                  <option value="true">YES</option>
                  <option value="false">NO</option>
                </Form.Select>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            {" "}
            {props.selectedData ? "Update Payment Mode" :
            <span className="align-middle"><i className="ri-add-line fs-18 lh-1 align-middle"></i> Create Payment Mode</span>}
          </Button>
          <Button
            variant="secondary"
            className="fs-14 d-flex align-items-center"  onClick={()=>props.closeFunction()}
          >
            <span className="align-middle">Cancel</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
