import React from 'react'
import { Col, Row } from 'react-bootstrap'

function CardShimmerLoader({columnCount, rowCount}) {
  const columns = Array.from({ length: columnCount }, (_, index) => index);

  return (
    <Row>
      {/* Map through the array to generate dynamic number of columns */}
      {columns.map((colIndex) => (
        <Col key={colIndex}>
          <p className="card-text placeholder-glow">
          {Array.from({ length: 8 }, (_, index) => (
              <span key={index} className="placeholder col-4"></span>
          ))}
          </p>
        </Col>
      ))}
    </Row>
  );
}

export default CardShimmerLoader