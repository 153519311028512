import {
  ADD_PAYMENT_MODE,
  DELETE_PAYMENT_MODE,
  PAYMENT_MODE_LIST,
  PaymentModeListByBranchIdSuccess,
  UPDATE_PAYMENT_MODE,
  UPDATE_PAYMENT_MODE_VISIBILITY,
} from "../actions/paymentModeAction";
import * as Loading from "../actions/loaderAction";
import { Navigate } from "react-router-dom";
import ToastifyService from "../../View/_common/ToastifyService";

const PaymentModeListByBranchId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === PAYMENT_MODE_LIST) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "PaymentModeListByBranchId",
          })
        );
        const PaymentModeListByBranchId =
          await api.paymentModeAPI.PaymentModeListByBranchId(action.payload);
        dispatch(PaymentModeListByBranchIdSuccess(PaymentModeListByBranchId));
      } catch (error) {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "PaymentModeListByBranchId",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "PaymentModeListByBranchId",
          })
        );
      }
    }
    next(action);
  };
const AddPaymentMode =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_PAYMENT_MODE) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "AddPaymentMode",
          })
        );
        const PaymentModeListByBranchId =
          await api.paymentModeAPI.AddPaymentMode(action.payload);
        dispatch(PaymentModeListByBranchIdSuccess(PaymentModeListByBranchId));
        ToastifyService.success("Payment Mode Added Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "AddPaymentMode",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "AddPaymentMode",
          })
        );
      }
    }
    next(action);
  };
const UpdatePaymentMode =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_PAYMENT_MODE) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "UpdatePaymentMode",
          })
        );
        const PaymentModeListByBranchId =
          await api.paymentModeAPI.UpdatePaymentMode(action.payload);
        dispatch(PaymentModeListByBranchIdSuccess(PaymentModeListByBranchId));
        ToastifyService.success("Payment Mode Updated Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "UpdatePaymentMode",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "UpdatePaymentMode",
          })
        );
      }
    }
    next(action);
  };
const DeletePaymentMode =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_PAYMENT_MODE) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "DeletePaymentMode",
          })
        );
        const PaymentModeListByBranchId =
          await api.paymentModeAPI.DeletePaymentMode(action.payload);
        dispatch(PaymentModeListByBranchIdSuccess(PaymentModeListByBranchId));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "DeletePaymentMode",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "DeletePaymentMode",
          })
        );
      }
    }
    next(action);
  };
const UpdatePaymentModeVisibility =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_PAYMENT_MODE_VISIBILITY) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "UpdatePaymentModeVisibility",
          })
        );
        const PaymentModeListByBranchId =
          await api.paymentModeAPI.UpdatePaymentModeVisibility(action.payload.id);
        dispatch(PaymentModeListByBranchIdSuccess(PaymentModeListByBranchId));
        ToastifyService.success("Payment Mode Visibility Updated Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "UpdatePaymentModeVisibility",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "UpdatePaymentModeVisibility",
          })
        );
      }
    }
    next(action);
  };
export default [
  PaymentModeListByBranchId,
  AddPaymentMode,
  UpdatePaymentMode,
  DeletePaymentMode,
  UpdatePaymentModeVisibility,
];
