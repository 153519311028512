export const PROFILE_BY_TENANT="PROFILE_BY_TENANT";
export const PROFILE_BY_TENANT_SUCCESS="PROFILE_BY_TENANT_SUCCESS";
export const IMAGE_UPLOAD="IMAGE_UPLOAD";
export const UPDATE_BUSINESS_PROFILE="UPDATE_BUSINESS_PROFILE";
export const GET_ALL_COMMON_FIELDS="GET_ALL_COMMON_FIELDS";
export const GET_ALL_CURRENCIES="GET_ALL_CURRENCIES";
export const CURRENCIES_SUCCESS="CURRENCIES_SUCCESS";
export const LANGUAGESS_SUCCESS="LANGUAGESS_SUCCESS";
export const ADD_BUSINESS_LANGUAGES="ADD_BUSINESS_LANGUAGES";
export const GET_ALL_LANGUAGES="GET_ALL_LANGUAGES";
export const BUSINESS_LANGUAGES_SUCCESS="BUSINESS_LANGUAGES_SUCCESS";
export const BUSINESS_LANGUAGES="BUSINESS_LANGUAGES";
export const DELETE_BUSINESS_LANGUAGES="DELETE_BUSINESS_LANGUAGES";
export const GET_ALL_TIMEZONES="GET_ALL_TIMEZONES";
export const TIMEZONES_SUCCESS="TIMEZONES_SUCCESS";
export const UPDATE_TIMEZONES="UPDATE_TIMEZONES";
export const UPDATE_CURRENCY="UPDATE_CURRENCY";
export const CHANGE_PASSWORD="CHANGE_PASSWORD";
export const CHANGE_PASSWORD_SUCCESS="CHANGE_PASSWORD_SUCCESS";

export const ProfileByTenant=(id)=>({
    type:PROFILE_BY_TENANT,
    payload:id
})
export const ProfileByTenantSuccess=(data)=>({
    type:PROFILE_BY_TENANT_SUCCESS,
    payload:data
})
export const ImageUpload=(data)=>({
    type:IMAGE_UPLOAD,
    payload:data
})
export const UpdateBusinessProfile=(data)=>({
    type:UPDATE_BUSINESS_PROFILE,
    payload:data
})
export const getAllCommonFields={
    type:GET_ALL_COMMON_FIELDS
}
export const GetAllCurrencyList={
    type:GET_ALL_CURRENCIES
}
export const CurrenciesSuccess=(data)=>({
    type:CURRENCIES_SUCCESS,
    payload:data
})
export const GetAllTimeZoneList={
    type:GET_ALL_TIMEZONES
}
export const TimeZonesSuccess=(data)=>({
    type:TIMEZONES_SUCCESS,
    payload:data
})
export const UpdateBusinessTimeZone=(data)=>({
    type:UPDATE_TIMEZONES,
    payload:data
})
export const UpdateBusinessCurrency=(data)=>({
    type:UPDATE_CURRENCY,
    payload:data
})
export const GetAllLanguagesList={
    type:GET_ALL_LANGUAGES
}
export const LanguagesSuccess=(data)=>({
    type:LANGUAGESS_SUCCESS,
    payload:data
})
export const AddBusinessLanguages=(data)=>({
    type:ADD_BUSINESS_LANGUAGES,
    payload:data
})
export const GetBusinessLanguages=(data)=>({
    type:BUSINESS_LANGUAGES,
    payload:data
})
export const BusinessLanguagesSuccess=(data)=>({
    type:BUSINESS_LANGUAGES_SUCCESS,
    payload:data
})
export const DeleteBusinessLanguages=(data)=>({
    type:DELETE_BUSINESS_LANGUAGES,
    payload:data
})
export const changePassword=(data)=>({
    type:CHANGE_PASSWORD,
    payload:data
})
export const changePasswordSuccess=(data)=>({
    type:CHANGE_PASSWORD_SUCCESS,
    payload:data
})