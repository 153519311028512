export const GET_CHARGES_LIST = "GET_CHARGES_LIST";
export const GET_CHARGES_LIST_SUCCESS = "GET_CHARGES_LIST_SUCCESS";
export const ADD_CHARGES = "ADD_CHARGES";
export const UPDATE_CHARGES = "UPDATE_CHARGES";
export const DELETE_CHARGES = "DELETE_CHARGES";
export const MAP_CHARGES = "MAP_CHARGES";
export const UNMAP_CHARGES = "UNMAP_CHARGES";
export const MAP_CHARGES_WITH_BRANCH = "MAP_CHARGES";
export const UNMAP_CHARGES_WITH_BRANCH = "UNMAP_CHARGES_WITH_BRANCH";
export const CHARGE_DETAILS = "CHARGE_DETAILS";
export const CHARGE_DETAILS_SUCCESS = "CHARGE_DETAILS_SUCCESS";
export const UNMAP_SINGLE_ORDERTYPE = "UNMAP_SINGLE_ORDERTYPE";
export const UNMAP_ALL_ORDERTYPE = "UNMAP_ALL_ORDERTYPE";
export const MAP_CHARGES_ORDERTYPE = "MAP_CHARGES_ORDERTYPE";

export const GetChargesList = {
  type: GET_CHARGES_LIST
}
export const GetChargesListSuccess = (data) => ({
  type: GET_CHARGES_LIST_SUCCESS,
  payload: data,
});
export const AddCharges = (data) => ({
  type: ADD_CHARGES,
  payload: data ,
});
export const UpdateCharges = (data) => ({
  type: UPDATE_CHARGES,
  payload:  data ,
});
export const DeleteCharges = (data) => ({
  type: DELETE_CHARGES,
  payload:  data ,
});
export const getChargeDetail = (id) => ({
  type: CHARGE_DETAILS,
  payload: id,
});
export const GetChargesDetailsSuccess = (data) => ({
  type: CHARGE_DETAILS_SUCCESS,
  payload: data,
});
export const UnmapSingleOrderType = (data) => ({
  type: UNMAP_SINGLE_ORDERTYPE,
  payload: data,
});
export const MapChargesOrderType = (data) => ({
  type: MAP_CHARGES_ORDERTYPE,
  payload: data,
});