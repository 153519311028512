import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Table, Card } from "react-bootstrap";
import AdvancePaymentList from "../../../../Json/Attendance/payment-log/advance-payment.json";

export default function PaymentLogAdvancePayment() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return (
        <React.Fragment>
            <Card className="card-one">
                <Card.Body>
                    <Table className="mb-0" responsive>
                        <thead>
                            <tr>
                                <th>Opening Balance</th>
                                <th>Entry Type</th>
                                <th>Payment Source</th>
                                <th>Advance Paid</th>
                                <th>Closing Balance</th>
                                <th>Date</th>
                            </tr>
                        </thead>

                        <tbody>
                            {AdvancePaymentList?.map((item, index) => (
                                <tr key={index}>
                                    <td><b>{item.openingBalance}</b></td>
                                    <td className={`fw-semibold ${item.entryType == "Credit" ? "text-danger" : ""}${item.entryType == "Debit" ? "text-success" : ""}`}>
                                        {item.entryType}
                                    </td>
                                    <td>{item.paymentSource}</td>
                                    <td><b>{item.advancePayment}</b></td>
                                    <td className="text-danger"><b>{item.closeingBalance}</b></td>
                                    <td>{item.date}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}