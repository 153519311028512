import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import Header from "../../../layouts/Header";
import { Link } from "react-router-dom";
import { Button, Card, Table, Dropdown, Badge, Form } from "react-bootstrap";
import "./kitchen.scss";
import AddNewKitchen from "./add-kitchen";
import EditKitchen from "./edit-kitchen";
import { useDispatch, useSelector } from "react-redux";
import { getKitchenList, getLoading } from "../../../application/selectors/indexSelector";
import { loadKitchen } from "../../../application/actions/loaderAction";
import Loader from "../../../layouts/Loader";
import { DeleteKitchen } from "../../../application/actions/kitchenAction";
import Environment from "../../../infrastructure/core/Environment";
import { debounce } from "lodash";
import TableShimmerLoader from "../../../layouts/ShimmerTable";

export default function Kitchens() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const dispatch = useDispatch();
  const { loading, value } = useSelector(getLoading);
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  //   const KitchenList = useSelector(getKitchenList);
  //   const loading = useSelector(state => state.Kitchen);

  useEffect(() => {
    Prism.highlightAll();
  }, []);
  useEffect(() => {
    dispatch(loadKitchen());
  }, []);

  switchSkin(skin);
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  const KitchenList = useSelector(getKitchenList);

  const [showAddKitchen, setShowAddKitchen] = useState(currentSkin);
  const handleAddKitchen = (state) => {
    setShowAddKitchen(state);
  };
  const [editKitchen, setKitchenLid] = useState(false);
  const [showEditKitchen, setshowEditKitchen] = useState(currentSkin);
  const handleEditKitchen = (item, state) => {
    setKitchenLid(item);
    setshowEditKitchen(state);
  };

  const deleteKitchen = (item, e) => {
    e.preventDefault();
    // const data={
    //   kitchenName: item.kitchenName,
    //   kitchenDescription: item.kitchenDescription,
    //   id: item.kitchenDescription,
    // }
    dispatch(DeleteKitchen(item));
  };

  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);

  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = KitchenList?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <div className="main main-app p-3 p-lg-4">
        <AddNewKitchen show={showAddKitchen} closeFunction={handleAddKitchen} />
        <EditKitchen
          show={showEditKitchen}
          data={editKitchen}
          closeFunction={handleEditKitchen}
        />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Manage</li>
            </ol>
            <h4 className="main-title mb-0">Kitchens</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button variant="primary" className="d-flex align-items-center gap-2" onClick={() => handleAddKitchen(true)}>
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline">Add New Kitchens</span>
            </Button>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body className="p-3 p-xl-4">
            <div className="d-md-flex align-items-center justify-content-between mb-3">
              <div>
                <h4 className="main-title fs-14 mb-0">List of Kitchens</h4>
              </div>
              <div className="custom-dropdown-wrapper">
                <div className="custom-drop-down z-index-2 wt-250">
                  <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                  <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                </div>
              </div>
            </div>

            {filteredEmployees && filteredEmployees !== undefined && (
              <Table className="mb-0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Kitchen Name</th>
                    <th>Kitchen Desc</th>
                    <th>Is Visible</th>
                    <th>Items Impacted</th>
                    <th>Created</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>

                  {filteredEmployees?.map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>

                      <td>
                        <h6>{item.kitchenName}</h6>
                      </td>
                      <td>{item.kitchenDescription}</td>
                      <td>
                        <Badge
                          bg={`${item.isVisible ? "success" : "danger"}`}
                          className="w-50 fs-14 ht-30 lh-4"
                          pill
                        >
                          {item.isVisible ? "Visible" : "InVisible"}
                        </Badge>
                      </td>
                      <td>{item.kitchenDescription}</td>
                      <td>
                        <h6 className="fs-11 mb-1">{item.created}</h6>
                        <h5 className="fs-14 fw-semibold">{item.status}</h5>
                      </td>
                      <td className="w-0">
                        <div className="d-flex justify-content-end dropdown">
                          <Button
                            variant="primary"
                            className="btn-icon me-2"
                            onClick={() => handleEditKitchen(item, true)}
                          >
                            <i className="ri-pencil-line"></i>
                          </Button>
                          <Button
                            variant="danger"
                            className="btn-icon me-2"
                            onClick={(e) => deleteKitchen(item, e)}
                          >
                            <i className="ri-delete-bin-line"></i>
                          </Button>

                          <Dropdown>
                            <Dropdown.Toggle
                              variant="dark"
                              className="btn-icon custom-dropdown-action"
                              id="dropdown-basic"
                            >
                              <i className="ri-more-2-fill"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item href="#">Action</Dropdown.Item>
                              <Dropdown.Item href="#">Default</Dropdown.Item>
                              <Dropdown.Item href="#">
                                Something else
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {!loading && filteredEmployees === undefined && (
              <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
            )}
            {loading && value === "Kitchenlist" && filteredEmployees === undefined && (
              <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
            )}
          </Card.Body>
        </Card>
      </div>
      {loading && <Loader message="Loading Kitchen List" />}
    </React.Fragment>
  );
}
