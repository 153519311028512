import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { useDispatch, useSelector } from "react-redux";
import {  Offcanvas, Tabs, Tab } from "react-bootstrap";
import Updatecharges from "./update";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import {  getBranchList, getChargeDetails, getLoading, getOrderTypeList } from "../../../application/selectors/indexSelector";
import { loadBranch, loadItemCatalog } from "../../../application/actions/loaderAction";
import {  MapChargesOrderType,  UnmapSingleOrderType } from "../../../application/actions/chargesAction";
import MappedOrderType from "../../_common/mapped-ordertype";
import {  OrderType } from "../../../application/actions/orderTypeAction";

export default function EditCharges(props) {
  const dispatch = useDispatch();
  const BranchList = useSelector(getBranchList);
  const chargeDetails = useSelector(getChargeDetails);
  const {loading,value} = useSelector(getLoading);
  const OrderListByBranch = useSelector(getOrderTypeList);
  const orderTypeOptions = OrderListByBranch?.filter(
    (order) => !chargeDetails?.orderTypes?.some((emp) => emp?.id === order?.id)
  ).map((item) => ({
    value: item.id,
    label: item.typeName,
  }));
  
  const BranchOptions = BranchList?.filter(
    (branch) => !chargeDetails?.branches?.some((emp) => emp?.branchId === branch?.id)
  ).map((item) => ({
    value: item.id,
    label: item.branchName,
  }));
  const [activeTab, setActiveTab] = useState('mapped_items_tab');
  const { user } = useContext(AuthContext);

  useEffect(() => {
    Prism.highlightAll();
      dispatch(loadItemCatalog);
      dispatch(OrderType);
      dispatch(loadBranch(user?.tenanatId));
  }, []);

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  const AssociateOrderType = (branchData, ordertypeData) => {
    if (branchData) {
      let mappingData = [];
      for (let i = 0; i < ordertypeData.length; i++) {
           const orderdata= ordertypeData[i].value
        mappingData.push(orderdata);
      }
      const DataObject = {
        chargeId: chargeDetails?.id,
        locationId: branchData.value,
        orderTypes: mappingData,
      };
      dispatch(MapChargesOrderType(DataObject));
    }
  };
  const DissociateOrderType = (id) => {
    dispatch(UnmapSingleOrderType(id));
  };
  const [mappedOrdertypeData, setMappedOrdertypeData]  = useState([])
  useEffect(()=>{
    const mapData= chargeDetails?.chargeMappings?.map((item) => ({
      id: item.id,
      typeName :item.orderTypName,
      mappingId:item.mappingId,
      branchName: item.branchName,
      createdOn:item.createdOn,
      createdByName:item.createdByName?item.createdByName:''

    }));
    setMappedOrdertypeData(mapData)
  },[chargeDetails])
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Charge</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body className="pd-5">
          <div className="mb-4">
            <h3 className="fs-18 fq-semibold text-dark mb-1">
              {chargeDetails?.chargeName}
            </h3>
            {/* <h6 className="fs-12 mb-3">Multiple Choice Group</h6> */}
          </div>

          <Tabs
            id="custom-styled-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="sale-on-point-tabs"
          >
            <Tab
              eventKey="mapped_items_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Edit Details</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
              <Updatecharges  data={chargeDetails}  />
              </div>
            </Tab>
            <Tab eventKey="order_type_tab" title={<><h6 className="fs-14 mb-0">Mapped Order Type</h6></>} tabClassName="custom-tab-header">
                <div className="custom-tab-body pt-3">
                    <MappedOrderType 
                    BranchData={BranchOptions}
                    OrderData={orderTypeOptions}
                    AssociateOrderType={AssociateOrderType}
                    DissociateOrderType={DissociateOrderType}
                    Data={mappedOrdertypeData}
                    isGlobal={chargeDetails?.isGlobal}/>
                </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
