import React, { useEffect, useState } from "react";
import Header from "../../../layouts/Header";
import { Link } from "react-router-dom";
import { Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import AddNewAddons from "./addAddons";
import EditAddonsGroup from "./edit-addons";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import { loadAddOns } from "../../../application/actions/loaderAction";
import {
  getAllAddOnsList,
  getLoading,
} from "../../../application/selectors/indexSelector";
import {
  DeleteAddOnsGroup,
  GetAddOnsGroupSuccess,
} from "../../../application/actions/addOnsAction";
import { ConfirmToast } from "react-confirm-toast";
import ConfirmToaster from "../../_common/ConfirmToaster";
import Environment from "../../../infrastructure/core/Environment";
import { debounce } from "lodash";

export default function AddonsGroup() {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  const dispatch = useDispatch();
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);

  useEffect(() => {
    dispatch(loadAddOns);
  }, []);

  const { loading, value } = useSelector(getLoading);
  const AddOnsList = useSelector(getAllAddOnsList);
  const [showAddAddons, setShowAddAddons] = useState(false);
  const handleAddAddons = (data, state) => {
    dispatch(GetAddOnsGroupSuccess(data));
    setShowAddAddons(state);
  };
  const [AddOnsData, setAddOnsData] = useState([]);
  const [showEditAddons, setShowEditAddons] = useState(false);
  const handleEditAddons = (data) => {
    dispatch(GetAddOnsGroupSuccess(data));
    setShowEditAddons(true);
  };
  const columns = [
    {
      name: "Group Title",
      selector: (row) => (
        <div>
          <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>{row.groupTitle}</Tooltip>} >
            <Link className="fs-14 fw-semibold mb-1" onClick={() => handleEditAddons(row, true)}>
              {row.groupTitle}
            </Link>
          </OverlayTrigger>

          <h5 className="fs-11">
            {row.groupDesc && row.groupDesc.length > 50 ? (
              <span className="fs-xs text-secondary">
                {row.groupDesc.substring(0, 50)}...
              </span>
            ) : (
              <span className="fs-xs text-secondary">{row.groupDesc}</span>
            )}
          </h5>
        </div>
      ),
    },
    {
      name: "Addon Items Count",
      selector: (row) => row.addOnItemCount,
    },
    {
      name: "Type",
      selector: (row) =>
        row.groupTypeId === "d9c5a952-911e-4a0f-8935-5e1fae01201e"
          ? "Single Selection"
          : "Multiple Selection ",
    },
    {
      name: "Mapped Items",
      selector: (row) => row.mappedWithCount,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            className="btn-icon me-2"
            onClick={() => handleEditAddons(row, true)}
          >
            <i className="ri-eye-line"></i>
          </Button>
          <Button
            variant="danger"
            className="btn-icon me-2"
            onClick={() => handleDelete(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
          {/* <ConfirmToaster
            message={"Are You Sure ?"}
            position={"top-right"}
            theme={"snow"}
            handleFunction={handleDelete}
            params={row.id}
            btnVariant={'outline-danger'}
            btnclassName={'btn-icon'}
            ButtonComponent={Button}
            buttonIcon={'ri-delete-bin-line'}
          /> */}
          
        </div>
      ),
    },
  ];
  const handleDelete = (id) => {
    dispatch(DeleteAddOnsGroup(id));
  };

  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);
  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <div className="main main-app p-3 p-lg-4">
        <AddNewAddons
          show={showAddAddons}
          data={AddOnsData}
          closeFunction={handleAddAddons}
          handleManage={() => setShowEditAddons(true)}
        />
        <EditAddonsGroup
          show={showEditAddons}
          closeFunction={() => setShowEditAddons(false)}
        />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Addons Groups</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handleAddAddons([], true)}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline"> Create New Addons Groups</span>
            </Button>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body className="p-3 p-xl-4">
            <div className="d-md-flex align-items-center justify-content-between mb-3">
              <div>
                <h4 className="main-title fs-14 mb-0">List of Addons Group</h4>
              </div>
              <div>
                <div className="form-search me-auto">
                  <i className="ri-search-line"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for tax group"
                  />
                </div>
              </div>
            </div>

            {AddOnsList && AddOnsList !== undefined && (
              <div className="custom-common-table">
              <DataTable
                columns={columns}
                data={AddOnsList}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
              </div>
            )}

            {!loading && AddOnsList === undefined &&  <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />}
            {loading &&
              value === "addonsGroupList" &&
              AddOnsList === undefined &&  <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
