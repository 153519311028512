export const CREATE_ORDER_TYPE='CREATE_ORDER_TYPE';
export const LOAD_ORDER_TYPE='LOAD_ORDER_TYPE';
export const DELETE_ORDER_TYPE='DELETE_ORDER_TYPE';
export const UPDATE_ORDER_TYPE_STATUS='UPDATE_ORDER_TYPE_STATUS';
export const GET_ORDERTYPE_DETAIL='GET_ORDERTYPE_DETAIL';
export const ORDER_TYPE='ORDER_TYPE';
export const RESET_ORDERTYPE_LIST='RESET_ORDERTYPE_LIST';

export const LOAD_ORDER_TYPE_SUCCESS='LOAD_ORDER_TYPE_SUCCESS';
export const ORDER_TYPE_SUCCESS='ORDER_TYPE_SUCCESS';

export const CREATE_ORDER_TYPE_FAILURE='CREATE_ORDER_TYPE_FAILURE';
export const ORDER_TYPE_VISIBILITY='ORDER_TYPE_VISIBILITY';

//actions
export const OrderType={
    type: ORDER_TYPE,
}
export const CreateOrderType=(data)=>({
    type:CREATE_ORDER_TYPE,
    payload:{data}
})
export const DeleteOrderType=(id)=>({
    type:DELETE_ORDER_TYPE,
    payload:{id}
})
export const UpdateOrderTypeStatus=(data)=>({
    type:UPDATE_ORDER_TYPE_STATUS,
    payload:{data}
})
export const LoadOrderTypeByBranchId=(data)=>({
    type:LOAD_ORDER_TYPE,
    payload:data
})
export const LoadOrderTypeListSuccess=(data)=>({
    type:LOAD_ORDER_TYPE_SUCCESS,
    payload:data
})
//success
export const resetOrderTypeList={
    type:RESET_ORDERTYPE_LIST
}
export const OrderTypeSuccess=(data)=>({
    type:ORDER_TYPE_SUCCESS,
    payload:data
})
export const UpdateOrderTypeVisibility=(data)=>({
    type:ORDER_TYPE_VISIBILITY,
    payload:data
})