import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Form, Table, Button } from "react-bootstrap";
import KitchenGroupsModal from "./change-kitchen";
import { useDispatch } from "react-redux";
import { getKitchenList } from "../../../../application/selectors/indexSelector";
import { GetKitchenList } from "../../../../application/actions/kitchenAction";

export default function KOTComponent() {
  const dispatch = useDispatch()
  useEffect(() => {
    Prism.highlightAll();
    dispatch(GetKitchenList())
  }, []);


  const [switchState, setSwitchState] = useState(false);
  const handleKOTToggle = (event) => {
    setSwitchState(event.target.checked);
  };

  const [showKitchenGroups, setShowKitchenGroups] = useState(false);
  const handleKitchenGroups = (state)=> {
    setShowKitchenGroups(state);
  }

  return (
    <React.Fragment>
      <KitchenGroupsModal show={showKitchenGroups} closeFunction={handleKitchenGroups} />
      <div className="d-flex align-items-center justify-content-between applicabled-view mt-3 mb-4 gap-4">
        <div className="d-flex">
          <Form.Check
            type="switch"
            className="fs-14 fw-semibold"
            label={switchState ? "Sent to kitchen" : "Not sent to kitchen"}
            checked={switchState}
            onChange={handleKOTToggle}
          />
        </div>
      </div>

      <div className="price-variant-bg">
        {switchState ? (
          <>
            <h3 className="fs-14 mt-4 mb-2 fw-semibold">Associated Kitchen Group</h3>
            <Table className="mb-0" responsive>
              <thead>
                <tr>
                  <th>Kitchen Group</th>
                  <th>Location</th>
                  <th>Modified</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td>Juice Center</td>
                  <td>Nerul Sector 27</td>
                  <td>Default</td>
                  <td className="w-0">
                    <Button variant="danger" className="fs-12" onClick={()=> handleKitchenGroups(true)}>Changes</Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ) : (
          <div>

          </div>
        )}

      </div>
    </React.Fragment>
  );
}
