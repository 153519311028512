// react-packages
import React, { useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { format } from "date-fns";
import Prism from "prismjs";
//Loader component
import TableShimmerLoader from "../../layouts/ShimmerTable";
//selectors, actions
import {
  getCustomerOrderList,
  getLoading,
} from "../../application/selectors/indexSelector";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";

export default function CustomerOrderlist() {
  //fetching and storing order of customer list and loader is on or not
  const customerOrderList = useSelector(getCustomerOrderList);
  const { loading, value } = useSelector(getLoading);
  const {selectedBranchData}=useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);

  const addIndexToData = (data) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1, // Adding 1 to start indexing from 1
    }));
  };
  const TableData = addIndexToData(customerOrderList);

  useEffect(() => {
    Prism.highlightAll();
  }, []);
  //datatable columns and their records
  const columns = [
    {
      name: "Order ID",
      selector: (row) => <span>{row.index}</span>,
    },
    {
      name: "Type",
      selector: (row) => <span>{row.orderTypeDesc}</span>,
    },
    {
      name: "Table / Ref",
      selector: (row) => <b>{row.tableName}</b>,
    },
    {
      name: "Status",
      selector: (row) => (
        <span
          className={`badge badge-pill top-badge ${
            row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e601"
              ? "bg-success"
              : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e602"
              ? "bg-success"
              : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e611"
              ? "bg-danger"
              : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e606"
              ? "bg-success"
              : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e610"
              ? "bg-success"
              : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e608"
              ? "bg-success"
              : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e609"
              ? "bg-success"
              : "bg-success"
          }`}
        >
          {row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e601"
            ? "Ordered"
            : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e602"
            ? "Confirmed"
            : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e611"
            ? "Cancelled"
            : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e606"
            ? "Billing"
            : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e610"
            ? "Delivered"
            : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e608"
            ? "Shipped"
            : row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e609"
            ? "Out For Delivery"
            : "Unknown"}
        </span>
      ),
    },
    {
      name: "Bill Amount",
      selector: (row) => <b> {parsedBranchData?.currencySymbol} {row.billAmount}</b>,
    },
    {
      name: "Seller",
      selector: (row) => <span>{row.salesPerson}</span>,
    },
    {
      name: "Last Updated",
      selector: (row) => (
        <>
          {row.modifiedOn && (
            <>
              <h6 className="mb-0 fw-semibold">
                {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")}
              </h6>
            </>
          )}
        </>
      ),
    },
    // {
    //   name: "Action",
    //   selector: (row) => (
    //     <div className="d-flex justify-content-end">
    //       <Button variant="secondary" className="btn-icon me-2">
    //         <i className="ri-eye-line"></i>
    //       </Button>
    //       {row.status !== "a5ca48b8-bfd1-42d6-8db1-7021c131e611" && (
    //         <>
    //           <Button
    //             variant="danger"
    //             className="btn-icon"
    //             // onClick={() => cancelOrder(row.id)}
    //           >
    //             <i className="ri-delete-bin-line"></i>
    //           </Button>
    //         </>
    //       )}
    //     </div>
    //   ),
    // },
  ];
  // const cancelOrder = (id) => {
  //   dispatch(cancelOrderOfCustomer(id));
  // };
  return (
    <React.Fragment>
      <Card className="card-one">
        <Card.Body className="custom-common-table">
          {customerOrderList && customerOrderList !== undefined && (
            <DataTable
              columns={columns}
              data={TableData}
              fixedHeader
              search={true}
              highlightOnHover
              pagination
            ></DataTable>
          )}
          {!loading && customerOrderList === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
          {loading &&
            value === "customerOrderList" &&
            customerOrderList === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
