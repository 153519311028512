import React, { useEffect, useState } from "react";
import { Card, Table, Image, Badge, Dropdown, Row, Col, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { getLoading } from "../../../application/selectors/indexSelector";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import reviewlist from "../../../Json/google-review.json";
import CustomerFilter from "./customer-filter-modal";

export default function CustomerReviews() {
    var data = [[0, 9], [1, 7], [2, 4], [3, 8], [4, 4], [5, 12], [6, 4], [7, 6], [8, 5], [9, 10], [10, 4], [11, 5], [12, 10], [13, 2], [14, 6]];
    const chart = {
        parentHeightOffset: 0,
        stacked: true,
        sparkline: {
            enabled: true
        }
    };

    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };

    const plotOptions = {
        bar: {
            columnWidth: '60%'
        },
    };

    const stroke = {
        curve: 'straight',
        lineCap: 'square'
    };

    useEffect(() => {
    }, []);

    const [showCustomerFilter, setShowCustomerFilter] = useState(false);
    const handleCustomerFilter = (state) => {
        setShowCustomerFilter(state)
    }

    const seriesOne = [{
        type: 'column',
        data: [[0, 0], [1, 0], [2, 5], [3, 10], [4, 6], [5, 10], [6, 15], [7, 18], [8, 7], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5]]
    }, {
        type: 'column',
        data: data
    }];

    const optionOne = {
        chart: chart,
        states: states,
        colors: ['#506fd9', '#e5e9f2'],
        plotOptions: plotOptions,
        stroke: stroke,
        fill: { opacity: 1 },
        tooltip: { enabled: false }
    };

    const { loading, value } = useSelector(getLoading);
    
    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = reviewlist?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

    return (
        <React.Fragment>
            <CustomerFilter show={showCustomerFilter} closeFunction={handleCustomerFilter} />
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <div>
                    <h4 className="main-title fs-16 mb-0">Customer Reviews</h4>
                </div>
            </div>

            <Row className="g-3 mb-3">
                <Col xs="6" sm>
                    <Card className="card-one">
                        <Card.Body>
                            <Row>
                                <Col xs="7">
                                    <h3 className="card-value mb-1">4,608</h3>
                                    <label className="card-title fw-medium text-dark mb-1">Total Reviews</label>
                                    <span className="d-block text-muted fs-11 ff-secondary lh-4">Google Business Page Review</span>
                                </Col>
                                <Col xs="5">
                                    <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={70} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs="3" sm>
                    <Card className="card-one">
                        <Card.Body className="p-3">
                            <h1 className="card-value mb-0 ls--1 fs-32">851</h1>
                            <label className="d-block mb-1 fw-medium text-dark">Positive Review</label>
                            <small>
                                <span className="d-inline-flex text-danger">0.7% <i className="ri-arrow-down-line"></i></span> than last week
                            </small>
                        </Card.Body>
                    </Card>
                </Col>

                <Col xs="3" sm>
                    <Card className="card-one">
                        <Card.Body className="p-3">
                            <h1 className="card-value mb-0 fs-32 ls--1">260</h1>
                            <label className="d-block mb-1 fw-medium text-dark">Negetive Reviews</label>
                            <small><span className="d-inline-flex text-success">1.2% <i className="ri-arrow-up-line"></i></span> than last week</small>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            {/* LIST OF REVIEWS */}
            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h3 className="fs-14 mb-0">List of Reviews</h3>
                    <div className="d-flex align-items-center gap-3">
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-250">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                        <Link onClick={() => handleCustomerFilter(true)}><i className="ri-filter-line fs-22"></i></Link>
                    </div>
                </Card.Header>
                <Card.Body>

                    {filteredEmployees && filteredEmployees !== undefined && (
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Reviewer Name</th>
                                    <th>Rating</th>
                                    <th>Comment</th>
                                    <th>Replied?</th>
                                    <th>Created On</th>
                                    <th>Updated On</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredEmployees.map((item, index) => (
                                    <tr key={index}>
                                        <td>
                                            <div className="d-flex align-items-center gap-2">
                                                <div className="wt-25 ht-25">
                                                    <Image src={item.reviewer.profilePhotoUrl} className="w-100 h-100" />
                                                </div>
                                                <b>{item.reviewer.displayName}</b>
                                            </div>
                                        </td>
                                        <td>
                                            {item.starRating === "ONE" && (
                                                <>
                                                    <i className="ri-star-fill text-warning"></i>
                                                </>
                                            )}
                                            {item.starRating === "TWO" && (
                                                <>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                </>
                                            )}
                                            {item.starRating === "THREE" && (
                                                <>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                </>
                                            )}
                                            {item.starRating === "FOUR" && (
                                                <>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                </>
                                            )}
                                            {!(item.starRating === "ONE" || item.starRating === "TWO" || item.starRating === "THREE" || item.starRating === "FOUR") && (
                                                <>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                    <i className="ri-star-fill text-warning"></i>
                                                </>
                                            )}
                                        </td>
                                        <td>
                                            <div className="wt-250 text-wrap">{item.comment}</div>
                                        </td>
                                        <td>
                                            <Badge bg={item.replied === "NO" ? "danger" : "success"} className="badge-pill">{item.replied}</Badge>
                                        </td>
                                        <td>{item.createTime}</td>
                                        <td>{item.updateTime}</td>
                                        <td>
                                            <Dropdown>
                                                <Dropdown.Toggle
                                                    variant="outline-dark"
                                                    className="btn-icon custom-dropdown-action wt-35 ht-35"
                                                    id="dropdown-basic"
                                                >
                                                    <i className="ri-more-2-fill fs-18"></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item><i className="ri-reply-all-line"></i> Replay</Dropdown.Item>
                                                    <Dropdown.Item><i className="ri-magic-line"></i> Analyse</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    )}
                    {!loading && filteredEmployees === undefined && (
                        <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading && value === "Reviewslist" && filteredEmployees === undefined && (
                        <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                    )}
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}
