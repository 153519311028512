import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import { UpdateUserRole } from "../../../../application/actions/userAction";
import environment from "../../../../infrastructure/core/Environment";
import { restrictAllNumbers, restrictAllSpecialCharacters, validateRequired } from "../../../../infrastructure/core/validationUtils";
import { restrictConsecutiveSpace } from "./../../../../infrastructure/core/validationUtils";

export default function UserBasicInfo(props) {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    roleName: "",
    id: `${environment.defaultValue}`,
    tenantId: user?.tenanatId,
    branchId: user.branchId,
    status: `${environment.defaultValue}`,
    isVisible: true,
    isDeleted: false,
    modifiedBy: user.id,
  });
  useEffect(() => {
    if (props?.data) {
      setFormData({
        ...formData,
        roleName: props.data.roleName,
        id: props?.data.id,
      });
    }
  }, [props?.data]);
  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate Tax Name
    newErrors.roleName = validateRequired(formData.roleName)
      ? ""
      : "Role Name is required";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    dispatch(UpdateUserRole(formData));
    props.closeFunction();
  };
  return (
    <React.Fragment>
      <>
        <Row>
          <Col xl="8">
            <div className="mb-4">
              <Form.Label>
                Role Name: <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Role Name"
                name="roleName"
                value={formData.roleName}
                onChange={(e) => {
                  setFormData({ ...formData, roleName: e.target.value });
                  setErrors({ ...errors, roleName: "" });
                }}
                onKeyDown={(e) => {
                  restrictConsecutiveSpace(e);
                  restrictAllSpecialCharacters(e);
                  restrictAllNumbers(e);
                }}              />
              <Form.Text className="text-danger">{errors.roleName}</Form.Text>
            </div>
          </Col>
        </Row>

        <div className="offcanvas-footer justify-content-start position-absolute">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Update</span>
          </Button>
        </div>
      </>
    </React.Fragment>
  );
}
