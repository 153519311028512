import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Nav, Row, Col, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import "./browse-menu.scss";
import AddNewVariants from "./add-variant";
import verientIcon from "../../../assets/img/icons/varient-icon.svg";
import warningErr from "../../../assets/img/icons/warningErr.png";
import OrderEmptyState from "../../_emptyState/order-empty";
import SearchItems from "./search-items";

export default function  BrowseMenu(props) {
  const [subCategory, setSubCategory] = useState(props?.Categorylist[0]?.id);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [showAddVariant, setShowAddVariant] = useState(false);
  const todaysDay = new Date().toLocaleDateString("en-US", { weekday: "long" });
  useEffect(() => {
    if (props?.selectedSubCategory) {
      setSubCategory(props?.selectedSubCategory);
    } else {
      setSubCategory(props?.Categorylist[0]?.id);
    }
    setShowAddVariant(false);
  }, [props?.selectedSubCategory]);
  useEffect(() => {
    if (props?.modifyItemId) {
      const isEmptyObject = (obj) => {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
      };
      if (!isEmptyObject(props?.modifyItemId)) {
        const itemDetails = props?.ItemCataloguelist.find(
          (itemData) => itemData?.id === props?.modifyItemId?.itemId
        );
        setSelectedItemData(itemDetails);
        setShowAddVariant(true);
      }
    }
  }, [props?.modifyItemId]);

  const Item = useMemo(() => {
    if (subCategory && !props?.isFavourites) {
      return props?.ItemCataloguelist.filter(
        (itemCat) =>
          itemCat.categoryId === subCategory &&
          itemCat?.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
          (itemCat.visibility === "73649d84-d590-40b9-8971-28b830acacd5" ||
            itemCat.visibility === "73649d84-d590-40b9-8971-28b830acacd6")
      );
    } else if (props?.isFavourites) {
      return props?.ItemCataloguelist.filter(
        (itemCat) =>
          itemCat?.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
          (itemCat.visibility === "73649d84-d590-40b9-8971-28b830acacd5" ||
            itemCat.visibility === "73649d84-d590-40b9-8971-28b830acacd6" ) && (itemCat.isBestSeller || itemCat.isFeatured)
      );
    } else {
      return [];
    }
  }, [subCategory, props?.ItemCataloguelist]);

  const handleSubCategoryTab = (tab) => {
    setSubCategory(tab.id);
  };
  const handleCategoryClick = (tab) => {
    handleSubCategoryTab(tab);
  };
  const handleAddVariant = (state, itemData) => {
    if (itemData) {
      setSelectedItemData(itemData);
    }
    props?.setModifyItemId(null);
    setShowAddVariant(state);
  };
  return (
    <React.Fragment>
      {/* SEARCHABLE ITEMS VIEW BEGIN */}
      {props.searchItem.trim() !== "" && (
        <SearchItems ItemCataloguelist={props.ItemCataloguelist} show={handleAddVariant} searchItem={props.searchItem}  />
      )}
      {/* SEARCHABLE ITEMS VIEW ENDED */}
      <AddNewVariants
        setModifyItemId={props?.setModifyItemId}
        setShowAddVariant={setShowAddVariant}
        modifyItemId={props?.modifyItemId}
        show={showAddVariant}
        closeFunction={handleAddVariant}
        selectedItemData={selectedItemData}
        selectedTable={props?.selectedTable?.id}
      />
      <div className={`sub-category-tabs ${props?.isFavourites ? "mt-4" : ""}`}>
        {!props?.isFavourites && (
          <h3 className="fs-14 text-dark pt-2 pb-2 px-3">Sub Menus:</h3>
        )}
        <Tab.Container id="left-tabs-example" activeKey={subCategory}>
          <Row className="m-0">
            {!props?.isFavourites && (
              <Col className="px-0" xs={12} md={3}>
                <div className="sab-menu-tabs">
                  {props?.Categorylist?.map((tab) => (
                    <Nav
                      variant="pills"
                      className="sale-on-point-tabs main-category-tabs"
                      key={tab.id}
                      activeKey={tab.id}
                      onSelect={() => handleSubCategoryTab(tab)}
                      onClick={() => handleCategoryClick(tab)}
                    >
                      <Nav.Item className="w-100">
                        <Nav.Link className="bg-transparent w-100" eventKey={tab.id}>{tab.name}</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  ))}
                </div>
              </Col>
            )}

            <Col xs={12} md={`${props?.isFavourites ? 12 : 9}`} className="pr-0">
              <div className="sub-menu-content">
                <Tab.Content>
                  {Item?.length !== 0 ? (
                    <>
                      <Row className="m-0 g-3">
                        {Item?.map((item, index) => (
                          <>
                            {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" && item?.sizeVariance?.length !== 0 &&
                              (!item?.isDaySpeacial || item?.catalogueOnDays?.some((day) => day.dayName === todaysDay)) && (
                                <Col sm={6} lg={3} md={4} key={index}>
                                  <Card className="card-one mb-3 variant-card">
                                    <Card.Body className="p-0">
                                      <Link
                                        className="d-block h-100 text-dark p-2"
                                        onClick={() => handleAddVariant(true, item,props?.selectedTable?.id)}
                                      >
                                        <div className="indicator-varient g-2">
                                          <span className={`indicator d-block me-2 ${
                                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" : 
                                              item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" : 
                                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" : 
                                              item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                            }`}
                                          ></span>
                                          {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                          ).length > 0 && (<img src={verientIcon} alt="varient" className="wt-18 me-2 opacity-75" />)}
                                        </div>
                                        <h3 className="fs-14 fw-semibold text-dark">{item.catalogueName}</h3>
                                      </Link>
                                    </Card.Body>
                                  </Card>
                                </Col>
                              )}
                          </>
                        ))}

                        {Item?.map((item, index) => (
                          <>
                            {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" && item?.sizeVariance?.length !== 0 && item?.isDaySpeacial &&
                              !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                <Col sm={6} lg={3} md={4} className="mb-3" key={index}>
                                  <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>Not available today</Tooltip>}
                                    showLabel={"Show"} hideLabel={"Hide"}>
                                    <Card className="card-one mb-3 variant-card disabled-variant">
                                      <Card.Body className="p-0">
                                        <Link className="d-block h-100 text-dark p-2">
                                          <div className="indicator-varient g-2">
                                            <span className={`indicator d-block me-1 ${
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" : 
                                                item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" : 
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" : 
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                              }`}
                                            ></span>
                                            {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                            ).length > 0 && (<img src={verientIcon} alt="varient" className="wt-18 me-1 opacity-75" />)}
                                            <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                          </div>
                                          <h3 className="fs-14 fw-semibold text-dark">{item.catalogueName}</h3>
                                        </Link>
                                      </Card.Body>
                                    </Card>
                                  </OverlayTrigger>
                                </Col>
                              )}
                          </>
                        ))}

                        {Item?.map((item, index) => (
                          <>
                            {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
                              item?.sizeVariance?.length === 0 && item?.isDaySpeacial &&
                              !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay) && (
                                <Col sm={6} lg={3} md={4} key={index}>
                                  <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>Not available today & no varients</Tooltip>
                                    }
                                    showLabel={"Show"}
                                    hideLabel={"Hide"}
                                  >
                                    <Card className="card-one mb-3 variant-card disabled-variant">
                                      <Card.Body className="p-0">
                                        <Link className="d-block h-100 text-dark p-2">
                                          <div className="indicator-varient g-2">
                                            <span className={`indicator d-block me-1 ${
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" : 
                                                item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" : 
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" : 
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                              }`}
                                            ></span>
                                            {item?.addOnGroups?.filter((addonItems) => addonItems.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                            ).length > 0 && (
                                              <img src={verientIcon} alt="varient" className="wt-18 me-2 opacity-75" />
                                            )}
                                            <img src={warningErr} alt="varient" className="wt-22 me-1" />
                                            {item?.isDaySpeacial && !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay
                                              ) && (
                                                <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                              )}
                                          </div>

                                          <h3 className="fs-14 fw-semibold text-dark">{item.catalogueName}</h3>
                                        </Link>
                                      </Card.Body>
                                    </Card>
                                  </OverlayTrigger>
                                </Col>
                              )}
                          </>
                        ))}

                        {Item?.map((item, index) => (
                          <>
                            {item.status === "9251e44c-dda2-40d9-8d96-b392d974331b" &&
                              item?.sizeVariance?.length === 0 &&
                              (!item?.isDaySpeacial || item?.catalogueOnDays?.some(
                                  (day) => day.dayName === todaysDay )) && (
                                <Col sm={6} lg={3} md={4} key={index}>
                                  <OverlayTrigger overlay={<Tooltip id={`tooltip-top`}>No varients</Tooltip>}
                                    showLabel={"Show"}
                                    hideLabel={"Hide"}
                                  >
                                    <Card className="card-one mb-3 variant-card disabled-variant">
                                      <Card.Body className="p-0">
                                        <Link className="d-block h-100 text-dark p-2">
                                          <div className="indicator-varient g-2">
                                            <span className={`indicator d-block me-1 ${
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de65" ? "non-veg" : 
                                                item.foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a" ? "veg" : 
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de67" ? "eggiterian" : 
                                                item.foodType === "a634316f-857d-41fd-a74c-895f61e4de66" ? "not-applicable" : ""
                                              }`}
                                            ></span>
                                            {item?.addOnGroups?.filter(
                                              (addonItems) =>
                                                addonItems.groupTypeId !==
                                                "d9c5a952-911e-4a0f-8935-5e1fae01201e"
                                            ).length > 0 && (
                                              <img src={verientIcon} alt="varient" className="wt-18 me-1 opacity-75" />
                                            )}
                                            <img src={warningErr} alt="varient" className="wt-22 me-1" />
                                            {item?.isDaySpeacial && !item?.catalogueOnDays?.some((day) => day.dayName === todaysDay
                                              ) && (
                                                <span className="badge badge-pill bg-danger fs-10">Not available today</span>
                                              )}
                                          </div>

                                          <h3 className="fs-14 fw-semibold text-dark">
                                            {item.catalogueName}
                                          </h3>
                                        </Link>
                                      </Card.Body>
                                    </Card>
                                  </OverlayTrigger>
                                </Col>
                              )}
                          </>
                        ))}
                      </Row>
                    </>
                  ) : (
                    <>
                      <OrderEmptyState />
                    </>
                  )}
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>
      </div>
    </React.Fragment>
  );
}
