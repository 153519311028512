import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const GetTemplateByTenantId  = async (tenantId)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.get(`${Environment.Setting_Url}print-template-by-tenant/${tenantId}`,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  }
  export default{GetTemplateByTenantId}