import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Tabs, Tab, Row, Col, Form, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { MapKitchenMenu, UnMapKitchenMenu, UpdateKitchen } from "../../../../application/actions/kitchenAction";
import { GetCategoryByMainCatId } from "../../../../application/actions/menuAction";
import { getCategoryList, getKitchenDetails, getLoading, getMenuList } from "../../../../application/selectors/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../../layouts/ShimmerTable";
export default function EditKitchen(props) {
  const [selectedMainId, setMainId] = useState(Environment.defaultValue);
  const [selectedCatId, setCatId] = useState(Environment.defaultValue);
  const MenuList = useSelector(getMenuList);
  const CategoryList = useSelector(getCategoryList);
  const KitchenDetails = useSelector(getKitchenDetails);
  const { loading, value } = useSelector(getLoading);

  const initialState = {
    id: "",
    branchId: 0,
    isVisible: "",
    kitchenName: "",
    kitchenDescription: "",
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (KitchenDetails) {
      setFormData({
        id: KitchenDetails?.id,
        branchId: KitchenDetails?.branchId,
        kitchenName: KitchenDetails?.kitchenName,
        kitchenDescription: KitchenDetails?.kitchenDescription,
        isVisible: KitchenDetails?.isVisible,
      });
    }
  }, [KitchenDetails]);

  const [activeTab, setActiveTab] = useState("details_tab");
useEffect(()=>{
  setActiveTab(props?.tabData)
},[props?.tabData])
  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    dispatch(UpdateKitchen(formData));
    setFormData(initialState);
    setMainId(Environment.defaultValue)
    setCatId(Environment.defaultValue)
    props.closeFunction();
  };
  const columns = [
    {
      name: "Group Title",
      selector: (row) => (
        <div>
          <h6 className="fs-14 fw-semibold mb-1">{row.menuName}</h6>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <Button
          variant="danger"
          className="d-flex align-items-center gap-2"
          onClick={()=>handleDissociate(row.mappingId)}
        >
          <i className="ri-delete-bin-line"></i>
          <span className="d-sm-inline">Disassociate</span>
        </Button>
      ),
    },
  ];
  const handleSelectMenu = (id) => {
    setMainId(id);
    dispatch(GetCategoryByMainCatId(id));
  };
  const MapMenu = () => {
    const Obj = {
      kitchenId: KitchenDetails?.id,
      mainCategoryId: selectedMainId,
      categoryId: selectedCatId,
      branchId: KitchenDetails?.branchId,
    };
    dispatch(MapKitchenMenu(Obj));
  };
  const handleDissociate = (id) => {
   dispatch(UnMapKitchenMenu(id))
  };
  const handleVisibilityChange = (e) => {
    const isVisible = JSON.parse(e.target.value); // Convert string value to boolean
    setFormData({ ...formData, isVisible });
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-45"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>Edit Kitchen</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-3">
            <h2 className="fs-18 mb-2 fw-semibold">{KitchenDetails?.kitchenName}</h2>
            <h5 className="fs-14">{KitchenDetails?.kitchenDescription}</h5>
          </div>

          <Tabs
            id="custom-styled-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="sale-on-point-tabs"
          >
            <Tab
              eventKey="details_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Details</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body mt-3">
                <Row>
                  <Col xl="8">
                    <div className="mb-4">
                      <Form.Label>
                        Kitche Name: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Group Title"
                        name="kitchenName"
                        value={formData.kitchenName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            kitchenName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Enter Description"
                        name="kitchenDescription"
                        value={formData.kitchenDescription}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            kitchenDescription: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                {/* <Row>
                  <Col xl="6">
                    <div className="mb-4">
                      <Form.Label>
                        IsVisible: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="isVisible"
                        value={formData.isVisible.toString()}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            isVisible: Boolean(e.target.value),
                          })
                        }
                      >
                        <option value="true">Active</option>
                        <option value="false">Inactive</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row> */}
                 <Row>
      <Col xl="6">
        <div className="mb-4">
          <Form.Label>
            IsVisible: <sup className="text-danger">*</sup>
          </Form.Label>
          <Form.Select
            aria-label="Default select example"
            name="isVisible"
            value={formData.isVisible?.toString()}
            onChange={handleVisibilityChange}
          >
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </Form.Select>
        </div>
      </Col>
    </Row>
                {/* <Row>
                  <Col xl="6">
                    <div className="mb-4">
                      <Form.Label>
                        Location: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="branchId"
                        value={formData.branchId}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            branchId: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          Choose Location
                        </option>
                        <option value="1">Chembur</option>
                        <option value="2">Nerul</option>
                        <option value="3">Mumbra</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row> */}
              </div>
            </Tab>

            <Tab
              eventKey="associated_menu_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">
                    Associated Menu
                    <Badge bg="danger" className="associated-menu-badge" pill>
                     { KitchenDetails?.mappedCategory?.length}
                    </Badge>
                  </h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <div className="bg-gray-200 p-3 mb-4">
                  <h4 className="fs-12 mb-2">Add Associated Sub Menus:</h4>
                  <Row>
                    <Col xl={5} sm={12}>
                      <Form.Label>
                        Main Menu: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="mainMenu"
                        value={selectedMainId}
                        onChange={(e) => {
                          handleSelectMenu(e.target.value);
                        }}
                      >
                         <option value={Environment.defaultValue}>
                           Choose Main Menu
                          </option>
                        {MenuList?.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.mainCatName}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xl={5} sm={12}>
                      <Form.Label>
                        Sub Menu: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="mainMenu"
                        value={selectedCatId}
                        onChange={(e) => {
                          setCatId(e.target.value);
                        }}
                      >
                        <option value={Environment.defaultValue}>
                           Choose Sub Menu
                          </option>
                        {CategoryList?.map((item, index) => (
                          <option key={index} value={item.id}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col xl={2} sm={12}>
                      <div className="mt-4 pt-1">
                        <Button variant="primary" onClick={MapMenu}>
                          Associate
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="custom-common-table">
                {KitchenDetails?.mappedCategory && (
                  <DataTable
                    columns={columns}
                    data={KitchenDetails?.mappedCategory}
                    fixedHeader
                    search={true}
                    highlightOnHover
                    pagination
                  ></DataTable>
                )}
</div>
                {!loading && !KitchenDetails?.mappedCategory && (
                  <TableShimmerLoader  columnCount={4} rowCount={4}/>
                )}
                {loading &&
                  value === "addonsGroupList" &&
                  !KitchenDetails?.mappedCategory && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
             
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button variant="primary" className="fs-14" onClick={onSubmit}>
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Save Changes</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
