import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button } from "react-bootstrap";
import { getKOTRemarkList, getLoading } from "../../../../application/selectors/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteKOTRemarks,
  KOTRemarkList,
} from "../../../../application/actions/KOTAction";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../../layouts/ShimmerTable";
import { CreateKOTRemarks } from "./createKOTRemark";
import { UpdateKOTRemarks } from "./editKOTRemarks";
import { format } from "date-fns";
import SweetAlertService from "../../../_common/SweetAlertService";
import ToastifyService from "../../../_common/ToastifyService";

export default function ReMarkComponent() {
  const dispatch = useDispatch();
  const [showAddKOTRemark, setShowAddKOTRemark] = useState(false);
  const [showEditKOTRemarks, setShowEditKOTRemarks] = useState(false);
  const [KOTRemarksData, setKOTRemarksData] = useState([]);

  const kotRemarkList = useSelector(getKOTRemarkList);
  const {loading} = useSelector(getLoading);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  useEffect(() => {
    dispatch(KOTRemarkList);
  }, []);

  const handleAddNewKOTRemark = (state) => {
    setShowAddKOTRemark(state);
  };
  const handleEditKOTRemarks = (data, state) => {
    setKOTRemarksData(data);
    setShowEditKOTRemarks(state);
  };
  const handleDeleteKOTRemarks = (id) => {
    SweetAlertService.showAlert(
      'Delete KOT Remark',
      'Are you sure you want to delete this KOT Remark?',
      'warning'
  ).then((result) => {
      if (result.isConfirmed) {
          SweetAlertService.showSuccess('Success', 'KOT Remark!');
          dispatch(DeleteKOTRemarks(id));
          ToastifyService.success(`Deleted KOT Remark Successfully`);
      } else {
          console.log('Delete operation cancelled!');
      }
  }).catch((error) => {
      console.error('Error:', error);
      ToastifyService.error(`something went wrong`);
  });
    
  };
  const columns = [
    {
      name: "Remark Content",
      selector: (row) => (
        <div onClick={() => handleEditKOTRemarks(row, true)}>
          <div>{row.remark}</div>
        </div>
      ),
    },
    {
      name: "Create On",
      selector: (row) => (
        <div>
          <div>
            <h6 className="fs-14 fw-semibold mb-1">
              {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")}
            </h6>
            <h5 className="fs-12">System</h5>
          </div>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <div className="w-100">
            <div
              className={`d-flex justify-content-end`}
            >
              <Button
                variant="primary"
                className="btn-icon me-2"
                onClick={() => handleEditKOTRemarks(row, true)}
              >
                <i className="ri-pencil-line"></i>
              </Button>
              <Button
                variant="danger"
                className="btn-icon"
                onClick={() => handleDeleteKOTRemarks(row.id)}
              >
                <i className="ri-delete-bin-line"></i>
              </Button>
            </div>
          </div>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">Predefined KOT Remarks</h4>
          <h5 className="fs-14 text-secondary">
            Define list of predefined Order Type for orders
          </h5>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => handleAddNewKOTRemark(true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add KOT</span>
          </Button>
        </div>
      </div>
      <CreateKOTRemarks
        show={showAddKOTRemark}
        closeFunction={handleAddNewKOTRemark}
      />
      <UpdateKOTRemarks
        show={showEditKOTRemarks}
        data={KOTRemarksData}
        closeFunction={handleEditKOTRemarks}
      />
      <div className="custom-common-table">
      {!loading && (
        <DataTable
          columns={columns}
          data={kotRemarkList}
          fixedHeader
          search={true}
          highlightOnHover
          pagination
        ></DataTable>
      )}
      </div>
      {loading && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
    </React.Fragment>
  );
}
