export const VARIANCE_LIST = 'VARIANCE_LIST'
export const VARIANCE_LIST_ERROR = 'VARIANCE_LIST_ERROR'
export const VARIANCE_LIST_SUCCESS = 'VARIANCE_LIST_SUCCESS'
export const ADD_VARIANCE = 'ADD_VARIANCE'
export const GET_VARIANCE_BY_ITEM_ID = 'GET_VARIANCE_BY_ITEM_ID'
export const GET_VARIANCE_BY_ID_SUCCESS = 'GET_VARIANCE_BY_ID_SUCCESS'
export const COMMON_FIELD = 'COMMON_FIELD'
export const VARIANCE_TYPES_SUCCESS = 'VARIANCE_TYPES_SUCCESS'
export const COMMON_SIZE_VARIANTS_SUCCESS = 'COMMON_SIZE_VARIANTS_SUCCESS'
export const COMMON_MEASUREMENT_SUCCESS = 'COMMON_MEASUREMENT_SUCCESS'
export const GET_VARIANCE_SUCCESS = 'GET_VARIANCE_SUCCESS'
export const RESET_ALL_BRANCHES = 'RESET_ALL_BRANCHES'
export const MAP_BRANCH_VARIANCE = 'MAP_BRANCH_VARIANCE'
export const RESET_ALL_BY_BRANCH_ID = 'RESET_ALL_BY_BRANCH_ID'
export const SET_VARIANT_MODE = 'SET_VARIANT_MODE'
export const SET_VARIANT_MODE_SUCCESS = 'SET_VARIANT_MODE_SUCCESS'
export const COPY_VARIANCE = 'COPY_VARIANCE'
export const BRANCH_VAR_FEATURES_UPDATE = 'BRANCH_VAR_FEATURES_UPDATE'
export const COMMON_FIELD_TYPE_BY_GROUP_CODE = 'COMMON_FIELD_TYPE_BY_GROUP_CODE'
export const COMMON_FIELD_TYPE_BY_GROUP_CODE_SUCCESS = 'COMMON_FIELD_TYPE_BY_GROUP_CODE_SUCCESS'
export const UNMAP_BRANCH_VARIANCE = 'UNMAP_BRANCH_VARIANCE'
export const UNMAP_GLOBAL_VARIANCE_BY_ID = 'UNMAP_GLOBAL_VARIANCE_BY_ID'
export const CATALOGUE_FEATURE_SUCCESS = 'CATALOGUE_FEATURE_SUCCESS'
export const UPDATE_BRANCH_VARIANCE = 'UPDATE_BRANCH_VARIANCE'

export const GetVariance = {
    type: VARIANCE_LIST 
};
export const GetVarianceError = {
   type: VARIANCE_LIST_ERROR 
};
export const GetVarianceSuccess = (data)=>({
    type: GET_VARIANCE_SUCCESS,   
    payload:{data}
});
export const AddVariance = (data)=>({
    type: ADD_VARIANCE,   
    payload:{data}
});
export const CopyVariance = (data)=>({
    type: COPY_VARIANCE,   
    payload:{data}
});
export const GetVarianceByItemId = (data)=>({
    type: GET_VARIANCE_BY_ITEM_ID,   
    payload:{data}
});
export const GetVarianceByIdSuccess = (data)=>({
    type: GET_VARIANCE_BY_ID_SUCCESS,   
    payload:{data}
});
  export const SetVariantModeType=(data)=>({
    type:SET_VARIANT_MODE,
    payload:{data}
 })  
 export const GetVarianceTypeSuccess=(data)=>({
    type:SET_VARIANT_MODE_SUCCESS,
    payload:{data}
 })
  export const ResetAllBranches=(data)=>({
    type:RESET_ALL_BRANCHES,
    payload:data
 }) 
  export const ResetAllByBranchId=(data)=>({
    type:RESET_ALL_BY_BRANCH_ID,
    payload:{data}
 })
 export const BranchVarianceFeaturesUpdate=(data)=>({
    type:BRANCH_VAR_FEATURES_UPDATE,
    payload:{data}
 })
 export const GetCommonFieldsTypeByGroupCode=(data)=>({
    type:COMMON_FIELD_TYPE_BY_GROUP_CODE,
    payload:data
 })
 export const CommonFieldsTypeByGroupCodeSuccess=(data)=>({
    type:COMMON_FIELD_TYPE_BY_GROUP_CODE_SUCCESS,
    payload:{data}
 })
 export const MapBranchVariance=(data)=>({
    type:MAP_BRANCH_VARIANCE,
    payload:data
 })
 export const UnMapGlobalVarianceById=(data)=>({
   type:UNMAP_GLOBAL_VARIANCE_BY_ID,
   payload:data
}) 
export const UnMapBranchVariance=(data)=>({
   type:UNMAP_BRANCH_VARIANCE,
   payload:data
})
export const UpdateBranchVariance=(data)=>({
   type:UPDATE_BRANCH_VARIANCE,
   payload:data
})
