
import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getVendorListByTenantId } from "../../../application/selectors/indexSelector";
import DataTable from "react-data-table-component";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import SweetAlertService from "../../_common/SweetAlertService";
import ToastifyService from "../../_common/ToastifyService";
import AddVendor from "./add-vendor";
import EditVendor from "./edit-vendor";
import { VendorList } from './../../../application/actions/vendorAction';

export default function Vendor() {
  const { user } = useContext(AuthContext)
  useEffect(() => {
    Prism.highlightAll();
    dispatch(VendorList);
  }, []);
  const dispatch = useDispatch()
  const [showVendor, setShowVendor] = useState(false);
  const handleVendor = (state) => {
    setShowVendor(state)
  };

  const [showUpdateVendor, setShowUpdateVendor] = useState(false);
  const handleUpdateVendor = (data, state) => {
    setVendor(data)
    setShowUpdateVendor(state)
  };
  const VendorsListData = useSelector(getVendorListByTenantId)
  const [vendor, setVendor] = useState({})
  const handleDeleteVendor = (id) => {
    SweetAlertService.showAlert(
      'Delete User Role',
      'Are you sure you want to delete this User Role?',
      'warning'
    ).then((result) => {
      if (result.isConfirmed) {
        SweetAlertService.showSuccess('Success', 'User Role!');
        // dispatch(DeleteVendor(id))
        ToastifyService.success(`Deleted User Role Successfully`);
      } else {
        console.log('Delete operation cancelled!');
      }
    }).catch((error) => {
      console.error('Error:', error);
      ToastifyService.error(`something went wrong`);
    });

  }
  const columns = [
    {
      name: "Vendor Name",
      selector: (row) => (
        <div>
          <h6
            className="fs-14 fw-semibold mb-1"
          // onClick={() => handleEditAddons(row, true)}
          >
            {row.vendorName}
          </h6>
        </div>
      ),
    },
    {
      name: "Vendor Address",
      selector: (row) => row.address ? row.address : '-',
    },
    {
      name: "Last Updated On",
      selector: (row) => row.modifiedOn,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleUpdateVendor(row, true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="danger"
            className="btn-icon me-2"
            onClick={() => handleDeleteVendor(row.id)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = VendorsListData?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <React.Fragment>
      <AddVendor show={showVendor} closeFunction={handleVendor} />
      <EditVendor show={showUpdateVendor} closeFunction={handleUpdateVendor} data={vendor} />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">Vendor</h4>
          <h5 className="fs-14 text-secondary">Configure basic settings of your organisation.</h5>
        </div>

        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button variant="primary" className="d-flex align-items-center gap-1" onClick={() => handleVendor(true)}>
            <i className="ri-add-line fs-18 lh-1"></i><span className="d-sm-inline">Add Vendor</span>
          </Button>
        </div>
      </div>
      <Card className="card-one">
        <Card.Header className="align-items-center justify-content-between">
        <h4 className="main-title fs-14 mb-0">List of Vendor</h4>
          <div className="custom-dropdown-wrapper">
            <div className="custom-drop-down z-index-2 wt-300">
              <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
              <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="custom-common-table">
            <DataTable
              columns={columns}
              data={filteredEmployees}
              fixedHeader
              search={true}
              highlightOnHover
              pagination
            ></DataTable>
          </div>
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}