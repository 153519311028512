import {
  CREATE_ORDER_SUCCESS,
  GET_CHARGE_BY_BRANCHID_SUCCESS,
  GET_DISCOUNT_BY_BRANCHID_SUCCESS,
  GET_WAITERLIST_POS_SUCCESS,
  ITEM_DETAIL_ON_POS_SUCCESS,
  ORDERS_BY_ORDERTYPE_SUCCESS,
  ORDER_RELATED_CHANGES,
  RESET_ORDER_DETAILS,
  TABLESECTION_BY_BRANCH_SUCCESS,
  TRANSFERED_TABLE_ORDERID,
  TRANSFER_MERGE_CHANGES,
} from "../actions/posAction";
const initialState = {
  ItemDetailOnPOS: [],
  CreateOrderSuccess: undefined,
  WaiterListPOSSuccess: [],
  chargeListByBranchIdSuccess: [],
  discountListByBranchIdSuccess: [],
  tableSectionByOrderType: undefined,
  transferedTableOrderId: undefined,
  ordersByOrderType: undefined,
  orderRelatedChangesDone: false,
  transferMergeChangesDone: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ITEM_DETAIL_ON_POS_SUCCESS:
      return { ...state, ItemDetailOnPOS: action.payload.result, error: null };
    case CREATE_ORDER_SUCCESS:
      return {
        ...state,
        CreateOrderSuccess: action.payload.result,
        error: null,
      };
    case GET_WAITERLIST_POS_SUCCESS:
      return {
        ...state,
        WaiterListPOSSuccess: action.payload.result,
        error: null,
      };
    case GET_DISCOUNT_BY_BRANCHID_SUCCESS:
      return {
        ...state,
        discountListByBranchIdSuccess: action.payload.result,
        error: null,
      };
    case GET_CHARGE_BY_BRANCHID_SUCCESS:
      return {
        ...state,
        chargeListByBranchIdSuccess: action.payload.result,
        error: null,
      };
    case RESET_ORDER_DETAILS:
      return { ...state, CreateOrderSuccess: undefined, error: null };
    case ORDER_RELATED_CHANGES:
      return { ...state, orderRelatedChangesDone: action.payload, error: null };
    case TRANSFER_MERGE_CHANGES:
      return {
        ...state,
        transferMergeChangesDone: action.payload,
        error: null,
      };
    case TABLESECTION_BY_BRANCH_SUCCESS:
      return {
        ...state,
        tableSectionByOrderType: action.payload,
        error: null,
      };
    case ORDERS_BY_ORDERTYPE_SUCCESS:
      return {
        ...state,
        ordersByOrderType: action.payload,
        error: null,
      };
    case TRANSFERED_TABLE_ORDERID:
      return {
        ...state,
        transferedTableOrderId: action.payload,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
