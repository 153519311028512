export const CUSTOMER_LIST_BY_TENANT = "CUSTOMER_LIST_BY_TENANT";
export const CUSTOMER_LIST_BY_TENANT_SUCCESS =
  "CUSTOMER_LIST_BY_TENANT_SUCCESS";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const CUSTOMER_DETAIL = "CUSTOMER_DETAIL";
export const CUSTOMER_DETAIL_SUCCESS = "CUSTOMER_DETAIL_SUCCESS";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const CUSTOMER_SUMMARY = "CUSTOMER_SUMMARY";
export const CUSTOMER_SUMMARY_SUCCESS = "CUSTOMER_SUMMARY_SUCCESS";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const CUSTOMER_ORDER_LIST = "CUSTOMER_ORDER_LIST";
export const CUSTOMER_ORDER_LIST_SUCCESS = "CUSTOMER_ORDER_LIST_SUCCESS";
export const CUSTOMER_LOYALTY_POINTS_LIST_SUCCESS =
  "CUSTOMER_LOYALTY_POINTS_LIST_SUCCESS";
export const CUSTOMER_LOYALTY_POINTS_LIST = "CUSTOMER_LOYALTY_POINTS_LIST";
export const CUSTOMER_ADDRESS_LIST = "CUSTOMER_ADDRESS_LIST";
export const CUSTOMER_ADDRESS_LIST_SUCCESS = "CUSTOMER_ADDRESS_LIST_SUCCESS";
export const CANCEL_ORDER_OF_CUSTOMER = "CANCEL_ORDER_OF_CUSTOMER";
export const ADD_CUSTOMER_ADDRESS = "ADD_CUSTOMER_ADDRESS";
export const UPDATE_CUSTOMER_DETAILS_IN_ORDER =
  "UPDATE_CUSTOMER_DETAILS_IN_ORDER";
export const UPDATE_CUSTOMER_ADDRESS = "UPDATE_CUSTOMER_ADDRESS";
export const RESET_CUSTOMER_DETAILS = "RESET_CUSTOMER_DETAILS";
export const UPLOAD_CUSTOMER = "UPLOAD_CUSTOMER";
export const ASK_FOR_BUSINESS_REVIEW = "ASK_FOR_BUSINESS_REVIEW";
export const GET_CUSTOMER_SEARCHED_LIST = "GET_CUSTOMER_SEARCHED_LIST";
export const GET_CUSTOMER_SEARCHED_LIST_SUCCESS = "GET_CUSTOMER_SEARCHED_LIST_SUCCESS";

export const customerListByTenant = (id) => ({
  type: CUSTOMER_LIST_BY_TENANT,
  payload: id,
});
export const customerListByTenantSuccess = (data) => ({
  type: CUSTOMER_LIST_BY_TENANT_SUCCESS,
  payload: data,
});
export const createCustomer = (data) => ({
  type: CREATE_CUSTOMER,
  payload: data,
});
export const customerDetail = (id) => ({
  type: CUSTOMER_DETAIL,
  payload: id,
});
export const customerDetailSuccess = (data) => ({
  type: CUSTOMER_DETAIL_SUCCESS,
  payload: data,
});
export const updateCustomer = (data) => ({
  type: UPDATE_CUSTOMER,
  payload: data,
});
export const customerSummary = (id) => ({
  type: CUSTOMER_SUMMARY,
  payload: id,
});
export const deleteCustomer = (id) => ({
  type: DELETE_CUSTOMER,
  payload: id,
});
export const customerOrderList = (id) => ({
  type: CUSTOMER_ORDER_LIST,
  payload: id,
});
export const customerOrderListSuccess = (data) => ({
  type: CUSTOMER_ORDER_LIST_SUCCESS,
  payload: data,
});
export const customerSummarySuccess = (data) => ({
  type: CUSTOMER_SUMMARY_SUCCESS,
  payload: data,
});
export const customerLoyaltyPointsList = (id) => ({
  type: CUSTOMER_LOYALTY_POINTS_LIST,
  payload: id,
});
export const customerLoyaltyPointsListSuccess = (data) => ({
  type: CUSTOMER_LOYALTY_POINTS_LIST_SUCCESS,
  payload: data,
});
export const customerAddressList = (id) => ({
  type: CUSTOMER_ADDRESS_LIST,
  payload: id,
});
export const customerAddressListSuccess = (data) => ({
  type: CUSTOMER_ADDRESS_LIST_SUCCESS,
  payload: data,
});
export const cancelOrderOfCustomer = (id) => ({
  type: CANCEL_ORDER_OF_CUSTOMER,
  payload: id,
});
export const addCustomerAddress = (data) => ({
  type: ADD_CUSTOMER_ADDRESS,
  payload: data,
});
export const updateCustomerAddress = (data) => ({
  type: UPDATE_CUSTOMER_ADDRESS,
  payload: data,
});
export const updateCustomerDetailsInOrder = (id) => ({
  type: UPDATE_CUSTOMER_DETAILS_IN_ORDER,
  payload: id,
});
export const uploadCustomer = (data) => ({
  type: UPLOAD_CUSTOMER,
  payload: data,
});
export const resetCustomerDetails = {
  type: RESET_CUSTOMER_DETAILS,
};
export const askForBusinessReview = (data) => ({
  type: ASK_FOR_BUSINESS_REVIEW,
  payload: data,
});
export const getSearchedListOfCustomer = (data) => ({
  type: GET_CUSTOMER_SEARCHED_LIST,
  payload: data,
});
export const getSearchedListOfCustomerSuccess = (data) => ({
  type: GET_CUSTOMER_SEARCHED_LIST_SUCCESS,
  payload: data,
});