import React from "react";
import "./Loader.scss";

function Loader({ message }) {
  return (
    <div className="d-flex justify-content-center common-loader text-center">
      <div className="loader-content">
        <div className="spinner-border" role="status"></div>
        <h5 className="message mt-3 fs-14">{message}</h5>
      </div>
    </div>
  );
}

export default Loader;
