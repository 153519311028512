import React, { useContext, useEffect, useRef, useState } from "react";
import { Offcanvas, Button, Form, Row, Col, Spinner } from "react-bootstrap";
import Select from "react-select";
import guestsIcon from "../../assets/img/icons/guests.png";
import AddNewCustomer from "../Customers/add-customer";
import { useDispatch, useSelector } from "react-redux";
import {
  getCustomerSearchedList,
  getWaiterListPOSSuccess, getLoading
} from "../../application/selectors/indexSelector";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
import Environment from "../../infrastructure/core/Environment";
import { getSearchedListOfCustomer } from "../../application/actions/customerAction";

export default function NewGuestAssigned(props) {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [customer, setCustomer] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [customerId, setCustomerId] = useState(Environment.defaultValue);
  const [seller, setSeller] = useState("");
  const [value, setValue] = useState(1);
  const [errors, setErrors] = useState({});
  const [error, setError] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const blurTimeoutRef = useRef(null);
  const [phoneNumber, setPhoneNumber] = useState("");
  const loading = useSelector(getLoading).loading;
  const waiterList = useSelector(getWaiterListPOSSuccess)
    .filter((waiterRole) => waiterRole.isOrderTake === true)
    .map((waiter) => ({
      value: waiter.id,
      label: waiter.fullName,
    }));
  const customerSearchedList = useSelector(getCustomerSearchedList);

  useEffect(() => {
    if (props?.show) {
      setCustomerId(Environment.defaultValue);
      setSeller("");
      setValue(1);
      setSearchQuery("");
      setErrors({});
    }
  }, [props.show]);
  useEffect(() => {
    if (customerSearchedList) {
      setSearchResults(customerSearchedList);
    }
  }, [customerSearchedList]);
  const handleAddCustomer = (state) => {
    setShowAddCustomer(state);
  };
  const handleSelectCustomer = (selected, type) => {
    if (selected) {
      if (type === "number") {
        setCustomer(selected);
        setCustomerId(selected?.id);
        setSearchQuery(selected?.contactNumber); // Update search query with selected customer's name
      }
    }
  };
  const handleBlur = () => {
    blurTimeoutRef.current = setTimeout(() => {
      setMenuIsOpen(false);
    }, 200);
  };
  const handleFocus = () => {
    setMenuIsOpen(false);
    clearTimeout(blurTimeoutRef.current);
  };
  const fetchCustomer = (phone) => {
    setError("");
    dispatch(getSearchedListOfCustomer(phone));
  };
  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    setMenuIsOpen(value.length >= 7 && searchResults?.length !== 0);
    setShowAddCustomer(false);
    if (value.length === 10 && searchResults?.length === 0) {
      setShowAddCustomer(true);
      setSearchResults(undefined);
    } else if (value.length >= 7) {
      fetchCustomer(value);
    } else {
      setCustomer(null);
      setSearchResults(undefined);
    }
  };
  const handleSubmit = () => {
    if (seller === "") {
      const newErrors = {};
      newErrors.noSellerSelected = "Please Select Seller";
      setErrors(newErrors);
      return;
    }
    const formObject = {
      guestCount: value,
      sellerId: seller,
      customerId: customerId,
    };
    props?.handleAddItem(props.selectedTab?.tableData, true, formObject);
  };
  return (
    <React.Fragment>
      <AddNewCustomer
        show={showAddCustomer}
        closeFunction={handleAddCustomer}
      />
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {props?.selectedTab?.tableData?.tableName}{" "}
            <span className="fs-14 text-black-50">
              ({props?.selectedTabSection})
            </span>
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="mb-4">
            <div className="mb-1">
              <img className="wt-50 opacity-75" src={guestsIcon} alt="guests" />
            </div>
            <h5 className="fs-14 text-dark fw-semibold mb-3">
              How many guests? <sup className="text-danger">*</sup>
            </h5>
            <div className="qty">
              <Button
                type="button"
                variant="outline-dark"
                className="minus"
                onClick={() => {
                  if (value > 1) {
                    setValue(value - 1);
                  }
                }}
              >
                <i className="ri-subtract-line"></i>
              </Button>
              <input type="number" min="1" className="count wt-40" name="qty" value={value} readOnly />
              <Button
                type="button"
                variant="outline-dark"
                className="plus"
                onClick={() => setValue(value + 1)}
              >
                <i className="ri-add-line"></i>
              </Button>
            </div>
          </div>

          <Row>
            <Col md={8}>
              <div className="mb-4 position-relative z-index-10">
                <Form.Label>Seller Name <sup className="text-danger">*</sup></Form.Label>
                <Select
                  options={waiterList}
                  isSearchable={true}
                  onChange={(e) => {
                    setSeller(e.value);
                    setErrors({ ...errors, noSellerSelected: "" });
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.noSellerSelected}
                </Form.Text>
              </div>
            </Col>

            <Col md={8}>
              <div className="mb-2 custom-dropdown-wrapper position-relative">
                <Form.Label>Customer Number</Form.Label>
                <div className="custom-drop-down z-index-2">
                  <span className="dropdown-icon">+91</span>
                  <Form.Control
                    type="text"
                    placeholder="Search Customer Number"
                    value={searchQuery}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={handleSearchChange}
                    maxLength={10}
                    style={{paddingLeft: "45px"}}
                  />
                </div>
                {menuIsOpen && (
                  <ul className="options-container">
                    {searchResults?.map((customer) => (
                      <li
                        key={customer.id}
                        onClick={() => handleSelectCustomer(customer, "number")}
                        className="option"
                      >
                        <span className="fs-14">{customer.contactNumber}</span>
                        <small className="fs-12">{customer.fullName}</small>
                      </li>
                    ))}
                    {searchResults?.length === 0 && searchQuery?.length > 7 && (
                      <li className="d-block" onClick={handleAddCustomer}>
                        <a className="d-flex align-items-center justify-content-center pt-2 pb-2 text-center fs-12">
                          <i className="ri-add-line fs-16 lh-1"></i>
                          <span className="d-sm-inline"> Add New Customer</span>
                        </a>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </Col>

            <>
              {loading && value === 'getSearchedListOfCustomer' && <div className="customer-list-spinner"><Spinner className="align-middle" animation="border" variant="primary" /></div>}
              {error && <p>{error}</p>}
              {customer ? (
                <div>
                  <p className="fs-14">Name: <b>{customer.fullName}</b></p>
                </div>
              ) : (
                phoneNumber.length === 10 && <></>
              )}
            </>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <Button
            type="button"
            variant="primary"
            className="fs-14 d-flex justify-content-center gap-2 align-items-center pe-4 ps-4"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">ADD</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
