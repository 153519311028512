import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import {
  ADD_CUSTOMER_ADDRESS,
  ADD_CUSTOMER_REVIEW,
  ASK_FOR_BUSINESS_REVIEW,
  CANCEL_ORDER_OF_CUSTOMER,
  CREATE_CUSTOMER,
  CUSTOMER_ADDRESS_LIST,
  CUSTOMER_DETAIL,
  CUSTOMER_LIST_BY_TENANT,
  CUSTOMER_LOYALTY_POINTS_LIST,
  CUSTOMER_ORDER_LIST,
  DELETE_CUSTOMER,
  GET_CUSTOMER_SEARCHED_LIST,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_ADDRESS,
  UPDATE_CUSTOMER_DETAILS_IN_ORDER,
  UPLOAD_CUSTOMER,
  customerAddressListSuccess,
  customerDetailSuccess,
  customerListByTenantSuccess,
  customerLoyaltyPointsListSuccess,
  customerOrderListSuccess,
  getSearchedListOfCustomerSuccess,
} from "../actions/customerAction";
import { CreateOrderSuccess, orderRelatedChanges, transferedTableOrderId } from "../actions/posAction";
import ToastifyService from "../../View/_common/ToastifyService";

const customerListByTenant =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CUSTOMER_LIST_BY_TENANT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "customerList" }));
        const customerList = await api.customerApi.customerListByTenant(
          action.payload
        );
        dispatch(customerListByTenantSuccess(customerList));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "customerList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(Loading.setLoading({ loading: false, value: "customerList" }));
      }
    }
    next(action);
  };
const createCustomer =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_CUSTOMER) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "createCustomer" })
        );
        const customerList = await api.customerApi.createCustomer(
          action.payload
        );
        dispatch(customerListByTenantSuccess(customerList));
        ToastifyService.success("Customer Created Successfully!");
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "createCustomer" })
        );
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "createCustomer" })
        );
      }
    }
    next(action);
  };
const customerDetail =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CUSTOMER_DETAIL) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "customerDetail" })
        );
        const customerData = await api.customerApi.customerDetail(
          action.payload
        );
        dispatch(customerDetailSuccess(customerData));
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "customerDetail" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "customerDetail" })
        );
      }
    }
    next(action);
  };
const updateCustomer =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_CUSTOMER) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "updateCustomer" })
        );
        const customerList = await api.customerApi.updateCustomer(
          action.payload
        );
        dispatch(customerListByTenantSuccess(customerList));

        ToastifyService.success("Customer Updated Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "updateCustomer" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "updateCustomer" })
        );
      }
    }
    next(action);
  };
const deleteCustomer =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_CUSTOMER) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "deleteCustomer" })
        );
        const customerList = await api.customerApi.deleteCustomer(
          action.payload
        );
        dispatch(customerListByTenantSuccess(customerList));
        ToastifyService.success("Customer Deleted Successfully!");
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "deleteCustomer" })
        );
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "deleteCustomer" })
        );
      }
    }
    next(action);
  };
const customerOrderList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CUSTOMER_ORDER_LIST) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "customerOrderList" })
        );
        const customerOrderList = await api.customerApi.customerOrderList(
          action.payload
        );
        dispatch(customerOrderListSuccess(customerOrderList));
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "customerOrderList" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "customerOrderList" })
        );
      }
    }
    next(action);
  };
const customerLoyaltyPointsList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CUSTOMER_LOYALTY_POINTS_LIST) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "customerLoyaltyPointsList",
          })
        );
        const customerLoyaltyPointsList =
        await api.customerApi.customerLoyaltyPointsList(action.payload);
        dispatch(customerLoyaltyPointsListSuccess(customerLoyaltyPointsList));
      } catch (error) {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "customerLoyaltyPointsList",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "customerLoyaltyPointsList",
          })
        );
      }
    }
    next(action);
  };
const customerAddressList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CUSTOMER_ADDRESS_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "customerAddressList" }));
        const customerAddressList = await api.customerApi.customerAddressList(
          action.payload
        );
        dispatch(customerAddressListSuccess(customerAddressList));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "customerAddressList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }finally{
        dispatch(Loading.setLoading({ loading: false, value: "customerAddressList" }));
      }
    }
    next(action);
  };
const cancelOrderOfCustomer =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CANCEL_ORDER_OF_CUSTOMER) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "cancelOrderOfCustomer" })
        );
        const customerOrderList = await api.customerApi.cancelOrderOfCustomer(
          action.payload
        );
        dispatch(customerOrderListSuccess(customerOrderList));
        ToastifyService.success("Order Cancelled Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "cancelOrderOfCustomer" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "cancelOrderOfCustomer" })
        );
      }
    }
    next(action);
  };
const addCustomerAddress =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_CUSTOMER_ADDRESS) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addCustomerAddress" })
        );
        const customerDetail = await api.customerApi.addCustomerAddress(
          action.payload
        );
        const customerList=await api.customerApi.customerListByTenant(
          action.payload.tenantId
        );
        dispatch(customerListByTenantSuccess(customerList));
        dispatch(customerDetailSuccess(customerDetail));
        ToastifyService.success("Customer Address Created Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "addCustomerAddress" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "addCustomerAddress" })
        );
      }
    }
    next(action);
  };
const updateCustomerAddress =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_CUSTOMER_ADDRESS) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "updateCustomerAddress" })
        );
        const customerDetail = await api.customerApi.updateCustomerAddress(
          action.payload
        );
        const customerList=await api.customerApi.customerListByTenant(
          action.payload.tenantId
        );
        dispatch(customerListByTenantSuccess(customerList));
        dispatch(customerDetailSuccess(customerDetail));
        ToastifyService.success("Customer Address Updated Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(
          Loading.setLoading({ loading: false, value: "updateCustomerAddress" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({ loading: false, value: "updateCustomerAddress" })
        );
      }
    }
    next(action);
  };
const updateCustomerDetailsInOrder =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_CUSTOMER_DETAILS_IN_ORDER) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "updateCustomerDetailsInOrder",
          })
        );
        const orderDetails = await api.customerApi.updateCustomerDetailsInOrder(
          action.payload.formObject
        );
        dispatch(transferedTableOrderId(action.payload.orderTypeId));
        dispatch(CreateOrderSuccess(orderDetails));
        dispatch(orderRelatedChanges(true));
        ToastifyService.success("Customer Updated Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong!");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "updateCustomerDetailsInOrder",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "updateCustomerDetailsInOrder",
          })
        );
      }
    }
    next(action);
  };
  const uploadCustomer =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPLOAD_CUSTOMER) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "uploadCustomer",
          })
        );
        const customerList = await api.customerApi.uploadCustomer(
          action.payload
        );
        dispatch(customerListByTenantSuccess(customerList));
        ToastifyService.success("Customer Updated Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong!");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "uploadCustomer",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "uploadCustomer",
          })
        );
      }
    }
    next(action);
  };
  const askForBusinessReview =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ASK_FOR_BUSINESS_REVIEW) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "askForBusinessReview",
          })
        );
        await api.customerApi.askForBusinessReview(
          action.payload
        );
        ToastifyService.success("Review Requested Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong!");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "askForBusinessReview",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "askForBusinessReview",
          })
        );
      }
    }
    next(action);
  };
  const getSearchedListOfCustomer =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_CUSTOMER_SEARCHED_LIST) {
      try {
        dispatch(
          Loading.setLoading({
            loading: true,
            value: "getSearchedListOfCustomer",
          })
        );
        const searchedList=await api.customerApi.getSearchedListOfCustomer(
          action.payload
        );
        dispatch(getSearchedListOfCustomerSuccess(searchedList))
      } catch (error) {
        ToastifyService.error("Something Went wrong!");
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "getSearchedListOfCustomer",
          })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      } finally {
        dispatch(
          Loading.setLoading({
            loading: false,
            value: "askForBusinessReview",
          })
        );
      }
    }
    next(action);
  };
export default [
  customerListByTenant,
  createCustomer,
  customerDetail,
  updateCustomer,
  deleteCustomer,
  customerOrderList,
  customerLoyaltyPointsList,
  customerAddressList,
  cancelOrderOfCustomer,
  addCustomerAddress,
  updateCustomerDetailsInOrder,
  updateCustomerAddress,
  uploadCustomer,
  askForBusinessReview,
  getSearchedListOfCustomer
];
