import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card } from "react-bootstrap";
import AddNewPaymentGateway from "./add-payment-gateway";
import payPalIcon from "../../../assets/img/icons/payment-gateway/payPal.png";
import stripeIcon from "../../../assets/img/icons/payment-gateway/stripe.png";
import cardIcon from "../../../assets/img/icons/payment-gateway/card.png";
import bankIcon from "../../../assets/img/icons/payment-gateway/bank.png";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";


export default function PaymentGateway() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [showAddPaymentGateway, setShowAddPaymentGateway] = useState(false);
    const handleAddPaymentGateway = (state) => {
        setShowAddPaymentGateway(state);
    }
    const { selectedBranchData } = useContext(AuthContext);
    const parsedBranchData = JSON.parse(selectedBranchData);
    return (
        <React.Fragment>
            <AddNewPaymentGateway show={showAddPaymentGateway} closeFunction={handleAddPaymentGateway} />
            <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
                <div>
                    <h4 className="fs-16 main-title mb-1">Payment Gateway</h4>
                </div>
                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-1" onClick={() => handleAddPaymentGateway(true)}>
                        <i className="ri-add-line fs-18 lh-1"></i>
                        <span className="d-sm-inline">Add Payment Gateway</span>
                    </Button>
                </div>
            </div>

            <Card className="card-one p-3 d-flex flex-row mb-2 align-items-center">
                <div className="card-icon wt-30 ht-30"><img src={payPalIcon} alt="icon" className="w-100 h-100 object-fit-contain" /></div>
                <div className="ms-3">
                    <h4 className="card-value text-dark fw-semibold fs-14 mb-0 text-uppercase">PayPal</h4>
                    <label className="card-title text-dark fs-12 mb-0 text-dark text-uppercase">Funds Recevied</label>
                </div>
                <div className="payment-itegration-bttn">
                    <h5 className="fs-14 text-dark fw-semibold">{parsedBranchData?.currencySymbol}250</h5>
                </div>
            </Card>
            
            <Card className="card-one p-3 d-flex flex-row mb-2 align-items-center">
                <div className="card-icon wt-30 ht-30"><img src={stripeIcon} alt="icon" className="w-100 h-100 object-fit-contain" /></div>
                <div className="ms-3">
                    <h4 className="card-value text-dark fw-semibold fs-14 mb-0 text-uppercase">Stripe</h4>
                    <label className="card-title text-dark fs-12 text-dark mb-0 text-uppercase">Invoice Paid</label>
                </div>
                <div className="payment-itegration-bttn">
                    <h5 className="fs-14 text-dark fw-bold">{parsedBranchData?.currencySymbol}480</h5>
                </div>
            </Card>

            <Card className="card-one p-3 d-flex flex-row mb-2 align-items-center">
                <div className="card-icon wt-30 ht-30"><img src={payPalIcon} alt="icon" className="w-100 h-100 object-fit-contain" /></div>
                <div className="ms-3">
                    <h4 className="card-value text-dark fw-semibold fs-14 mb-0 text-uppercase">PayPal</h4>
                    <label className="card-title text-dark fs-12 text-dark mb-0 text-uppercase">Funds Recevied</label>
                </div>
                <div className="payment-itegration-bttn">
                    <h5 className="fs-14 text-dark fw-bold">{parsedBranchData?.currencySymbol}250</h5>
                </div>
            </Card>

            <Card className="card-one p-3 d-flex flex-row mb-2 align-items-center">
                <div className="card-icon wt-30 ht-30"><img src={cardIcon} alt="icon" className="w-100 h-100 object-fit-contain" /></div>
                <div className="ms-3">
                    <h4 className="card-value text-dark fw-semibold fs-14 mb-0 text-uppercase">Credit Card</h4>
                    <label className="card-title text-dark fs-12 text-dark mb-0 text-uppercase">Top Up</label>
                </div>
                <div className="payment-itegration-bttn">
                    <h5 className="fs-14 text-dark fw-bold">{parsedBranchData?.currencySymbol}350</h5>
                </div>
            </Card>

            <Card className="card-one p-3 d-flex flex-row mb-2 align-items-center">
                <div className="card-icon wt-30 ht-30"><img src={bankIcon} alt="icon" className="w-100 h-100 object-fit-contain" /></div>
                <div className="ms-3">
                    <h4 className="card-value text-dark fw-semibold fs-14 mb-0 text-uppercase">Bank</h4>
                    <label className="card-title text-dark fs-12 text-dark mb-0 text-uppercase">Check Deposited</label>
                </div>
                <div className="payment-itegration-bttn">
                    <h5 className="fs-14 text-dark fw-bold">{parsedBranchData?.currencySymbol}240</h5>
                </div>
            </Card>
        </React.Fragment>
    )
}