import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import Header from "../../../layouts/Header";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import EditDiscount from "./edit-discount";
import { loadDiscount } from "../../../application/actions/loaderAction";
import { getDiscountList, getLoading } from "../../../application/selectors/indexSelector";
import { DeleteDiscount, GetDiscountByIdSuccess } from "../../../application/actions/discountAction";
import AddNewDiscount from "./add-discount";
import Environment from "../../../infrastructure/core/Environment";
import {debounce} from "lodash";

export default function Discount() {
  const dispatch = useDispatch();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";

  const [skin, setSkin] = useState(currentSkin);
  const [showEditDiscount, setshowEditDiscount] = useState(false);
  const discountList = useSelector(getDiscountList);
  const {loading,value} = useSelector(getLoading);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  switchSkin(skin);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(loadDiscount);
  }, []);

  const addIndexToData = (data) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1,
    }));
  };
  const discountListData = addIndexToData(discountList);

  const handleEditDiscount = (state, data) => {
    dispatch(GetDiscountByIdSuccess(data));
    setshowEditDiscount(true);
  };
  const handleDeleteDiscount = (data) => {
    dispatch(DeleteDiscount(data.id));
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => <div>{row.index}</div>,
      width: "80px",
    },
    {
      name: "Discount Name",
      selector: (row) => (
        <div>
          <h6 className="mb-0 fw-semibold">
            <Link onClick={() => handleEditDiscount(true, row)}>{row.discountTitle}</Link>
          </h6>
          <span className="fs-xs text-secondary">{row.discountDesc}</span>
        </div>
      ),
      width: "420px",
    },
    // {
    //   name: "Type",
    //   selector: (row) => row.discountTypeDesc,
    //   width: "150px",
    // },
    // {
    //   name: "Minimum Bill",
    //   selector: (row) => row.minDiscountValue,
    //   width: "150px",
    // },
    {
      name: "Max Disc. Allowed",
      selector: (row) => row.maxDiscountValue,
      width: "150px",
    },
    // {
    //   name: "Applicable On",
    //   selector: (row) => (
    //     <div>
    //       {row?.applicableTos?.map((data, index) => (
    //         <span key={index}>
    //           {data.applicableDesc}
    //           {index < row.applicableTos.length - 1 ? ", " : ""}
    //         </span>
    //       ))}
    //     </div>
    //   ),
    //   width: "150px",
    // },
    {
      name: "Created On",
      selector: (row) => format(new Date(row.createdOn), "dd MMM yyyy hh:mm a"),
      width: "250px",
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleEditDiscount(true, row)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handleDeleteDiscount(row)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
      width: "200px",
    },
  ];
  const [showUpdateDiscount, setShowUpdateDiscount] = useState(false);
  const handleUpdateDiscount = (state) => {
      setShowUpdateDiscount(state);
  };
  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);
  return (
    <React.Fragment>
    <Header passDataToPointOfSale={handleBranchIdFromHeader}/>
      <div className="main main-app p-3 p-lg-4">
      <AddNewDiscount show={showUpdateDiscount}  closeFunction={handleUpdateDiscount} handleDetail={()=>{setshowEditDiscount(true)}} />
        <EditDiscount show={showEditDiscount} closeFunction={()=>setshowEditDiscount(false)} />
        <div className="top-header d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Discounts</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handleUpdateDiscount(true, [])}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline"> Add New Discount</span>
            </Button>
          </div>
        </div>
        <Card className="card-one">
          <Card.Body className="custom-common-table">
               {discountList && discountList !== undefined &&(
                 <DataTable
                 columns={columns}
                 data={discountListData}
                 fixedHeader
                 search={true}
                 highlightOnHover
                 pagination
               ></DataTable>
               )}
            {!loading && discountList === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
            {loading && value==='dicountList' && discountList === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
