export const ADD_PAYMENT_MODE="ADD_PAYMENT_MODE";
export const ADD_PAYMENT_MODE_FAILURE="ADD_PAYMENT_MODE_FAILURE";
export const PAYMENT_MODE_LIST="PAYMENT_MODE_LIST";
export const PAYMENT_MODE_LIST_SUCCESS="PAYMENT_MODE_LIST_SUCCESS";
export const UPDATE_PAYMENT_MODE="UPDATE_PAYMENT_MODE";
export const DELETE_PAYMENT_MODE="DELETE_PAYMENT_MODE";
export const UPDATE_PAYMENT_MODE_VISIBILITY="UPDATE_PAYMENT_MODE_VISIBILITY";

export const AddPaymentModes=(data)=>({
    type:ADD_PAYMENT_MODE,
    payload:data
})
export const AddPaymentModeFailure=(data)=>({
    type:ADD_PAYMENT_MODE_FAILURE,
    payload:data
})
export const UpdatePaymentMode=(data)=>({
    type:UPDATE_PAYMENT_MODE,
    payload:data
})
export const DeletePaymentMode=(id)=>({
    type:DELETE_PAYMENT_MODE,
    payload:id
})
export const PaymentModeListByBranchId=(branchId)=>({
    type:PAYMENT_MODE_LIST,
    payload:branchId
})

export const PaymentModeListByBranchIdSuccess=(data)=>({
    type:PAYMENT_MODE_LIST_SUCCESS,
    payload:data
})
export const UpdatePaymentModeVisibility=(data)=>({
    type:UPDATE_PAYMENT_MODE_VISIBILITY,
    payload:data
})