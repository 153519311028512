import React, { useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import Environment from "../../../infrastructure/core/Environment";

function ComponentToPrint({ printData, onPrintRequested }) {
  const [printed, setPrinted] = useState(false);
  const commonfields = JSON.parse(localStorage.getItem("CommonFields"));
  const printableRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
  });
  
  useEffect(() => {
    if (!printed && printData) { 
      setPrinted(true);
      if (onPrintRequested) {
        handlePrint(); // Call the callback function to trigger printing logic
        onPrintRequested();
      }
    }
  }, [printed, printData, onPrintRequested]);

  return (
    <div style={{top: 0, padding: '15px', position: "sticky", backgroundColor: "#ffffff", border: "1px solid #e2e5ec"}} id="printable-section" ref={printableRef}>
      {printData?.printType === "kotItems" ? (
        <table style={{ width: "100%" }}>
          <thead>
            <tr>
              <th colSpan={2}>
                <h3 style={{color: "#000",  textAlign: "center", textTransform: "uppercase", fontSize: "16px", marginBottom: "10px", fontWeight: 600}}>
                  {printData?.orderType?.typeName}
                </h3>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td colSpan={2} style={{color: "#000", fontSize: "14px"}}>
                KOT:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {parseInt(printData?.KOTDetails?.createdOn?.slice(8, 10), 10)}
                  {/* Date */}
                  {parseInt(printData?.KOTDetails?.createdOn?.slice(5, 7), 10)}
                  {/* Month */}
                  {printData?.KOTDetails?.createdOn?.slice(2, 4)}
                  {/* Year */}/{printData?.KOTDetails?.todayKOTNumber}
                </span>
              </td>
            </tr>

            <tr>
              <td colSpan={2} style={{color: "#000", fontSize: "14px"}}>
                Kitchen:{" "}
                <span style={{ fontWeight: "bold" }}>Hardcoded Counter</span>
                <div style={{color: "#000", margin: "5px 0", borderBottomStyle: "dashed", borderBottomWidth: "2px", borderBottomColor: "#000"}}></div>
              </td>
            </tr>

            {printData?.orderType?.isTableSection && (
              <>
                <tr>
                  <td colSpan={2} style={{color: "#000", fontSize: "14px"}}>
                    Section:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {printData?.sectionName}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td colSpan={2} style={{color: "#000", fontSize: "14px"}}>
                    Table:{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {printData?.tableName}
                    </span>
                  </td>
                </tr>
              </>
            )}
            <tr>
              <td colSpan={2} style={{color: "#000", fontSize: "14px"}}>
                Serve:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {printData?.getCustomerDetails?.id
                    ? printData?.getCustomerDetails?.fullName
                    : "Guest"}
                </span>
              </td>
            </tr>

            <tr>
              <td colSpan={2}>
                <div style={{color: "#000", margin: "5px 0", borderBottomStyle: "dashed", borderBottomWidth: "2px", borderBottomColor: "#000"}}></div>
                <h3 style={{color: "#000", fontSize: "14px", textTransform: "uppercase"}}>
                  Items
                </h3>
              </td>
            </tr>
            {printData?.orderItemLists?.map((item, index) => (
              <React.Fragment key={index}>
                {item.kotSequence === printData?.KOTDetails?.kotSequence && (
                  <>
                    {item.isCustom === false && item.isAddOn === false && (
                      <>
                        <tr>
                          <td colSpan={2} style={{color: "#000", fontSize: "14px", fontWeight: 700}}>
                            {item.quantity} X {item.catalogueName} -{" "}
                            <h4 style={{fontSize: "12px", color: "#000", display: "inline-block"}}>
                              <span> {item.varianceName}{" "} {item.variantType === "d9c5a952-911e-4a0f-8935-5e1fae01902e" ? item?.sizeId !== Environment.defaultValue
                                    ? `(${commonfields?.find((size) => size.id === item.sizeId)?.fieldValue})`
                                    : ""
                                  : ""}
                              </span>
                            </h4>
                          </td>
                        </tr>

                        {printData?.orderItemLists?.filter(
                          (custom) =>
                            (custom.isCustom === true ||
                              custom.isAddOn === true) &&
                            custom.orderSequence === item.orderSequence &&
                            custom.kotSequence ===
                              printData?.KOTDetails?.kotSequence
                        ).length > 0 && (
                          <tr>
                            <td colSpan={2} style={{ paddingLeft: "15px" }}>
                              <td style={{width: "35px", fontSize: "12px", textAlign: "left", fontWeight: 500}}>CM :</td>
                              <td style={{fontSize: "12px", fontWeight: 500}}>
                                {printData?.orderItemLists
                                  ?.filter(
                                    (custom) =>
                                      (custom.isCustom === true ||
                                        custom.isAddOn === true) &&
                                      custom.orderSequence ===
                                        item.orderSequence &&
                                      custom.kotSequence ===
                                        printData?.KOTDetails?.kotSequence
                                  )
                                  .map((custom, customiseIndex) => (
                                    <React.Fragment key={customiseIndex}>
                                      <span>{custom.addOnName} </span>
                                      {custom.isAddOn === true && (
                                        <>
                                          ( {custom.quantity} )
                                          {customiseIndex <
                                            printData?.orderItemLists.filter(
                                              (customSlash) =>
                                                (custom.isCustom === true ||
                                                  custom.isAddOn === true) &&
                                                customSlash.orderSequence ===
                                                  item.orderSequence &&
                                                customSlash.kotSequence ===
                                                  printData?.KOTDetails
                                                    ?.kotSequence
                                            ).length -
                                              1 && ", "}
                                        </>
                                      )}
                                    </React.Fragment>
                                  ))}
                              </td>
                            </td>
                          </tr>
                        )}
                        {item?.remark !== "" && (
                          <tr>
                            <td colSpan={2} style={{ paddingLeft: "16px" }}>
                              <td style={{width: "35px", fontSize: "12px", textAlign: "left", fontWeight: 500}}>RE :</td>
                              <td style={{fontSize: "12px", fontWeight: 500}}>{item.remark}</td>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </>
                )}
              </React.Fragment>
            ))}

            <tr>
              <td colSpan={2} style={{color: "#000"}}>
                <div style={{color: "#000", margin: "5px 0", borderBottomStyle: "dashed", borderBottomWidth: "2px", borderBottomColor: "#000"}}></div>
                <div style={{color: "#000", fontSize: "14px"}}>
                  Printed On: {new Date().toLocaleString()} <br /> - Reprinted
                  KOT - {printData?.KOTDetails?.kotLogPrintCount + 1}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : printData?.printType === "cancelledItems" ? (
        <table style={{ width: "100%" }}>
          <h6>cancelledItems</h6>
        </table>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ComponentToPrint;
