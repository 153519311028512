import React from 'react';
import { Button } from 'react-bootstrap';
import { ConfirmToast } from 'react-confirm-toast';

const ConfirmToaster = ({ 
    message, 
    position, 
    theme, 
    handleFunction, 
    params,
    btnVariant,
    btnClass,
    buttonIcon,
    buttonText,
    ButtonComponent
}) => {
    return (
        <ConfirmToast
            asModal={false}
            customCancel={'Cancel'}
            customConfirm={'Confirm'}
            customFunction={() => handleFunction(params)}
            message={message}
            position={position}
            showCloseIcon={true}
            theme={theme}
        >
            <ButtonComponent
                variant={btnVariant}
                className={`btn-icon ${btnClass}`}
            >
                {buttonIcon && <i className={buttonIcon}></i>}
                {buttonText && <span>{buttonText}</span>}
            </ButtonComponent>
        </ConfirmToast>
    );
}

export default ConfirmToaster;
