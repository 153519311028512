import React from 'react'
import { Col, Row } from 'react-bootstrap'

function SideShimmerLoader() {

  return (
    <Row>
      <Col>
        <p className="card-text placeholder-glow">
          <span className="placeholder col-4"></span>
          <span className="placeholder col-4"></span>
          <span className="placeholder col-4"></span>
          <span className="placeholder col-4"></span>
          <span className="placeholder col-4"></span>
          <span className="placeholder col-4"></span>
          <span className="placeholder col-4"></span>
          <span className="placeholder col-4"></span>
        </p>
      </Col>
    </Row>

  )
}

export default SideShimmerLoader