import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import * as charges from "../actions/chargesAction";
import ToastifyService from "./../../View/_common/ToastifyService";
import {
  ADD_CHARGES,
  DELETE_CHARGES,
  GET_CHARGES_LIST,
  GetChargesListSuccess,
  UPDATE_CHARGES,
  MAP_CHARGES,
  MAP_CHARGES_WITH_BRANCH,
  UNMAP_CHARGES,
  CHARGE_DETAILS,
  GetChargesDetailsSuccess,
  UNMAP_CHARGES_WITH_BRANCH,
  UNMAP_SINGLE_ORDERTYPE,
  UNMAP_ALL_ORDERTYPE,
} from "../actions/chargesAction";

const GetChargesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_CHARGES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "chargesList" }));
        const chargesList = await api.chargesAPI.GetChargesList();
        dispatch(GetChargesListSuccess(chargesList));
        dispatch(Loading.setLoading({ loading: false, value: "chargesList" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddCharges =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_CHARGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const chargesDetails = await api.chargesAPI.AddCharges(action.payload);
        dispatch(GetChargesDetailsSuccess(chargesDetails.result));
        ToastifyService.success(`New Charge Added Successfully!`);

        dispatch(Loading.loadCharges);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateCharges =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_CHARGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const chargeDetails = await api.chargesAPI.UpdateCharges(
          action.payload
        );
        dispatch(GetChargesDetailsSuccess(chargeDetails.result));
        ToastifyService.success(`Updated Charge Successfully!`);

        dispatch(Loading.loadCharges);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteCharges =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_CHARGES) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const chargesList = await api.chargesAPI.DeleteCharges(action.payload);
        dispatch(GetChargesListSuccess(chargesList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Charge Deleted Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UnmapSingleOrderType =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UNMAP_SINGLE_ORDERTYPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const chargeDetails = await api.chargesAPI.UnmapSingleOrderType(
          action.payload
        );
        dispatch(GetChargesDetailsSuccess(chargeDetails.result));
        dispatch(charges.GetChargesList);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("OrderType Or Location Unmapped Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const getChargeDetail =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CHARGE_DETAILS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const chargeDetails = await api.chargesAPI.getChargeDetail(
          action.payload
        );
        dispatch(GetChargesDetailsSuccess(chargeDetails.result));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MapChargesOrderType =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === charges.MAP_CHARGES_ORDERTYPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const chargeDetails = await api.chargesAPI.MapChargesOrderType(
          action.payload
        );
        dispatch(GetChargesDetailsSuccess(chargeDetails.result));
        ToastifyService.success(`Charges Mapped Successfully!`);

        dispatch(charges.GetChargesList);
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  GetChargesList,
  AddCharges,
  DeleteCharges,
  UpdateCharges,
  getChargeDetail,
  UnmapSingleOrderType,
  MapChargesOrderType,
];
