import {
  CUSTOMER_ADDRESS_LIST_SUCCESS,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_LIST_BY_TENANT_SUCCESS,
  CUSTOMER_LOYALTY_POINTS_LIST_SUCCESS,
  CUSTOMER_ORDER_LIST_SUCCESS,
  GET_CUSTOMER_SEARCHED_LIST_SUCCESS,
  RESET_CUSTOMER_DETAILS
} from "../actions/customerAction";

const initialState = {
  customerList: undefined,
  customerOrderList: undefined,
  customerLoyaltyPointsList: undefined,
  customerAddressList: undefined,
  customerSummary: undefined,
  customerDetail:undefined,
  searchedCustomerList:undefined,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_LIST_BY_TENANT_SUCCESS:
      return { ...state, customerList: action.payload.result, error: null };
    case GET_CUSTOMER_SEARCHED_LIST_SUCCESS:
      return { ...state, searchedCustomerList: action.payload.result, error: null };
    case CUSTOMER_DETAIL_SUCCESS:
      return { ...state, customerDetail: action.payload.result, error: null };
    case CUSTOMER_ORDER_LIST_SUCCESS:
      return {
        ...state,
        customerOrderList: action.payload.result,
        error: null,
      };
    case CUSTOMER_LOYALTY_POINTS_LIST_SUCCESS:
      return {
        ...state,
        customerLoyaltyPointsList: action.payload.result,
        error: null,
      };
    case CUSTOMER_ADDRESS_LIST_SUCCESS:
      return {
        ...state,
        customerAddressList: action.payload.result,
        error: null,
      };
      case RESET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetail: undefined,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
