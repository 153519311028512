import { CANCELLED_ORDER_SUCCESS, DASHBOARD_ANALYTICS_SUCCESS, ORDER_HISTORY, ORDER_HISTORY_SUCCESS, PENDING_ORDERS, PENDING_ORDERS_SUCCESS } from "../actions/salesOrdersAction";

const initialState = {
  DashBoardAnalytics: undefined,
  OrderHistory:undefined,
  CancelledOrders:undefined,
  PendingOrders:undefined,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_ANALYTICS_SUCCESS:
      return { ...state, DashBoardAnalytics: action.payload.result, error: null };
    case CANCELLED_ORDER_SUCCESS:
      return { ...state, CancelledOrders: action.payload.result, error: null };
    case PENDING_ORDERS_SUCCESS:
      return { ...state, PendingOrders: action.payload.result, error: null };
    case ORDER_HISTORY_SUCCESS:
      return { ...state, OrderHistory: action.payload.result, error: null };
    default:
      return state;
  }
};

export default reducer;
