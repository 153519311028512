import { Navigate } from "react-router-dom";
import {
  CREATE_MATERIAL,
  MATERIAL_LIST,
  MaterialListSuccess,
} from "../actions/materialAction";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";

const CreateMaterial =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_MATERIAL) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const MaterialList = await api.materialAPI.CreateMaterial(
          action.payload.data
        );
        dispatch(MaterialListSuccess(MaterialList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Material Created Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MaterialList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MATERIAL_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const MaterialList = await api.materialAPI.MaterialList();
        dispatch(MaterialListSuccess(MaterialList));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [CreateMaterial, MaterialList];
