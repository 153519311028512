import { Navigate } from "react-router-dom";
import * as Loading from "../actions/loaderAction";
import {
  CREATE_RECEIPE,
  DELETE_RECEIPE,
  RECEIPE_LIST_BY_CATALOGUE_ID,
  ReceipeListByCatalogueIdSuccess,
  UPDATE_RECEIPE,
} from "../actions/receipeAction";
import ToastifyService from "../../View/_common/ToastifyService";

const ReceipeListByCatalogueId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === RECEIPE_LIST_BY_CATALOGUE_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const ReceipeData = await api.receipeAPI.ReceipeListByCatalogueId(
          action.payload
        );
        dispatch(ReceipeListByCatalogueIdSuccess(ReceipeData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

const CreateReceipe =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_RECEIPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const ReceipeData = await api.receipeAPI.CreateReceipe(action.payload);
        dispatch(ReceipeListByCatalogueIdSuccess(ReceipeData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Receipe Created Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateReceipe =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_RECEIPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const ReceipeData = await api.receipeAPI.UpdateReceipe(action.payload);
        dispatch(ReceipeListByCatalogueIdSuccess(ReceipeData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteReceipe =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_RECEIPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const ReceipeData = await api.receipeAPI.DeleteReceipe(action.payload);
        dispatch(ReceipeListByCatalogueIdSuccess(ReceipeData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  ReceipeListByCatalogueId,
  CreateReceipe,
  DeleteReceipe,
  UpdateReceipe,
];
