import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Offcanvas, Button } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  AddTable,
  UpdateTable,
} from "../../../../application/actions/tableAction";
import { getSectionsList } from "../../../../application/selectors/indexSelector";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import {
  validateRequired,
  validateRequiredDropdown,
} from "../../../../infrastructure/core/validationUtils";

export default function AddNewTable(props) {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const SectionsList = useSelector(getSectionsList);
  const [selectedOptions, setSelectedOptions] = useState({
    value: props?.section?.id,
    label: props?.section?.name,
  });
  const initialState = {
    tableName: "",
    branchId: props?.branchId,
    tableCode: "",
    tableTypeId: props?.section?.id,
    createdBy: user.id,
    modifiedBy: user.id,
    FirebaseRefId: "NA",
    isVisible: true,
    isDeleted: false,
  };
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const validateName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.tableName = validateRequired(formData.tableName)
      ? ""
      : "Table Name is required";
    // Validate mainCatId (dropdown)
    newErrors.tableTypeId = validateRequiredDropdown(formData.tableTypeId)
      ? ""
      : "Please Select Section";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  useEffect(() => {
    if (props?.section) {
      setFormData({
        ...formData,
        tableTypeId: props?.section?.id,
        branchId: props?.section?.branchId,
      });
      setSelectedOptions({
        value: props?.section?.id,
        label: props?.section?.name,
      });
    }
    if (props?.table) {
      setFormData({
        ...formData,
        id: props?.table?.id,
        tableName: props?.table?.tableName,
        tableCode: props?.table?.tableCode,
      });
    }
  }, [props?.section, props?.table]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const IsValid = validateName();
    if (!IsValid) {
      return;
    }
    if (formData.id !== undefined) {
      dispatch(UpdateTable(formData));
      setFormData({ ...formData, tableName:"",tableCode:"" });
    } else {
      dispatch(AddTable(formData));
      setFormData({ ...formData, tableName:"",tableCode:"" });
    }
    props.closeFunction();
  };

  const SectionsSelect = SectionsList?.map((type, index) => ({
    value: type.id, // Assuming you want to assign unique IDs
    label: type.name,
  }));
  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    setFormData({ ...formData, tableTypeId: selectedValues.value });
    setErrors({ ...errors, tableTypeId: "" });
  };
  const handleClose = () => {
    setFormData(initialState);
    setErrors({});
    props.closeFunction();
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {props?.table?.id ? "Update Table" : "Create New Table"}
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Section Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Select
                  options={SectionsSelect}
                  isSearchable={false}
                  name="tableTypeId"
                  value={selectedOptions}
                  onChange={handleChange}
                />
                <Form.Text className="text-danger">
                  {errors.tableTypeId}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Table Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="tableName"
                  placeholder="Enter Table Name"
                  value={formData.tableName}
                  onChange={(e) => {
                    setFormData({ ...formData, tableName: e.target.value });
                    setErrors({ ...errors, tableName: "" });
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.tableName}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>QR Scan Code</Form.Label>
                <Form.Control
                  type="text"
                  name="tableCode"
                  placeholder="Enter Section Name"
                  value={formData.tableCode}
                  onChange={(e) => {
                    setFormData({ ...formData, tableCode: e.target.value });
                  }}
                />
                <h6 className="fs-14 mt-1">
                  This code will be used by customers to scan and place the
                  order directly
                </h6>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            {props?.table?.id ? (
              "Update Table"
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Create New Table</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
