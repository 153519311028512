import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Tabs, Tab } from "react-bootstrap";
import KOTSlip from "../kotSlip";
import PrintView from "../print-view";
import StoreBill from "../store-bill";

export default function CreateTemplate(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [activeTab, setActiveTab] = useState("template_details_tab");
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    const [bold, setBold] = useState(false);
    const [italic, setItalic] = useState(false);
    const [underline, setUnderline] = useState(false);
    const [fieldValue, setFieldValue] = useState("");
    const kotIDBoldStyle = {
        fontWeight: bold ? "bold" : "normal",
        fontStyle: italic ? "italic" : "normal",
        textDecoration: underline ? "underline" : "normal",
    };

    // HNADLE BOLD STYLE TOGGLE
    const handleBoldToggle = (status, field) => {
        if (status === "bold") {
            setBold(!bold);
            setFieldValue(field);
        } else if (status === "italic") {
            setItalic(!italic);
            setFieldValue(field);
        } else if (status === "underline") {
            setUnderline(!underline);
            setFieldValue(field);
        }
    };

    const [showPrintView, setShowPrintView] = useState(false);
    const handlePrintView = (state) => {
        setShowPrintView(state)
    }

    return (
        <React.Fragment>
            <PrintView show={showPrintView} closeFunction={handlePrintView} kotIDBoldStyle={kotIDBoldStyle} fieldValue={fieldValue} />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-50">
                <Offcanvas.Header className="d-block custom-offcanvas-header pe-0 ps-0" closeButton>
                    <Offcanvas.Title className="pe-3 ps-3">Create Print Template</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                        <h3 className="fs-16">Dine In</h3>

                        <div>
                            <Button
                                variant="outline-dark"
                                className="btn-icon me-2"
                                onClick={()=> handlePrintView(true)}
                            >
                                <i className="ri-eye-line"></i>
                            </Button>
                        </div>
                    </div>
                    <Tabs activeKey={activeTab} onSelect={handleTabSelect} className="sale-on-point-tabs">
                        <Tab eventKey="template_details_tab" title={<><h6 className="fs-14 mb-0">Template Details</h6></>} tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3">

                            </div>
                        </Tab>

                        <Tab eventKey="template_design_tab1" title={<><h6 className="fs-14 mb-0">Template Design</h6></>} tabClassName="custom-tab-header">
                            <div className="custom-tab-body pt-3">
                                {props.data === "KOT Slip" && (
                                    <KOTSlip handleBoldToggle={handleBoldToggle} />
                                )}

                                {
                                    props.data === "Store Bill" && <StoreBill />
                                }
                            </div>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
                <div className="offcanvas-footer bg-white">
                    <div>
                        <Button variant="primary" className="fs-14 me-3">
                            <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
                            <span className="align-middle">Create</span>
                        </Button>
                    </div>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
