import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Card, Button, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import  Select  from 'react-select';
import { format } from "date-fns";

export default function MappedItem(props) {
  const initialState = {
    Id: props?.id,
    catalogueId: 0,
  };
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    Prism.highlightAll();
  }, []);
 
  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
    setFormData({ ...formData, catalogueId: selectedValues.value });
  };
  const unMapItem =(id)=>{
    props.dissociateItem(id);
  }
  const Onsubmit = (e) => {
    e.preventDefault();
    props.associateItem(selectedOptions);
    setSelectedOptions([])
  };
  
  const columns = [
    {
      name: "Item Name",
      selector: (row) => row.catalogueName || row.itemName
      ,
    },
    {
      name: "Update On",
      selector: (row) => (
        <div>
          <h6 className="fs-11 mb-1">{format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a")}</h6>
          {/* <h5 className="fs-14 fw-semibold">{row.createdByName}</h5> */}
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <Button variant="danger" className="btn-icon" onClick={()=>unMapItem(row.mappingId)}>
          <i className="ri-delete-bin-line"></i>
        </Button>
      ),
      width: "100px",
    },
  ];
  const [isOpen, setIsOpen] = useState(false);

const toggleDropdown = () => {
  setIsOpen(true); // Toggle dropdown menu open/close state
};

const closeDropdown = () => {
  setIsOpen(false); // Close dropdown menu
};
  return (
    <React.Fragment>
      <Row className="mb-3">
        <Col xl="4">
        <div onBlur={closeDropdown}>
      <div onClick={toggleDropdown}>
        <Select
          options={props?.ItemCatalogData}
          isMulti
          name="catalogueId"
          isSearchable={true}
          value={selectedOptions}
          onChange={handleChange}
          placeholder="Select Item"
          className="react-select-container"
          menuIsOpen={isOpen}
          onMenuClose={() => {}}
        />
      </div>
    </div>
        </Col>
        <Col xl="6">
          <Button variant="primary" type="submit" onClick={Onsubmit}>
            Associate
          </Button>
        </Col>
      </Row>
      <Card className="card-one">
        <Card.Body className="custom-common-table">
          <DataTable
            columns={columns}
            data={props?.data}
            fixedHeader
            search={true}
            highlightOnHover
            pagination
          ></DataTable>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
