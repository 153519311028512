import React, { useState, useEffect } from "react";
import CheckboxTree from "react-checkbox-tree";
import "../../system-setting.scss";
import { useDispatch, useSelector } from 'react-redux';
import { getAccessList } from "../../../../application/selectors/indexSelector";
import { AssignAccessRoleId, RemoveAccessRoleId } from "../../../../application/actions/userAction";
import Environment from "../../../../infrastructure/core/Environment";

export default function UserPermissions(props) {
  const dispatch =useDispatch()
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const AccessList = useSelector(getAccessList);

    const handleCheck = (newChecked, node) => {
        setChecked(newChecked);
        if(node.checked){
          if(node.children){
          const dataObj = node.children?.map((data) => ({
          roleId: props?.id,
          moduleId: node.value,
          menuId: data.value,
        }));
        dispatch(AssignAccessRoleId(dataObj))
        }
        else{
          dispatch(AssignAccessRoleId([{
            roleId: props?.id,
            moduleId: node.parent.value,
            menuId: node.value,
          }]))
        }
      }else{       
      const dataObj = {
          roleId: props?.id,
          moduleId: node.children?node.value:node.parent.value,
          isModule: node.children?true:false,
          accessId:node.children?Environment.defaultValue:node.parent.children.find(child => child.value === node.value).permissionId
        }
        dispatch(RemoveAccessRoleId(dataObj))
    };
  }
    useEffect(() => {
        if (AccessList && AccessList?.length > 0) {
          const checkedIds = AccessList?.reduce((acc, node) => {
            if (node.children && node.children?.length > 0) {
                const childrenChecked = node.children?.filter(child => child.isChecked)?.map(child => child.value);
                acc.push(...childrenChecked);
            }
            return acc;
        }, []);
        setChecked(checkedIds);
          const allParentNodeValues = AccessList?.map((node) => node.value);
          setExpanded(allParentNodeValues);
        } 
    }, [AccessList]); // Update expanded nodes whenever AccessList changes
   
  

    return (
        <React.Fragment>
            <div className="permission-status">
                <div className="checkbox-tree-wrapper">
                    <CheckboxTree
                        nodes={AccessList}
                        checked={checked}
                        expanded={expanded}
                        onCheck={handleCheck}
                        onExpand={(newExpanded) => setExpanded(newExpanded)}
                        style={{ listStyle: "none" }}
                        icons={{
                            expandClose: <span className="ri-arrow-up-s-line text-dark" />,
                            expandOpen: <span className="ri-arrow-down-s-line text-dark" />,
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
