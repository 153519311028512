import React, { useContext, useEffect, useState } from "react";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
import { useDispatch } from "react-redux";
import { AddCoupon } from "../../application/actions/discountAction";

export default function AddNewCoupon(props) {
  const { user } = useContext(AuthContext);
  const initialState = {
    branchId: user.branchId,
    status: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    isVisible: true,
    isDeleted: false,
    title: "",
    coupenCode: "",
    shortDescription: "",
    minOrderValue: 0,
    maxDiscount: 0,
    discountRate: 0,
    usageAllowPerUser: 0,
  };
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();
  const statusCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "STS"
      )
    : [];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(AddCoupon(formData));
    setFormData(initialState)
    props.closeFunction(false)
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Add New Coupon
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={8}>
              <div className="mb-4">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  name="status"
                  value={formData.status}
                  onChange={(e) => handleChange(e)}
                >
                  <option>Choose Status</option>
                  {statusCommonList.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <div className="mb-4">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Coupon Name"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
            </Col>
          </Row>

          <Row className="mb-4 g-3">
            <Col xl={6}>
              <Form.Label>Coupen Code</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Coupen Code"
                name="coupenCode"
                value={formData.coupenCode}
                onChange={handleChange}
              />
            </Col>

            <Col xl={6}>
              <Form.Label>Short Description</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Short Description"
                name="shortDescription"
                value={formData.shortDescription}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row className="mb-4">
            <Col xl={6}>
              <Form.Label>Minimum Order Value</Form.Label>
              <Form.Control
                type="number"
                min={1}
                placeholder="Enter Minimum Order Value"
                name="minOrderValue"
                value={formData.minOrderValue}
                onChange={handleChange}
              />
            </Col>

            <Col xl={6}>
              <Form.Label>Max Discount</Form.Label>
              <Form.Control
                type="number"
                min={1}
                placeholder="Enter Max Discount"
                name="maxDiscount"
                value={formData.maxDiscount}
                onChange={handleChange}
              />
            </Col>
          </Row>

          <Row>
            <Col xl={6}>
              <Form.Label>Discount Percentage</Form.Label>
              <Form.Control
                type="number"
                min={1}
                placeholder="Enter Discount Percentage"
                name="discountRate"
                value={formData.discountRate}
                onChange={handleChange}
              />
            </Col>

            <Col xl={6}>
              <Form.Label>Usage Allow Per User</Form.Label>
              <Form.Control
                type="number"
                min={1}
                placeholder="Enter Usage Allow Per User"
                name="usageAllowPerUser"
                value={formData.usageAllowPerUser}
                onChange={handleChange}
              />
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Submit</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
