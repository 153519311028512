import React, { useContext, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLoading } from '../../../application/selectors/indexSelector';
import { getBranchList, getOrdersHistory } from "../../../application/selectors/indexSelector";
import { loadBranch } from "../../../application/actions/loaderAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import Select from "react-select";
import TableShimmerLoader from '../../../layouts/ShimmerTable';
import { GetOrderHistory } from './../../../application/actions/salesOrdersAction';
import { startOfMonth, endOfMonth, subMonths, startOfDay, subDays, format } from "date-fns";
import Environment from "../../../infrastructure/core/Environment";
import * as XLSX from 'xlsx';
import DataTable from 'react-data-table-component';

export default function OrderHistory({ setOrderHistoryCount }) {
  const dispatch = useDispatch();
  const { loading, value } = useSelector(getLoading);
  const { user } = useContext(AuthContext);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState("currentMonth");
  const branchList = useSelector(getBranchList);
  const orderHistoryList = useSelector(getOrdersHistory);
  const orderDataDownload = orderHistoryList?.map((data) => ({
    OrderId: data.orderId,
    Type: data.type,
    TableName: data.tableRef,
    BillAmount: data.billAmount,
    paymentMode: data.paympaymentModeDescent,
    paymentStatus: data.paymentStatusDesc,
    Mode: data.mode,
    LastUpdated: format(new Date(data.modifiedOn), "dd MMM yyyy hh:mm a"),
    modifiedByName: data.modifiedByName,
  }));
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem("branch"))
  );
  const initialState = {
    branchId: selectedOptions.value,
    orderStatus: Environment.defaultValue,
    paymentMethod: Environment.defaultValue,
    startDate: null,
    endDate: null,
  };
  const [formData, setFormData] = useState(initialState);

  const BranchType = branchList?.map((option) => ({
    value: option.id,
    label: option.branchName,
  }));
  useEffect(() => {
    setFormData({
      ...formData,
      startDate: startDate ? startDate.toISOString() : null,
      endDate: endDate ? endDate.toISOString() : null,
      branchId: selectedOptions.value,
    });
  }, [startDate, endDate, selectedOptions]);

  useEffect(() => {
    dispatch(loadBranch(user?.tenanatId));
    handleDateChange();
  }, [user?.tenanatId]);

  const handleStartDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    if (!endDate || selectedDate < endDate) {
      setStartDate(selectedDate);
    } else {
      // Optionally handle invalid start date selection here
      console.log("Invalid start date selection");
    }
  };

  const handleEndDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    if (!startDate || selectedDate > startDate) {
      setEndDate(selectedDate);
    } else {
      // Optionally handle invalid end date selection here
      console.log("Invalid end date selection");
    }
  };

  const handleDateChange = () => {
    // Handle date filter logic based on selectedFilter
    switch (selectedFilter) {
      case "lastMonth":
        setStartDate(startOfMonth(subMonths(new Date(), 1)));
        setEndDate(endOfMonth(subMonths(new Date(), 1)));
        dispatch(GetOrderHistory({ ...formData, branchId: selectedOptions.value ? selectedOptions.value : selectedOptions, startDate: startOfMonth(subMonths(new Date(), 1)), endDate: endOfMonth(subMonths(new Date(), 1)) }));
        break;
      case "currentMonth":
        setStartDate(startOfMonth(new Date()));
        setEndDate(endOfMonth(new Date()));
        dispatch(GetOrderHistory({ ...formData, branchId: selectedOptions.value ? selectedOptions.value : selectedOptions, startDate: startOfMonth(new Date()), endDate: endOfMonth(new Date()) }));
        break;
      case "last30Days":
        setStartDate(startOfDay(subDays(new Date(), 29)));
        setEndDate(endOfMonth(new Date()));
        dispatch(GetOrderHistory({ ...formData, branchId: selectedOptions.value ? selectedOptions.value : selectedOptions, startDate: startOfDay(subDays(new Date(), 29)), endDate: endOfMonth(new Date()) }));
        break;
      case "custom":
        dispatch(GetOrderHistory({ ...formData, branchId: selectedOptions.value ? selectedOptions.value : selectedOptions, startDate: startDate, endDate: endDate }));
        break
      default:
        break;
    }

  };
  const handleSelectBranch = (selectedOption) => {
    setSelectedOptions(selectedOption?.value);
    const Obj = { ...formData, branchId: selectedOption?.value }
    dispatch(GetOrderHistory(Obj))
  };
  const handleDownload = () => {
    downloadExcel(orderDataDownload, 'Order History'); // Specify desired file name here
  };

  const downloadExcel = (jsonData, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(jsonData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };
  const addIndexToData = (data) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1, // Adding 1 to start indexing from 1
    }));
  };
  const TableData = addIndexToData(orderHistoryList);
  const columns = [
    // {
    //   name: "ID",
    //   selector: (row) => ( row.index ),
    // },
    {
      name: "Order ID",
      selector: (row) => <div>{`O-${row.orderNumber.substring(2, 8)}/${row.orderNumber.substring(
        row.orderNumber.lastIndexOf("/") + 1
      )}`}</div>,
    },
    {
      name: "Payment Status",
      selector: (row) => <b>{row.paymentStatusDesc}</b>,
    },
    {
      name: "Table / Ref",
      selector: (row) =>
        row.tableName,
    },
    {
      name: "Bill Amount",
      selector: (row) => <b>{row.billAmount}</b>,
    }, {
      name: "Payment",
      selector: (row) =>
        // <Badge className="bg-success" pill>
        <b> {row.paymentModeDesc}</b>
      // </Badge>,
    }, {
      name: "Last Updated",
      selector: (row) => <div>
        <h6 className="mb-0 fw-semibold">
          {format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a")}
        </h6>
        <span className="fs-12 text-dark">{row.modifiedByName}</span>
      </div>,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="secondary"
            className="btn-icon me-2"
          // onClick={() => handleEditAddons(row, true)}
          >
            <i className="ri-eye-line"></i>
          </Button>
          {/* <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleAddAddons(row, true)}
          >
            <i className="ri-pencil-line"></i>
          </Button> */}
          {/* <ConfirmToaster
            message={"Are You Sure ?"}
            position={"top-right"}
            theme={"snow"}
            handleFunction={handleDelete}
            params={row.id}
            btnVariant={'danger'}
            btnclassName={'btn-icon'}
            ButtonComponent={Button}
            buttonIcon={'ri-delete-bin-line'}
          /> */}
        </div>
      ),
    },
  ];
  // Update parent component's pendingOrderCount state
  useEffect(() => {
    setOrderHistoryCount(orderHistoryList?.length);
  }, [orderHistoryList, setOrderHistoryCount]);
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <Button variant="success" className="d-flex align-items-center gap-2" onClick={handleDownload}>
            <i className="ri-file-excel-2-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Download Report</span>
          </Button>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-2 mt-3 mt-md-0 w-50 position-relative z-index-2">

          <Button variant="outline-secondary" className="btn-icon">
            <i className="ri-equalizer-line"></i>
          </Button>

          <Select
            name="branchId"
            placeholder="Choose Branch"
            options={BranchType}
            onChange={handleSelectBranch}
            value={BranchType?.find(
              (option) => option.value === selectedOptions.value
            )}
            className="p-0 wt-250"
            id="choose_branch"
          />

          <Form.Select
            className="wt-200 fs-14"
            aria-label="Default select example"
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e.target.value)}
          >
            <option value="lastMonth">Last Month</option>
            <option value="currentMonth">Current Month</option>
            <option value="last30Days">Last 30 Days</option>
            <option value="custom">Custom Dates</option>
          </Form.Select>
          {selectedFilter === "custom" && (
            <>
              <input
                type="date"
                className="form-control wt-150"
                value={startDate ? format(startDate, "yyyy-MM-dd") : ""}
                onChange={handleStartDateChange}
              />
              <input
                type="date"
                className="form-control wt-150"
                value={endDate ? format(endDate, "yyyy-MM-dd") : ""}
                onChange={handleEndDateChange}
                min={startDate ? format(startDate, "yyyy-MM-dd") : ""}
              />
            </>
          )}
          <Button variant="primary" onClick={handleDateChange}>
            Filter
          </Button>
        </div>
      </div>


      {TableData && TableData !== undefined && (
        <div className="custom-common-table">
          <DataTable
            columns={columns}
            data={TableData}
            fixedHeader
            search={true}
            highlightOnHover
            pagination
          ></DataTable>
        </div>
      )}
      {!loading && TableData === undefined && (
        <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
      )}
      {loading &&
        value === "inprogresslist" &&
        TableData === undefined && (
          <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
        )}
    </React.Fragment>
  );
}
