import Environment from "../../../core/Environment";
import jwtInterceptor from './../../../core/helpers/jwtInterceptor';

const PaymentModeListByBranchId = async (branchId) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.get(
      `${Environment.Setting_Url}pos-payment-mode-by-branch/${branchId}`,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };
  const AddPaymentMode = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.post(
      `${Environment.Setting_Url}add-pos-payment-mode`,data,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };  
  const UpdatePaymentMode = async (data) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.post(
      `${Environment.Setting_Url}update-pos-payment-mode`,data,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };
  const DeletePaymentMode = async (id) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.post(
      `${Environment.Setting_Url}delete-pos-payment-mode/${id}`,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };  
  const UpdatePaymentModeVisibility = async (id) => {
    const storedToken = JSON.parse(localStorage.getItem("Token"));
    const response = await jwtInterceptor.post(
      `${Environment.Setting_Url}update-pos-payment-mode-visibility/${id}`,
      {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      }
    );
    return response.data;
  };
  export default {PaymentModeListByBranchId,AddPaymentMode,DeletePaymentMode,UpdatePaymentMode,UpdatePaymentModeVisibility}