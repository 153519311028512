
import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const AddSection = async (data)=>{
  const storedToken = JSON.parse(localStorage.getItem("Token"));
    const URL = data.id !== undefined ?`${Environment.Setting_Url}update-table-section`:`${Environment.Setting_Url}add-table-section`  
    const response = await jwtInterceptor.post(URL, data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    });
    return response.data;
};
const AddTable = async (data)=>{
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const URL = data.id !== undefined  ?`${Environment.Setting_Url}update-dinning-table`:`${Environment.Setting_Url}add-dinning-table`  
  const response = await jwtInterceptor.post(URL, data,{
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    });;
    return response.data;
};
const UpdateTable = async (data)=>{
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const URL =`${Environment.Setting_Url}update-dinning-table`  
  const response = await jwtInterceptor.post(URL, data,{
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    });;
    return response.data;
};
const DeleteTable = async (data)=>{    
    const response = await jwtInterceptor.get(`${Environment.DashBoard_Url}/DeleteTable/${data}`, );
    return response.data;
};
const DeleteSection = async (data)=>{
    
    const response = await jwtInterceptor.get(`${Environment.DashBoard_Url}/DeleteSection/${data}`, );
    return response.data;
};
const getSectionData = async (data)=>{
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Setting_Url}table-section-by-branch/${data}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
    return response.data;
};
const getTableList = async (data)=>{
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Setting_Url}dinning-table-by-section/${data}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
    return response.data;
};
export default {
    AddSection,
    getSectionData,
    AddTable,
    UpdateTable,
    DeleteTable,
    DeleteSection,
    getTableList
}