import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [localStorageCartData, setCartData] = useState(() => {
    const storedData = localStorage.getItem("TableCartData");
    return storedData ? JSON.parse(storedData) : [];
  });
  const [deletedItems, setDeletedItems] = useState(() => {
    const deletedItemsData = localStorage.getItem("DeletedItems");
    return deletedItemsData ? JSON.parse(deletedItemsData) : [];
  });

  const clearLocalStorage = () => {
    localStorage.removeItem("TableCartData");
    setCartData([]);
  };

  const clearLocalDeletedItems = () => {
    localStorage.removeItem("DeletedItems");
    setDeletedItems([]);
  };

  useEffect(() => {
    localStorage.setItem("TableCartData", JSON.stringify(localStorageCartData));
  }, [localStorageCartData]);

  useEffect(() => {
    localStorage.setItem("DeletedItems", JSON.stringify(deletedItems));
  }, [deletedItems]);

  return (
    <CartContext.Provider
      value={{
        localStorageCartData,
        deletedItems,
        setCartData,
        setDeletedItems,
        clearLocalStorage,
        clearLocalDeletedItems
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
