import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Offcanvas, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { UpdateOrderTypeStatus } from "../../../../application/actions/orderTypeAction";

export default function UpdateOrderType(props) {
  const dispatch = useDispatch();

  const initialState = {
    id: 0,
    typeName: "",
    typeDescription: "-",
    isTableSection: false,
    status: 1,
  };

  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    Prism.highlightAll();
  }, []);
  useEffect(() => {
    if (props.data) {
      setFormData({
        id: props.data.id,
        typeName: props.data.typeName,
        isTableSection: props.data.isTableSection,
        typeDescription: props.data.typeDescription,
        status: props.data.status,
      });
    }
  }, [props]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const orderTypeData = {
      id: formData.id,
      typeName: formData.typeName,
      typeDescription: formData.typeDescription,
      isTableSection: formData.isTableSection,
      status: formData.status,
    };
    dispatch(UpdateOrderTypeStatus(orderTypeData));
    props.closeFunction(false);
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Update Order Type
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="8">
              <div className="mb-4">
                <Form.Label>
                  Order Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Order Type"
                  name="typeName"
                  onChange={handleChange}
                  value={formData.typeName}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <Form.Check
                type="switch"
                label="Has Table Section"
                className="me-3 fs-16 fw-semibold text-dark"
                name="isTableSection"
                checked={formData.isTableSection}
                onChange={handleToggle}
              />
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            <span className="align-middle">Update Order Type</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
