import React, { useState } from "react";
import { Offcanvas, Form, Button, Dropdown, Row, Col, Card, Tabs, Tab, Badge, Table } from "react-bootstrap";
import RefundInitiated from "../option/refund-initiated";
import ChangeCustomer from "../option/change-customer";
import CustomerDetails from "./customer-details";
import OrderDetails from "./order-details";
import KotLogs from "./kotLogs";


export default function PedingOrderDetails(props) {
    const [orderDetailsactiveTab, setOrderDetailsactiveTab] = useState("Order_details_tab");
    const handleTabSelect = (tab) => {
        setOrderDetailsactiveTab(tab);
    };

    const [showRefundInitiated, setShowRefundInitiated] = useState(false);
    const handleRefundInitiated = (state) => {
        setShowRefundInitiated(state);
    }

    const [showChangeCustomer, setShowChangeCustomer] = useState(false);
    const handleChangeCustomer = (state) => {
        setShowChangeCustomer(state);
    }

    return (
        <React.Fragment>
            <RefundInitiated show={showRefundInitiated} closeFunction={handleRefundInitiated} />
            <ChangeCustomer show={showChangeCustomer} closeFunction={handleChangeCustomer} />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-90">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Pending Orders</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="d-flex align-items-center justify-content-end gap-2 pt-2 pb-3 flex-wrap">
                        <Form.Select className="wt-200">
                            <option selected="" disabled="">Select Order Status</option>
                            <option value="Delivered">Closed</option>
                            <option value="Ordered">Ordered</option>
                            <option value="Confirmed">Confirmed</option>
                            <option value="Packed">Packed</option>
                            <option selected="selected" value="Cancelled">Cancelled</option>
                        </Form.Select>
                        <Button type="button" variant="danger"><i className="ri-bank-card-line"></i> Payment Status</Button>
                        <Button type="button" variant="dark" className="btn-icon wt-35 ht-35"><i className="ri-printer-line"></i></Button>
                        <Dropdown>
                            <Dropdown.Toggle variant="primary" className="btn-icon custom-dropdown-action wt-35 ht-35" id="dropdown-basic">
                                <i className="ri-more-2-fill fs-18"></i>
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleRefundInitiated(true)}>
                                    <i className="ri-file-text-fill"></i> Initiate Refund
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleChangeCustomer(true)}>
                                    <i className="ri-file-text-fill"></i> Change Customer
                                </Dropdown.Item>

                            </Dropdown.Menu>
                        </Dropdown>
                        <Button type="button" variant="success">Save</Button>
                    </div>

                    <Row>
                        <Col sm={12} xl={3}>
                            <Card className="card-one">
                                <Card.Body>
                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label className="mb-1 fs-12">Sales Order ID</Form.Label>
                                            <h6 className="fs-14 text-dark fw-semibold">SO068211 <span className="fs-12 text-black-50">(Web)</span></h6>
                                        </Col>
                                        <Col>
                                            <Form.Label className="mb-1 fs-12">Ordered Date</Form.Label>
                                            <h6 className="fs-14 text-dark fw-semibold">28-Apr-2024 11:43</h6>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label className="mb-1 fs-12">Sales Order Status</Form.Label>
                                            <h5>
                                                <Badge className="bg-danger rounded-pill">Cancelled</Badge>
                                            </h5>
                                        </Col>
                                        <Col>
                                            <Form.Label className="mb-1 fs-12">Payment Status</Form.Label>
                                            <h5>
                                                <Badge className="bg-danger rounded-pill">Pending</Badge>
                                            </h5>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label className="mb-1 fs-12">Payment Mode</Form.Label>
                                            <h5 className="fs-14 text-dark fw-semibold">COD</h5>
                                        </Col>
                                    </Row>

                                    <Row className="mb-3">
                                        <Col>
                                            <Form.Label className="mb-1 fs-12">Delivery Date</Form.Label>
                                            <h5 className="fs-14 text-dark fw-semibold">29-Apr-2024</h5>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col>
                                            <Form.Label className="mb-1 fs-12">Table</Form.Label>
                                            <h5 className="fs-14 text-dark fw-semibold">D-1</h5>
                                        </Col>
                                    </Row>

                                    <hr />
                                    <Table className="mb-0" responsive>
                                        <thead>
                                            <tr>
                                                <th>Bill Summary</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Order Value</td>
                                                <td className="text-right">
                                                    <span className="fw-bold">₹ 0.00</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Delivery Charge</td>
                                                <td className="text-right">
                                                    ₹ <span className="fw-bold">+ ₹ 0</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Discount Applied</td>
                                                <td className="text-right">
                                                    <span className="fw-bold">- ₹ 0</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Total Amount</td>
                                                <td className="text-right">
                                                    <span className="fw-bold">₹ 0.00</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>Wallet Cash Applied</td>
                                                <td className="text-right">
                                                    <span className="fw-bold">- ₹ 0</span>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>GST</td>
                                                <td className="text-right">
                                                    <span className="fw-bold">+ ₹ 0</span>
                                                </td>
                                            </tr>

                                            <tr className="bg-danger">
                                                <td className="fw-semibold text-white">Payable Amount</td>
                                                <td className="text-right text-white">
                                                    <span className="fw-bold">0.00</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>

                                </Card.Body>
                            </Card>
                        </Col>

                        {/* PENDING ORDER DETAILS */}
                        <Col sm={12} xl={9}>
                            <Card className="card-one">
                                <Card.Body className="p-0">
                                    <Tabs activeKey={orderDetailsactiveTab} onSelect={handleTabSelect} className="sale-on-point-tabs main-category-tabs">
                                        <Tab eventKey="Order_details_tab"
                                            title={<>
                                                <i className="ri-shopping-cart-2-line"></i>
                                                <h6 className="fs-14 mb-0 ms-1">Order Details</h6>
                                            </>
                                            }
                                            className="custom-tab-header"
                                        >
                                            <div className="custom-tab-body p-3">
                                                <OrderDetails />
                                            </div>
                                        </Tab>
                                        <Tab eventKey="customer_details_tab"
                                            title={
                                                <>
                                                    <i className="ri-group-fill"></i>
                                                    <h6 className="fs-14 mb-0 ms-1">Customer Details</h6>
                                                </>
                                            }
                                            className="custom-tab-header"
                                        >
                                            <div className="custom-tab-body p-3 text-dark">
                                                <CustomerDetails />
                                            </div>
                                        </Tab>

                                        <Tab eventKey="logs_tab"
                                            title={
                                                <>
                                                    <i className="ri-file-line"></i>
                                                    <h6 className="fs-14 mb-0 ms-1">Logs</h6>
                                                </>
                                            }
                                            className="custom-tab-header"
                                        >
                                              <div className="custom-tab-body p-3">
                                                <KotLogs />
                                            </div>

                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}