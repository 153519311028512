import React, { useEffect, useState, useContext } from "react";
import Prism from "prismjs";
import Header from "../../../layouts/Header";
import { Link } from "react-router-dom";
import { Button, Card } from "react-bootstrap";
import AddTaxGroup from "./add-tax";
import EditTaxGroup from "./edit-tax";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import { useDispatch, useSelector } from "react-redux";
import { getLoading,  getTaxDetails,  getTaxList } from "../../../application/selectors/indexSelector";
import { loadBranch, loadTax } from "../../../application/actions/loaderAction";
import { UpdateTax, getTaxDetail } from "../../../application/actions/taxAction";
import { LoadOrderTypeByBranchId, OrderType } from "../../../application/actions/orderTypeAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { format } from "date-fns";
import ManageTax from "./manage-tax";
import {debounce} from "lodash";
import Environment from "../../../infrastructure/core/Environment";

export default function TaxGroup(props) {
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const dispatch = useDispatch();
  const {loading,value} = useSelector(getLoading);
  const taxList = useSelector(getTaxList);
  const {user}=useContext(AuthContext)
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };


  useEffect(() => {
    Prism.highlightAll();
    dispatch(loadBranch(user?.tenanatId));
    dispatch(OrderType);
    dispatch(loadTax);
  }, []);
  switchSkin(skin);

  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  
  const handelDelete = (data) => {
    const locationData= data?.locations?.map((option)=> option.locationId)
    const Data= {
      id: data.id,
      tenantId: user.tenantId,
      branchId: user.branchId,
      locations:locationData,
      status: data.status,
      isVisible: false,
      isDeleted: true,
      taxGroupName: data.taxGroupName,
      taxGroupDescription: data.taxGroupDescription,
      // taxTypeId: data.taxCodeId,
      taxTypeId: data.taxTypeId,
      taxMethodId: data.taxMethodId,
      appliedOn: data.appliedOn,
      taxRate: data.taxRate,
      orderTypes: [],
    }
    dispatch(UpdateTax(Data));
  };

  const branch= JSON.parse(localStorage.getItem("branch"))
  useEffect(() => {
    dispatch(OrderType);
  }, []);
  const [showAddGroup, setShowAddGroup] = useState(false);
  const handleAddGroup = (state) => {
    setShowAddGroup(state);
  };

  const [showManageTax, setShowManageTax] = useState(false);
  const [setEditGroup, setDataEditGroup] = useState(false);
  const handleManageTax = (row, state) => {
    setDataEditGroup(row);
    // setShowManageTax(state);
    setShowUpdateTax(state)
    dispatch(getTaxDetail(row.id))
  };
  const addIndexToData = (data) => {
    return data?.map((row, index) => ({
      ...row,
      index: index + 1, // Adding 1 to start indexing from 1
    }));
  };
  const taxMethodCommonList = JSON.parse(localStorage.getItem("CommonFields"))
  ? JSON.parse(localStorage.getItem("CommonFields")).filter(
      (fields) => fields.fieldTypeCode === "TAXME"
    )
  : [];
  const TableData = addIndexToData(taxList);
  const columns = [
    { name: 'Id',selector: (row) =>   row.index, 
  
     width: "50px",},

    {
      name: "Tax Title",
      selector: (row) => <Link className="fs-14 fw-semibold mb-1" onClick={() => handleManageTax(row, true)}>{row.taxGroupName}</Link>,
    },

    {
      name: "Precentage",
      selector: (row) => row.taxRate,
    },
    {
      name: "Mode",
      selector: (row) =>  <span>{taxMethodCommonList.find(item => item.id === row.taxMethodId).fieldValue}</span>
    },
    {
      name: "Created On",
      selector: (row) =><span> {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")}</span>,
    },

    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleManageTax(row, true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => handelDelete(row, true)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  const [showUpdateTax, setShowUpdateTax] = useState(false);
  const TaxDetails = useSelector(getTaxDetails);
  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);
  return (
    <React.Fragment>
    <Header passDataToPointOfSale={handleBranchIdFromHeader}/>

      <div className="main main-app p-3 p-lg-4">
        <AddTaxGroup show={showAddGroup} closeFunction={handleAddGroup} />
        <EditTaxGroup show={showUpdateTax} data={TaxDetails} closeFunction={()=> setShowUpdateTax(false)} />
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item">
                <Link to="#">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Manage
              </li>
            </ol>
            <h4 className="main-title mb-0">Tax Group</h4>
          </div>
          <div className="d-flex gap-2 mt-3 mt-md-0">
            <Button
              variant="primary"
              className="d-flex align-items-center gap-2"
              onClick={() => handleAddGroup(true)}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline"> Create Tax Group</span>
            </Button>
          </div>
        </div>

        <Card className="card-one">
          <Card.Body className="p-3 p-xl-4">
            <div className="d-md-flex align-items-center justify-content-between mb-3">
              <div>
                <h4 className="main-title fs-14 mb-0">List of Tax Group</h4>
              </div>
              <div>
                <div className="form-search me-auto">
                  <i className="ri-search-line"></i>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search for tax group"
                  />
                </div>
              </div>
            </div>
              <div className="custom-common-table">
               {taxList && taxList !== undefined &&(
                 <DataTable
                 columns={columns}
                 data={TableData}
                 fixedHeader
                 search={true}
                 highlightOnHover
                 pagination
               ></DataTable>
               )}
               </div>
            {!loading && taxList === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
            {loading && value==='taxList' && taxList === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
          </Card.Body>
        </Card>
      </div>
    </React.Fragment>
  );
}
