import React, { useEffect, useState, useRef, useContext } from "react";
import Prism from "prismjs";
import "./menu.scss";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CreateSUBMain } from "../../../application/actions/menuAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import environment from "../../../infrastructure/core/Environment";
import { getCategoryList } from "../../../application/selectors/indexSelector";
import { restrictAllNumbers, restrictAllSpecialCharacters, restrictConsecutiveSpace, restrictOnlyNumber, validateRequired, validateRequiredDropdown } from "../../../infrastructure/core/validationUtils";

export default function AddNewSubMenu(props) {
  const { user } = useContext(AuthContext);
  const Status = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "STS"
  );
  const StatusData=[
    {id:'a5ca48b8-bfd1-42d6-8db1-7021c131e400', fieldValue:'Yes'},
    {id:'a5ca48b8-bfd1-42d6-8db1-7021c131e401', fieldValue:'No'}
  ]
  const CategoryList = useSelector(getCategoryList);

  useEffect(() => {
    Prism.highlightAll();
    if (CategoryList) {
      setFormData({ ...formData, sequence: CategoryList?.length + 1 });
    }
  }, [CategoryList]);
  const dispatch = useDispatch();
  const initialState = {
    name: "",
    description: "",
    isVisible: true,
    isDeleted: false,
    sequence: "",
    mainCategoryId: "",
    createdBy: user.id,
    modifiedBy: user.id,
    status: `${environment.defaultValue}`,
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (props.data) {
      setFormData({ ...formData, mainCategoryId: props.data });
    }
  }, [props.data]);

  const [errors, setErrors] = useState({});
  const validateName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.name = validateRequired(formData.name)
      ? ""
      : "Sub Menu is required";
    // Validate mainCatId (dropdown)
    newErrors.status = validateRequiredDropdown(formData.status)
      ? ""
      : "Please Select Visibility";

      setErrors(newErrors);
      // Check if any field has errors
      return !Object.values(newErrors).some((error) => error);
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      name: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      name: "" ,
    }));
  };

  const OnSubmit = (e) => {
    e.preventDefault();

    // Validate name before submitting
    const isValid = validateName();
    if (!isValid) {
      return;
    }
    const formObject = {
      name: formData.name,
      description: formData.description,
      status: formData.status,
      isVisible: true,
      isDeleted: false,
      sequence: formData.sequence,
      mainCategoryId: formData.mainCategoryId,
      createdBy: user.id,
      modifiedBy: user.id,
    };

    dispatch(CreateSUBMain(formObject));
    setFormData(initialState);
    props.closeFunction();
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add Sub Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  {" "}
                  Sub Menu Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Item Name"
                  value={formData.name}
                  onChange={handleNameChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}                />
                <Form.Text className="text-danger">{errors.name}</Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>

                <Form.Control
                  type="text"
                  placeholder="Enter Short Description"
                  value={formData.description}
                  onChange={(e) => {
                    setFormData({ ...formData, description: e.target.value });
                  }}
                  onKeyDown={restrictConsecutiveSpace}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  {" "}
                  Visible For Online Orders:{" "}
                  <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={formData.status}
                  onChange={(e) => {
                    setFormData({ ...formData, status: e.target.value });
                  }}
                >
                  <option value={`${environment.defaultValue}`} disabled>
                    Is It Visible For Online Orders?
                  </option>
                  {StatusData?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.status}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <div className="mb-4">
                <Form.Label>Sub Menu Sequence</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Sequence"
                  value={formData.sequence}
                  onChange={(e) => {
                    setFormData({ ...formData, sequence: e.target.value });
                  }}
                  onKeyDown={restrictOnlyNumber}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer" onClick={OnSubmit}>
          <Button variant="primary" className="fs-14">
            {" "}
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create Sub Menu</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
