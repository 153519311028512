import { INVOICE_LIST_SUCCESS } from "../actions/recieptAction";

const initialState = {
    InvoiceList: undefined,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case INVOICE_LIST_SUCCESS:
      return {
        ...state,
        InvoiceList: action.payload.result,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
