import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card } from "react-bootstrap";
import AddNewSection from "./add-section";
import AddNewTable from "./add-table";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../../../layouts/ShimmerTable";
import EditSection from "./edit-section";
import { useDispatch, useSelector } from "react-redux";
import { getSectionsList } from "../../../../application/selectors/indexSelector";
import { AddSection, TableSuccess } from "../../../../application/actions/tableAction";
import SweetAlertService from "../../../_common/SweetAlertService";
import ToastifyService from "../../../_common/ToastifyService";

export default function SectionTable(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const [showAddSection, setShowAddSection] = useState(false);
  const handleAddNewSection = (state) => {
    setShowAddSection(state);
  };

  const [showAddTable, setShowAddTable] = useState(false);
  const [section, setSection] = useState({});
  const handleAddNewTable = (data, state) => {
    setSection(data);
    setShowAddTable(state);
  };

  const [showEditSection, setShowEditSection] = useState(false);
  const handleEditSection = (data, state) => {
    setSection(data);
    setShowEditSection(state);
    if (data) { dispatch(TableSuccess(data?.dineInTables)) }
  };
  const handleDeleteSection = (data) => {
    const SecData = {
      name: data.name,
      branchId: data.branchId,
      sectionDesc: data.sectionDesc,
      isDeleted: true,
      id: data.id ? data.id : "00000000-0000-0000-0000-000000000000",
    };

    SweetAlertService.showAlert(
      'Delete Table',
      'Are you sure you want to delete this Table?',
      'warning'
    ).then((result) => {
      if (result.isConfirmed) {
        SweetAlertService.showSuccess('Success', 'Table!');
        dispatch(AddSection(SecData));
        ToastifyService.success(`Deleted Table Successfully`);
      } else {
        console.log('Delete operation cancelled!');
      }
    }).catch((error) => {
      console.error('Error:', error);
      ToastifyService.error(`something went wrong`);
    });

  };
  const columns = [
    {
      name: "Section Name",
      selector: (row) => (
        <div>
          {" "}
          <h6 className="mb-0 fw-semibold">{row.name}</h6>
          <span className="text-dark fw-semibold">{row.sectionDesc}</span>
        </div>
      ),
    },
    {
      name: "Table Count",
      selector: (row) => row?.dineInTables?.length
      ,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-between w-100">
          <Button
            variant="dark"
            className="me-2 fs-12"
            onClick={() => handleAddNewTable(row, true)}
          >
            Add Table
          </Button>
          <Button
            variant="primary"
            className="btn-icon me-2"
            onClick={() => handleEditSection(row, true)}
          >
            <i className="ri-pencil-line"></i>
          </Button>
          <Button
            variant="danger"
            className="btn-icon"
            onClick={() => handleDeleteSection(row)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  const SectionsList = useSelector(getSectionsList);
  return (
    <React.Fragment>
      <AddNewSection
        show={showAddSection}
        branchId={props.branchId}
        closeSection={handleAddNewSection}
      />
      <EditSection
        show={showEditSection}
        closeSection={handleEditSection}
        section={section}
        loading={props.loading}
      />
      <AddNewTable
        show={showAddTable}
        section={section}
        branchId={props.branchId}
        closeFunction={handleAddNewTable}
      />

      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="main-title fs-16 mb-0">Section & Table</h4>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="danger"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={() => handleAddNewSection(true)}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Add New Section</span>
          </Button>
        </div>
      </div>

      <Card className="card-one">
        <Card.Body className="custom-common-table">
          {!props?.loading && (
            <DataTable
              columns={columns}
              data={SectionsList}
              fixedHeader
              search={true}
              highlightOnHover
              pagination
            ></DataTable>
          )}
          {props?.loading && <TableShimmerLoader columnCount={4} rowCount={4} />}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
