import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs"; // Prism for syntax highlighting
import { useDispatch } from "react-redux"; // Importing useDispatch hook from react-redux

import { Button, Row, Col, Form } from "react-bootstrap"; // Importing Bootstrap components

import AuthContext from "../../../infrastructure/core/helpers/AuthContext"; // Importing AuthContext
import { UpdateCharges } from "../../../application/actions/chargesAction"; // Importing UpdateCharges action
import { GetBranch } from "../../../application/actions/branchAction"; // Importing GetBranch action
import { OrderType } from "../../../application/actions/orderTypeAction"; // Importing OrderType action
import { restrictAllNumbers, restrictAllSpecialCharacters, restrictConsecutiveSpace } from "../../../infrastructure/core/validationUtils";

export default function Updatecharges(props) {
  const dispatch = useDispatch(); // Initializing useDispatch hook
  const { user } = useContext(AuthContext); // Using useContext hook to access user data

  // Initializing form state with initial values
  const initialState = {
    tenantId: "",
    createdBy: "",
    modifiedBy: user.id,
    chargeName: "",
    chargeDescription: "",
    isAllBranches: false,
    appliedOn: "",
    chargeType: "",
    fixedRate: "",
    locations: [],
    orderTypeId: [],
    isAutoPay: "",
  };
  const [formData, setFormData] = useState(initialState); // Using useState hook for form data
  const [chargeTypeVisi, setChargeTypeVisi] = useState(); // Using useState hook for charge type visibility

  // Retrieving common fields from local storage and filtering based on fieldTypeCode
  const appliedOnCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "APLDON"
      )
    : [];
  const chargeTypeCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "CHRGTY"
      )
    : [];

  // Fetching branches and order types on component mount
  useEffect(() => {
    dispatch(GetBranch(user?.tenanatId));
    dispatch(OrderType);
    Prism.highlightAll(); // Highlighting code syntax using Prism
  }, []);

  // Populating form data if props.data changes
  useEffect(() => {
    if (props.data) {
      setFormData({
        id: props.data.id,
        chargeName: props.data.chargeName,
        chargeDescription: props.data.chargeDescription,
        chargeType: props.data.chargeType,
        fixedRate: props.data.fixedRate,
        isAutoPay: props.data.isAutoPay,
        appliedOn: props.data.appliedOn,
        isAllBranches: false,
        isDefault: true,
      });
      const selectedOrderTypeValues = props?.data?.orderTypes?.map(
        (option) => option.id
      );
      setFormData((prevState) => ({
        ...prevState,
        orderTypeId: selectedOrderTypeValues,
      }));
      const selectedBranchValues = props?.data?.branches?.map(
        (option) => option.branchId
      );
      setFormData((prevState) => ({
        ...prevState,
        locations: selectedBranchValues,
      }));
    }
  }, [props.data]);

  // Handling form input changes
  const handleChange = (e) => {
    const { name, value, type, options } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "chargeType") {
      // Setting chargeTypeVisi based on selected charge type
      if (e.target.selectedOptions[0].innerText === "Floating %") {
        setChargeTypeVisi(true);
      } else {
        setChargeTypeVisi(false);
      }
    } else if (name === "isAutoPay") {
      // Converting string value to boolean for isAutoPay
      if (e.target.value === "true") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: true,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: false,
        }));
      }
    }
  };

  // Handling form submission
  const Onsubmit = (e) => {
    e.preventDefault();
    dispatch(UpdateCharges(formData)); // Dispatching UpdateCharges action with form data
    setFormData(initialState); // Resetting form data
    // props.closeFunction(); // Closing modal or performing any other action after submission
  };
  return (
    <React.Fragment>
      <Form  data-parsley-validate>
      <Row>
        <Col xl="8">
          <div className="mb-4">
            <Form.Label>
              Charge Name: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Charge Name"
              name="chargeName"
              value={formData.chargeName}
              onChange={handleChange}
              onKeyDown={(e) => {
                restrictConsecutiveSpace(e);
                restrictAllSpecialCharacters(e);
                restrictAllNumbers(e);
              }}              required
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="12">
          <div className="mb-4">
            <Form.Label>Description</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Description"
              name="chargeDescription"
              value={formData.chargeDescription}
              onChange={handleChange}
              onKeyDown={restrictConsecutiveSpace}
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Applied On <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="appliedOn"
              value={formData.appliedOn}
              onChange={handleChange}
            >
              {appliedOnCommonList.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.fieldValue}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Charge Type: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="chargeType"
              value={formData.chargeType}
              onChange={handleChange}
            >
              {chargeTypeCommonList?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.fieldValue}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
        {!chargeTypeVisi ? (
          <>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Enter Fixed Charge: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Group
                  controlId="formFile"
                  className="position-relative overflow-hidden"
                >
                  <Form.Label className="custom-label mb-0">$</Form.Label>
                  <Form.Control
                    className="tax-percentage-input"
                    style={{ paddingLeft: "50px" }}
                    type="number" min="1"
                    placeholder="Enter Tax Percentage"
                    name="fixedRate"
                    value={formData.fixedRate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        fixedRate: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Enter Float Percentage: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Group
                  controlId="formFile"
                  className="position-relative overflow-hidden"
                >
                  <Form.Label className="custom-label mb-0">%</Form.Label>
                  <Form.Control
                    className="tax-percentage-input"
                    style={{ paddingLeft: "50px" }}
                    type="number" min="1"
                    placeholder="Enter Tax Percentage"
                    name="fixedRate"
                    value={formData.fixedRate}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        fixedRate: e.target.value,
                      })
                    }
                  />
                </Form.Group>
              </div>
            </Col>
          </>
        )}
      </Row>

      <Row>
        <Col xl="6" className="pb-5">
          <div className="mb-5">
            <Form.Label>
              Auto Apply: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="isAutoPay"
              value={formData.isAutoPay}
              onChange={handleChange}
            >
              <option value="" disabled>
                Choose Auto Apply Status
              </option>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </Form.Select>
          </div>
        </Col>
      </Row>

      <div className="offcanvas-footer justify-content-start position-absolute bg-white">
        <Button
          variant="primary"
          className="fs-14 d-flex align-items-center"
          type="submit"
          onClick={Onsubmit}
        >
          <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
          <span className="align-middle">Update</span>
        </Button>
      </div>
      </Form>
    </React.Fragment>
  );
}
