import { Navigate } from "react-router-dom";
import {
  CREATE_KOT_REMARK,
  DELETE_KOT_REMARK,
  KOTREMARK_LIST,
  KOTRemarkListSuccess,
  UPDATE_KOT_REMARK,
} from "../actions/KOTAction";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";

const KOTRemarkList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === KOTREMARK_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const KOTRemarkData = await api.kotRemarkAPI.KOTRemarkList();
        dispatch(KOTRemarkListSuccess(KOTRemarkData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

const CreateKOTRemark =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_KOT_REMARK) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const KOTRemarkData = await api.kotRemarkAPI.AddKOTRemark(
          action.payload
        );
        dispatch(KOTRemarkListSuccess(KOTRemarkData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Kot Remark Added Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateKOTRemark =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_KOT_REMARK) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const KOTRemarkData = await api.kotRemarkAPI.UpdateKOTRemark(
          action.payload
        );
        dispatch(KOTRemarkListSuccess(KOTRemarkData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Kot Remark Details Updated Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteKOTRemarks =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_KOT_REMARK) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const KOTRemarkData = await api.kotRemarkAPI.DeleteKOTRemarks(
          action.payload
        );
        dispatch(KOTRemarkListSuccess(KOTRemarkData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        ToastifyService.success("Kot Remark Deleted Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  CreateKOTRemark,
  KOTRemarkList,
  UpdateKOTRemark,
  DeleteKOTRemarks,
];
