
export const DASHBOARD_ANALYTICS = 'DASHBOARD_ANALYTICS';
export const DASHBOARD_ANALYTICS_SUCCESS = 'DASHBOARD_ANALYTICS_SUCCESS';
export const PENDING_ORDERS_SUCCESS = 'PENDING_ORDERS_SUCCESS';
export const PENDING_ORDERS = 'PENDING_ORDERS';
export const ORDER_HISTORY = 'ORDER_HISTORY';
export const ORDER_HISTORY_SUCCESS = 'ORDER_HISTORY_SUCCESS';
export const CANCELLED_ORDER = 'CANCELLED_ORDER';
export const CANCELLED_ORDER_SUCCESS = 'CANCELLED_ORDER_SUCCESS';

export const DashBoardAnalytics = (id)=>({
    type: DASHBOARD_ANALYTICS,
    payload:id
});
export const DashBoardAnalyticsSuccess = (data)=>({
    type: DASHBOARD_ANALYTICS_SUCCESS,
    payload:data
})
export const PendingOrders = (data)=>({
    type: PENDING_ORDERS,
    payload:data
})
export const PendingOrdersSuccess = (data)=>({
    type: PENDING_ORDERS_SUCCESS,
    payload:data
})
export const GetOrderHistory = (data)=>({
    type: ORDER_HISTORY,
    payload:data
})
export const OrderHistorySuccess = (data)=>({
    type: ORDER_HISTORY_SUCCESS,
    payload:data
})
export const CancelledOrders = (data)=>({
    type: CANCELLED_ORDER,
    payload:data
});
export const CancelledOrdersSuccess = (data)=>({
    type: CANCELLED_ORDER_SUCCESS,
    payload:data
})