import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";

export default function EBills() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const selectEBill = [
        { value: 'Email', label: 'Email' },
        { value: 'SMS', label: 'SMS' }
    ];


    return (
        <React.Fragment>
            <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
                <div>
                    <h4 className="fs-16 main-title mb-1">E-Bill</h4>
                    <h5 className="fs-14 text-secondary">Send a digital copy of bill to your walk-in customers.</h5>
                </div>
            </div>


            <div>
                <Row>
                    <Col xl={6}>
                        <div className="common-switch-toggle mb-4">
                            <Form.Check type="switch" label="Auto send E-Bill" className="fs-16 fw-semibold text-dark" name="autoBill" />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div className="mb-4">
                            <Form.Label>Select the channels where E-Bill would be sent</Form.Label>
                            <Select options={selectEBill} isSearchable={false} isMulti />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xl={6}>
                        <div className="mb-4">
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}