export const USERROLE_LIST = "USERROLE_LIST";
export const USERROLE_LIST_SUCCESS = "USERROLE_LIST_SUCCESS";
export const CREATE_USERROLE = "CREATE_USERROLE";
export const USERROLE_DETAIL = "USERROLE_DETAIL";
export const USERROLE_DETAIL_SUCCESS = "USERROLE_DETAIL_SUCCESS";
export const UPDATE_USERROLE = "UPDATE_USERROLE";
export const DELETE_USERROLE = "DELETE_USERROLE";
export const ACCESS_LIST_BY_USERROLEID = "ACCESS_LIST_BY_USERROLEID";
export const ACCESS_LIST_BY_USERROLEID_SUCCESS = "ACCESS_LIST_BY_USERROLEID_SUCCESS";
export const ASSIGN_ACCESS_BY_ROLEID = "ASSIGN_ACCESS_BY_ROLEID";
export const REMOVE_ACCESS_BY_ROLEID = "REMOVE_ACCESS_BY_ROLEID";
export const ASSIGN_ROLE_TO_EMPLOYEE = "ASSIGN_ROLE_TO_EMPLOYEE";
export const EMPLOYEE_LIST_BY_ROLEID = "EMPLOYEE_LIST_BY_ROLEID";
export const EMPLOYEE_LIST_BY_ROLEID_SUCCESS = "EMPLOYEE_LIST_BY_ROLEID_SUCCESS";

  export const UserRoleList={
    type: USERROLE_LIST
  }
  export const  UserRoleListSuccess=(data) =>({
    type: USERROLE_LIST_SUCCESS,
    payload:data
  });  
  export const CreateUserRole=(data) =>({
    type: CREATE_USERROLE,
    payload:data
  });  
  export const  UserRoleDetail=(id) =>({
    type: USERROLE_DETAIL,
    payload:id
  });
  export const  UserRoleDetailSuccess=(data) =>({
    type: USERROLE_DETAIL_SUCCESS,
    payload:data
  });
  export const UpdateUserRole=(data) =>({
    type: UPDATE_USERROLE,
    payload:data
  });
  export const DeleteUserRole=(id) =>({
    type: DELETE_USERROLE,
    payload:id
  }); 
  export const AccessByUserRoleId=(id) =>({
    type: ACCESS_LIST_BY_USERROLEID,
    payload:id
  }); 
  export const AssignAccessRoleId=(id) =>({
    type: ASSIGN_ACCESS_BY_ROLEID,
    payload:id
  }); 
  export const RemoveAccessRoleId=(id) =>({
    type: REMOVE_ACCESS_BY_ROLEID,
    payload:id
  }); 
   export const AccessByUserRoleIdSuccess=(id) =>({
    type: ACCESS_LIST_BY_USERROLEID_SUCCESS,
    payload:id
  }); 
  export const AssignRoletoEmployees=(data) =>({
    type: ASSIGN_ROLE_TO_EMPLOYEE,
    payload:data
  }); 
  export const EmployeeListByRoleId=(id) =>({
    type: EMPLOYEE_LIST_BY_ROLEID,
    payload:id
  });  
  export const EmployeeListByRoleIdSuccess=(id) =>({
    type: EMPLOYEE_LIST_BY_ROLEID_SUCCESS,
    payload:id
  }); 
