export const ADDONS_GROUPS_LIST = 'ADDONS_GROUPS_LIST'
export const CREATE_ADDONS_GROUPS = 'CREATE_ADDONS_GROUPS'
export const ADDONS_GROUPS_LIST_SUCCESS = 'ADDONS_GROUPS_LIST_SUCCESS'
export const CREATE_ADDONS_GROUPS_SUCCESS = 'CREATE_ADDONS_GROUPS_SUCCESS'
export const GET_ADDONS_GROUPS_BY_ID = 'GET_ADDONS_GROUPS_BY_ID'
export const GET_ADDONS_GROUPS_SUCCESS = 'GET_ADDONS_GROUPS_SUCCESS'
export const CREATE_ADDONS_ITEM = 'CREATE_ADDONS_ITEM'
export const COMMON_FIELD = 'COMMON_FIELD'
export const FOOD_TYPE = 'FOOD_TYPE'
export const ADDONS_ITEM_List = 'ADDONS_ITEM_List'
export const ADDONS_ITEM_BY_ID = 'ADDONS_ITEM_BY_ID'
export const ADDONS_ITEM_LIST_SUCCESS = 'ADDONS_ITEM_LIST_SUCCESS'
export const QUANTITY_TYPE = 'QUANTITY_TYPE'
export const CATALOGUE_ITEM_BY_ADDONS_ID = 'CATALOGUE_ITEM_BY_ADDONS_ID'
export const CATALOGUE_ITEM_BY_ADDONS_ID_SUCCESS = 'CATALOGUE_ITEM_BY_ADDONS_ID_SUCCESS'
export const UPDATE_ADDONS_GROUPS = 'UPDATE_ADDONS_GROUPS'
export const DELETE_ADDONS_GROUPS = 'DELETE_ADDONS_GROUPS'
export const DELETE_ADDONS_ITEMS = 'DELETE_ADDONS_ITEMS'
export const ADDONS_GROUPS_FAILURE = 'ADDONS_GROUPS_FAILURE'

export const GetAddOnsGroupList ={
   type: ADDONS_GROUPS_LIST,
}
export const GetAddOnsGroupListSuccess =(data)=> ({
   type: ADDONS_GROUPS_LIST_SUCCESS,
   payload :{data}
});
export const CreateAddOnsGroup = (data)=>({
   type: CREATE_ADDONS_GROUPS,
   payload:{data}
});
export const AddOnsGroupFailure = (data)=>({
   type: ADDONS_GROUPS_FAILURE,
   payload:data
});
export const UpdateAddOnsGroup = (data)=>({
   type: UPDATE_ADDONS_GROUPS,
   payload:{data}
});
export const DeleteAddOnsGroup = (ID)=>({
   type: DELETE_ADDONS_GROUPS,
   payload:ID
});
export const DeleteAddOnsItem = (id)=>({
   type: DELETE_ADDONS_ITEMS,
   payload:id
});
export const GetAddOnsGroupbyId = (data)=>({
   type: GET_ADDONS_GROUPS_BY_ID,
   payload:{data}
});
export const GetAddOnsGroupSuccess = (data)=>({
   type: GET_ADDONS_GROUPS_SUCCESS,
   payload:{data}
});
export const CreateAddOnsItem = (data)=>({
   type: CREATE_ADDONS_ITEM,
   payload:{data}
});
export const GetAddOnsItemListSuccess = (data)=>({
   type: ADDONS_ITEM_LIST_SUCCESS,
   payload:{data}
});
export const GetAddOnsItemByGroupId = (data)=>({
   type: ADDONS_ITEM_List,
   payload:{data}
});
export const GetAddOnsItemById = (data)=>({
   type: ADDONS_ITEM_BY_ID,
   payload:{data}
});
export const GetCatalogueItemsByAddonsId = (data)=>({
   type: CATALOGUE_ITEM_BY_ADDONS_ID,
   payload:{data}
});
export const GetCatalogueItemsByAddonsIdSuccess = (data)=>({
   type: CATALOGUE_ITEM_BY_ADDONS_ID_SUCCESS,
   payload:{data}
});
