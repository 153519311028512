import React, { useEffect, useState, useContext } from "react";
import Prism from "prismjs";
import { useParams } from "react-router-dom";
import { Button, Row, Col, Table, Form } from "react-bootstrap";
import Select from "react-select";
import {
  MapAddOnItem,
  UnMapAddOnItem,
  UpdateAddonsMode,
  UpdateCatalogFeatures,
} from "../../../../application/actions/ItemAction";
import {
  AddOnsItemList,
  getAllAddOnsList,
  getCatalogFeature,
} from "../../../../application/selectors/indexSelector";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import { logDOM } from "@testing-library/react";
import Environment from "../../../../infrastructure/core/Environment";

export default function AddonsComponent(props) {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const { id } = useParams();
  const [mode, setMode] = useState(false);
  const AddOnsList = useSelector(getAllAddOnsList);
  const MappedAddOnsList = useSelector(AddOnsItemList);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const CatalogFeature = useSelector(getCatalogFeature);
  const options = AddOnsList?.filter(
    (addon) =>
      !MappedAddOnsList.some((mappedAddon) => mappedAddon.id === addon.id)
  ).map((item) => ({
    value: item.id,
    label: item.groupTitle,
  }));
  const dispatch = useDispatch();
  const handleChange = (selectedValues) => {
    setSelectedOptions(selectedValues);
  };
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (CatalogFeature !== undefined) {
      setFormData({
        ...formData,
        mappingId: CatalogFeature?.id,
        catalogueId: CatalogFeature?.catalogueId,
        isTaxOverride: CatalogFeature?.isTaxOverride,
        isKOT: CatalogFeature?.isKOT,
        isDedicatedKitchen: CatalogFeature?.isDedicatedKitchen,
        isAddOn: CatalogFeature?.isAddOn,
        isCustomized: CatalogFeature?.isCustomized,
        isRecipe: CatalogFeature?.isRecipe,
        isBOM: CatalogFeature?.isBOM,
      });
      setMode(CatalogFeature?.isAddOn);
    }
  }, [CatalogFeature]);

  const MapAddOns = () => {
    let AddOnsList = [];
    for (var i = 0; i < selectedOptions?.length; i++) {
      const addOns = {
        isVisible: true,
        catalogueId: id,
        addonGroupId: selectedOptions[i].value,
        CreatedBy: user.id,
        ModifiedBy: user.id,
        mainCategoryId: Environment.defaultValue,
        categoryId: Environment.defaultValue,
        subCategoryId: Environment.defaultValue,
      };
      AddOnsList.push(addOns);
    }
    dispatch(MapAddOnItem(AddOnsList));
    setSelectedOptions([]);
  };
  const unMapAddons = (AddOns) => {
    const mappingId = MappedAddOnsList?.find(
      (addon) => addon.id === AddOns.id
    )?.mappingId;
    const Data = {
      mappingId: mappingId,
      id: AddOns.id,
      catalogueId: id,
    };
    dispatch(UnMapAddOnItem(Data));
  };

  const updateAddonsMode = (state) => {
    setMode(state);
    let updatedFormObject = { ...formData, isAddOn: state };
    dispatch(UpdateCatalogFeatures(updatedFormObject));
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(true); // Toggle dropdown menu open/close state
  };

  const closeDropdown = () => {
    setIsOpen(false); // Close dropdown menu
  };
  return (
    <React.Fragment>
      <div className="mb-2 d-flex mb-4">
        <Form.Check
          type="switch"
          className="fs-16 fw-semibold"
          label="Allow Addons and Customization?"
          checked={mode}
          onChange={(e) => updateAddonsMode(e.target.checked)}
        />
        <span className="text-danger">*</span>
      </div>
      {mode && (
        <div className="price-variant-bg">
          <Form.Label>Choose Addon Group: <sup className="text-danger">*</sup></Form.Label>

          <Row className="gap-2">
            <Col xl={6} md={6} xs={12}>
              <div onBlur={closeDropdown}>
                <div onClick={toggleDropdown}>
                  <Select
                    options={options}
                    isMulti
                    isSearchable={true}
                    value={selectedOptions}
                    onChange={handleChange}
                    placeholder="Select Addon Group"
                    menuIsOpen={isOpen}
                    onMenuClose={() => { }}
                  />
                </div>
              </div>
            </Col>
            <Col xl={4} md={4} xs={12}>
              <Button variant="primary" onClick={MapAddOns}>Include</Button>
            </Col>
          </Row>

          <div className="mb-3 mt-4">
            <Table className="mb-0" responsive>
              <thead>
                <tr>
                  <th>Addon Group Name</th>
                  <th>Update By</th>
                  <th>Exclude</th>
                </tr>
              </thead>

              <tbody>
                {MappedAddOnsList?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.groupTitle}</td>
                    <td>
                      <h6 className="fs-11 mb-1">
                        {" "}
                        {format(
                          new Date(item.createdOn),
                          "dd MMM yyyy hh:mm a"
                        )}
                      </h6>
                      <h5 className="fs-14 fw-semibold">
                        {item.createdByName}
                      </h5>
                    </td>

                    <td>
                      <Button
                        variant="outline-danger"
                        className="btn-icon"
                        onClick={() => unMapAddons(item)}
                      >
                        <i className="ri-delete-bin-line"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
