import { EMPLOYEE_DETAIL_SUCCESS, EMPLOYEE_LIST_SUCCESS, ROLES_LIST_BY_EMPID_SUCCESS } from "../actions/employeeAction";

const initialState = {
    EmployeeList:undefined,
    EmployeeDetail:{},
    RoleslistByEmpId:[],
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case EMPLOYEE_LIST_SUCCESS:
            return { ...state ,EmployeeList: action.payload.result, error: null}
        case EMPLOYEE_DETAIL_SUCCESS:
            return { ...state ,EmployeeDetail: action.payload.result, error: null}
         case ROLES_LIST_BY_EMPID_SUCCESS:
            return { ...state ,RoleslistByEmpId: action.payload.result, error: null}
        default:            
            return state;
    }
}

export default reducer;