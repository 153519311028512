export const LOAD_EMPLOYEE = "LOAD_EMPLOYEE";
export const EMPLOYEE_LIST_SUCCESS = "EMPLOYEE_LIST_SUCCESS";
export const CREATE_EMPLOYEE = "CREATE_EMPLOYEE";
export const DELETE_EMPLOYEE = "DELETE_EMPLOYEE";
export const UPDATE_EMPLOYEE = "UPDATE_EMPLOYEE";
export const EMPLOYEE_DETAIL_SUCCESS = "EMPLOYEE_DETAIL_SUCCESS";
export const EMPLOYEE_DETAIL = "EMPLOYEE_DETAIL";
export const ASSIGN_EMPLOYEE_BRANCH = "ASSIGN_EMPLOYEE_BRANCH";
export const DELETE_MAPPED_EMP_BRANCH = "DELETE_MAPPED_EMP_BRANCH";
export const ASSIGN_EMPLOYEE_ROLES = "ASSIGN_EMPLOYEE_ROLES";
export const ROLES_LIST_BY_EMPID = "ROLES_LIST_BY_EMPID";
export const ROLES_LIST_BY_EMPID_SUCCESS = "ROLES_LIST_BY_EMPID_SUCCESS";
export const REVOKE_EMPLOYEE_ROLE = "REVOKE_EMPLOYEE_ROLE";

export const LoadEmployee=(id) =>({
  type: LOAD_EMPLOYEE,
  payload:id
});
export const LoadEmployeeListSuccess = (data) => ({
  type: EMPLOYEE_LIST_SUCCESS,
  payload: data,
});
export const CreateEmployee = (data) => ({
  type: CREATE_EMPLOYEE,
  payload: data,
});
export const DeleteEmployee = (id) => ({
  type: DELETE_EMPLOYEE,
  payload: id,
});
export const UpdateEmployee = (data) => ({
  type: UPDATE_EMPLOYEE,
  payload: data,
});
export const EmployeeDetail = (id) => ({
  type: EMPLOYEE_DETAIL,
  payload: id,
});
export const assignEmployeeBranch = (data) => ({
  type: ASSIGN_EMPLOYEE_BRANCH,
  payload: data,
});
export const EmployeeDetailSuccess = (id) => ({
  type: EMPLOYEE_DETAIL_SUCCESS,
  payload: id,
});
export const deleteMappedEmpBranch = (id) => ({
  type: DELETE_MAPPED_EMP_BRANCH,
  payload: id,
});
export const assignEmployeesRoles = (data) => ({
  type:ASSIGN_EMPLOYEE_ROLES,
  payload: data,
});
export const GetRolesListByEmpId = (data) => ({
  type : ROLES_LIST_BY_EMPID,
  payload: data,
});
export const GetRolesListByEmpIdSuccess = (data) => ({
  type : ROLES_LIST_BY_EMPID_SUCCESS,
  payload: data,
});
export const revokeEmployeeRole = (id) => ({
  type : REVOKE_EMPLOYEE_ROLE,
  payload: id,
});