import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Card, Table, Button } from "react-bootstrap";
import healthInfoList from "../../../../Json/Catalogue/healthInformation/health-information.json";

export default function HealthInfoComponent() {
    const [getHealthInfo, setGetHealthInfo] = useState(healthInfoList);
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return (
        <React.Fragment>
            <div className="price-variant-bg">
                {/* <div className="text-center">
                    <Button variant="primary" className="gap-2 mb-3">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="d-sm-inline align-middle">Generate</span>
                    </Button>
                </div> */}
                
                <Card className="card-one h-100 border mb-3">
                    <Card.Body>
                        <Table className="mb-0" responsive>
                            <tbody>
                                <tr>
                                    <td className="w-15">Total Calories</td>
                                    <td className="text-wrap">{getHealthInfo.total_calories}</td>
                                </tr>
                                <tr>
                                    <td className="w-15">Total Fat</td>
                                    <td className="text-wrap">{getHealthInfo.total_fat}</td>
                                </tr>
                                <tr>
                                    <td className="w-15">Vitamins</td>
                                    <td className="text-wrap">{getHealthInfo.vitamins}</td>
                                </tr>
                                <tr>
                                    <td className="w-15">Total Carbohydrates</td>
                                    <td className="text-wrap">{getHealthInfo.total_carbohydrates}</td>
                                </tr>
                                <tr>
                                    <td className="w-15">Total Proteins</td>
                                    <td className="text-wrap">{getHealthInfo.total_proteins}</td>
                                </tr>
                                <tr>
                                    <td className="w-15">total Phosphorus</td>
                                    <td className="text-wrap">{getHealthInfo.total_phosphorus}</td>
                                </tr>
                                <tr>
                                    <td className="w-15">Glycemic</td>
                                    <td className="text-wrap">{getHealthInfo.glycemic_index}</td>
                                </tr>
                                <tr>
                                    <td className="w-15">Glycemic</td>
                                    <td className="text-wrap">{getHealthInfo.nutrition_value}</td>
                                </tr>
                                <tr>
                                    <td className="w-15">Allergens</td>
                                    <td className="text-wrap">{getHealthInfo.allergens}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Card className="card-one ht-auto border">
                    <Card.Body>
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Ingredient Name</th>
                                    <th>Calories</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {getHealthInfo.ingredient.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.ingredient_name}</td>
                                        <td>{item.calories}</td>
                                        <td className="w-0">
                                            <div>
                                                <Button variant="outline-danger" className="btn-icon me-2"><i className="ri-delete-bin-line"></i></Button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}
