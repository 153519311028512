import React, { useEffect, useState, useRef, useContext } from "react";
import Prism from "prismjs";
import "./menu.scss";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  Imgaeupload,
  UpdateCategory,
} from "../../../application/actions/menuAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import environment from "../../../infrastructure/core/Environment";
import {
  restrictAllNumbers,
  restrictAllSpecialCharacters,
  restrictConsecutiveSpace,
  restrictOnlyNumber,
  validateRequired,
  validateRequiredDropdown,
} from "../../../infrastructure/core/validationUtils";

export default function EditSubMenu(props) {
  const { user } = useContext(AuthContext);
  const initialState = {
    id: "",
    name: "",
    description: "",
    sequence: "",
    mainCategoryId: "",
    isVisible: true,
    status: `${environment.defaultValue}`,
    isDeleted: false,
    createdBy: user.id,
    modifiedBy: user.id,
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const dispatch = useDispatch();
  const Status = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "STS"
  );

  useEffect(() => {
    if (props.data) {
      setFormData({
        ...formData,
        id: props?.data?.id,
        name: props?.data?.name,
        description: props?.data?.description,
        isVisible: props?.data?.isVisible,
        status: props?.data?.status,
        sequence: props?.data?.sequence,
        mainCategoryId: props?.data?.mainCategoryId,
      });
    }
  }, [props.data]);
  const [errors, setErrors] = useState({});
  const validateName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.name = validateRequired(formData.name)
      ? ""
      : "Sub Menu is required";
    // Validate mainCatId (dropdown)
    newErrors.status = validateRequiredDropdown(formData.status)
      ? ""
      : "Please Select Visibility";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // Validate name before submitting
    const isValid = validateName();
    if (!isValid) {
      return;
    }
    dispatch(UpdateCategory(formData));
    setFormData(initialState);
    props.closeFunction();
  };
  const fileInputRef = useRef(null);
  const [imagePath, setImagePath] = useState("");
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imagePath = e.target.result;
        setImagePath(imagePath);
        const image = imagePath.split(",")[1];
        const id = props?.data?.id;
        const data = {
          image: file,
          id: id,
          foldername: "category-images",
        };
        dispatch(Imgaeupload(data));
      };

      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Edit Sub Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Sub Menu Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Item Name"
                  name="name"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      name: "",
                    }));
                  }}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}                />
                <Form.Text className="text-danger">{errors.name}</Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Short Description"
                  name="description"
                  value={formData.description}
                  onChange={(e) =>{
                    setFormData({ ...formData, description: e.target.value })
                  
                  }}
                  onKeyDown={restrictConsecutiveSpace}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Sub Menu: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  value={formData.status}
                  onChange={(e) => {
                    setFormData({ ...formData, status: e.target.value });
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      status: "" ,
                    }));
                  }}
                >
                  <option value={`${environment.defaultValue}`} disabled>
                    Choose Sub Menu Status
                  </option>
                  {Status?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">{errors.status}</Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <div className="mb-4">
                <Form.Label>Sub Menu Sequence</Form.Label>
                <Form.Control
                  type="text"
                  name="sequence"
                  value={formData.sequence}
                  onChange={(e) =>
                    setFormData({ ...formData, sequence: e.target.value })
                  }
                  onKeyDown={restrictOnlyNumber}
                  placeholder="enter sequence"
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Menu Icon</Form.Label>
                  <div className="custom-update-image">
                    <Form.Control
                      type="file"
                      className="custom-file-input"
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      accept=".jpg, .jpeg, .png"
                    />
                    <label
                      className="custom-file-label"
                      htmlFor="customFileInput"
                    >
                      <img
                        src={`https://freshlo.oss-ap-south-1.aliyuncs.com/FE/category-images/${
                          formData?.id
                        }/${
                          formData?.id
                        }.png?version=${Math.floor(Math.random() * 1000000)}}`}
                        onError={(e) => {
                          e.target.src =
                            "https://freshlo.oss-ap-south-1.aliyuncs.com/FudEasy/no-image%20(2).png";
                        }}
                        className="img-fluid"
                        alt="thumbnail"
                      />
                    </label>
                  </div>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer" onClick={onSubmit}>
          <Button variant="primary" className="fs-14 d-flex align-items-center">
            {" "}
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Save Changes</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
