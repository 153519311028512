import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Form, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getVarianceList,
} from "../../../../application/selectors/indexSelector";
import Environment from "./../../../../infrastructure/core/Environment";
import { MapBranchVariance } from "../../../../application/actions/varianceAction";

export default function CopyPriceVariant(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();
  const VarianceList = useSelector(getVarianceList);
  const { loading, value } = useSelector(getLoading);
  const [varianceList, setVarianceList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const BranchData = VarianceList?.locations.filter((value) => value.branchId !==props.branchId)
  const handleSelectBranch = (locationId) => {
    const selectVariantOptions = VarianceList?.locations?.find(
      (loc) => loc.branchId === locationId
    );
    const options = selectVariantOptions.variances?.map((item) => ({
      value: item,
      label: item.variantName,
    }));
    setSelectedOptions([]);
    setVarianceList(options);
  };
  const handleVariance = (data) => {
    setSelectedOptions(data);
    let Data = data.map((value) => value.value);
    setSelectedData(Data);
  };
  const handleSubmit = () => {
    const Obj = selectedData?.map((item) => ({
      mappingId: Environment.defaultValue,
      catalogueId: item.catalogueId,
      varianceId: item.id,
      sellingPrice: item.sellingPrice,
      markupPrice: item.markupPrice,
      preparationPrice: item.preparationPrice,
      branchId: props?.branchId,
    }));
    dispatch(MapBranchVariance(Obj));
    handleClose()
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(true); // Toggle dropdown menu open/close state
  };

  const closeDropdown = () => {
    setIsOpen(false); // Close dropdown menu
  };
  const handleClose=()=>{
    props.closeFunction()
    setSelectedOptions([])
  }
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16">
            Copy Price Variant
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl={8}>
              <div className="mb-4">
                <Form.Label>Choose Branch</Form.Label>
                <Form.Select
                  onChange={(e) => handleSelectBranch(e.target.value)}
                >
                  <option value="Choose Branch">Choose Branch</option>
                  {BranchData?.map((branch, index) => (
                    <option key={index} value={branch.branchId}>
                      {branch.branchName}
                    </option>
                  ))}
                </Form.Select>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={8}>
              <div>
                <Form.Label>Choose Variant</Form.Label>
                <div onBlur={closeDropdown}>
                  <div onClick={toggleDropdown}>
                    <Select
                      options={varianceList}
                      isSearchable={true}
                      value={selectedOptions}
                      isMulti
                      onChange={handleVariance}
                      menuIsOpen={isOpen}
                      onMenuClose={() => {}}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer">
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            <span className="align-middle">Submit</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
