import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Offcanvas, Row, Col, Form, Button } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

export default function EmpSalaryAdvance(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [startDate, setStartDate] = useState(new Date());

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Employee Salary Advance</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Row>
                        <Col xl={8} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Employee Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Employee Name" name="EmpName" />
                            </div>
                        </Col>

                        <Col xl={8} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Amount Paid</Form.Label>
                                <Form.Control type="number" min="1" placeholder="Enter Amount Paid" name="amoutPaid" />
                            </div>
                        </Col>
                        <Col xl={8} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Date</Form.Label>
                                <ReactDatePicker selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                            </div>
                        </Col>
                        
                        <Col xl={8} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Payment Mode</Form.Label>
                                <Form.Select name="genderId">
                                    <option>Payment Mode</option>
                                    <option value="cash">Cash</option>
                                    <option value="bank">Bank</option>
                                </Form.Select>
                            </div>
                        </Col>

                        <Col xl={8} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Remarks</Form.Label>
                                <Form.Control as="textarea" rows="3" placeholder="Enter Remarks"></Form.Control>
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Save</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}