import {
  GET_EMP_DAILY_REPORT_SUCCESS,
  GET_EMP_MONTHLY_REPORT_SUCCESS,
  MARK_EMP_ATTEN_SUCCESS,
} from "../actions/attendanceAction";

const initialState = {
  MonthlyReport: undefined,
  EmpAttendance: undefined,
  DailyReport: undefined,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EMP_MONTHLY_REPORT_SUCCESS:
      return { ...state, MonthlyReport: action.payload.result, error: null };
    case MARK_EMP_ATTEN_SUCCESS:
      return { ...state, EmpAttendance: action.payload.result, error: null };
    case GET_EMP_DAILY_REPORT_SUCCESS:
      return { ...state, DailyReport: action.payload.result, error: null };
    default:
      return state;
  }
};

export default reducer;
