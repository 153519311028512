import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Tab, Nav } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import Dashboard from "./Dashboard/dashboard";
import PendingOrder from "./PendingOrder/pending";
import OrderHistory from "./OrderHistory/history";
import CancelledOrder from "./CancelledOrder/cancelled-order";
import SaleReport from "./SaleReport/sale-report";
import { Link } from "react-router-dom";
import Environment from "../../infrastructure/core/Environment";
import {debounce} from "lodash";

export default function OrderSale () {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);
 
    useEffect(() => {
        switchSkin(skin);
    }, [skin]);
    const [pendingOrderCount, setPendingOrderCount] = useState(null);
    const [orderHistoryCount, setOrderHistoryCount] = useState(null);
    const [cancelledOrderCount, setCancelledOrderCount] = useState(null);
     useEffect(() => {
        document.body.classList.add('page-app');
        return () => {
          document.body.classList.remove('page-app');
        }
      }, []);

      // toggle sidebar in mobile
    const [isSidebarShow, setSidebarShow] = useState(false);

    const [branchData, setBranchData] = useState(Environment.defaultValue);
    const handleBranchIdFromHeader = debounce((data) => {
      setBranchData(data);
    }, 300);
    return (
      <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader}/>
        
        <Tab.Container id="left-tabs-example" defaultActiveKey="dashboard_tab">
            <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                <PerfectScrollbar className="file-sidebar page-in-tabs">
                    <Nav variant="pills">
                    <Nav.Item><Nav.Link className="position-relative" eventKey="dashboard_tab"><i className="ri-dashboard-line align-middle"></i> Dashboard</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className="position-relative" eventKey="pending_order_tab"><i className="ri-inbox-unarchive-line align-middle"></i> Pending Order <span className="position-absolute right-10">{pendingOrderCount}</span></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className="position-relative" eventKey="order_history_tab"><i className="ri-chat-history-line align-middle"></i> Order History <span className="position-absolute right-10">{orderHistoryCount}</span></Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link className="position-relative" eventKey="cancelled_orders_tab"><i className="ri-mail-close-line align-middle"></i> Cancelled Orders <span className="position-absolute right-10">{cancelledOrderCount}</span></Nav.Link></Nav.Item>
                        <hr />
                        <Nav.Item><Nav.Link className="position-relative" eventKey="sale_report_tab"><i className="ri-bank-card-line"></i> Sale Report <span className="position-absolute right-10">2</span></Nav.Link></Nav.Item>
                    </Nav>
                </PerfectScrollbar>
                <PerfectScrollbar className="file-content p-3 p-lg-4">
                    <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                        <i className="ri-arrow-left-line"></i>
                    </Link>
                    <Tab.Content>
                    <Tab.Pane eventKey="dashboard_tab"><Dashboard /></Tab.Pane>
                        <Tab.Pane eventKey="pending_order_tab"><PendingOrder setPendingOrderCount={setPendingOrderCount}/></Tab.Pane>
                        <Tab.Pane eventKey="order_history_tab"><OrderHistory setOrderHistoryCount={setOrderHistoryCount}/></Tab.Pane>
                        <Tab.Pane eventKey="cancelled_orders_tab"><CancelledOrder setCancelledOrderCount={setCancelledOrderCount} /></Tab.Pane>
                        <Tab.Pane eventKey="sale_report_tab"><SaleReport /></Tab.Pane>
                    </Tab.Content>
                </PerfectScrollbar>
            </div>
        </Tab.Container>
    </React.Fragment>

    
       
    )
}