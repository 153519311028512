import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Form, Row, Col, Button } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { transferOrder } from "../../../application/actions/posAction";
import {
  getCreateOrderSuccess,
  getLoading,
  getSectionsList,
  getTransferMergeChangesDone,
  getWaiterListPOSSuccess,
} from "../../../application/selectors/indexSelector";
import LoadingSpinner from "../../_common/LoadingSpinner";
import * as Loading from "./../../../application/actions/loaderAction";
import Environment from "../../../infrastructure/core/Environment";
import { validateRequiredDropdown } from "../../../infrastructure/core/validationUtils";

export default function MyCardTransfer(props) {
  const dispatch = useDispatch();
  const SectionsList = useSelector(getSectionsList);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const [sectionListByBranch, setSectionListByBranch] = useState([]);
  const [tablesBySelectedSection, setTablesBySelectedSection] = useState([]);
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedOrderType, setSelectedOrderType] = useState({});
  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  const transferMergeChangesDone = useSelector(getTransferMergeChangesDone);
  const orderTypeList = props?.orderTypeList?.map((option) => ({
    value: option.id,
    label: option.typeName,
  }));
  const waiterList = useSelector(getWaiterListPOSSuccess)
    .filter((waiterRole) => waiterRole.isOrderTake === true)
    .map((waiter) => ({
      value: waiter.id,
      label: waiter.fullName,
    }));
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  useEffect(() => {
      Prism.highlightAll();
      handleReset();
      if (createOrderSuccess?.salesPerson !== Environment.defaultValue) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          waiter: {
            value: createOrderSuccess?.salesPerson,
            label: waiterList.find(
              (waiter) => waiter.value === createOrderSuccess?.salesPerson
            )?.label,
          },
        }));
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          waiter: {
            value: null,
            label: 'Select Seller',
          },
        }));
      }
  }, [createOrderSuccess]);

  const validateInput = () => {
    const newErrors = {};
    if (
      !validateRequiredDropdown(formData.waiter.value) &&
      !validateRequiredDropdown(formData.orderType)
    ) {
      newErrors.waiter = validateRequiredDropdown(formData.waiter.value)
        ? ""
        : "Please Select Waiter";
      newErrors.orderType = validateRequiredDropdown(formData.orderType)
        ? ""
        : "Please Select Ordertype";
      if (formData.orderType && selectedOrderType?.isTableSection) {
        if (sectionListByBranch?.length === 0) {
          newErrors.noSection = "All Sections Utilized";
        } else {
          newErrors.noSection = "";
          newErrors.noTables = "";
          newErrors.section = validateRequiredDropdown(formData.section)
            ? ""
            : "Please Select Section";
          if (formData.section && tablesBySelectedSection?.length === 0) {
            newErrors.noTables = "All Tables Utilized";
          } else {
            newErrors.noTables = "";
            newErrors.table = validateRequiredDropdown(formData.table)
              ? ""
              : "Please Select Table";
          }
        }
      }
    } else if (formData.orderType !== null) {
      newErrors.waiter = "";
      if (formData.orderType && selectedOrderType?.isTableSection) {
        if (sectionListByBranch?.length === 0) {
          newErrors.noSection = "All Sections Utilized";
        } else {
          newErrors.noSection = "";
          newErrors.noTables = "";
          newErrors.section = validateRequiredDropdown(formData.section)
            ? ""
            : "Please Select Section";
          if (formData.section && tablesBySelectedSection?.length === 0) {
            newErrors.noTables = "All Tables Utilized";
          } else {
            newErrors.noTables = "";
            newErrors.table = validateRequiredDropdown(formData.table)
              ? ""
              : "Please Select Table";
          }
        }
      }
    }
    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };

  const handleChange = (fieldName, selectedOption) => {
    if (fieldName === "waiter" || fieldName === "orderType") {
      setErrors({});
    } else {
      setErrors({ ...errors, [fieldName]: "" });
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: selectedOption,
    }));

    switch (fieldName) {
      case "orderType":
        const selectedOrderType = props?.orderTypeList?.find(
          (orderType) => orderType.id === selectedOption?.value
        );
        setSelectedOrderType(selectedOrderType);
        const sectionsByBranch = SectionsList?.filter(
          (section) => selectedOrderType?.id === section?.typeId
        );
        const sectionList = sectionsByBranch?.map((option) => ({
          value: option.id,
          label: option.name,
        }));
        setSectionListByBranch(sectionList);
        if (selectedOrderType?.isTableSection) {
          setErrors({
            ...errors,
            noSection: sectionList.length === 0 ? "All Sections Utilized" : "",
            orderType: "",
          });
        }
        setTablesBySelectedSection([]);
        break;
      case "section":
        setErrors({
          ...errors,
          noTables: "",
        });
        const selectedOptionSection = SectionsList?.find(
          (section) => section.id === selectedOption?.value
        );
        const dineInTablesOfSelectedSection =
          selectedOptionSection?.dineInTables
            ?.filter(
              (dineInTables) =>
                dineInTables.tableStatus ===
                  "d9c5a952-911e-4a0f-8935-5e1fae02401e" &&
                !dineInTables.isMerged
            )
            .map((option) => ({
              value: option.id,
              label: option.tableName,
            }));
        if (dineInTablesOfSelectedSection.length === 0) {
          setErrors({
            ...errors,
            noTables:
              dineInTablesOfSelectedSection.length === 0
                ? "All Tables Utilized"
                : "",
            section: "",
          });
        }
        setTablesBySelectedSection(dineInTablesOfSelectedSection);
        break;
      default:
        break;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    const hasSectionTable = props?.orderTypeList?.find(
      (orderType) =>
        orderType.id ===
        (formData.orderType?.value
          ? formData.orderType?.value
          : createOrderSuccess?.orderTypeId)
    )?.isTableSection;
    const formObject = {
      orderId: createOrderSuccess?.id,
      orderType: formData.orderType?.value
        ? formData.orderType?.value
        : createOrderSuccess?.orderTypeId,
      tableId: hasSectionTable
        ? formData.table?.value
          ? formData.table?.value
          : createOrderSuccess?.tableId
        : Environment.defaultValue,
      salesPerson: formData.waiter?.value
        ? formData?.waiter?.value
        : createOrderSuccess?.salesPerson,
    };
    const formParameters = {
      formObject: formObject,
      hasSectionTable: hasSectionTable,
    };
    dispatch(transferOrder(formParameters));
  };

  const handleReset = () => {
    // Reset form data except for the "waiter" field
    setErrors({});
    setFormData((prevFormData) => ({
      waiter: prevFormData.waiter,
      orderType: null, // Reset other fields
      section: null,
      table: null,
    }));
    setSectionListByBranch([]);
    setTablesBySelectedSection([]);
  };
  useEffect(() => {
    if (
      ((transferMergeChangesDone && value === "transferOrder") ||
        (loading === false && value === "transferOrder")) &&
      formData.orderType?.value !== undefined
    ) {
      dispatch(Loading.setLoading({ loading: false, value: "" }));
      handleReset();
      props?.closeFunction();
    }
  }, [loading, value]);

  return (
    <React.Fragment>
      {loading && value === "transferOrder" && (
        <div className="card-loader">
          <LoadingSpinner color="#dc3545" size="100" type={"ThreeDots"} />
        </div>
      )}
      <h3 className="fs-14 text-dark mb-2">Transfer Order</h3>
      <Form>
        <Row>
          <Col xl="8">
            <div className="mb-4">
              <Form.Label>Seller:</Form.Label>
              <Select
                name="waiter"
                isSearchable={true}
                placeholder="Select Seller"
                options={waiterList}
                onChange={(selectedOption) =>
                  handleChange("waiter", selectedOption)
                }
                value={formData.waiter}
              />
              <Form.Text className="text-danger">{errors.waiter}</Form.Text>
            </div>
          </Col>

          <Col xl="8">
            <div className="mb-4">
              <Form.Label>Order Type:</Form.Label>
              <Select
                name="orderType"
                isSearchable={true}
                placeholder="Select OrderType"
                options={orderTypeList}
                onChange={(selectedOption) =>
                  handleChange("orderType", selectedOption)
                }
                value={formData.orderType}
              />
              <Form.Text className="text-danger">{errors.orderType}</Form.Text>
            </div>
          </Col>
          {errors.noSection !== "" && errors.noSection !== undefined && (
            <Col xl="8">
              <Form.Text className="alert alert-danger p-1 d-inline-flex">
                <i className="ri-alert-line fs-20"></i>
                {errors.noSection}
              </Form.Text>
            </Col>
          )}
          {selectedOrderType && sectionListByBranch?.length > 0 && (
            <Col xl="8">
              <div className="mb-4">
                <Form.Label>Section:</Form.Label>
                <Select
                  name="section"
                  placeholder="Select Section"
                  options={sectionListByBranch}
                  onChange={(selectedOption) =>
                    handleChange("section", selectedOption)
                  }
                  value={formData.section}
                />
                <Form.Text className="text-danger">{errors.section}</Form.Text>
              </div>
            </Col>
          )}
          {errors.noTables !== "" && errors.noTables !== undefined && (
            <Col xl="8">
              <Form.Text className="alert alert-danger p-1 d-inline-flex">
                <i className="ri-alert-line fs-20"></i>
                {errors.noTables}
              </Form.Text>
            </Col>
          )}
          {selectedOrderType?.isTableSection &&
            tablesBySelectedSection &&
            tablesBySelectedSection.length > 0 && (
              <Col xl="8" className="mb-4">
                <div>
                  <Form.Label>Table Name:</Form.Label>
                  <Select
                    name="table"
                    options={tablesBySelectedSection}
                    placeholder="Select Table"
                    onChange={(selectedOption) =>
                      handleChange("table", selectedOption)
                    }
                    value={formData.table}
                  />
                  <Form.Text className="text-danger">{errors.table}</Form.Text>
                </div>
              </Col>
            )}
        </Row>

        <div className="my-card-footer">
          <Button
            type="button"
            variant="danger me-3"
            className="fs-12"
            onClick={handleSubmit}
          >
            Update
          </Button>
          <Button
            type="button"
            variant="outline-secondary"
            className="fs-12"
            onClick={handleReset}
          >
            Discard
          </Button>
        </div>
      </Form>
    </React.Fragment>
  );
}
