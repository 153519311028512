import React, { useState } from "react";
import { Button, Row, Col, Form, Card, Table } from "react-bootstrap";
import Select from "react-select";
import SweetAlertService from "../../../_common/SweetAlertService";
import ToastifyService from "../../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import { AssignRoletoEmployees, RemoveAccessRoleId } from "../../../../application/actions/userAction";
import { getEmployeeListByRoleId } from "../../../../application/selectors/indexSelector";

export default function MappedUsers(props) {
  const dispatch = useDispatch();
  const EmployeeList = props?.employeeList?.map((option) => ({
    value: option.id,
    label: option.fullName,
  }));
  const [formData, setFormData] = useState([]);
  const EmployeByRoleId = useSelector(getEmployeeListByRoleId);
  const handleSelectUser = (selectedOption) => {
    const selectedValues = selectedOption;
    setFormData(selectedValues);
  };
  const handleDeleteMappdeUser = (id) => {
    SweetAlertService.showAlert(
      "Delete User",
      "Are you sure you want to delete this User?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {
          // SweetAlertService.showSuccess("Success", "Language!");
          // dispatch(RemoveAccessRoleId(id))
          ToastifyService.success(`User Deleted Successfully`);
        } else {
          console.log("Delete operation cancelled!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`something went wrong`);
      });
  };
  const handleMapUser = () => {
    const data = {
      roleId: props?.data?.roleId,
      userRoleId: props?.data?.id,
      employeeIds: formData.map((item) => item.value),
    };
    dispatch(AssignRoletoEmployees(data));
    setFormData([]);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(true); // Toggle dropdown menu open/close state
  };

  const closeDropdown = () => {
    setIsOpen(false); // Close dropdown menu
  };
  return (
    <React.Fragment>
      <>
        <Row className="mb-3">
          <Col xl="12">
            <Form.Label>Choose User</Form.Label>
          </Col>
          <Col xl="6">
            <div onBlur={closeDropdown}>
              <div onClick={toggleDropdown}>
                <Select
                  name="roleName"
                  isMulti
                  options={EmployeeList}
                  onChange={(e) => handleSelectUser(e)}
                  value={EmployeeList?.find((option) =>
                    formData?.includes(option.value)
                  )}
                  menuIsOpen={isOpen}
                  onMenuClose={() => {}}
                />
              </div>
            </div>

            {/* <Form.Control type="text" placeholder="Enter Role Name" name="roleName" /> */}
          </Col>
          <Col>
            <Button
              variant="primary"
              className="d-flex align-items-center gap-1"
              onClick={handleMapUser}
            >
              <i className="ri-add-line fs-18 lh-1"></i>
              <span className="d-sm-inline">Add User</span>
            </Button>
          </Col>
        </Row>

        <Card className="card-one">
          <Card.Body>
            <Table className="mb-0" responsive>
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Added On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {EmployeByRoleId.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <h6 className="fs-13 mb-1">{item.name}</h6>
                      <h5 className="fs-12 fw-semibold">{item.email}</h5>
                    </td>
                    <td>
                      <h6 className="fs-13 mb-1">{item.addedOn}</h6>
                      <h5 className="fs-12 fw-semibold">System</h5>
                    </td>
                    <td className="w-0">
                      <div className="d-flex">
                        <Button
                          variant="danger"
                          className="btn-icon"
                          onClick={() => handleDeleteMappdeUser(item.id)}
                        >
                          <i className="ri-delete-bin-line"></i>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </>
    </React.Fragment>
  );
}
