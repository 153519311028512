import * as tableActions from "../actions/tableAction";
import * as Loading from "../actions/loaderAction";
import { Navigate } from "react-router-dom";
import ToastifyService from "../../View/_common/ToastifyService";

const AddSection =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === tableActions.ADD_SECTION) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const SectionData = await api.tableAPI.AddSection(action.payload.data);
        dispatch(tableActions.loadSectionSuccess(SectionData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (action.payload.data.id && action.payload.data.isDeleted === false) {
          ToastifyService.success("Section Updated Successfully! ");
        } else if(action.payload.data.isDeleted === false){
          ToastifyService.success("Section Added Successfully! ");
        }
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const AddTable =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === tableActions.ADD_TABLE) {
      try {
        const TableData = await api.tableAPI.AddTable(action.payload.data);
        dispatch(tableActions.loadSectionSuccess(TableData));
        ToastifyService.success("Table Added Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    } else if (action.type === tableActions.UPDATE_TABLE) {
      try {
        const TableData = await api.tableAPI.UpdateTable(action.payload.data);
        dispatch(tableActions.TableSuccess(TableData.result));
        if (action.payload.data.isDeleted) {
          ToastifyService.success("Table Deleted Successfully! ");
        } else {
          ToastifyService.success("Table Details Updated Successfully! ");
        }
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
      }
    }
    next(action);
  };
const DeleteTable =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === tableActions.DELETE_TABLE) {
      try {
        const TableData = await api.tableAPI.DeleteTable(
          action.payload.data.tableId
        );
        dispatch(tableActions.TableSuccess(TableData.result));
        ToastifyService.success("Table Deleted Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
      }
    }
    next(action);
  };
const DeleteSection =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === tableActions.DELETE_SECTION) {
      try {
        const SectionData = await api.tableAPI.DeleteSection(
          action.payload.data.sectionId
        );
        if (SectionData === 200) {
          dispatch(
            tableActions.getSectionByBranchId(action.payload.data.hubId)
          );
        }
        ToastifyService.success("Section Deleted Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
      }
    }
    next(action);
  };
const getSectionByBranchId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === tableActions.GET_SECTION) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "sectionList" }));
        const SectionData = await api.tableAPI.getSectionData(
          action.payload.data
        );
        dispatch(tableActions.loadSectionSuccess(SectionData));
        dispatch(Loading.setLoading({ loading: false, value: "sectionList" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "sectionList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetTableBySectionId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === tableActions.GET_TABLE_BY_SECTION_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const TableData = await api.tableAPI.getTableList(action.payload.data);
        dispatch(tableActions.TableSuccess(TableData.result));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  AddSection,
  getSectionByBranchId,
  AddTable,
  DeleteTable,
  DeleteSection,
  GetTableBySectionId,
];
