export const PAGE_LOADED = '[ui] page loaded';
export const SET_LOADING_ON = '[ui] set loading on';
export const SET_LOADING_OFF = '[ui] set loading off';
export const LOAD_SUCCESS= 'load_success'
export const LOAD_FAILURE= 'load_failure'
export const LOAD_ITEM_CATALOG= 'load item catalogue'
export const LOAD_ADDONS= 'LOAD_ADDONS'
export const LOAD_ITEM_CATALOG_BY_ID= 'LOAD_ITEM_CATALOG_BY_ID'
export const LOAD_MENU= 'LOAD_MENU'
export const LOAD_KITCHEN= 'LOAD_KITCHEN'
export const LOAD_TAX= 'LOAD_TAX'
export const LOAD_DISCOUNT= 'LOAD_DISCOUNT'
export const LOAD_BRANCH= 'LOAD_BRANCH'
export const LOAD_CHARGES= 'LOAD_CHARGES'
export const LOAD_TAX_BY_TENANT= 'LOAD_TAX_BY_TENANT'

export const pageLoaded = {
    type: PAGE_LOADED
};
export const setLoading = (data) => ({
    type: data.loading ? SET_LOADING_ON : SET_LOADING_OFF,
    payload: data,
});
export const loadSuccess = (load) => ({
    type: LOAD_SUCCESS,
    payload: load,
});
export const loadFailure = (load) => ({
    type: LOAD_FAILURE,
    payload: load,
});
export const loadItemCatalog = {
    type: LOAD_ITEM_CATALOG,   
}
export const loadAddOns = {
    type: LOAD_ADDONS,   
}
export const loadItemCatalogById =(data)=> ({
    type: LOAD_ITEM_CATALOG_BY_ID,   
    payload:data
})
export const loadMenu = {
    type: LOAD_MENU,  
}
export const loadKitchen =(data)=>({
    type: LOAD_KITCHEN,  
    payload:data
})

export const loadTax={
    type:LOAD_TAX
}
export const loadTaxByTenant=(data)=>({
    type:LOAD_TAX_BY_TENANT,
    payload:data
})
export const loadDiscount={
    type:LOAD_DISCOUNT
}
export const loadBranch=(data)=>({
    type:LOAD_BRANCH,
    payload:{data}
})
export const loadCharges={
    type:LOAD_CHARGES
}