import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Environment from "../../../infrastructure/core/Environment";
import {
  restrictAllSpecialCharacters,
  restrictConsecutiveSpace,
  validateRequired,
} from "../../../infrastructure/core/validationUtils";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { EditVendorDataById } from "../../../application/actions/vendorAction";

export default function EditVendor(props) {
  const dispatch = useDispatch();
  const initialState = {
    branchId: Environment.defaultValue,
    status: Environment.defaultValue,
    isVisible: true,
    isDeleted: false,
    vendorName: "",
    address: "",
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (props?.data) {
      setFormData(props?.data);
    }
  }, [props?.data]);
  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate Tax Name
    newErrors.vendorName = validateRequired(formData.vendorName)
      ? ""
      : "Role Name is required";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    dispatch(EditVendorDataById(formData));
    handleClose();
  };
  const handleClose = () => {
    setFormData(initialState);
    setErrors({});
    props.closeFunction();
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Update Vendor
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>
                  Vendor Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  Update
                  Role
                  placeholder="Enter Vendor Name"
                  name="vendorName"
                  value={formData.vendorName}
                  onChange={(e) => {
                    setFormData({ ...formData, vendorName: e.target.value });
                    setErrors({ ...errors, vendorName: "" });
                  }}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.vendorName}
                </Form.Text>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>
                  Vendor Address: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  Update
                  Role
                  placeholder="Enter Vendor Addres"
                  name="address"
                  value={formData.address}
                  onChange={(e) => {
                    setFormData({ ...formData, address: e.target.value });
                    setErrors({ ...errors, address: "" });
                  }}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                  }}
                />
                <Form.Text className="text-danger">
                  {errors.address}
                </Form.Text>
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>
        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Update</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
