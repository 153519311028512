// react-packages
import React, { useEffect, useState, useContext, useRef } from "react";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Prism from "prismjs";
//context
import AuthContext from "../../infrastructure/core/helpers/AuthContext";
//actions,selectors
import { updateCustomer } from "../../application/actions/customerAction";
import {
  getCustomerDetail,
  getCustomerList,
  getEmployeeList,
} from "../../application/selectors/indexSelector";
//environment file
import Environment from "../../infrastructure/core/Environment";
import {
  restrictAllNumbers,
  restrictAllSpecialCharacters,
  restrictConsecutiveSpace,
  restrictEmailCharacters,
  restrictOnlyNumber,
  restrictSpace,
  validateEmail,
  validateRequired,
  validateRequiredDropdown,
} from "../../infrastructure/core/validationUtils";

import LoadingBar from 'react-top-loading-bar'

export default function CustomerEditInfo(props) {
  //accessing hook from react-redux package
  const dispatch = useDispatch();
  //accessing user data from local storage using context
  const { user } = useContext(AuthContext);
  //making useState for customer formData
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  //manipulating the common fields list as needed in select package of react
  const genderCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "GEN"
      )
    : [];
  //fetching and storing customer Details
  const customerDetail = useSelector(getCustomerDetail);
  const customerList = useSelector(getCustomerList);
  const employeeList = useSelector(getEmployeeList);
  //Load customer details based on customer Id and set the data in formdata
  useEffect(() => {
    if (customerDetail) {
      setFormData({
        id: customerDetail.id,
        tenantId: customerDetail.tenantId,
        branchId: customerDetail.branchId,
        isVisible: customerDetail.isVisible,
        isDeleted: customerDetail.isDeleted,
        modifiedBy: user.id,
        fullName: customerDetail.fullName,
        countryCode: customerDetail.countryCode,
        contactNumber: customerDetail.contactNumber,
        email: customerDetail.email,
        genderId: customerDetail.genderId,
        isBlock: customerDetail.isBlock,
      });
    }
  }, [customerDetail, user]);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const checkDuplicateEmail = (name, value) => {
    const email = value.toLowerCase(); // Convert email to lowercase
    const isDuplicateEmail = customerList.some(
      (user) => user.username.toLowerCase() === email
    )
      ? customerList?.some((user) => user.username.toLowerCase() === email)
      : employeeList?.some((user) => user.email.toLowerCase() === email);
    if (isDuplicateEmail) {
      setErrors({
        ...errors,
        [name]: "Email already exists",
      });
    }
    return isDuplicateEmail;
  };
  const validateInput = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.fullName = validateRequired(formData.fullName)
      ? ""
      : "Full Name is required";

    // Validate catalogueCode
    newErrors.countryCode = validateRequiredDropdown(formData.countryCode)
      ? ""
      : "Country Code is required";

    // Validate mainCatId (dropdown)
    newErrors.contactNumber = validateRequired(formData.contactNumber)
      ? ""
      : "Contact Number is required";

    // Validate catId (dropdown)
    newErrors.email = !validateRequired(formData.email)
      ? "Email is required"
      : !validateEmail(formData.email)
      ? "Invalid email format"
      : checkDuplicateEmail("email", formData.email)
      ? "Email already exists"
      : "";
    // Validate foodtype (dropdown)
    newErrors.genderId = validateRequiredDropdown(formData.genderId)
      ? ""
      : "Please select gender";
    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  //onchange function for setting form data
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (value?.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
      // Email format validation
      if (name === "email") {
        const isValidEmail = validateEmail(value);
        if (!isValidEmail) {
          setErrors({
            ...errors,
            [name]: "Invalid email format",
          });
          return;
        } else {
          checkDuplicateEmail(name, value);
        }
      }
    } else {
      setErrors({
        ...errors,
        [name]: `${
          name === "fullName"
            ? "Full Name"
            : name === "countryCode"
            ? "Country code"
            : name === "contactNumber"
            ? "Contact Number"
            : name === "email"
            ? "Email"
            : name === "genderId"
            ? "Gender"
            : "This Field"
        } is required`,
      });
    }
  };
  //edit customer details api integration
  const [data, setData] = useState(null);
  const ref = useRef(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    ref.current.continuousStart(); // Start the loading bar
    // Validate the form
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    const formObject = {
      id: formData.id,
      tenantId: formData.tenantId,
      branchId: formData.branchId,
      isVisible: formData.isVisible,
      isDeleted: formData.isDeleted,
      modifiedBy: formData.modifiedBy,
      fullName: formData.fullName,
      countryCode: formData.countryCode,
      contactNumber: formData.contactNumber,
      email: formData.email,
      genderId: formData.genderId,
      isBlock: formData.isBlock,
    };
    ref.current.complete();
    dispatch(updateCustomer(formObject));
    props.closeFunction(false);
  };

  const handleLoaderFinished = () => {
    console.log('Loading finished! Performing another action...');
    anotherFunction();
};

const anotherFunction = () => {
  // Your additional function logic here
  console.log('Another function called!');
};


  return (
    <React.Fragment>
      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Customer Name: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Customer Name"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              onKeyDown={(e) => {
                restrictConsecutiveSpace(e);
                restrictAllSpecialCharacters(e);
                restrictAllNumbers(e);
              }}
            />
            <Form.Text className="text-danger">{errors.fullName}</Form.Text>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Phone Number: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Group
              controlId="formFile"
              className="position-relative overflow-hidden"
            >
              <Form.Label className="custom-label mb-0">+91</Form.Label>
              <Form.Control
                className="tax-percentage-input"
                style={{ paddingLeft: "50px" }}
                type="number"
                min="1"
                placeholder="Enter Phone Number"
                name="contactNumber"
                value={formData.contactNumber}
                onChange={handleChange}
                onKeyDown={(e) => restrictOnlyNumber(e)}
              />
            </Form.Group>
            <Form.Text className="text-danger">
              {errors.contactNumber}
            </Form.Text>
          </div>
        </Col>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Email Address"
              name="email"
              value={formData.email}
              onChange={handleChange}
              onKeyDown={(e) => {
                restrictSpace(e);
                restrictEmailCharacters(e);
              }}
            />
            <Form.Text className="text-danger">{errors.email}</Form.Text>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              aria-label="Default select example"
              name="genderId"
              value={formData.genderId}
              onChange={handleChange}
            >
              <option value={`${Environment.defaultValue}`} disabled>
                Choose Gender
              </option>
              {genderCommonList?.map((data) => (
                <option value={data.id} key={data.id}>
                  {data.fieldValue}
                </option>
              ))}
            </Form.Select>
            <Form.Text className="text-danger">{errors.genderId}</Form.Text>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          <Form.Check
            type="switch"
            className="me-3 fs-16 fw-semibold mt-2"
            label="Blacklisted"
            checked={formData.isBlock}
            name="isBlock"
            onChange={(e) => {
              //onchange function for setting form data if using check box or radio button as we use checked instead of value
              const { name, checked } = e.target;
              setFormData({ ...formData, [name]: checked });
            }}
          />
        </Col>
      </Row>
      <LoadingBar color="#f11946" ref={ref} onLoaderFinished={handleLoaderFinished} />
      <div className="offcanvas-footer justify-content-start position-absolute bg-white">
        <Button
          variant="primary"
          className="fs-14 me-2 d-flex align-items-center"
          onClick={handleSubmit}>
          <span className="align-middle"> Update Customer</span>
        </Button>
      </div>
    </React.Fragment>
  );
}
