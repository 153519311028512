import { Navigate } from "react-router-dom";
import {
  BranchOTSessionsSuccess,
  GET_BRANCH,
  GET_BRANCH_OT_SESSIONS,
  GetBranchSuccess,
  UPDATE_BASIC_INFO,
  UPDATE_OPERATING_HOURS,
  UpdateBasicInfoSuccess,
} from "../actions/branchAction";
import {} from "../actions/discountAction";
import * as Loading from "../actions/loaderAction";
import ToastifyService from "../../View/_common/ToastifyService";

const GetBranchList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_BRANCH) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "branchList" }));
        const BranchData = await api.branchAPI.GetBranchList(
          action.payload.data
        );
        dispatch(GetBranchSuccess(BranchData));
        dispatch(Loading.setLoading({ loading: false, value: "branchList" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetBranchOTSessions =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_BRANCH_OT_SESSIONS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const OTData = await api.branchAPI.GetBranchOTSessions(
          action.payload
        );
        dispatch(BranchOTSessionsSuccess(OTData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateBasicInfo =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_BASIC_INFO) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const BranchData = await api.branchAPI.UpdateBasicInfo(
          action.payload.data
        );
        dispatch(UpdateBasicInfoSuccess(BranchData));
        dispatch(GetBranchSuccess(BranchData));
        ToastifyService.success("Branch Updated Successfully!");
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateOperatingHors =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_OPERATING_HOURS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const BranchData = await api.branchAPI.UpdateOperatingHors(
          action.payload
        );
        ToastifyService.success("Operation Hours Updated Successfully!");

        dispatch(BranchOTSessionsSuccess(BranchData));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [UpdateOperatingHors, GetBranchList, UpdateBasicInfo,GetBranchOTSessions];
