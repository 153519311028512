import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const AddVendor  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.post(`${Environment.Vendor_Url}create-vendors`,data,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  }
  const EditVendorDataById  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.post(`${Environment.Vendor_Url}update-vendors`,data,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  }
  const getVendorList  = async ()=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.get(`${Environment.Vendor_Url}get-vendors`,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data;
  }
  export default {AddVendor,getVendorList,EditVendorDataById};