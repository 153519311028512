import React from "react";
import { Row, Col } from "react-bootstrap";


export default function KotLogs() {
    return (
        <React.Fragment>
            <Row>
                <Col xl={12}>
                    <h2 className="fs-14 mb-3">KOT Log</h2>

                    <ul className="activity-group mb-5">
                        <li className="activity-date">Today, April 23, 2024</li>
                        <li className="activity-item brown">
                            <p className="d-sm-flex align-items-center mb-2">
                                <span className="fs-sm">Status changed to <strong>Closed</strong></span>
                                <span className="text-secondary fs-xs ms-auto">10:30 PM</span>
                            </p>

                        </li>
                        <li className="activity-item brown">
                            <p className="d-sm-flex align-items-center mb-2">
                                <span className="fs-sm">Status Chnaged to <strong>Selected</strong></span>
                                <span className="fs-xs text-secondary ms-auto">10:45 PM</span>
                            </p>
                        </li>

                        <li className="activity-item brown">
                            <p className="d-sm-flex align-items-center mb-2">
                                <span className="fs-sm">Status Chnaged to <strong>Billing</strong></span>
                                <span className="fs-xs text-secondary ms-auto">11:10 PM</span>
                            </p>
                        </li>

                        <li className="activity-item percent">
                            <p className="d-sm-flex align-items-center mb-2">
                                <span className="fs-sm">Discount of <strong>Rs. 100</strong> applied on the bill</span>
                                <span className="fs-xs text-secondary ms-auto">11:30 PM</span>
                            </p>
                        </li>

                        <li className="activity-item delete">
                            <p className="d-sm-flex align-items-center mb-2">
                                <span className="fs-sm">Items Deleted from the order by user  <strong>Aamir Sayyed</strong></span>
                                <span className="text-secondary fs-xs ms-auto">08:50 PM</span>
                            </p>
                            <div className="timeline-order-view danger">
                                <div className="timeline-order-list">
                                    <h5 className="fs-12 fw-semibold text-dark">Kunafa</h5>
                                    <h6 className="fs-12 fw-semibold text-dark">1</h6>
                                </div>
                                <div className="timeline-order-list">
                                    <h5 className="fs-12 fw-semibold text-dark">Kunafa</h5>
                                    <h6 className="fs-12 fw-semibold text-dark">1</h6>
                                </div>
                            </div>
                        </li>

                        <li className="activity-item add">
                            <p className="d-sm-flex align-items-center">
                                <span className="fs-sm">Order Created by  <strong>seller: Imran Qureshi</strong> <br /> #3245 21 252</span>
                                <span className="text-secondary fs-xs ms-auto">09:25 PM</span>
                            </p>
                        </li>
                    </ul>

                </Col>
            </Row>
        </React.Fragment>
    )
}