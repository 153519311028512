// import React, { useEffect, useState } from "react";
// import Header from "../../layouts/Header";
// import { Row, Col, Card } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import axios from "axios";
// import InfiniteScroll from "react-infinite-scroll-component";
// import ShimmerLoader from "../../layouts/ShimmerTable";
// import { getItemCatalogData, getLoading } from "../../application/selectors/indexSelector";
// import { loadItemCatalog } from "../../application/actions/loaderAction";
// import Environment from "../../infrastructure/core/Environment";
// import {debounce} from "lodash";

// export default function InfiniteScrollComponent() {
//     let chunckData;
//     const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
//     const [skin, setSkin] = useState(currentSkin);
//     const switchSkin = (skin) => {
//         if (skin === 'dark') {
//             const btnWhite = document.getElementsByClassName('btn-white');

//             for (const btn of btnWhite) {
//                 btn.classList.add('btn-outline-primary');
//                 btn.classList.remove('btn-white');
//             }
//         } else {
//             const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');
//             for (const btn of btnOutlinePrimary) {
//                 btn.classList.remove('btn-outline-primary');
//                 btn.classList.add('btn-white');
//             }
//         }
//     }
//     switchSkin(skin);
//     const dispatch = useDispatch();
//     useEffect(() => {
//         switchSkin(skin);
//     }, [skin]);

//     const {loading,value} = useSelector(getLoading)
//     // const [loading, setLoading]=useState(false)
//     useEffect(() => {
//         // setLoading(Loader)
//         dispatch(loadItemCatalog);
//         // setLoading(Loader)
//     }, []);
//     const ItemCatalogData = useSelector(getItemCatalogData);
//     function* chunkArrayGenerator(array, chunkSize) {
//         for (let i = 0; i < array.length; i += chunkSize) {
//             yield array?.slice(i, i + chunkSize);
//         }
//     }
//     let pendingList = [];
//     for (let i = 0; i < ItemCatalogData?.length; i++) {
//         if (ItemCatalogData[i].approval === "Pending") {
//             pendingList?.push(ItemCatalogData[i]);
//         }
//     }

//     let vegganList = [];
//     for (let i = 0; i < ItemCatalogData?.length; i++) {
//         if (ItemCatalogData[i].foodType === 2) {
//             vegganList.push(ItemCatalogData[i]);
//         }
//     }

//     let nonVegList = [];
//     for (let i = 0; i < ItemCatalogData?.length; i++) {
//         if (ItemCatalogData[i].foodType !== 2) {
//             nonVegList.push(ItemCatalogData[i]);
//         }
//     }

//     const [showAddCatalogue, setShowAddCatalogue] = useState(false);
//     const handleAddCatalogue = (state) => {
//         setShowAddCatalogue(state);
//     };

//     // 
//     const [items, setItems] = useState([]);
//     const [hasMore, setHasMore] = useState(true);
//     const [page, setPage] = useState(1);
//     const [chunkGenerator, setChunkGenerator] = useState();

//     function* createChunkIterator(arr, size) {
//         let i = 0;
//         while (i < arr.length) {
//           yield arr.slice(i, i + size);
//           i += size;
//         }
//       }
//     const fetchMoreData = async () => {
//         try {
//             if (chunckData.next().value !== null) {
//                 setItems(chunckData.next().value);
//                 setPage((prevPage) => prevPage + 1);
//             } else {
//                 // No more new data, set hasMore to false
//                 setHasMore(false);
//             }
//         } catch (error) {
//             console.error("Error fetching data:", error);
//         }
//     };
    

//     // const createChunkIterator =  (array, chunkSize) => {
//     //     let index = 0;
//     //     return  () => {
//     //         const chunk =  array.slice(index, index + chunkSize);
//     //         index += chunkSize;
//     //         return chunk.length > 0 ? chunk : null;
//     //     };
//     // };
//     // if(ItemCatalogData?.length !== 0){
//     //     const chunckData =  createChunkIterator(ItemCatalogData, 20)
//     //     setChunkGenerator(chunckData);
//     // fetchMoreData();
//     // }
//     useEffect(() => {
//         if(ItemCatalogData.length !== 0){
//             chunckData =  createChunkIterator(ItemCatalogData, 20)
//             setChunkGenerator(chunckData);
//         fetchMoreData();
//         }
//          // Initial data fetching
//     }, [ItemCatalogData ]);


//     const [branchData, setBranchData] = useState(Environment.defaultValue);
//     const handleBranchIdFromHeader = debounce((data) => {
//       setBranchData(data);
//     }, 300);
//     return (
//       <React.Fragment>
//       <Header passDataToPointOfSale={handleBranchIdFromHeader}/>
//             <div className="main main-app p-3 p-lg-4">
//                 <Row className="mb-4 align-items-center">
//                     <Col>
//                         <div>
//                             <ol className="breadcrumb fs-sm mb-1">
//                                 <li className="breadcrumb-item"><Link to="#">Home</Link></li>
//                                 <li className="breadcrumb-item active" aria-current="page">Manage</li>
//                             </ol>
//                             <h4 className="main-title mb-0">Infinite Scroll</h4>
//                         </div>
//                     </Col>
//                 </Row>

//                 {/* ITEM CATELOGUE SUMMARY */}

//                 {!loading && (<Row className="g-3">
//                     {
//                         [{
//                             "label": "Total Items",
//                             "icon": "ri-shopping-bag-3-line",
//                             "value": ItemCatalogData?.length,
//                             "percent": "0.7",
//                             "status": "down",
//                             'des': '0 Items added this week'
//                         }, {
//                             "label": "Pending Approval",
//                             "icon": "ri-restart-line",
//                             "value": pendingList?.length,
//                             "percent": "2.1",
//                             "status": "up",
//                             'des': 'Items not listed for sales'
//                         }, {
//                             "label": "Total Veggan Items",
//                             "icon": "ri-leaf-line",
//                             "value": vegganList?.length,
//                             "percent": "0.3",
//                             "status": "down",
//                             'des': 'Vegetarian Items in menu'
//                         }, {
//                             "label": "Total Non-Veg Items",
//                             "icon": "ri-arrow-down-line",
//                             "value": nonVegList?.length,
//                             "percent": "1.2",
//                             "status": "up",
//                             'des': 'Non Vegetarian Items in menu'
//                         }].map((card, index) => (
//                             <Col xs="6" xl="3" key={index}>
//                                 <Card className="card-one">
//                                     <Card.Body>
//                                         <Card.Title as="label" className="fs-sm fw-medium mb-1">{card.label}</Card.Title>
//                                         <h3 className={`card-value mb-1 ${card.label === 'Pending Approval' ? 'text-danger' : ''}`}>
//                                             <i className={`${card.icon} ${card.label === 'Pending Approval' ? 'text-danger' : ''}${card.label === 'Total Veggan Items' ? 'text-success' : ''}`}></i> {card.value}
//                                         </h3>
//                                         <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent}% <i className={"ri-arrow-" + ((card.status === "up") ? "up" : "down") + "-line"}></i></span> {card.des}</small>
//                                     </Card.Body>
//                                 </Card>
//                             </Col>
//                         ))
//                     }
//                 </Row>
//                 )}
//                 {loading && (<ShimmerLoader  columnCount={4} rowCount={4}/>)}

//                 <Row className="mb-4 mt-4 align-items-center">
//                     <Col xs={5} xl={6}>
//                         <div>
//                             <h4 className="main-title fs-16 mb-0">List of Items</h4>
//                         </div>
//                     </Col>
//                     <Col xs={7} xl={6}>
//                         <div className="justify-content-end d-flex">
//                             <div className="form-search w-50"><i className="ri-search-line"></i><input type="text" className="form-control" placeholder="Search" /></div>
//                         </div>
//                     </Col>
//                 </Row>

//                 {/*  */}
//                 <Card>
//                     <Card.Body>
//                         <InfiniteScroll
//                             dataLength={items.length}
//                             next={fetchMoreData}
//                             hasMore={hasMore}
//                             loader={<h4>Loading...</h4>}
//                             endMessage={<p>No more items to load</p>}
//                         >
//                             {items.map((item, index) => (
//                                 <div key={index}>
//                                     <h2>{index}</h2>
//                                     {/* <h2>{item.catalogueName}</h2>*/}
//                                 </div>
//                             ))}
//                         </InfiniteScroll>
//                     </Card.Body>
//                 </Card>
//             </div>
//         </React.Fragment>
//     )
// }
import React from 'react'

function InfiniteScrollComponent() {
  return (
    <div>infinite-scroll</div>
  )
}

export default InfiniteScrollComponent
