import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Button,
  Offcanvas,
  Row,
  Col,
  Form,
  Tabs,
  Tab,
  Card,
} from "react-bootstrap";
import MappedLocation from "../../_common/mapped-location";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteEmployee,
  EmployeeDetail,
  UpdateEmployee,
  assignEmployeeBranch,
  deleteMappedEmpBranch,
  revokeEmployeeRole,
} from "../../../application/actions/employeeAction";
import {
  getBranchList,
  getCustomerList,
  getEmployeeDetail,
  getEmployeeList,
  getLoading,
} from "../../../application/selectors/indexSelector";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { loadBranch } from "../../../application/actions/loaderAction";
import Environment from "../../../infrastructure/core/Environment";
import { format } from "date-fns";
import CardShimmerLoader from "../../../layouts/ShimmerTable";
import DataTable from "react-data-table-component";
import Loader from "../../../layouts/Loader";
import "react-datepicker/dist/react-datepicker.css";
import {
  restrictConsecutiveSpace,
  restrictEmailCharacters,
  restrictSpace,
  validateEmail,
  validateRequired,
  validateRequiredDropdown,
} from "../../../infrastructure/core/validationUtils";

export default function ManageEmployee(props) {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);

  const employeeDetail = useSelector(getEmployeeDetail);
  const customerList = useSelector(getCustomerList);
  const employeeList = useSelector(getEmployeeList);
  const BranchList = useSelector(getBranchList);
  const { loading, value } = useSelector(getLoading);

  const initialState = {
    fullName: "",
    email: "",
    username: "",
    countryCode: "+91",
    contactNumber: "",
    defaultPassword: "Pass@123",
    passport: "",
    govermentID: "",
    tenantId: user?.tenanatId,
    genderId: Environment.defaultValue,
    status: Environment.defaultValue,
    branchId: props.branchId,
    roleId: Environment.defaultValue,
    createdBy: user.id,
    isSystemAccess: false,
    isTrackAttendance: false,
    isOrderTake: false,
    dateOfBirth: null,
    salary: 0,
  };
  const genderCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "GEN"
      )
    : [];
  const statusCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "STS"
      )
    : [];
  const MappedBranches = employeeDetail?.branches
    ? employeeDetail?.branches
    : [];
  const BranchOptions = BranchList?.filter(
    (branch) => !MappedBranches?.some((emp) => emp.branchId === branch.id)
  ).map((item) => ({
    value: item.id,
    label: item.branchName,
  }));
  const columns = [
    {
      name: "Role Assigned",
      selector: (roles) => <span>{roles.roleName}</span>,
    },
    {
      name: "Role",
      selector: (roles) => (
        <div>
          <h5 className="fs-14 mb-1 fw-semibold">
            {format(new Date(roles.createdOn), "dd MMM yyyy hh:mm a")}
          </h5>
          <h6 className="fs-12">{roles.createdByName}</h6>
        </div>
      ),
    },
    {
      name: "System Access",
      selector: (roles) => (
        <div onClick={() => handleRevokeEmployeeRole(roles.id)}>
          <Button variant="danger">
            <i className="ri-delete-bin-line fs-16 lh-1 align-middle me-1"></i>
            <span className="d-sm-inline align-middle">Revoke</span>
          </Button>
        </div>
      ),
    },
  ];
  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  const [activeTab, setActiveTab] = useState("edit_details_tab");
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (employeeDetail && employeeDetail.dateOfBirth) {
      setFormData({
        ...formData,
        id: employeeDetail.id,
        fullName: employeeDetail.fullName,
        email: employeeDetail.email,
        countryCode: "+91",
        contactNumber: employeeDetail.contactNumber,
        passport: employeeDetail.passport,
        govermentID: employeeDetail.govermentID,
        tenantId: employeeDetail.tenantId,
        status: employeeDetail.status,
        branchId: employeeDetail.branchId,
        roleId: employeeDetail.roleId,
        genderId: employeeDetail.genderId,
        isVisible: employeeDetail.isVisible,
        isDelete: employeeDetail.isDelete,
        createdBy: employeeDetail.createdBy,
        isSystemAccess: employeeDetail.isSystemAccess,
        isTrackAttendance: employeeDetail.isTrackAttendance,
        isOrderTake: employeeDetail.isOrderTake,
        salary: employeeDetail.salary,
        dateOfBirth: new Date(employeeDetail.dateOfBirth),
      });
    }
  }, [employeeDetail]);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  useEffect(() => {
    if (props.data) {
      dispatch(EmployeeDetail(props.data));
    }
  }, [props.data]);
  useEffect(() => {
    if (props.show) {
      setErrors({});
    }
  }, [props.show]);
  useEffect(() => {
    dispatch(loadBranch(user?.tenanatId));
  }, [dispatch, user?.tenanatId]);

  const checkDuplicateEmail = (name, value) => {
    const email = value.toLowerCase(); // Convert email to lowercase
    const isDuplicateEmail = customerList?.some(
      (user) =>
        user.username.toLowerCase() === email && user.id !== employeeDetail.id
    )
      ? customerList?.some(
          (user) =>
            user.username.toLowerCase() === email &&
            user.id !== employeeDetail.id
        )
      : employeeList?.some(
          (user) =>
            user.email.toLowerCase() === email && user.id !== employeeDetail.id
        );
    if (isDuplicateEmail) {
      setErrors({
        ...errors,
        [name]: "Email already exists",
      });
    }
    return isDuplicateEmail;
  };

  const validateInput = () => {
    const newErrors = {};
    newErrors.fullName = validateRequired(formData.fullName)
      ? ""
      : "Full Name is required";
    newErrors.contactNumber =
      formData?.contactNumber?.trim() !== ""
        ? ""
        : "Contact Number is required";
    newErrors.email = !validateRequired(formData.email)
      ? "Email is required"
      : !validateEmail(formData.email)
      ? "Invalid email format"
      : checkDuplicateEmail("email", formData.email)
      ? "Email already exists"
      : "";
    const age = formData.dateOfBirth ? calculateAge(formData.dateOfBirth) : 0;
    if (
      formData.dateOfBirth !==
      "Mon Jan 01 0001 00:00:00 GMT+0553 (India Standard Time)"
    ) {
      if (!formData.dateOfBirth || isNaN(formData.dateOfBirth.getTime())) {
        newErrors.dateOfBirth = "Date of Birth is required";
      } else if (age < 18) {
        newErrors.dateOfBirth = "You must be at least 18 years old";
      }
    }
    newErrors.status = validateRequiredDropdown(formData.status)
      ? ""
      : "Please Select Account Status";

    newErrors.roleId = validateRequiredDropdown(formData.roleId)
      ? ""
      : "Please Select User Role";
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };
  const maxSelectableYear = () => {
    const today = new Date();
    return today.getFullYear() - 18;
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (value?.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
      if (name === "email") {
        const isValidEmail = validateEmail(value);
        if (!isValidEmail) {
          setErrors({
            ...errors,
            [name]: "Invalid email format",
          });
          return;
        } else {
          checkDuplicateEmail(name, value);
        }
      }
    }
  };
  const handleDateChange = (date) => {
    setFormData({
      ...formData,
      dateOfBirth: date,
    });
    if (date) {
      setErrors({ ...errors, dateOfBirth: "" });
    }
  };
  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    const formObject = {
      id: formData.id,
      fullName: formData.fullName,
      email: formData.email,
      countryCode: formData.countryCode,
      contactNumber: formData.contactNumber,
      passport: formData.passport,
      govermentID: formData.govermentID,
      tenantId: formData.tenantId,
      genderId: formData.genderId,
      status: formData.status,
      branchId: formData.branchId,
      roleId: Number(formData.roleId),
      createdBy: formData.createdBy,
      isSystemAccess: formData.isSystemAccess,
      isTrackAttendance: formData.isTrackAttendance,
      dateOfBirth: formData.dateOfBirth
        ? format(formData.dateOfBirth, "yyyy-MM-dd")
        : "",
      salary: Number(formData.salary),
      isVisible: formData.isVisible,
      isOrderTake: formData.isOrderTake,
      isDelete: false,
    };
    dispatch(UpdateEmployee(formObject));
    handleClose();
  };
  const handleClose = () => {
    setFormData(initialState);
    setErrors({});
    props.closeFunction(false);
  };
  const handleDeleteEmployee = (id) => {
    dispatch(DeleteEmployee(id));
    handleClose();
  };
  const AssociateBranch = (branchIds) => {
    if (branchIds) {
      let branchMappingData = [];
      for (let i = 0; i < branchIds.length; i++) {
        const DataObject = {
          employeeId: employeeDetail.id,
          branchId: branchIds[i].value,
        };
        branchMappingData.push(DataObject);
      }
      dispatch(assignEmployeeBranch(branchMappingData));
    }
  };
  const DissociateBranch = (id) => {
    dispatch(deleteMappedEmpBranch(id));
  };
  const handleRevokeEmployeeRole = (id) => {
    dispatch(revokeEmployeeRole(id));
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Manage Employee
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Card className="card-one h-auto mb-4">
            <Card.Body>
              <Row>
                <Col xl="8">
                  <div>
                    <h3 className="fs-16 text-dark fw-semibold">
                      {employeeDetail?.fullName}
                    </h3>
                    <h6 className="fs-14 mb-0 text-dark">
                      {employeeDetail?.roleName}
                    </h6>
                  </div>
                </Col>
                <Col xl="4">
                  <div className="text-right">
                    <Button
                      variant="danger"
                      className="gap-1"
                      onClick={() => handleDeleteEmployee(employeeDetail.id)}
                    >
                      <i className="ri-delete-bin-line fs-18 lh-1 align-middle me-1"></i>
                      <span className="d-sm-inline align-middle">
                        Delete User
                      </span>
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {loading &&
            (value === "EmployeeDetail" ||
              value === "deleteMappedEmpBranch" ||
              value === "revokeEmployeeRole" ||
              value === "assignEmployeeBranch") && <Loader />}

          <Tabs
            id="custom-styled-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="sale-on-point-tabs"
          >
            <Tab
              eventKey="edit_details_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Edit Details</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3 pb-5">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                  <div>
                    <h4 className="main-title fs-16 mb-0">Edit Details</h4>
                  </div>
                </div>
                <Row>
                  <Col xl={8} md={12} sm={12}>
                    <div className="mb-4">
                      <Form.Label>
                        Account Status: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="status"
                        onChange={handleChange}
                        value={formData.status}
                      >
                        <option value={Environment.defaultValue} disabled>
                          Choose status
                        </option>
                        {statusCommonList.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.fieldValue}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Text className="text-danger">
                        {errors.status}
                      </Form.Text>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={8} md={12} sm={12}>
                    <div className="mb-4">
                      <Form.Label>
                        System Role: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="roleId"
                        onChange={handleChange}
                        value={formData.roleId}
                      >
                        <option value={Environment.defaultValue}>
                          Choose Role
                        </option>
                        {props?.UserRolesList.map((user, index) => (
                          <option value={user.roleId} key={index}>
                            {user.roleName}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Text className="text-danger">
                        {errors.roleId}
                      </Form.Text>
                    </div>
                  </Col>
                </Row>
                <div className="divider divider-start">
                  <span className="text-dark ">Basic Details</span>
                </div>
                <Row>
                  <Col xl={6} md={10} sm={12}>
                    <div className="mb-4">
                      <Form.Label>
                        Employee Name: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Employee Name"
                        name="fullName"
                        value={formData.fullName}
                        onChange={handleChange}
                      />
                      <Form.Text className="text-danger">
                        {errors.fullName}
                      </Form.Text>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} md={10} sm={12}>
                    <div className="mb-4">
                      <Form.Label>
                        Email Address: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email Address"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        onKeyDown={(e) => {
                          restrictSpace(e);
                          restrictEmailCharacters(e);
                        }}
                      />
                      <Form.Text className="text-danger">
                        {errors.email}
                      </Form.Text>
                    </div>
                  </Col>
                  <Col xl={6} md={10} sm={12}>
                    <div className="mb-4">
                      <Form.Label>
                        Phone Number: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Group
                        controlId="formFile"
                        className="position-relative overflow-hidden"
                      >
                        <Form.Label className="custom-label mb-0">
                          +91
                        </Form.Label>
                        <Form.Control
                          type="number"
                          min="1"
                          className="tax-percentage-input"
                          style={{ paddingLeft: "50px" }}
                          placeholder="Enter Phone Number"
                          name="contactNumber"
                          value={formData.contactNumber}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <Form.Text className="text-danger">
                        {errors.contactNumber}
                      </Form.Text>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Date Of Birth</Form.Label>
                      <DatePicker
                        name="dateOfBirth"
                        selected={formData.dateOfBirth}
                        onChange={handleDateChange}
                        dateFormat="yyyy-MM-dd"
                        placeholderText="Select Date of Birth"
                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={80}
                        maxDate={new Date(maxSelectableYear(), 11, 31)}
                        className={`form-control`}
                      />
                      <Form.Text className="text-danger">
                        {errors.dateOfBirth}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col xl={6} md={10} sm={12}>
                    <div className="mb-4">
                      <Form.Label>Choose Gender:</Form.Label>
                      <Form.Select
                        name="genderId"
                        onChange={handleChange}
                        value={formData.genderId}
                      >
                        <option value={Environment.defaultValue} disabled>
                          Choose Gender
                        </option>
                        {genderCommonList.map((option, index) => (
                          <option key={index} value={option.id}>
                            {option.fieldValue}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Text className="text-danger">
                        {errors.genderId}
                      </Form.Text>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl={6} md={10} sm={12}>
                    <div className="mb-4">
                      <Form.Label>Adhaar/Passport Number: </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Adhaar / Passport Number"
                        name="passport"
                        onChange={handleChange}
                        onKeyDown={restrictConsecutiveSpace}
                        value={formData?.passport}
                      />
                      <Form.Text className="text-danger">
                        {errors.passport}
                      </Form.Text>
                    </div>
                  </Col>
                  <Col xl={6} md={10} sm={12}>
                    <div className="mb-4">
                      <Form.Label>
                        Govt. ID/PANCard Number:{" "}
                        <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Govt. ID / PANCard Number"
                        name="govermentID"
                        onChange={handleChange}
                        onKeyDown={restrictConsecutiveSpace}
                        value={formData?.govermentID}
                      />
                      <Form.Text className="text-danger">
                        {errors.govermentID}
                      </Form.Text>
                    </div>
                  </Col>
                </Row>
                <div className="divider divider-start">
                  <span className="text-dark ">Other Details</span>
                </div>

                <Row>
                  <Col xl={6} md={10} sm={12}>
                    <div className="mb-4">
                      <Form.Label>Monthly Salary</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Monthly Salary"
                        value={formData.salary}
                        name="salary"
                        onChange={handleChange}
                      />
                    </div>
                  </Col>
                </Row>

                <Row className="g-3">
                  <Col sm={12}>
                    <div className="common-switch-toggle mb-4">
                      <Form.Check
                        type="switch"
                        className="fs-14 fw-semibold text-dark"
                        label="System Login Enabled"
                        name="isSystemAccess"
                        checked={formData.isSystemAccess}
                        onChange={handleToggle}
                      />
                    </div>
                    <div className="common-switch-toggle mb-4">
                      <Form.Check
                        type="switch"
                        className="fs-14 fw-semibold text-dark"
                        label="Is OrderTake"
                        name="isOrderTake"
                        checked={formData.isOrderTake}
                        onChange={handleToggle}
                      />
                    </div>
                    <div className="common-switch-toggle mb-5">
                      <Form.Check
                        type="switch"
                        className="fs-14 fw-semibold text-dark"
                        label="Track Attendance and Salary"
                        checked={formData.isTrackAttendance}
                        name="isTrackAttendance"
                        onChange={handleToggle}
                      />
                    </div>
                  </Col>
                </Row>

                <div className="offcanvas-footer justify-content-start position-absolute bg-white">
                  <Button
                    variant="primary"
                    className="fs-14 me-2 d-flex align-items-center"
                    onClick={handleSubmit}
                  >
                    <span className="align-middle"> Update Employee</span>
                  </Button>
                </div>
              </div>
            </Tab>

            <Tab
              eventKey="role_assigned_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Role Assigned</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                  <div>
                    <h4 className="main-title fs-16 mb-0">Role Assigned</h4>
                  </div>
                </div>
                <Card className="card-one">
                  <Card.Body className="custom-common-table">
                    {loading && value === "revokeEmployeeRole" ? (
                      <CardShimmerLoader columnCount={3} rowCount={4} />
                    ) : (
                      <DataTable
                        columns={columns}
                        data={employeeDetail?.roles}
                        fixedHeader
                        search={true}
                        highlightOnHover
                        pagination
                      ></DataTable>
                    )}
                  </Card.Body>
                </Card>
              </div>
            </Tab>

            <Tab
              eventKey="mapped_location_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Mapped Location</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <MappedLocation
                  BranchData={BranchOptions}
                  loading={loading}
                  AssociateBranch={AssociateBranch}
                  DissociateBranch={DissociateBranch}
                  Data={employeeDetail?.branches}
                />
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
