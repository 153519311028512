import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { CreateEmployee } from "../../../application/actions/employeeAction";
import { useDispatch, useSelector } from "react-redux";
import {
  restrictAllSpecialCharacters,
  restrictConsecutiveSpace,
  restrictEmailCharacters,
  restrictOnlyNumber,
  restrictSpace,
  validateEmail,
  validateRequired,
  validateRequiredDropdown,
} from "../../../infrastructure/core/validationUtils";
import Environment from "../../../infrastructure/core/Environment";
import {
  getCustomerList,
  getEmployeeList,
} from "../../../application/selectors/indexSelector";

export default function AddNewEmployee(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const { user } = useContext(AuthContext);
  const dispatch = useDispatch();
  const employeeList = useSelector(getEmployeeList);
  const customerList = useSelector(getCustomerList);
  const initialState = {
    fullName: "",
    email: "",
    username: "",
    countryCode: "+91",
    contactNumber: "",
    defaultPassword: "Pass@123",
    passport: "",
    govermentID: "",
    tenantId: user?.tenanatId,
    genderId: Environment.defaultValue,
    status: Environment.defaultValue,
    branchId: props.branchId,
    roleId: Environment.defaultValue,
    createdBy: user.id,
    isSystemAccess: false,
    isTrackAttendance: false,
    isOrderTake: false,
    dateOfBirth: "",
    salary: 0,
  };
  const [formData, setFormData] = useState(initialState);
  const genderCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "GEN"
      )
    : [];
  const statusCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "STS"
      )
    : [];
  // Calculate minimum selectable year based on user's minimum age requirement
  const maxSelectableYear = () => {
    const today = new Date();
    return today.getFullYear() - 18;
  };
  const [errors, setErrors] = useState({});
  const checkDuplicateEmail = (name, value) => {
    const email = value.toLowerCase(); // Convert email to lowercase
    const isDuplicateEmail = customerList?.some(
      (user) => user.username.toLowerCase() === email
    )
      ? customerList?.some((user) => user.username.toLowerCase() === email)
      : employeeList?.some((user) => user.email.toLowerCase() === email);
    if (isDuplicateEmail) {
      setErrors({
        ...errors,
        [name]: "Email already exists",
      });
    }
    return isDuplicateEmail;
  };
  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };
  const validateInput = () => {
    const newErrors = {};
    newErrors.fullName = validateRequired(formData.fullName)
      ? ""
      : "Full Name is required";
    newErrors.contactNumber =
      formData?.contactNumber?.trim() !== ""
        ? ""
        : "Contact Number is required";
    newErrors.email = !validateRequired(formData.email)
      ? "Email is required"
      : !validateEmail(formData.email)
      ? "Invalid email format"
      : checkDuplicateEmail("email", formData.email)
      ? "Email already exists"
      : "";
    const age = formData.dateOfBirth ? calculateAge(formData.dateOfBirth) : 0;
    if (
      formData.dateOfBirth !==
      "Mon Jan 01 0001 00:00:00 GMT+0553 (India Standard Time)"
    ) {
      if (!formData.dateOfBirth || isNaN(formData.dateOfBirth.getTime())) {
        newErrors.dateOfBirth = "Date of Birth is required";
      } else if (age < 18) {
        newErrors.dateOfBirth = "You must be at least 18 years old";
      }
    }
    newErrors.status = validateRequiredDropdown(formData.status)
      ? ""
      : "Please Select Account Status";

    newErrors.roleId = validateRequiredDropdown(formData.roleId)
      ? ""
      : "Please Select User Role";
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (value?.trim() !== "") {
      setErrors({ ...errors, [name]: "" });
      // Email format validation
      if (name === "email") {
        const isValidEmail = validateEmail(value);
        if (!isValidEmail) {
          setErrors({
            ...errors,
            [name]: "Invalid email format",
          });
          return;
        } else {
          checkDuplicateEmail(name, value);
        }
      }
    }
  };

  const handleDateChange = (e) => {
    setFormData({ ...formData, dateOfBirth: e });
    setErrors({ ...errors, dateOfBirth: "" });
  };
  const handleToggle = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateInput();
    if (!isValid) {
      return;
    }
    const formObject = {
      fullName: formData.fullName,
      email: formData.email,
      username: formData.email,
      countryCode: "+91",
      contactNumber: formData.contactNumber,
      defaultPassword: "Pass@123",
      passport: formData.passport,
      govermentID: formData.govermentID,
      tenantId: user?.tenanatId,
      genderId: formData.genderId,
      status: formData.status,
      branchId: props.branchId,
      roleId: formData.roleId,
      createdBy: user.id,
      isSystemAccess: formData.isSystemAccess,
      isTrackAttendance: formData.isTrackAttendance,
      dateOfBirth: formData.dateOfBirth.toLocaleDateString("en-CA"),
      salary: Number(formData.salary),
      isOrderTake: formData.isOrderTake,
    };
    dispatch(CreateEmployee(formObject));
    handleClose();
  };
  const handleClose = () => {
    setFormData(initialState);
    setErrors({});
    props.closeFunction(false);
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Add New Employee
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={8} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Account Status: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="status"
                  onChange={handleChange}
                  value={formData.status}
                >
                  <option value={Environment.defaultValue} disabled>
                    Choose status
                  </option>
                  {statusCommonList.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">{errors.status}</Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={8} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  System Role: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="roleId"
                  onChange={handleChange}
                  value={formData.roleId}
                >
                  <option value={Environment.defaultValue}>Choose Role</option>
                  {props?.UserRolesList.map((user, index) => (
                    <option value={user.roleId} key={index}>
                      {user.roleName}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">{errors.roleId}</Form.Text>
              </div>
            </Col>
          </Row>

          <div className="divider divider-start">
            <span className="text-dark ">Basic Details</span>
          </div>
          <Row>
            <Col xl={6} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Employee Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Employee Name"
                  name="fullName"
                  id="fullName"
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.fullName}</Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Email Address: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email Address"
                  name="email"
                  id="email"
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictSpace(e);
                    restrictEmailCharacters(e);
                  }}
                />
                <Form.Text className="text-danger">{errors.email}</Form.Text>
              </div>
            </Col>

            <Col xl={6} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>
                  Phone Number: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Group
                  controlId="number"
                  className="position-relative overflow-hidden"
                >
                  <Form.Label className="custom-label mb-0">+91</Form.Label>
                  <Form.Control
                    // type="number"
                    // min="1"
                    className="tax-percentage-input"
                    style={{ paddingLeft: "50px" }}
                    placeholder="Enter Phone Number"
                    name="contactNumber"
                    onChange={handleChange}
                    onKeyDown={restrictOnlyNumber}
                  />
                </Form.Group>
                <Form.Text className="text-danger">
                  {errors.contactNumber}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Date Of Birth</Form.Label>
                <DatePicker
                  name="dateOfBirth"
                  selected={formData.dateOfBirth}
                  onChange={handleDateChange}
                  dateFormat="yyyy-MM-dd"
                  placeholderText="Select Date of Birth"
                  showYearDropdown
                  scrollableYearDropdown
                  yearDropdownItemNumber={80}
                  maxDate={new Date(maxSelectableYear(), 11, 31)}
                  className={`form-control`}
                />
                <Form.Text className="text-danger">
                  {errors.dateOfBirth}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col xl={6} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Choose Gender:</Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="genderId"
                  onChange={handleChange}
                  value={formData.genderId}
                >
                  <option value={Environment.defaultValue} disabled>
                    Choose Gender
                  </option>
                  {genderCommonList.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                {/* <Form.Text className="text-danger">{errors.genderId}</Form.Text> */}
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Adhaar/Passport Number:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Adhaar / Passport Number"
                  name="passport"
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                {/* <Form.Text className="text-danger">{errors.passport}</Form.Text> */}
              </div>
            </Col>
            <Col xl={6} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Govt. ID/PANCard Number:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Govt. ID / PANCard Number"
                  name="govermentID"
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                  }}
                />
                {/* <Form.Text className="text-danger">
                  {errors.govermentID}
                </Form.Text> */}
              </div>
            </Col>
          </Row>

          <div className="divider divider-start">
            <span className="text-dark ">Other Details</span>
          </div>

          <Row>
            <Col xl={6} md={10} sm={12}>
              <div className="mb-4">
                <Form.Label>Monthly Salary</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Monthly Salary"
                  name="salary"
                  onChange={handleChange}
                  onKeyDown={restrictConsecutiveSpace}
                />
              </div>
            </Col>
          </Row>

          <Row className="g-3">
            <Col sm={12}>
              <div className="common-switch-toggle mb-4">
                <Form.Check
                  type="switch"
                  className="fs-14 fw-semibold text-dark"
                  label="System Login Enabled"
                  checked={formData.isSystemAccess}
                  name="isSystemAccess"
                  onChange={handleToggle}
                />
              </div>

              <div className="common-switch-toggle mb-4">
                <Form.Check
                  type="switch"
                  className="fs-14 fw-semibold text-dark"
                  label="Is OrderTake"
                  checked={formData.isOrderTake}
                  name="isOrderTake"
                  onChange={handleToggle}
                />
              </div>

              <div className="common-switch-toggle mb-5">
                <Form.Check
                  type="switch"
                  className="fs-14 fw-semibold text-dark"
                  label="Track Attendance and Salary"
                  checked={formData.isTrackAttendance}
                  name="isTrackAttendance"
                  onChange={handleToggle}
                />
              </div>
            </Col>
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create New Employee</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
