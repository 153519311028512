import { KOTREMARK_LIST_SUCCESS } from '../actions/KOTAction';
const initialState = {
    KOTRemarkList:[],
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case KOTREMARK_LIST_SUCCESS:
            return { ...state ,KOTRemarkList: action.payload.result, error: null}
        default:            
            return state;
    }
}

export default reducer;