export const LOAD_TODOS = '[todos] load';
export const CATEGORY_LIST = 'CATEGORY_LIST1';
export const SUB_CATEGORY_LIST = 'SUB_CATEGORY_LIST';
export const CATEGORY_SUCCESS = 'CATEGORY_SUCCESS';
export const SUB_CATEGORY_SUCCESS = 'SUB_CATEGORY_SUCCESS';
export const LIVE_TABLES = 'LIVE_TABLES';
export const LIVE_TABLE_SUCCESS = 'LIVE_TABLE_SUCCESS';
export const TAKEAWAY_TABLE = 'TAKEAWAY_TABLE';
export const HOME_DELIVERY = 'HOME_DELIVERY';
export const PRE_ORDERS = 'PRE_ORDERS';
export const ON_HOLD_ORDERS = 'ON_HOLD_ORDERS';
export const TKYORDER_SUCCESS = 'TKYORDER_SUCCESS';
export const HMORDER_SUCCESS = 'HMORDER_SUCCESS';
export const PRE_ORDER_SUCCESS = 'PRE_ORDER_SUCCESS';
export const HOLD_ORDER_SUCCESS = 'HOLD_ORDER_SUCCESS';
export const PRODUCT_BY_CATEGORYID = 'PRODUCT_BY_CATEGORYID';
export const PROD_CATEGORY_SUCCESS = 'PROD_CATEGORY_SUCCESS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';

export const loadTodos = {
    type: LOAD_TODOS,
};
export const getAllCategoryData =(data)=> ({
    type: CATEGORY_LIST,
    payload :{data}
});
export const getSubCategoryList =(data)=> ({
    type: SUB_CATEGORY_LIST,
    payload :{data}
});
export const getProductByCategoryId =(data)=> ({
    type: PRODUCT_BY_CATEGORYID,
    payload :{data}
});
export const loadCategoryData=(data)=>({
    type:CATEGORY_SUCCESS,
    payload:{data}
})
export const loadProdByCatIdData=(data)=>({
    type:PROD_CATEGORY_SUCCESS,
    payload:{data}
})
export const loadSubCategoryData=(data)=>({
    type:SUB_CATEGORY_SUCCESS,
    payload:{data}
})
export const getAllTables=(data)=>({
    type:LIVE_TABLES,
    payload:{data}
})
export const loadTableData=(data)=>({
    type:LIVE_TABLE_SUCCESS,
    payload:data
})
export const getTakeawayTables=(data)=>({
    type:TAKEAWAY_TABLE,
    payload:{data}
})
export const loadOrderData=(data)=>({
    type:TKYORDER_SUCCESS,
    payload:{data}
})
export const getHomeDeliveryOrders=(data)=>({
    type:HOME_DELIVERY,
    payload:{data}
})
export const loadHMOrderData=(data)=>({
    type:HMORDER_SUCCESS,
    payload:{data}
})
export const getPreOrders=(data)=>({
    type:PRE_ORDERS,
    payload:{data}
})
export const loadPreOrderData=(data)=>({
    type:PRE_ORDER_SUCCESS,
    payload:{data}
})
export const getOnHoldOrders=(data)=>({
    type:ON_HOLD_ORDERS,
    payload:{data}
})
export const loadOnHoldOrderData=(data)=>({
    type:HOLD_ORDER_SUCCESS,
    payload:{data}
})
export const AddToCart=(data)=>({
    type:ADD_TO_CART,
    payload:{data}
})
export const AddedToCartSuccess=(data)=>({
    type:ADD_TO_CART_SUCCESS,
    payload:{data}
})