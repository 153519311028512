import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CreateKitchen } from "../../../../application/actions/kitchenAction";

export default function AddNewKitchen(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();

  const data = {
    kitchenName: "",
    kitchenDescription: "",
    isVisible: true,
    branchId: props?.branch?.id,
  };
  const [formData, setFormData] = useState(data);
  const handelChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    let valid = true;
    let errors = {};

    if (!formData.kitchenName.trim()) {
      errors.kitchenName = "Kitchen Name is required";
      valid = false;
    }

    if (!formData.isVisible) {
      errors.isVisible = "IsVisible is required";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    dispatch(CreateKitchen(formData));
    setFormData(data);
    props.closeFunction();
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-45"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>Add Kitchen</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Form>
            <Row>
              <Col xl="8">
                <div className="mb-4">
                  <Form.Label>
                    Kitchen Name: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter Kitchen Name"
                    name="kitchenName"
                    value={formData.kitchenName}
                    onChange={handelChange}
                  />
                   {errors.kitchenName && (
              <div className="text-danger">{errors.kitchenName}</div>
            )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="12">
                <div className="mb-4">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    placeholder="Enter Description"
                    name="kitchenDescription"
                    value={formData.kitchenDescription}
                    onChange={handelChange}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl="6">
                <div className="mb-4">
                  <Form.Label>
                    IsVisible: <sup className="text-danger">*</sup>
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    name="isVisible"
                    value={formData.isVisible}
                    onChange={handelChange}
                  >
                    <option value="1">Active</option>
                    <option value="2">InActive</option>
                  </Form.Select>
                  {errors.isVisible && (
              <div className="text-danger">{errors.isVisible}</div>
            )}
                </div>
              </Col>
            </Row>
          </Form>
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button variant="primary" className="fs-14" onClick={onSubmit}>
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create New Kitchen</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
