import React, { useState } from "react";
import { Row, Col, Card, Table, ProgressBar, Tab, ButtonGroup} from "react-bootstrap";
//import WordCloud from 'react-wordcloud';
import ReactApexChart from "react-apexcharts";
import dishIcon from "../../../assets/img/icons/google-review/dish.png";
import waiterIcon from "../../../assets/img/icons/google-review/waiter.png";
import ambienceIcon from "../../../assets/img/icons/google-review/ambience-table.png";
import { FoodSentimentlist, ServiceSentimentlist, AmbianceSentimentlist } from "../../../Json/google-reviews/SentimentReport/sentiment-report";
import { CustomerSatisfactionlist, negetiveRating, positiveRating, staffreviewslist, foodReviewlist } from "../../../Json/google-reviews/CSATAnalysis/CSAT-analysis";

export default function SentimentAnalytics() {
    const [selectedOption, setSelectedOption] = useState('last_7_days');

    const handleChange = (e) => {
        setSelectedOption(e.target.id);
    };

    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };

    const seriesFour = [{
        data: [485, 444, 496, 478, 412, 550, 478, 420, 466, 490, 478, 400, 410, 469, 300, 298, 540, 578, 357, 254, 566, 255, 211, 159, 568, 265, 555, 578, 598, 547, 492]
    }];

    const optionFour = {
        chart: {
            stacked: true,
            toolbar: { show: false }
        },
        grid: {
            borderColor: 'rgba(72,94,144, 0.07)',
            padding: {
                top: 0,
                left: 5
            }
        },
        states: states,
        colors: ['#506fd9', '#fff', '#85b6fe'],
        plotOptions: {
            bar: { columnWidth: '50%' },
        },
        stroke: {
            curve: 'straight',
            lineCap: 'square',
            width: 0
        },
        xaxis: {
            type: 'numeric',
            tickAmount: 4,
            decimalsInFloat: 0,
            labels: {
                show: false,
                style: {
                    fontSize: '12px'
                }
            },
        },
        yaxis: {
            max: 800,
            tickAmount: 5,
            labels: {
                style: {
                    colors: ['#a2abb5'],
                    fontSize: '11px'
                }
            }
        },
        dataLabels: { enabled: false },
        tooltip: { enabled: true },
        fill: { opacity: 1 },
        legend: { show: false }
    };

    // NEGETIVE SENTIMENTS CHART
    const negetive_sentiments_chart = [{
        data: negetiveRating
    }];

    // NEGETIVE SENTIMENTS OPTION
    const negetive_sentiments_option = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false },
            stacked: true,
            sparkline: { enabled: true }
        },
        colors: ['#dc3545'],
        stroke: {
            curve: 'straight',
            width: 2
        },
        xaxis: { max: 30 },
        yaxis: {
            min: 0,
            max: 80,
            labels: {
                show: false, // Hide x-axis labels
            },
        },
        fill: {
            type: 'solid',
            opacity: 0.2
        },
        tooltip: { enabled: false }
    }

    // POSITIVE SENTIMENTS CHART
    const positive_sentiments_chart = [{
        data: positiveRating
    }];

    // POSITIVE SENTIMENTS OPTION
    const positive_sentiments_option = {
        chart: {
            parentHeightOffset: 0,
            toolbar: { show: false },
            stacked: true,
            sparkline: { enabled: true }
        },
        colors: ['#0cb785'],
        stroke: {
            curve: 'straight',
            width: 2
        },
        xaxis: { max: 30 },
        yaxis: {
            min: 0,
            max: 80,
            labels: {
                show: false, // Hide x-axis labels
            },
        },
        fill: {
            type: 'solid',
            opacity: 0.2
        },
        tooltip: { enabled: false }
    }

    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <div>
                    <h4 className="main-title fs-14 mb-0">Sentiment Analytics</h4>
                </div>
            </div>

            {/* SENTIMENT ANALYSIS PAGES ALL DATA */}
            <Row className="g-3">
                {/* OVERALL RATING */}
                <Col xl={4} md={5} sm={12}>
                    <Card className="card-one">
                        <Card.Header>
                            <Card.Title as="h6">Overall Rating</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <div className="d-flex align-items-baseline gap-2 mb-0">
                                <h1 className="card-value mb-0">4.8</h1>
                                <div className="d-flex gap-1 text-warning fs-20">
                                    <i className="ri-star-fill"></i>
                                    <i className="ri-star-fill"></i>
                                    <i className="ri-star-fill"></i>
                                    <i className="ri-star-fill"></i>
                                    <i className="ri-star-half-fill"></i>
                                </div>
                            </div>
                            <p className="fs-sm">Measures the quality or your support team's efforts.</p>

                            <Table className="table-ratings mb-0">
                                <tbody>
                                    {[
                                        {
                                            "rating": "5.0",
                                            "star": ["fill", "fill", "fill", "fill", "fill"],
                                            "count": "4,230",
                                            "percent": "58%"
                                        }, {
                                            "rating": "4.0",
                                            "star": ["fill", "fill", "fill", "fill", "line"],
                                            "count": "1,416",
                                            "percent": "26%"
                                        }, {
                                            "rating": "3.0",
                                            "star": ["fill", "fill", "fill", "line", "line"],
                                            "count": "980",
                                            "percent": "16%"
                                        }, {
                                            "rating": "2.0",
                                            "star": ["fill", "fill", "line", "line", "line"],
                                            "count": "798",
                                            "percent": "12%"
                                        }, {
                                            "rating": "1.0",
                                            "star": ["fill", "line", "line", "line", "line"],
                                            "count": "401",
                                            "percent": "8%"
                                        }
                                    ].map((item, index) => (
                                        <tr key={index}>
                                            <td><strong>{item.rating}</strong></td>
                                            <td>
                                                <div className="d-flex gap-1 text-warning fs-16">
                                                    {item.star.map((star, ind) => (
                                                        <i key={ind} className={"ri-star-" + star}></i>
                                                    ))}
                                                </div>
                                            </td>
                                            <td>{item.count}</td>
                                            <td>{item.percent}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>

                {/* REVIEWS LAST 30 DAYS */}
                <Col xl={8} md={7} sm={12}>
                    <Card className="card-one">
                        <Card.Header>
                            <Card.Title as="h6">Reviews Last 30 Days</Card.Title>
                        </Card.Header>
                        <Card.Body className="mb-0">
                            <ReactApexChart series={seriesFour} options={optionFour} type="bar" height={250} className="apex-chart-nine" />
                        </Card.Body>
                    </Card>
                </Col>

                <Tab.Container defaultActiveKey="last_7_days_tab">
                    {/* SENTIMENT REPORT BY INDICATORS */}
                    <Col md={12} className="mt-5 mb-3">
                        <Row className="g-3 align-items-center">
                            <Col xl={5} md={4} sm={12}>
                                <h3 className="fs-14">Sentiment Report by Indicators</h3>
                            </Col>
                            <Col xl={7} md={8} sm={12}>
                                <div className="sentiment-reports-tab text-end tab-text-left">
                                    <ButtonGroup>
                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="btnradio"
                                            id="last_7_days"
                                            autoComplete="off"
                                            checked={selectedOption === 'last_7_days'}
                                            onChange={handleChange}
                                        />
                                        <label className="btn btn-outline-primary fs-12" htmlFor="last_7_days">
                                            Last 7 Days
                                        </label>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="btnradio"
                                            id="last_30_days"
                                            autoComplete="off"
                                            checked={selectedOption === 'last_30_days'}
                                            onChange={handleChange}
                                        />
                                        <label className="btn btn-outline-primary fs-12" htmlFor="last_30_days">
                                            Last 30 Days
                                        </label>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="btnradio"
                                            id="last_3_months"
                                            autoComplete="off"
                                            checked={selectedOption === 'last_3_months'}
                                            onChange={handleChange}
                                        />
                                        <label className="btn btn-outline-primary fs-12" htmlFor="last_3_months">
                                            Last 3 Months
                                        </label>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="btnradio"
                                            id="last_6_month"
                                            autoComplete="off"
                                            checked={selectedOption === 'last_6_month'}
                                            onChange={handleChange}
                                        />
                                        <label className="btn btn-outline-primary fs-12" htmlFor="last_6_month">
                                            Last 6 Months
                                        </label>

                                        <input
                                            type="radio"
                                            className="btn-check"
                                            name="btnradio"
                                            id="lastyear"
                                            autoComplete="off"
                                            checked={selectedOption === 'lastyear'}
                                            onChange={handleChange}
                                        />
                                        <label className="btn btn-outline-primary fs-12" htmlFor="lastyear">
                                            Last 1 Year
                                        </label>
                                    </ButtonGroup>
                                </div>
                            </Col>
                        </Row>
                    </Col>

                    {/* FOOD SENTIMENT ANALYSIS */}
                    <Col lg={4} md={4} sm={12}>
                        <Card className="card-one">
                            <Card.Header as="h6" className="fw-semibold">
                                <img src={dishIcon} alt="waiter" className="me-2" /> Food Sentiment Analysis
                            </Card.Header>
                            <Card.Body>
                                <h2 className="fs-40 text-dark mb-4">350 <small className="fs-12 text-dark">reviews</small></h2>
                                {FoodSentimentlist.map((item, index) => (
                                    <div className="d-flex align-items-center mb-2" key={index}>
                                        <div className="w-40 fs-12 fw-bold fw-bold">{item.name}</div>
                                        <div className="w-60">
                                            <ProgressBar className="w-100 ht-15 rounded-0 progress-bar-class">
                                                {item.progressData.map((val, index) => (
                                                    <ProgressBar
                                                        key={index}
                                                        now={val.percentage}
                                                        variant={val.variant}
                                                        label={`${val.percentage}%`}
                                                        style={{ color: 'white' }}
                                                    />
                                                ))
                                                }
                                            </ProgressBar>
                                        </div>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* SERVICE SENTIMENT ANALYSIS */}
                    <Col lg={4} md={4} sm={12}>
                        <Card className="card-one">
                            <Card.Header as="h6" className="fw-semibold">
                                <img src={waiterIcon} alt="waiter" className="align-middle me-2" /> Service Sentiment Analysis
                            </Card.Header>
                            <Card.Body>
                                <h2 className="fs-40 text-dark mb-4">601 <small className="fs-12 text-dark">reviews</small></h2>
                                {ServiceSentimentlist.map((item, index) => (
                                    <div className="d-flex align-items-center mb-2" key={index}>
                                        <div className="w-40 fs-12 fw-bold fw-bold">{item.name}</div>
                                        <div className="w-60">
                                            <ProgressBar className="w-100 ht-15 rounded-0">
                                                {item.progressData.map((val, index) => (
                                                    <ProgressBar
                                                        key={index}
                                                        now={val.percentage}
                                                        variant={val.variant}
                                                        label={`${val.percentage}%`}
                                                        style={{ color: 'white' }}
                                                    />
                                                ))
                                                }
                                            </ProgressBar>
                                        </div>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* AMBIANCE SENTIMENT ANALYSIS */}
                    <Col lg={4} md={4} sm={12}>
                        <Card className="card-one">
                            <Card.Header as="h6" className="fw-semibold">
                                <img src={ambienceIcon} alt="waiter" className="me-2" /> Ambiance Sentiment Analysis
                            </Card.Header>
                            <Card.Body>
                                <h2 className="fs-40 text-dark mb-4">204 <small className="fs-12 text-dark">of 1304 reviews</small></h2>
                                {AmbianceSentimentlist.map((item, index) => (
                                    <div className="d-flex align-items-center mb-2" key={index}>
                                        <div className="w-40 fs-12 fw-bold">{item.name}</div>
                                        <div className="w-60">
                                            <ProgressBar className="w-100 ht-15 rounded-0">
                                                {item.progressData.map((val, index) => (
                                                    <ProgressBar
                                                        key={index}
                                                        now={val.percentage}
                                                        variant={val.variant}
                                                        label={`${val.percentage}%`}
                                                        style={{ color: 'white' }}
                                                    />
                                                ))
                                                }
                                            </ProgressBar>
                                        </div>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* CSAT ANALYSIS HEADER */}
                    <Col md={12} className="mt-5 mb-3">
                        <h3 className="fs-14">CSAT Analysis</h3>
                    </Col>

                    {/* CUSTOMER SATISFACTION TO REMOTE */}
                    <Col lg={4} md={4} sm={12}>
                        <Card className="card-one">
                            <Card.Header as="h6">Customer Satisfaction to remote</Card.Header>
                            <Card.Body>
                                <h2 className="fs-40 text-dark mb-4">60% <small className="fs-12 text-dark">of Customer willing to promote</small></h2>
                                {CustomerSatisfactionlist.map((item, index) => (
                                    <div className="d-flex align-items-center mb-2" key={index}>
                                        <div className="w-40 fs-12 fw-bold">{item.name}</div>
                                        <div className="w-60">
                                            <ProgressBar className="w-100 ht-15 rounded-0">
                                                {item.progressData.map((val, index) => (
                                                    <ProgressBar
                                                        key={index}
                                                        now={val.percentage}
                                                        variant={val.variant}
                                                        label={`${val.percentage}%`}
                                                        style={{ color: 'white' }}
                                                    />
                                                ))
                                                }
                                            </ProgressBar>
                                        </div>
                                    </div>
                                ))}
                            </Card.Body>
                        </Card>
                    </Col>


                    {/* <Col lg={4} md={4} sm={12}>
                        <Card className="card-one  h-auto">
                            <Card.Header as="h6">Keyword for Negative Sentiments</Card.Header>
                            <Card.Body>
                                <WordCloud words={words} options={options} callbacks={callbacks} />
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col lg={4} md={4} sm={12}>
                        <Card className="card-one">
                            <Card.Header as="h6">Keyword for Positive Sentiments</Card.Header>
                            <Card.Body>
                                <WordCloud words={words} options={options} callbacks={callbacks} />
                            </Card.Body>
                        </Card>
                    </Col> */}


                    {/* MEGETIVE SENTIMENT LAST 60 DAYS */}
                    <Col lg={4} md={4} sm={12}>
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Negetive Sentiments (Last 60 Days)</Card.Title>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <div className="position-absolute p-4">
                                    <h1 className="card-value d-flex align-items-baseline mb-0">268</h1>
                                    <p className="fs-xs mb-2"><span className="text-danger fs-numerals">0.3%</span> higher than last month</p>
                                    <p className="fs-sm">The total number of negetive feedbacks received.</p>
                                </div>
                                <ReactApexChart series={negetive_sentiments_chart} options={negetive_sentiments_option} type="area" height={280} />
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* POSITIVE SENTIMENTS LAST 60 DAYS */}
                    <Col lg={4} md={4} sm={12}>
                        <Card className="card-one">
                            <Card.Header>
                                <Card.Title as="h6">Positive Sentiments (Last 60 Days)</Card.Title>
                            </Card.Header>
                            <Card.Body className="p-0">
                                <div className="position-absolute p-4">
                                    <h1 className="card-value d-flex align-items-baseline mb-0">340</h1>
                                    <p className="fs-xs mb-2"><span className="text-success fs-numerals">0.3%</span> higher than last month</p>
                                    <p className="fs-sm">The total number of negetive feedbacks received.</p>
                                </div>
                                <ReactApexChart series={positive_sentiments_chart} options={positive_sentiments_option} type="area" height={280} />
                            </Card.Body>
                        </Card>
                    </Col>


                    {/* CSAT ANALYSIS HEADER */}
                    <Col md={12} className="mt-5 mb-3">
                        <h3 className="fs-14">Reviews by Mention</h3>
                    </Col>

                    {/* LIST OF STAFF */}
                    <Col md={12}>
                        <Card className="card-one">
                            <Card.Header as="h6" className="fw-semibold">Names of staffs appeared in reviews</Card.Header>
                            <Card.Body>
                                <Table className="mb-0" responsive>
                                    <thead>
                                        <tr>
                                            <th>Staff Name</th>
                                            <th>Staff Friendliness</th>
                                            <th>Staff Attentiveness</th>
                                            <th>Professionalism</th>
                                            <th>Accuracy of Orders</th>
                                            <th>Handling of Complaints</th>
                                            <th>Bias Behaviour</th>
                                            <th>Staff Appearance</th>
                                            <th>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {staffreviewslist.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.staff_name}</td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle align-middle"></i>
                                                    <b className="align-middle">{item.staff_friendliness}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle align-middle"></i></td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.staff_attentivenes}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.professionalism}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.accuracyof_orders}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.handling_of_complaints}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.bias_behaviour}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.staff_appearance}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td className="wt-120">
                                                    <ProgressBar className="wt-100 ht-15 bg-danger">
                                                        <ProgressBar
                                                            now={item.score}
                                                            max={10}
                                                            variant="success"
                                                            label={item.score}
                                                            style={{ color: 'white' }}
                                                        />
                                                    </ProgressBar>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>


                    {/* LIST OF FOOD */}
                    <Col md={12}>
                        <Card className="card-one">
                            <Card.Header className="fw-semibold fs-14">Names of Dishes appeared in reviews</Card.Header>
                            <Card.Body>
                                <Table className="mb-0" responsive>
                                    <thead>
                                        <tr>
                                            <th>Dish Name</th>
                                            <th>Taste</th>
                                            <th>Cost</th>
                                            <th>Authenticity</th>
                                            <th>Texture</th>
                                            <th>Healthiness</th>
                                            <th>Score</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {foodReviewlist.map((item, index) => (
                                            <tr key={index}>
                                                <td>{item.dish_name}</td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle align-middle"></i>
                                                    <b className="align-middle">{item.taste}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle align-middle"></i></td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.cost}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.authenticity}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.texture}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <i className="ri-thumb-up-line text-success me-1 align-middle"></i>
                                                    <b className="align-middle">{item.healthiness}</b>
                                                    <i className="ri-thumb-down-line text-danger ms-1 align-middle"></i>
                                                </td>
                                                <td>
                                                    <ProgressBar className="w-100 ht-15 bg-danger">
                                                        <ProgressBar
                                                            now={item.score}
                                                            max={10}
                                                            variant="success"
                                                            label={item.score}
                                                            style={{ color: 'white' }}
                                                        />
                                                    </ProgressBar>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Tab.Container>
            </Row>
        </React.Fragment>
    );
}
