import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Offcanvas, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { AddSection } from "./../../../../application/actions/tableAction";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import { OrderType } from "../../../../application/actions/orderTypeAction";
import { getOrderTypeList } from "../../../../application/selectors/indexSelector";
import Select from "react-select";
import { validateRequired, validateRequiredDropdown } from './../../../../infrastructure/core/validationUtils';

export default function AddNewSection(props) {
  const { user } = useContext(AuthContext);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const initialState = {
    name: "",
    branchId: props?.branchId,
    status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
    sectionDesc: "",
    createdBy: user.id,
    modifiedBy: user.id,
    tenantId: user.tenantId,
    FirebaseRefId: "NA",
    isVisible: true,
    isDeleted: false,
    isApplyAuto: false,
    sectionCharge: 0,
    typeId: "",
  };
  const getOrderListByBranch = useSelector(getOrderTypeList);
  const ApplyOrderType = getOrderListByBranch?.filter((emp) => emp?.isTableSection !== false).map((option) => ({
      value: option.id,
      label: option.typeName,
    }));
  const handleSelectApplied = (selectedOptions) => {
    setFormData({ ...formData, typeId: selectedOptions.value });
    setErrors({...errors, typeId:""})
  };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const validateName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.name = validateRequired(formData.name)
      ? ""
      : "Section Name is required";
    // Validate mainCatId (dropdown)
    newErrors.typeId = validateRequired(formData.typeId)
      ? ""
      : "Please Select Order Type";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  useEffect(() => {
    dispatch(OrderType);
    setFormData({ ...formData, branchId: props?.branchId });
  }, [props?.branchId]);

  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    const IsValid = validateName();
    if (!IsValid) {
      return;
    }
    dispatch(AddSection(formData));
    handleClose()
  };
  const handleClose =()=>{
    props.closeSection(false);
    setFormData(initialState);    
    setErrors({});    
  }
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Create New Section</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Choose Order Type <sup className="text-danger">*</sup>
                </Form.Label>
                <Select
                  name="orderTypes"
                  options={ApplyOrderType}
                  onChange={handleSelectApplied}
                  value={ApplyOrderType?.filter((option) =>
                    formData.typeId?.includes(option.value)
                  )}
                />
                  <Form.Text className="text-danger">
                  {errors.typeId}
                </Form.Text>
              </div>
             
            </Col>
          </Row>
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Section Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  placeholder="Enter Section Name"
                  value={formData.name}
                  onChange={(e) => {
                    setFormData({ ...formData, name: e.target.value });
                    setErrors({...errors, name:""})

                  }}
                />
                    <Form.Text className="text-danger">
                  {errors.name}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="8">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  name="sectionDesc"
                  placeholder="Enter Section Name"
                  value={formData.sectionDesc}
                  onChange={(e) => {
                    setFormData({ ...formData, sectionDesc: e.target.value });
                  }}
                />
              </div>
            </Col>
          </Row>
          {/* <Row>
                <Col xl="6">
                    <div className="mb-2">
                    <Form.Check type="switch" className="me-3 fs-14 fw-semibold" checked label="Apply Charge Automatically" onChange={() => {}} />
                    </div>
                    <div className="mb-4">
                        <Form.Label>Choose Charge Name: <sup className="text-danger">*</sup></Form.Label>
                        <Form.Control type="text" name="sectionName" placeholder="Enter Section Name" />
                    </div>
                </Col>
            </Row> */}
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            type="sunmit"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create New Section</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
