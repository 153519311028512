import { Navigate } from "react-router-dom";
import {
  ADDONS_GROUPS_LIST,
  ADDONS_ITEM_List,
  CATALOGUE_ITEM_BY_ADDONS_ID,
  //COMMON_FIELD,
  CREATE_ADDONS_GROUPS,
  CREATE_ADDONS_ITEM,
  DELETE_ADDONS_GROUPS,
  DELETE_ADDONS_ITEMS,
  //FoodTypeSuccess,
  GET_ADDONS_GROUPS_BY_ID,
  GetAddOnsGroupListSuccess,
  GetAddOnsGroupSuccess,
  GetAddOnsItemListSuccess,
  GetCatalogueItemsByAddonsId,
  GetCatalogueItemsByAddonsIdSuccess,
  UPDATE_ADDONS_GROUPS,
  //quantityTypeSuccess,
} from "../actions/addOnsAction";
import * as Loading from "../actions/loaderAction";
import * as itemAction from "../actions/ItemAction";
import * as addons from "../actions/addOnsAction";
import ToastifyService from "../../View/_common/ToastifyService";
const GetAddOnsGroupList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADDONS_GROUPS_LIST) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addonsGroupList" })
        );
        const AddOnsGroupData = await api.addOnsAPI.GetAddOnsList();
        dispatch(GetAddOnsGroupListSuccess(AddOnsGroupData));
        dispatch(
          Loading.setLoading({ loading: false, value: "addonsGroupList" })
        );
      } catch (error) {
        dispatch(
          Loading.setLoading({ loading: false, value: "addonsGroupList" })
        );
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const CreateAddOnsGroup =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_ADDONS_GROUPS) {
      try {
        const AddOnsGroupData = await api.addOnsAPI.CreateAddOnsGroup(
          action.payload.data
        );
        dispatch(addons.AddOnsGroupFailure(false));
        dispatch(GetAddOnsGroupSuccess(AddOnsGroupData.result));
        ToastifyService.success(
          `${AddOnsGroupData.result.groupTitle} Added Successfully!`
        );
        dispatch(Loading.loadAddOns);
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(addons.AddOnsGroupFailure(true));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateAddOnsGroup =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_ADDONS_GROUPS) {
      try {
        // dispatch(Loading.setLoading({loading:true, value:''}))
        const AddOnsGroupData = await api.addOnsAPI.UpdateAddOnsGroup(
          action.payload.data
        );
        // dispatch(GetAddOnsGroupListSuccess(AddOnsGroupData));
        dispatch(GetAddOnsGroupSuccess(AddOnsGroupData.result));
        ToastifyService.success(
          `${AddOnsGroupData.result.groupTitle} Updated Successfully!`
        );

        dispatch(Loading.loadAddOns);
        // dispatch(Loading.setLoading({loading:false, value:''}))
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteAddOnsGroup =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_ADDONS_GROUPS) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addonsGroupList" })
        );
        const AddOnsGroupData = await api.addOnsAPI.DeleteAddOnsGroup(
          action.payload
        );
        ToastifyService.success(`AddOns Group Deleted Successfully!`);
        dispatch(GetAddOnsGroupListSuccess(AddOnsGroupData));
        dispatch(
          Loading.setLoading({ loading: false, value: "addonsGroupList" })
        );
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteAddOnsItem =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_ADDONS_ITEMS) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addonsItemList" })
        );
        const AddOnsItemData = await api.addOnsAPI.DeleteAddOnsItem(
          action.payload
        );
        dispatch(GetAddOnsItemListSuccess(AddOnsItemData));
        dispatch(addons.GetAddOnsGroupList);
        dispatch(
          Loading.setLoading({ loading: false, value: "addonsItemList" })
        );
        ToastifyService.success("Addon Item Deleted Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetAddOnsGroupbyId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ADDONS_GROUPS_BY_ID) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addonsItemList" })
        );
        const AddOnsGroupData = await api.addOnsAPI.GetAddOnsGroupbyId(
          action.payload.data
        );
        dispatch(GetAddOnsGroupSuccess(AddOnsGroupData.result));
        dispatch(
          Loading.setLoading({ loading: false, value: "addonsItemList" })
        );
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

const CreateAddOnsItem =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_ADDONS_ITEM) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addonsItemList" })
        );
        const AddOnsGroupData = await api.addOnsAPI.CreateAddOnsItem(
          action.payload.data
        );
        dispatch(GetAddOnsItemListSuccess(AddOnsGroupData));
        if(action.payload.id){
          ToastifyService.success(`AddOns Item Updated Successfully!`);
        }else{
          ToastifyService.success(`AddOns Item Added Successfully!`);
        }

        dispatch(addons.GetAddOnsGroupList);
        dispatch(
          Loading.setLoading({ loading: false, value: "addonsItemList" })
        );
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetAddOnsItemByGroupId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADDONS_ITEM_List) {
      try {
        dispatch(
          Loading.setLoading({ loading: true, value: "addonsItemList" })
        );
        const AddOnsGroupData = await api.addOnsAPI.GetAddOnsItemByGroupId(
          action.payload.data
        );
        dispatch(GetAddOnsItemListSuccess(AddOnsGroupData));
        dispatch(GetCatalogueItemsByAddonsId(action.payload.data));
        const ItemCatalogData = await api.itemAPI.GetItemCatalog();
        dispatch(itemAction.CatalogSuccess(ItemCatalogData));

        dispatch(
          Loading.setLoading({ loading: false, value: "addonsItemList" })
        );
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetCatalogueByAddonsId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CATALOGUE_ITEM_BY_ADDONS_ID) {
      try {
        const CatalogAddonsData = await api.addOnsAPI.GetCatalogueByAddonsId(
          action.payload.data
        );
        dispatch(GetCatalogueItemsByAddonsIdSuccess(CatalogAddonsData));
      } catch (error) {
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
export default [
  DeleteAddOnsItem,
  DeleteAddOnsGroup,
  UpdateAddOnsGroup,
  GetAddOnsGroupList,
  CreateAddOnsGroup,
  GetAddOnsGroupbyId,
  CreateAddOnsItem,
  GetAddOnsItemByGroupId,
  GetCatalogueByAddonsId,
];
