import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { ListGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import Environment from "../../../infrastructure/core/Environment";
import { useDispatch, useSelector } from "react-redux";
import {
  GeneralSetting,
  GetPOSGenSetting,
} from "../../../application/actions/generalSettingAction";
import AuthContext from "./../../../infrastructure/core/helpers/AuthContext";
import { getGeneralSettingData } from "../../../application/selectors/indexSelector";

export default function GeneralComponent() {
  // Accessing user context
  const { user } = useContext(AuthContext);

  // Dispatch hook for Redux actions
  const dispatch = useDispatch();

  // Selector hook to get general setting data from Redux store
  const GeneralSettingData = useSelector(getGeneralSettingData);

  // Options for Sale Void and Bill Round from local storage
  const SaleVoidOption = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "SV"
  );
  const BillRoundOption = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "BR"
  );

  // Initial state for form data
  const initialState = {
    id: Environment.defaultValue,
    isPaymentModeSetup: false,
    isBillRoundUp: Environment.defaultValue,
    allowSalesVoid: Environment.defaultValue,
    isTranferToAnotherBranch: false,
    isSellerMandatory: false,
    isCreditLimit: false,
    creditAmount: 0,
    branchId: user.branchId,
    isVisible: true,
    isDeleted: false,
  };

  // State hook to manage form data
  const [formData, setFormData] = useState(initialState);

  // Effect hook to fetch POS general settings on component mount
  useEffect(() => {
    Prism.highlightAll();
    dispatch(GetPOSGenSetting(user?.tenanatId));
  }, []);

  // Effect hook to update form data when GeneralSettingData changes
  useEffect(() => {
    if (GeneralSettingData) {
      setFormData({
        ...formData,
        id: GeneralSettingData.id,
        isPaymentModeSetup: GeneralSettingData.isPaymentModeSetup,
        isBillRoundUp: GeneralSettingData.isBillRoundUp,
        allowSalesVoid: GeneralSettingData.allowSalesVoid,
        isTranferToAnotherBranch: GeneralSettingData.isTranferToAnotherBranch,
        isSellerMandatory: GeneralSettingData.isSellerMandatory,
        isCreditLimit: GeneralSettingData.isCreditLimit,
        creditAmount: GeneralSettingData.creditAmount,
        branchId: user.branchId,
        isVisible: true,
        isDeleted: false,
      });
    }
  }, [GeneralSettingData]);

  // Function to handle form field changes
  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    let updatedFormObject = { ...formData, [name]: newValue };

    // If isCreditLimit is unchecked, set creditAmount to 0
    if (name === "isCreditLimit" && !newValue) {
      updatedFormObject.creditAmount = 0;
    }
    // Update form data state
    setFormData(updatedFormObject);
    // Update general setting
    updateGeneralSetting(updatedFormObject);
  };

  // Function to dispatch action to update general setting
  const updateGeneralSetting = (formData) => {
    dispatch(GeneralSetting(formData));
  };

  return (
    <React.Fragment>
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">Setup General Settings</h4>
          <h5 className="fs-14 text-secondary">
            Define the payment mode for the bill payment settlement
          </h5>
        </div>
      </div>
      <ListGroup as="ul" className="list-group-one">
        {/* Bill Amount Rounding */}
        <ListGroup.Item as="li" className="px-0 d-flex align-items-center gap-2">
          <div>
            <h6 className="mb-0 fw-semibold text-dark">Bill Amount Rounding</h6>
            <span className="fs-xs text-secondary text-dark">
              Specify the method for rounding the bill amount
            </span>
          </div>
          <div className="ms-auto text-end d-flex align-items-center">
            <Link className="me-3 text-secondary">
              <i className="ri-question-line fs-20"></i>
            </Link>
            <Form.Select
              className="wt-200"
              name="isBillRoundUp"
              value={formData.isBillRoundUp}
              onChange={handleChange}
            >
              <option value={Environment.defaultValue} disabled>
                Disabled
              </option>
              {BillRoundOption.map((options, index) => (
                <option key={index} value={options.id}>
                  {options.fieldValue}
                </option>
              ))}
            </Form.Select>
          </div>
        </ListGroup.Item>

        {/* Allow Sales Void */}
        <ListGroup.Item as="li" className="px-0 d-flex align-items-center gap-2">
          <div>
            <h6 className="mb-0 fw-semibold text-dark">Allow Sales Void</h6>
            <span className="fs-xs text-secondary text-dark">
              Specify the Duration within which a closed sale order can be voided
            </span>
          </div>
          <div className="ms-auto text-end d-flex align-items-center">
            <Form.Select
              className="wt-200"
              name="allowSalesVoid"
              value={formData.allowSalesVoid}
              onChange={handleChange}
            >
              <option value={Environment.defaultValue} disabled>
                Disabled
              </option>
              {SaleVoidOption.map((options, index) => (
                <option key={index} value={options.id}>
                  {options.fieldValue}
                </option>
              ))}
            </Form.Select>
          </div>
        </ListGroup.Item>

        {/* Allow transfer online orders to others location? */}
        <ListGroup.Item as="li" className="px-0 d-flex align-items-center gap-2">
          <div>
            <h6 className="mb-0 fw-semibold text-dark">
              Allow transfer online orders to others location?
            </h6>
            <span className="fs-xs text-secondary text-dark">
              Specify whether the orders can be transferred to other locations.
            </span>
          </div>
          <div className="ms-auto text-end d-flex align-items-center">
            <Form.Check
              type="switch"
              className="me-3 fs-16 fw-semibold"
              name="isTranferToAnotherBranch"
              checked={formData.isTranferToAnotherBranch}
              onChange={handleChange}
            />
          </div>
        </ListGroup.Item>

        {/* Make seller selection mandatory? */}
        <ListGroup.Item as="li" className="px-0 d-flex align-items-center gap-2">
          <div>
            <h6 className="mb-0 fw-semibold text-dark">Make seller selection mandatory?</h6>
            <span className="fs-xs text-secondary text-dark">
              Specify whether the seller name selection is mandatory for in-store orders.
            </span>
          </div>
          <div className="ms-auto text-end d-flex align-items-center">
            <Form.Check
              type="switch"
              name="isSellerMandatory"
              checked={formData.isSellerMandatory}
              onChange={handleChange}
              className="me-3 fs-16 fw-semibold"
            />
          </div>
        </ListGroup.Item>

        {/* Restrict Credit Limit */}
        <ListGroup.Item as="li" className="px-0 d-flex align-items-center gap-2">
          <div>
            <h6 className="mb-0 fw-semibold text-dark">Restrict Credit Limit</h6>
            <span className="fs-xs text-secondary text-dark">
              Specify Maximum allowed credit amount for customer.
            </span>
          </div>
          <div className="ms-auto text-end d-flex align-items-center">
            <Form.Control
              type="number" min="1"
              name="creditAmount"
              value={formData.creditAmount}
              onChange={(e)=>setFormData({...formData, creditAmount:e.target.value})}
              onBlur={handleChange}
              className="wt-120"
              disabled={!formData.isCreditLimit}
            />
            <Form.Check
              type="switch"
              name="isCreditLimit"
              checked={formData.isCreditLimit}
              onChange={handleChange}
              className="me-3 fs-16 fw-semibold"
            />
          </div>
        </ListGroup.Item>
      </ListGroup>
    </React.Fragment>
  );
}
