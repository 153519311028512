import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import InProgress from "./inProgress";
import  Select  from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import { getBranchList, getPendingOrders } from "../../../application/selectors/indexSelector";
import OutForDelivery from "./OutForDelivery";
import { PendingOrders } from "../../../application/actions/salesOrdersAction";
import CreditPending from "./CreditPending";

export default function PendingOrder({setPendingOrderCount}) {
    const dispatch= useDispatch()

    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }
    switchSkin(skin);
    useEffect(() => {
        switchSkin(skin);
    }, [skin]);
    const BranchList = useSelector(getBranchList);
    const [selectedOptions, setSelectedOptions] = useState(
      JSON.parse(localStorage.getItem("branch"))
    );
    const BranchType = BranchList?.map((option) => ({
      value: option.id,
      label: option.branchName,
    }));
    useEffect(() => {
        dispatch(PendingOrders(selectedOptions.value?selectedOptions.value:selectedOptions))
      }, [selectedOptions]);
    const [activeTab, setActiveTab] = useState('inProgress_tab');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };
       const handleSelectBranch = (selectedOption) => {
      setSelectedOptions(selectedOption.value);
      dispatch(PendingOrders(selectedOption.value))
    };
    const PendingOrdersList = useSelector(getPendingOrders)
    const OutForDeliveryList= PendingOrdersList?.filter(list=> list.orderStatus==='a5ca48b8-bfd1-42d6-8db1-7021c131e609')
    const CreditPendingList= PendingOrdersList?.filter(list=> list.paymentMode==='a5ca48b8-bfd1-42d6-8db1-7021c131e806')
  
    // Update parent component's pendingOrderCount state
    useEffect(() => {
        setPendingOrderCount(PendingOrdersList?.length);
      }, [PendingOrdersList, setPendingOrderCount]);
      return (
        <React.Fragment>
          <div className="wt-300 mb-3">
        <div className="form-search me-auto p-0 position-relative z-index-2">
          <Select
            name="branchId"
            placeholder="Choose Branch"
            options={BranchType}
            onChange={handleSelectBranch}
            value={BranchType?.find(
              (option) => option.value === selectedOptions.value
            )}
            className="w-100 p-0"
            id="choose_branch"
          />
        </div>
        </div>
            <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="sale-on-point-tabs bg-transparent">
                <Tab eventKey="inProgress_tab" title={<>In Progress <span className="badge rounded-pill badge-length-web">{PendingOrdersList?.length}</span></>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3">
                        <InProgress InProgressList={PendingOrdersList} />
                    </div>
                </Tab>
                <Tab eventKey="out_of_delivery_tab" title={<>Out for Delivery <span className="badge rounded-pill badge-length-web">{OutForDeliveryList?.length}</span></>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3">
                        <OutForDelivery OutForDeliveryList={OutForDeliveryList} />
                    </div>
                </Tab>
                <Tab eventKey="pending_credit_tab" title={<>Pending Credit <span className="badge rounded-pill badge-length-web">{CreditPendingList?.length}</span></>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3">                        
                    <CreditPending CreditPendingList={CreditPendingList} />
                </div>
                </Tab>
            </Tabs>
        </React.Fragment>
    )
}