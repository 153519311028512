import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Tab, Nav, Button, Alert } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import AdvancePayment from "./AdvancePayment/AdvancePayment";
import SalaryPayment from "./SalaryPayment/salary-payment";
import SalaryStatement from "./SalaryStatement/salary-statement";
import { Link } from "react-router-dom";
import PayAdvanceSalary from "./AdvancePayment/pay-advance-salary";
import Environment from "../../infrastructure/core/Environment";
import {debounce} from "lodash";

export default function SalaryAdvance() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);


    useEffect(() => {
        document.body.classList.add('page-app');
        return () => {
            document.body.classList.remove('page-app');
        }
    }, []);

    // toggle sidebar in mobile
    // toggle sidebar in mobile
    const [isSidebarShow, setSidebarShow] = useState(false);

    const [showPayAdvanceSal, setShowPayAdvanceSal] = useState(false);
    const handlePayAdvanceSal = (state) => {
        setShowPayAdvanceSal(state);
    };
    
    const [branchData, setBranchData] = useState(Environment.defaultValue);
    const handleBranchIdFromHeader = debounce((data) => {
      setBranchData(data);
    }, 300);
    return (
      <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader}/>
            <PayAdvanceSalary show={showPayAdvanceSal} closeFunction={handlePayAdvanceSal} />
            <Tab.Container id="left-tabs-example" defaultActiveKey="attendance_payment_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <div className="p-2 pt-0 pb-0 mb-3 mt-3">
                            <Button variant="primary" className="w-100" onClick={() => handlePayAdvanceSal(true)}>
                            <i className="ri-bank-card-line align-middle"></i> <span className="align-middle">Pay Advance</span>
                        </Button>
                        </div>
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link className="position-relative" eventKey="attendance_payment_tab"><i className="ri-hand-coin-line align-middle"></i> Advance Payment</Nav.Link></Nav.Item>
                            {/* <Nav.Item><Nav.Link className="position-relative" eventKey="advance_payment_tab"><i className="ri-calendar-event-line align-middle"></i> Attendance Report</Nav.Link></Nav.Item> */}
                            <Nav.Item><Nav.Link className="position-relative" eventKey="salary_payment_tab"><i className="ri-bank-card-line align-middle"></i> Salary Payment</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link className="position-relative" eventKey="salary_statement_tab"><i className="ri-chat-history-line align-middle"></i> Salary Statement</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                         <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <div className="w-100 mb-3">
                            <Alert variant="danger" className="mb-0 w-100">
                                <i className="ri-error-warning-line align-middle"></i> <b>ATTENTION!!</b> Generate the slary statement for the month of December 2023
                                <Link className="text-dark position-absolute right-10 fw-bold">GENERATE</Link>
                            </Alert>
                        </div>
                        
                        <Tab.Content>
                            {/* SALARY COMPOENT */}
                            <Tab.Pane eventKey="attendance_payment_tab"><AdvancePayment /></Tab.Pane>
                            {/* <Tab.Pane eventKey="advance_payment_tab"><AttendanceReport /></Tab.Pane> */}
                            <Tab.Pane eventKey="salary_payment_tab"><SalaryPayment /></Tab.Pane>
                            <Tab.Pane eventKey="salary_statement_tab"><SalaryStatement /></Tab.Pane>
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}