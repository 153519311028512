const FoodSentimentlist = [
    {
        "name": "Taste",
        "progressData": [
            {
                "label": "Negative",
                "variant": "danger",
                "percentage": 10
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 30
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 60
            }
        ]
    },
    {
        "name": "Cost",
        "progressData": [
            {
                "label": "Negetaive",
                "variant": "danger",
                "percentage": 5
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 50
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 45
            }
        ]
    },
    {
        "name": "Authenticity",
        "progressData": [
            {
                "label": "Negetaive",
                "variant": "danger",
                "percentage": 20
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 10
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 70
            }
        ]
    },
    {
        "name": "Quantity",
        "progressData": [
            {
                "label": "Negetaive",
                "variant": "danger",
                "percentage": 50
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 20
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 30
            }
        ]
    },
    {
        "name": "Texture",
        "progressData": [
            {
                "label": "Negetaive",
                "variant": "danger",
                "percentage": 0
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 12
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 88
            }
        ]
    },
    {
        "name": "Healthiness",
        "progressData": [
            {
                "label": "Negetaive",
                "variant": "danger",
                "percentage": 0
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 0
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 100
            }
        ]
    },
    {
        "name": "Temprature",
        "progressData": [
            {
                "label": "Negetaive",
                "variant": "danger",
                "percentage": 10
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 40
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 60
            }
        ]
    },
    {
        "name": "Portion Size",
        "progressData": [
            {
                "label": "Negetaive",
                "variant": "danger",
                "percentage": 70
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 0
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 30
            }
        ]
    },
    {
        "name": "Cooking Time",
        "progressData": [
            {
                "label": "Negetaive",
                "variant": "danger",
                "percentage": 20
            },
            {
                "label": "Neutral",
                "variant": "warning",
                "percentage": 10
            },
            {
                "label": "Positive",
                "variant": "success",
                "percentage": 70
            }
        ]
    }
];

const ServiceSentimentlist = [
    {
        "name": "Staff Friendliness",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 20 },
            { "label": "Neutral", "variant": "warning", "percentage": 25 },
            { "label": "Positive", "variant": "success", "percentage": 55 }
        ]
    },
    {
        "name": "Staff Attentiveness",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 20 },
            { "label": "Neutral", "variant": "warning", "percentage": 20 },
            { "label": "Positive", "variant": "success", "percentage": 60 }
        ]
    },
    {
        "name": "Professionalism",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 10 },
            { "label": "Neutral", "variant": "warning", "percentage": 20 },
            { "label": "Positive", "variant": "success", "percentage": 70 }
        ]
    },
    {
        "name": "Accuracy of Orders",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 30 },
            { "label": "Neutral", "variant": "warning", "percentage": 20 },
            { "label": "Positive", "variant": "success", "percentage": 50 }
        ]
    },
    {
        "name": "Handling of Complaints",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 15 },
            { "label": "Neutral", "variant": "warning", "percentage": 25 },
            { "label": "Positive", "variant": "success", "percentage": 60 }
        ]
    },
    {
        "name": "Wait Time for Seating",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 40 },
            { "label": "Neutral", "variant": "warning", "percentage": 10 },
            { "label": "Positive", "variant": "success", "percentage": 50 }
        ]
    },
    {
        "name": "Bias Behaviour",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 10 },
            { "label": "Neutral", "variant": "warning", "percentage": 25 },
            { "label": "Positive", "variant": "success", "percentage": 65 }
        ]
    },
    {
        "name": "Staff Appearance",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 15 },
            { "label": "Neutral", "variant": "warning", "percentage": 15 },
            { "label": "Positive", "variant": "success", "percentage": 70 }
        ]
    },
    {
        "name": "Value for Money",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 10 },
            { "label": "Neutral", "variant": "warning", "percentage": 35 },
            { "label": "Positive", "variant": "success", "percentage": 55 }
        ]
    }
];

const AmbianceSentimentlist = [
    {
        "name": "Interior Ambiance",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 0 },
            { "label": "Neutral", "variant": "warning", "percentage": 0 },
            { "label": "Positive", "variant": "success", "percentage": 0 }
        ]
    },
    {
        "name": "Cleanliness",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 10 },
            { "label": "Neutral", "variant": "warning", "percentage": 30 },
            { "label": "Positive", "variant": "success", "percentage": 60 }
        ]
    },
    {
        "name": "Lighting",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 5 },
            { "label": "Neutral", "variant": "warning", "percentage": 25 },
            { "label": "Positive", "variant": "success", "percentage": 70 }
        ]
    },
    {
        "name": "Noise Level",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 55 },
            { "label": "Neutral", "variant": "warning", "percentage": 25 },
            { "label": "Positive", "variant": "success", "percentage": 20 }
        ]
    },
    {
        "name": "Seating Comfort",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 18 },
            { "label": "Neutral", "variant": "warning", "percentage": 22 },
            { "label": "Positive", "variant": "success", "percentage": 60 }
        ]
    },
    {
        "name": "A/c & Temperature",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 45 },
            { "label": "Neutral", "variant": "warning", "percentage": 15 },
            { "label": "Positive", "variant": "success", "percentage": 40 }
        ]
    },
    {
        "name": "Space and Layout",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 15 },
            { "label": "Neutral", "variant": "warning", "percentage": 35 },
            { "label": "Positive", "variant": "success", "percentage": 50 }
        ]
    },
    {
        "name": "Customer Privacy",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 10 },
            { "label": "Neutral", "variant": "warning", "percentage": 0 },
            { "label": "Positive", "variant": "success", "percentage": 90 }
        ]
    },
    {
        "name": "Ordor and Scent",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 8 },
            { "label": "Neutral", "variant": "warning", "percentage": 22 },
            { "label": "Positive", "variant": "success", "percentage": 70 }
        ]
    }
] 

export { FoodSentimentlist, ServiceSentimentlist, AmbianceSentimentlist };