
import React, { useState } from 'react';
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';

const libraries = ['places'];
const mapContainerStyle = {
  width: '100%',
  height: '500px',
  marginTop: "20px"
};
const defaultCenter = {
  lat: 7.2905715, // default latitude
  lng: 80.6337262, // default longitude
};

const Places = ({ placeDetails }) => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [address, setAddress] = useState('');
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [mapZoom, setMapZoom] = useState(10);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyB2Prluw64FPvOnn9rwqLYyAxtYrbcr4tQ',
    libraries,
  });

  const handlePlaceSelect = (value) => {
    console.log(value);
    const placeId = value.value.place_id; // Get the place ID
    placeDetails({ placeId: placeId });
    setAddress(value.label);
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));

    service.getDetails({ placeId }, (result, status) => {
      if (status === window.google.maps.places.PlacesServiceStatus.OK) {
        const location = result.geometry.location;
        setSelectedPlace({
          lat: location.lat(),
          lng: location.lng(),
        });
        setMapCenter({
          lat: location.lat(),
          lng: location.lng(),
        });
        setMapZoom(15); // Zoom in to the selected location
      }
    });
  };

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <GooglePlacesAutocomplete
        apiKey='AIzaSyB2Prluw64FPvOnn9rwqLYyAxtYrbcr4tQ'
        selectProps={{
          // value: address,
          onChange: handlePlaceSelect,
          inputValue: address,
          onInputChange: (inputValue) => setAddress(inputValue),
        }}
      />
      <GoogleMap mapContainerStyle={mapContainerStyle} zoom={mapZoom} center={mapCenter}>
        {selectedPlace && <Marker position={{ lat: selectedPlace.lat, lng: selectedPlace.lng }} />}
      </GoogleMap>
    </div>
  );
};

export default Places;
