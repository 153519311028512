import { GET_SECTION_SUCCESS, GET_TABLE_SUCCESS } from "../actions/tableAction";

const initialState = {
    SectionData:undefined,
    TableData:undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_SECTION_SUCCESS:
        return { ...state, SectionData: action.payload.data.result, error: null }; 
        case GET_TABLE_SUCCESS:
        return { ...state, TableData: action.payload, error: null };
        default:
            return state;
    }
}

export default reducer;