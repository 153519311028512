import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { useSelector } from "react-redux";
import { getLoading } from "../../../application/selectors/indexSelector";
import { Button, Card, Table, Form } from "react-bootstrap";
import salaryPaymentList from "../../../Json/SalaryAdvance/salary-payment.json";
import SalaryUpdate from "./salary-update";
import EmpSalaryAdvance from "./salary-advance";
import TableShimmerLoader from "../../../layouts/ShimmerTable";

export default function SalaryPayment() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const { loading, value } = useSelector(getLoading);
    const [showSalaryUpdate, setShowSalaryUpdate] = useState(false);
    const [showEmpSalaryAdvance, setShowEmpSalaryAdvance] = useState(false);
    const [salaryUpdateData, setsalaryUpdateData] = useState();
    const handleSalaryUpdate = (state, item) => {
        setShowSalaryUpdate(state);
        setsalaryUpdateData(item);
    }
    const handleEmpSalaryAdvance = (state) => {
        setShowEmpSalaryAdvance(state);
    }

    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = salaryPaymentList?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

    return (
        <React.Fragment>
            <SalaryUpdate show={showSalaryUpdate} closeFunction={handleSalaryUpdate} data={salaryUpdateData} />
            <EmpSalaryAdvance show={showEmpSalaryAdvance} closeFunction={handleEmpSalaryAdvance} />
            <div className="d-md-flex align-items-center justify-content-between mb-3">
                <div>
                    <Button variant="success" className="fs-14" type="button">
                        <i className="ri-file-excel-2-line align-middle fs-18 me-2"></i> <span className="align-middle">Download Report</span>
                    </Button>
                </div>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <div>
                        <h4 className="main-title fs-14 mb-0">Salary Payment</h4>
                    </div>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-250">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>

                    {filteredEmployees && filteredEmployees !== undefined && (
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Total Payable</th>
                                    <th>Eligible Salary</th>
                                    <th>Advance Balance</th>
                                    <th>Cash Payment</th>
                                    <th>Bank Payment</th>
                                    <th>Payment Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            <tbody>
                                {filteredEmployees?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.name}</td>
                                        <td><b>{item.totalPayable}</b></td>
                                        <td><b>{item.eligibleSalary}</b></td>
                                        <td><b>{item.advanceBalance}</b></td>
                                        <td><b>{item.cashPayment}</b></td>
                                        <td><b>{item.bankPayment}</b></td>
                                        <td className={`fw-semibold ${item.paymentStatus == "Paid" ? "text-success" : ""}${item.paymentStatus == "Pending" ? "text-danger" : ""}`}>
                                            {item.paymentStatus}
                                        </td>
                                        <td className="w-0">
                                            {item.action === "Update Salary" ? <>
                                                <Button variant="outline-success" onClick={() => handleSalaryUpdate(true, item)} className="text-nowrap wt-150">Update Salary</Button>
                                            </> : <>
                                                <Button variant="outline-danger" onClick={() => handleEmpSalaryAdvance(true)} className="text-nowrap wt-150">Pay Advance</Button>
                                            </>}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                    )}
                    {!loading && filteredEmployees === undefined && (
                        <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading &&
                        value === "salaryPaymentList" && filteredEmployees === undefined && (
                            <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                        )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}