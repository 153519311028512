
import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Card } from "react-bootstrap";
import "./integration.scss"
import pinelabsIcon from "../../../assets/img/integration/pinelabs.png";
import tallyPrimeIcon from "../../../assets/img/integration/tally-prime.png";
import paytmIcon from "../../../assets/img/integration/paytm.png";


export default function Integration() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return (
        <React.Fragment>
            <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
                <div>
                    <h4 className="fs-16 main-title mb-1">Integrations</h4>
                </div>
                <div className="d-flex gap-2 mt-3 mt-md-0">
                    <Button variant="primary" className="d-flex align-items-center gap-1">
                        <i className="ri-add-line fs-18 lh-1"></i>
                        <span className="d-sm-inline">Add Integrations</span>
                    </Button>
                </div>
            </div>

            <div>
                <Card className="card-one p-3 d-flex flex-row mb-2">
                    <div className="card-icon"><img src={pinelabsIcon} alt="icon" className="w-100 h-100 object-fit-contain" /></div>
                    <div className="ms-3">
                        <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">Pinelabs</h4>
                        <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">DIGITAL-PAYMENT</label>
                        <p className="fs-xs text-dark mb-0 lh-4">Securely collect payments via Cards, UPI, Wallets etc. at your storefront with Pine Labs EDCs.</p>
                    </div>
                    <div className="payment-itegration-bttn">
                        <Button variant="primary" className="fs-12 text-uppercase">Sign up</Button>
                    </div>
                </Card>

                <Card className="card-one p-3 d-flex flex-row mb-2">
                    <div className="card-icon"><img src={tallyPrimeIcon} alt="icon" className="w-100 h-100 object-fit-contain" /></div>
                    <div className="ms-3">
                        <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">Tally</h4>
                        <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">ACCOUNTING</label>
                        <p className="fs-xs text-dark mb-0 lh-4">Seamlessly integrate Urbanpiper Prime with Tally Prime as your accounting system.</p>
                    </div>
                    <div className="payment-itegration-bttn">
                        <Button variant="primary" className="fs-12 text-uppercase">Sign up</Button>
                    </div>
                </Card>

                <Card className="card-one p-3 d-flex flex-row mb-2">
                    <div className="card-icon"><img src={paytmIcon} alt="icon" className="w-100 h-100 object-fit-contain" /></div>
                    <div className="ms-3">
                        <h4 className="card-value text-dark fw-semibold fs-16 mb-1 text-uppercase">Paytm</h4>
                        <label className="card-title text-dark fs-14 text-dark mb-1 text-uppercase">DIGITAL-PAYMENT</label>
                        <p className="fs-xs text-dark mb-0 lh-4">Securely collect payments via Cards, UPI, Wallets etc. at your storefront with Paytm EDCs.</p>
                    </div>
                    <div className="payment-itegration-bttn">
                        <Button variant="primary" className="fs-12 text-uppercase">View Details</Button>
                    </div>
                </Card>
            </div>
        </React.Fragment>
    )
}