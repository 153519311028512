import React, { useContext, useEffect, useRef, useState } from "react";
import "./print.scss";
import { Table } from "react-bootstrap";
//import qrCodeImg from "../../../assets/img/qr.png";
import { useReactToPrint } from "react-to-print";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import Environment from "../../../infrastructure/core/Environment";

function BillPrint({ billPrintData, onBillPrintRequested }) {
  const commonfields = JSON.parse(localStorage.getItem("CommonFields"));
  const [billPrinted, setBillPrinted] = useState(false);
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const printableBillRef = useRef(null);
  const handleBillPrint = useReactToPrint({
    content: () => printableBillRef.current,
  });

  useEffect(() => {
    if (!billPrinted && billPrintData) {
      setBillPrinted(true);
      if (onBillPrintRequested) {
        handleBillPrint(); // Call the callback function to trigger printing logic
        console.log("Billprint",printableBillRef.current);
        onBillPrintRequested();
        
      }
    }
  }, [billPrinted, billPrintData, onBillPrintRequested]);


  return (
    <React.Fragment>
      <Table style={{ color: "#000", border: 0, width: "100%", padding: '5px'}} ref={printableBillRef}>
        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }} colSpan={3}>
            <h3 style={{ fontWeight: "700", color: "#000", textAlign: "center", textTransform: "uppercase", fontSize: "8px", marginBottom: "5px" }}>Bill Print</h3>
          </td>
        </tr>

        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }} colSpan={3}>
            <p style={{ color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px", textAlign: "center" }}>
              {parsedBranchData?.addressline1}, {parsedBranchData?.addressline2}, {parsedBranchData?.city}, {parsedBranchData?.state},{" "} {parsedBranchData?.country} - {parsedBranchData?.zipCode}
            </p>
          </td>
        </tr>
        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }} colSpan={3}>
            <div style={{ color: "#000", fontWeight: "700", margin: "5px 0", borderBottomStyle: "dashed", borderBottomWidth: "1px", borderBottomColor: "#000" }}></div>
          </td>
        </tr>

        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }} colSpan={3}>
            <div style={{ color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px" }}>
              <small style={{ fontSize: "6px" }}>B.No</small>:{" "}
              {`${billPrintData?.createOrderSuccess?.orderNumber?.substring(
                0,
                billPrintData?.createOrderSuccess?.orderNumber?.indexOf("-") + 1
              )}${billPrintData?.createOrderSuccess?.orderNumber?.substring(
                2,
                8
              )}/${billPrintData?.createOrderSuccess?.orderNumber?.substring(
                billPrintData?.createOrderSuccess?.orderNumber?.lastIndexOf(
                  "/"
                ) + 1
              )}`}{" "}
              - {new Date().toLocaleString()}
            </div>

            {billPrintData?.createOrderSuccess?.waiterDetails && (
              <div style={{ color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px" }}><small style={{ fontSize: "11px" }}>Cashier</small>:{" "}{billPrintData?.createOrderSuccess?.waiterDetails?.fullName}</div>
            )}

            {billPrintData?.createOrderSuccess?.customerDetails && (
              <div style={{ color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px" }}>
                <small style={{ fontSize: "6px" }}>Customer</small>:{" "}
                {billPrintData?.createOrderSuccess?.customerDetails?.fullName}
              </div>
            )}

            <div style={{ color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px" }}>
              <small style={{ fontSize: "6px" }}>Order Type</small>:{" "}
              {billPrintData?.orderType?.typeName}
            </div>
            {billPrintData?.orderType?.isTableSection && (
              <div style={{ color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px" }}>
                <small style={{ fontSize: "6px" }}>Table</small>:{" "}
                {billPrintData?.tableName}
              </div>
            )}
          </td>
        </tr>

        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }} colSpan={3}>
            <div style={{ color: "#000", fontWeight: "700", margin: "5px 0", borderBottomStyle: "dashed", borderBottomWidth: "1px", borderBottomColor: "#000" }}></div>
          </td>
        </tr>
        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }} colSpan={3}>
            <h3 style={{ color: "#000", fontWeight: "700", textAlign: "center", textTransform: "uppercase", fontSize: "8px", marginBottom: "5px" }}>INVOICE</h3>
          </td>
        </tr>

        <tr style={{ border: 0 }}>
          <td style={{ border: "0", fontWeight: "700", color: "#000", fontSize: "6px", lineHeight: "10px" }}>Item(s)</td>
          <td style={{ border: "0", fontWeight: "700", textAlign: "right", color: "#000", fontSize: "6px", lineHeight: "10px" }}>Qty.</td>
          <td style={{ border: "0", fontWeight: "700", textAlign: "right", padding: "0 5px", color: "#000", fontSize: "6px", lineHeight: "10px" }}>Amt.</td>
        </tr>

        {billPrintData?.createOrderSuccess?.orderItemLists?.filter((item) => item.isCustom === false && item.isAddOn === false).map((item, index) => (
          <>
            <tr style={{ border: 0 }} index={index}>
              <td style={{ border: "0", fontWeight: "700", color: "#000", fontSize: "6px", lineHeight: "10px" }}>
                {item.catalogueName} -{" "}
                <h4 style={{ fontWeight: "700", color: "#000", fontSize: "6px", display: "inline-block" }}>
                  <span> {item.varianceName}{" "} {item.variantType === "d9c5a952-911e-4a0f-8935-5e1fae01902e" ? item?.sizeId !== Environment.defaultValue ?
                    `(${commonfields?.find((size) => size.id === item.sizeId)?.fieldValue})` : "" : ""}</span>
                </h4>{" "}
                ({item.price})
              </td>
              <td style={{ border: "0", fontWeight: "700", color: "#000", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>{item.quantity}</td>
              <td style={{ border: "0", fontWeight: "700", color: "#000", padding: "0 5px", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>{item.amount}</td>
            </tr>

            {billPrintData?.createOrderSuccess?.orderItemLists?.filter((custom) => (custom.isCustom === true || custom.isAddOn === true) &&
              custom.orderSequence === item.orderSequence && custom.kotSequence === item?.kotSequence).map((custom, customiseIndex) => (
                <tr style={{ border: 0 }} key={customiseIndex}>
                  <td style={{ border: "0", fontWeight: "700", color: "#000", fontSize: "6px", lineHeight: "16px" }}>{custom.addOnName} ({custom.price})</td>
                  <td style={{ border: "0", fontWeight: "700", color: "#000", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>{custom.quantity}</td>
                  <td style={{ border: "0", fontWeight: "700", color: "#000", padding: "0 5px", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>{custom.amount}</td>
                </tr>
              ))}
          </>
        ))}

        <tr style={{ border: 0 }}>
          <td style={{ border: "0" }} colSpan={3}>
            <div style={{ color: "#000", fontWeight: "700", margin: "5px 0", borderBottomStyle: "dashed", borderBottomWidth: "1px", borderBottomColor: "#000" }}></div>
          </td>
        </tr>

        <tr style={{ border: 0 }}>
          <td colSpan={2} style={{ border: "0", fontWeight: "700", color: "#000", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>Total Items:</td>
          <td style={{ border: "0", color: "#000", fontWeight: "700", padding: "0 5px", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>
            {billPrintData?.createOrderSuccess?.orderItemLists ? billPrintData?.createOrderSuccess?.orderItemLists.reduce((total, item) => total + item.quantity, 0) : 0}
          </td>
        </tr>

        <tr style={{ border: 0 }}>
          <td colSpan={2} style={{ border: "0", color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>
            {billPrintData?.createOrderSuccess?.globalTaxGroup?.taxGroupName}{" "}
            ({billPrintData?.createOrderSuccess?.globalTaxGroup?.taxRate}%{" "}
            {commonfields?.find((cmdflds) => cmdflds.id === billPrintData?.createOrderSuccess?.globalTaxGroup?.taxTypeId)?.fieldValue})
          </td>
          <td style={{ border: "0", color: "#000", fontWeight: "700", padding: "0 5px", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>
            +{" "}<b>{parsedBranchData?.currencySymbol}{" "}{billPrintData?.createOrderSuccess?.taxAmount}</b>{" "}/-
          </td>
        </tr>

        <tr style={{ border: 0 }}>
          <td colSpan={2} style={{ border: "0", color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px", textAlign: "right" }}>Total Payable</td>
          <td style={{ border: "0", padding: "0 5px", color: "#000", fontWeight: "700", fontSize: "6px", lineHeight: "10px", textAlign: "right"}}>
            {parsedBranchData?.currencySymbol}{" "} {billPrintData?.createOrderSuccess?.grandTotalPrice}/-
          </td>
        </tr>


        <tr style={{ border: 0 }}>
          <td style={{ border: "0" }} colSpan={3}>
            <div style={{ color: "#000", margin: "5px 0", fontWeight: "700", borderBottomStyle: "dashed", borderBottomWidth: "1px", borderBottomColor: "#000" }}></div>
          </td>
        </tr>

        <tr style={{ border: 0 }}>
          <td colSpan={3} style={{ color: "#000"}}>
            <div style={{ color: "#000", fontWeight: "700", fontSize: "8px", lineHeight: "12px" }}><small style={{ fontSize: "11px" }}>For Inquiry</small>:{" "} {parsedBranchData?.contactNo}</div>
            <div style={{ color: "#000", fontWeight: "700", fontSize: "8px", lineHeight: "12px" }}><small style={{ fontSize: "11px" }}>For Feedback</small>:{" "} {parsedBranchData?.branchEmail}</div>
            <div style={{ color: "#000", fontWeight: "700", fontSize: "8px", lineHeight: "12px" }}><small style={{ fontSize: "11px" }}>Online Order</small>: www.fudeasy.com</div>
            <div style={{ color: "#000", fontWeight: "700", fontSize: "8px", lineHeight: "12px" }}><small style={{ fontSize: "11px" }}>GSTIN</small> - {parsedBranchData?.taxNumber}</div>
          </td>
        </tr>
        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }} colSpan={3}>
            <div style={{ color: "#000", fontWeight: "700", margin: "5px 0", borderBottomStyle: "dashed", borderBottomWidth: "1px", borderBottomColor: "#000" }}></div>
          </td>
        </tr>
        <tr style={{ border: 0 }}>
          <td style={{ border: 0 }} colSpan={3}>
            <div style={{ marginTop: "5px", fontWeight: "700", marginBottom: "5px" }}>
              <div style={{ width: "60px", fontWeight: "700", height: "60px", margin: "0 auto", display: "block", }}>
                <img
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=upi%3A%2F%2Fpay%3Fpa%3Dprachiraut446%40okicici%26pn%3DFudeasy%26am%3D${billPrintData?.createOrderSuccess?.grandTotalPrice}%26cu%3DINR`}
                  alt="QR" style={{ width: "100%", height: "100%", margin: "0 auto", display: "block", objectFit: "conver" }} />
              </div>

            </div>
            <div style={{ color: "#000", fontWeight: "700", fontSize: "8px", lineHeight: "12px", textAlign: "center" }}>Thank You For Visiting Us</div>
          </td>
        </tr>
      </Table>
    </React.Fragment>
  );
}
export default BillPrint;
