import React, { useState } from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";
import Places from "../_common/searchPlace";

export default function GoogleBusinessUpdate(props) {
  const [placeDetails, setPlaceDetails] = useState({});
  console.log(placeDetails);

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Google My Business
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="mb-4">
            <Form.Label>Branch Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Branch Name"
              value="#AB2485"
              disabled
            />
          </div>
          <div className="mb-4">
            <Form.Label>Google Business Profile ID</Form.Label>
            <Form.Control type="text" placeholder="Enter Google Business Profile ID" value={placeDetails.placeId || ""} readOnly />
          </div>

          <div className="mb-4">
            <Form.Label>Google Branch Location</Form.Label>
            <div className="branch-map-view">
              <Places placeDetails={setPlaceDetails} />
            </div>
          </div>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="submit"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Update</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
