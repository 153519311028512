import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import CreateTemplate from "./create-template";

export default function SelectPrintTemlate(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [showCreateTemplate, setShowCreateTemplate] = useState(false);
    const handleCreateTemplate = (state) => {
        setShowCreateTemplate(state)
    }

    const [selectedTemplateType, setSelectedTemplateType] = useState("KOT Slip");
    const handleTemplateType = (e) => {
        setSelectedTemplateType(e.target.value);
    };

    const [selectedPapperSize, setSelectedPapperSize] = useState("");
    const handlePapperSize = (e) => {
        setSelectedPapperSize(e.target.value);
        console.log("Get Papper Size", e.target.value);
    };

    return (
        <React.Fragment>
            <CreateTemplate 
            show={showCreateTemplate} 
            closeFunction={handleCreateTemplate}
            data={selectedTemplateType}
             />

            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-25">
                <Offcanvas.Header className="d-block custom-offcanvas-header pe-0 ps-0" closeButton>
                    <Offcanvas.Title className="pe-3 ps-3">Select Print Template</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Form>
                        <Row>
                            <Col xl={12}>
                                <div className="mb-4">
                                    <Form.Label>Template Name: <sup className="text-danger">*</sup></Form.Label>
                                    <Form.Control type="text" placeholder="Enter Template Name" />
                                </div>
                            </Col>

                            <Col xl={12}>
                                <div className="mb-4">
                                    <Form.Label>Template Type: <sup className="text-danger">*</sup></Form.Label>
                                    <Form.Select value={selectedTemplateType} onChange={handleTemplateType} name="templateType">
                                        <option disabled>Choose Template Type</option>
                                        <option value="KOT Slip">KOT Slip</option>
                                        <option value="Store Bill">Store Bill</option>
                                        <option value="Online Invoice">Online Invoice</option>
                                    </Form.Select>
                                </div>
                            </Col>

                            <Col xl={12}>
                                {(selectedTemplateType === "KOT Slip" || selectedTemplateType === "Store Bill") && (
                                    <div className="mb-4">
                                        <Form.Label>Papper Size: <sup className="text-danger">*</sup></Form.Label>
                                        <Form.Select value={selectedPapperSize} onChange={handlePapperSize} name="papperSize">
                                            <option disabled>Choose Papper Size</option>
                                            <option value="50mm x 25mm">50mm x 25mm</option>
                                            <option value="57mm x 40mm">57mm x 40mm</option>
                                            <option value="58mm x 50mm">58mm x 50mm</option>
                                            <option value="76mm x 76mm">76mm x 76mm</option>
                                            <option value="80mm x 80mm">80mm x 80mm</option>
                                            <option value="100mm x 150mm">100mm x 150mm</option>
                                            <option value="110mm x 100mm">110mm x 100mm</option>
                                            <option value="112mm x 50mm">112mm x 50mm</option>
                                        </Form.Select>
                                    </div>
                                )}
                                {selectedTemplateType === "Online Invoice" && (
                                    <div className="mb-4">
                                        <Form.Label>Papper Size: <sup className="text-danger">*</sup></Form.Label>
                                        <Form.Select value={selectedPapperSize} onChange={handlePapperSize} name="papperSize">
                                            <option disabled>Choose Papper Size</option>
                                            <option value="841 x 1189 mm">A0</option>
                                            <option value="594 x 841 mm">A1</option>
                                            <option value="420 x 594 mm">A2</option>
                                            <option value="297 x 420 mm">A3</option>
                                            <option value="210 x 297 mm">A4</option>
                                            <option value="148 x 210 mm">A5</option>
                                            <option value="105 x 148 mm">A6</option>
                                            <option value="74 x 105 mm">A7</option>
                                            <option value="52 x 74 mm">A8</option>
                                            <option value="37 x 52 mm">A9</option>
                                            <option value="26 x 37 mm">A10</option>
                                        </Form.Select>
                                    </div>
                                )}
                            </Col>
                        </Row>
                    </Form>
                </Offcanvas.Body>
                <div className="offcanvas-footer bg-white">
                    <Button variant="primary" className="fs-14" onClick={() => handleCreateTemplate(true)}>
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
                        <span className="align-middle">Add</span>
                    </Button>
                </div>

            </Offcanvas>
        </React.Fragment>
    );
}
