import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const SweetAlertService = {
  showAlert: (title, text, icon = 'info') => {
    return MySwal.fire({
      title,
      text,
      icon,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    });
  },

  showSuccess: (title, text,icon = 'success') => {
    return MySwal.fire({
      title,
      text,
      icon,
    });
  },
  showError: (title, text,icon = 'error') => {
    return MySwal.fire({
      title,
      text,
      icon,
    });
  },
  // Add more methods as needed
};

export default SweetAlertService;
