import React, { useEffect, useState, useMemo, useContext } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  getCreateOrderSuccess,
  getPaymentModeList,
} from "../../../application/selectors/indexSelector";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

const SettlePaymentSingle = ({
  onPaymentChange,
  resetChild,
  setResetChild,
}) => {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const createOrderSuccess = useSelector(getCreateOrderSuccess);
  const paymentModes = useSelector(getPaymentModeList);
  const reorderedPaymentModes = paymentModes.slice().sort((a, b) => {
    if (a.title === "Cash") return -1;
    if (b.title === "Cash") return 1;
    return 0;
  });
  const [state, setState] = useState({
    selectedPayment: reorderedPaymentModes[0]?.id,
    transactionId: "",
    transactionIdEnabled: { [reorderedPaymentModes[0]?.id]: true },
    cashReceived: "",
    tips: "",
    transactionIds: {},
  });
  useEffect(() => {
    if (resetChild) {
      // Reset the state to initial values when resetChild changes
      setState({
        selectedPayment: reorderedPaymentModes[0]?.id,
        transactionId: "",
        transactionIdEnabled: { [reorderedPaymentModes[0]?.id]: true },
        cashReceived: "",
        tips: "",
        transactionIds: {},
      });
      setResetChild(false);
    }
  }, [resetChild]);

  const TRANSACTION_ID_EMPTY = "";
  const PAYMENT_RESPONSE_INITIAL = "-";
  const SPECIAL_MODES = [
    "a1ca48b8-bfd1-42d6-8db1-7021c131e002",
    "a1ca48b8-bfd1-42d6-8db1-7021c131e003",
  ];

  const handlePaymentChange = (mode) => {
    setState((prev) => ({
      ...prev,
      selectedPayment: mode.id,
      transactionIdEnabled: {
        ...Object.fromEntries(reorderedPaymentModes.map((m) => [m.id, false])),
        [mode.id]: true,
      },
      transactionIds: {
        ...Object.fromEntries(
          reorderedPaymentModes.map((m) => [m.id, TRANSACTION_ID_EMPTY])
        ),
        [mode.id]: TRANSACTION_ID_EMPTY,
      },
      // Reset cashReceived and tips if the selected payment mode is a SPECIAL_MODE
      cashReceived: SPECIAL_MODES.includes(mode.id) ? "" : prev.cashReceived,
      tips: prev.tips,
    }));

    const enteredCash = parseFloat(state.cashReceived) || 0;
    const tips = parseFloat(state.tips) || 0;
    const billAmount = createOrderSuccess?.grandTotalPrice || 0;
    const enteredBalance = enteredCash + tips - billAmount;

    const paymentObject = {
      paymentMode: mode.id,
      transactionId: TRANSACTION_ID_EMPTY,
      paymentResponse: PAYMENT_RESPONSE_INITIAL,
      paidAmount: enteredCash,
      balanceAmount: enteredBalance.toFixed(2),
    };

    onPaymentChange(
      [paymentObject],
      SPECIAL_MODES.includes(mode.id) ? 0 : enteredBalance,
      tips
    );
  };

  const handleValueChange = (key, value) => {
    setState((prev) => {
      const newState = { ...prev, [key]: value };
      // Recalculate cashBalance based on the entered values
      if (
        key === "cashReceived" ||
        key === "transactionIds" ||
        key === "tips"
      ) {
        const billAmount = createOrderSuccess?.grandTotalPrice || 0;
        const enteredCash = parseFloat(newState.cashReceived) || 0;
        const tips = parseFloat(newState.tips) || 0;
        newState.cashBalance = enteredCash - (billAmount + tips);
      }
      const tips = parseFloat(newState.tips) || 0;
      // Call onPaymentChange with updated values
      const paymentObject = {
        paymentMode: newState.selectedPayment,
        transactionId: newState.transactionIds[newState.selectedPayment] || "",
        paymentResponse: "-",
        paidAmount: parseFloat(newState.cashReceived) || 0,
        balanceAmount: newState.cashBalance.toFixed(2),
      };
      onPaymentChange(
        [paymentObject],
        SPECIAL_MODES.includes(newState.selectedPayment)
          ? 0
          : newState.cashBalance,
        tips
      );
      return newState;
    });
  };

  const cashBalance = useMemo(() => {
    const billAmount = createOrderSuccess?.grandTotalPrice || 0;
    const enteredCash = parseFloat(state.cashReceived) || 0;
    const tips = parseFloat(state.tips) || 0;
    return enteredCash - (billAmount + tips);
  }, [createOrderSuccess, state.cashReceived, state.tips]);

  const paymentUI = useMemo(() => {
    return reorderedPaymentModes.map((mode) => (
      <Row key={mode.id} className="mb-2 align-items-center">
        {mode.isVisible && !SPECIAL_MODES.includes(mode.id) && (
          <Col xl={6}>
            <label className="cursor-pointer">
              <input
                type="radio"
                name="payment"
                className="align-middle"
                checked={state.selectedPayment === mode.id}
                onChange={() => handlePaymentChange(mode)}
              />
              <span className="ms-2 fw-bold align-middle text-dark">
                {mode.title}
              </span>
            </label>
          </Col>
        )}
        {mode.isTransactionIdRequired && (
          <>
            <Col xl={5}>
              <Form.Control
                type="text"
                placeholder="Transaction ID"
                value={state.transactionIds[mode.id] || ""}
                disabled={!state.transactionIdEnabled[mode.id]}
                required={mode.isTransactionIdMandatory}
                onChange={(e) =>
                  handleValueChange("transactionIds", {
                    ...state.transactionIds,
                    [mode.id]: e.target.value,
                  })
                }
              />
            </Col>
            {mode.isTransactionIdMandatory && state.transactionIdEnabled[mode.id] && (
              <Col xl={1}>
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`tooltip-top`}>This field is required</Tooltip>
                  }
                >
                  <span>
                    <i className="fs-20 ri-error-warning-line text-danger"></i>
                  </span>
                </OverlayTrigger>
              </Col>
            )}
          </>
        )}
      </Row>
    ));
  }, [
    reorderedPaymentModes,
    state.selectedPayment,
    state.transactionIdEnabled,
    state.transactionIds,
  ]);

  const filteredPaymentModesUI = useMemo(() => {
    return reorderedPaymentModes.map((mode) => (
      <Row key={mode.id} className="mb-2 align-items-center">
        {SPECIAL_MODES.includes(mode.id) && mode.isVisible && (
          <Col xl={6}>
            <label className="cursor-pointer">
              <input
                type="radio"
                name="payment"
                className="align-middle"
                checked={state.selectedPayment === mode.id}
                onChange={() => handlePaymentChange(mode)}
              />
              <span className="ms-2 fw-bold align-middle text-dark">
                {mode.title}
              </span>
            </label>
          </Col>
        )}
      </Row>
    ));
  }, [state.selectedPayment, handlePaymentChange]);

  return (
    <>
      {paymentUI}
      <div className="divider"></div>
      {filteredPaymentModesUI}
      {!SPECIAL_MODES.includes(state.selectedPayment) && (
        <Row className="mb-3 align-items-center">
          <Col xl={8}>
            <h5 className="fs-14 text-dark text-right">Cash Received</h5>
          </Col>
          <Col xl={4} className="text-right">
            <Form.Control
              type="text"
              className="wt-120 d-inline-block"
              value={state.cashReceived}
              onChange={(e) =>
                handleValueChange("cashReceived", e.target.value)
              }
            />
          </Col>
        </Row>
      )}
      <Row className="mb-3 align-items-center">
        <Col xl={8}>
          <h5 className="fs-14 text-dark text-right">Add Tips</h5>
        </Col>
        <Col xl={4} className="text-right">
          <Form.Control
            type="text"
            className="wt-120 d-inline-block"
            value={state.tips}
            onChange={(e) => handleValueChange("tips", e.target.value)}
          />
        </Col>
      </Row>
      <Row className="mb-4 align-items-center">
        <Col xl={8}>
          <h5 className="fs-14 text-dark text-right">Bill Amount</h5>
        </Col>
        <Col xl={4} className="text-right">
          <h5 className="fs-20 text-dark fw-semibold">
            {parsedBranchData?.currencySymbol}{" "}
            {createOrderSuccess?.grandTotalPrice}
          </h5>
        </Col>
      </Row>

      {state.cashReceived && (
        <Row className="align-items-center">
          <Col xl={8}>
            <h5 className="fs-14 text-dark text-right">Cash Balance</h5>
          </Col>
          <Col xl={4} className="text-right">
            <h5
              className={`fs-20 fw-bold ${
                cashBalance >= 0 ? "text-success" : "text-danger"
              }`}
            >
              {parsedBranchData?.currencySymbol}{" "}
              {Math.abs(cashBalance).toFixed(2)}
            </h5>
          </Col>
        </Row>
      )}
    </>
  );
};

export default SettlePaymentSingle;
