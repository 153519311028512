import React from "react";
import { Button, Card, Row, Col, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import bg1 from "../../assets/img/login/side-patch.png";
import whiteLogo from "../../assets/img/login/fe-white-logo.png";

export default function LoginBusiness() {
    const navigate = useNavigate();
    const handleBusinessId = () => {
        navigate('/account/login');
    };

    return (
        <div className="page-sign d-block py-0">
            <Row className="g-0">
                <Col md="7" lg="4" className="col-wrapper">
                    <Card className="card-sign">
                        <Card.Header>
                            <Card.Title>Login to Your Bussiness</Card.Title>
                            <Card.Text className="text-dark">Welcome back! Please Signin to continue.</Card.Text>
                        </Card.Header>
                        <Card.Body>
                            <Form >
                                <div className="mb-4">
                                    <Form.Label>Your Bussiness ID</Form.Label>
                                    <Form.Control type="text" placeholder="Enter Your Bussiness ID" name="BusinessID" />
                                </div>
                                <Button type="button" onClick={handleBusinessId} className="btn-sign">Proceed</Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
                <Col className="d-none d-lg-block">
                    <div className="login-msg">
                        <div className="header-logo mb-3"><img className="wt-200" src={whiteLogo} alt="logo" /></div>
                        <h1 className="text-white fw-semibold">Robust solution for <br /> smart & growing <br /> restaurant</h1>
                    </div>
                    <img src={bg1} className="h-100 w-100" alt="bg" />
                </Col>
            </Row>
        </div>

    )
}