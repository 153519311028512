import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Card, Button, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../layouts/ShimmerTable";
import Select from "react-select";
import caution from "../../assets/img/icons/caution.png";
import { format } from "date-fns";
import { LoadOrderTypeByBranchId } from "../../application/actions/orderTypeAction";
import { useDispatch } from "react-redux";

export default function MappedOrderType(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const [formData, setFormData] = useState({});
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOrderOptions, setSelectedOrderOptions] = useState([]);
  const [global, setGlobal] = useState(true);
  useEffect(() => {
    setGlobal(props?.isGlobal);
  }, [props?.isGlobal]);
  const Onsubmit = (e) => {
    e.preventDefault();
    props?.AssociateOrderType(selectedOptions, selectedOrderOptions);
    setSelectedOptions([]);
    setSelectedOrderOptions([]);
  };
  const deleteMappedBranch = (id) => {
    props?.DissociateOrderType(id);
  };
  const columns = [
    {
      name: " Branch - Order Type",
      selector: (row) => (
        <div>
          {row?.branchName} - {row.typeName}
        </div>
      ),
    },
    {
      name: "Added On",
      selector: (row) => (
        <div>
          <h6 className="fs-14 fw-semibold mb-1">
            {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")}
          </h6>
          <h5 className="fs-12">{row.createdByName}</h5>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <Button
            variant="danger"
            className="btn-icon"
            onClick={() => deleteMappedBranch(row.mappingId)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  const handleChange = (selectedValue) => {
    setFormData({ ...formData, locationId: selectedValue.value });
    setSelectedOptions(selectedValue);
    dispatch(LoadOrderTypeByBranchId(selectedValue.value));
  };
  const handleOrderTypeChange = (selectedValue) => {
    setFormData({ ...formData, orderTypes: selectedValue.value });
    setSelectedOrderOptions(selectedValue);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(true); // Toggle dropdown menu open/close state
  };

  const closeDropdown = () => {
    setIsOpen(false); // Close dropdown menu
  };
  return (
    <React.Fragment>
      {global && (
        <div className="applicabled-view d-flex justify-content-between align-items-center mt-3 mb-4">
          <h3 className="fs-14 d-flex align-items-center gap-1">
            <img src={caution} alt="caution" /> Applicable on all OrderTypes
          </h3>
          <Button
            variant="outline-secondary"
            className="d-flex align-items-center gap-1"
            onClick={() => setGlobal(false)}
          >
            <i className="ri-pencil-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Override</span>
          </Button>
        </div>
      )}
      {!global && (
        <>
          <Row className="mb-3">
            <Col xl="4">
              <Select
                // isMulti
                options={props?.BranchData}
                isSearchable={true}
                value={selectedOptions}
                name="locationId"
                onChange={handleChange}
                placeholder="Choose Location"
                className="react-select-container"
              />
            </Col>

            <Col xl="4">
              <div onBlur={closeDropdown}>
                <div onClick={toggleDropdown}>
                  <Select
                    isMulti
                    options={props?.OrderData}
                    isSearchable={true}
                    value={selectedOrderOptions}
                    name="orderTypes"
                    onChange={handleOrderTypeChange}
                    placeholder="Choose Ordertype"
                    className="react-select-container"
                    menuIsOpen={isOpen}
                    onMenuClose={() => {}}
                  />
                </div>
              </div>
            </Col>
            <Col xl="2">
              <Button variant="primary" type="submit" onClick={Onsubmit}>
                Associate
              </Button>
            </Col>
          </Row>
          <Card className="card-one">
            <Card.Body className="custom-common-table">
              {!props?.loading && (
                <DataTable
                  columns={columns}
                  data={props?.Data}
                  fixedHeader
                  search={true}
                  highlightOnHover
                  pagination
                ></DataTable>
              )}
              {props?.loading &&  <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />}
            </Card.Body>
          </Card>
        </>
      )}
    </React.Fragment>
  );
}
