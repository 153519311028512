import {
  COUPON_LIST_SUCCESS,
  DISCOUNT_LIST_SUCCESS,
  GET_COUPON_BY_ID_SUCCESS,
  GET_DISCOUNT_BY_ID_SUCCESS,
} from "../actions/discountAction";

const initialState = {
  DiscountList: undefined,
  CouponList: undefined,
  CouponData: undefined,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case DISCOUNT_LIST_SUCCESS:
      return {
        ...state,
        DiscountList: action.payload.data.result,
        error: null,
      };
    case GET_DISCOUNT_BY_ID_SUCCESS:
      return { ...state, DiscountData: action.payload.data, error: null };
    case COUPON_LIST_SUCCESS:
      return { ...state, CouponList: action.payload.data.result, error: null };
    case GET_COUPON_BY_ID_SUCCESS:
      return { ...state, CouponData: action.payload.data, error: null };

    default:
      return state;
  }
};

export default reducer;
