import { VENDOR_LIST_SUCCESS } from "../actions/vendorAction";

const initialState = {
    VendorList: undefined,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case VENDOR_LIST_SUCCESS:
      return {
        ...state,
        VendorList: action.payload.result,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
