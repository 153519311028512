import {
  ADD_TO_CART,
  CATEGORY_LIST,
  HOME_DELIVERY,
  LIVE_TABLES,
  ON_HOLD_ORDERS,
  PRE_ORDERS,
  PRODUCT_BY_CATEGORYID,
  SUB_CATEGORY_LIST,
  TAKEAWAY_TABLE,
  loadCategoryData,
  loadHMOrderData,
  loadOnHoldOrderData,
  loadOrderData,
  loadPreOrderData,
  loadProdByCatIdData,
  loadSubCategoryData,
  loadTableData,
} from "../actions/saleActions";
import { loadFailure } from "../actions/loaderAction";
import * as saleActions from "../actions/saleActions";
import ToastifyService from "../../View/_common/ToastifyService";

const getAllCategory =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === CATEGORY_LIST) {
      try {
        const categoryData = await api.saleAPI.getAllCategory(
          action.payload.data
        );
        const CartData = await api.saleAPI.getCartData(action.payload.data);
        const data = {
          categoryData,
          CartData,
        };
        dispatch(loadCategoryData(data));
      } catch (error) {
        dispatch(loadFailure(error));
      }
    }
  };
const getSubCategoryList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === SUB_CATEGORY_LIST) {
      try {
        const subCategoryData = await api.saleAPI.getAllSubCategory(
          action.payload.data
        );
        const ProdByCategoryData = await api.saleAPI.getProductByCategoryId(
          action.payload.data,
          subCategoryData[0].categoryId
        );
        const CartData = await api.saleAPI.getCartData(action.payload.data);
        const data = {
          subCategoryData,
          ProdByCategoryData,
          CartData,
        };
        dispatch(loadSubCategoryData(data));
      } catch (error) {
        dispatch(loadFailure(error));
      }
    }
  };
const AddToCart =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === ADD_TO_CART) {
      try {
        const CartData = await api.saleAPI.addToCart(action.payload.data);
        const catdata = {
          mainCategoryId: action.payload.data.mainCategoryId,
          languageId: "null",
          hubId: "HID04",
          foodType: "all",
          CustomerId: "EMPID01",
        };
        // const ProdByCategoryData = await api.saleAPI.getProductByCategoryId(action.payload.data, subCategoryData[0].categoryId);
        // if(CartData === 200){
        dispatch(saleActions.getSubCategoryList(catdata));
        // }
      } catch (error) {
        dispatch(loadFailure(error));
      }
    }
  };
const getProductByCategoryId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type === PRODUCT_BY_CATEGORYID) {
      try {
        const ProdByCategoryData = await api.saleAPI.getProductByCategoryId(
          action.payload.data
        );
        const subCategoryData = await api.saleAPI.getAllSubCategory(
          action.payload.data,
          ProdByCategoryData[0].mainCategoryId
        );
        const data = {
          ProdByCategoryData,
          subCategoryData,
        };
        dispatch(loadProdByCatIdData(data));
      } catch (error) {
        dispatch(loadFailure(error));
      }
    }
  };
const getAllTables =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type === LIVE_TABLES) {
      try {
        const TableData = await api.saleAPI.getAllTables();
        const TKYOrderData = await api.saleAPI.getTakeawayTables(
          action.payload.data.TKYdata
        );
        const HMOrderData = await api.saleAPI.getTakeawayTables(
          action.payload.data.HMdata
        );
        const PreOrderData = await api.saleAPI.getTakeawayTables(
          action.payload.data.PreOrderdata
        );
        const HoldOrderData = await api.saleAPI.getTakeawayTables(
          action.payload.data.WaitingOrderdata
        );
        const data = {
          TableData,
          TKYOrderData,
          HMOrderData,
          PreOrderData,
          HoldOrderData,
        };
        dispatch(loadTableData(data));
      } catch (error) {
      }
    }
  };

const getTakeawayTables =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (action.type === TAKEAWAY_TABLE) {
      try {
        const TKYOrderData = await api.saleAPI.getTakeawayTables(
          action.payload.data
        );
        const data = {
          TKYOrderData,
        };
        dispatch(loadOrderData(data));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
      }
    } else if (action.type === HOME_DELIVERY) {
      try {
        const HMOrderData = await api.saleAPI.getTakeawayTables(
          action.payload.data
        );
        const data = {
          HMOrderData,
        };
        dispatch(loadHMOrderData(data));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
      }
    } else if (action.type === PRE_ORDERS) {
      try {
        const PreOrderData = await api.saleAPI.getTakeawayTables(
          action.payload.data
        );
        const data = {
          PreOrderData,
        };
        dispatch(loadPreOrderData(data));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
      }
    } else if (action.type === ON_HOLD_ORDERS) {
      try {
        const HoldOrderData = await api.saleAPI.getTakeawayTables(
          action.payload.data
        );
        const data = {
          HoldOrderData,
        };
        dispatch(loadOnHoldOrderData(data));
      } catch (error) {
      }
    }
  };

export default [
  getAllTables,
  getAllCategory,
  getTakeawayTables,
  getSubCategoryList,
  getProductByCategoryId,
  AddToCart,
  // loadTodosFlow
];
