import React, { useEffect, useState, useRef } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { CreateKitchen } from "../../../application/actions/kitchenAction";
export default function AddNewKitchen(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);
    const dispatch = useDispatch();

    const data = {
        kitchenName: "",
        kitchenDescription: "",
        isVisible: 1,
       branchId:0,   
      };
      const [formData, setFormData] = useState(data);
    const handelchange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
    }


const onSubmit =(e)=>{
    e.preventDefault();
 dispatch(CreateKitchen(formData)); 
 setFormData(data);
 props.closeFunction();
}




    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-50">
                <Offcanvas.Header closeButton style={{ borderBottom: '1px solid #ccc' }}>
                    <Offcanvas.Title>Add Kitchen</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Form>
                    <Row>
                        <Col xl="8">
                            <div className="mb-4">
                                <Form.Label>Kitchen Name: <sup className="text-danger">*</sup></Form.Label>
                                <Form.Control type="text" placeholder="Enter Kitchen Name" name="kitchenName" value={formData.kitchenName} onChange={handelchange}/>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="12">
                            <div className="mb-4">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" rows="3" placeholder="Enter Description" name="kitchenDescription" value={formData.kitchenDescription} onChange={handelchange}/>
                            </div>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col xl="6">
                            <div className="mb-4">
                                <Form.Label>IsVisible: <sup className="text-danger">*</sup></Form.Label>
                                <Form.Select aria-label="Default select example" name="isVisible" value={formData.isVisible} onChange={handelchange}>
                                    <option value="1">Active</option>
                                    <option value="2">InActive</option> 
                                  
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl="6">
                            <div className="mb-4">
                                <Form.Label>Location: <sup className="text-danger">*</sup></Form.Label>
                                <Form.Select aria-label="Default select example" name="branchId" value={formData.branchId} onChange={handelchange}>
                                    <option value="0" disabled>Choose Location</option>
                                    <option value="1">Chembur</option>
                                    <option value="2">Nerul</option> 
                                    <option value="3">Mumbra</option>
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>
                    </Form>
                </Offcanvas.Body>

                <div className="offcanvas-footer bg-white">
                    <Button variant="primary" className="fs-14" onClick={onSubmit}>
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i><span className="align-middle">Create New Kitchen</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}