import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { useSelector } from "react-redux";
import { getLoading } from "../../../application/selectors/indexSelector";
import { Row, Col, Card, Button, Table, Form } from "react-bootstrap";
import advancePaymentlist from "../../../Json/Attendance/advance-payment.json";
import PayAdvanceSalary from "./pay-advance-salary";
import PaymentLog from "./payment-log";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import TableShimmerLoader from "../../../layouts/ShimmerTable";

export default function AdvancePayment() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const { loading, value } = useSelector(getLoading);
    const { selectedBranchData } = useContext(AuthContext);
    const parsedBranchData = JSON.parse(selectedBranchData);
    const [showPayAdvanceSal, setShowPayAdvanceSal] = useState(false);
    const handlePayAdvanceSal = (state) => {
        setShowPayAdvanceSal(state);
    };
    const [showPaymentLog, setShowPaymentLog] = useState(false);
    const handlePaymentLog = (state) => {
        setShowPaymentLog(state);
    };

    const [searchTerm, setSearchTerm] = useState('');
    const filteredEmployees = advancePaymentlist?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

    return (
        <React.Fragment>
            <PayAdvanceSalary show={showPayAdvanceSal} closeFunction={handlePayAdvanceSal} />
            <PaymentLog show={showPaymentLog} closeFunction={handlePaymentLog} />
            <div className="text-right mb-3">
                <Button variant="primary" className="fs-14" type="button" onClick={() => handlePayAdvanceSal(true)}>
                    <i className="ri-add-line align-middle me-1"></i> <span className="align-middle">Pay Advance</span>
                </Button>
            </div>

            <Row className="g-3 mb-4">
                <Col sm={12} md={6} xl={3}>
                    <Card className="card-one bg-danger text-white">
                        <Card.Body>
                            <Card.Title as="label" className="fs-14 fw-medium mb-1">Total Advance Balance</Card.Title>
                            <h3 className="card-value text-white"><i className="ri-money-dollar-box-line text-white"></i> {parsedBranchData?.currencySymbol} 42320</h3>
                            <small className="text-white">4 employees with advance balance.</small>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} xl={3}>
                    <Card className="card-one">
                        <Card.Body>
                            <Card.Title as="label" className="fs-14 fw-medium mb-1">Paid this month</Card.Title>
                            <h3 className="card-value"><i className="ri-money-dollar-box-line"></i> {parsedBranchData?.currencySymbol} 5500</h3>
                            <small className="text-dark">Advance Paid in Junary.</small>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} xl={3}>
                    <Card className="card-one">
                        <Card.Body>
                            <Card.Title as="label" className="fs-14 fw-medium mb-1">Paid last month</Card.Title>
                            <h3 className="card-value"><i className="ri-money-dollar-box-line"></i> {parsedBranchData?.currencySymbol} 8000</h3>
                            <small className="text-dark">Advance Paid in March.</small>
                        </Card.Body>
                    </Card>
                </Col>

                <Col sm={12} md={6} xl={3}>
                    <Card className="card-one">
                        <Card.Body>
                            <Card.Title as="label" className="fs-14 fw-medium mb-1">Paid last 6 months</Card.Title>
                            <h3 className="card-value"><i className="ri-money-dollar-box-line"></i> {parsedBranchData?.currencySymbol} 15000</h3>
                            <small className="text-dark">Advance Paid last 6 months.</small>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <div>
                        <h4 className="main-title fs-14 mb-0">Payment Advance</h4>
                    </div>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-250">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {filteredEmployees && filteredEmployees !== undefined && (
                        <Table className="mb-0" responsive>
                            <thead>
                                <tr>
                                    <th>Employee Name</th>
                                    <th>Advance Balance</th>
                                    <th>Previous Pay</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredEmployees?.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.empName}</td>
                                        <td className={`${item.advanceBal > 0 ? "text-danger" : ""}`}><b>{parsedBranchData?.currencySymbol} {item.advanceBal}</b></td>
                                        <td>{item.previouPay}</td>
                                        <td width={150}>
                                            <Button variant="outline-dark" className="d-flex align-items-center" onClick={() => handlePaymentLog(true)}>
                                                <i className="ri-delete-bin-line me-1"></i> View Log
                                            </Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    )}
                    {!loading && filteredEmployees === undefined && (
                        <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                    )}
                    {loading &&
                        value === "salaryPaymentList" && filteredEmployees === undefined && (
                            <TableShimmerLoader colNames={filteredEmployees?.map((column) => column.name)} colsCount={1} />
                        )}
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}