import React, {  useEffect, useState } from "react";
import {  Offcanvas, Tabs, Tab } from "react-bootstrap";
import UserBasicInfo from "./BasicInfo/basic-info";
import MappedUsers from "./MappedUsers/mappedUser";
import UserPermissions from "./Permissions/permissions";
import { useDispatch } from "react-redux";
import { AccessByUserRoleId,  UserRoleDetail } from "../../../application/actions/userAction";

export default function UpdateUserRole(props) {
    const dispatch= useDispatch()
    const [tabUserRole, setTabUserRole] = useState('basic_info_tab');
    const handleUserRoleTab = (tab) => {
        setTabUserRole(tab);
    };
useEffect(()=>{
    if(props?.data?.id !== undefined){
         dispatch(AccessByUserRoleId(props?.data?.id))
         dispatch(UserRoleDetail(props?.data?.id))
        // dispatch(EmployeeListByRoleId(props?.id))
        }
},[props.data])   
    return(
        <React.Fragment>
             <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Update Role</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                <Tabs id="custom-styled-tabs" activeKey={tabUserRole} onSelect={handleUserRoleTab} className="sale-on-point-tabs">
                <Tab eventKey="basic_info_tab" title={<>Basic Info</>} tabClassName="custom-tab-header fw-semibold">
                            <div className="custom-tab-body mt-3">
                            <UserBasicInfo closeFunction={props.closeFunction} data={props?.data}/>
                            </div>
                        </Tab>

                        <Tab eventKey="permissions_tab" title={<>Permissions</>} tabClassName="custom-tab-header fw-semibold">
                            <div className="custom-tab-body mt-3">
                                <UserPermissions id={props?.data?.id}/>
                            </div>
                        </Tab>

                        <Tab eventKey="mapped_users_tab" title={<>Mapped Users</>} tabClassName="custom-tab-header fw-semibold">
                            <div className="custom-tab-body mt-3">
                               <MappedUsers employeeList={props?.employeeList} data={props?.data}/>
                            </div>
                        </Tab>
                </Tabs>
                </Offcanvas.Body>
             </Offcanvas>
        </React.Fragment>
    )
}