import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Offcanvas, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { CreateOrderType } from "../../../../application/actions/orderTypeAction";
import { validateRequired } from "../../../../infrastructure/core/validationUtils";

export default function AddNewOrderType(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const initialState = {
    typeName: "",
    typeDescription: "-",
    isTableSection: false,
    branchId: props?.branch?.id
  };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const validateName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.typeName = validateRequired(formData.typeName)
      ? ""
      : "Order Type Name is required";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value, branchId: props?.branch?.id });
  };
  const handleToggle = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value === "on" ? true : false });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const IsValid = validateName();
    if (!IsValid) {
      return;
    }
    dispatch(CreateOrderType(formData))
    handleClose()
  };
  const handleClose = () => {
    props.closeFunction(false)
    setFormData(initialState)
    setErrors({})
  }
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-40"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Add New Order Type
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col xl="8">
              <div className="mb-4">
                <Form.Label>
                  Order Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Order Type Name"
                  name="typeName"
                  onChange={handleChange}
                />
                <Form.Text className="text-danger">
                  {errors.typeName}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Check
                  type="switch"
                  label="Allow Dine-in with Table Sections"
                  className="me-3 fs-16 fw-semibold text-dark"
                  name="isTableSection"
                  onChange={handleToggle}
                />
              </div>
            </Col>
          </Row>

          {/* <Row>
            <Col xl="6">
              <Form.Check
                type="switch"
                label="Has Timeslot"
                className="me-3 fs-16 fw-semibold text-dark"
                name="timeSlot"
              />
            </Col>
          </Row> */}
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            type="submit"
            onClick={handleSubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Create New Order Type</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
