import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import "./merge-table.scss";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { mergeTable } from "../../../application/actions/posAction";

export default function MergeTable(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const alreadyMergedTables = props?.selectedTable?.mergedTables?.map(
    (table) => ({
      value: table.tableId,
      label: table.tableName,
    })
  );
  useEffect(() => {
    if (props?.show) {
      setSelectedValues(alreadyMergedTables);
    }
  }, [props?.show]);

  const sectionListByOrderType = props?.sectionByOrderTypeId?.map(
    (section) => ({
      value: section.id,
      label: section.name,
    })
  );
  const branchData = JSON.parse(localStorage.getItem("branch"));
  const [tableListBySection, setTableListBySection] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [selectedSection, setSelectedSection] = useState([]);

  const sectionChange = (e) => {
    setSelectedSection(e?.value);
    const newTableList =
      props?.sectionByOrderTypeId
        ?.find((section) => section?.id === e?.value)
        ?.dineInTables?.filter(
          (tables) =>
            tables.id !== props?.selectedTable &&
            tables.tableStatus === "d9c5a952-911e-4a0f-8935-5e1fae02401e"
        )
        ?.map((table) => ({
          value: table.id,
          label: table.tableName,
        })) || [];
    const filteredTableList = newTableList.filter(
      (table) =>
        !selectedValues.some(
          (selectedValue) => selectedValue.label === table.label
        )
    );
    setTableListBySection(filteredTableList);
  };
  const handleSelect = (selectedOption) => {
    const updatedTableList = tableListBySection.filter(
      (table) => table.label !== selectedOption.label
    );
    setTableListBySection(updatedTableList);
    setSelectedValues([...selectedValues, selectedOption]);
  };
  const handleRemove = (selectedValue) => {
    const updatedSelectedValues = selectedValues.filter(
      (value) => value.value !== selectedValue // Compare value instead of the label
    );
    const tableToAddBack = selectedValues.find(
      (table) => table.value === selectedValue
    );
    setSelectedValues(updatedSelectedValues);
    if (tableToAddBack) {
      setTableListBySection([...tableListBySection, tableToAddBack]);
    }
  };
  const handleRemoveAll = () => {
    setSelectedValues([]);
    const newTableList = props?.sectionByOrderTypeId
      ?.find((section) => section?.id === selectedSection)
      ?.dineInTables.filter(
        (tables) =>
          tables.tableStatus === "d9c5a952-911e-4a0f-8935-5e1fae02401e"
      )
      .map((table) => ({
        value: table.id,
        label: table.tableName,
      }));

    setTableListBySection(newTableList);
  };
  const handleSubmit = () => {
    const formObject = {
      parentTable: props?.selectedTable?.id,
      branchId: branchData.value,
      childTables: selectedValues?.map(
        (selectedTables) => selectedTables?.value
      ),
    };
    const orderType = props?.orderType?.id;
    const formDetails = {
      formObject: formObject,
      orderType: orderType,
    };
    dispatch(mergeTable(formDetails));
    props?.closeFunction();
    props?.setIsDataLoaded(true);
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.closeFunction}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-16 text-dark">
            Merge Table - ({props?.selectedTable?.tableName})
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>
                  Section Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Select
                  options={sectionListByOrderType}
                  isSearchable={true}
                  onChange={(e) => {
                    sectionChange(e);
                  }}
                />
              </div>
            </Col>

            <Col xl="12">
              <div className="mb-4">
                <Form.Label>
                  Table Name <sup className="text-danger">*</sup>
                </Form.Label>
                <Select
                  onChange={handleSelect}
                  options={tableListBySection}
                  isSearchable={true}
                />
              </div>
            </Col>
          </Row>
          <div>
            {selectedValues?.length > 0 && (
              <div className="all-remove-table mt-2 mb-3">
                <Button
                  variant="outline-dark"
                  className="fs-14"
                  onClick={handleRemoveAll}
                >
                  Remove All
                </Button>
              </div>
            )}
            <ul className="table-selected-badge gap-2">
              {selectedValues?.map((data, index) => (
                <li key={index}>
                  {data.label}
                  <Button onClick={() => handleRemove(data.value)}>
                    <i className="ri-close-line"></i>
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="outline-dark" onClick={props?.closeFunction}>
            Close
          </Button>
          <Button variant="danger" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}
