import React from 'react'
import { Table } from 'react-bootstrap'

function TableShimmerLoader({ colsCount, colNames }) {
  const renderTableRows = () => {
    const rows = [];
    for (let i = 0; i < 3; i++) {
      rows.push(
        <tr key={i}>
          <>
            {colNames?.map((_, index) => (
              <td key={index} className="p-2">
                <div className="table-shimmer w-100"></div>
              </td>
            ))}
          </>
        </tr>
      );
    }
    return rows;
  };

  return (
   <div className='table-shommer-effect'>
     <Table className="w-100">
      <tbody>{renderTableRows()}</tbody>
    </Table>
   </div>
  );
}

export default TableShimmerLoader