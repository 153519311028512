import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Form, Button, Table } from "react-bootstrap";
//import Paymentlist from "../../../../Json/setting/payment-mode.json";
import AddPaymentMode from "./add-payment-mode";
import SweetAlertService from "../../../_common/SweetAlertService";
import ToastifyService from "../../../_common/ToastifyService";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentModeList } from "../../../../application/selectors/indexSelector";
import {
  DeletePaymentMode,
  PaymentModeListByBranchId,
  UpdatePaymentModeVisibility,
} from "../../../../application/actions/paymentModeAction";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";

export default function PaymentModeComponent() {
  const dispatch = useDispatch();
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  useEffect(() => {
    Prism.highlightAll();
    dispatch(PaymentModeListByBranchId(parsedBranchData?.id));
  }, []);

  const PaymentModeList = useSelector(getPaymentModeList);
  const [showAddMode, setShowAddMode] = useState(false);
  const [selectedData, setSelectedData] = useState(undefined);
  const handleAddMode = (data, state) => {
    setSelectedData(data);
    setShowAddMode(state);
  };

  const handleDeletePaymentMode = (id) => {
    SweetAlertService.showAlert(
      "Delete Payment Mode",
      "Are you sure you want to delete this Payment Mode?",
      "warning"
    )
      .then((result) => {
        if (result.isConfirmed) {
          dispatch(DeletePaymentMode(id));
          SweetAlertService.showSuccess("Success", "Payment Mode!");
          ToastifyService.success(`Deleted Payment Mode Successfully`);
        } else {
          console.log("Delete operation cancelled!");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        ToastifyService.error(`something went wrong`);
      });
  };
  const handleVisibility = (e, id) => {
    e.preventDefault();
    const formObject={
      id:id,
      branchId:parsedBranchData.id
    }
    dispatch(UpdatePaymentModeVisibility(formObject));
  };
  return (
    <React.Fragment>
      <AddPaymentMode
        show={showAddMode}
        closeFunction={handleAddMode}
        selectedData={selectedData}
      />
      <div className="top-header d-md-flex align-items-center justify-content-between mb-4 position-static">
        <div>
          <h4 className="fs-16 main-title mb-1">Setup the Payment Mode</h4>
          <h5 className="fs-14 text-secondary">
            Define the payment mode for the bill payment settelment
          </h5>
        </div>
        <div className="d-flex gap-2 mt-3 mt-md-0">
          <Button
            variant="primary"
            className="d-flex align-items-center gap-1"
            onClick={() => handleAddMode(undefined, true)}
          >
            <i className="ri-add-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Add Mode</span>
          </Button>
        </div>
      </div>

      <Table className="mb-0" responsive>
        <thead>
          <tr>
            <th className="wt-40">Status</th>
            <th>Payment Mode</th>
            <th>Transaction ID</th>
            <th>Create On</th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody>
          {PaymentModeList?.map((item, index) => (
            <tr key={index}>
              <td>
                <Form.Check
                  type="switch"
                  name={`isVisible${item.id}`}
                  className="me-3 fs-16 fw-semibold"
                  checked={item.isVisible}
                  onChange={(e) => handleVisibility(e, item.id)}
                />
              </td>
              <td>{item.title}</td>
              <td>
                {item.isTransactionIdRequired ? "Required" : "Not Required"}
                {item.isTransactionIdRequired &&
                  ` / ${
                    item.isTransactionIdMandatory
                      ? "Mandatory"
                      : "Not Mandatory"
                  }`}
              </td>
              <td>
                <h6 className="fs-14 fw-semibold mb-1">{item.createOn}</h6>
                <h5 className="fs-12">System</h5>
              </td>
              <td className="w-0">
                {item.isDefault ? (
                  "Default"
                ) : (
                  <div className={`d-flex justify-content-end`}>
                    <Button
                      variant="primary"
                      className="btn-icon me-2"
                      onClick={() => handleAddMode(item, true)}
                    >
                      <i className="ri-pencil-line"></i>
                    </Button>
                    <Button
                      variant="danger"
                      className="btn-icon"
                      onClick={() => handleDeletePaymentMode(item.id)}
                    >
                      <i className="ri-delete-bin-line"></i>
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
}
