import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const AddVariance = async (data) => {
  let VarData = {
    id: data.id,
    status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
    isVisible: data.isVisible,
    isDeleted: data.isDeleted,
    modifiedBy: data.createdBy,
    catalogueId: data.catalogueId,
    variantMode: Environment.defaultValue,
    variantType: data.variantType
      ? data.variantType
      : "00000000-0000-0000-0000-000000000000",
    variantName: data.variantName ? data.variantName : "NoVar",
    sizeId: data.sizeId ? data.sizeId : "00000000-0000-0000-0000-000000000000",
    sellingPrice: data.sellingPrice,
    markupPrice: data.markupPrice,
    preparationPrice: data.preparationPrice,
    measurmentTypeId: data.measurmentTypeId,
    isGlobal:data.locations.length === 0?true:false,
    locations: data.locations ? data.locations : [],
    branchId:Environment.defaultValue
  };
  //     Varlist.push(VarData);
  //  }
  const URL =
    data.id !== undefined
      ? `${Environment.Catalogue_Url}update-size-variance`
      : `${Environment.Catalogue_Url}add-size-variance`;
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(URL, VarData, {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
    authorization: `Bearer ${storedToken}`,
  });
  return response.data;
};
const VarianceSizeList = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Catalogue_Url}size-variance/${data}`,
    {
      method: "GET",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        authorization: `Bearer ${storedToken}`,
      },
    }
  );
  return response.data;
};
const SetVariantModeType = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-size-variant-mode/${data.catalogueId}/${data.variantTypeId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const ResetAllBranches = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}reset-size-variance/${data}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const ResetAllByBranchId = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}reset-size-variance/${data.branchId}/${data.catalogueId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const CopyVariance = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}add-size-variance`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const BranchVarianceFeaturesUpdate = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-variance-features`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const GetCommonFieldsTypeByGroupCode = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.SetUp_Url}common-field-type-by-group/${data}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const MapBranchVariance = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}map-branch-size-variance`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const UnMapBranchVariance = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}remove-branch-size-varience/${data}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const UnMapGlobalVarianceById = async (variantId) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}delete-size-variance/${variantId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const UpdateBranchVariance = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Catalogue_Url}update-branch-size-variance`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};

export default {
  UpdateBranchVariance,
  UnMapGlobalVarianceById,
  MapBranchVariance,
  GetCommonFieldsTypeByGroupCode,
  AddVariance,
  VarianceSizeList,
  ResetAllBranches,
  ResetAllByBranchId,
  SetVariantModeType,
  CopyVariance,
  BranchVarianceFeaturesUpdate,
  UnMapBranchVariance
};
