import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const GetItemDetailOnPOS = async (branchId, id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Catalogue_Url}detail/${branchId}/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const GetPOSMenuDataByBranchId = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Sync_Url}pos-menu-data/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const GetWaiterListPOS = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.User_Url}employee-by-branch/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const getDiscountByBranchId = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Sales_Url}branch-discount/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const getChargeByBranchId = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Sales_Url}branch-charge/${data.branchId}/${data.orderTypeId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const orderDetails = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Sales_Url}order-details/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const CreateOrder = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}create-order`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const UpdateOrder = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}add-order-items/${data.id}`,
    data.orderData,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const cancelOrder = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}cancel-order/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const applyCharges = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}apply-charge/${data.salesId}/${data.chargeId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const applyDiscount = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}apply-discount/${data.salesId}/${data.selectedDiscount}/${data.fixedDiscount}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const removeDiscount = async (salesId) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}remove-discount/${salesId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const removeCharges = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}remove-charge/${data.salesId}/${data.chargeId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const deleteOrderedItem = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}delete-order-items/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const deleteAllOrderedItem = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}confirm-delete-kot`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const transferOrder = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.DashBoard_Url}transfer-order`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const tableSectionByOrderType = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Setting_Url}table-section-by-order-type/${data.branchId}/${data.orderTypeId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const ordersByOrderType = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Sales_Url}orders-by-order-type/${data.branchId}/${data.orderTypeId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const orderSettlePayment = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}order-settle-payment`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const closeOrder = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}close-order/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const orderWaiterChange = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}update-order-waiter/${data.orderId}/${data.waiterId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const mergeTable = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.DashBoard_Url}merge-table`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const increKotprintCount = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}kot-print`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const changeOrderStatus = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}update-order-status`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};

export default {
  GetItemDetailOnPOS,
  CreateOrder,
  GetPOSMenuDataByBranchId,
  GetWaiterListPOS,
  UpdateOrder,
  getDiscountByBranchId,
  getChargeByBranchId,
  cancelOrder,
  orderDetails,
  applyCharges,
  applyDiscount,
  removeDiscount,
  removeCharges,
  deleteOrderedItem,
  transferOrder,
  tableSectionByOrderType,
  ordersByOrderType,
  orderSettlePayment,
  closeOrder,
  orderWaiterChange,
  mergeTable,
  increKotprintCount,
  deleteAllOrderedItem,
  changeOrderStatus
};
