import React, { useEffect, useState } from "react";
import { Form, Tabs, Tab } from "react-bootstrap";
export default function SaleReport() {
    const currentSkin = (localStorage.getItem('skin-mode')) ? 'dark' : '';
    const [skin, setSkin] = useState(currentSkin);
    const switchSkin = (skin) => {
        if (skin === 'dark') {
            const btnWhite = document.getElementsByClassName('btn-white');

            for (const btn of btnWhite) {
                btn.classList.add('btn-outline-primary');
                btn.classList.remove('btn-white');
            }
        } else {
            const btnOutlinePrimary = document.getElementsByClassName('btn-outline-primary');

            for (const btn of btnOutlinePrimary) {
                btn.classList.remove('btn-outline-primary');
                btn.classList.add('btn-white');
            }
        }
    }

    switchSkin(skin);

    useEffect(() => {
        switchSkin(skin);
    }, [skin]);

    const [activeTab, setActiveTab] = useState('pending_order_tab');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    return (
        <React.Fragment>
            <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="sale-on-point-tabs bg-transparent">
                <Tab eventKey="pending_order_tab" title={<>Pending Order <span className="badge rounded-pill badge-length-web">2</span></>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3 text-dark">
                       Pending Order
                    </div>
                </Tab>
                <Tab eventKey="closed_tab" title={<>Closed <span className="badge rounded-pill badge-length-web">0</span></>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3 text-dark">
                        Closes
                    </div>
                </Tab>
                <Tab eventKey="cancelled_tab" title={<>Cancelled <span className="badge rounded-pill badge-length-web">0</span></>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3 text-dark">
                        Cancelled
                    </div>
                </Tab>
                <Tab eventKey="pending_payment_tab" title={<>Payment Pending <span className="badge rounded-pill badge-length-web">0</span></>} tabClassName="custom-tab-header fw-semibold">
                    <div className="custom-tab-body pt-3 text-dark">
                        Pending Payment
                    </div>
                </Tab>
            </Tabs>
        </React.Fragment>
    )
}