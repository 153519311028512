import React, { useEffect, useState, useRef } from "react";
import Prism from "prismjs";
import {
  Button,
  Offcanvas,
  Tabs,
  Tab,
  Row,
  Col,
  Form,
  Table,
  Badge,
} from "react-bootstrap";
import { useDispatch } from "react-redux";
import { UpdateKitchen } from "../../../application/actions/kitchenAction";
export default function EditKitchen(props) {
  const initialState = {
    id: "",
    branchId: 0,
    isVisible: "",
    kitchenName: "",
    kitchenDescription: "",
  };
  const [formData, setFormData] = useState(initialState);

  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const dispatch = useDispatch();
  useEffect(() => {
    if (props.data) {
      setFormData({
        id: props.data.id,
        branchId: props.data.branchId,
        kitchenName: props.data.kitchenName,
        kitchenDescription: props.data.kitchenDescription,
        isVisible: props.data.isVisible,
      });
    }
  }, [props.data]);

  const [activeTab, setActiveTab] = useState("details");

  const handleTabSelect = (item, tab) => {
    setActiveTab(tab);
  };

  const onSubmit =(e)=>{
    e.preventDefault();
 dispatch(UpdateKitchen(formData)); 
 setFormData(initialState);
 props.closeFunction();
  }

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-50"
      >
        <Offcanvas.Header
          closeButton>
          <Offcanvas.Title>Edit Kitchen</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div className="mb-3">
            <h2 className="fs-18 mb-2 fw-semibold">Juice Center</h2>
            <h5 className="fs-14">No Description</h5>
          </div>

          <Tabs
            id="custom-styled-tabs"
            activeKey={activeTab}
            onSelect={handleTabSelect}
            className="sale-on-point-tabs"
          >
            <Tab
              eventKey="details"
              title={
                <>
                  <h6 className="fs-14 mb-0">Details</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body mt-3">
                <Row>
                  <Col xl="8">
                    <div className="mb-4">
                      <Form.Label>
                        Kitche Name: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Group Title"
                        name="kitchenName"
                        value={formData.kitchenName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            kitchenName: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12">
                    <div className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows="3"
                        placeholder="Enter Description"
                        name="kitchenDescription"
                        value={formData.kitchenDescription}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            kitchenDescription: e.target.value,
                          })
                        }
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6">
                    <div className="mb-4">
                      <Form.Label>
                        IsVisible: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="isVisible"
                        value={formData.isVisible ? "1" : "2"}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            isVisible: e.target.value === "1",
                          })
                        }
                      >
                        <option value="1">Active</option>
                        <option value="2">Inactive</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xl="6">
                    <div className="mb-4">
                      <Form.Label>
                        Location: <sup className="text-danger">*</sup>
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="branchId"
                        value={formData.branchId}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            branchId: e.target.value,
                          })
                        }
                      >
                        <option value="" disabled>
                          Choose Location
                        </option>
                        <option value="1">Chembur</option>
                        <option value="2">Nerul</option>
                        <option value="3">Mumbra</option>
                      </Form.Select>
                    </div>
                  </Col>
                </Row>
              </div>
            </Tab>

            <Tab
              eventKey="AssociatedMenu"
              title={
                <>
                  <h6 className="fs-14 mb-0">
                    Associated Menu{" "}
                    <Badge bg="danger" pill>
                      1
                    </Badge>
                  </h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body mt-3">
                <Table className="mb-0">
                  <thead>
                    <tr>
                      <th>Menu Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr>
                      <td>
                        <h5 className="fs-14 mb-1 fw-semibold">Beverages</h5>
                        <h6 className="fs-11">no description</h6>
                      </td>

                      <td className="w-0">
                        <Button
                          variant="danger"
                          className="d-flex align-items-center gap-2"
                        >
                          <i className="ri-delete-bin-line"></i>
                          <span className="d-sm-inline">Disassociate</span>
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button variant="primary" className="fs-14" onClick={onSubmit}>
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Save Changes</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
