import React, { useState } from "react";
import "./social-handle.scss"
import { Card, Image, Form, Row, Col } from "react-bootstrap";
import fbIcon from "../../../../assets/img/icons/social-handles/facebook.png";
import youtubeIcon from "../../../../assets/img/icons/social-handles/youtube.png";
import googleIcon from "../../../../assets/img/icons/social-handles/google.png";
import whatsappIcon from "../../../../assets/img/icons/social-handles/whatsapp.png";
import instagramIcon from "../../../../assets/img/icons/social-handles/instagram.png";
import twitterIcon from "../../../../assets/img/icons/social-handles/twitter.png";
import snapchatIcon from "../../../../assets/img/icons/social-handles/snapchat.png";
import pinterestIcon from "../../../../assets/img/icons/social-handles/pinterest.png";
import linkedinIcon from "../../../../assets/img/icons/social-handles/linkedin.png";
import mapIcon from "../../../../assets/img/icons/social-handles/google-maps.png";

export default function SocialHandles() {
    const socialIcons = [
        { name: "Facebook", socialIcon: fbIcon, value: "https://www.facebook.com/arabiandastar" },
        { name: "Youtube", socialIcon: youtubeIcon, value: "https://www.youtube.com/watch?v=by8qujyfcjU" },
        { name: "Google", socialIcon: googleIcon, value: "arabiandastar.com" },
        { name: "Whatsapp", socialIcon: whatsappIcon, value: "" },
        { name: "Instagram", socialIcon: instagramIcon, value: "https://www.instagram.com/arabian_dastar/" },
        { name: "Twitter", socialIcon: twitterIcon, value: "" },
        { name: "Snapchat", socialIcon: snapchatIcon, value: "" },
        { name: "Pinterest", socialIcon: pinterestIcon, value: "" },
        { name: "Linkedin", socialIcon: linkedinIcon, value: "" },
        { name: "Google Map", socialIcon: mapIcon, value: "" },
    ];

    const [switchStates, setSwitchStates] = useState(socialIcons.map(() => false));

    const handleSwitchChange = (index) => {
        setSwitchStates((prevStates) =>
            prevStates.map((state, i) => (i === index ? !state : state))
        );
    };

    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="main-title fs-16 mb-0">Social Handle Links</h4>
                </div>
            </div>

            {socialIcons.map((item, index) => (
                <Card className="card-one mb-2 social-handle-row">
                    <Card.Body>
                        <Row className="g-3">
                            <Col xl={1} md={2} sm={3}>
                                <div className="text-center">
                                    <Form.Check type="switch" checked={switchStates[index]} onChange={() => handleSwitchChange(index)} />
                                </div>
                            </Col>
                            <Col className={`position-relative ${switchStates[index] ? 'enable-class' : 'disable-class'}`} xl={11} md={10} sm={9}>
                                <div className="d-flex align-items-center gap-3 mb-3">
                                    <div className="wt-30 ht-30">
                                        <Image src={item.socialIcon} alt="icon" className="w-100 h-100 object-fit-contain" />
                                    </div>
                                    <span className="fw-semibold fs-14 text-dark">{item.name}</span>
                                </div>

                                <div>
                                    <Form.Control type="text" value={item.value} className="wt-400" placeholder="Social Link...." />
                                    <h6 className="fs-12 mt-2">This is a sample text</h6>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            ))}
        </React.Fragment>
    )
}