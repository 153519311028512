import React, { useEffect } from "react";
import Prism from "prismjs";
import { Form, Row, Col } from "react-bootstrap";

export default function PrintTemplate() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);


    return (
        <React.Fragment>
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="main-title fs-16 mb-0">Print Template</h4>
                </div>
            </div>
            <Row>
                <Col xl="6">
                    <div className="mb-4">
                        <Form.Label>KOT Slip</Form.Label>
                        <Form.Select aria-label="Default select example" name="isVisible">
                            <option>Choose KOT Slip</option>
                            <option value="KOT Slip">KOT Slip</option>
                            <option value="Store Bill">Store Bill</option>
                            <option value="Online Invoice">Online Invoice</option>
                        </Form.Select>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xl="6">
                    <div className="mb-4">
                        <Form.Label>Store Bill Print</Form.Label>
                        <Form.Select aria-label="Default select example" name="isVisible">
                            <option>Choose Bill Print</option>
                            <option value="KOT Slip">KOT Slip</option>
                            <option value="Store Bill">Store Bill</option>
                            <option value="Online Invoice">Online Invoice</option>
                        </Form.Select>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xl="6">
                    <div>
                        <Form.Label>Online Invoice</Form.Label>
                        <Form.Select aria-label="Default select example" name="isVisible">
                            <option>Choose Online Invoice</option>
                            <option value="KOT Slip">KOT Slip</option>
                            <option value="Store Bill">Store Bill</option>
                            <option value="Online Invoice">Online Invoice</option>
                        </Form.Select>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}