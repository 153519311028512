import { LOAD_ADDONS, LOAD_ITEM_CATALOG, LOAD_ITEM_CATALOG_BY_ID, LOAD_MENU,LOAD_KITCHEN, LOAD_TAX, LOAD_DISCOUNT,LOAD_CHARGES, LOAD_BRANCH, LOAD_TAX_BY_TENANT} from "../actions/loaderAction";
import * as item from '../actions/ItemAction';
import * as addons from '../actions/addOnsAction';
import * as menu from '../actions/menuAction';
import * as kitchen from '../actions/kitchenAction';
import * as tax from '../actions/taxAction';
import * as discount from '../actions/discountAction';
import * as branch from '../actions/branchAction';
import * as charges from '../actions/chargesAction';


const loadItemCatalog = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_ITEM_CATALOG) {
    log('page loaded');
    
    dispatch(item.GetItemCatalog);
  }
  next(action);
}
const loadAddOns = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_ADDONS) {
    log('page loaded');
    
    dispatch(addons.GetAddOnsGroupList);
  }
  next(action);
}
const loadItemCatalogById = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_ITEM_CATALOG_BY_ID) {
    log('page loaded');
    
    dispatch(item.GetCatalogueItemById(action.payload));
  }
  next(action);
}
const loadMenu = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_MENU) {
    log('page loaded');
    
    dispatch(menu.GetMenuList);
  }
  next(action);
}
const loadKitchen = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_KITCHEN) {
    log('page loaded');
    
    dispatch(kitchen.GetKitchenList(action.payload));
  }
  next(action);
}
const loadTax = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_TAX) {
    log('page loaded');
    
    dispatch(tax.GetTaxList);
  }
  next(action);
}
const loadTaxByTenant = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_TAX_BY_TENANT) {
    log('page loaded');
    dispatch(tax.GetTaxListByTenant(action.payload));
  }
  next(action);
}
const loadDiscount = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_DISCOUNT) {
    log('page loaded');
    
    dispatch(discount.GetDiscount);
  }
  next(action);
}
const loadBranch = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_BRANCH) {
    log('page loaded');
    
    dispatch(branch.GetBranch(action.payload.data));
  }
  next(action);
}
const loadCharges = ({ log }) => ({ dispatch }) => (next) => (action) => {
  if (action.type === LOAD_CHARGES) {
    log('page loaded');
    dispatch(charges.GetChargesList);
  }
  next(action);
}
export default [
  loadItemCatalog,loadAddOns,loadItemCatalogById,loadMenu,loadKitchen,loadTax,loadDiscount,
 loadBranch, loadCharges,loadTaxByTenant
];
