import React, { useEffect } from "react";
import Prism from "prismjs";
import { Offcanvas, Table, Button } from "react-bootstrap";

export default function PrintView(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const handlePrintClick = () => {
    const printableDiv = document.getElementById("printable-section");
    if (printableDiv) {
      const printWindow = window.open("");
      printWindow.document.write(printableDiv.innerHTML);
      printWindow.document.close();
      printWindow.print();
    }
  };

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
        <Offcanvas.Header className="d-block custom-offcanvas-header pe-0 ps-0" closeButton>
          <Offcanvas.Title className="pe-3 ps-3">Bill Print</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table style={{ width: "100%", borderCollapse: "collapse" }} responsive id="printable-section">
            <tbody>
              <tr>
                <td colSpan={2} style={{ border: 0 }}>
                  <h3 style={{ color: "#000", fontWeight: "600", textAlign: "center", textTransform: "uppercase", fontSize: "20px", marginBottom: "10px" }}>Dine-In</h3>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", fontSize: "14px", fontWeight: "500", border: 0 }}>
                  KOT: <span style={{ fontWeight: 500, fontStyle: "normal", whiteSpace: "normal", fontSize: "14px" }}>1232/21</span>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", fontSize: "14px", fontWeight: "500", border: 0 }}>
                  Kitchen: <span style={{ fontWeight: 500, fontStyle: "normal", whiteSpace: "normal", fontSize: "14px" }}>Juice Center</span>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", border: 0, paddingBottom: 0, paddingTop: 0 }}>
                  <hr style={{ marginTop: "10px", marginBottom: "10px", border: "none", borderBottom: "dashed 1px #000000" }} />
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", fontSize: "14px", fontWeight: "500", border: 0 }}>
                  <h6 style={{ fontWeight: 500, fontStyle: "normal", whiteSpace: "normal", fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}><span>Section:</span> Ground Floor</h6>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", fontSize: "14px", fontWeight: "500", border: 0 }}>
                  <h6 style={{ fontWeight: 500, fontStyle: "normal", whiteSpace: "normal", fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}><span>Table:</span> T1</h6>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", fontSize: "14px", fontWeight: "500", border: 0 }}>
                  <h6 style={{ fontWeight: 500, fontStyle: "normal", whiteSpace: "normal", fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}><span>Server:</span> Aamir Sayyed</h6>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", border: 0, paddingBottom: 0, paddingTop: 0 }}>
                  <hr style={{ marginTop: "10px", marginBottom: "10px", border: "none", borderBottom: "dashed 1px #000000" }} />
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ border: 0 }}>
                  <h3 style={{ color: "#000", fontSize: "16px", textTransform: "uppercase" }}>Items</h3>
                </td>
              </tr>

              <tr>
                <td style={{ color: "#000", border: 0 }} colSpan={2}>
                  <h6 style={{ display: "flex", flexWrap: "wrap", alignItems: "center", fontWeight: 500, fontStyle: "normal", whiteSpace: "normal", fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}>
                    <span style={{ display: "inline-block", flex: "0 40px" }}>1 x</span>{" "}
                    <span>Mango Milk Shake - Full CM:</span> {" "}
                    <span>Soya Milk, Extra Mango Pulp, Vanilla Ice Cream (1)</span>
                  </h6>
                </td>
              </tr>

              <tr>
                <td style={{ color: "#000", border: 0 }} colSpan={2}>
                  <h6 style={{ display: "flex", alignItems: "center", fontWeight: 500, fontStyle: "normal", whiteSpace: "normal", fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}>
                    <span style={{ display: "inline-block", flex: "0 40px" }}>1 x</span>{" "}
                    <span>Cheese Garlic Bread - Big</span>
                  </h6>
                </td>
              </tr>

              <tr>
                <td style={{ color: "#000", border: 0 }} colSpan={2}>
                  <h6 style={{ display: "flex", alignItems: "center", fontWeight: 500, fontStyle: "normal", whiteSpace: "normal", fontSize: "14px", marginTop: "5px", marginBottom: "5px" }}>
                    <span style={{ display: "inline-block", flex: "0 40px" }}>1 x</span>{" "}
                    <span>Milk Tea RE: Without Sugar</span>
                  </h6>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", border: 0, paddingBottom: 0, paddingTop: 0 }}>
                  <hr style={{ marginTop: "10px", marginBottom: "10px", border: "none", borderBottom: "dashed 1px #000000" }} />
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ color: "#000", textWrap: "nowrap", fontWeight: "500", border: 0 }}>
                  <div style={{ color: "#000", fontSize: "14px", fontWeight: "500" }}>Printed On: 12 Nov 2023 12:30 PM <br /> - Reprinted KOT -</div>
                </td>
              </tr>
            </tbody>
          </Table>
        </Offcanvas.Body>
        <div className="offcanvas-footer bg-white">
          <Button variant="danger" className="fs-14" onClick={handlePrintClick}>
            <i className="ri-printer-line fs-18 lh-1 align-middle me-2"></i>{" "}
            <span className="align-middle">Print Test Page</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
