import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Offcanvas, Button, Row, Col, Form } from "react-bootstrap";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

export default function SalaryUpdate(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, [props.data]);
    const {selectedBranchData}=useContext(AuthContext);
    const parsedBranchData = JSON.parse(selectedBranchData);
    const [paidExtra, setPaidExtra] = useState(0);
    const handleChange = (event) => {
        const value = event.target.value;
        setPaidExtra(value);
      };

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Salary Update</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Row className="mb-4">
                        <Col xl={6} sm={12}>
                            <Form.Label>To be Paid</Form.Label>
                            <div className="bg-gray-200 rounded p-2">
                                <h3 className="text-danger fw-bold fs-18">{parsedBranchData?.currencySymbol} 30000</h3>
                            </div>
                        </Col>

                        <Col xl={6} sm={12}>
                            <Form.Label>Paid Extra</Form.Label>
                            <div className="bg-gray-200 rounded p-2">
                                <h3 className={`fw-semibold fs-18 ${paidExtra > 0 ? "text-success" : ""}`}>{parsedBranchData?.currencySymbol} {paidExtra}</h3>
                            </div>
                        </Col>
                    </Row>

                    <h2 className="fs-16 fw-semibold text-dark mb-3">Settle Salary:</h2>
                    <Row>
                        <Col xl={6} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Cash Paid</Form.Label>
                                <Form.Control type="number" min="1" placeholder="Enter Cash Paid" value={30000} name="cashPaid" />
                            </div>
                        </Col>
                        <Col xl={6} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Bank Transfer</Form.Label>
                                <Form.Control type="number" min="1" placeholder="Enter Bank Transfer" name="bankTransfer" onChange={handleChange} />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <div className="mb-4">
                                <Form.Label>Remarks</Form.Label>
                                <Form.Control as="textarea" rows="3" placeholder="Enter Remarks"></Form.Control>
                            </div>
                        </Col>
                    </Row>

                    <Col sm={12}>
                        <div className="mb-4">
                            <Form.Label>Extra Payment Reason?</Form.Label>
                            <Form.Select name="genderId">
                                <option value="1">Advance Salary</option>
                                <option value="2">Incentive</option>
                                <option value="2">Bonus</option>
                                <option value="2">Others</option>
                            </Form.Select>
                        </div>
                    </Col>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Update</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}