import React, { useContext, useEffect, useState } from "react";
import { Offcanvas, Button, Form, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getDiscountListByBranchIdSuccess,
  getLoading,
  getOrderRelatedChangesDone,
} from "../../../application/selectors/indexSelector";
import {
  applyDiscount,
  removeDiscount,
} from "../../../application/actions/posAction";
import Environment from "../../../infrastructure/core/Environment";
import LoadingSpinner from "../../_common/LoadingSpinner";
import ToastifyService from "../../_common/ToastifyService";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

export default function ApplyDiscount(props) {
  const dispatch = useDispatch();
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const [orderDetails, setOrderDetails] = useState(props?.createOrderSuccess);
  const [discountAmount, setDiscountAmount] = useState(0);
  const discountList = useSelector(getDiscountListByBranchIdSuccess);
  const [discount, setDiscount] = useState("");
  const [exceedDiscountMsg, setExceedDiscountMsg] = useState("");
  const [selectedCoupon, setSelectedCoupon] = useState(
    Environment.defaultValue
  );
  const orderRelatedChangesDone = useSelector(getOrderRelatedChangesDone);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  useEffect(() => {
    if (props?.show) {
      setOrderDetails(props?.createOrderSuccess);
    }
  }, [props?.createOrderSuccess, props?.show]);
  useEffect(() => {
    if (props.show) {
      // Assuming you have a property like 'appliedDiscountId' in orderDetails
      const appliedDiscountId = orderDetails?.discountId;
      if (appliedDiscountId !== Environment.defaultValue) {
        // Find the applied discount in the discountList
        const appliedDiscount = discountList.find(
          (discount) => discount.id === appliedDiscountId
        );
        if (appliedDiscount) {
          // Set the selectedCoupon and discountAmount based on the applied discount
          setSelectedCoupon(appliedDiscount.id);
          setDiscountAmount(orderDetails?.discountAmount?.toFixed(2));
          setDiscount("");
          setExceedDiscountMsg("");
        }
      } else if (orderDetails?.discountAmount !== 0) {
        setDiscount(orderDetails?.discountAmount);
        setDiscountAmount(orderDetails?.discountAmount?.toFixed(2));
        setExceedDiscountMsg("");
        setSelectedCoupon(Environment.defaultValue);
      } else {
        setSelectedCoupon(Environment.defaultValue);
        setDiscount("");
        setExceedDiscountMsg("");
        setDiscountAmount(0.0);
      }
    }
  }, [props.show, discountList, orderDetails]);

  const handleApplyDiscount = () => {
    if (
      selectedCoupon !== "00000000-0000-0000-0000-000000000000" &&
      discount === ""
    ) {
      const discountDetail = discountList?.find(
        (discountData) => discountData.id === selectedCoupon
      );
      if (discountDetail.isOrderValue) {
        if (discountDetail.minOrderValue <= orderDetails?.totalOrderPrice) {
          const formObject = {
            fixedDiscount: discount === "" ? "0" : discount,
            selectedDiscount: selectedCoupon,
            salesId: orderDetails?.id,
          };
          const formObjectData = {
            formObject: formObject,
            orderTypeId: props?.createOrderSuccess?.orderTypeId,
          };
          dispatch(applyDiscount(formObjectData));
          props.closeFunction();
        } else {
          ToastifyService.error(
            "Minimum order value should be " + discountDetail.minOrderValue
          );
        }
      } else {
        const formObject = {
          fixedDiscount: discount === "" ? "0" : discount,
          selectedDiscount: selectedCoupon,
          salesId: orderDetails?.id,
        };
        const formObjectData = {
          formObject: formObject,
          orderTypeId: props?.createOrderSuccess?.orderTypeId,
        };
        dispatch(applyDiscount(formObjectData));
        props.closeFunction();
      }
    } else {
      const formObject = {
        fixedDiscount: discount === "" ? "0" : discount,
        selectedDiscount: selectedCoupon,
        salesId: orderDetails?.id,
      };
      const formObjectData = {
        formObject: formObject,
        orderTypeId: props?.createOrderSuccess?.orderTypeId,
      };
      dispatch(applyDiscount(formObjectData));
      props.closeFunction();
    }
  };

  const handleRemoveDiscount = () => {
    const formObject = {
      orderId: orderDetails?.id,
      orderTypeId: props?.createOrderSuccess?.orderTypeId,
    };
    dispatch(removeDiscount(formObject));
    props.closeFunction();
  };

  return (
    <React.Fragment>
      {(orderRelatedChangesDone ||
        (loading &&
          (value === "applyDiscount" || value === "removeDiscount"))) && (
        <div className="card-loader">
          <LoadingSpinner color="#dc3545" size="100" type={"ThreeDots"} />
        </div>
      )}
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-35"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Apply Discount
          </Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl={6}>
              <div className="mb-4">
                <Form.Label>Enter Cash:</Form.Label>
                {/* <sup className="text-danger">*</sup> */}
                <Form.Group className="position-relative overflow-hidden">
                  <Form.Label className="custom-label mb-0">
                    {parsedBranchData?.currencySymbol}{" "}
                  </Form.Label>
                  <Form.Control
                    className="tax-percentage-input"
                    style={{ paddingLeft: "50px" }}
                    type="number"
                    min="1"
                    placeholder="Enter Amount"
                    name="discount"
                    value={discount}
                    onChange={(event) => {
                      setDiscount(event.target.value);
                      setSelectedCoupon(Environment.defaultValue);
                      setExceedDiscountMsg("");
                      event.target.value === ""
                        ? setDiscountAmount(0.0)
                        : setDiscountAmount(
                            parseFloat(event.target.value).toFixed(2)
                          );
                    }}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>

          <div>
            <Form.Label className="mb-2">Apply Coupon</Form.Label>
            {discountList?.map((discountData, index) => (
              <label className="choose-coupon-radio" key={index}>
                <input
                  type="radio"
                  name={discountData?.id}
                  value={discountData?.id}
                  checked={selectedCoupon === discountData?.id}
                  onChange={(event) => {
                    setDiscount("");
                    const selectedValue = event.target.value;
                    setSelectedCoupon(selectedValue);
                    if (
                      discountData.discountTypeId ===
                      "d9c5a952-911e-4a0f-8935-5e1fae01801e"
                    ) {
                      setDiscountAmount(discountData.discountRate.toFixed(2));
                      setExceedDiscountMsg("");
                    } else {
                      const calculatedDiscount =
                        (orderDetails?.totalOrderPrice *
                          discountData?.discountRate) /
                        100;
                      if (
                        discountData.isOrderValue &&
                        orderDetails?.totalOrderPrice <
                          discountData?.minOrderValue
                      ) {
                        setDiscountAmount(
                          calculatedDiscount > discountData?.maxDiscountValue
                            ? discountData?.maxDiscountValue?.toFixed(2)
                            : calculatedDiscount?.toFixed(2)
                        );
                        setExceedDiscountMsg(
                          `Minimum Order Value should be ${parsedBranchData?.currencySymbol} ${discountData?.minOrderValue}`
                        );
                      } else if (
                        discountData.isOrderValue &&
                        calculatedDiscount > discountData?.maxDiscountValue
                      ) {
                        setDiscountAmount(
                          calculatedDiscount > discountData?.maxDiscountValue
                            ? discountData?.maxDiscountValue?.toFixed(2)
                            : calculatedDiscount?.toFixed(2)
                        );
                        setExceedDiscountMsg(
                          `Discount allowed is ${parsedBranchData?.currencySymbol} ${discountData?.maxDiscountValue}`
                        );
                      } else {
                        setDiscountAmount(calculatedDiscount.toFixed(2));
                        setExceedDiscountMsg("");
                      }
                    }
                  }}
                />
                <div className="radio-btn">
                  <h3 className="fs-14 fw-semibold text-dark">
                    {discountData?.discountTitle}
                    {selectedCoupon === discountData?.id &&
                      discountData?.isOrderValue &&
                      orderDetails?.totalOrderPrice <
                        discountData?.minOrderValue && (
                        <span className="text-danger fs-12">
                          {" "}
                          - Minimum order value should be{" "}
                          {discountData?.minOrderValue}
                        </span>
                      )}
                    {orderDetails?.discountId === discountData?.id && (
                      <span className="fs-12 text-success"> - Applied</span>
                    )}
                  </h3>
                  <b>
                    {discountData?.discountTypeId ===
                    "d9c5a952-911e-4a0f-8935-5e1fae01801e"
                      ? `${parsedBranchData?.currencySymbol}`
                      : ""}{" "}
                    {discountData?.discountRate}{" "}
                    {discountData.discountTypeId ===
                    "d9c5a952-911e-4a0f-8935-5e1fae01802e"
                      ? "%"
                      : ""}
                  </b>
                </div>
              </label>
            ))}
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <div>
            <h6 className="fs-12 text-dark mb-1">Payable</h6>
            <h3 className="fs-14 text-dark">
              <b>
                {(orderDetails?.totalOrderPrice - discountAmount).toFixed(2)}
              </b>{" "}
              ({orderDetails?.totalOrderPrice} - {discountAmount})
              {exceedDiscountMsg !== "" && (
                <span className="text-danger mx-2">({exceedDiscountMsg})</span>
              )}
            </h3>
          </div>
          <div>
            {(orderDetails?.discountAmount !== 0 ||
              (orderDetails?.discountId === selectedCoupon &&
                orderDetails?.discountId !== Environment.defaultValue)) && (
              <Button
                variant="danger me-2 fs-12"
                onClick={handleRemoveDiscount}
              >
                Remove Discount
              </Button>
            )}

            <Button
              variant="dark fs-12"
              onClick={handleApplyDiscount}
              disabled={
                (discount === "" &&
                  (selectedCoupon === Environment.defaultValue ||
                    selectedCoupon === orderDetails?.discountId)) ||
                (discountList?.find(
                  (discountData) => discountData.id === selectedCoupon
                )?.isOrderValue
                  ? discountList?.find(
                      (discountData) => discountData.id === selectedCoupon
                    )?.minOrderValue >= orderDetails?.totalOrderPrice
                  : false)
              }
            >
              Apply Discount
            </Button>
          </div>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
