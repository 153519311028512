import {LOAD_ORDER_TYPE_SUCCESS, ORDER_TYPE_SUCCESS, RESET_ORDERTYPE_LIST} from '../actions/orderTypeAction';
const initialState = {
    OrderTypeListByBranchId:[],
    OrderTypeList:[],
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOAD_ORDER_TYPE_SUCCESS:
            return { ...state ,OrderTypeListByBranchId: action.payload.result, error: null}
        case ORDER_TYPE_SUCCESS:
            return { ...state ,OrderTypeList: action.payload.result, error: null} 
        case RESET_ORDERTYPE_LIST:
            return { ...state ,OrderTypeListByBranchId: undefined, error: null}
        default:            
            return state;
    }
}

export default reducer;