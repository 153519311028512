import {  COMMON_APPLIED_SUCCESS,  COMMON_TAX_CODE_SUCCESS,  COMMON_TAX_TYPE_SUCCESS,  GET_TAX_LIST,  GET_TAX_LIST_SUCCESS,  COMMON_APPLIED_TO_SUCCESS,  GET_TAX_LIST_BY_TENANT_SUCCESS, TAX_DETAILS_SUCCESS,
} from "../actions/taxAction";

const initialState = {
  taxList: undefined,
  taxcode: [],
  taxtype: [],
  appliedOn: [],
  appliedTo: [],
  taxDetails:[],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TAX_LIST_SUCCESS:
      return { ...state, taxList: action.payload.data.result, error: null };
    case COMMON_TAX_CODE_SUCCESS:
      return { ...state, taxcode: action.payload.data.result, error: null };
    case COMMON_TAX_TYPE_SUCCESS:
      return { ...state, taxtype: action.payload.data.result, error: null };
    case COMMON_APPLIED_SUCCESS:
      return { ...state, appliedOn: action.payload.data.result, error: null };
    case COMMON_APPLIED_TO_SUCCESS:
      return { ...state, appliedTo: action.payload.data.result, error: null };
    case GET_TAX_LIST_BY_TENANT_SUCCESS:
      return { ...state, taxListByTenant: action.payload.result, error: null };
    case TAX_DETAILS_SUCCESS:
      return { ...state, taxDetails: action.payload.result, error: null };
    default:
      return state;
  }
};

export default reducer;
