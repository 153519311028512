import { GENERAL_SETTING_SUCCESS } from "../actions/generalSettingAction";

const initialState = {
    GeneralSettingData:undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
         case GENERAL_SETTING_SUCCESS:            
            return { ...state ,GeneralSettingData: action.payload.result, error: null };
         default:            
            return state;
    }
}

export default reducer;