export const MENU_LIST = "MENU_LIST";
export const CATEGORY_LIST = "CATEGORY_LIST";
export const MENU_LIST_SUCCESS = "MENU_LIST_SUCCESS";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CREATE_MENU = "CREATE_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const UPLOAD_IMAGE = "UPLOAD_IMAGE";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const CREATE_SUB_MAIN = "CREATE_SUB_MAIN";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const MENU_DETAILS_SUCCESS = "MENU_DETAILS_SUCCESS";
export const MENU_DELETE_BY_ID = "MENU_DELETE_BY_ID";
export const CREATE_MENU_FAILURE = "CREATE_MENU_FAILURE";
export const CATEGORY_FAILURE = "CATEGORY_FAILURE";
export const UPDATE_MENU_SEQUENCE = "UPDATE_MENU_SEQUENCE";


export const GetMenuList = {
  type: MENU_LIST,
};
export const MenuSuccess = (data) => ({
  type: MENU_LIST_SUCCESS,
  payload: { data },
});
export const GetCategoryByMainCatId = (data) => ({
  type: CATEGORY_LIST,
  payload: { data },
});
export const CategoryListSuccess = (data) => ({
  type: CATEGORY_LIST_SUCCESS,
  payload: { data },
});
export const MenuDeleteById = (data) => ({
  type: MENU_DELETE_BY_ID,
  payload: {data},
});

export const MenuDetailsSuccess = (data) => ({
  type: MENU_DETAILS_SUCCESS,
  payload: { data },
});

export const CreateMain = (data) => ({
  type: CREATE_MENU,
  payload: { data },
});
export const CreateMainFailure = (data) => ({
  type: CREATE_MENU_FAILURE,
  payload: data ,
});
export const UpdateMain = (data) => ({
  type: UPDATE_MENU,
  payload: { data },
});
export const UpdateMainSequence = (data) => ({
  type: UPDATE_MENU_SEQUENCE,
  payload: data ,
});
export const Imgaeupload =(data)=>({
  type:UPLOAD_IMAGE,
  payload:{data}
});

export const Imgaeuploadsuccess =(data)=>({
  type:UPLOAD_IMAGE_SUCCESS,
  payload:{data}
});
export const CreateSUBMain =(data)=>({
  type:CREATE_SUB_MAIN,
  payload:{data}
});
export const UpdateCategory = (data) => ({
  type: UPDATE_CATEGORY,
  payload: { data },
});
export const DeleteCategory = (data) => ({
  type: DELETE_CATEGORY,
  payload: { data },
});
export const CategoryFailure = (data) => ({
  type: CATEGORY_FAILURE,
  payload: data ,
});