import React from "react";
import { Offcanvas, Form, Button } from "react-bootstrap";

export default function KitchenGroupsModal(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Change Kitchen Group</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <div>
                        <Form.Label>Kitchen Group</Form.Label>
                        <Form.Select>
                            <option>Choose Kitchen Group</option>
                            <option value={1}>Juice Center</option>
                            <option value={2}>Maharastrian Kitchen</option>
                            <option value={3}>My Kitchen</option>
                        </Form.Select>
                    </div>
                </Offcanvas.Body>
                <div className="offcanvas-footer bg-white">
                    <Button variant="primary" className="d-flex align-items-center gap-1 me-2">
                        <i className="ri-add-line fs-18 lh-1"></i>
                        <span className="d-sm-inline">Change</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}
