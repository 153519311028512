import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const customerListByTenant = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.User_Url}customer-by-tenant/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const createCustomer = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.User_Url}add-customer`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const customerDetail = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.User_Url}customer-detail/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const updateCustomer = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.User_Url}update-customer`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const customerOrderList = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.User_Url}orders-by-customer/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const deleteCustomer = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.User_Url}delete-customer/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const customerLoyaltyPointsList = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.User_Url}loyalty-points-by-customer/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const customerAddressList = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.User_Url}user-address/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const cancelOrderOfCustomer = async (id) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}cancel-order/${id}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const addCustomerAddress = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.User_Url}add-user-address`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const updateCustomerAddress = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.User_Url}update-user-address`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const updateCustomerDetailsInOrder = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Sales_Url}update-order-address`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const uploadCustomer = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.User_Url}customer-bulk-upload`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const askForBusinessReview = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.User_Url}ask-business-review`,data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const getSearchedListOfCustomer = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.User_Url}search-customer/${data}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
export default {
  customerListByTenant,
  createCustomer,
  customerDetail,
  updateCustomer,
  deleteCustomer,
  customerOrderList,
  customerLoyaltyPointsList,
  customerAddressList,
  cancelOrderOfCustomer,
  addCustomerAddress,
  updateCustomerDetailsInOrder,
  updateCustomerAddress,
  uploadCustomer,
  askForBusinessReview,
  getSearchedListOfCustomer
};
