import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";

export default function PayAdvanceSalary(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [startDate, setStartDate] = useState(new Date());

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-45">
                <Offcanvas.Header className="custom-offcanvas-header" closeButton>
                    <Offcanvas.Title>Pay Advance Salary</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Row>
                        <Col xl={8} md={6} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Employee Name: <sup className="text-danger">*</sup> </Form.Label>
                                <Form.Control type="text" placeholder="Enter Employee Name" name="empName" />
                            </div>
                        </Col>

                        <Col xl={6} md={6} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Payment Mode: <sup className="text-danger">*</sup> </Form.Label>
                                <Form.Select name="paymentMode">
                                    <option>Choose Payment Mode</option>
                                    <option value="cash">Cash on Delivery</option>
                                    <option value="card">Card</option>
                                    <option value="upi">UPI</option>
                                </Form.Select>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={4} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Amount Paid: <sup className="text-danger">*</sup> </Form.Label>
                                <Form.Control type="number" min="1" placeholder="Enter Amount Paid" name="amountPaid" />
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} sm={12}>
                            <div className="mb-4">
                                <Form.Label>Paid On: <sup className="text-danger">*</sup> </Form.Label>
                                <ReactDatePicker dateFormat="MMMM dd, yyyy" selected={startDate} onChange={(date) => setStartDate(date)} className="form-control" />
                            </div>
                        </Col>
                    </Row>

                    <Col xl={8} sm={12}>
                            <div>
                                <Form.Label>Remark</Form.Label>
                                <Form.Control type="text" placeholder="Enter Remark" name="remark" />
                            </div>
                        </Col>
                </Offcanvas.Body>
                <div className="offcanvas-footer">
                    <Button variant="primary" className="fs-14 me-3">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i> <span className="align-middle">Save</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}