import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Card, Button, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import TableShimmerLoader from "../../layouts/ShimmerTable";
import Select from "react-select";
import caution from "../../assets/img/icons/caution.png";
import { format } from "date-fns";

export default function MappedLocation(props) {
  const initialState = {
    Id: props?.id,
    catalogueId: 0,
  };
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const [formData, setFormData] = useState(initialState);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [global, setGlobal] = useState(true);
  useEffect(() => {
    setGlobal(props?.isGlobal);
  }, [props?.isGlobal]);

  const Onsubmit = (e) => {
    e.preventDefault();
    props?.AssociateBranch(selectedOptions);
    setSelectedOptions([]);
  };
  const deleteMappedBranch = (id) => {
    props?.DissociateBranch(id);
  };
  const columns = [
    {
      name: "Location",
      selector: (row) => <div>{row?.branchName}</div>,
    },
    {
      name: "Added On",
      selector: (row) => (
        <div>
          <h6 className="fs-14 fw-semibold mb-1">
            {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")}
          </h6>
          <h5 className="fs-12">{row.createdByName}</h5>
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => (
        <div>
          <Button
            variant="outline-danger"
            className="btn-icon"
            onClick={() => deleteMappedBranch(row.mappingId)}
          >
            <i className="ri-delete-bin-line"></i>
          </Button>
        </div>
      ),
    },
  ];
  const handleChange = (selectedValue) => {
    setFormData({ ...formData, branchId: selectedValue.value });
    setSelectedOptions(selectedValue);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(true); // Toggle dropdown menu open/close state
  };

  const closeDropdown = () => {
    setIsOpen(false); // Close dropdown menu
  };
  return (
    <React.Fragment>
      {global && (
        <div className="applicabled-view d-flex justify-content-between align-items-center mt-3 mb-4">
          <h3 className="fs-14 d-flex align-items-center gap-1">
            <img src={caution} alt="caution" /> Applicable on all Locations
          </h3>
          <Button
            variant="outline-secondary"
            className="d-flex align-items-center gap-1"
            onClick={() => setGlobal(false)}
          >
            <i className="ri-pencil-line fs-18 lh-1"></i>
            <span className="d-sm-inline">Override</span>
          </Button>
        </div>
      )}
      {!global && (
        <>

          <div className="d-md-flex align-items-center justify-content-between mb-4">
            <div>
              <h4 className="main-title fs-16 mb-0">Mapped Location</h4>
            </div>

            <div className="d-flex align-items-center gap-3 flex-wrap">
              <div onBlur={closeDropdown}>
                <div onClick={toggleDropdown}>
                  <Select
                    isMulti
                    options={props?.BranchData}
                    isSearchable={true}
                    value={selectedOptions}
                    name="branchId"
                    onChange={handleChange}
                    placeholder="Choose Location"
                    className="react-select-container wt-250"
                    menuIsOpen={isOpen}
                    onMenuClose={() => { }}
                  />
                </div>
              </div>

              <Button variant="primary" type="submit" onClick={Onsubmit}>Associate</Button>
            </div>
          </div>
          <Card className="card-one">
            <Card.Body className="custom-common-table">
              {!props?.loading && (
                <DataTable
                  columns={columns}
                  data={props?.Data}
                  fixedHeader
                  search={true}
                  highlightOnHover
                  pagination
                ></DataTable>
              )}
              {props?.loading && <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />}
            </Card.Body>
          </Card>
        </>
      )}
      {/* )} */}
    </React.Fragment>
  );
}
