// react-packages
import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import { Card, Badge } from "react-bootstrap";
import Prism from "prismjs";
///Loader component
import TableShimmerLoader from "../../layouts/ShimmerTable";
//selectors
import {
  getCustomerLoyaltyPointsList,
  getLoading,
} from "../../application/selectors/indexSelector";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";

export default function CustomerLoyaltyPoints() {
  //fetching and storing customerLoyalty points and loader is on or not
  const customerLoyaltyPointsList = useSelector(getCustomerLoyaltyPointsList);
  const { loading, value } = useSelector(getLoading);
  const {selectedBranchData}=useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  //datatable columns and their records
  const columns = [
    {
      name: "Order ID",
      selector: (row) => <span>{row.orderId}</span>,
    },
    {
      name: "Bill Amount",
      selector: (row) => <b>{parsedBranchData?.currencySymbol} {row.billAmt}</b>,
    },
    {
      name: "Points Earned",
      selector: (row) => <b>{row.pointsEarned}</b>,
    },
    {
      name: "Points Used",
      selector: (row) => (
        <Badge bg="danger" pill>
          {row.pointsUsed}
        </Badge>
      ),
    },
    {
      name: "Balance Points",
      selector: (row) => <span>{row.balancePoint}</span>,
    },
    {
      name: "Data",
      selector: (row) => (
        <>
          <h6 className="mb-0 fw-semibold">{row.lastupdate}</h6>
          <span className="text-dark fw-semibold">{row.time}</span>
        </>
      ),
    },
  ];
  return (
    <React.Fragment>
      <Card className="card-one">
        <Card.Body className="custom-common-table">

          {customerLoyaltyPointsList &&
            customerLoyaltyPointsList !== undefined && (
              <DataTable
                columns={columns}
                data={customerLoyaltyPointsList}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
          {!loading && customerLoyaltyPointsList === undefined && (
            <TableShimmerLoader  columnCount={4} rowCount={4}/>
          )}
          {loading &&
            value === "customerLoyaltyPointsList" &&
            customerLoyaltyPointsList === undefined && <TableShimmerLoader  columnCount={4} rowCount={4}/>}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
