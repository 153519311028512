import React, { useContext, useEffect, useState } from "react";
import { Offcanvas, Button, Form, ListGroup, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getChargeListByBranchIdSuccess, getLoading } from "../../../application/selectors/indexSelector";
import { applyCharges, removeCharges } from "../../../application/actions/posAction";
import LoadingSpinner from "../../_common/LoadingSpinner";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

export default function ApplyCharges(props) {
  const dispatch = useDispatch();
  const [orderDetails, setOrderDetails] = useState(props?.createOrderSuccess);
  const [loadingButtons, setLoadingButtons] = useState({});
  const [chargeId, setChargeId] = useState({});
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const chargeList = useSelector(getChargeListByBranchIdSuccess);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;

  useEffect(() => {
    setOrderDetails(props?.createOrderSuccess);
  }, [props?.createOrderSuccess]);

  useEffect(() => {
    if ( loading === false && (value === "removeCharges" || value === "applyCharges")) {
      setLoadingButtons((prevLoadingButtons) => ({...prevLoadingButtons, [chargeId]: false,}));
    }
  }, [loading, value]);

  const handleApplyCharges = (chargeId) => {
    setChargeId(chargeId);
    setLoadingButtons((prevLoadingButtons) => ({
      ...prevLoadingButtons,
      [chargeId]: true,
    }));
    const formObject = {
      salesId: orderDetails?.id,
      chargeId: chargeId,
      orderTypeId:props?.createOrderSuccess?.orderTypeId
    };
    dispatch(applyCharges(formObject));
  };

  const handleRemoveCharges = (chargeId) => {
    setChargeId(chargeId);
    setLoadingButtons((prevLoadingButtons) => ({
      ...prevLoadingButtons,
      [chargeId]: true,
    }));
    const formObject = {
      salesId: orderDetails?.id,
      chargeId: chargeId,
      orderTypeId:props?.createOrderSuccess?.orderTypeId
    };
    dispatch(removeCharges(formObject));
  };

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-35">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">Apply Charges</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <div>
            <Form.Label className="mb-2">Choose from the list:</Form.Label>
            <ListGroup>
              {chargeList?.map((charges, index) => (
                <ListGroup.Item key={index} className="aplly-charge-list">
                  <Row className={`align-items-center w-100 justify-content-between test ${orderDetails?.orderCharges?.some((orderCharge) => orderCharge.id === charges.id ? "applay": "applay1")}`}>
                    <Col xl={6}>
                      <h3 className="fs-14 fw-semibold text-dark">
                      {orderDetails?.orderCharges?.some((orderCharge) => orderCharge.id === charges.id) ? (
                        <i className="ri-checkbox-circle-fill text-success fs-18 align-middle me-2"></i>

                      ) : (
                        <i className="ri-checkbox-circle-fill text-black-50 opacity-50 fs-18 align-middle me-2"></i>
                      )}
                        {charges?.chargeName}
                      </h3>
                    </Col>
                    <Col xl={6} className="text-right pe-0">
                      <b className="me-2">
                        <span>
                          {charges?.chargeType === "d9c5a952-911e-4a0f-8935-5e1fae01701e" && `${parsedBranchData?.currencySymbol}`}
                          {charges?.fixedRate} {charges?.chargeType === "d9c5a952-911e-4a0f-8935-5e1fae01702e" && "%"}{" "}
                        </span>
                      </b>
                      {orderDetails?.orderCharges?.some((orderCharge) => orderCharge.id === charges.id) ? (
                        <Button type="submit" variant="default" className="text-uppercase text-danger fs-12 wt-70"
                          onClick={() => handleRemoveCharges(charges.id)}
                          disabled={loading && value === "removeCharges" && loadingButtons[charges.id]}>
                          {loading && value === "removeCharges" && loadingButtons[charges.id] ? (
                            <LoadingSpinner color="#ffffff" size={15} type={"TailSpin"} />
                          ) : (
                            "Remove"
                          )}
                        </Button>
                      ) : (
                        <Button type="submit" variant="primary" className="text-uppercase fs-12 wt-70"
                          onClick={() => handleApplyCharges(charges.id)}
                          disabled={loading && value === "applyCharges" && loadingButtons[charges.id]}>
                          {loading && value === "applyCharges" && loadingButtons[charges.id] ? (
                            <LoadingSpinner color="#ffffff" size={15} type={"TailSpin"}/>
                          ) : (
                            "Apply"
                          )}
                        </Button>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </div>
        </Offcanvas.Body>
        <div className="offcanvas-footer">
          <div>
            <h3 className="fs-14 text-dark">Charges Added:{" "}<b>{orderDetails?.orderCharges?.length === 0 ? 0 : orderDetails?.orderCharges?.length}</b></h3>
          </div>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}