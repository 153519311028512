import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCurrencies,
  getAllTimeZones,
  getProfileByTenant,
} from "../../../application/selectors/indexSelector";
import {
  GetAllCurrencyList,
  GetAllTimeZoneList,
  UpdateBusinessCurrency,
  UpdateBusinessTimeZone,
} from "./../../../application/actions/accountAction";
import Environment from "../../../infrastructure/core/Environment";
import AuthContext from './../../../infrastructure/core/helpers/AuthContext';

export default function ProfileSetting() {
  const dispatch = useDispatch();
  const {user} = useContext(AuthContext);
  useEffect(() => {
    dispatch(GetAllCurrencyList);
    dispatch(GetAllTimeZoneList);
    Prism.highlightAll();
  }, []);
  const Currencies = useSelector(getAllCurrencies);
  const TimeZones = useSelector(getAllTimeZones);
  const profileByTenant = useSelector(getProfileByTenant);
  useEffect(()=>{
    if(profileByTenant){
        setFormData({ ...formData, 
            timeZoneId: profileByTenant.timeZoneId,
            currencyId:profileByTenant.currencyId
         });
    }
  },[profileByTenant])
  const [formData, setFormData] = useState({
    timeZoneId: "",
    currencyId: "",
    branchId:user.branchId,
    tenantId:user?.tenanatId
  });
  const handleTimeZoneId = (id) => {
    setFormData({ ...formData, timeZoneId: id });
    const updatedFormObject = {...formData,  timeZoneId: id,};
    dispatch(UpdateBusinessTimeZone(updatedFormObject));
  };
  const handleCurrencyId = (id) => {
    setFormData({ ...formData, currencyId: id });
    const updatedFormObject = {...formData,  currencyId: id,};
    dispatch(UpdateBusinessCurrency(updatedFormObject));
  };
  return (
    <React.Fragment>
      <Row>
        <Col xl="8">
          <h3 className="fs-14 text-dark">Time Zone</h3>
          <p className="fs-12 mb-0 text-dark">
            Define the organisation wide Time Zone for your account.
          </p>
        </Col>
        <Col xl="4">
          <Form.Select
            aria-label="Default select example"
            className="wt-200"
            style={{ margin: "0 0 0 auto" }}
            name="timeZoneId"
            value={formData.timeZoneId}
            onChange={(e) => handleTimeZoneId(e.target.value)}
          >
             <option value={Environment.defaultValue} disabled>
                Disabled
              </option>
            {TimeZones?.map((option, index) => (
              <option  key={index} value={option.id}>{option.timeZone}</option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <hr />

      <Row>
        <Col xl="8">
          <h3 className="fs-14 text-dark">Currency</h3>
          <p className="fs-12 mb-0 text-dark">
            Define the default Currency for the organisation.
          </p>
        </Col>
        <Col xl="4">
          <Form.Select
            aria-label="Default select example"
            className="wt-200"
            style={{ margin: "0 0 0 auto" }}
            name="currencyId"
            value={formData.currencyId}
            onChange={(e) => handleCurrencyId(e.target.value)}
          >
            <option value={Environment.defaultValue} disabled>
              Choose Currency
            </option>
            {Currencies?.map((option,index) => (
              <option key={index} value={option.id}>
                {option.currancyCode} - {option.currancySymbol}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
    </React.Fragment>
  );
}
