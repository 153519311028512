import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Table, Card } from "react-bootstrap";
import salaryPaymentList from "../../../../Json/Attendance/payment-log/salary-payment.json";

export default function PaymentLogSalaryPayment() {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    return (
        <React.Fragment>
            <Card className="card-one">
                <Card.Body>
                    <Table className="mb-0" responsive>
                        <thead>
                            <tr>
                                <th>Payment Date</th>
                                <th>Month</th>
                                <th>Eligibal</th>
                                <th>Cash</th>
                                <th>Bank</th>
                                <th>Advance Adjusted</th>
                                <th>Balance Paid</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salaryPaymentList?.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.paymentDate}</td>
                                    <td>{item.month}</td>
                                    <td><b>{item.eligibal}</b></td>
                                    <td><b>{item.cash}</b></td>
                                    <td><b>{item.bank}</b></td>
                                    <td><b>- {item.advance}</b></td>
                                    <td className="text-danger fw-semibold">{item.cash + item.bank + item.advance}</td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
        </React.Fragment>
    )
}