import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Button, Table } from "react-bootstrap";
import { UpdateOperatingHours } from "../../../../application/actions/branchAction";
import { useDispatch, useSelector } from "react-redux";
import OrderType from "../../../_common/order-type";
import timeSlotlist from "../../../../Json/setting/timeslot.json";
import OperationsTimingView from "../locationDetails/operrationDetails/operations-timing";
import { getOrderTypeList } from "../../../../application/selectors/indexSelector";

export default function OperationsTiming(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const initialState = {
    branchId: 0,
    branchStartTime: "00:00:00",
    branchCloseTime: "00:00:00",
    isNextDay: true
  };
  const [formData, setFormData] = useState(initialState);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (props?.branch) {
      setFormData({
        ...formData,
        branchId: props?.branch?.id,
        branchStartTime: props?.branch?.branchStartTime,
        branchCloseTime: props?.branch?.branchCloseTime,
        isNextDay: false,
      });
    }
  }, [props.branch]);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(UpdateOperatingHours(formData));
  }
  const [showOrderType, setShowOrderType] = useState(false);
  const handleOrderType = (state) => {
    setShowOrderType(state)
  }
  // Function to generate time slots
  const [slotList, setSlots] = useState([])
  const generateTimeSlots = (closeTimeValue) => {
    const slots = [];
    setFormData({ ...formData, branchCloseTime: closeTimeValue + ':00' });
    const startTime = new Date(`2000-01-01T${formData.branchStartTime}`);
    let closeTime = new Date(`2000-01-01T${closeTimeValue}:00`);

    // Adjust close time if it's before start time (indicating it's on the next day)
    if (closeTime < startTime) {
      setFormData({ ...formData, isNextDay: true });
      closeTime = new Date(closeTime.getTime() + 24 * 60 * 60 * 1000); // add one day
    } else {
      setFormData({ ...formData, isNextDay: false });
    }

    let current = new Date(startTime);

    // Add slots starting from the start time's minute
    while (current < closeTime) {
      const slotStart = current.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
      current.setHours(current.getHours() + 1);
      const slotEnd = current.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
      slots.push(`${slotStart} - ${slotEnd}`);
      current.setMinutes(startTime.getMinutes()); // Set minutes to start time's minutes
    }

    // Store the last slot's end time before removing it
    const lastSlotEnd = slots.pop();

    // Add the new last slot using the last slot's end time as the start time
    const lastSlotStart = lastSlotEnd.split(' - ')[0];
    slots.push(`${lastSlotStart} - ${closeTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })}`);

    setSlots(slots);
  };
  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="main-title fs-16 mb-0">Operations Timing</h4>
        </div>
      </div>

      <OperationsTimingView branch={props?.branch} />
    </React.Fragment>
  );
}
