export const VENDOR_LIST="VENDOR_LIST";
export const VENDOR_LIST_SUCCESS="VENDOR_LIST_SUCCESS";
export const ADD_VENDOR="ADD_VENDOR";
export const EDIT_VENDOR_BY_ID="EDIT_VENDOR_BY_ID";

export const AddVendorData=(data)=>({
    type:ADD_VENDOR,
    payload:data
})
export const EditVendorDataById=(data)=>({
    type:EDIT_VENDOR_BY_ID,
    payload:data
})

export const VendorListSuccess=(data)=>({
    type:VENDOR_LIST_SUCCESS,
    payload:data
})
export const VendorList={
    type:VENDOR_LIST
}