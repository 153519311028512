import { BUSINESS_LANGUAGES_SUCCESS, CHANGE_PASSWORD_SUCCESS, CURRENCIES_SUCCESS, LANGUAGESS_SUCCESS, PROFILE_BY_TENANT_SUCCESS, TIMEZONES_SUCCESS } from "../actions/accountAction";

const initialState = {
    ProfileByTenant: {},
    Currencies: [],
    TimeZones:[],
    Languages: undefined,
    BussinessLanguages: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PROFILE_BY_TENANT_SUCCESS:
            return { ...state, ProfileByTenant: action.payload.result, error: null };
        case CURRENCIES_SUCCESS:
            return { ...state, Currencies: action.payload.result, error: null };
        case TIMEZONES_SUCCESS:
            return { ...state, TimeZones: action.payload.result, error: null };
        case LANGUAGESS_SUCCESS:
            return { ...state, Languages: action.payload.result, error: null };
        case BUSINESS_LANGUAGES_SUCCESS:
            return { ...state, BussinessLanguages: action.payload.result, error: null };
        case CHANGE_PASSWORD_SUCCESS:
            return { ...state, changePasswordResponse: action.payload, error: null };
        default:
            return state;
    }
}

export default reducer;