export const INVOICE_LIST="INVOICE_LIST";
export const INVOICE_LIST_SUCCESS="INVOICE_LIST_SUCCESS";
export const ADD_INVOICE="ADD_INVOICE";

export const AddInvoiceData=(data)=>({
    type:ADD_INVOICE,
    payload:data
})
export const InvoiceListSuccess=(data)=>({
    type:INVOICE_LIST_SUCCESS,
    payload:data
})
export const InvoiceList={
    type:INVOICE_LIST
}