import {
  CHARGE_DETAILS_SUCCESS,
  GET_CHARGES_LIST_SUCCESS,
} from "../actions/chargesAction";
const initialState = {
  chargestype: [],
  chargesappliedOn: [],
  autoapply: [],
  chargesList:undefined,
  chargeDetails:{},
  mapChargedItems: [],
  loading: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHARGES_LIST_SUCCESS:
      return { ...state, chargesList: action.payload.result, error: null };
    case CHARGE_DETAILS_SUCCESS:
      return {...state, chargeDetails: action.payload,error: null,
      };
    default:
      return state;
  }
};

export default reducer;
