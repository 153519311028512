import { Navigate } from "react-router-dom";
import {
  CANCELLED_ORDER,
  CancelledOrdersSuccess,
  DASHBOARD_ANALYTICS,
  DashBoardAnalyticsSuccess,
  ORDER_HISTORY,
  OrderHistorySuccess,
  PENDING_ORDERS,
  PendingOrdersSuccess,
} from "../actions/salesOrdersAction";
import * as Loading from "../actions/loaderAction";

const getDashBoardAnalytics =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === DASHBOARD_ANALYTICS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "DashBoardAnalyticsLoader" }));
        const DashBoardAnalytics =
          await api.salesOrdersAPI.getDashBoardAnalytics(action.payload);
        dispatch(DashBoardAnalyticsSuccess(DashBoardAnalytics));
        dispatch(Loading.setLoading({ loading: false, value: "DashBoardAnalyticsLoader" }));

      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "DashBoardAnalyticsLoader" }));

        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
  };
const getPendingOrders =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === PENDING_ORDERS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const PendingOrders = await api.salesOrdersAPI.getPendingOrders(action.payload);
        dispatch(PendingOrdersSuccess(PendingOrders));
        dispatch(Loading.setLoading({ loading: false, value: "" }));

      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));

        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
  };
const getOrderHistory =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === ORDER_HISTORY) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const OrderHistory = await api.salesOrdersAPI.getOrderHistory(action.payload);
        dispatch(OrderHistorySuccess(OrderHistory));
        dispatch(Loading.setLoading({ loading: false, value: "" }));

      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));

        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
  };
const getCancelledOrders =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (action.type === CANCELLED_ORDER) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "" }));
        const CancelledOrders = await api.salesOrdersAPI.getCancelledOrders(action.payload);
        dispatch(CancelledOrdersSuccess(CancelledOrders));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
  };
export default [
  getDashBoardAnalytics,
  getPendingOrders,
  getOrderHistory,
  getCancelledOrders,
];
