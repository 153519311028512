import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Offcanvas, Tabs, Tab } from "react-bootstrap";
import BasicInfo from "./manageLocation/basic-info";
import OperationsTiming from "./manageLocation/operrations-time";
import SectionTable from "./manageLocation/section-table";
import KitchenGroups from "./KitchenGroup/kitchen-group";
import PrintTemplate from "./print-template";
import { useSelector } from "react-redux";
import { getLoading, getSectionsList, getKitchenList, getOrderType } from "../../../application/selectors/indexSelector";
import OrderTypeComponent from "../SaleOfPoint/OrderType/order-type";
import SocialHandles from "./ManageBranch/social-handle";
import PaymentModeComponent from "./PaymentMode/PaymentMode";
//import BranchSetting from "./ManageBranch/setting";

export default function ManageLocation(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);

  const [tabManageLocation, setTabManageLocation] = useState("manage_branch_tab");
  const handleManageLocationSelect = (tab) => {
    setTabManageLocation(tab);
  };

  // const [tabManageBranch, setTabManageBranch] = useState("basic_info_tab");

  // const handleManageBranchSelect = (tab) => {
  //   setTabManageBranch(tab);
  // };

  const { loading } = useSelector(getLoading);
  const SectionsList = useSelector(getSectionsList);
  const KitchenList = useSelector(getKitchenList);
  const OrderType = useSelector(getOrderType);
  // const sectionsListLength

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-70">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Location Details</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Tabs id="custom-styled-tabs" activeKey={tabManageLocation} onSelect={handleManageLocationSelect} className="sale-on-point-tabs">

            {/* MANAGE BRANCH TAB */}
            <Tab eventKey="manage_branch_tab" className="text-wrap" title={<>Branch Info</>} tabClassName="custom-tab-header">
              <div className="custom-tab-body pt-3 pb-5"><BasicInfo branch={props?.branch} /></div>
            </Tab>

            {/* SECTION & TABLE TAB */}
            <Tab eventKey="section_table_tab" className="text-wrap"  title={<>Section & Table <span className="badge rounded-pill badge-length-web">
              {SectionsList && SectionsList?.length ? SectionsList?.length : 0}</span></>} tabClassName="custom-tab-header">
              <div className="custom-tab-body pt-3">
                <SectionTable  branchId={props?.branch?.id} loading={loading} />
              </div>
            </Tab>

            {/* KITCHEN KOT GROUPS TAB */}
            <Tab eventKey="kitchen_group_tab" className="text-wrap" title={<>Kitchen KOT Groups <span className="badge rounded-pill badge-length-web">{KitchenList && KitchenList?.length ?KitchenList?.length  :0}</span></>} 
            tabClassName="custom-tab-header">
              <div className="custom-tab-body pt-3">
                <KitchenGroups branch={props?.branch} />
              </div>
            </Tab>

            {/* PRINT TEMPLATE TAB */}
            <Tab eventKey="print_template_tab" className="text-wrap" title={<>Print Template</>} tabClassName="custom-tab-header">
              <div className="custom-tab-body pt-3">
                <PrintTemplate />
              </div>
            </Tab>

            {/* ORDER TYPE TAB */}
            <Tab eventKey="order_type_tab" className="text-wrap" title={<>Order Type <span className="badge rounded-pill badge-length-web">{OrderType && OrderType?.length ? OrderType?.length :0}</span></>} 
            tabClassName="custom-tab-header">
              <div className="custom-tab-body pt-3">
                <OrderTypeComponent branch={props?.branch} />
              </div>
            </Tab>

            {/* OPERATIONS TIMING TAB */}
            <Tab eventKey="operation_timeing_tab" className="text-wrap" title={<>Operations Timing</>} tabClassName="custom-tab-header">
              <div className="custom-tab-body pt-3">
                <OperationsTiming branch={props?.branch} />
              </div>
            </Tab>

            {/* SOCIAL HANDLE LINKS TAB */}
            <Tab eventKey="goggle_review_tab" className="text-wrap" title={<>Social Handle Links</>} tabClassName="custom-tab-header">
              <div className="custom-tab-body pt-3">
                <SocialHandles />
              </div>
            </Tab>

              {/* PAYMENT MODE TAB */}
              <Tab eventKey="payment_mode_tab" className="text-wrap" title={<>Payment Mode</>} tabClassName="custom-tab-header">
              <div className="custom-tab-body pt-3">
                  <PaymentModeComponent />
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
