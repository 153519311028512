export const GET_TEMPLATE_BY_TENANTID = 'GET_TEMPLATE_BY_TENANTID'
export const GET_TEMPLATE_BY_TENANTID_SUCCESS = 'GET_TEMPLATE_BY_TENANTID_SUCCESS'

export const GetTemplateByTenantId = (tenantId)=>({
    type: GET_TEMPLATE_BY_TENANTID,   
    payload:tenantId
});
export const GetTemplateByTenantIdSuccess = (data)=>({
    type: GET_TEMPLATE_BY_TENANTID_SUCCESS,   
    payload:data
});