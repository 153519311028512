import {  ADDONS_GROUPS_FAILURE, ADDONS_GROUPS_LIST_SUCCESS, ADDONS_ITEM_LIST_SUCCESS, CATALOGUE_ITEM_BY_ADDONS_ID_SUCCESS, FOOD_TYPE, GET_ADDONS_GROUPS_SUCCESS, QUANTITY_TYPE } from "../actions/addOnsAction";

const initialState = {
    AddOnsData:{},
    AddOnsList:undefined,
    FoodType:[],
    AddOnsItem:undefined,
    QuantityType:[],
    loading:false,
    AddOnsGroupError:undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADDONS_GROUPS_LIST_SUCCESS:
            return { ...state ,AddOnsList: action.payload.data.result, error: null };
        case GET_ADDONS_GROUPS_SUCCESS:            
            return { ...state ,AddOnsData: action.payload.data, error: null };
        case FOOD_TYPE:            
            return { ...state ,FoodType: action.payload.data.result, error: null };
        case QUANTITY_TYPE:            
            return { ...state ,QuantityType: action.payload.data.result, error: null };
         case ADDONS_ITEM_LIST_SUCCESS:            
            return { ...state ,AddOnsItem: action.payload.data.result, error: null };
        case CATALOGUE_ITEM_BY_ADDONS_ID_SUCCESS:
            return { ...state ,CatalogAddonsData: action.payload.data.result, error: null }; 
         case ADDONS_GROUPS_FAILURE:
            return { ...state, AddOnsGroupError: action.payload }; 
        default:            
            return state;
    }
}

export default reducer;