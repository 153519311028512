import { Navigate } from "react-router-dom";
import {
  CATEGORY_LIST,
  CategoryListSuccess,
  MENU_LIST,
  MenuSuccess,
  CREATE_MENU,
  UPDATE_MENU,
  UPLOAD_IMAGE,
  CREATE_SUB_MAIN,
  UPDATE_CATEGORY,
  DELETE_CATEGORY,
} from "../actions/menuAction";
import * as Loading from "../actions/loaderAction";
import * as item from "../actions/ItemAction";
import * as menu from "../actions/menuAction";
import ToastifyService from "./../../View/_common/ToastifyService";

const GetMenuList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MENU_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "menuList" }));
        const MenuList = await api.menuAPI.GetMenuList();
        dispatch(MenuSuccess(MenuList));
        dispatch(Loading.setLoading({ loading: false, value: "menuList" }));
      } catch (error) {
        dispatch(Loading.setLoading({ loading: false, value: "menuList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const GetCategoryByMainCatId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CATEGORY_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "subMenuList" }));
        const CategoryList = await api.menuAPI.GetCategoryByMainCatId(
          action.payload.data
        );
        dispatch(CategoryListSuccess(CategoryList.result));
        if(CategoryList.result.length !== 0){
          dispatch(item.GetCatalogByCatId(CategoryList.result[0].id));
      }        
      dispatch(Loading.setLoading({ loading: false, value: "subMenuList" }));
      } catch (error) {
        console.log(error);
        dispatch(Loading.setLoading({ loading: false, value: "subMenuList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MenuDeleteById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === menu.MENU_DELETE_BY_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "menuList" }));
        const MenuList = await api.menuAPI.MenuDeleteById(action.payload.data);
        dispatch(MenuSuccess(MenuList));
        ToastifyService.success(`Menu Deleted Successfully!`);
        dispatch(Loading.setLoading({ loading: false, value: "menuList" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "menuList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const CreateMain =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_MENU) {
      try {
        const MenuDetails = await api.menuAPI.CreateMain(action.payload.data);
        dispatch(menu.MenuDetailsSuccess(MenuDetails.result));
        dispatch(menu.CreateMainFailure(false));
        dispatch(Loading.loadMenu);
        if (MenuDetails.result.mainCatName) {
          ToastifyService.success(
            `${MenuDetails.result.mainCatName} Added Successfully!`
          );
         
        }
        dispatch(CategoryListSuccess([]));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(menu.CreateMainFailure(true));
        dispatch(Loading.setLoading({ loading: false, value: "" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateMain =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_MENU) {
      try {
        // dispatch(Loading.setLoading({loading:true, value:''}));
        const MenuDetails = await api.menuAPI.UpdateMain(action.payload.data);
        dispatch(menu.MenuDetailsSuccess(MenuDetails.result));
        dispatch(Loading.loadMenu);
        ToastifyService.success("Menu Details Updated Successfully!");
        // dispatch(Loading.setLoading({loading:false, value:''}));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        // dispatch(Loading.setLoading({loading:false, value:''}));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UpdateMainSequence =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === menu.UPDATE_MENU_SEQUENCE) {
      try {
        // dispatch(Loading.setLoading({loading:true, value:''}));
        const MenuList = await api.menuAPI.UpdateMainSequence(action.payload);
        dispatch(MenuSuccess(MenuList));
        ToastifyService.success(
          `Sequence Updated Successfully!`
        );
        // dispatch(Loading.setLoading({loading:false, value:''}));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        // dispatch(Loading.setLoading({loading:false, value:''}));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const Imgaeupload =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPLOAD_IMAGE) {
      try {
        const imageUploaded = await api.menuAPI.Imgaeupload(
          action.payload.data
        );
        // const MenuList = await api.menuAPI.GetMenuList();
        // dispatch(MenuSuccess(MenuList));
      } catch (error) {
        // ToastifyService.error("Something Went wrong! ");
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const CreateSUBMain =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === CREATE_SUB_MAIN) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "subMenuList" }));
        const CategoryList = await api.menuAPI.CreateSUBMain(
          action.payload.data
        );
        dispatch(CategoryListSuccess(CategoryList.result));
        const MenuList = await api.menuAPI.GetMenuList();
        dispatch(MenuSuccess(MenuList));
        dispatch(Loading.setLoading({ loading: false, value: "subMenuList" }));
        ToastifyService.success("Sub Menu Created Successfully!");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "subMenuList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const UpdateCategory =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_CATEGORY) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "subMenuList" }));
        const CategoryList = await api.menuAPI.UpdateCategory(
          action.payload.data
        );
        dispatch(CategoryListSuccess(CategoryList.result));
        dispatch(Loading.setLoading({ loading: false, value: "subMenuList" }));
        ToastifyService.success("Sub Menu Details Updated Successfully! ");
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "subMenuList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const DeleteCategory =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === DELETE_CATEGORY) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "subMenuList" }));
        const CategoryList = await api.menuAPI.DeleteCategory(
          action.payload.data
        );
        dispatch(CategoryListSuccess(CategoryList.result));
        const MenuList = await api.menuAPI.GetMenuList();
        dispatch(MenuSuccess(MenuList));
        ToastifyService.success("Sub Menu Deleted Successfully! ");
        dispatch(Loading.setLoading({ loading: false, value: "subMenuList" }));
      } catch (error) {
        ToastifyService.error("Something Went wrong! ");
        dispatch(Loading.setLoading({ loading: false, value: "subMenuList" }));
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

export default [
  DeleteCategory,
  GetMenuList,
  GetCategoryByMainCatId,
  CreateMain,
  UpdateMain,
  Imgaeupload,
  CreateSUBMain,
  UpdateCategory,
  MenuDeleteById,
  UpdateMainSequence
];
