export const CREATE_KOT_REMARK="CREATE_KOT_REMARK";
export const KOTREMARK_LIST="KOTREMARK_LIST";
export const KOTREMARK_LIST_SUCCESS="KOTREMARK_LIST_SUCCESS";
export const UPDATE_KOT_REMARK="UPDATE_KOT_REMARK";
export const DELETE_KOT_REMARK="DELETE_KOT_REMARK";

export const CreateKOTRemark=(data)=>({
    type:CREATE_KOT_REMARK,
    payload:data
})
export const UpdateKOTRemark=(data)=>({
    type:UPDATE_KOT_REMARK,
    payload:data
})
export const DeleteKOTRemarks=(id)=>({
    type:DELETE_KOT_REMARK,
    payload:id
})
export const KOTRemarkList={
    type:KOTREMARK_LIST,
}

export const KOTRemarkListSuccess=(data)=>({
    type:KOTREMARK_LIST_SUCCESS,
    payload:data
})