

import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

const GeneralSetting = async (data) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.post(
    `${Environment.Setting_Url}pos-gen-setting`,
    data,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
const GetPOSGenSetting = async (tenantId) => {
  const storedToken = JSON.parse(localStorage.getItem("Token"));
  const response = await jwtInterceptor.get(
    `${Environment.Setting_Url}pos-gen-setting-by-tenant/${tenantId}`,
    {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      authorization: `Bearer ${storedToken}`,
    }
  );
  return response.data;
};
export default {GeneralSetting,GetPOSGenSetting}