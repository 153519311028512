import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { useDispatch } from "react-redux";

import { Button, Offcanvas, Row, Col, Form } from "react-bootstrap";
import { AddCharges } from "../../../application/actions/chargesAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import {
  restrictAllNumbers,
  restrictAllSpecialCharacters,
  restrictConsecutiveSpace,
  validateRequired,
  validateRequiredDropdown,
} from "../../../infrastructure/core/validationUtils";
import Environment from "../../../infrastructure/core/Environment";
export default function AddNewCharges(props) {
  const dispatch = useDispatch();
  const branch = JSON.parse(localStorage.getItem("branch"));
  const { user ,selectedBranchData} = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);

  const [chargeTypeVisi, setChargeTypeVisi] = useState();
  const initialState = {
    tenantId: user?.tenanatId,
    branchId: branch.value,
    isVisible: true,
    isDeleted: false,
    createdBy: user.id,
    modifiedBy: "",
    chargeName: "",
    chargeDescription: "",
    appliedOn: Environment.defaultValue,
    chargeType:  Environment.defaultValue,
    fixedRate: 0,
    isAutoPay: "",
  };
  const [formData, setFormData] = useState(initialState);
  const appliedOnCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "APLDON"
      )
    : [];
  const chargeTypeCommonList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
        (fields) => fields.fieldTypeCode === "CHRGTY"
      )
    : [];

  useEffect(() => {
    Prism.highlightAll();
    setFormData(initialState);
  }, []);

  const [errors, setErrors] = useState({});
  const validateInput = () => {
    const newErrors = {};
    // Validate Charge Name
    newErrors.chargeName = validateRequired(formData.chargeName)
      ? ""
      : "Charge Name is required";

    // Validate appliedOn
    newErrors.appliedOn = validateRequired(formData.appliedOn)
      ? ""
      : "Please Select Applied On";
    // Validate chargeType
    newErrors.chargeType = validateRequired(formData.chargeType)
      ? ""
      : "Please Select Charge Type";
    // Validate catalogueCode
    newErrors.fixedRate = validateRequired(formData.fixedRate)
      ? ""
      : "Charge Rate is required ";
    // Validate catalogueCode
    newErrors.isAutoPay = validateRequired(formData.isAutoPay)
      ? ""
      : "Please Select Auto Apply";

    setErrors(newErrors);
    // Check if any field has errors
    return !Object.values(newErrors).some((error) => error);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrors({ ...errors, [name]: "" });
    if (name === "chargeType") {
      if (e.target.selectedOptions[0].innerText === "Floating %") {
        setChargeTypeVisi(true);
      } else {
        setChargeTypeVisi(false);
      }
    } else if (name === "isAutoPay") {
      if (e.target.value === "true") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: true,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: false,
        }));
      }
    }
  };
  const Onsubmit = (e) => {
    e.preventDefault();
      // Validate the form
      const isValid = validateInput();
      if (!isValid) {
        return;
      }
    dispatch(AddCharges(formData));
    props.detailsModal();
    handleClose()
  };
  const handleClose=()=>{
    props.closeFunction()
    setFormData(initialState);
    setErrors({});

  }
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={handleClose}
        placement="end"
        className="w-45"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Add New Charge</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Row>
            <Col xl="8">
              <div className="mb-4">
                <Form.Label>
                  Charge Name: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Charge Name"
                  name="chargeName"
                  value={formData.chargeName}
                  onChange={handleChange}
                  onKeyDown={(e) => {
                    restrictConsecutiveSpace(e);
                    restrictAllSpecialCharacters(e);
                    restrictAllNumbers(e);
                  }}                />
                 <Form.Text className="text-danger">
                  {errors.chargeName}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="12">
              <div className="mb-4">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Description"
                  name="chargeDescription"
                  value={formData.chargeDescription}
                  onChange={handleChange}
                  onKeyDown={restrictConsecutiveSpace}
                />
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Applied On <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="appliedOn"
                  value={formData.appliedOn}
                  onChange={handleChange}
                >
                  <option value={Environment.defaultValue} disabled>
                    Choose Applied On
                  </option>
                  {appliedOnCommonList?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.appliedOn}
                </Form.Text>
              </div>
            </Col>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Auto Apply: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="isAutoPay"
                  value={formData.isAutoPay}
                  onChange={handleChange}
                >
                  <option value={""} disabled>
                    Choose Auto Apply Status
                  </option>
                  <option value={true}>Yes</option>
                  <option value={false}>No</option>
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.isAutoPay}
                </Form.Text>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Charge Type: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Select
                  aria-label="Default select example"
                  name="chargeType"
                  value={formData.chargeType}
                  onChange={handleChange}
                >
                  <option value={Environment.defaultValue} disabled>
                    Choose Charge Type
                  </option>
                  {chargeTypeCommonList?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.fieldValue}
                    </option>
                  ))}
                </Form.Select>
                <Form.Text className="text-danger">
                  {errors.chargeType}
                </Form.Text>
              </div>
            </Col>
            {!chargeTypeVisi ? (
              <>
                <Col xl="6">
                  <div className="mb-4">
                    <Form.Label>
                      Enter Fixed Charge: <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Group className="position-relative overflow-hidden">
                      <Form.Label className="custom-label mb-0">{parsedBranchData?.currencySymbol}</Form.Label>
                      <Form.Control
                        className="tax-percentage-input"
                        style={{ paddingLeft: "50px" }}
                        type="number"
                        min="1"
                        placeholder="Enter Tax Percentage"
                        name="fixedRate"
                        value={formData.fixedRate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                    <Form.Text className="text-danger">
                  {errors.fixedRate}
                </Form.Text>
                  </div>
                </Col>
              </>
            ) : (
              <>
                <Col xl="6">
                  <div className="mb-4">
                    <Form.Label>
                      Enter Float Percentage:{" "}
                      <sup className="text-danger">*</sup>
                    </Form.Label>
                    <Form.Group className="position-relative overflow-hidden">
                      <Form.Label className="custom-label mb-0">%</Form.Label>
                      <Form.Control
                        className="tax-percentage-input"
                        style={{ paddingLeft: "50px" }}
                        type="number"
                        min="1"
                        placeholder="Enter Tax Percentage"
                        name="fixedRate"
                        value={formData.fixedRate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Offcanvas.Body>

        <div className="offcanvas-footer bg-white">
          <Button
            variant="primary"
            className="fs-14 d-flex align-items-center"
            type="submit"
            onClick={Onsubmit}
          >
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>{" "}
            <span className="align-middle">Create New Charge</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
