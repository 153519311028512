// react-packages
import React, { useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Offcanvas, Row, Col, Tabs, Tab } from "react-bootstrap";
import Prism from "prismjs";
//components
import CustomerEditInfo from "./customer-edit-info";
import CustomerOrderlist from "./customer-order-list";
import CustomerLoyaltyPoints from "./loyalty-points";
import CustomerAddress from "./customer-address";
//actions,
import {
  customerAddressList,
  customerDetail,
  customerLoyaltyPointsList,
  customerOrderList,
  deleteCustomer,
} from "../../application/actions/customerAction";
import {
  getCustomerDetail,
  getCustomerOrderList,
  getLoading,
} from "../../application/selectors/indexSelector";
import Loader from "../../layouts/Loader";
import AuthContext from "../../infrastructure/core/helpers/AuthContext";

export default function ManageCustomer(props) {
  //accessing hook from react-redux package
  const dispatch = useDispatch();
  //fetching and storing customerdetails and orderlist
  const customerDetailData = useSelector(getCustomerDetail);
  const customerSummary = customerDetailData?.orderSummary;
  const customerOrderListData = useSelector(getCustomerOrderList);
  const loading = useSelector(getLoading).loading;
  const value = useSelector(getLoading).value;
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  //Loading all the details related to customer
  useEffect(() => {
    if (props?.data) {
      dispatch(customerDetail(props.data));
      dispatch(customerOrderList(props.data));
      dispatch(customerLoyaltyPointsList(props.data));
      dispatch(customerAddressList(props.data));
    }
  }, [props?.data]);
  //delete customer
  const handledeleteCustomer = (id) => {
    dispatch(deleteCustomer(id));
    props.closeFunction(false);
  };
  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={props.closeFunction}
        placement="end"
        className="w-70"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">
            Customer Details
          </Offcanvas.Title>
        </Offcanvas.Header>

        {loading &&
          (value === "customerDetail" ||
            value === "customerDetail" ||
            value === "customerLoyaltyPointsList" ||
            value === "customerAddressList") && <Loader />}

        <Offcanvas.Body>
          <div className="d-flex align-items-center justify-content-between mb-4">
            <div>
              <h2 className="fs-16 text-dark d-flex align-items-center mb-1 fw-semibold">
                <span>{customerDetailData?.fullName}</span>
              </h2>

              <div className="d-flex align-items-center">
                <div className="d-flex justify-content-between">
                  <h3 className="fs-14 text-dark">
                    {customerDetailData?.countryCode}{" "}
                    {customerDetailData?.contactNumber}
                  </h3>
                  <div className="divider divider-vertical"></div>
                </div>
                <div className="d-flex">
                  <h3 className="fs-14 text-dark">
                    {customerDetailData?.email}
                  </h3>
                  {customerDetailData?.isBlock ? (
                    <>
                      <div className="divider divider-vertical"></div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                {customerDetailData?.isBlock ? (
                  <>
                    <div>
                      <h3 className="fs-14 text-danger fw-semibold">
                        Blacklisted
                      </h3>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div>
              <Button
                variant="danger"
                className="fs-14"
                type="submit"
                onClick={() => handledeleteCustomer(customerDetailData.id)}
              >
                Delete Customer
              </Button>
            </div>
          </div>

          <Row className="mb-4">
            <Col>
              <h3 className="fs-18 text-danger mb-1">
                {parsedBranchData?.currencySymbol}{" "}
                {customerSummary?.pendingPayments} (
                {customerSummary?.pendingCount})
              </h3>
              <h5 className="fs-12 text-dark">Pending Payments</h5>
            </Col>
            <Col>
              <h3 className="fs-18 mb-1 text-dark">
                {customerSummary?.totalOrderCount} (
                {parsedBranchData?.currencySymbol}{" "}
                {customerSummary?.totalOrderPayments})
              </h3>
              <h5 className="fs-12 text-dark">Total Orders Placed</h5>
            </Col>
            <Col>
              <h3 className="fs-16 mb-1 text-dark">
                {customerSummary?.lastOrder === ""
                  ? "No orders placed yet"
                  : customerSummary?.lastOrder}
                {customerSummary?.lastOrder === "" ? (
                  <></>
                ) : (
                  <small className="text-dark fs-12"> ago</small>
                )}
                {/* days */}
              </h3>
              <h5 className="fs-12 text-dark">Last Orders</h5>
            </Col>
            <Col>
              <h3 className="fs-18 mb-1 text-dark">
                {parsedBranchData?.currencySymbol}{" "}
                {customerSummary?.creaditLimit}
              </h3>
              <h5 className="fs-12">Creadit Limit</h5>
            </Col>
            <Col>
              <h3 className="fs-18 mb-1 text-success">
                {customerSummary?.loyaltyPoints}{" "}
                <small className="text-dark fs-12">Points</small>
              </h3>
              <h5 className="fs-12 text-dark">Loyalty Points</h5>
            </Col>
          </Row>

          <Tabs
            defaultActiveKey="edit_info_tab"
            className="sale-on-point-tabs"
            id="uncontrolled-tab-example"
          >
            <Tab
              eventKey="edit_info_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Edit Info</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <CustomerEditInfo closeFunction={props.closeFunction} />
              </div>
            </Tab>
            <Tab
              eventKey="order_list_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">
                    Order List
                    <span className="badge rounded-pill badge-length-web">
                      {customerOrderListData && customerOrderListData.length
                        ? customerOrderListData.length
                        : 0}
                    </span>
                  </h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <CustomerOrderlist />
              </div>
            </Tab>

            <Tab
              eventKey="addresses_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Addresses</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <CustomerAddress />
              </div>
            </Tab>

            <Tab
              eventKey="loyalty_points_tab"
              title={
                <>
                  <h6 className="fs-14 mb-0">Loyalty Points Log</h6>
                </>
              }
              tabClassName="custom-tab-header"
            >
              <div className="custom-tab-body pt-3">
                <CustomerLoyaltyPoints />
              </div>
            </Tab>
          </Tabs>
        </Offcanvas.Body>
      </Offcanvas>
    </React.Fragment>
  );
}
