import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Button, Card, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getBranchList,
  getEmpAttendance,
  getEmployeeList,
  getLoading,
} from "../../../application/selectors/indexSelector";
import ReactDatePicker from "react-datepicker";
import MarkEmpList from "../../../Json/Attendance/mark-attendance.json";
import Select from "react-select";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import { LoadEmployee } from "../../../application/actions/employeeAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import {
  EmployeeDaywiseAttendace,
  MarkEmpAttendance,
} from "./../../../application/actions/attendanceAction";
import { debounce } from "lodash";
import Environment from "../../../infrastructure/core/Environment";
import { format } from "date-fns";

export default function MarkAttendace({ AttendanceStatus }) {
  const dispatch = useDispatch();
  const { user } = useContext(AuthContext);
  const branch = JSON.parse(localStorage.getItem("branch"));
  const BranchList = useSelector(getBranchList);
  console.log(BranchList);
  const date = new Date();

  const initialData = {
    branchId: user.branchId,
    attendanceDay: date.toISOString(),
  };
  useEffect(() => {
    Prism.highlightAll();
    // setStartDate(date.toISOString())
    dispatch(EmployeeDaywiseAttendace(initialData));
  }, []);
  const MarkAttendanceAsPerDay = useSelector(getEmpAttendance);
  const [startDate, setStartDate] = useState(new Date());
  const { loading, value } = useSelector(getLoading);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState({});

  const options = AttendanceStatus.map((status) => ({
    value: status.id,
    label: status.fieldValue,
  }));
  const handleChange = (userId, selected) => {
    setSelectedOption((prev) => ({
      ...prev,
      [userId]: selected,
    }));
    const data = [
      {
        userId: userId,
        attendanceDay: startDate.toISOString(),
        branchId: branchData,
        status: selected.value,
      },
    ];
    dispatch(MarkEmpAttendance(data));
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: selectedOption ? selectedOption.value : null,
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
  };

  const filteredEmployees = MarkAttendanceAsPerDay?.filter((item) =>
    Object.values(item).some(
      (value) =>
        value &&
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  const [branchData, setBranchData] = useState(branch?.value);
  const filterDate = () => {
    const data = {
      branchId: branchData,
      attendanceDay: startDate,
    };
    dispatch(EmployeeDaywiseAttendace(data));
  };
  console.log(MarkAttendanceAsPerDay);
  return (
    <React.Fragment>
      <div className="mb-4">
        <Row className="gap-2">
          <Col xl={8}>
            <Row className="g-3">
              <Col xl={4} md={4} sm={5}>
                <Form.Select
                  aria-label="Default select example"
                  className="fs-13"
                  name="branch"
                  value={branchData}
                  onChange={(e) => setBranchData(e.target.value)}
                >
                  {BranchList?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.branchName}
                    </option>
                  ))}
                </Form.Select>
              </Col>
              <Col xl={4} md={4} sm={5}>
                <ReactDatePicker
                  dateFormat="MMMM dd, yyyy"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  className="form-control"
                />
              </Col>
              <Col xl={2} md={2} sm={2}>
                <Button variant="dark" className="fs-12" onClick={filterDate}>
                  <i className="ri-filter-line"></i> Filter
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Card className="card-one">
        <Card.Body>
          <div className="d-md-flex align-items-center justify-content-between mb-3">
            <div>
              <h4 className="main-title fs-14 mb-0">
                Mark Employee Attendance
              </h4>
            </div>
            <div className="custom-dropdown-wrapper">
              <div className="custom-drop-down z-index-2 wt-250">
                <span className="dropdown-icon bg-white">
                  <i className="ri-search-line"></i>
                </span>
                <Form.Control
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
            </div>
          </div>

          {filteredEmployees && filteredEmployees !== undefined && (
            <Table className="mb-0" responsive>
              <thead>
                <tr>
                  <th>Employee Name</th>
                  <th>Todays Status</th>
                  <th>Remark</th>
                </tr>
              </thead>

              <tbody>
                {filteredEmployees?.map((item, index) => (
                  <tr key={index}>
                    <td>{item.userName}</td>
                    <td className="wt-400">
                      <Select
                        value={selectedOption[item.id] || null}
                        onChange={(e) => handleChange(item.id, e)}
                        options={options}
                        styles={customStyles}
                        placeholder="Choose Attendance"
                      />
                    </td>
                    <td>
                      <Form.Control
                        type="text"
                        placeholder="Enter Remark"
                        name="remark"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {!loading && filteredEmployees === undefined && (
            <TableShimmerLoader
              colNames={filteredEmployees?.map((column) => column.fullName)}
              colsCount={1}
            />
          )}
          {loading &&
            value === "MarkEmpList" &&
            filteredEmployees === undefined && (
              <TableShimmerLoader
                colNames={filteredEmployees?.map((column) => column.fullName)}
                colsCount={1}
              />
            )}
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
