import React, { useState, useEffect } from "react";
import { Offcanvas, Button, Form, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import whatsappIcon from "../../../../assets/img/icons/whatsapp.png";
import commentIcon from "../../../../assets/img/icons/comment.png";
import emailIcon from "../../../../assets/img/icons/email.png";
//import Select from "react-select";
import { getCustomerList } from "../../../../application/selectors/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { askForBusinessReview } from "../../../../application/actions/customerAction";

export default function BusinessReviewMd(props) {
  const dispatch = useDispatch();
  const [ratingText, setRatingText] = useState("");
  const [activeBadge, setActiveBadge] = useState(1);
  const [copied, setCopied] = useState(false);
  const [selectedCustomerNumber, setSelectedCustomerNumber] = useState(null);
  const [selectedReviewMedium, setSelectedReviewMedium] = useState(null);

  const customerListData = useSelector(getCustomerList);
  const reviewMediumList = JSON.parse(localStorage.getItem("CommonFields"))
    ? JSON.parse(localStorage.getItem("CommonFields")).filter(
      (fields) => fields.fieldTypeCode === "RMED"
    )
    : [];
  //manipulating the customer name and number list as needed in select package of react
  const customerContactList = customerListData?.map((option) => ({
    value: option.id,
    label: option.contactNumber,
  }));
  const ratingMessages = [
    "Relished your meal? Rate us ★★★★★! Share your thoughts: [link]. Thanks!",
    "Enjoyed dining with us? Give us ★★★★★! Share your feedback: [link]. Thanks",
    "Satisfied with your dining experience? Let us know how we did: [link]. Your feedback helps us serve you better. Thank you for choosing us!",
    "Had a delightful meal? We'd love to hear from you! Share your experience: [link]. Thanks so much for dining with us!",
    "Feasted with delight? Share your insight! Review us here: [link]. Thanks for the bite!",
  ];

  useEffect(() => {
    if (props?.show) {
      handleRatingMsg(1);
      setSelectedCustomerNumber({
        value: props?.getCustomerDetails?.id,
        label: props?.getCustomerDetails?.contactNumber,
      });
      setSelectedReviewMedium('d9c5a952-911e-4a0f-8935-5e1fae03001e')
    }
  }, [props?.show]); // Empty dependency array ensures that this effect runs only once, after the initial render

  const handleRatingMsg = (msg) => {
    setActiveBadge(msg);
    setRatingText(ratingMessages[msg - 1]);
  };

  const handleChange = (e) => {
    setRatingText(e.target.value);
  };
  const handleSelectionChange = (reviewMediumId) => {
    setSelectedReviewMedium(reviewMediumId);
  };
  const handleCopyText = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };
  const handleSubmit = () => {
    const formObject = {
      customerId: selectedCustomerNumber.value,
      message: ratingText,
      socialMediaId: selectedReviewMedium,
    };
    dispatch(askForBusinessReview(formObject))
  };

  return (
    <React.Fragment>
      <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-35">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="fs-16 text-dark">Ask for Business Review</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            {/* <Col xl={8}>
              <div className="mb-4 custom-dropdown-wrapper position-relative">
                <Form.Label className="fs-14">Customer Mobile Number</Form.Label>
                <div className="custom-drop-down z-index-2">
                  <span className="dropdown-icon">+91</span>
                  <Select
                    value={selectedCustomerNumber}
                    onChange={(selected) => {
                      setSelectedCustomerNumber(selected);
                    }}
                    onKeyDown={(e) => {
                      const key = e.key;
                      const allowedKeys =
                        /^[a-zA-Z0-9]$/.test(key) ||
                        key === "Backspace" ||
                        key === "Delete" ||
                        key === "ArrowLeft" ||
                        key === "ArrowRight" ||
                        key === "ArrowUp" ||
                        key === "ArrowDown" ||
                        key === "Home" ||
                        key === "End" ||
                        key === "Enter" ||
                        key === "Escape";
                      if (!allowedKeys) {
                        e.preventDefault();
                        return;
                      }
                      setSelectedCustomerNumber(null);
                    }}
                    options={customerContactList}
                    getOptionLabel={(option) => option.label}
                    getOptionValue={(option) => option.value}
                    placeholder="Enter Customer Mobile Number"
                  />
                </div>
              </div>
            </Col> */}

            <Col xl={12}>
              <div className="d-flex align-items-center justify-content-between">
                <Form.Label className="fs-14">Messages</Form.Label>
                <div className="d-flex gap-2">
                  {[1, 2, 3, 4, 5].map((msg) => (
                    <span
                      key={msg}
                      className={`msg-rating-bttn ${activeBadge === msg ? "active" : ""
                        }`}
                      onClick={() => handleRatingMsg(msg)}
                    >
                      {msg}
                    </span>
                  ))}
                </div>
              </div>
              <Form.Control
                as="textarea"
                rows="3"
                placeholder="Enter message here..."
                value={ratingText}
                onChange={handleChange}
              ></Form.Control>
              <div className="text-right">
                {!copied && (
                  <CopyToClipboard text={ratingText} onCopy={handleCopyText}>
                    <Link className="text-dark">
                      <i className="ri-links-line fs-18 align-middle me-1"></i>
                      <span className="align-middle">Copy Text</span>
                    </Link>
                  </CopyToClipboard>
                )}
                {copied && (
                  <div className="text-success">
                    <i className="ri-links-line fs-18 align-middle me-1"></i>{" "}
                    <span className="align-middle">Copied!</span>
                  </div>
                )}
              </div>
            </Col>
          </Row>

          <div>
            <h5 className="fs-14 mb-2 fw-semibold">Share Via</h5>
            <div className="d-flex gap-2 share-via">
              {reviewMediumList?.map((reviewMedium) => (
                <Link key={reviewMedium.id}
                  onClick={() => handleSelectionChange(reviewMedium.id)}
                  style={{ cursor: "pointer" }}
                  className={`text-center ${selectedReviewMedium === reviewMedium.id ? "active" : ""}`}
                >
                  <div>
                    <Image
                      src={
                        reviewMedium.id === "d9c5a952-911e-4a0f-8935-5e1fae03001e" ?  emailIcon : 
                        reviewMedium.id === "d9c5a952-911e-4a0f-8935-5e1fae03002e" ? commentIcon : 
                        reviewMedium.id === "d9c5a952-911e-4a0f-8935-5e1fae03003e" ? whatsappIcon : ""
                      }
                      className="wt-25"
                    />
                    <h6 className="fs-12 mt-2">{reviewMedium.fieldValue}</h6>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </Offcanvas.Body>

        <div className="offcanvas-footer">
          <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center" onClick={handleSubmit}>
            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
            <span className="align-middle">Send</span>
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
