import React, { useEffect, useState } from "react";
import Header from "../../layouts/Header";
import { Nav, Tab } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./system-setting.scss";
import BusinessProfile from "./BusinessProfile/business-profile";
import SettingPointOfSale from "./SaleOfPoint/sale-of-point";
import Locations from "./Locations/locations";
import UserRoles from "./UserRoles/user-role-roles";
import Employees from "./Employees/employees";
import PrintTemplates from "./PrintTemplates/print-templates";
import EBills from "./EBills/e-bills";
import { Link } from "react-router-dom";
import PasswordPolicy from "./PasswordPolicy/password-policy";
import Integration from "./Integration/integration";
import PaymentGateway from "./PaymentGateway/payment-gateway";
import Environment from "../../infrastructure/core/Environment";
import { debounce } from "lodash";
import Vendor from "./Vendor/vendor";

export default function SystemSettings() {
    useEffect(() => {
        document.body.classList.add('page-app');
        return () => {
            document.body.classList.remove('page-app');
        }
    }, []);
    const [branchData, setBranchData] = useState(Environment.defaultValue);
    const [isSidebarShow, setSidebarShow] = useState(false);
    const handleBranchIdFromHeader = debounce((data) => {
        setBranchData(data);
      }, 300);
    return (
        <React.Fragment>
            <Header passDataToPointOfSale={handleBranchIdFromHeader}/>
            <Tab.Container id="left-tabs-example" defaultActiveKey="business_profile_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="business_profile_tab"><i className="ri-archive-line"></i> Business Profile</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="point_sale_tab"><i className="ri-contacts-fill"></i> Point of Sale</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="print_templates_tab"><i className="ri-printer-line"></i> Print Templates</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="locations_tab"><i className="ri-map-pin-line"></i> Locations</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="user_roles_tab"><i className="ri-user-line"></i> User Roles</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="employees_tab"><i className="ri-group-line"></i> Employees</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="vendor_tab"><i className="ri-group-line"></i> Vendor</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="kitchen_system_tab"><i className="ri-tv-2-line"></i> Kitchen Display System</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="e_bills_tab"><i className="ri-delete-bin-line"></i> E Bills</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="integration_tab"><i className="ri-settings-line"></i> Integration</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="payment_gateway_tab"><i className="ri-bank-card-line"></i> Payment Gateway</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="password_policy_tab"><i className="ri-lock-password-line"></i> Password Policy</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey="fudeasy_apps_tab"><i className="ri-android-line"></i> FudEasy Apps</Nav.Link></Nav.Item>
                        </Nav>
                    </PerfectScrollbar>
                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <Tab.Content>
                            {/* POINT OF SALE TAB */}
                            <Tab.Pane eventKey="business_profile_tab"><BusinessProfile /></Tab.Pane>

                            {/* BUSINESS DETAILS TAB */}
                            <Tab.Pane eventKey="point_sale_tab"><SettingPointOfSale /></Tab.Pane>

                            {/* PRINT TEMPLATES TAB */}
                            <Tab.Pane eventKey="print_templates_tab"><PrintTemplates /></Tab.Pane>

                            {/* LOCATIONS TAB */}
                            <Tab.Pane eventKey="locations_tab"><Locations /></Tab.Pane>

                            {/* USER ROLES TAB */}
                            <Tab.Pane eventKey="user_roles_tab"><UserRoles /></Tab.Pane>

                            {/* EMPLOYEES ROLES TAB */}
                            <Tab.Pane eventKey="employees_tab"><Employees /></Tab.Pane>
                            
                            {/* EMPLOYEES ROLES TAB */}
                            <Tab.Pane eventKey="vendor_tab"><Vendor /></Tab.Pane>

                            {/* KITCHEN SYSTEM TAB */}
                            <Tab.Pane eventKey="kitchen_system_tab">Second tab content</Tab.Pane>

                            {/* E BILL TAB */}
                            <Tab.Pane eventKey="e_bills_tab"><EBills /></Tab.Pane>

                             {/* Integration TAB */}
                             <Tab.Pane eventKey="integration_tab"><Integration /></Tab.Pane>

                              {/* Payment Gateway TAB */}
                            <Tab.Pane eventKey="payment_gateway_tab"><PaymentGateway /></Tab.Pane>

                            {/* PASSWORD POLICY TAB */}
                            <Tab.Pane eventKey="password_policy_tab"><PasswordPolicy /></Tab.Pane>

                            {/* FUDEASY APPS TAB */}
                            <Tab.Pane eventKey="fudeasy_apps_tab">Second tab content</Tab.Pane>
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
}