import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Badge, Form, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ManageLocation from "./manage-location";
import { useDispatch, useSelector } from "react-redux";
import { getSectionByBranchId } from "../../../application/actions/tableAction";
import { GetBranchOTSessions } from "../../../application/actions/branchAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { getBranchList, getLoading } from "../../../application/selectors/indexSelector";
import { loadBranch } from "../../../application/actions/loaderAction";
import TableShimmerLoader from "../../../layouts/ShimmerTable";
import DataTable from "react-data-table-component";
import { GetAllCurrencyList } from './../../../application/actions/accountAction';
import { format } from "date-fns";

export default function Locations() {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch()
  const { user } = useContext(AuthContext);
  useEffect(() => {
    dispatch(loadBranch(user?.tenanatId))
    dispatch(GetAllCurrencyList);
  }, [])

  const [showManageLocation, setShowManageLocation] = useState(false);
  const [branch, setBranch] = useState({});
  const handleManageLocation = (data, state) => {
    setShowManageLocation(state);
    setBranch(data)
    if (data !== undefined) {
      dispatch(getSectionByBranchId(data?.id))
      dispatch(GetBranchOTSessions(data?.id))
    }
  }
  const { loading, value } = useSelector(getLoading)
  const BranchList = useSelector(getBranchList)
  const columns = [
    {
      name: "Location Name",
      selector: (row) => <Link onClick={() => handleManageLocation(row, true)} className="fw-semibold">{row.branchName}</Link>
    },
    {
      name: "Location Code",
      selector: (row) => row.shortCode,
    },
    {
      name: "Status",
      selector: (row) =>

        <Badge bg={`${row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e400" ? "success" : "danger"}`} className="fs-14 wt-100 text-uppercase round" pill>{row.status === "a5ca48b8-bfd1-42d6-8db1-7021c131e400" ? "Active" : "InActive"}</Badge>
    },
    {
      name: "Created On",
      selector: (row) => <span >
        {format(new Date(row.createdOn), "dd MMM yyyy hh:mm a")}
      </span>,
    },
    {
      name: "Action",
      selector: (row) => (
        <div className="d-flex justify-content-end">
          <Button variant="primary" className="btn-icon me-2" onClick={() => handleManageLocation(row, true)} ><i className="ri-pencil-line"></i></Button>
        </div>
      ),
    },
  ];


  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = BranchList?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <React.Fragment>
      <ManageLocation show={showManageLocation} branch={branch} closeFunction={handleManageLocation} />
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="main-title fs-16 mb-0">Locations</h4>
        </div>
      </div>
      <Card className="card-one">
        <Card.Header className="align-items-center justify-content-between">
          <h4 className="main-title fs-14 mb-0">List of Locations</h4>
          <div className="custom-dropdown-wrapper">
            <div className="custom-drop-down z-index-2 wt-300">
              <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
              <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          <div className="custom-common-table">
            {filteredEmployees && filteredEmployees !== undefined && (
              <DataTable
                columns={columns}
                data={filteredEmployees}
                fixedHeader
                search={true}
                highlightOnHover
                pagination
              ></DataTable>
            )}
          </div>
          {!loading && filteredEmployees === undefined && <TableShimmerLoader columnCount={4} rowCount={4} />}
          {loading && value === 'branchList' && filteredEmployees === undefined && <TableShimmerLoader columnCount={4} rowCount={4} />}
        </Card.Body>
      </Card>
    </React.Fragment>
  )
}