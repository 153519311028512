const CustomerSatisfactionlist = [
    {
        "name": "First Impression",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 35 },
            { "label": "Positive", "variant": "success", "percentage": 65 }
        ]
    },
    {
        "name": "Will Recommend",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 10 },
            { "label": "Positive", "variant": "success", "percentage": 90 }
        ]
    },
    {
        "name": "Chance of revisit",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 15 },
            { "label": "Positive", "variant": "success", "percentage": 85 }
        ]
    },
    {
        "name": "Extremly Upset",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 5 },
            { "label": "Positive", "variant": "success", "percentage": 95 }
        ]
    },
    {
        "name": "Miss Leaded",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 7 },
            { "label": "Positive", "variant": "success", "percentage": 93 }
        ]
    },
    {
        "name": "Wrong Commintment",
        "progressData": [
            { "label": "Negetaive", "variant": "danger", "percentage": 20 },
            { "label": "Positive", "variant": "success", "percentage": 80 }
        ]
    }
];

const negetiveRating = [
    [0, 40],
    [1, 39],
    [2, 35],
    [3, 33],
    [4, 28],
    [5, 28],
    [6, 24],
    [7, 20],
    [8, 17],
    [9, 16],
    [10, 19],
    [11, 16],
    [12, 13],
    [13, 18],
    [14, 17],
    [15, 18],
    [16, 18],
    [17, 19],
    [18, 18],
    [19, 17],
    [20, 20],
    [21, 18],
    [22, 17],
    [23, 17],
    [24, 15],
    [25, 15],
    [26, 14],
    [27, 15],
    [28, 18],
    [29, 19],
    [30, 23]
];

const positiveRating = [
    [0, 40],
    [1, 39],
    [2, 35],
    [3, 33],
    [4, 28],
    [5, 28],
    [6, 24],
    [7, 20],
    [8, 17],
    [9, 16],
    [10, 19],
    [11, 16],
    [12, 13],
    [13, 18],
    [14, 17],
    [15, 18],
    [16, 18],
    [17, 19],
    [18, 18],
    [19, 17],
    [20, 20],
    [21, 18],
    [22, 17],
    [23, 17],
    [24, 15],
    [25, 15],
    [26, 14],
    [27, 15],
    [28, 18],
    [29, 19],
    [30, 23]
];

const staffreviewslist = [
    {
        "staff_name": "Aamir Sayyed",
        "staff_friendliness": "1 :: 1",
        "staff_attentivenes": "1 :: 1",
        "professionalism": "1 :: 1",
        "accuracyof_orders": "1 :: 1",
        "handling_of_complaints": "1 :: 1",
        "bias_behaviour": "1 :: 1",
        "staff_appearance": "1 :: 1",
        "score": "5.5",
    },
    {
        "staff_name": "Ravi Kore",
        "staff_friendliness": "1 :: 1",
        "staff_attentivenes": "1 :: 1",
        "professionalism": "1 :: 1",
        "accuracyof_orders": "1 :: 1",
        "handling_of_complaints": "1 :: 1",
        "bias_behaviour": "1 :: 1",
        "staff_appearance": "1 :: 1",
        "score": "7.8",
    },
    {
        "staff_name": "Fazal Harron",
        "staff_friendliness": "1 :: 1",
        "staff_attentivenes": "1 :: 1",
        "professionalism": "1 :: 1",
        "accuracyof_orders": "1 :: 1",
        "handling_of_complaints": "1 :: 1",
        "bias_behaviour": "1 :: 1",
        "staff_appearance": "1 :: 1",
        "score": "6.4",
    },
]


const foodReviewlist = [
    {
        "dish_name": "Algerian Saudi Mandi",
        "taste": "1 :: 1",
        "cost": "1 :: 1",
        "authenticity": "1 :: 1",
        "texture": "1 :: 1",
        "healthiness": "1 :: 1",
        "score": "8.5",
    },
    {
        "dish_name": "Yamani Turkey Raan Mandi",
        "taste": "1 :: 1",
        "cost": "1 :: 1",
        "authenticity": "1 :: 1",
        "texture": "1 :: 1",
        "healthiness": "1 :: 1",
        "score": "7.7",
    },
    {
        "dish_name": "Lebanese Turkey Mandi",
        "taste": "1 :: 1",
        "cost": "1 :: 1",
        "authenticity": "1 :: 1",
        "texture": "1 :: 1",
        "healthiness": "1 :: 1",
        "score": "9.1",
    },
    {
        "dish_name": "CHICKE HOT GARLIC",
        "taste": "1 :: 1",
        "cost": "1 :: 1",
        "authenticity": "1 :: 1",
        "texture": "1 :: 1",
        "healthiness": "1 :: 1",
        "score": "6.8",
    },
];

export { CustomerSatisfactionlist, negetiveRating, positiveRating, staffreviewslist, foodReviewlist };