import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Button, Row, Col, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UpdateAddOnsGroup } from "../../../application/actions/addOnsAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";
import { getAddOnsData } from "../../../application/selectors/indexSelector";
import environment from "../../../infrastructure/core/Environment";
import Environment from "../../../infrastructure/core/Environment";
import { restrictAllNumbers, restrictAllSpecialCharacters, restrictConsecutiveSpace, restrictOnlyNumber, validateRequired, validateRequiredDropdown } from "../../../infrastructure/core/validationUtils";

export default function EditAddons(props) {
  const { user } = useContext(AuthContext);
  const AddOnsData = useSelector(getAddOnsData);
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const initialState = {
    status: "a5ca48b8-bfd1-42d6-8db1-7021c131e400",
    isVisible: true,
    createdBy: user.id,
    modifiedBy: user.id,
    branchId: user.branchId,
    groupTitle: "",
    groupDesc: "",
    groupTypeId: Environment.defaultValue,
    minAllowedQty: 0,
    maxAllowedQty: 0,
    isMandatory: true,
    // isQuantityUsed:false
  };
  const [formData, setFormData] = useState(initialState);
  useEffect(() => {
    if (AddOnsData?.id !== undefined) {
      setFormData({
        ...formData,
        id: AddOnsData?.id,
        status: AddOnsData.status,
        isVisible: AddOnsData.isVisible,
        isDeleted: false,
        createdBy: AddOnsData.createdBy,
        modifiedBy: AddOnsData.modifiedBy,
        groupTitle: AddOnsData.groupTitle,
        groupDesc: AddOnsData.groupDesc,
        groupTypeId: AddOnsData.groupTypeId,
        minAllowedQty: AddOnsData.minAllowedQty,
        maxAllowedQty: AddOnsData.maxAllowedQty===9999?'\u221E':AddOnsData.maxAllowedQty,
        isMandatory: AddOnsData.isMandatory,
        branchId: user.branchId,
      });
    } else {
      setFormData(initialState);
    }
  }, [AddOnsData?.id]);

  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const validateName = () => {
    const newErrors = {};
    // Validate catalogueName
    newErrors.groupTitle = validateRequired(formData.groupTitle)
      ? ""
      : "AddOns Group Name is required";
    // Validate mainCatId (dropdown)
    newErrors.groupTypeId = validateRequiredDropdown(formData.groupTypeId)
      ? ""
      : "Please Addons Group Type";

      setErrors(newErrors);
      // Check if any field has errors
      return !Object.values(newErrors).some((error) => error);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    const IsValid = validateName();
    if (!IsValid) {
      return;
    }
    dispatch(UpdateAddOnsGroup({...formData,maxAllowedQty:formData.maxAllowedQty=== '∞'?9999:formData.maxAllowedQty}));
  };
  const GroupType = JSON.parse(localStorage.getItem("CommonFields")).filter(
    (item) => item.fieldTypeCode === "GRPTY"
  );
  const handleMaxAllowedQtyChange = (e) => {
    let newValue = e.target.value;

    // Remove any non-numeric characters except the infinity symbol
    newValue = newValue.replace(/[^0-9]/g, '');

    // Ensure the value is not empty
    if (newValue === '') {
      newValue = '∞'; // Set back to infinity symbol if value becomes empty
    }else if (parseInt(newValue) === 0) {
      newValue = '1'; // Set to minimum allowed value if input is '0'
    }
    setFormData({
      ...formData,
      maxAllowedQty: newValue,
    });
  };
  return (
    <React.Fragment>
      <Row>
        <Col xl="8">
          <div className="mb-4">
            <Form.Label>
              Group Title: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="groupTitle"
              value={formData.groupTitle}
              onChange={(e) => {
                setFormData({ ...formData, groupTitle: e.target.value });
                setErrors({ ...errors, groupTitle: "" });
              }}
              onKeyDown={(e) => {
                restrictConsecutiveSpace(e);
                restrictAllSpecialCharacters(e);
                restrictAllNumbers(e);
              }}
              placeholder="Enter Group Title"
            />
            <Form.Text className="text-danger">
              {errors.groupTitle}
            </Form.Text>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Group Type: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Select
              aria-label="Default select example"
              defaultValue={`${environment.defaultValue}`}
              name="groupTypeId"
              value={formData.groupTypeId}
              onChange={(e) => {
                setFormData({ ...formData, groupTypeId: e.target.value });
                setErrors({ ...errors, groupTypeId: "" });
              }}
            >
              <option value={Environment.defaultValue} disabled>
                Group Type
              </option>
              {GroupType?.map((option,index) => (
                <option key={index} value={option.id}>
                  {option.fieldValue}
                </option>
              ))}
            </Form.Select>
            {formData.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e" &&
              formData.groupTypeId !== Environment.defaultValue && (
                <h6 className="fs-11 mt-2 text-dark">
                  This will allow to choose multiple item from the list.
                </h6>
              )}
              <Form.Text className="text-danger">
                  {errors.groupTypeId}
                </Form.Text>
          </div>
        </Col>
      </Row>
      {formData.groupTypeId !== "d9c5a952-911e-4a0f-8935-5e1fae01201e" &&
        formData.groupTypeId !== Environment.defaultValue && (
          <Row>
            <Col xl="6">
              <div className="mb-4">
                <Form.Label>
                  Max Item Allowed: <sup className="text-danger">*</sup>
                </Form.Label>
                <Form.Control
                  type="text" min="1"
                  name="maxAllowedQty"
                  value={formData.maxAllowedQty}
                  onChange={handleMaxAllowedQtyChange}
                  onKeyDown={restrictOnlyNumber}
                />
              <h6 className="fs-14 mt-2 text-dark">
                    {formData.maxAllowedQty=== '∞'? 'Unlimited':''}</h6>
              </div>
            </Col>
          </Row>
        )}
      {formData.groupTypeId === "d9c5a952-911e-4a0f-8935-5e1fae01201e" && (
        <Row>
          <Col xl="6">
            <div className="mb-4">
              <Form.Label>
                Is it Mandatory?: <sup className="text-danger">*</sup>
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                name="isMandatory"
                value={formData.isMandatory.toString()}
                onChange={(e) => {
                  const value = e.target.value === "true";
                  setFormData({
                    ...formData,
                    isMandatory:value,
                  });
                }}
              >
                 <option value="true">YES</option>
                 <option value="false">NO</option>
              </Form.Select>
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col xl="12">
          <div className="mb-3">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              name="groupDesc"
              value={formData.groupDesc}
              onChange={(e) => {
                setFormData({ ...formData, groupDesc: e.target.value });
              }}
              onKeyDown={restrictConsecutiveSpace}
              placeholder="Enter Description"
            />
          </div>
        </Col>
      </Row>
      <div className="offcanvas-footer justify-content-start position-absolute bg-white">
        <Button
          variant="primary"
          className="fs-14"
          type="submit"
          onClick={handleSubmit}
        >
          <i className="ri-add-line fs-18 lh-1 align-middle"></i>
          <span className="align-middle">Update Addons Group</span>
        </Button>
      </div>
    </React.Fragment>
  );
}
