import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Accordion, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getOperationHoursList } from "../../../../application/selectors/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";
import { weekdays } from "moment/moment";
import { UpdateOperatingHours } from "../../../../application/actions/branchAction";

export default function WeeklySchedule(props) {
  const OperationHoursList = useSelector(getOperationHoursList);
  const initialState = {
    id: Environment.defaultValue,
    branchId: props?.branch,
    orderTypeId: Environment.defaultValue,
    day: 0,
    branchStartTime: "00:00:00",
    branchCloseTime: "00:00:00",
    isStandardTimeSlots: true,
    isNextDay: true,
  };
  const [formData, setFormData] = useState(initialState);
  const dispatch = useDispatch();

  const weekDays = [
    {
      id: 0,
      name: "Sun",
      checked: false,
    },
    {
      id: 1,
      name: "Mon",
      checked: false,
    },
    {
      id: 2,
      name: "Tue",
      checked: false,
    },
    {
      id: 3,
      name: "Wed",
      checked: false,
    },
    {
      id: 4,
      name: "Thu",
      checked: false,
    },
    {
      id: 5,
      name: "Fri",
      checked: false,
    },
    {
      id: 6,
      name: "Sat",
      checked: false,
    },
  ];
  const [selectedWeekdays, setUniqueWeekdays] = useState([]);
  const [dayTimes, setDayTimes] = useState({});
  useEffect(() => {
    // Initialize an object to hold the weekdays for each order type
    let data = {};
    // Iterate through OperationHoursList to populate the data object
    OperationHoursList?.forEach((opshrs) => {
      data[opshrs.orderTypeId] = weekDays.map((weekday) => ({
        ...weekday,
        checked: opshrs.branchOperationTimes.some(
          (branchTime) => branchTime.day === weekday.id
        ),
      }));
    });
    // Update the state with the combined data object
    setUniqueWeekdays(data);
    const initialDayTimes = {};

    // Check if OperationHoursList is defined before iterating over it
    if (OperationHoursList) {
      OperationHoursList.forEach((item) => {
        initialDayTimes[item.orderTypeId] = []; // Initialize dayTimes for each order type
        // Loop through each day of the week
        if (
          !item.branchOperationTimes ||
          item.branchOperationTimes?.length === 0
        ) {
          for (let i = 0; i < 7; i++) {
            // Check if data exists for the day and order type
            const dataExists = item.branchOperationTimes.some(
              (slot) => slot.day === i
            );
            // Push the day into initialDayTimes with default values if data doesn't exist
            if (!initialDayTimes[item.orderTypeId][i]) {
              initialDayTimes[item.orderTypeId][i] = []; // Initialize as an empty array if undefined
            }
            // Add default slot for each day
            initialDayTimes[item.orderTypeId][i].push({
              id: i,
              startTime: "00:00:00",
              endTime: "00:00:00",
              checked: dataExists, // Set checked based on data availability
              mappingId: Environment.defaultValue,
            });
          }
        } else if (
          item.branchOperationTimes &&
          item.branchOperationTimes?.length > 0
        ) {
          item.branchOperationTimes.forEach((slot) => {
            if (!initialDayTimes[item.orderTypeId][slot.day]) {
              initialDayTimes[item.orderTypeId][slot.day] = []; // Initialize as an empty array if undefined
            }
            initialDayTimes[item.orderTypeId][slot.day].push({
              id: slot.day,
              startTime: slot.branchStartTime,
              endTime: slot.branchCloseTime,
              checked: true, // Initialize checked to false
              mappingId: slot.id,
            });
          });
          data[item.orderTypeId].forEach((day) => {
            if (!day.checked) {
              // If the day is not checked in data, initialize an empty day object in initialDayTimes
              if (!initialDayTimes[item.orderTypeId][day.id]) {
                initialDayTimes[item.orderTypeId][day.id] = []; // Initialize as an empty array if undefined
              }
              initialDayTimes[item.orderTypeId][day.id].push({
                id: day.id,
                startTime: "00:00:00",
                endTime: "00:00:00",
                checked: false, // Set checked to false
                mappingId: Environment.defaultValue,
              });
            }
          });
        }
      });
    }
    setDayTimes(initialDayTimes);
  }, [OperationHoursList]);

  const handleCheckboxChange = (weekdayId, orderTypeId) => {
    // Retrieve the weekdays array for the specific orderTypeId
    const weekdaysForOrderType = selectedWeekdays[orderTypeId];

    // If weekdays array exists for the orderTypeId
    if (weekdaysForOrderType) {
      // Update the checked property of the corresponding weekday in the weekdays array
      const updatedWeekdays = weekdaysForOrderType.map((weekday) => {
        if (weekday.id === weekdayId) {
          return { ...weekday, checked: !weekday.checked }; // Toggle the checked property
        }
        return weekday;
      });
      // Update the uniqueWeekdays array with the updated weekdays
      setUniqueWeekdays({
        ...selectedWeekdays,
        [orderTypeId]: updatedWeekdays,
      });
    }
  };
  const handleAddTime = (orderTypeId, dayId) => {
    setDayTimes((prevDayTimes) => {
      // Create a copy of previous dayTimes
      const updatedDayTimes = {
        ...prevDayTimes,
        [orderTypeId]: Object.assign({}, prevDayTimes[orderTypeId], {
          [dayId]: [
            ...(prevDayTimes[orderTypeId]?.[dayId] || []),
            {
              id: dayId,
              startTime: "00:00:00",
              endTime: "00:00:00",
              checked: true,
              mappingId: Environment.defaultValue,
            },
          ],
        }),
      };
      // Update checkbox state for the added time
      const updatedCheckboxes = selectedWeekdays[orderTypeId].map((checkbox) =>
        checkbox.id === dayId ? { ...checkbox, checked: true } : checkbox
      );

      // Update unique weekdays state
      setUniqueWeekdays({
        ...selectedWeekdays,
        [orderTypeId]: updatedCheckboxes,
      });

      return updatedDayTimes;
    });
  };

  const handleRemoveTime = (orderTypeId, dayId, index) => {
    if (dayTimes[orderTypeId]?.[dayId]?.length > 1) {
      const newDayTimes = { ...dayTimes };
      newDayTimes[orderTypeId][dayId].splice(index, 1);
      setDayTimes(newDayTimes);
    }
  };
  const handleTimeChange = (orderTypeId, index, key, value, dayId) => {
    const newDayTimes = { ...(dayTimes || {}) };
    if (!newDayTimes[orderTypeId]) {
      newDayTimes[orderTypeId] = [];
    }
    if (!newDayTimes[orderTypeId][dayId]) {
      newDayTimes[orderTypeId][dayId] = [];
    }
    if (!newDayTimes[orderTypeId][dayId][index]) {
      newDayTimes[orderTypeId][dayId][index] = {}; // Assuming only one time slot per day for simplicity
    }
    // newDayTimes[orderTypeId][index][key] = value;
    newDayTimes[orderTypeId][dayId][index][key] = value;
    newDayTimes[orderTypeId][dayId][index].checked = true;
    setDayTimes(newDayTimes);
    // Update checkboxes state based on order type and weekday
    const updatedCheckboxes = selectedWeekdays[orderTypeId].map((checkbox) =>
      checkbox.id === dayId ? { ...checkbox, checked: true } : checkbox
    );
    setUniqueWeekdays({
      ...selectedWeekdays,
      [orderTypeId]: updatedCheckboxes,
    });
  };
  const handleSubmit = (data) => {
    let formObj = [];
    Object.entries(dayTimes[data.orderTypeId]).forEach(([day, slots]) => {
      const [slot] = slots; // Use the first slot or default to midnight
      if (slot.checked === true) {
        // Check if the slot exists and is checked
        // Map each slot and append to formObj
        formObj = [
          ...formObj,
          ...slots.map((slotData) => ({
            id: slotData.mappingId,
            branchId: props?.branch?.id,
            orderTypeId: data.id,
            day: parseInt(day), // Convert day to integer
            branchStartTime: /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/.test(
              slotData.startTime
            )
              ? slotData.startTime
              : slotData.startTime + ":00",
            branchCloseTime: /^(?:[01]\d|2[0-3]):(?:[0-5]\d):(?:[0-5]\d)$/.test(
              slotData.endTime
            )
              ? slotData.endTime
              : slotData.endTime + ":00",
            isStandardTimeSlots: data.isStandardTimeSlots,
            isNextDay: true,
          })),
        ];
      }
    });
    dispatch(UpdateOperatingHours(formObj));
  };

  const [isStandardTimeSlots, setIsStandardTimeSlots] = useState(true);
  const handleScheduleSelect = (event) => {
    const selectedValue = event.target.value;
    if (selectedValue == "Stadanrd") {
      setIsStandardTimeSlots(true);
    } else {
      setIsStandardTimeSlots(false);
    }
  };
  const handleCheckStandardHrs = (mappedData, data, value) => {
    console.log(value);
    const formObj = [
      {
        branchCloseTime: "00:00:00",
        branchStartTime: "00:00:00",
        id: mappedData?.id ? mappedData?.id : Environment.defaultValue,
        branchId: props?.branch?.id,
        orderTypeId: data.orderTypeId,
        day: 7,
        isStandardTimeSlots: value,
        isNextDay: false,
      },
    ];
    dispatch(UpdateOperatingHours(formObj));
  };

  return (
    <React.Fragment>
      <>
        {OperationHoursList?.map((orderType, index) => (
          <Card className="card-one weekly-schedule-collapse mb-2" key={index}>
            <Card.Body className="p-0">
              <Accordion>
                <Accordion.Item eventKey={index}>
                     
                  <Accordion.Header>{orderType.orderTypeName} </Accordion.Header>
                  <Accordion.Body>
                    <div>
                      <div className="mb-3 custom-checkbox">
                        <Row>
                          <Col xl={4}>
                            <Form.Select
                              value={
                                orderType.branchOperationTimes.find(
                                  (dayId) => dayId.day === 7
                                )?.isStandardTimeSlots ? "true" : "false"
                              }
                              onChange={(e) => {
                                const selectedValue = e.target.value === "true";

                                const dayId=orderType.branchOperationTimes.find(
                                    (dayId) => dayId.day === 7
                                  );
                                handleCheckStandardHrs(
                                  dayId,
                                  orderType,
                                  selectedValue
                                );
                              }}
                            >
                              <option value="" disabled>
                                Select weekly schedule
                              </option>
                              <option value="true">
                                Standard weekly operational hours
                              </option>
                              <option value="false">
                                Custom Weekly Schedule
                              </option>
                            </Form.Select>
                          </Col>
                        </Row>
                      </div>
                      <div
                        className={`${
                          orderType.branchOperationTimes.find(
                            (dayId) => dayId.day === 7
                          )?.isStandardTimeSlots ? "d-block" : "d-none"
                        }`}
                      >
                        <Row>
                          <Col xl={4}>
                            <Form.Label>
                              Operation Starts:{" "}
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="startTime"
                              value={formData.startTime}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  startTime: e.target.value,
                                })
                              }
                              placeholder="Enter Start Time"
                            />
                          </Col>
                          <Col xl={4}>
                            <Form.Label>
                              Ends: <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="closeTime"
                              value={formData.closeTime}
                              onChange={(e) =>
                                setFormData({
                                  ...formData,
                                  closeTime: e.target.value,
                                })
                              }
                              placeholder="Enter End Time"
                            />
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <hr />
                    
                    <div>
                      {! orderType.branchOperationTimes.find(
                                  (dayId) => dayId.day === 7
                                )?.isStandardTimeSlots && (
                        <>
                          {" "}
                          <h3 className="fw-semibold text-dark fs-14 mt-3">
                            Weekly Schedule
                          </h3>
                          <Button
                            variant="primary"
                            className="fs-14 m-2 d-flex align-orderTypes-center"
                            type="submit"
                            onClick={() => handleSubmit(orderType)}
                          >
                            <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                            <span className="align-middle">Save</span>
                          </Button>
                          {selectedWeekdays[orderType.orderTypeId]?.map(
                            (weekday) => (
                              <Row className="mt-3">
                                <Col xl={1} key={weekday.id}>
                                  <div className="weekly-days-checkbox">
                                    {/* <label>
                                      <input
                                        type="checkbox"
                                        checked={weekday.checked}
                                        onChange={() =>
                                          handleCheckboxChange(
                                            weekday.id,
                                            orderType.orderTypeId
                                          )
                                        }
                                      />
                                      <span className="checkbox-label text-uppercase fw-semibold fs-13">
                                        {weekday.name}
                                      </span>
                                    </label> */}

                                    <Form.Check
                                      type="switch"
                                      checked={weekday.checked}
                                      label={weekday.name}
                                      onChange={() =>
                                        handleCheckboxChange(
                                          weekday.id,
                                          orderType.orderTypeId
                                        )
                                      }
                                    />
                                  </div>
                                </Col>
                                <Col>
                                  {dayTimes[orderType.orderTypeId][
                                    weekday.id
                                  ]?.map((day, dayIndex) => (
                                    <Row key={dayIndex}>
                                      <Col xl={5}>
                                        <Form.Control
                                          type="time"
                                          step="300"
                                          format="HH:mm"
                                          placeholder="Start Time"
                                          value={day.startTime}
                                          onChange={(e) =>
                                            handleTimeChange(
                                              orderType.orderTypeId,
                                              dayIndex,
                                              "startTime",
                                              e.target.value,
                                              weekday.id
                                            )
                                          }
                                          className="mb-2"
                                        />
                                      </Col>
                                      <Col xl={5}>
                                        <Form.Control
                                          type="time"
                                          step="300"
                                          format="HH:mm"
                                          placeholder="End Time"
                                          value={day.endTime}
                                          onChange={(e) =>
                                            handleTimeChange(
                                              orderType.orderTypeId,
                                              dayIndex,
                                              "endTime",
                                              e.target.value,
                                              weekday.id
                                            )
                                          }
                                          className="mb-2"
                                        />
                                      </Col>
                                      <Col xl={2}>
                                        <Row>
                                          <Col className="d-flex align-items-center">
                                            {dayTimes[orderType.orderTypeId][
                                              weekday.id
                                            ].length > 1 && (
                                              <Button
                                                variant="danger"
                                                className="btn-icon me-2"
                                                onClick={() =>
                                                  handleRemoveTime(
                                                    orderType.orderTypeId,
                                                    weekday.id
                                                  )
                                                }
                                              >
                                                <i className="ri-close-line"></i>
                                              </Button>
                                            )}
                                            {dayIndex ===
                                              dayTimes[orderType.orderTypeId][
                                                weekday.id
                                              ].length -
                                                1 && (
                                              <Button
                                                variant="primary"
                                                className="btn-icon me-2"
                                                onClick={() =>
                                                  handleAddTime(
                                                    orderType.orderTypeId,
                                                    weekday.id
                                                  )
                                                }
                                              >
                                                <i className="ri-add-line"></i>
                                              </Button>
                                            )}
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  ))}
                                </Col>
                              </Row>
                            )
                          )}
                        </>
                      )}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        ))}
      </>
    </React.Fragment>
  );
}
