import React, { useContext, useEffect, useState } from "react";
import Prism from "prismjs";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { UpdateBasicInfo } from "../../../../application/actions/branchAction";
import AuthContext from "../../../../infrastructure/core/helpers/AuthContext";
import environment from "../../../../infrastructure/core/Environment";
import {
  getAllCurrencies,
  getTaxList,
} from "../../../../application/selectors/indexSelector";
import Environment from "../../../../infrastructure/core/Environment";
import { loadTax } from "../../../../application/actions/loaderAction";
import SearchWithoutPlaces from "../../../_common/without-search-place";
import Locations from "./../locations";

export default function BasicInfo(props) {
  useEffect(() => {
    Prism.highlightAll();
  }, []);
  const dispatch = useDispatch();
  const Currencies = useSelector(getAllCurrencies);

  const initialState = {
    id: `${environment.defaultValue}`,
    tenantId: 0,
    branchId: 0,
    status: `${environment.defaultValue}`,
    isVisible: true,
    isDeleted: false,
    createdBy: "",
    createdOn: new Date(),
    modifiedBy: "",
    modifiedOn: new Date(),
    branchRootId: `${environment.defaultValue}`,
    branchName: "",
    shortCode: "",
    type: "",
    timeZoneId: 0,
    currencyId: `${environment.defaultValue}`,
    merchantId: "",
    duplicateprint: "",
    branchEmail: "",
    branchNotifyEmail: "",
    addressline1: "",
    addressline2: "",
    zipCode: "",
    contactPersonName: "",
    contactPersonContactNumber: "",
    contactPersonEmail: "",
    city: "",
    state: "",
    country: "",
    latitude: `${environment.defaultValue}`,
    longitude: `${environment.defaultValue}`,
    mapCode: "",
    contactNo: "",
    isFacebookEnable: false,
    facebookLink: "",
    isInstaEnable: false,
    instaLink: "",
    isTwitterEnable: false,
    twitterLink: "",
    isSnapchatEnable: false,
    snapChatLink: "",
    isLinkedInEnable: false,
    linkedInLink: "",
    isGoogleMapEnable: false,
    googleMapLink: "",
    isPrinterestEnable: false,
    printerestLink: "",
    isWhatsAppEnable: false,
    whatsAppLink: "",
    isYoutubeEnable: false,
    youtubeLink: "",
    isGoogleReviewEnable: false,
    isGoogleReviewLink: "",
    isBestseller: false,
    isFeatured: false,
    isDiscountAllowed: false,
    isEbill: false,
    ebillType: `${environment.defaultValue}`,
    businessLicense: "",
    fireLicense: "",
    foodLicense: "",
    taxNumber: "NA",
    taxGroupId: Environment.defaultValue,
    googleMapAddess: "",
    googleBussinessProfileId: "",
  };
  const { user } = useContext(AuthContext);
  const [formData, setFormData] = useState(initialState);
  const [placeDetails, setPlaceDetails] = useState("");
  useEffect(() => {
    setFormData({
      id: props?.branch?.id,
      tenantId: props?.branch?.tenantId,
      branchId: props?.branch?.id,
      status: props?.branch?.status,
      isVisible: props?.branch?.isVisible,
      isDeleted: props?.branch?.isDeleted,
      createdBy: props?.branch?.createdBy,
      modifiedBy: user.id,
      branchRootId: `${environment.defaultValue}`,
      branchName: props?.branch?.branchName,
      shortCode: props?.branch?.shortCode,
      type: props?.branch?.type,
      timeZoneId: props?.branch?.timeZoneId,
      currencyId: props?.branch?.currencyId,
      merchantId: props?.branch?.merchantId,
      duplicateprint: props?.branch?.duplicateprint,
      branchEmail: props?.branch?.branchEmail,
      branchNotifyEmail: props?.branch?.branchNotifyEmail,
      addressline1: props?.branch?.addressline1,
      addressline2: props?.branch?.addressline2,
      zipCode: props?.branch?.zipCode,
      contactPersonName: props?.branch?.contactPersonName,
      contactPersonContactNumber: props?.branch?.contactPersonContactNumber,
      contactPersonEmail: props?.branch?.contactPersonEmail,
      city: props?.branch?.city,
      state: props?.branch?.state,
      country: props?.branch?.country,
      latitude: props?.branch?.latitude,
      longitude: props?.branch?.longitude,
      mapCode: props?.branch?.mapCode,
      contactNo: props?.branch?.contactNo,
      isFacebookEnable: props?.branch?.isFacebookEnable,
      facebookLink: props?.branch?.facebookLink,
      isInstaEnable: props?.branch?.isInstaEnable,
      instaLink: props?.branch?.instaLink,
      isTwitterEnable: props?.branch?.isTwitterEnable,
      twitterLink: props?.branch?.twitterLink,
      isSnapchatEnable: props?.branch?.isSnapchatEnable,
      snapChatLink: props?.branch?.snapChatLink,
      isLinkedInEnable: props?.branch?.isLinkedInEnable,
      linkedInLink: props?.branch?.linkedInLink,
      isGoogleMapEnable: props?.branch?.isGoogleMapEnable,
      googleMapLink: props?.branch?.googleMapLink,
      isPrinterestEnable: props?.branch?.isPrinterestEnable,
      printerestLink: props?.branch?.printerestLink,
      isWhatsAppEnable: props?.branch?.isWhatsAppEnable,
      whatsAppLink: props?.branch?.whatsAppLink,
      isYoutubeEnable: props?.branch?.isYoutubeEnable,
      youtubeLink: props?.branch?.youtubeLink,
      isGoogleReviewEnable: props?.branch?.isGoogleMapEnable,
      isGoogleReviewLink: props?.branch?.isGoogleReviewLink,
      isBestseller: props?.branch?.isBestseller,
      isFeatured: props?.branch?.isFeatured,
      isDiscountAllowed: props?.branch?.isDiscountAllowed,
      isEbill: props?.branch?.isEbill,
      ebillType: props?.branch?.ebillType,
      businessLicense: props?.branch?.businessLicense,
      fireLicense: props?.branch?.fireLicense,
      foodLicense: props?.branch?.foodLicense,
      closeTime: props?.branch?.closeTime,
      startTime: props?.branch?.startTime,
      taxNumber: props?.branch?.taxNumber,
      taxGroupId: props?.branch?.taxGroupId,
      googleBussinessProfileId: props?.branch?.googleBussinessProfileId,
      googleMapAddess: props?.branch?.googleMapAddess,
    });
    dispatch(loadTax);
  }, [props?.branch]);
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(UpdateBasicInfo(formData));
  };
  const taxList = useSelector(getTaxList);

  useEffect(() => {
    if (props?.branch) {
      setFormData({
        ...initialState,
        ...props.branch,
        modifiedBy: user.id,
        isGoogleReviewEnable: props?.branch?.isGoogleMapEnable,
      });
      dispatch(loadTax);
    }
  }, [props?.branch]);

  useEffect(() => {
    if (placeDetails) {
      const addressComponents = {
        addressline1: "",
        addressline2: "",
        city: "",
        state: "",
        country: "",
        zipCode: "",
      };

      placeDetails?.address_components.forEach((component) => {
        if (component.types.includes("street_number")) {
          addressComponents.addressline1 += component.long_name;
        }
        if (component.types.includes("route")) {
          addressComponents.addressline1 += ` ${component.long_name}`;
        }
        if (component.types.includes("subpremise")) {
          addressComponents.addressline2 = component.long_name;
        }
        if (component.types.includes("locality")) {
          addressComponents.city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          addressComponents.state = component.long_name;
        }
        if (component.types.includes("country")) {
          addressComponents.country = component.long_name;
        }
        if (component.types.includes("postal_code")) {
          addressComponents.zipCode = component.long_name;
        }
      });

      setFormData({
        ...formData,
        ...addressComponents,
        googleMapAddess: placeDetails,
        googleBussinessProfileId: placeDetails?.place_id,
      });
    }
  }, [placeDetails]);
  console.log(formData);

  return (
    <React.Fragment>
      <div className="d-md-flex align-items-center justify-content-between mb-4">
        <div>
          <h4 className="main-title fs-16 mb-0">Basic Info</h4>
        </div>
      </div>

      <Row>
        <Col xl="3">
          <div className="mb-4">
            <Form.Label>
              FID: <sup className="text-danger">*</sup>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              name="shortCode"
              value={formData.shortCode}
              onChange={(e) =>
                setFormData({ ...formData, shortCode: e.target.value })
              }
              placeholder="Enter Location Code"
              disabled
            />
          </div>
        </Col>
        <Col xl="2">
          <div className="mb-4">
            <Form.Label>
              Status: <sup className="text-danger">*</sup>{" "}
            </Form.Label>
            <Form.Control
              type="text"
              name="shortCode"
              value="ACTIVE"
              placeholder=""
              disabled
            />
          </div>
        </Col>
      </Row>

      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Location Name: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="branchName"
              value={formData.branchName}
              onChange={(e) =>
                setFormData({ ...formData, branchName: e.target.value })
              }
              placeholder="Enter Location Code"
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Google Map Location: <sup className="text-danger">*</sup>
            </Form.Label>
            <div className="map-hidden-class">
              <SearchWithoutPlaces placeDetails={setPlaceDetails} />
            </div>
          </div>
        </Col>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Google Business Profile ID: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="googleBussinessProfileId"
              placeholder="Enter Google Business Profile ID"
              value={formData.googleBussinessProfileId}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  googleBussinessProfileId: e.target.value,
                })
              }
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xl="3">
          <div className="mb-4">
            <Form.Label>
              Currency: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Select
              name="currencyId"
              value={formData.currencyId}
              onChange={(e) =>
                setFormData({ ...formData, currencyId: e.target.value })
              }
            >
              <option value={Environment.defaultValue}>Choose Currency</option>
              {Currencies?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.currancyCode} - {option.currancySymbol}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
      </Row>
      <div className="divider divider-start table-section-header">
        <span className="fs-14 fw-semibold text-dark">Tax Detail</span>
      </div>

      <Row>
        <Col xl="4">
          <div className="mb-4">
            <Form.Label>
              Tax Group: <sup className="text-danger">*</sup>{" "}
            </Form.Label>
            <Form.Select
              name="taxGroupId"
              value={formData.taxGroupId}
              onChange={(e) =>
                setFormData({ ...formData, taxGroupId: e.target.value })
              }
            >
              <option value={Environment.defaultValue}>Choose Tax</option>
              {taxList?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.taxGroupName}
                </option>
              ))}
            </Form.Select>
          </div>
        </Col>
        <Col xl="4">
          <div className="mb-4">
            <Form.Label>Tax Number (GST / VAT Number)</Form.Label>
            <Form.Control
              type="text"
              name="taxNumber"
              value={formData.taxNumber}
              onChange={(e) =>
                setFormData({ ...formData, taxNumber: e.target.value })
              }
              placeholder="Enter Location Code"
            />
          </div>
        </Col>
      </Row>

      <div className="divider divider-start table-section-header">
        <span className="fs-14 fw-semibold text-dark">
          Business Location License Detail
        </span>
      </div>
      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>Business License Number</Form.Label>
            <Form.Control
              type="text"
              name="businessLicense"
              value={formData.businessLicense}
              onChange={(e) =>
                setFormData({ ...formData, businessLicense: e.target.value })
              }
              placeholder="Enter Location Code"
            />
          </div>
        </Col>

        <Col xl="6">
          <div className="mb-4">
            <Form.Label>Food License Number</Form.Label>
            <Form.Control
              type="text"
              name="foodLicense"
              value={formData.foodLicense}
              onChange={(e) =>
                setFormData({ ...formData, foodLicense: e.target.value })
              }
              placeholder="Enter Location Code"
            />
          </div>
        </Col>

        <Col xl="6">
          <div className="mb-4">
            <Form.Label>Fire License Number</Form.Label>
            <Form.Control
              type="text"
              name="fireLicense"
              value={formData.fireLicense}
              onChange={(e) =>
                setFormData({ ...formData, fireLicense: e.target.value })
              }
              placeholder="Enter Location Code"
            />
          </div>
        </Col>
      </Row>

      <div className="divider divider-start table-section-header">
        <span className="fs-14 fw-semibold text-dark">
          Business Mailing Address
        </span>
      </div>

      <Row>
        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Address Line 1: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="addressline1"
              value={formData.addressline1}
              onChange={(e) =>
                setFormData({ ...formData, addressline1: e.target.value })
              }
              placeholder="Enter Address Line 1"
            />
          </div>
        </Col>

        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Address Line 2: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="addressline2"
              value={formData.addressline2}
              onChange={(e) =>
                setFormData({ ...formData, addressline2: e.target.value })
              }
              placeholder="Enter Address Line 2"
            />
          </div>
        </Col>

        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              City: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="city"
              value={formData.city}
              onChange={(e) =>
                setFormData({ ...formData, city: e.target.value })
              }
              placeholder="Enter City"
            />
          </div>
        </Col>

        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              State: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="state"
              value={formData.state}
              onChange={(e) =>
                setFormData({ ...formData, state: e.target.value })
              }
              placeholder="Enter State"
            />
          </div>
        </Col>

        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Country: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="country"
              value={formData.country}
              onChange={(e) =>
                setFormData({ ...formData, country: e.target.value })
              }
              placeholder="Enter Country"
            />
          </div>
        </Col>

        <Col xl="6">
          <div className="mb-4">
            <Form.Label>
              Zip Code: <sup className="text-danger">*</sup>
            </Form.Label>
            <Form.Control
              type="text"
              name="zipCode"
              value={formData.zipCode}
              onChange={(e) =>
                setFormData({ ...formData, zipCode: e.target.value })
              }
              placeholder="Enter Zip Code"
            />
          </div>
        </Col>
      </Row>

      <div className="offcanvas-footer justify-content-start position-absolute bg-white">
        <Button
          variant="primary"
          className="fs-14 me-2 d-flex align-items-center"
          type="submit"
          onClick={handleSubmit}
        >
          <i className="ri-add-line fs-18 lh-1 align-middle"></i>
          <span className="align-middle">Save</span>
        </Button>
      </div>
    </React.Fragment>
  );
}
