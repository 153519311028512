import { LOAD_SUCCESS } from "../actions/loaderAction";
import {
  CATEGORY_SUCCESS,
  HMORDER_SUCCESS,
  HOLD_ORDER_SUCCESS,
  LIVE_TABLE_SUCCESS,
  PRE_ORDER_SUCCESS,
  PROD_CATEGORY_SUCCESS,
  SUB_CATEGORY_SUCCESS,
  TKYORDER_SUCCESS,
} from "../actions/saleActions";

const initialState = {
  TableData: [],
  TKYOrderData: [],
  HMOrderData: [],
  PreOrderData: [],
  HoldOrderData: [],
  categoryData: [],
  subCategoryData: [],
  ProdByCategoryData: [],
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_SUCCESS:
      return { allTodos: action.payload, error: null };
    case CATEGORY_SUCCESS:
      return {
        categoryData: action.payload.data.categoryData.result,
        CartData: action.payload.data.CartData,
        error: null,
      };
    case SUB_CATEGORY_SUCCESS:
      return {
        subCategoryData: action.payload.data.subCategoryData,
        ProdByCategoryData: action.payload.data.ProdByCategoryData,
        CartData: action.payload.data.CartData,
        error: null,
      };
    case PROD_CATEGORY_SUCCESS:
      return {
        subCategoryData: action.payload.data.subCategoryData,
        ProdByCategoryData: action.payload.data.ProdByCategoryData,
        error: null,
      };
    case LIVE_TABLE_SUCCESS:
      return {
        TableData: action.payload.TableData,
        TKYOrderData: action.payload?.TKYOrderData,
        HMOrderData: action.payload?.HMOrderData,
        PreOrderData: action.payload?.PreOrderData,
        HoldOrderData: action.payload?.HoldOrderData,
        error: null,
      };
    case TKYORDER_SUCCESS:
      return { TKYOrderData: action.payload.TKYOrderData, error: null };
    case HMORDER_SUCCESS:
      return { HMOrderData: action.payload.data.HMOrderData, error: null };
    case PRE_ORDER_SUCCESS:
      return { PreOrderData: action.payload.data.PreOrderData, error: null };
    case HOLD_ORDER_SUCCESS:
      return { HoldOrderData: action.payload.data.HoldOrderData, error: null };
    default:
      return state;
  }
};

export default reducer;
