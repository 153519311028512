import {
  CREATE_KITCHEN,
  DELETE_KITCHEN,
  GET_KITCHEN_BY_ID_SUCCESS,
  GET_KITCHEN_LIST,
  GET_KITCHEN_LIST_BY_TENANT_SUCCESS,
  GET_KITCHEN_LIST_SUCCESS,
  UPDATE_KITCHEN,
} from "../actions/kitchenAction";
const initialState = {
  KitchenList: undefined,
  KitchenListByTenant: undefined,
  KitchenDetails:{},
  loading: false,
  error: null,

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_KITCHEN_LIST:
      return { ...state, loading: true, error: null };
    case GET_KITCHEN_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        KitchenList: action.payload.data.result,
        error: null,
      };
    case GET_KITCHEN_LIST_BY_TENANT_SUCCESS:
      return {
        ...state,
        loading: false,
        KitchenListByTenant: action.payload.data.result,
        error: null,
      };
    case CREATE_KITCHEN:
      return { ...state, loading: true, error: null };
    case UPDATE_KITCHEN:
      return { ...state, loading: true, error: null };
    case DELETE_KITCHEN:
      return { ...state, loading: true, error: null };
    case GET_KITCHEN_BY_ID_SUCCESS:
      return { ...state, KitchenDetails: action.payload.data.result, error: null };
    default:
      return state;
  }
};

export default reducer;