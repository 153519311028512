import React, { useContext, useEffect, useState } from "react";
import { Card, Row, Col, Nav, Table, ProgressBar } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { useDispatch, useSelector } from "react-redux";
import { getBranchList, getDashBoardAnalytics, getLoading } from "./../../../application/selectors/indexSelector";
import Select from "react-select";
import { DashBoardAnalytics } from "../../../application/actions/salesOrdersAction";
import AuthContext from "../../../infrastructure/core/helpers/AuthContext";

export default function SaleDashboard() {
  const { selectedBranchData } = useContext(AuthContext);
  const parsedBranchData = JSON.parse(selectedBranchData);
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const branch = JSON.parse(localStorage.getItem("branch"));
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };

  switchSkin(skin);
  const dispatch = useDispatch();
  useEffect(() => {
    switchSkin(skin);
    dispatch(DashBoardAnalytics(branch.value));
  }, [skin]);

  const DashBoardAnalyticsData = useSelector(getDashBoardAnalytics);
  const { loading, value } = useSelector(getLoading);

  const states = {
    hover: {
      filter: {
        type: "none",
      },
    },
    active: {
      filter: {
        type: "none",
      },
    },
  };

  const [todayshrsReports, setTodayshrsReports] = useState([]);
  const orderHourlyStatastics = DashBoardAnalyticsData?.orderHourlyStatastics;
  useEffect(() => {
    if (orderHourlyStatastics && orderHourlyStatastics?.length > 0) {
      // Prepare data for the chart
      const chartData = {
        name: "Order Count",
        data: orderHourlyStatastics?.map((item) => item.orderCount),
      };

      // Set the chart data in the state
      setTodayshrsReports([chartData]);
    } else {
      console.log("No data available");
    }
  }, [orderHourlyStatastics]);

  const optionFour = {
    chart: {
      parentHeightOffset: 0,
      stacked: true,
      toolbar: { show: false },
    },
    grid: {
      borderColor: "rgba(72,94,144, 0.07)",
      padding: {
        top: -20,
        left: 5,
      },
    },
    states: states,
    colors: ["#506fd9"],
    plotOptions: {
      bar: {
        columnWidth: "35%",
        horizontal: false,
        // Add markers to each bar
        // dataLabels: {
        //   position: 'top',
        //   hideOnHover: false // Show data labels on hover
        // },
      },
    },
    stroke: {
      curve: "straight",
      lineCap: "square",
      width: 0,
    },
    xaxis: {
      labels: {
        show: true,
        style: {
          fontSize: "12px",
        },
      },
      type: "numeric",
      tickAmount: 24,
      decimalsInFloat: 0,
    },
    yaxis: {
      max: 12,
      tickAmount: 12,
      labels: {
        text: "Order Count",
        style: {
          colors: ["#a2abb5"],
          fontSize: "12px",
        },
      },
    },
    tooltip: { enabled: false },
    fill: { opacity: 1 },
    legend: { show: false },
    noData: {
      text:
        DashBoardAnalyticsData === undefined
          ? "Loading..."
          : "No Data present in the graph!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#000000",
        fontSize: "14px",
        fontFamily: "Helvetica",
      },
    },

    markers: {
      size: 0 // Marker shape (circle, square, etc.)
    }
  };
  const BranchList = useSelector(getBranchList);
  const [selectedOptions, setSelectedOptions] = useState(
    JSON.parse(localStorage.getItem("branch"))
  );
  const BranchType = BranchList?.map((option) => ({
    value: option.id,
    label: option.branchName,
  }));
  const handleSelectBranch = (selectedOption) => {
    setSelectedOptions(selectedOption.value);
  };
  return (
    <React.Fragment>
      <div className="wt-300 mb-3">
        <div className="form-search me-auto p-0">
          <Select
            name="branchId"
            placeholder="Choose Branch"
            options={BranchType}
            onChange={handleSelectBranch}
            value={BranchType?.find(
              (option) => option.value === selectedOptions.value
            )}
            className="p-0 w-100"
            id="choose_branch"
          />
        </div>
      </div>

      <Row className="g-3 mb-4">
        {[
          {
            label: "Todays Sales",
            icon: "ri-shopping-bag-3-line",
            value: DashBoardAnalyticsData?.todaysSales,
            percent: DashBoardAnalyticsData?.todaySalesUpPercentage,
            status: "down",
            des: "Than yesterday",
          },
          {
            label: "Ongoing Orders",
            icon: "ri-restart-line",
            value: DashBoardAnalyticsData?.onGoingOrderValue,
            percent: DashBoardAnalyticsData?.onGoingPendingOrderCount,
            status: "up",
            des: "Orders currently pending",
          },
          {
            label: "Completed Orders",
            icon: "ri-leaf-line",
            value: DashBoardAnalyticsData?.todaysCompletedOrderCount,
            percent: DashBoardAnalyticsData?.todaysCompletedOrderUpPercentage,
            status: "down",
            des: "Than yesterday",
          },
          {
            label: "Cancelled Orders",
            icon: "ri-arrow-down-line",
            value: DashBoardAnalyticsData?.todaysCancelledOrderCount,
            percent: DashBoardAnalyticsData?.todaysCancelledOrderUpPercentage,
            status: "up",
            des: "Higher than yesterday",
          },
        ].map((card, index) => (
          <Col xs="6" xl="3" key={index}>
            <Card className="card-one">
              <Card.Body>
                <Card.Title as="label" className="fs-sm fw-medium mb-1 text-dark">
                  {card.label}
                </Card.Title>
                <h3
                  className={`card-value mb-1 ${card.label === "Pending Approval" ? "text-danger" : ""
                    }`}
                >
                  <i
                    className={`${card.icon} ${card.label === "Pending Approval" ? "text-danger" : ""
                      }${card.label === "Total Veggan Items" ? "text-success" : ""
                      }`}
                  ></i>{" "}
                  {parsedBranchData?.currencySymbol} {card.value}
                </h3>
                <small className="text-dark">
                  <span
                    className={
                      "d-inline-flex text-" +
                      (card.status === "up" ? "success" : "danger")
                    }
                  >
                    {card.percent}{" "}
                    <i
                      className={
                        "ri-arrow-" +
                        (card.status === "up" ? "up" : "down") +
                        "-line"
                      }
                    ></i>
                  </span>{" "}
                  {card.des}
                </small>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <Row className="g-3">
        <Col md="5" xl="4">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Sales Breakup Report</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto">
                <Nav.Link href="">
                  <i className="ri-refresh-line"></i>
                </Nav.Link>
                <Nav.Link href="">
                  <i className="ri-more-2-fill"></i>
                </Nav.Link>
              </Nav>
            </Card.Header>
            <Card.Body className="p-3">
              <h2 className="performance-value mb-0">
                {DashBoardAnalyticsData?.salesBreackupReport}
                <small className="text-success d-flex align-items-center">
                  <i className="ri-arrow-up-line"></i> 2.2%
                </small>
              </h2>

              <label className="card-title fs-sm fw-medium text-dark">Total Sales</label>

              <ProgressBar className="progress-one ht-8 mt-2 mb-4">
                <ProgressBar now={50} />
                <ProgressBar now={25} variant="success" />
                <ProgressBar now={5} variant="orange" />
                <ProgressBar now={5} variant="pink" />
                <ProgressBar now={10} variant="info" />
                <ProgressBar now={5} variant="indigo" />
              </ProgressBar>

              <Table className="table-three">
                <tbody>
                  {[
                    {
                      dot: "primary",
                      label: "Cash",
                      count: "3,007",
                      percent: "50",
                    },
                    {
                      dot: "success",
                      label: "UPI - Wallet",
                      count: "1,674",
                      percent: "25",
                    },
                    {
                      dot: "orange",
                      label: "Card",
                      count: "125",
                      percent: "6",
                    },
                    {
                      dot: "pink",
                      label: "Creadit",
                      count: "98",
                      percent: "5",
                    },
                    {
                      dot: "info",
                      label: "Free of Cost",
                      count: "512",
                      percent: "10",
                    },
                  ].map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className={"badge-dot bg-" + item.dot}></div>
                      </td>
                      <td>{item.label}</td>
                      <td>{item.count}</td>
                      <td>{item.percent}%</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col md="7" xl="8">
          <Card className="card-one">
            <Card.Header>
              <Card.Title as="h6">Todays Hourly Sales Report</Card.Title>
              <Nav className="nav-icon nav-icon-sm ms-auto">
                <Nav.Link href="">
                  <i className="ri-refresh-line"></i>
                </Nav.Link>
                <Nav.Link href="">
                  <i className="ri-more-2-fill"></i>
                </Nav.Link>
              </Nav>
            </Card.Header>
            <Card.Body>
              <ReactApexChart
                series={todayshrsReports}
                options={optionFour}
                type="bar"
                height={300}
                className="apex-chart-nine"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}
