import { GET_TEMPLATE_BY_TENANTID_SUCCESS } from "../actions/printTemplate";
const initialState = {
  TemplateByTenantId: undefined,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TEMPLATE_BY_TENANTID_SUCCESS:
      return {
        ...state,
        TemplateByTenantId: action.payload.result,
        error: null,
      };
    default:
      return state;
  }
};

export default reducer;
