import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import { Offcanvas, Tabs, Tab } from "react-bootstrap";
import PaymentLogAdvancePayment from "./paymentLog/advance-payment";
import PaymentLogSalaryPayment from "./paymentLog/salary-payment";

export default function PaymentLog(props) {
    useEffect(() => {
        Prism.highlightAll();
    }, []);

    const [tabAttendanceReport, setTabAttendanceReport] = useState('advance_payment_tab');
    const handleSalesSelect = (tab) => {
        setTabAttendanceReport(tab);
    };

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-45">
                <Offcanvas.Header className="custom-offcanvas-header" closeButton>
                    <Offcanvas.Title>Payment Log</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Tabs id="custom-styled-tabs" activeKey={tabAttendanceReport} onSelect={handleSalesSelect} className="sale-on-point-tabs">
                        <Tab eventKey="advance_payment_tab" title={<>Advance Payments</>} tabClassName="custom-tab-header fw-semibold">
                            <div className="custom-tab-body mt-3">
                                <PaymentLogAdvancePayment />
                            </div>
                        </Tab>

                        <Tab eventKey="salary_payment_tab" title={<>Salary Payments</>} tabClassName="custom-tab-header fw-semibold">
                            <div className="custom-tab-body mt-3">
                                <PaymentLogSalaryPayment />
                            </div>
                        </Tab>
                    </Tabs>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}