import React, { useCallback, useEffect, useState } from "react";
import Header from "../../../layouts/Header";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Row, Col, Tooltip, OverlayTrigger, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogId, getCatalogueCodes, getItemCatalogPaginationData, getLoading } from "../../../application/selectors/indexSelector";
import { CatalogueItemByIdSuccess, GetItemCatalogPagination } from "../../../application/actions/ItemAction";
import AddNewCatalogue from "./add-catalogue";
import CardShimmerLoader from "../../../layouts/ShimmerCard";
import { debounce } from "lodash";
import Environment from "../../../infrastructure/core/Environment";

export default function ItemCatalogue() {
  const CatalogId = useSelector(getCatalogId);
  const navigate = useNavigate();
  const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
  const [skin, setSkin] = useState(currentSkin);
  const switchSkin = (skin) => {
    if (skin === "dark") {
      const btnWhite = document.getElementsByClassName("btn-white");

      for (const btn of btnWhite) {
        btn.classList.add("btn-outline-primary");
        btn.classList.remove("btn-white");
      }
    } else {
      const btnOutlinePrimary = document.getElementsByClassName(
        "btn-outline-primary"
      );

      for (const btn of btnOutlinePrimary) {
        btn.classList.remove("btn-outline-primary");
        btn.classList.add("btn-white");
      }
    }
  };
  switchSkin(skin);
  const dispatch = useDispatch();
  useEffect(() => {
    switchSkin(skin);
  }, [skin]);
  const ItemCatalogData = useSelector(getItemCatalogPaginationData);
  const CatalogueCodes = useSelector(getCatalogueCodes);
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [fetchSize, setFetchSize] = useState(calculateInitialFetchSize());
  const [hasMore, setHasMore] = useState(false);
  function calculateInitialFetchSize() {
    // Example: Set initial fetch size based on the visible height of the window
    const windowHeight = window.innerHeight;
    // Adjust fetch size based on your specific requirements and UI
    return Math.ceil(windowHeight / 50); // Adjust '50' based on item height in pixels
  }
  let pendingList = [];
  for (let i = 0; i < ItemCatalogData?.result?.length; i++) {
    if (ItemCatalogData?.result[i].status === "73649d84-d590-40b9-883971-28b0acacd3") {
      pendingList.push(ItemCatalogData?.result[i]);
    }
  }
  let vegganList = [];
  for (let i = 0; i < ItemCatalogData?.result?.length; i++) {
    if (
      ItemCatalogData?.result[i].foodType === "d4ce1e35-7ebb-4b29-be69-7c20c216741a"
    ) {
      vegganList.push(ItemCatalogData?.result[i]);
    }
  }
  let nonVegList = [];
  for (let i = 0; i < ItemCatalogData?.result?.length; i++) {
    if (
      ItemCatalogData?.result[i].foodType === "a634316f-857d-41fd-a74c-895f61e4de65"
    ) {
      nonVegList.push(ItemCatalogData?.result[i]);
    }
  }
  const { loading, value } = useSelector(getLoading);
  useEffect(() => {
    const data = {
      pageNumber: page, // Use current page number
      pageSize: fetchSize,
      orderBy: "Sequence",
      direction: "asc",
    };
    dispatch(GetItemCatalogPagination(data));
    dispatch(CatalogueItemByIdSuccess(undefined));
  }, []);

  const [showAddCatalogue, setShowAddCatalogue] = useState(false);
  const handleAddCatalogue = (state) => {
    setShowAddCatalogue(state);
  };

  useEffect(() => {
    if (CatalogId !== undefined) {
      navigate(`/item-catalogue/details/${CatalogId}`);
    }
  }, [CatalogId]);

  const [branchData, setBranchData] = useState(Environment.defaultValue);
  const handleBranchIdFromHeader = debounce((data) => {
    setBranchData(data);
  }, 300);
  const fetchItems = () => {
    // Update the items state based on whether it's the initial load or subsequent loads
    if (ItemCatalogData?.result) {
      if (page === 1) {
        setItems(ItemCatalogData?.result);
      } else {
        // Filter out items that are already in the items array
        const filteredNewItems = ItemCatalogData?.result?.filter((newItem) => {
          return !items.some((item) => item.id === newItem.id);
        });

        // Update the items state by concatenating filteredNewItems with prevItems
        setItems((prevItems) => [...prevItems, ...filteredNewItems]);
      }

      // Check if there are no more items to load
      setHasMore(ItemCatalogData?.result?.length >= fetchSize);
    }
  };

  const handleScroll = useCallback(() => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 20 && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page number
      const data = {
        pageNumber: page + 1, // Use current page number
        pageSize: fetchSize,
        orderBy: "Sequence",
        direction: "asc",
      };
      dispatch(GetItemCatalogPagination(data));
    }
  }, [hasMore, setPage]);



  useEffect(() => {
    // Fetch initial data on component mount
    fetchItems();

    // Add scroll event listener for infinite scrolling
    window.addEventListener("scroll", handleScroll);

    return () => {
      // Clean up scroll event listener on component unmount
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]); // Include handleScroll in dependency array


  useEffect(() => {
    // Fetch more items when page changes
    if (page > 1) {
      fetchItems();
    }
  }, [page]); // Re-run effect when page changes
  useEffect(() => {
    fetchItems()
  }, [ItemCatalogData?.result])

  const [searchTerm, setSearchTerm] = useState('');
  const filteredEmployees = items?.filter((item) => Object.values(item).some((value) => value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())));

  return (
    <React.Fragment>
      <Header passDataToPointOfSale={handleBranchIdFromHeader} />
      <div className="main main-app p-3 p-lg-4">
        <AddNewCatalogue
          show={showAddCatalogue}
          CatalogueCodes={CatalogueCodes}
          closeFunction={handleAddCatalogue}
        />
        <Row className="mb-4 align-items-center">
          <Col>
            <div>
              <h4 className="main-title mb-0">Item Catalogue</h4>
            </div>
          </Col>
          <Col>
            <div className="d-flex justify-content-end gap-2 mt-3 mt-md-0">
              <Button
                variant="primary"
                className="d-flex align-items-center gap-2"
                onClick={() => handleAddCatalogue(true)}
              >
                <i className="ri-add-line fs-18 lh-1"></i>
                <span className="d-sm-inline"> Create New Item</span>
              </Button>
            </div>
          </Col>
        </Row>
        {items && items !== undefined && (
          <>
            <Row className="g-3">
              {[
                {
                  label: "Total Items",
                  icon: "ri-shopping-bag-3-line",
                  value: ItemCatalogData?.totalCount,
                  // "percent": "0.7",
                  // "status": "down",
                  // 'des': '0 Items added this week'
                },
                {
                  label: "Draft",
                  icon: "ri-restart-line",
                  value: ItemCatalogData?.totalDraftCount,
                  // "percent": "2.1",
                  // "status": "up",
                  // 'des': 'Items not listed for sales'
                },
                {
                  label: "Total Veggan Items",
                  icon: "ri-leaf-line",
                  value: ItemCatalogData?.totalVeggenCount,
                  // "percent": "0.3",
                  // "status": "down",
                  // 'des': 'Vegetarian Items in menu'
                },
                {
                  label: "Total Non-Veg Items",
                  icon: "ri-arrow-down-line",
                  value: ItemCatalogData?.totalNonVegCount,
                  // "percent": "1.2",
                  // "status": "up",
                  // 'des': 'Non Vegetarian Items in menu'
                },
              ].map((card, index) => (
                <Col xs="6" xl="3" key={index}>
                  <Card className="card-one">
                    <Card.Body>
                      <Card.Title as="label" className="fs-sm fw-medium mb-1">
                        {card.label}
                      </Card.Title>
                      <h3
                        className={`card-value mb-1 ${card.label === "Pending Approval" ? "text-danger" : ""
                          }`}
                      >
                        <i
                          className={`${card.icon} ${card.label === "Pending Approval" ? "text-danger" : ""
                            }${card.label === "Total Veggan Items"
                              ? "text-success"
                              : ""
                            }`}
                        ></i>{" "}
                        {card.value}
                      </h3>
                      {/* <small><span className={"d-inline-flex text-" + ((card.status === "up") ? "success" : "danger")}>{card.percent}% <i className={"ri-arrow-" + ((card.status === "up") ? "up" : "down") + "-line"}></i></span> {card.des}</small> */}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>

            <Row className="mb-4 mt-4 align-items-center">
              <Col xs={5} xl={6}>
                <div>
                  <h4 className="main-title fs-16 mb-0">List of Items</h4>
                </div>
              </Col>
              <Col xs={7} xl={6}>
                <div className="custom-dropdown-wrapper justify-content-end d-flex">
                  <div className="custom-drop-down z-index-2 wt-300">
                    <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                    <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="g-3 pb-4">
              {filteredEmployees?.map((item, index) => (
                <>
                  {!item.isDeleted && (
                    <Col xs={12} md={6} xl={4} key={index}>
                      <Card
                        className="card-one dishes-card"
                        onClick={() =>
                          navigate(`/item-catalogue/details/${item.id}`)
                        }
                      >
                        <Card.Body>
                          <Row className="align-items-center h-100">
                            <Col xs={8} className="pe-0">
                              <span
                                className={`badge badge-pill top-badge ${item.status ===
                                  "9251e44c-dda2-40d9-8d96-b392d974331b"
                                  ? "bg-success"
                                  : ""
                                  } ${item.status !==
                                    "9251e44c-dda2-40d9-8d96-b392d974331b"
                                    ? "bg-danger"
                                    : ""
                                  }`}
                              >
                                {item.status ===
                                  "9251e44c-dda2-40d9-8d96-b392d974331b"
                                  ? "Published"
                                  : "Draft"}
                              </span>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id={`tooltip-top`}>
                                    {item.catalogueName}
                                  </Tooltip>
                                }
                              >
                                <Link
                                  to={`/item-catalogue/details/${item.id}`}
                                  className="dish-title three-line-ellipsis"
                                >
                                  {item.catalogueName}
                                </Link>
                              </OverlayTrigger>
                              <h5 className="fs-sm fw-medium mb-1">{item.des}</h5>
                            </Col>
                            <Col xs={4}>
                              <div className="dish-img">
                                <img
                                  src={`https://freshlo.oss-ap-south-1.aliyuncs.com/FE/catalogueThumbnail/${item.id
                                    }/${item.id}.png?version=${Math.floor(
                                      Math.random() * 1000000
                                    )}}`}
                                  onError={(e) => {
                                    e.target.src =
                                      "https://freshlo.oss-ap-south-1.aliyuncs.com/FudEasy/no-image%20(2).png";
                                  }}
                                  className="img-fluid"
                                  alt="thumbnail"
                                />
                              </div>
                            </Col>
                          </Row>
                        </Card.Body>
                      </Card>
                    </Col>
                  )}{" "}
                </>
              ))}
            </Row>
          </>
        )}
        {loading && value === "itemList" && filteredEmployees?.result === undefined && (
          <CardShimmerLoader columnCount={4} rowCount={4} />
        )}
        {hasMore && (
          <CardShimmerLoader columnCount={4} rowCount={4} />
        )}

      </div>
    </React.Fragment>
  );
}
