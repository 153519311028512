export const GET_TAX_LIST = "GET_TAX_LIST";
export const GET_TAX_LIST_SUCCESS = "GET_TAX_LIST_SUCCESS";
export const ADD_TAX = "ADD_TAX";
export const UPDATE_TAX = "UPDATE_TAX";
export const COMMON_FILED = "COMMON_FILED";
export const COMMON_FILED_SUCCESS = "COMMON_FILED_SUCCESS";
export const COMMON_TAX_CODE_SUCCESS = "COMMON_TAX_CODE_SUCCESS";
export const COMMON_TAX_TYPE_SUCCESS = "COMMON_TAX_TYPE_SUCCESS";
export const COMMON_APPLIED_SUCCESS = "COMMON_APPLIED_SUCCESS";
export const COMMON_APPLIED_TO_SUCCESS = "COMMON_APPLIED_TO_SUCCESS";
export const DELETE_TAX = "DELETE_TAX";
export const GET_TAX_LIST_BY_TENANT = "GET_TAX_LIST_BY_TENANT";
export const GET_TAX_LIST_BY_TENANT_SUCCESS = "GET_TAX_LIST_BY_TENANT_SUCCESS";
export const MAP_TAX_ORDERTYPE = "MAP_TAX_ORDERTYPE";
export const UNMAP_TAX_ORDERTYPE = "UNMAP_TAX_ORDERTYPE";
export const MAP_TAX_WITH_BRANCH = "MAP_TAX_WITH_BRANCH";
export const UNMAP_TAX_WITH_BRANCH = "UNMAP_TAX_WITH_BRANCH";
export const TAX_DETAILS = "TAX_DETAILS";
export const TAX_DETAILS_SUCCESS = "TAX_DETAILS_SUCCESS";

export const GetTaxList ={
  type: GET_TAX_LIST,
}
export const GetTaxListByTenant = (data) => ({
  type: GET_TAX_LIST_BY_TENANT,
  payload: data,
});
export const GetTaxListSuccess = (data) => ({
  type: GET_TAX_LIST_SUCCESS,
  payload: { data },
});
export const GetTaxListByTenantSuccess = (data) => ({
  type: GET_TAX_LIST_BY_TENANT_SUCCESS,
  payload: data,
});
export const AddTax = (data) => ({
  type: ADD_TAX,
  payload: { data },
});
export const UpdateTax = (data) => ({
  type: UPDATE_TAX,
  payload: { data },
});
export const MapTaxOrdertype = (data) => ({
  type: MAP_TAX_ORDERTYPE,
  payload: data,
});
export const UnMapTaxOrdertype = (id) => ({
  type: UNMAP_TAX_ORDERTYPE,
  payload: id,
});
export const MapTaxWithBranch = (data) => ({
  type: MAP_TAX_WITH_BRANCH,
  payload: data ,
});
export const UnMapTaxWithBranch = (data) => ({
  type: UNMAP_TAX_WITH_BRANCH,
  payload: data,
});
export const getTaxDetail = (id) => ({
  type: TAX_DETAILS,
  payload: id,
});
export const GetTaxDetailsSuccess = (data) => ({
  type:TAX_DETAILS_SUCCESS,
  payload: data,
});