import React from "react";

// Dashboard
import EventManagement from "../dashboard/EventManagement";
import SalesMonitoring from "../dashboard/SalesMonitoring";
import WebsiteAnalytics from "../dashboard/WebsiteAnalytics";
import FinanceMonitoring from "../dashboard/FinanceMonitoring";
// import Cryptocurrency from "../dashboard/Cryptocurrency";
import HelpdeskService from "../dashboard/HelpdeskService";
import StorageManagement from "../dashboard/StorageManagement";
import ProductManagement from "../dashboard/ProductManagement";

// ROUTING PAHE > AAMIR SAYYED
import Dashboard from "../View/Dashboard/dashboard"
import FinancialDashboard from "../View/Dashboard/financial-dashboard";

import SaleDashboard from "../View/Orders/Dashboard/dashboard";
import ReportAnalytics from "../View/Orders/ReportAnalytics/report";
import Customers from "../View/Customers/customers";
import Employees from "../View/Employees/employees";
import Attendance from "../View/Attendance/attendance";
import SalaryAdvance from "../View/SalaryAdvance/salary-advance";
import OrderSale from "../View/Orders/sale";
import PointOfSale from "../View/Sales/table-state";
import AdminPanel from "../View/AdminPanel/admin-panel";
import CouponManagement from "../View/CouponManagemen/coupon-managemen";
import GoogleReview from "../View/GoogleReview/google-review";
import CustomerLoyalty from "../View/CustomerLoyalty/customer-loyalty";
import SystemSettings from "../View/Settings/system-setting";
import ManageItem from "../View/ManageItem/manage";

// BUSINESS CATALOG COMPONENTS IMPORT
import ItemCatalogue from "../View/BusinessCatalog/ItemCatalogue/item-catalogue";
import TaxGroup from "../View/BusinessCatalog/TaxGroup/manage";
import ManageItemDetails from "../View/BusinessCatalog/ItemCatalogue/details-view";
import MenuManagement from "../View/BusinessCatalog/MenuManagement/menu";
import AddonsGroup from "../View/BusinessCatalog/Addons/manage";
import Kitchens from "../View/BusinessCatalog/Kitchens/kitchen";
import Discount from "../View/BusinessCatalog/Discount/discount";
import Charges from "../View/BusinessCatalog/Charges/charges";

// Apps
import GalleryMusic from "../apps/GalleryMusic";
import GalleryVideo from "../apps/GalleryVideo";
import Tasks from "../apps/Tasks";
import Contacts from "../apps/Contacts";
import Chat from "../apps/Chat";
import AppCalendar from "../apps/AppCalendar";
import Email from "../apps/Email";
import FileManager from "../apps/FileManager";

// Pages
import Pricing from "../pages/Pricing";
import Faq from "../pages/Faq";
import Profile from "../pages/Profile";
import People from "../pages/People";
import Activity from "../pages/Activity";
import Events from "../pages/Events";
import Settings from "../pages/Settings";

// UI Elements
import LayoutColumns from "../docs/LayoutColumns";
import LayoutGrid from "../docs/LayoutGrid";
import LayoutGutters from "../docs/LayoutGutters";
import Accordions from "../docs/Accordions";
import Alerts from "../docs/Alerts";
import Avatars from "../docs/Avatars";
import Badges from "../docs/Badges";
import Breadcrumbs from "../docs/Breadcrumbs";
import Buttons from "../docs/Buttons";
import Cards from "../docs/Cards";
import Carousels from "../docs/Carousels";
import Dropdowns from "../docs/Dropdowns";
import Images from "../docs/Images";
import Listgroup from "../docs/Listgroup";
import Markers from "../docs/Markers";
import Modals from "../docs/Modals";
import NavTabs from "../docs/NavTabs";
import OffCanvas from "../docs/OffCanvas";
import Paginations from "../docs/Paginations";
import Placeholders from "../docs/Placeholders";
import Popovers from "../docs/Popovers";
import Progress from "../docs/Progress";
import Spinners from "../docs/Spinners";
import Toasts from "../docs/Toasts";
import Tooltips from "../docs/Tooltips";
import Tables from "../docs/Tables";
import FormElements from "../docs/FormElements";
import FormSelects from "../docs/FormSelects";
import FormChecksRadios from "../docs/FormChecksRadios";
import FormRange from "../docs/FormRange";
import FormPickers from "../docs/FormPickers";
import FormLayouts from "../docs/FormLayouts";
import UtilBackground from "../docs/UtilBackground";
import UtilBorder from "../docs/UtilBorder";
import UtilColors from "../docs/UtilColors";
import UtilDivider from "../docs/UtilDivider";
import UtilFlex from "../docs/UtilFlex";
import UtilSizing from "../docs/UtilSizing";
import UtilSpacing from "../docs/UtilSpacing";
import UtilOpacity from "../docs/UtilOpacity";
import UtilPosition from "../docs/UtilPosition";
import UtilTypography from "../docs/UtilTypography";
import UtilShadows from "../docs/UtilShadows";
import UtilExtras from "../docs/UtilExtras";
import ApexCharts from "../docs/ApexCharts";
import ChartJs from "../docs/ChartJs";
import MapLeaflet from "../docs/MapLeaflet";
import MapVector from "../docs/MapVector";
import IconRemix from "../docs/IconRemix";
import IconFeather from "../docs/IconFeather";
import InfiniteScrollComponent from './../View/_common/infinite-scroll';
import ManageInvoice from "../View/RecieptsAndPayments/manageInvoice";
// import SelectBranchComponent from "../View/Account/select-branch";

const ROLES = {
  'Admin': 1,
  'Management':2,
  'Branch Manager':3,
  'Kitchen Manager':4,
  'Waiter':5
}

const protectedRoutes = [
  { path: "dashboard/finance", element: <FinanceMonitoring /> },
  { path: "dashboard/events", element: <EventManagement /> },
  { path: "dashboard/sales", element: <SalesMonitoring /> },
  { path: "dashboard/analytics", element: <WebsiteAnalytics /> },
  // { path: "dashboard/crypto", element: <Cryptocliurrency /> },
  { path: "dashboard/helpdesk", element: <HelpdeskService /> },
  { path: "dashboard/storage", element: <StorageManagement /> },
  { path: "dashboard/product", element: <ProductManagement /> },

  // Aamir Sayyed
  { path: "dashboard/dashboard",allowedRoles:[ROLES.Admin , ROLES.Management], element: <Dashboard /> },
  { path: "dashboard/financial",allowedRoles:[ROLES.Admin , ROLES.Management], element: <FinancialDashboard /> },

  { path: "order/dashboard",allowedRoles:[ROLES.Admin , ROLES.Management], element: <SaleDashboard /> },
  { path: "order/report-analytics",allowedRoles:[ROLES.Admin , ROLES.Management], element: <ReportAnalytics /> },


  { path: "item-catalogue", allowedRoles:[ROLES.Admin, ROLES.Management], element: <ItemCatalogue /> },
  { path: "item-catalogue/details/:id", allowedRoles:[ROLES.Admin, ROLES.Management], element: <ManageItemDetails /> },
  { path: "customers", allowedRoles:[ROLES.Admin, ROLES.Management], element: <Customers /> },
  { path: "employees",allowedRoles:[ROLES.Admin , ROLES.Management],  element: <Employees /> },
  { path: "attendance",allowedRoles:[ROLES.Admin , ROLES.Management],  element: <Attendance /> },
  { path: "salary-advance",allowedRoles:[ROLES.Admin , ROLES.Management],  element: <SalaryAdvance /> },
  { path: "order/sale",allowedRoles:[ROLES.Admin],  element: <OrderSale /> },
  { path: "sales/table-state", allowedRoles:[ROLES.Admin , ROLES.Management], element: <PointOfSale /> },

  
  { path: "admin-panel",allowedRoles:[ROLES.Admin , ROLES.Management],  element: <AdminPanel /> },
  { path: "coupon-management",allowedRoles:[ROLES.Admin , ROLES.Management],  element: <CouponManagement /> },
  { path: "invoice-management",allowedRoles:[ROLES.Admin , ROLES.Management],  element: <ManageInvoice /> },
  { path: "google-review",allowedRoles:[ROLES.Admin , ROLES.Management],  element: <GoogleReview /> },
  { path: "customer-loyalty",allowedRoles:[ROLES.Admin , ROLES.Management],  element: <CustomerLoyalty /> },
  { path: "system-settings",allowedRoles:[ROLES.Admin, ROLES.Management],  element: <SystemSettings /> },
  { path: "manage-item", allowedRoles:[ROLES.Admin, ROLES.Management], element: <ManageItem /> },
  { path: "taxGroup/manage", element: <TaxGroup /> },
  { path: "/", element: <PointOfSale /> },

  // BUSINESS CATALOG COMPONENTS
  { path: "businessCatalog/menu", allowedRoles:[ROLES.Admin,ROLES.Management], element: <MenuManagement /> },
  { path: "business-catalog/addons", element: <AddonsGroup /> },
  { path: "business-catalog/kitchen", element: <Kitchens /> },
  { path: "business-catalog/discount", allowedRoles:[ROLES.Admin , ROLES.Management], element: <Discount /> },
  { path: "business-catalog/charges", allowedRoles:[ROLES.Admin , ROLES.Management], element: <Charges /> },

  // { path: "account/Choose-branch", element: <SelectBranchComponent /> },

  { path: "apps/gallery-music", element: <GalleryMusic /> },
  { path: "apps/gallery-video", element: <GalleryVideo /> },
  { path: "apps/tasks", element: <Tasks /> },
  { path: "apps/contacts", element: <Contacts /> },
  { path: "apps/chat", element: <Chat /> },
  { path: "apps/calendar", element: <AppCalendar /> },
  { path: "apps/email", element: <Email /> },
  { path: "apps/file-manager", element: <FileManager /> },
  { path: "pages/pricing", element: <Pricing /> },
  { path: "pages/faq", element: <Faq /> },
  { path: "pages/profile", element: <Profile /> },
  { path: "pages/people", element: <People /> },
  { path: "pages/activity", element: <Activity /> },
  { path: "pages/events", element: <Events /> },
  { path: "pages/settings", element: <Settings /> },
  { path: "docs/layout/grid", element: <LayoutGrid /> },
  { path: "docs/layout/columns", element: <LayoutColumns /> },
  { path: "docs/layout/gutters", element: <LayoutGutters /> },
  { path: "docs/com/accordions", element: <Accordions /> },
  { path: "docs/com/alerts", element: <Alerts /> },
  { path: "docs/com/avatars", element: <Avatars /> },
  { path: "docs/com/badge", element: <Badges /> },
  { path: "docs/com/breadcrumbs", element: <Breadcrumbs /> },
  { path: "docs/com/buttons", element: <Buttons /> },
  { path: "docs/com/cards", element: <Cards /> },
  { path: "docs/com/carousel", element: <Carousels /> },
  { path: "docs/com/dropdown", element: <Dropdowns /> },
  { path: "docs/com/images", element: <Images /> },
  { path: "docs/com/listgroup", element: <Listgroup /> },
  { path: "docs/com/markers", element: <Markers /> },
  { path: "docs/com/modal", element: <Modals /> },
  { path: "docs/com/navtabs", element: <NavTabs /> },
  { path: "docs/com/offcanvas", element: <OffCanvas /> },
  { path: "docs/com/pagination", element: <Paginations /> },
  { path: "docs/com/placeholders", element: <Placeholders /> },
  { path: "docs/com/popovers", element: <Popovers /> },
  { path: "docs/com/progress", element: <Progress /> },
  { path: "docs/com/spinners", element: <Spinners /> },
  { path: "docs/com/toasts", element: <Toasts /> },
  { path: "docs/com/tooltips", element: <Tooltips /> },
  { path: "docs/com/tables", element: <Tables /> },
  { path: "docs/form/elements", element: <FormElements /> },
  { path: "docs/form/selects", element: <FormSelects /> },
  { path: "docs/form/checksradios", element: <FormChecksRadios /> },
  { path: "docs/form/range", element: <FormRange /> },
  { path: "docs/form/pickers", element: <FormPickers /> },
  { path: "docs/form/layouts", element: <FormLayouts /> },
  { path: "docs/chart/apex", element: <ApexCharts /> },
  { path: "docs/chart/chartjs", element: <ChartJs /> },
  { path: "docs/map/leaflet", element: <MapLeaflet /> },
  { path: "docs/map/vector", element: <MapVector /> },
  { path: "docs/icon/remix", element: <IconRemix /> },
  { path: "docs/icon/feather", element: <IconFeather /> },
  { path: "docs/util/background", element: <UtilBackground /> },
  { path: "docs/util/border", element: <UtilBorder /> },
  { path: "docs/util/colors", element: <UtilColors /> },
  { path: "docs/util/divider", element: <UtilDivider /> },
  { path: "docs/util/flex", element: <UtilFlex /> },
  { path: "docs/util/sizing", element: <UtilSizing /> },
  { path: "docs/util/spacing", element: <UtilSpacing /> },
  { path: "docs/util/opacity", element: <UtilOpacity /> },
  { path: "docs/util/position", element: <UtilPosition /> },
  { path: "docs/util/typography", element: <UtilTypography /> },
  { path: "docs/util/shadows", element: <UtilShadows /> },
  { path: "docs/util/extras", element: <UtilExtras /> },
  { path: "infinite-scroll", element: <InfiniteScrollComponent /> },
]
export default protectedRoutes;