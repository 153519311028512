import { PAYMENT_MODE_LIST_SUCCESS } from '../actions/paymentModeAction';
const initialState = {
    PaymentModeList:[],
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_MODE_LIST_SUCCESS:
            return { ...state ,PaymentModeList: action.payload.result, error: null}
        default:            
            return state;
    }
}

export default reducer;