import Environment from "../../../core/Environment";
import jwtInterceptor from "../../../core/helpers/jwtInterceptor";

  const GetAddOnsList  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.get(`${Environment.Catalogue_Url}catalogue-addons-groups`,{
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
        }
      })
      return response.data
  }
  const GetAddOnsGroupbyId  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.get(`${Environment.Catalogue_Url}addon-group-items/${data}`,{
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
        }
      })
      return response.data
  }  
  const GetAddOnsItemByGroupId  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.get(`${Environment.Catalogue_Url}addon-group-items/${data}`,{
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
        }
      })
      return response.data
  }  
  const CreateAddOnsGroup  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.post(`${Environment.Catalogue_Url}create-catalogue-addon-group`,data,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data
  }
  const UpdateAddOnsGroup  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const  response = await jwtInterceptor.post(`${Environment.Catalogue_Url}update-catalogue-addon-group`,data,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data
  }
  const CreateAddOnsItem  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));
    const URL = data.id !== undefined ?`${Environment.Catalogue_Url}update-catalogue-addon-item`:`${Environment.Catalogue_Url}create-catalogue-addon-item`  
    const  response = await jwtInterceptor.post(URL,data,{       
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
     
      })
      return response.data
  }
  const DeleteAddOnsGroup  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));  
    const  response = await jwtInterceptor.post(`${Environment.Catalogue_Url}delete-catalogue-addon-group/${data}`,{
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
      })
      return response.data
  } 
  const DeleteAddOnsItem  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));  
    const  response = await jwtInterceptor.post(`${Environment.Catalogue_Url}delete-catalogue-addon-item/${data}`,{
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
      })
      return response.data
  } 
  const GetCatalogueByAddonsId  = async (data)=>{
    const storedToken = JSON.parse(localStorage.getItem('Token'));    
    const  response = await jwtInterceptor.get(`${Environment.Catalogue_Url}catalogue-by-addon-group/${data}`,{
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
          authorization: `Bearer ${storedToken}`,
      })
      return response.data
  } 
    export default {DeleteAddOnsItem,DeleteAddOnsGroup,UpdateAddOnsGroup,GetAddOnsList, CreateAddOnsGroup,GetAddOnsGroupbyId,CreateAddOnsItem,GetAddOnsItemByGroupId,GetCatalogueByAddonsId};