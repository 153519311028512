import React from "react";
import { Button, Offcanvas, Form } from "react-bootstrap";

export default function RefundInitiated(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-35">
                <Offcanvas.Header closeButton style={{ borderBottom: '1px solid #ccc' }}>
                    <Offcanvas.Title>Refund Initiated</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Form.Label>Refund Initiated Notes</Form.Label>
                    <Form.Control as="textarea" id="exampleFormControlTextarea1" rows="3" placeholder="Enter Refund Initiated Notes"></Form.Control>
                </Offcanvas.Body>

                <div className="offcanvas-footer">
                    <Button variant="primary" className="fs-14">
                       <span className="align-middle">Submit</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}